
import { takeUntil, map, switchMap, take, tap } from 'rxjs/operators';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { Component, OnInit } from '@angular/core';
import { WorkOrderFormService } from '../../services';
import { IWorkOrder } from '../../models';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { PhxConstants } from '../../../common';
import { forkJoin } from 'rxjs';
import { ComplianceDocumentService } from 'src/app/compliance/shared/compliance-document.service';
import { DocumentComplianceEntityInformation } from 'src/app/common/model/document-compliance/document-compliance-entity-information';

@Component({
  selector: 'app-workorder-tab-documents',
  templateUrl: './workorder-tab-documents.component.html'
})
export class WorkorderTabDocumentsComponent extends BaseComponentOnDestroy implements OnInit {

  html: {
    phxConstants: typeof PhxConstants;
    rootModel: IWorkOrder;
    lists: {
      listUserProfileWorker: Array<any>;
    };
    documentComplianceAdditionalInformation?: string;
  } = {
      phxConstants: null,
      rootModel: null,
      lists: {
        listUserProfileWorker: []
      },
      documentComplianceAdditionalInformation: null
    };

  workOrderVersionId: number | null = null;

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private commonListsObservableService: CommonListsObservableService,
    private complianceDocumentService: ComplianceDocumentService
  ) {
    super();
  }

  ngOnInit() {
    this.html.phxConstants = PhxConstants;
    this.workOrderFormService.workOrder$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((workOrder: IWorkOrder) => this.workOrderVersionId = workOrder.WorkOrderVersion.Id),
        switchMap((workOrder: IWorkOrder) =>
          forkJoin([
            this.commonListsObservableService.getUserProfileWorkers$(workOrder.UserProfileIdWorker)
              .pipe(
                take(1),
                map(listUserProfileWorker => ({ workOrder, listUserProfileWorker }))
              ),
            /** NOTE: this list is a document compliance data soft launch feature that will be removed in time */
            this.complianceDocumentService.getExcludedClientListForDocumentComplianceData()
          ])
        )
      )
      .subscribe(([data, exclusionList]) => {
        this.html.rootModel = data.workOrder;
        /** NOTE: getting document compliance data from gorilla vision is dependent on this 'additionalInformation'
         *  - if a client is excluded from the compliance document data feature we prevant that analysis from happening 
         * nulling this property - this is a temporary implementation (july/4/2024) until the feature rolled out in full
         */
        if (exclusionList.includes(data.workOrder.WorkOrderVersion?.BillingInfoes[0]?.OrganizationIdClient)) {
          this.html.documentComplianceAdditionalInformation = null;
        } else {
          const additionalInformation: DocumentComplianceEntityInformation = {
            WorkOrderId: data.workOrder.WorkOrderId,
            OrganizationIdInternal: data.workOrder.OrganizationIdInternal,
            OrganizationIdClient: data.workOrder.WorkOrderVersion?.BillingInfoes[0]?.OrganizationIdClient,
            OrganizationIdSupplier: data.workOrder.WorkOrderVersion?.PaymentInfoes[0]?.OrganizationIdSupplier,
            UserProfileIdWorker: data.workOrder.UserProfileIdWorker
          };
          this.html.documentComplianceAdditionalInformation = JSON.stringify(additionalInformation);
        }

        this.html.lists.listUserProfileWorker = data.listUserProfileWorker;
      });
  }
}
