import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  private readonly valuesToExclude = new Set([null, undefined, NaN, '']);
  transform(values: (string | number | null | undefined)[], separator: string, emptyValueSubstitute = $localize`:@@common.message.na:N/A`): string {
    return values.map(value => (this.valuesToExclude.has(value) ? emptyValueSubstitute : value)).join(separator);
  }
}
