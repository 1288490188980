import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import DevExpress from 'devextreme/bundles/dx.all';

@Component({
  selector: 'app-phx-select-box-badge',
  templateUrl: './phx-select-box-badge.component.html',
  styleUrls: ['./phx-select-box-badge.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhxSelectBoxBadgeComponent)
    }
  ]
})
export class PhxSelectBoxBadgeComponent implements ControlValueAccessor, OnDestroy {
  @Input() items: any;
  @Input() placeholder: string;
  @Input() showClearButton = true;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() badgeField: string;
  @Input() badgeCssClass = 'badge-warning';
  @Input() width: any; // "55px", "80%", "auto", "inherit" (default = undefined )
  @Input() value: any;
  @Input() searchField?: string;
  @Input() set searchable(s: boolean) {
    this.isSearchable = s;
    this.setFieldButtons(s);
  }

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  hasAlteredInput = false;
  isSearchable = false;
  buttons: Array<DevExpress.common.TextEditorButton> = [];

  private isDestroyed = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = _ => {};

  onTouched = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onValueChanged(event: any) {
    this.hasAlteredInput = false;
    this.value = event.value;
    if (event.event) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  private setFieldButtons(isSearchable: boolean): void {
    this.buttons = isSearchable
      ? [
          {
            name: 'search',
            location: 'before',
            options: {
              icon: 'search',
              disabled: true,
              stylingMode: 'text'
            }
          }
        ]
      : [];
  }
}
