import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { IFormService } from '@common/model';
import { FormGroup, FormControl, FormBuilder } from '@common/ngx-strongly-typed-forms/model';
import { ICanadianNonResidentWithholdingTax, IWorkOrder } from '@workorder/models';

import { WorkorderService } from '../workorder.service';



@Injectable()
export class CnrWithholdingTaxFormService implements IFormService {

  formGroup: FormGroup<ICanadianNonResidentWithholdingTax>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkorderService
  ) {
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ICanadianNonResidentWithholdingTax> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<ICanadianNonResidentWithholdingTax>({
      CNRWithholdingTaxIsApplied: [
        workorder.WorkOrderVersion.CNRWithholdingTaxIsApplied
      ],
      CNRWithholdingTaxOverrideReason: [
        workorder.WorkOrderVersion.CNRWithholdingTaxOverrideReason,
        [Validators.maxLength(250)]
      ],
    });

    this.workOrderService.isCanadianNonResidentWithholdingTaxApplicable(workorder)
    .subscribe(isApplicable => {
      if (isApplicable) {
        this.cNRWithholdingTaxIsAppliedFormControl.setValue(workorder.WorkOrderVersion.CNRWithholdingTaxIsApplied);
        this.cNRWithholdingTaxOverrideReasonFormControl.setValue(workorder.WorkOrderVersion.CNRWithholdingTaxOverrideReason);
        this.cNRWithholdingTaxOverrideReasonFormControl.setValidators(
          workorder.WorkOrderVersion.CNRWithholdingTaxIsApplied ? [Validators.maxLength(250)] : [Validators.required, Validators.maxLength(250)]
        );
      } else {
        this.cNRWithholdingTaxIsAppliedFormControl.setValue(null);
        this.cNRWithholdingTaxOverrideReasonFormControl.setValue(null);
        this.cNRWithholdingTaxOverrideReasonFormControl.setValidators(null);
      }
    });

    return this.formGroup;
  }
  
  updateForm(workorder: IWorkOrder): void {
    this.formGroup.patchValue({
      CNRWithholdingTaxIsApplied: workorder.WorkOrderVersion.CNRWithholdingTaxIsApplied,
      CNRWithholdingTaxOverrideReason: workorder.WorkOrderVersion.CNRWithholdingTaxOverrideReason,
    }, { emitEvent: true });
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.cNRWithholdingTaxIsAppliedChanges$
      .pipe(takeUntil(this.isRootComponentDestroyed$))
      .subscribe(isApplied => this.cNRWithholdingTaxIsAppliedFormListener(isApplied));
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const cnrFormGroupValues: ICanadianNonResidentWithholdingTax = this.formGroup.value;
    workOrder.WorkOrderVersion.CNRWithholdingTaxIsApplied = cnrFormGroupValues.CNRWithholdingTaxIsApplied;
    workOrder.WorkOrderVersion.CNRWithholdingTaxOverrideReason = cnrFormGroupValues.CNRWithholdingTaxOverrideReason;
    return workOrder;
  }

  public get cNRWithholdingTaxIsAppliedChanges$() {
    return this.cNRWithholdingTaxIsAppliedFormControl.valueChanges;
  }

  get cNRWithholdingTaxIsAppliedFormControl(): FormControl<boolean> {
    return this.formGroup.get('CNRWithholdingTaxIsApplied') as FormControl<boolean>;
  }

  get cNRWithholdingTaxOverrideReasonFormControl(): FormControl<string> {
    return this.formGroup.get('CNRWithholdingTaxOverrideReason') as FormControl<string>;
  }

  private cNRWithholdingTaxIsAppliedFormListener(isApplied: boolean | null) {
    // when CNR withholding tax is not applicable, this bool should always be null
    if (isApplied === null) {
      this.cNRWithholdingTaxOverrideReasonFormControl.setValue(null);
    } else {
      // when tax is applicable, is applied should be true, unless overriden with isApplied = false
      this.cNRWithholdingTaxOverrideReasonFormControl.setValidators(
        isApplied ? [Validators.maxLength(250)] : [Validators.required, Validators.maxLength(250)]
      );

      if (isApplied) {
        this.cNRWithholdingTaxOverrideReasonFormControl.setValue(null);
      }
    }
  }
}
