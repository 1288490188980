<form [formGroup]="formGroup">
  <div class="form-horizontal">
    <app-workorder-time-material-detail [readOnlyStorage]="readOnlyStorage">
    </app-workorder-time-material-detail>

    <div class="content-group">
      <div class="content-left">
        <app-workorder-billing-invoices [inputFormGroup]="formGroup.controls.TabTimeMaterialInvoiceBillingInfoes"
          [invoiceType]="phxConstants.InvoiceType.TimeSheet" [readOnlyStorage]="readOnlyStorage"
          (addRecipient)="addBillingRecipient($event)" (removeRecipient)="removeBillingRecipient($event)"
          (addTimeSheetNote)="addTimesheetBillingInvoiceNote($event)"
          (removeTimeSheetNote)="removeTimesheetBillingInvoiceNote($event)">
        </app-workorder-billing-invoices>
      </div>
      <div class="content-right">
        <app-workorder-payment-invoices [inputFormGroup]="formGroup.controls.TabTimeMaterialInvoicePaymentInfoes"
          [invoiceType]="phxConstants.InvoiceType.TimeSheet" [readOnlyStorage]="readOnlyStorage">
        </app-workorder-payment-invoices>
        </div>
      <div class="content-right xyz margin-20-v-t ">
        <app-phx-timesheet-override-exception [cssClass]="'timesheet-override'" [overrideTimesheetExceptions]="overrideTimesheetExceptions" 
        [editable]="readOnlyStorage.IsEditable && !readOnlyStorage.isTemplate" [entityType]="entityType"  
        (updatedTimesheetExceptions)="getUpdatedTimesheetExceptions($event)" 
        (addNewTimesheetException)="addNewTimesheetException($event)" 
        (removeTimesheetException)="removeTimesheetException($event)" 
        [entityId]="entityId">
        </app-phx-timesheet-override-exception>
      </div>
      </div>
    </div>
</form>