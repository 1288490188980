import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { NonATSLineOfBusinessRestrictionDto } from './models/non-ats-line-of-business-restriction.dto';

@Injectable({
  providedIn: 'root'
})
export class FboMonolithAssignmentDataService {
  constructor(private apiService: ApiService) {}

  public getNonATSLineOfBusinessRestrictions(): Observable<NonATSLineOfBusinessRestrictionDto[]> {
    return this.apiService.httpGetRequest<NonATSLineOfBusinessRestrictionDto[]>('assignment/non-ats-lob-restrictions');
  }
}
