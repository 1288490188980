
import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IBillingRecipient, IReadOnlyStorage } from '../../models';
import { CodeValue, CodeValueGroups } from '../../../common/model';
import { CodeValueService, PhxConstants } from '../../../common';
import { ExpenseDetailFormService, WorkorderService } from '../../services';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { Subscription } from 'rxjs';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { Validators } from '@angular/forms';

interface IHtml {
  codeValueLists: {
    listDeliveryMethods: Array<CodeValue>;
    listFileExportFormats: Array<CodeValue>;
    listRecipientTypes: Array<CodeValue>;
    listBillingRecipientTypes: Array<CodeValue>;
  };
  commonLists: {
    listProfilesForApproval: Array<any>;
    listActiveUserProfile: Array<any>;
  };
  phxConstants: any;
}

@Component({
  selector: 'app-workorder-billing-recipient',
  templateUrl: './workorder-billing-recipient.component.html',
  styleUrls: ['./workorder-billing-recipient.component.less']
})
export class WorkorderBillingRecipientComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() invoiceType: number;
  @Input() invoiceTypeId: number;
  @Input() recipientIndex: number;
  @Input() organizationClientId: number;

  @Input() inputFormGroup: FormGroup<IBillingRecipient>;
  @Output() removeRecipient = new EventEmitter();

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  deliveryMethods: any[] = [];
  codeValueGroups = CodeValueGroups;

  methodologyId: number;

  html: IHtml = {
    codeValueLists: {
      listFileExportFormats: [],
      listDeliveryMethods: [],
      listRecipientTypes: [],
      listBillingRecipientTypes: []
    },
    commonLists: {
      listProfilesForApproval: [],
      listActiveUserProfile: []
    },
    phxConstants: typeof PhxConstants
  };

  private subscription$: Subscription;

  constructor(
    private codeValueService: CodeValueService,
    private workOrderService: WorkorderService,
    private commonListsObservableService: CommonListsObservableService,
    private expenseDetailFormService: ExpenseDetailFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
    this.getActiveUserProfiles();
  }

  get deliveryMethodIdChanges$() {
    return this.inputFormGroup.get('DeliveryMethodId').valueChanges;
  }

  ngOnInit() {
    if (this.organizationClientId) {

      // INVESTIGATE HOW THIS COMPONENT IS BEING USED
      // IF TOGGLE BY URL, THIS ASYNC CALL MIGHT NOT GET TRIGGER AGAIN WITH UPDATED VALUES
      this.workOrderService.getProfilesListOrganizational(this.organizationClientId)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((response) => {
          const list = response ? response.map(item => item.Data) : [];
          this.html.commonLists.listProfilesForApproval = list;
          this.html.commonLists.listProfilesForApproval.forEach(element => {
            element.DisplayValue = element.Contact.FullName + ' - ' + element.Contact.Id;
          });
        });
    }
    this.deliveryMethods = this.getDeliverMethods(this.inputFormGroup.get('RecipientTypeId').value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormGroup) {
      this.setupFormGroupListener();
    }
  }

  setupFormGroupListener() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.deliveryMethodIdChanges$.pipe(takeUntil(this.isDestroyed$)).subscribe(value => {

        this.inputFormGroup.get('FileExportFormatId').setValidators(
          value && value === PhxConstants.DeliveryMethod.FileExportFormat
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'FileExportFormatId', [
              Validators.required
            ])
            : null
        );

        this.inputFormGroup.get('DeliverToUserProfileId').setValidators(
          value && (value === PhxConstants.DeliveryMethod.InternalProfile || value === PhxConstants.DeliveryMethod.ClientProfile)
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliverToUserProfileId', [
              Validators.required
            ])
            : null
        );

        this.inputFormGroup.patchValue({
          FileExportFormatId: null,
          DeliverToUserProfileId: null,
        });
        this.inputFormGroup.updateValueAndValidity();
      })
    );

    if (this.invoiceTypeId === PhxConstants.InvoiceType.Expense) {
      this.subscription$.add(
        this.expenseDetailFormService.expenseIdChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(methodologyId => {
            this.methodologyId = methodologyId;

            this.inputFormGroup.get('UserProfileId').setValidators(
              this.methodologyId !== PhxConstants.ExpenseMethodology.NoExpense && this.invoiceTypeId === this.invoiceType
                ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'UserProfileId', [
                  Validators.required
                ])
                : null
            );

            this.inputFormGroup.get('DeliveryMethodId').setValidators(
              this.methodologyId !== PhxConstants.ExpenseMethodology.NoExpense && this.invoiceTypeId === this.invoiceType
                ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliveryMethodId', [Validators.required
                  ])
                : null
            );

            this.inputFormGroup.patchValue({
              UserProfileId: this.inputFormGroup.get('UserProfileId').value || null,
              DeliveryMethodId: this.inputFormGroup.get('DeliveryMethodId').value || null,
            }, { emitEvent: false });
          })
      );
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getActiveUserProfiles() {
    this.commonListsObservableService
      .listUserProfileInternal$().pipe(
        takeUntil(this.isDestroyed$))
      .subscribe((response: any) => {
        if (response) {
          this.html.commonLists.listActiveUserProfile = response;
        }
      });
  }

  getDeliverMethods(recipientTypeId: number) {
    if (recipientTypeId === PhxConstants.RecipientType.InvoiceRecipient) {
      return this.html.codeValueLists.listDeliveryMethods.filter(item => {
        return item.id !== PhxConstants.DeliveryMethod.InternalProfile
          && item.id !== PhxConstants.DeliveryMethod.ClientProfile;
      });
    } else {
      return this.html.codeValueLists.listDeliveryMethods.filter(item => {
        return item.id !== PhxConstants.DeliveryMethod.InternalProfile
          && item.id !== PhxConstants.DeliveryMethod.Suppressed
          && item.id !== PhxConstants.DeliveryMethod.FileExportFormat
          && item.id !== PhxConstants.DeliveryMethod.ClientProfile;
      });
    }
  }

  getCodeValuelistsStatic() {
    this.html.phxConstants = PhxConstants;
    this.html.codeValueLists.listDeliveryMethods = this.codeValueService.getCodeValues(this.codeValueGroups.DeliveryMethod, true);
    this.html.codeValueLists.listFileExportFormats = this.codeValueService.getCodeValues(this.codeValueGroups.FileExportFormat, true);
    this.html.codeValueLists.listRecipientTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RecipientType, true);
    this.html.codeValueLists.listBillingRecipientTypes = this.html.codeValueLists.listRecipientTypes.filter(item => item.id !== 1);
  }

  removeBillingRecipient(index: number) {
    this.removeRecipient.emit(index);
  }
}
