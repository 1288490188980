import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IPaymentPartiesRateDetail, IPaymentPaySideDeduction, IReadOnlyStorage } from '../../models';
import { ToTypedFormGroup } from 'src/app/common/model/to-typed-form-group.model';
import { FormGroup } from '@angular/forms';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { WorkorderService } from '../../services';

@Component({
  selector: 'app-workorder-payment-pay-side-deductions',
  templateUrl: './workorder-payment-pay-side-deductions.component.html',
  styleUrls: ['./workorder-payment-pay-side-deductions.component.less']
})
export class WorkorderPaymentPaySideDeductionsComponent extends BaseComponentOnDestroy implements OnChanges {
  @Input() inputFormGroup: FormGroup<ToTypedFormGroup<IPaymentPaySideDeduction>>;
  @Input() paymentInfo: FormGroup<ToTypedFormGroup<IPaymentPartiesRateDetail>>;
  @Input() paySideDeductionIndex: number;
  @Input() organizationIdClient: number;
  @Input() lineOfBusinessId: number;
  @Output() deletePaySideDeductionEvent = new EventEmitter();

  @Input() readOnlyStorage: IReadOnlyStorage;
  listPaySideDeductions: [];

  constructor(private workOrderService: WorkorderService) {
    super();
  }

  ngOnChanges(): void {
    this.workOrderService
      .getPaySideDeductionsByOrganizationId(this.organizationIdClient, this.lineOfBusinessId)
      .subscribe(response => {
        this.listPaySideDeductions = response;
      });
  }

  removePaySideDeduction(paySideDeductionIndex: number) {
    this.deletePaySideDeductionEvent.emit(paySideDeductionIndex);
  }

}
