import { Component, OnInit, Input } from '@angular/core';
import { PhxConstants, CodeValueGroups, CodeValueService, PhxLocalizationService } from '../../../common';
import { CodeValue } from '../../../common/model';
import { PhoenixCommonModuleResourceKeys } from '../../../common/PhoenixCommon.module';

@Component({
  selector: 'app-workorder-purchaseorderline-tab-details',
  templateUrl: './workorder-purchaseorderline-tab-details.component.html',
  styleUrls: ['./workorder-purchaseorderline-tab-details.component.less']
})
export class WorkorderPurchaseorderlineTabDetailsComponent implements OnInit {

  @Input() purchaseorderForm;
  @Input() poNumber;

  phxConstants = PhxConstants;
  codeValueGroups = CodeValueGroups;
  listDepletionOption: Array<CodeValue>;

  constructor(
    private codevalueservice: CodeValueService,
    private localizationService: PhxLocalizationService
  ) {}

  ngOnInit() {
    this.listDepletionOption = this.codevalueservice
      .getCodeValues(this.codeValueGroups.PurchaseOrderDepletedOptions, true)
      .map(c => {
        return {
          ...c,
          text: c.id === PhxConstants.DepletionOption.FundsAllocated
            ? this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.yes)
            : c.id === PhxConstants.DepletionOption.Shared
              ? this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.no)
              : c.text
        };
      });
  }
}
