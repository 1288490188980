import { Component, Input, inject } from '@angular/core';

import { PhxConstants } from '@common/PhoenixCommon.module';
import { ConfigurationService } from '@configuration/service/configuration.service';

import { IReadOnlyStorage } from '../../models';
import { PartyTabFormService } from '../../services';

@Component({
  selector: 'app-workorder-tab-parties',
  templateUrl: './workorder-tab-parties.component.html'
})
export class WorkorderTabPartiesComponent {
  @Input({ required: true }) readOnlyStorage: IReadOnlyStorage;

  readonly formGroup = inject(PartyTabFormService).formGroup;
  readonly hasIncentiveCompensationSection$ = inject(ConfigurationService).isFeatureActive$(PhxConstants.FeatureFlags.workorderIncentiveCompensationAllowance);
}
