import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PhxModalComponent } from 'src/app/common/components/phx-modal/phx-modal.component';
import { CodeValue, CodeValueGroups, PhxButton, PhxConstants } from 'src/app/common/model';
import { WorkorderService } from '../../services';
import { IRoot, IWorkOrder } from '../../models';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiService, CodeValueService, CommonService } from 'src/app/common';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';

interface RateQuestion {
  form: string;
  question: string;
  questionFr: string;
  options: RateOption[];
  otherOption: string;
}

interface RateOption {
  text: string;
  textFr: string;
  risk: string;
  checked: boolean;
}

interface Translation {
  [key: string]: string;
}

type RiskName = 'Low' | 'Moderate' | 'High';

@Component({
  selector: 'app-termination-planning-modal',
  templateUrl: './termination-planning-modal.component.html',
  styleUrls: ['./termination-planning-modal.component.less'],
  providers: [DatePipe]
})
export class TerminationPlanningModalComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() workOrder: IRoot;
  @Input() workOrderDto: IWorkOrder;

  @Output() loadAdditionalDocuments = new EventEmitter();
  @ViewChild(PhxModalComponent) modal: PhxModalComponent;

  userProfileIdWorker$ = new BehaviorSubject<number>(null);
  workerProfileType: string = null;

  isEnglish: boolean = true;
  englishLang = 'EN';
  frenchLang = 'FR';

  currentTranslations: Translation = {};
  englishTranslations: Translation = {};
  frenchTranslations: Translation = {};

  form: UntypedFormGroup;
  riskForm: UntypedFormGroup;

  buttons: PhxButton[] = [];
  riskQuestionsAreValid = false;

  questions: RateQuestion[] = [];
  currentPage = 1;

  currentRiskLevel = null;

  methodOptions: { value: string; }[] = [];
  classificationOptions: { value: string; id: string; risk: string; profileTypeId: number; }[] = [];
  riskOptions = [];

  methodOptionsFr: { value: string; }[] = [];
  classificationOptionsFr: { value: string; id: string; risk: string; profileTypeId: number; }[] = [];
  riskOptionsFr = [];

  workerLocationOptions = [];
  clientWorksiteOptions = [];

  validationMessages: string[] = [];
  formSubmitting = false;

  readonly PhxConstants = PhxConstants;
  readonly Pages = {
    formPage: 1,
    questionPage: 2,
    analysisPage: 3,
    finalPage: 4
  };
  readonly RISK = {
    LOW: 'Low',
    MODERATE: 'Moderate',
    HIGH: 'High',
    NA: 'NA'
  };

  riskSummaries: Record<RiskName, string>;
  riskSummariesFr: Record<RiskName, string>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workorderService: WorkorderService,
    private commonListsObservableService: CommonListsObservableService,
    private codeValueService: CodeValueService,
    private apiService: ApiService,
    private commonService: CommonService,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.initRateQuestions();
    this.initRiskSummaries();

    this.initTerminationPlanningForms();
    this.initUserProfileIdWorkerListener();

    this.setEnglishTranslations();
    this.setFrenchTranslations();

    this.clientWorksiteOptions = this.codeValueService.getCodeValues(CodeValueGroups.Worksite, true);
    this.currentTranslations = this.englishTranslations;
  }

  /** NOTE: reset UI each time user opens modal */
  openModal() {
    this.currentPage = 1;
    this.currentRiskLevel = null;

    this.resetForm();
    this.updateFormWithWorkOrder(this.workOrder);

    this.setModalButtons();
    this.modal.show();

    /** NOTE: fire these when modal opens because the user may have gone from one WO to another WO without re-initializing the component */
    /** NOTE: we will always have a client that doesn't change */
    this.initObserveClientContact(this.workOrder.TabParties.TabPartyBillingInfoes?.PartiesRateDetails?.[0]?.OrganizationIdClient);
    /** NOTE: we will always have a worker that doesnt change */
    this.initObserverWorker(this.workOrder.TabParties.TabPartyPaymentInfoes?.PaymentPartiesRateDetails?.[0]?.UserProfileIdWorker);
  }

  changePage(change: number) {
    this.currentPage = this.currentPage + change;
    this.setModalButtons();
  }

  onSelectRiskOption(question: RateQuestion, option: RateOption) {
    const changedQuestion = this.questions.find(f => f.form === question.form);
    const optionIndex = changedQuestion.options.findIndex(f => f.text === option.text);

    changedQuestion.options[optionIndex].checked = !changedQuestion.options[optionIndex].checked;

    this.setRiskLevel();
  }

  /** NOTE: each risk question has an 'other' option which is a drop down selection */
  onOtherOptionSelected(selection, question) {
    const changedQuestion = this.questions.find(f => f.form === question.form);
    changedQuestion.otherOption = selection?.value;

    if (!selection.value) {
      this.riskForm.get(`${question.form}Notes`).clearValidators();
    } else {
      this.riskForm.get(`${question.form}Notes`).setValidators(Validators.required);
    }
    this.riskForm.get(`${question.form}Notes`).updateValueAndValidity();
    this.setRiskLevel();
  }

  submitForm() {
    this.validationMessages = [];

    const body = {
      TerminationRiskAssessmentHtml: this.convertFormToHtml(),
      WorkOrderVersionId: this.workOrder.Id,
      RiskAssessmentValue: this.currentRiskLevel,
      AdditionalRecipients: this.riskForm.controls.additionalRecipients.value,
      RiskAssessmentDate: this.form.controls.requestDate.value
    };

    this.validateEmailAddresses();

    if (!this.validationMessages.length) {
      this.formSubmitting = true;
      this.apiService.command('WorkOrderTerminationRiskAssessmentSubmit', body, true).then(() => {
        this.currentPage = this.Pages.finalPage;
        this.setModalButtons();
      }).catch(error => {
        const validationMessages = this.commonService.parseResponseError(error);
        if (validationMessages.length > 0) {
          validationMessages.forEach(element => {
            this.validationMessages.push(element.Message);
          });
        } else {
          this.validationMessages = ['An unknown error has occured.'];
        }
      }).finally(() => this.formSubmitting = false);
    }
  }

  openHotfix() {
    const docsUrl = this.getDocumentTabUrl();
    navigator.clipboard.writeText(docsUrl);
    window.open(this.getHotfixUrl(docsUrl));

    this.modal.hide();
  }

  getDocumentTabUrl(): string {
    const arUrl = location.href.split('next');
    const arRoute = arUrl[1].split('/').filter(m => !!m);

    return `${arUrl[0]}next/${arRoute.slice(0, 4).join('/')}/activity/documents`;
  }

  getHotfixUrl(docsUrl: string): string {
    const codec = new HttpUrlEncodingCodec;
    const base = 'https://procomservices.atlassian.net/servicedesk/customer/portal/6/group/21/create/154?';
    const qs = [];
    qs.push(`customfield_10473=${codec.encodeValue(this.form.controls.clientName.value)}`);
    qs.push(`customfield_10470=${codec.encodeValue(this.form.controls.contractorName.value)}`);
    qs.push(`customfield_10831=${codec.encodeValue('Work Order: ' + docsUrl)}`);

    return `${base}${qs.join('&')}`;
  }

  setLanguage(lang: string) {
    if (lang === this.englishLang) {
      this.currentTranslations = this.englishTranslations;
      this.isEnglish = true;
    } else {
      this.currentTranslations = this.frenchTranslations;
      this.isEnglish = false;
    }
    this.setModalButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.workOrderDto) {
      this.userProfileIdWorker$.next(changes.workOrderDto.currentValue.UserProfileIdWorker);
    }
  }

  private validateEmailAddresses() {
    if (this.riskForm.controls.additionalRecipients.value) {
      const emailRegEx = /(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*|".+")@(?:\[\d{1,3}(?:\.\d{1,3}){3}]|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?![a-zA-Z])/g;
      /** NOTE: remove all valid emails - what is left determines if there is an invalid email address   */
      const isInvalid = this.riskForm.controls.additionalRecipients.value.replace(emailRegEx, '').includes('@');

      if (isInvalid) {
        this.validationMessages.push(this.currentTranslations.invalidEmail);
      }
    }
  }

  private initUserProfileIdWorkerListener() {
    this.userProfileIdWorker$.pipe(
      filter(userProfileIdWorker => !!userProfileIdWorker),
      takeUntil(this.isDestroyed$),
      switchMap(userProfileIdWorker => this.commonListsObservableService.getUserProfileWorkers$(userProfileIdWorker))
    ).subscribe((listUserProfileWorker: any) => {
      if (listUserProfileWorker?.length) {
        const profileTypeId = listUserProfileWorker[0].ProfileTypeId;
        const classificationOption = this.classificationOptions.find(f => f.profileTypeId === profileTypeId);
        this.workerProfileType = classificationOption?.id || null;
      }
    });
  }

  /** NOTE: to get an overall risk value we need to calculate acording to selections */
  private setRiskLevel() {
    const riskValues = this.getRiskValues();

    this.currentRiskLevel = null;

    if (riskValues.high > 0 || (riskValues.moderate > 5 && riskValues.high === 0)) {
      this.currentRiskLevel = this.RISK.HIGH;
    } else if (riskValues.moderate > 0 && riskValues.moderate < 6 && riskValues.high === 0) {
      this.currentRiskLevel = this.RISK.MODERATE;
    } else if (riskValues.low > 0 && riskValues.moderate === 0 && riskValues.high === 0) {
      this.currentRiskLevel = this.RISK.LOW;
    }

    this.validateRiskQuestions();
  }

  private getRiskValues(): { low: number, moderate: number, high: number; } {
    let low = 0;
    let moderate = 0;
    let high = 0;

    const countRiskValue = (riskOption, RISK) => {
      if (riskOption === RISK.LOW) { low += 1; }
      else if (riskOption === RISK.MODERATE) { moderate += 1; }
      else if (riskOption === RISK.HIGH) { high += 1; }
    };

    this.questions.forEach(question => {
      if (question.otherOption) {
        countRiskValue(question.otherOption, this.RISK);
      }
      question.options.forEach(option => {
        if (option.checked) {
          countRiskValue(option.risk, this.RISK);
        }
      });
    });

    if (this.form.controls.classification.value) {
      countRiskValue(this.classificationOptions.find(f => f.id === this.form.controls.classification.value).risk, this.RISK);
    }

    return { low, moderate, high };
  }

  /** NOTE: user needs to select at least one option per question - if they select the 'other'
   * options then they need to include some 'notes'
   */
  private validateRiskQuestions() {
    let isValid = true;

    /** NOTE: check each question for at least one selected option */
    this.questions.forEach(question => {
      if (!question.options.find(f => f.checked) && !question.otherOption) {
        isValid = false;
      }
      /** NOTE: if user selected 'Other' option then there needs to be some Notes added */
      const questionNotes = this.riskForm.get(`${question.form}Notes`);
      if (question.otherOption && !questionNotes?.value) {
        isValid = false;
      }
    });

    this.riskQuestionsAreValid = isValid;
  }

  private updateFormWithWorkOrder(workOrder: IRoot) {
    this.form.patchValue({
      clientName: workOrder.TabParties.TabPartyBillingInfoes?.PartiesRateDetails?.[0]?.OrganizationClientDisplayName,
      clientId: workOrder.TabParties.TabPartyBillingInfoes?.PartiesRateDetails?.[0]?.OrganizationIdClient,
      clientLocationId: workOrder.TabCore.Details.WorksiteId,
      engagementDate: workOrder.TabCore.Details.StartDate,
      assignmentLength: this.getDateDifferenceByMonths(new Date(workOrder.TabCore.Details.OriginalStartDate || workOrder.TabCore.Details.StartDate), new Date(workOrder.TabCore.Details.EndDate)),
      contractorLocationId: workOrder.TabCore.Details.WorkerLocationId
    });

    this.form.controls.requestDate.patchValue(new Date());
  }

  private initObserveClientContact(organizationIdClient: number) {
    this.workorderService.getProfilesListOrganizational(organizationIdClient).pipe(
      filter(profiles => !!profiles),
      takeUntil(this.isDestroyed$)
    ).subscribe(profiles => {
      const clientContact = profiles.find(f => f.Id === this.workOrder.TabParties.TabPartyBillingInfoes?.PartiesRateDetails?.[0]?.UserProfileIdClient);
      if (clientContact) {
        this.form.patchValue({
          clientContactName: clientContact.DisplayText,
          clientContactId: clientContact.Id
        });
      }
    });
  }

  private initObserverWorker(userProfileIdWorker: number) {
    this.commonListsObservableService.getUserProfileWorkers$(userProfileIdWorker).pipe(
      filter(workers => !!workers),
      take(1)
    ).subscribe((workers: any[]) => {
      const worker = workers.find(f => f.Id === userProfileIdWorker);
      if (worker) {
        this.setWorkerLocationOptions(worker.ProfileTypeId);
        this.form.patchValue({
          contractorName: worker.Contact.FullName,
          clientContactId: worker.Id
        });
      }
    });
  }

  private setWorkerLocationOptions(profileTypeId: number): void {
    if ([PhxConstants.UserProfileType.WorkerTemp, PhxConstants.UserProfileType.WorkerCanadianSp].includes(profileTypeId)) {
      this.workerLocationOptions = this.getSubdivisionsByCountry(PhxConstants.CountryCanada);
    } else if (PhxConstants.UserProfileType.WorkerUnitedStatesW2 === profileTypeId) {
      this.workerLocationOptions = this.getSubdivisionsByCountry(PhxConstants.CountryUSA);
    } else {
      this.workerLocationOptions = this.getAllSubdivisions();
    }
  }

  private getAllSubdivisions(): CodeValue[] {
    const subdivs = this.codeValueService.getCodeValues(CodeValueGroups.Subdivision, true);
    return this.getSubdivisionsWithCountryNames(subdivs);
  }

  private getSubdivisionsByCountry(countryId: number): CodeValue[] {
    const subdivs = this.codeValueService.getRelatedCodeValues(CodeValueGroups.Subdivision, countryId, CodeValueGroups.Country);
    return this.getSubdivisionsWithCountryNames(subdivs, countryId);
  }

  private getSubdivisionsWithCountryNames(subdivs: CodeValue[], countryId?: number): CodeValue[] {
    const countryNames: { [countryId: number]: string; } = {};

    if (countryId) {
      const country = this.codeValueService.getCodeValue(countryId, 'geo.CodeCountry');
      countryNames[country.id] = country.text;
    } else {
      this.codeValueService.getCodeValues(CodeValueGroups.Country, true)
        .forEach((c: CodeValue) => {
          countryNames[c.id] = c.text;
        });
    }

    return subdivs.map(s => ({ ...s, text: `${s.text}, ${countryNames[s.parentId]}` }));
  }

  private initTerminationPlanningForms() {
    this.form = this.formBuilder.group({
      requestDate: [null, Validators.required],
      contractorName: null,
      contractorLocation: null,
      contractorLocationId: null,
      clientName: null,
      clientId: null,
      clientLocation: null,
      clientLocationId: null,
      clientContactName: null,
      clientContactId: null,

      engagementDate: null,
      assignmentLength: null,
      timeOnAssignment: [null, Validators.required],

      conversationDate: [null, Validators.required],
      conversationMethod: [null, Validators.required],
      conversationWith: [null, Validators.required],
      conversationRole: [null, Validators.required],
      procomProfile: [null, Validators.required],
      followUp: [null, Validators.required],

      noticePeriodProvided: [null, Validators.required],
      noticePeriodProvidedDetails: null,

      offboardingNotes: null,

      classification: [null, Validators.required],
      classificationNotes: null,
    });

    this.riskForm = this.formBuilder.group({
      MethodNotes: null,
      ReasonNotes: null,
      ConsiderationNotes: null,
      FactorsNotes: null,
      additionalRecipients: null
    });

    this.methodOptions = [
      { value: 'Email' },
      { value: 'Teams/Skype/Zoom etc.' },
      { value: 'In Person' },
      { value: 'Phone Call' },
    ];
    this.methodOptionsFr = [
      { value: 'email' },
      { value: 'Teams/Skype/Zoom etc.' },
      { value: 'en personne' },
      { value: 'appel téléphonique' },
    ];

    this.classificationOptions = [
      { value: 'Temp', id: 'Temp', risk: 'Low', profileTypeId: 3 },
      { value: 'W2', id: 'W2', risk: 'Low', profileTypeId: 7 },
      { value: 'INC Worker', id: 'INC/LLC', risk: 'Low', profileTypeId: 5 },
      { value: 'Canadian SP', id: 'SP', risk: 'Moderate', profileTypeId: 4 },
    ];
    this.classificationOptionsFr = [
      { value: 'Temp', id: 'Temp', risk: 'Low', profileTypeId: 3 },
      { value: 'W2', id: 'W2', risk: 'Low', profileTypeId: 7 },
      { value: 'SARL/SA', id: 'INC/LLC', risk: 'Low', profileTypeId: 5 },
      { value: 'SP Canadien ', id: 'SP', risk: 'Moderate', profileTypeId: 4 },
    ];

    this.riskOptions = [
      { value: this.RISK.LOW, id: this.RISK.LOW }, { value: this.RISK.MODERATE, id: this.RISK.MODERATE }, { value: this.RISK.HIGH, id: this.RISK.HIGH }
    ];
    this.riskOptionsFr = [
      { value: 'faible', id: this.RISK.LOW }, { value: 'modéré', id: this.RISK.MODERATE }, { value: 'élevé', id: this.RISK.HIGH }
    ];

    this.form.controls.classification.valueChanges.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => this.setRiskLevel());

    this.riskForm.valueChanges.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => this.setRiskLevel());

    this.form.controls.requestDate.valueChanges.pipe(
      filter(requestDate => !!requestDate && !!this.form.controls.engagementDate.value),
      takeUntil(this.isDestroyed$)
    ).subscribe(requestDate => {
      const tmpDate = requestDate instanceof Date ? requestDate : new Date(requestDate.replace(/-/g, '/'));

      if (!isNaN(tmpDate.getTime())) {
        const engagementDate = this.form.controls.engagementDate.value instanceof Date
          ? this.form.controls.engagementDate.value
          : new Date(this.form.controls.engagementDate.value.replace(/-/g, '/'));

        this.form.controls.timeOnAssignment.patchValue(this.getDateDifferenceByMonths(tmpDate, engagementDate));
      }
    });
  }

  private getDateDifferenceByMonths(date1: Date, date2: Date) {
    const diffTime = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (diffDays / 30).toFixed(1);
  }

  /** NOTE: modal buttons change per 'page' so this is called each 'page' change */
  private setModalButtons() {
    const buttonList = [];

    if (this.currentPage === this.Pages.analysisPage) {
      buttonList.push({
        icon: null,
        tooltip: this.isEnglish ? 'Submit' : 'Soumettre',
        btnType: 'primary',
        action: () => {
          this.submitForm();
        },
        disabled: () => this.formSubmitting
      });
    }

    if (this.currentPage === this.Pages.questionPage) {
      buttonList.push({
        icon: null,
        tooltip: this.isEnglish ? 'Analyze Risk' : 'Analyser le risque',
        btnType: 'primary',
        action: () => {
          this.changePage(1);
        },
        disabled: () => this.form.invalid || this.riskForm.invalid || !this.riskQuestionsAreValid
      });
    }

    if (this.currentPage === this.Pages.formPage) {
      buttonList.push({
        icon: null,
        tooltip: this.isEnglish ? 'Next' : 'Prochain',
        btnType: 'primary',
        action: () => {
          this.changePage(1);
        }
      });
    }

    if (this.currentPage > this.Pages.formPage && this.currentPage < this.Pages.finalPage) {
      buttonList.push({
        icon: null,
        tooltip: this.isEnglish ? 'Previous' : 'Précédent',
        btnType: 'primary',
        action: () => {
          this.changePage(-1);
        },
        disabled: () => this.formSubmitting
      });
    }

    if (this.currentPage === this.Pages.finalPage && this.currentRiskLevel === this.RISK.HIGH) {
      buttonList.push({
        icon: null,
        tooltip: this.isEnglish ? 'Open Legal Hotfix Ticket' : 'Ouvrir le Ticket de Correctif Juridique',
        btnType: 'primary',
        action: () => {
          this.loadAdditionalDocuments.emit();
          this.openHotfix();
        }
      });
    }

    buttonList.push({
      icon: null,
      tooltip: this.currentPage === this.Pages.finalPage ? this.isEnglish ? 'Close' : 'Fermer' : this.isEnglish ? 'Cancel' : 'Annuler',
      btnType: 'default',
      action: () => {
        if (this.currentPage === this.Pages.finalPage) {
          this.loadAdditionalDocuments.emit();
        }
        this.modal.hide();
      },
      disabled: () => this.formSubmitting
    });

    this.buttons = buttonList;
  }

  private resetForm() {
    this.questions.forEach(question => {
      question.options.forEach(option => option.checked = false);
    });

    this.form.patchValue({
      timeOnAssignment: null,
      conversationDate: null,
      conversationMethod: null,
      conversationWith: null,
      conversationRole: null,
      procomProfile: null,
      followUp: null,
      noticePeriodProvided: null,
      noticePeriodProvidedDetails: null,
      offboardingNotes: null,
      classification: this.workerProfileType,
      classificationNotes: null,
      additionalRecipients: null
    });
    this.form.markAsPristine();

    this.riskForm.patchValue({
      FactorsNotes: null,
      MethodNotes: null,
      ReasonNotes: null,
      ConsiderationNotes: null,
    });
  }

  private initRateQuestions() {
    this.questions = [
      {
        form: 'Method',
        question: 'How does the client propose to terminate the contractor?',
        questionFr: 'Comment le client propose-t-il de résilier le contrat de l\'entrepreneur?',
        options: [
          { text: 'Working notice', textFr: 'Avis de travail', risk: this.RISK.LOW, checked: false },
          { text: 'Pay in lieu', textFr: 'Paiement en lieu', risk: this.RISK.LOW, checked: false },
          { text: 'Client requires consultation', textFr: 'Le client nécessite une consultation', risk: this.RISK.LOW, checked: false },
          { text: 'Short notice', textFr: 'Préavis court', risk: this.RISK.MODERATE, checked: false },
          { text: 'Client has already notified or terminated contractor', textFr: 'Le client a déjà informé ou résilié le contrat avec l\'entrepreneur.', risk: this.RISK.MODERATE, checked: false },
          { text: 'For cause/no compensation', textFr: 'Pour cause / sans indemnisation', risk: this.RISK.HIGH, checked: false },
        ],
        otherOption: null
      },
      {
        form: 'Reason',
        question: 'Why does the client want to terminate the contractor?',
        questionFr: 'Pourquoi le client veut-il résilier le contrat de l\'entrepreneur?',
        options: [
          { text: 'Early project completion', textFr: 'Achèvement anticipé du projet', risk: this.RISK.LOW, checked: false },
          { text: 'Budget issues', textFr: 'Le projet prend fin pour des raisons budgétaires.', risk: this.RISK.LOW, checked: false },
          { text: 'Material changes in job duties', textFr: 'Changements matériels dans les fonctions de travail', risk: this.RISK.MODERATE, checked: false },
          { text: 'Performance issues', textFr: 'Problèmes de performance', risk: this.RISK.MODERATE, checked: false },
          { text: 'Code of conduct violations', textFr: 'Violations du Code de conduite', risk: this.RISK.HIGH, checked: false },
          { text: 'Health or safety violations', textFr: 'Violations de la santé ou de la sécurité', risk: this.RISK.HIGH, checked: false },
          { text: 'Other policy violations (include details in Notes)', textFr: 'Autres violations de politique (inclure des détails dans les Notes)', risk: this.RISK.HIGH, checked: false },
        ],
        otherOption: null
      },
      {
        form: 'Consideration',
        question: 'What factors may affect the contractor\'s response to the termination?',
        questionFr: 'Quels facteurs peuvent affecter la réponse de l\'entrepreneur à la résiliation?',
        options: [
          {
            text: 'Contractor resigned from previous employment for this role',
            textFr: 'L\'entrepreneur a démissionné de son emploi précédent pour ce rôle', risk: this.RISK.MODERATE, checked: false
          },
          { text: 'Long tenure (5+ years)', textFr: 'Longue durée (5+ ans)', risk: this.RISK.HIGH, checked: false },
          { text: 'Long tenure (3+ years)', textFr: 'Longue durée (3+ ans)', risk: this.RISK.MODERATE, checked: false },
          { text: 'Workplace dispute', textFr: 'Litige sur le lieu de travail', risk: this.RISK.MODERATE, checked: false },
          { text: 'Timing (around holiday, vacation, etc.)', textFr: 'Calendrier (autour des vacances, des congés, etc.)', risk: this.RISK.MODERATE, checked: false },
          { text: 'Alleged discrimination against client', textFr: 'Présumée discrimination à l\'encontre du client', risk: this.RISK.HIGH, checked: false },
          { text: 'Confrontational or litigious', textFr: 'Confrontationnel ou litigieux', risk: this.RISK.HIGH, checked: false },
          { text: 'Not applicable', textFr: 'Non applicable', risk: this.RISK.NA, checked: false },
        ],
        otherOption: null
      },
      {
        form: 'Factors',
        question: 'What legal factors need to be considered?',
        questionFr: 'Quels facteurs juridiques doivent être pris en compte?',
        options: [
          { text: 'Undeclared time worked', textFr: 'Temps de travail non déclaré', risk: this.RISK.MODERATE, checked: false },
          { text: 'Unresolved financial issues/oustanding payments', textFr: 'Problèmes financiers non résolus / paiements en attente', risk: this.RISK.MODERATE, checked: false },
          { text: 'Protected leave', textFr: 'Congé protégé', risk: this.RISK.HIGH, checked: false },
          { text: 'Not applicable', textFr: 'Non applicable', risk: this.RISK.NA, checked: false }
        ],
        otherOption: null
      }
    ];
  }

  private initRiskSummaries() {
    this.riskSummaries = {
      Low: 'Upon submission, an email notification will be sent to the Job Owner associated with this Work Order, along with Consulting Agreement, Term Request and/or Resume as optional.',
      Moderate: 'Upon submission, an email notification will be sent to the Job Owner and Branch Manager associated with this Work Order, ' +
        'along with Consulting Agreement, Term Request and/or Resume as optional.',
      High: 'Upon submission, an email notification will be sent to Job Owner, Branch Manager and Legal. Once you generate the link to this form after ' +
        'submitting, you can open a Legal Hotfix Ticket in the next step.'
    };

    this.riskSummariesFr = {
      Low: 'Après la soumission, une notification sera envoyée par courriel au responsable du poste associé à ce bon de travail, avec le contrat de consultant, ' +
        'les renseignements sur la demande de licenciement et le CV (qui est facultatif).',
      Moderate: 'Après la soumission, une notification sera envoyée par courriel au directeur de succursale et par courriel au responsable du poste associé à ce ' +
        'bon de travail, avec le contrat de consultant, les renseignements sur la demande de licenciement et le CV (qui est facultatif).',
      High: 'Après la soumission, une notification sera envoyée parpar courriel au directeur de succursale, par courriel au responsable du poste associé à ce ' +
        'bon de travail, et Équipe Juridique, avec le contrat de consultant, les renseignements sur la demande de licenciement et le CV (qui est facultatif). Une fois ' +
        'que vous aurez généré le lien vers ce formulaire à la suite de la soumission, vous pourrez ouvrir un billet Hotfix du Service juridique lors de la prochaine étape.'
    };

  }

  private convertFormToHtml() {
    const htmlStart = '<html><head><style>.color-Low{color:#0f9524;}.color-Moderate{ color:#ed6c02;}.color-High{ color:#d13031;}body{font-family:Arial,Helvetica,' +
      'sans-serif}table{width:100%;margin-bottom:40px}td:first-child{font-weight:600}</style></head><body>';

    const arFormPage = [];
    arFormPage.push(`<div style='font-size:14px'>${this.currentTranslations.confidentialTitle}</div>`);
    arFormPage.push(`<div style='font-size:12px'>${this.currentTranslations.confidentialSubTitle}</div>`);
    arFormPage.push(`<h1>${this.currentTranslations.title}</h1><table><tr><td><table><tr><td colspan="2">`);
    arFormPage.push(`<h3>${this.currentTranslations.requestDetails}</h3></td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.requestDate}:</td><td>${this.datePipe.transform(this.form.controls.requestDate.value, 'YYYY-MM-dd')}</td>`);
    arFormPage.push(`</tr><tr><td>${this.currentTranslations.contractorName}:</td><td>${this.form.controls.contractorName.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.contractorLocation}:</td><td>${this.workerLocationOptions.find(f => f.id === this.form.controls.contractorLocationId.value)?.text}</td>`);
    arFormPage.push(`</tr><tr><td>${this.currentTranslations.clientName}:</td><td>${this.form.controls.clientName.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.clientLocation}:</td><td>${this.clientWorksiteOptions.find(f => f.id === this.form.controls.clientLocationId.value)?.text}</td>`);
    arFormPage.push(`</tr><tr><td>${this.currentTranslations.clientContact}:</td><td>${this.form.controls.clientContactName.value}</td></tr></table><table>`);
    arFormPage.push(`<tr><td colspan="2"><h3>${this.currentTranslations.engagementDetails}</h3></td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.assignmentStartDate}:</td><td>${this.form.controls.engagementDate.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.assignmentLength}:</td><td>${this.form.controls.assignmentLength.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.timeOnAssignment}:</td><td>${this.form.controls.timeOnAssignment.value}</td></tr></table></td><td `);
    arFormPage.push(`style="vertical-align:top"><table><tr><td colspan="2"><h3>${this.currentTranslations.riskReviewWith}</h3></td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.conversationDate}:</td><td>${this.datePipe.transform(this.form.controls.conversationDate.value, 'YYYY-MM-dd')}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.method}:</td><td>${this.form.controls.conversationMethod.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.conversationWith}:</td><td>${this.form.controls.conversationWith.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.conversationRole}:</td><td>${this.form.controls.conversationRole.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.followUp}:</td><td>${this.form.controls.followUp.value ? this.currentTranslations.yes : this.currentTranslations.no}</td>`);

    arFormPage.push(`<tr><td>${this.currentTranslations.noticePeriodProvided}:</td>' + 
    '<td>${this.form.controls.noticePeriodProvided.value ? this.currentTranslations.yes : this.currentTranslations.no}</td>`);
    if (this.form.controls.noticePeriodProvided.value) {
      arFormPage.push(`<tr><td>${this.currentTranslations.noticePeriodProvided}:</td><td>${this.form.controls.noticePeriodProvided.value}</td></tr>`);
    }

    arFormPage.push(`</tr><tr><td>${this.currentTranslations.procomParticipants}:</td><td>${this.form.controls.procomProfile.value}</td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.offboardingNotes}:</td><td>${this.form.controls.offboardingNotes.value || ''}</td></tr></table><table><tr><td colspan="2">`);
    arFormPage.push(`<h3>${this.currentTranslations.contractor}</h3></td></tr>`);
    arFormPage.push(`<tr><td>${this.currentTranslations.classification}:</td>` +
      `<td>${(this.isEnglish ? this.classificationOptions : this.classificationOptionsFr).find(f => f.id === this.form.controls.classification.value)?.value}</td>`);
    arFormPage.push(`</tr><tr><td>${this.currentTranslations.notes}:</td><td>${this.form.controls.classificationNotes.value || ''}</td></tr></table></td></tr></table>`);

    const formPage = arFormPage.join('');

    const questionPage = [];
    this.questions.forEach(question => {
      questionPage.push(`<table><tr><td colspan="3"><h3>${this.isEnglish ? question.question : question.questionFr}</h3></td></tr>`);
      question.options.forEach(option => {
        questionPage.push(`<tr><td style='width:70%'>${this.isEnglish ? option.text : option.textFr}</td><td style='width:10%'>` +
          `<input type='checkbox' disabled='true' ${option.checked ? 'checked' : ''}></td><td style='width:20%' class='color-${option.risk}'>${this.currentTranslations[option.risk]}</td></tr>`);
      });
      if (question.otherOption) {
        questionPage.push(`<tr><td style='width:70%'>${this.currentTranslations.other
          }</td><td style='width:10%'><input type='checkbox' disabled='true' checked></td><td style='width:20%' ` +
          `class='color-${question.otherOption}'>${this.currentTranslations[question.otherOption]}</td></tr>`);
      }
      questionPage.push('</table>');
      questionPage.push(`<p><b>${this.currentTranslations.notes}:</b> ${this.riskForm.get(question.form + 'Notes').value || ''}</p>`);
    });

    const analysisPage = `<p>${this.currentTranslations.assessed}</p><p style='text-align:center;padding:20px 0 20px 40px;font-size:20px;' ` +
      `class='color-${this.currentRiskLevel}'>${this.currentTranslations[this.currentRiskLevel]} ${this.isEnglish ? this.englishTranslations.risk : this.currentRiskLevel === this.RISK.LOW
        ? this.frenchTranslations.risk : ''}</p><p>${this.isEnglish ? this.riskSummaries[this.currentRiskLevel] : this.riskSummariesFr[this.currentRiskLevel]}</p>`;

    const htmlEnd = '</body></html>';

    return `${htmlStart}${formPage}${questionPage.join('')}${analysisPage}${htmlEnd}`;
  }

  private setEnglishTranslations() {
    this.englishTranslations = {
      confidentialTitle: 'CONFIDENTIAL & PRIVILEGED',
      confidentialSubTitle: 'Prepared for Review by Procom Legal Counsel  ',
      title: 'Termination Planning Risk Assessment Form',
      tooltip: 'This document is required when Procom has received a contractor termination request from one of its clients. It must be completed based on discussion ' +
        'with the client before any termination actions take place.',
      risk: 'Risk',
      invalidEmail: 'Please enter valid email.',
      Low: 'Low',
      Moderate: 'Moderate',
      High: 'High',
      NA: 'N/A',

      requestDetails: 'Request Details',
      requestDate: 'Requested Termination Date',
      contractorName: 'Contractor Name',
      contractorLocation: 'Contractor Location',
      clientName: 'Client Name',
      clientLocation: 'Client Location',
      clientContact: 'Client Contact',

      engagementDetails: 'Engagement Details',
      assignmentStartDate: 'Original Start Date',
      assignmentLength: 'Assignment Length (months)',
      timeOnAssignment: 'Time on Assignment (months)',

      riskReviewWith: 'Risk Review with Client',
      conversationDate: 'Conversation Date',
      method: 'Method',
      conversationWith: 'Who did you speak with?',
      conversationRole: 'What is their role?',
      followUp: 'Follow up required?',
      noticePeriodProvided: 'Was a Notice Period provided?',
      noticePeriodProvidedDetails: 'Add Details',
      yes: 'Yes',
      no: 'No',
      procomParticipants: 'Procom Participants',
      offboardingNotes: 'Offboarding Requirements / Notes',

      contractor: 'Contractor',
      classification: 'Classification',
      notes: 'Notes',
      other: 'Other',

      assessed: 'Based on the completed risk assessment, this termination request has been deemed',
      submitted: 'Your Termination Planning Risk Assessment form has been submitted.',
      recipients: 'Send notification to additional recipients',
      selectOne: '(select at least one)',
      includeDetails: '(include details in Notes)',

      selectPlaceholder: '-- Select One --'
    };
  }

  private setFrenchTranslations() {
    this.frenchTranslations = {
      confidentialTitle: 'CONFIDENTIEL & PRIVILÉGIÉ',
      confidentialSubTitle: 'Préparé pour examen par le conseil juridique de Procom',
      title: 'Formulaire de planification de cessation d\'emploi',
      tooltip: 'Le présent document est requis lorsque Procom reçoit d\'un de ses clients une demande de licenciement visant un travailleur contractuel. Le formulaire ' +
        'doit être rempli avec la participation du client avant que toute mesure de licenciement ne soit prise.',
      risk: 'risque',
      invalidEmail: 'Veuillez entrer un email valide.',
      Low: 'faible',
      Moderate: 'modéré',
      High: 'élevé',
      NA: 'S/O',

      requestDetails: 'Détails de la demande',
      requestDate: 'Date de résiliation demandée',
      contractorName: 'Nom du travailleur contractuel',
      contractorLocation: 'Emplacement de l\'entrepreneur',
      clientName: 'Entreprise cliente',
      clientLocation: 'Emplacement du client',
      clientContact: 'Contact Client',

      engagementDetails: 'Détails de l\'engagement',
      assignmentStartDate: 'Date de début de l\'affectation d\'origine',
      assignmentLength: 'Durée de la mission (mois)',
      timeOnAssignment: 'Temps sur la mission (mois)',

      riskReviewWith: 'Examen des risques avec le client',
      conversationDate: 'Date de la conversation d\'examen des risques avec le client',
      method: 'Comment s\'est a déroulé la conversation?',
      conversationWith: 'Nom de la personne-ressource chez le client',
      conversationRole: 'Quel est leur rôle?',
      followUp: 'Suivi nécessaire?',
      noticePeriodProvided: 'Une "période de préavis" a-t-elle été fournie?',
      noticePeriodProvidedDetails: 'Ajouter des détails',
      yes: 'Oui',
      no: 'Non',
      procomParticipants: 'Nom de la personne-ressource chez Procom',
      offboardingNotes: 'Éléments liés au départ ou notes générales [joindre les communications pertinentes du client]',

      contractor: 'Type de mandat du travailleur contractuel',
      classification: 'Type de mandat du travailleur contractuel',
      notes: 'Remarques',
      other: 'Autre',

      assessed: 'Selon l\'évaluation des risques effectuée, ce licenciement est considéré comme présentant un',
      submitted: 'Selon l\'évaluation des risques effectuée, ce licenciement est considéré comme présentant un',
      recipients: 'Envoyez une notification à ces destinataires supplémentaires',
      selectOne: '(sélectionnez au moins un)',
      includeDetails: '(inclure des détails dans les Notes)',

      selectPlaceholder: '-- Sélectionnez --'
    };
  }

}
