
import { takeUntil } from 'rxjs/operators';
// Angular
import { Component, Input, OnInit } from '@angular/core';
// Common
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
// Work order
import { IReadOnlyStorage, ITabCoreCollaborators } from '../../models/workorder.interface';
import { CoreCollaboratorFormService } from '../../services';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { PhxConstants } from 'src/app/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';


@Component({
  selector: 'app-workorder-tab-core-collaborators',
  templateUrl: './workorder-tab-core-collaborators.component.html'
})

export class WorkorderTabCoreCollaboratorsComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  formGroup: FormGroup<ITabCoreCollaborators>;

  html: {
    lists: {
      userProfileAssignedTo: Array<any>;
    };
  } = {
      lists: {
        userProfileAssignedTo: []
      }
    };

  constructor(
    private coreCollaboratorFormService: CoreCollaboratorFormService,
    private commonListsObservableService: CommonListsObservableService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.coreCollaboratorFormService.formGroup;
    this.getUserProfileAssignedTo();
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getUserProfileAssignedTo() {
    this.commonListsObservableService.listUserProfileInternal$().pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((response: any) => {
        if (response) {
          this.html.lists.userProfileAssignedTo = response;
        }
      });
  }

}
