import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IPaymentPartiesRateDetail, IPaymentPartyInfoes, IReadOnlyStorage, IRoot } from '../../models';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { PartyPaymentInfoFormService } from '../../services';

@Component({
  selector: 'app-workorder-payment-parties',
  templateUrl: './workorder-payment-parties.component.html'
})
export class WorkorderPaymentPartiesComponent extends BaseComponentOnDestroy implements OnInit {
  formGroup: FormGroup<IPaymentPartyInfoes>;
  @Input() readOnlyStorage: IReadOnlyStorage;

  constructor(
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
  ) {
    super();
  }

  get paymentPartiesRateDetailFormArray(): FormArray<IPaymentPartiesRateDetail> {
    return this.formGroup.get('PaymentPartiesRateDetails') as FormArray<IPaymentPartiesRateDetail>;
  }

  ngOnInit() {
    this.formGroup = this.partyPaymentInfoFormService.formGroup;
  }

  trackByFn(index: number, item: AbstractControl<IPaymentPartiesRateDetail>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  addPaymentInfo() {
    this.partyPaymentInfoFormService.addPaymentPartiesRateDetailFormGroup();
  }

}
