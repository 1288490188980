import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

import { IFormService } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkOrder, IWorkplaceSafetyInsurance } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { WorkorderService } from '../workorder.service';
import { Validators } from '@angular/forms';

@Injectable()
export class WorkplaceSafetyInsuranceFormService implements IFormService {

  formGroup: FormGroup<IWorkplaceSafetyInsurance>;
  checkEarningvalidate = false;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkorderService
  ) {
  }

  get workerCompensationIdFormControl(): FormControl<number> {
    return this.formGroup.get('WorkerCompensationId') as FormControl<number>;
  }

  get wCBIsAppliedFormControl(): FormControl<boolean> {
    return this.formGroup.get('WCBIsApplied') as FormControl<boolean>;
  }

  get wCBIsAppliedValue(): boolean {
    return this.wCBIsAppliedFormControl.value;
  }

  get workerCompensationIdChanges$() {
    return this.workerCompensationIdFormControl.valueChanges;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<IWorkplaceSafetyInsurance> {
    this.isRootComponentDestroyed$ = isDestroyed$;


    this.formGroup = this.fb.group<IWorkplaceSafetyInsurance>({
      WorkerCompensationId: [
        workorder.WorkOrderVersion.WorkerCompensationId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
          'WorkOrderVersion',
          'WorkerCompensationId',
          this.checkEarningvalidate ?
            [Validators.required] : null
        )
      ],
      WCBIsApplied: [
        workorder.WorkOrderVersion.WCBIsApplied,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
          'WorkOrderVersion',
          'WCBIsApplied',
          this.checkEarningvalidate ?
            [Validators.required] : null
        )
      ],
      WCBPositionTitle: [workorder.WorkOrderVersion.WCBPositionTitle, Validators.maxLength(128)]
    });

    if(workorder.WorkOrderVersion.WorkerLocationId && workorder.OrganizationIdInternal && workorder.workerProfileTypeId && !workorder.WorkOrderVersion.WorkerCompensationId)
    {
      this.setWorkerCompensationByWorkerLocation(workorder.WorkOrderVersion.WorkerLocationId, workorder.OrganizationIdInternal, workorder.workerProfileTypeId);
    }
    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.workerCompensationIdChanges$
      .pipe(takeUntil(this.isRootComponentDestroyed$))
      .subscribe(() => {
        this.setWcbIsAppliedValidators();
        this.wCBIsAppliedFormControl.patchValue(null, { emitEvent: false });
      });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const formGroupSafetyInsuranceValues: IWorkplaceSafetyInsurance = this.formGroup.value;
    workOrder.WorkOrderVersion.WorkerCompensationId = formGroupSafetyInsuranceValues.WorkerCompensationId;
    workOrder.WorkOrderVersion.WCBIsApplied = formGroupSafetyInsuranceValues.WCBIsApplied;
    workOrder.WorkOrderVersion.WCBPositionTitle = formGroupSafetyInsuranceValues.WCBPositionTitle;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    this.workerCompensationIdFormControl.setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
        'WorkOrderVersion',
        'WorkerCompensationId',
        this.checkEarningvalidate ?
          [Validators.required] : null
      )
    );

    this.setWcbIsAppliedValidators();

    this.formGroup.get('WCBPositionTitle').setValidators(Validators.maxLength(128));

    this.formGroup.patchValue({
      WorkerCompensationId: workorder.WorkOrderVersion.WorkerCompensationId,
      WCBIsApplied: workorder.WorkOrderVersion.WCBIsApplied,
      WCBPositionTitle: workorder.WorkOrderVersion.WCBPositionTitle
    }, { emitEvent: false });
  }

  updateWCBIsApplied(value: boolean, emitEvent = false) {
    this.wCBIsAppliedFormControl.patchValue(value, { emitEvent });
  }

  updateWorkerCompensationId(value: number, emitEvent = false) {
    this.workerCompensationIdFormControl.patchValue(value, { emitEvent });
  }

  getValidatorForWorkerCompensation(workOrder: IWorkOrder): Promise<boolean> {
    return new Promise(resolve => {
      const subdivisionId = workOrder.WorkOrderVersion.WorkerLocationId;
      if (!subdivisionId || !workOrder.OrganizationIdInternal) {resolve(false);}
      this.workOrderService.getWCBCodesBySubdivisionId(subdivisionId, workOrder.OrganizationIdInternal)
        .subscribe((response: any) => {
          resolve(Boolean(response?.Items?.length > 0));
        });
    });
  }

  setWorkerCompensationByWorkerLocation(subdivisionId: number, organizationIdInternal: number, workerProfileTypeId: number): void {
    this.workOrderService.getWCBCodesBySubdivisionId(subdivisionId, organizationIdInternal).subscribe((response: any) => {
      if (response?.Items.length === 1) {
        this.updateWorkerCompensationId(response.Items[0].WorkerCompensationId);
        for (const item of response.Items[0].WcbSubdivisionDetailWorkerTypeDefault) {
          if (item.ProfileTypeIdWorker === workerProfileTypeId)
          {
            this.updateWCBIsApplied(true);
            break;
          }
          else
          {
            this.updateWCBIsApplied(false);
          }
        }
      }
      else {
        this.formGroup.patchValue({
          WorkerCompensationId: undefined,
          WCBIsApplied: undefined,
          WCBPositionTitle: undefined
        }, { emitEvent: false });
      }
    });
  }

  private setWcbIsAppliedValidators() {
    this.wCBIsAppliedFormControl.setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
        'WorkOrderVersion',
        'WCBIsApplied',
        this.checkEarningvalidate ?
          [Validators.required] : null
      )
    );
  }

}
