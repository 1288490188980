import { Component, OnInit, OnChanges, Input, EventEmitter, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';

import { each } from 'lodash';
import moment from 'moment';

import { PhxConstants, CustomFieldService, CodeValueService, CommonService, WindowRefService, CodeValueGroups, PhxLocalizationService, DialogService } from '@common';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { NavigationBarItem } from '@common/model';
import { FormBuilder, FormGroup, FormArray } from '@common/ngx-strongly-typed-forms/model';
import { PhoenixCommonModuleResourceKeys } from '@common/PhoenixCommon.module';
import { HashModel } from '@common/utility/hash-model';

import { IFormGroupSetup, POLineNew, WOPOLines, IReadOnlyStorage, IPurchaseOrder, IRoot, IVersion } from '../../models/workorder.interface';
import { WorkorderService } from '../../services';

@Component({
  selector: 'app-purchaseorderlinepopup',
  templateUrl: './purchaseorderlinepopup.component.html',
  styleUrls: ['./purchaseorderlinepopup.component.less']
})
export class PurchaseorderlinepopupComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  readOnlyStorage: IReadOnlyStorage = null;
  purchaseOrder: IPurchaseOrder = null;
  rootFormGroup: FormGroup<IRoot>;
  currentTab: NavigationBarItem;

  html: {
    navigationBarContent: Array<NavigationBarItem>;
    validationMessages: Array<string>;
    versionsOrdered: Array<IVersion>;
    codeValueLists: any;
    commonLists: any;
    access: any;
  } = {
      navigationBarContent: null,
      validationMessages: [],
      versionsOrdered: null,
      codeValueLists: {},
      commonLists: {},
      access: {}
    };
  public routerParams: any;
  public routerState: any;
  @Input() POmodal: PhxModalComponent;
  @Input() LineId = 0;
  @Input() poLineNum: number;
  @Input() poNumber: string;
  @Input() workOderPurchaseOrderLineId: number;
  @Input() AssignmentId: number;
  @Input() workorderId: number;
  @Input() workorderNumber: number;
  @Input() workorderVersion: number;
  @Input() workerProfileId: number;
  @Output() lineSaved = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  public selectedTab: NavigationBarItem = { Id: 1, IsDefault: true, IsHidden: false, Name: 'details', DisplayText: 'Details' };
  purchaseorderForm: FormGroup<POLineNew>;
  lineNew: POLineNew;
  newLine = {} as POLineNew;
  listDepletionOption: Array<any>;
  listCurrency: Array<any>;
  listDepletionGroup: Array<any>;
  purchaseOrderStatuses: Array<any>;
  phxConstants = PhxConstants;
  validationMessages: Array<string> = [];
  isLineId = false;
  formGroupSetup: IFormGroupSetup;
  codeValueGroups = CodeValueGroups;
  totalamountCommited = 0;
  totalamountSpent = 0;
  totalamountReserved = 0;
  totalamountAvailable = 0;
  totalamountAllowed = 0;
  WOLArray: Array<any>;
  WoArrayExists = false;
  rate = 0;
  workunit: string;
  emptyLine: WOPOLines;
  hasPendingRequests: boolean;
  PurchaseOrderLines: Array<any> = [];
  workRateUnitTypes: Array<any>;
  workerName: string;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private workorderService: WorkorderService,
    private customFieldService: CustomFieldService,
    private codevalueservice: CodeValueService,
    private commonservice: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeRef: ChangeDetectorRef,
    private winRef: WindowRefService,
    private localizationService: PhxLocalizationService,
    private commonListsObservableService: CommonListsObservableService
  ) {
    super();
    this.getdepletionOption();
    this.getcurrencyList();
    this.getdepletionGroup();
    this.getstatuslist();
    this.getRateUnitTypesList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.LineId?.currentValue) {
      this.isLineId = true;
      this.LineId = changes.LineId.currentValue;
    }
    if (changes.poLineNum?.currentValue) {
      this.poLineNum = changes.poLineNum.currentValue;
    }
    if (changes.poNumber?.currentValue) {
      this.poNumber = changes.poNumber.currentValue;
    }
    if (changes.AssignmentId?.currentValue) {
      this.AssignmentId = changes.AssignmentId.currentValue;
    }
    if (changes.workorderId?.currentValue) {
      this.workorderId = changes.workorderId.currentValue;
    }
    if (changes.workorderNumber?.currentValue) {
      this.workorderNumber = changes.workorderNumber.currentValue;
    }
    if (changes.workorderVersion?.currentValue) {
      this.workorderVersion = changes.workorderVersion.currentValue;
    }
    if (changes.workerProfileId?.currentValue) {
      this.workerProfileId = changes.workerProfileId.currentValue;
      this.getListUserProfileWorker(this.workerProfileId);
    }
    if (this.isLineId) {

      // ASYNC CALL HERE, POTENTIAL RACE CONDITION
      this.workorderService
        .getByPurchaseOrderLineId(
          this.LineId,
          oreq
            .request()
            .withExpand(['WorkOrderPurchaseOrderLines'])
            .withSelect([
              'Id',
              'StatusId',
              'PurchaseOrderId',
              'PurchaseOrderLineNumber',
              'PurchaseOrderLineReference',
              'StartDate',
              'EndDate',
              'Amount',
              'CurrencyId',
              'IsTaxIncluded',
              'DepletionOptionId',
              'DepletionGroupId',
              'Description',
              'IsDraft',
              'WorkOrderPurchaseOrderLines',
              'LastModifiedDatetime'
            ])
            .url()
        )
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(result => {
          this.lineNew = {
            Amount: result.Items[0].Amount,
            CurrencyId: result.Items[0].CurrencyId,
            DepletionOptionId: result.Items[0].DepletionOptionId,
            DepletionGroupId: result.Items[0].DepletionGroupId,
            Description: result.Items[0].Description,
            EndDate: result.Items[0].EndDate,
            Id: result.Items[0].Id,
            IsDraft: result.Items[0].IsDraft,
            IsTaxIncluded: result.Items[0].IsTaxIncluded,
            PurchaseOrderId: result.Items[0].PurchaseOrderId,
            PurchaseOrderNumber: result.Items[0].PurchaseOrderNumber,
            PurchaseOrderLineReference: result.Items[0].PurchaseOrderLineReference,
            PurchaseOrderTransactions: result.Items[0].PurchaseOrderTransactions,
            StartDate: result.Items[0].StartDate,
            StatusId: result.Items[0].StatusId,
            WorkOrderPurchaseOrderLines: result.Items[0].WorkOrderPurchaseOrderLines,
            CreatedDatetime: null,
            LastModifiedDatetime: result.Items[0].LastModifiedDatetime,
            PurchaseOrderDepletionGroupId: result.Items[0].DepletionGroupId,
            PurchaseOrderLineNumber: result.Items[0].PurchaseOrderLineNumber
          };
          if (this.workOderPurchaseOrderLineId === -1) {
            this.addEmptyPurchaseOrderLine();
          }

          if (this.lineNew.Amount != null) {
            this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
            this.purchaseorderForm = this.formBuilderGroupSetup(this.formGroupSetup, this.lineNew);
            this.amountCalc();
            this.changeRef.detectChanges();
          }
        });
    }
  }

  navigate(item) {
    this.winRef.nativeWindow.open(`/next/workorder/${item.controls.AssignmentId.value}/${item.controls.WorkOrderId.value}/0/core`, '_blank');
  }

  addEmptyPurchaseOrderLine() {
    this.emptyLine = {
      Id: 0,
      AssignmentId: this.AssignmentId,
      WorkOrderId: this.workorderId,
      WorkOrderNumber: this.workorderNumber,
      AmountReserved: 0,
      AmountSpent: 0,
      AmountCommited: 0,
      WorkerName: this.workerName,
      IsTaxIncluded: false,
      SumSalesTaxVersionRate: 0
    };
    this.lineNew.WorkOrderPurchaseOrderLines.push(this.emptyLine);
  }

  getListUserProfileWorker(workerProfileId) {
    this.commonListsObservableService
      .getUserProfileWorkers$(workerProfileId)
      .pipe(
        filter(response => response != null),
        take(1),
        takeUntil(this.isDestroyed$))
      .subscribe((response: any) => {
        if (response.length > 0) {
          this.workerName = response[0].Contact.LastName + ', ' + response[0].Contact.FirstName;
        }
      });
  }

  ngOnInit() {
    this.dialogService.dialogHasOpened$.pipe(this.takeUntilDestroyed).subscribe(() => {
      if (this.lineNew) {
        this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
        this.purchaseorderForm = this.formBuilderGroupSetup(this.formGroupSetup, this.lineNew);
      }
    });
    this.html.navigationBarContent = this.navigationBarContentSetup();
  }

  navigationBarContentSetup(): Array<NavigationBarItem> {
    return [
      {
        Id: 1,
        IsDefault: false,
        IsHidden: false,
        Name: PhxConstants.WorkOrderPurchaseOrderLineModalNavigationName.details,
        DisplayText: 'Details'
      },
      {
        Id: 2,
        IsDefault: true,
        IsHidden: false,
        Name: PhxConstants.WorkOrderPurchaseOrderLineModalNavigationName.workorders,
        DisplayText: 'Work Orders'
      },
      {
        Id: 3,
        IsDefault: false,
        IsHidden: true,
        Name: PhxConstants.WorkOrderPurchaseOrderLineModalNavigationName.documents,
        DisplayText: 'Documents'
      }
    ];
  }

  getdepletionOption() {
    this.listDepletionOption = this.codevalueservice
      .getCodeValues(this.codeValueGroups.PurchaseOrderDepletedOptions, true)
      .map(c => {
        return {
          ...c,
          text: c.id === PhxConstants.DepletionOption.FundsAllocated
            ? this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.yes)
            : c.id === PhxConstants.DepletionOption.Shared
              ? this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.no)
              : c.text
        };
      });
  }

  getcurrencyList() {
    this.listCurrency = this.codevalueservice.getCodeValues(this.codeValueGroups.Currency, true);
  }

  getdepletionGroup() {
    this.listDepletionGroup = this.codevalueservice.getCodeValues(this.codeValueGroups.PurchaseOrderDepletedGroups, true);
  }

  getstatuslist() {
    this.purchaseOrderStatuses = this.codevalueservice.getCodeValues(this.codeValueGroups.PurchaseOrderStatus, true);
  }
  getRateUnitTypesList() {
    this.workRateUnitTypes = this.codevalueservice.getCodeValues(this.codeValueGroups.RateUnit, true);
  }

  /**
   * Method to determine if the po documents can be deleted. In this case, from workorder you are not allowed to delete documents so its always false;
   */
  canDeleteDocument() {
    return false;
  }

  formGroupInitialValues() {
    this.lineNew = {
      Amount: null,
      CurrencyId: null,
      DepletionOptionId: null,
      DepletionGroupId: null,
      Description: null,
      EndDate: null,
      Id: 0,
      IsDraft: true,
      IsTaxIncluded: true,
      PurchaseOrderId: null,
      PurchaseOrderNumber: this.poLineNum,
      PurchaseOrderLineReference: null,
      PurchaseOrderTransactions: null,
      StartDate: null,
      StatusId: 1,
      WorkOrderPurchaseOrderLines: null,
      CreatedDatetime: null,
      LastModifiedDatetime: moment(Date.now()).format('YYYY-MM-DD'),
      PurchaseOrderDepletionGroupId: null,
      PurchaseOrderLineNumber: null
    };
    this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
    this.purchaseorderForm = this.formBuilderGroupSetup(this.formGroupSetup, this.lineNew);
  }

  formBuilderGroupSetup(formGroupSetup: IFormGroupSetup, poform: POLineNew): FormGroup<POLineNew> {
    const formGroup = formGroupSetup.hashModel.getFormGroup<POLineNew>(formGroupSetup.toUseHashCode, 'POLineNew', poform, 0, () =>
      formGroupSetup.formBuilder.group<POLineNew>({
        Amount: [
          poform.Amount,
          [
            Validators.minLength(0),
            Validators.maxLength(9),
            Validators.required
          ]
        ],
        CurrencyId: [poform.CurrencyId, [Validators.required]],
        DepletionOptionId: [poform.DepletionOptionId, [Validators.required]],
        DepletionGroupId: [poform.DepletionGroupId, [Validators.required]],
        Description: [poform.Description],
        EndDate: [poform.EndDate],
        Id: [poform.Id],
        IsDraft: [poform.IsDraft],
        IsTaxIncluded: [poform.IsTaxIncluded, [Validators.required]],
        PurchaseOrderId: [poform.PurchaseOrderId],
        PurchaseOrderNumber: [poform.PurchaseOrderNumber],
        PurchaseOrderLineNumber: [poform.PurchaseOrderLineNumber],
        PurchaseOrderLineReference: [poform.PurchaseOrderLineReference],
        PurchaseOrderTransactions: [poform.PurchaseOrderTransactions],
        StartDate: [poform.StartDate],
        StatusId: [poform.StatusId],
        WorkOrderPurchaseOrderLines: this.WorkorderAddNew(this.formGroupSetup, poform.WorkOrderPurchaseOrderLines),
        CreatedDatetime: [poform.CreatedDatetime],
        LastModifiedDatetime: [poform.LastModifiedDatetime],
        PurchaseOrderDepletionGroupId: [poform.PurchaseOrderDepletionGroupId]
      })
    );
    return formGroup;
  }

  updateModelFromFormGroup(formGroup: FormGroup<POLineNew>) {
    Object.assign(this.newLine, {
      ...formGroup.value
    });
  }

  onClickCancel() {
    this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
    this.purchaseorderForm = this.formBuilderGroupSetup(this.formGroupSetup, this.lineNew);
    this.validationMessages = [];
    this.cancelEvent.emit(this.lineNew.Id);
    this.POmodal.hide();
    this.WoArrayExists = false;
  }

  onClickSubmit() {
    this.updateModelFromFormGroup(this.purchaseorderForm);
    this.lineNew = {
      Amount: this.purchaseorderForm.value.Amount,
      CurrencyId: this.purchaseorderForm.value.CurrencyId,
      DepletionOptionId: this.purchaseorderForm.value.DepletionOptionId,
      DepletionGroupId: this.purchaseorderForm.value.DepletionGroupId,
      Description: this.purchaseorderForm.value.Description,
      EndDate: this.purchaseorderForm.value.EndDate,
      Id: this.purchaseorderForm.value.Id,
      IsDraft: this.purchaseorderForm.value.IsDraft,
      IsTaxIncluded: this.purchaseorderForm.value.IsTaxIncluded,
      PurchaseOrderId: this.purchaseorderForm.value.PurchaseOrderId,
      PurchaseOrderNumber: null,
      PurchaseOrderLineReference: this.purchaseorderForm.value.PurchaseOrderLineReference,
      PurchaseOrderTransactions: this.lineNew.PurchaseOrderTransactions ? this.lineNew.PurchaseOrderTransactions : null,
      StartDate: this.purchaseorderForm.value.StartDate,
      StatusId: this.purchaseorderForm.value.StatusId,
      WorkOrderPurchaseOrderLines: this.purchaseorderForm.value.WorkOrderPurchaseOrderLines,
      CreatedDatetime: null,
      LastModifiedDatetime: null,
      PurchaseOrderDepletionGroupId: this.purchaseorderForm.value.DepletionGroupId,
      PurchaseOrderLineNumber: this.purchaseorderForm.value.PurchaseOrderLineNumber
    };
    this.PurchaseOrderLines.push(this.lineNew);
    this.saveWorkOrderAssoications();
  }

  saveWorkOrderAssoications() {
    this.hasPendingRequests = true;

    let woPoLine: WOPOLines;
    if (this.newLine.WorkOrderPurchaseOrderLines?.length) {
      this.newLine.WorkOrderPurchaseOrderLines.forEach(item => {
        if (item.Id === this.workOderPurchaseOrderLineId || item.Id === 0) {
          woPoLine = item;
          return;
        }
      });
    }

    const command = {
      Id: woPoLine.Id,
      WorkOrderId: woPoLine.WorkOrderId,
      PurchaseOrderLineId: this.lineNew.Id,
      AmountCommited: woPoLine.AmountCommited,
      AllocationNote: woPoLine.AllocationNote
    };
    this.validationMessages = [];
    this.workorderService
      .PONewlineSave(command)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.hasPendingRequests = false;
            this.POmodal.hide();
            this.commonservice.logSuccess('Purchase Order Work Order Association Updated');
            const navigatePath = `/next/workorder/${this.AssignmentId}/${this.workorderId}/${this.workorderVersion}/purchaseorder`;
            this.lineSaved.emit();
            this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent }).catch(() => {});
          }
        },
        error: error => {
          this.hasPendingRequests = false;
          const validationMessages = this.commonservice.parseResponseError(error);
          if (validationMessages.length > 0) {
            validationMessages.forEach(element => {
              this.validationMessages.push(element.Message);
            });
          }
        }
      });
  }

  getMaxLastModified() {
    if (!this.newLine.LastModifiedDatetime) {
      return new Date(Date.now());
    }
    const dates = [this.newLine.LastModifiedDatetime];
    each(this.PurchaseOrderLines, (pol: any) => {
      if (!pol.LastModifiedDatetime || typeof pol.LastModifiedDatetime === 'string') {
        return;
      }
      dates.push(pol.LastModifiedDatetime);
      each(pol.WorkOrderPurchaseOrderLines, (wopol: any) => {
        if (!wopol.LastModifiedDatetime || typeof wopol.LastModifiedDatetime === 'string') {
          return;
        }
        dates.push(wopol.LastModifiedDatetime);
      });
    });
    return new Date(Math.max(...dates.map(date => date.getTime())));
  }

  amountCalc() {
    this.totalamountCommited = 0;
    this.totalamountSpent = 0;
    this.totalamountAllowed = 0;
    this.totalamountReserved = 0;
    this.lineNew.WorkOrderPurchaseOrderLines.forEach(obj => {
      this.totalamountCommited += isNaN(obj.AmountCommited) ? 0 : obj.AmountCommited;
      this.totalamountSpent += isNaN(obj.AmountSpent) ? 0 : obj.AmountSpent;
      this.totalamountAllowed += isNaN(obj.AmountAllowed) ? 0 : obj.AmountAllowed;
      this.totalamountReserved += isNaN(obj.AmountReserved) ? 0 : obj.AmountReserved;
    });
  }

  CommitChange(CalcLine: any) {
    if (this.validationMessages.length > 0) {
      this.validationMessages.splice(1, 1);
    }
    this.validationMessages = [];
    this.totalamountCommited = 0;
    this.totalamountSpent = 0;
    this.totalamountAllowed = 0;
    this.totalamountReserved = 0;
    CalcLine.forEach(element => {
      if (this.validationMessages.length === 0) {
        if (Number(element.value.AmountCommited) - Number(element.value.AmountReserved) - Number(element.value.AmountSpent) < 0) {
          // this.validationMessages.push(element.value.WorkOrderFullNumber + ' - Amount Committed: must be greater than Funds Reserved + Funds Spent');
        }
      }
      this.totalamountCommited += isNaN(Number(element.value.AmountCommited)) ? 0 : Number(element.value.AmountCommited);
      this.totalamountSpent += isNaN(Number(element.value.AmountSpent)) ? 0 : Number(element.value.AmountSpent);
      this.totalamountAllowed += isNaN(Number(element.value.AmountAllowed)) ? 0 : Number(element.value.AmountAllowed);
      this.totalamountReserved += isNaN(Number(element.value.AmountReserved)) ? 0 : Number(element.value.AmountReserved);
    });
    if (this.validationMessages.length === 0) {
      if (Number(this.purchaseorderForm.value.Amount) - this.totalamountCommited < 0) {
        this.validationMessages.push('The amount committed cannot be greater than the funds available');
      }
    }
  }

  WorkorderAddNew(formGroupOnNew: IFormGroupSetup, purschaseOrderNew: Array<WOPOLines>): FormArray<WOPOLines> {
    if (purschaseOrderNew != null) {
      this.rate = 0;
      const ex = formGroupOnNew.formBuilder.array<WOPOLines>(
        purschaseOrderNew.map((woNew: WOPOLines, index) =>
          formGroupOnNew.hashModel.getFormGroup<WOPOLines>(formGroupOnNew.toUseHashCode, 'WOPOLines', woNew, index, () =>
            formGroupOnNew.formBuilder.group<WOPOLines>({
              Id: [woNew.Id],
              WorkOrderId: [woNew.WorkOrderId],
              PurchaseOrderLineId: [woNew.PurchaseOrderLineId],
              PurchaseOrderLineCurrencyId: [woNew.PurchaseOrderLineCurrencyId],
              PurchaseOrderLineStatusId: [woNew.PurchaseOrderLineStatusId],
              IsTaxIncluded: [woNew.IsTaxIncluded],
              SumSalesTaxVersionRate: [woNew.SumSalesTaxVersionRate],
              Amount: [woNew.Amount],
              AmountCommited: [
                woNew.AmountCommited,
                [
                  Validators.minLength(0),
                  Validators.maxLength(9),
                  Validators.required
                ]
              ],
              StatusId: [woNew.StatusId],
              IsDraft: [woNew.IsDraft],
              AllocationNote: [woNew.AllocationNote],
              LastModifiedByProfileId: [woNew.LastModifiedByProfileId],
              LastModifiedByContactName: [woNew.LastModifiedByContactName],
              LastModifiedDatetime: [woNew.LastModifiedDatetime],
              CreatedByProfileId: [woNew.CreatedByProfileId],
              CreatedByContactName: [woNew.CreatedByContactName],
              CreatedDatetime: [woNew.CreatedDatetime],
              AssignmentId: [woNew.AssignmentId],
              WorkOrderStartDate: [woNew.WorkOrderStartDate],
              WorkOrderEndDate: [woNew.WorkOrderEndDate],
              WorkOrderNumber: [woNew.WorkOrderNumber],
              WorkOrderFullNumber: [woNew.WorkOrderFullNumber],
              PurchaseOrderId: [woNew.PurchaseOrderId],
              PurchaseOrderDepletionGroupId: [woNew.PurchaseOrderDepletionGroupId],
              PurchaseOrderDescription: [woNew.PurchaseOrderDescription],
              PurchaseOrderLineEndDate: [woNew.PurchaseOrderLineEndDate],
              PurchaseOrderLineNumber: [woNew.PurchaseOrderLineNumber],
              PurchaseOrderLineStartDate: [woNew.PurchaseOrderLineStartDate],
              PurchaseOrderNumber: [woNew.PurchaseOrderNumber],
              OrganizationId: [woNew.OrganizationId],
              OrganizationLegalName: [woNew.OrganizationLegalName],
              AmountAllowed: [woNew.AmountAllowed],
              AmountReserved: [woNew.AmountReserved],
              AmountSpent: [woNew.AmountSpent],
              AmountTotal: [woNew.AmountTotal],
              AmountRemaining: [woNew.AmountRemaining],
              Rate: [woNew.Rate],
              RateUnitId: [woNew.RateUnitId],
              WorkerName: [woNew.WorkerName]
            })
          )
        )
      );
      if (purschaseOrderNew.length > 0) {
        this.WoArrayExists = true;
      }
      return ex;
    } else {
      return null;
    }
  }

  getUpdatedWorkOrderAssociations(submittedPoLine: any, PurchaseorderID: number) {
    this.WOLArray = [];
    submittedPoLine.forEach(element => {
      const update = {
        AmountCommited: parseFloat(element.AmountCommited),
        PurchaseOrderId: PurchaseorderID,
        Id: element.Id,
        AllocationNote: element.AllocationNote,
        WorkOrderId: parseFloat(element.WorkOrderId)
      };
      this.WOLArray.push(update);
    });
    return this.WOLArray;
  }

  onTabSelected(tab: NavigationBarItem) {
    this.currentTab = tab;
    this.selectedTab.Name = tab.Name;
  }
}
