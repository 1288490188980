<app-phx-modal [title]="title" [buttons]="buttons" (closeModal)="closed()">
  <div [formGroup]="form" *ngIf="form">
    <div *ngIf="detail !==''" class="row div-detail">
      <span>
        <i class="material-icons md-18">clear</i>
        {{detail}}
      </span>
    </div>
    <div class="row">
      <phx-form-control [control]="form.get('OffboardingTaskTypeId')" labelText="Task" [layoutType]="layoutType">
        <div class="div-task-type">
          {{form.get('OffboardingTaskTypeId').value | CodeValue: codeValueGroups.WorkOrderOffboardingTask}}
        </div>
      </phx-form-control>
    </div>
    <div class="row">
      <div class="col-xs-12 divlabel">
        <p>Please put in exemption reason below</p>
        <div *ngIf="form.dirty && form.get('ExemptionReason').errors" class="asterisk error"> * </div>
      </div>
      <phx-form-control [control]="form.get('ExemptionReason')" class="comment" [showValidationMessages]="true"
        [layoutType]="layoutType">
        <textarea [formControl]="form.get('ExemptionReason')" class="form-control" rows="10"
          [class.disabled]="isEditable" placeholder="Minimum of 10 characters"></textarea>
      </phx-form-control>
    </div>
  </div>
</app-phx-modal>
