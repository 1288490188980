<app-phx-modal cssClass="modal-lg" [showHeader]="true">
    <div class="col-md-12 no-padding-h" custom-title>
        Sick Pay Release
    </div>
    <div class="common-headstone-container">
        <div class="headstone-row">
            <div>
                <label>Work Order</label>{{workorderId }}
            </div>
            <div *ngIf="listUserProfileWorker.length">
                <label>Worker</label>
                {{userProfileIdWorker|lookup:listUserProfileWorker:'Id':'Contact.FullName'}}
                -
                {{userProfileIdWorker}} ({{listUserProfileWorker[0].ProfileTypeId|lookup:profileTypeList:'id':'text'}})
            </div>
            <div *ngIf="organizationInternalList.length">
                <label>Internal Company</label>{{internalCompany}}
            </div>
        </div>
    </div>
    <div class="form-container">
        <form [formGroup]="form">
            <div class="content-group form-horizontal form-row">
                <phx-form-control [control]="form.controls.unitsToRelease" [editable]="true"
                    labelText="Sick Pay to Release" [viewModeText]="form.controls.unitsToRelease.value">
                    <div class="flex-row">
                        <input [formControl]="form.controls.unitsToRelease" type="text"
                            class="form-control count-input" phxFloatBetweenInput='{"from":0, "to":9999999.99, "decimalplaces":2}' />
                        <div class="flex-row">
                            <div class="at-container"> &#64; </div>
                            <div class="rate-container">
                                {{ currentRate | currency:PhxConstants.Currency[currentCurrencyRateId] }}/{{ currentRateUnitId | CodeValue:CodeValueGroups.RateUnit }} =
                            </div>
                            <div><input type="text" disabled="true" class="total-text-box" [value]="currentTotal">
                                {{PhxConstants.Currency[currentCurrencyRateId]}}</div>
                        </div>
                    </div>
                </phx-form-control>
                <phx-form-control [control]="form.controls.fromDate" [editable]="true" labelText="Work Period From"
                    [viewModeText]="form.controls.fromDate.value | date: PhxConstants.DateFormat.mediumDate">
                    <app-phx-date-box
                        type="date"
                        [displayFormat]="{ dateStyle: 'medium' }"
                        [formControl]="form.controls.fromDate"
                    ></app-phx-date-box>
                </phx-form-control>
                <phx-form-control [control]="form.controls.toDate" [editable]="true" labelText="Work Period To"
                    [viewModeText]="form.controls.toDate.value | date: PhxConstants.DateFormat.mediumDate">
                    <app-phx-date-box
                        type="date"
                        [displayFormat]="{ dateStyle: 'medium' }"
                        [formControl]="form.controls.toDate"
                    ></app-phx-date-box>
                </phx-form-control>
            </div>
        </form>
    </div>
    <div class="row margin-top-30">
        <div *ngIf="validationMessages?.length" class="alert alert-danger" role="alert">
            <div *ngFor="let message of validationMessages;">{{ message }}</div>
        </div>
    </div>
    <div class="common-footer-container">
        <button class="btn btn-default" (click)="onCancel()" [disabled]="isSubmitting">Cancel</button>
        <button class="btn btn-primary" (click)="onSubmit()" [disabled]="form.invalid || isSubmitting">Release Sick
            Pay</button>
    </div>
</app-phx-modal>
