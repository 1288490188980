import { Component, Input, OnInit } from '@angular/core';
import { IRateNegotiation, IReadOnlyStorage } from '../../models';
import { PartyRateNegotiationFormService } from '../../services';
import { CodeValueService } from 'src/app/common';
import { FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { CodeValue, CodeValueGroups, PhxConstants } from 'src/app/common/model';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-rate-negotiation',
  templateUrl: './workorder-rate-negotiation.component.html',
  styleUrls: ['./workorder-rate-negotiation.component.less']
})
export class WorkorderRateNegotiationComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  formGroup: FormGroup<IRateNegotiation>;

  codeValueGroups = CodeValueGroups;

  html: {
    lists: {
      profileTypes: CodeValue[]
    },
    enable: boolean
  } = {
      lists: {
        profileTypes: []
      },
      enable: true
    };

  constructor(
    private codeValueService: CodeValueService,
    private rateNegotiationFormService: PartyRateNegotiationFormService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.rateNegotiationFormService.formGroup;

    const allowedProfileTypes = [
      this.phxConstants.UserProfileType.WorkerTemp,
      this.phxConstants.UserProfileType.WorkerCanadianSp,
      this.phxConstants.UserProfileType.WorkerCanadianInc
    ];

    this.html.lists.profileTypes = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true)
      .filter(x => allowedProfileTypes.includes(x.id));
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }
}
