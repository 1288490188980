<button
  *ngIf="showAddButton"
  [disabled]="!editable || isBulkUploadInProgress"
  class="btn btn-default"
  (click)="showModal()"
>
  {{ 'common.phxDocumentFileUpload.addBtn' | phxTranslate }}
</button>

<ng-template #fileUploadTemplate>
  <div class="file-upload-modal file-upload-container">
    <div class="modal-content">
      <div class="modal-header header">
        <h4>{{ 'common.phxDocumentFileUpload.title' | phxTranslate }}</h4>
        <button
          *ngIf="!isBulkUploadInProgress"
          class="btn btn-material btn-borderless pull-right close-container"
          (click)="close()"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
      <div
        class="modal-body"
        style="display: flex"
        ng2FileDrop
        (fileOver)="fileOverBase($event)"
        [uploader]="uploader"
        [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
      >
        <div [ngClass]="insideModal ? '' : 'container'">
          <div
            *ngIf="isReplaceAndMergeEnabled"
            class="alert-container"
          >
            <div class="alert alert-warning"><mat-icon>info</mat-icon> Documents of the same type will be combined.</div>
          </div>
          <div class="row message-header text-center">
            <div class="col-md-12">
              <span [innerHTML]="configuration?.UploadTitle"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <label
                class="btn btn-default btn-file"
                [class.disabled-label]="isBulkUploadInProgress"
              >
                {{ 'common.phxDocumentFileUpload.addBtn' | phxTranslate }}
                <input
                  type="file"
                  class="hidden"
                  ng2FileSelect
                  [uploader]="uploader"
                  multiple
                  [disabled]="isBulkUploadInProgress"
                />
              </label>
              <button
                class="btn btn-primary margin-10-l"
                (click)="upload()"
                [disabled]="disableUploadButton || !uploader?.queue?.length || isBulkUploadInProgress"
              >
                {{ 'common.phxDocumentFileUpload.uploadBtn' | phxTranslate }}
              </button>
            </div>
            <div
              class="col-md-12 text-center tertiary-action"
              *ngIf="configuration?.customUiConfig?.tertiaryAction"
            >
              <button
                class="btn btn-link"
                (click)="configuration.customUiConfig.tertiaryAction.action()"
                [disabled]="configuration.customUiConfig.tertiaryAction.disabled()"
              >
                {{ configuration.customUiConfig.tertiaryAction.text }}
              </button>
            </div>
          </div>
          <div
            class="row message-accepted-file-types text-center"
            *ngIf="configuration?.SupportedFileExtensions"
          >
            <div class="col-md-12">
              <div>{{ 'common.phxDocumentFileUpload.acceptedFileTypesLabel' | phxTranslate }}: {{ configuration?.SupportedFileExtensions }}</div>
            </div>
            <div
              class="row"
              *ngIf="configuration && uploader.queue.length > configuration?.limitMultiFileUploads"
            >
              <strong class="error text-danger"> Only {{ configuration.limitMultiFileUploads }} file(s) can be uploaded at a time </strong>
            </div>
            <div
              class="row"
              *ngFor="let message of validationMessages"
            >
              <strong class="error text-danger">{{ message }}</strong>
            </div>
          </div>
          <section
            class="files-container"
            *ngIf="uploader.queue.length"
          >
            <table class="table">
              <thead>
                <tr>
                  <th class="col-md-6">
                    {{ 'common.phxDocumentFileUpload.nameColumnHeader' | phxTranslate }}
                  </th>
                  <th class="col-md-2">
                    {{ 'common.phxDocumentFileUpload.sizeColumnHeder' | phxTranslate }}
                  </th>
                  <th class="col-md-2">
                    {{ 'common.phxDocumentFileUpload.progressColumnHeader' | phxTranslate }}
                  </th>
                  <th class="col-md-1">
                    {{ 'common.phxDocumentFileUpload.statusColumnHeader' | phxTranslate }}
                  </th>
                  <th class="col-md-1">
                    {{ 'common.phxDocumentFileUpload.actionsColumnHeader' | phxTranslate }}
                  </th>
                </tr>
              </thead>
              <tbody *ngFor="let item of uploader.queue; let idx = index">
                <tr>
                  <td>
                    <i class="material-icons">insert_drive_file</i>
                    <div class="file-name">
                      <strong>{{ item?.file?.name }}</strong>
                    </div>
                  </td>
                  <td class="no-wrap">
                    {{ item?.file?.size / 1024 / 1024 | number : '.2' }}
                    {{ 'common.phxDocumentFileUpload.megabyteShort' | phxTranslate }}
                  </td>
                  <td>
                    <mat-progress-bar
                      *ngIf="!item.isUploaded"
                      mode="determinate"
                      class="no-margin"
                      [value]="item.progress"
                    >
                    </mat-progress-bar>
                  </td>
                  <td>
                    <span *ngIf="item.isSuccess">
                      <i class="material-icons">check</i>
                    </span>
                    <span *ngIf="item.isCancel">
                      <i class="material-icons">cancel</i>
                    </span>
                    <span *ngIf="item.isError">
                      <i class="material-icons text-red">error</i>
                    </span>
                  </td>
                  <td class="no-wrap">
                    <button
                      *ngIf="!item.isUploaded && !isBulkUploadInProgress"
                      type="button"
                      class="btn btn-default btn-material btn-borderless"
                      (click)="removeItem(item, idx)"
                      [title]="'common.phxDocumentFileUpload.remove' | phxTranslate"
                    >
                      <i class="material-icons">delete</i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="item.isTampered">
                  <td>
                    E-signed document is tampered. Please discard this document and upload another.
                    <button
                      class="btn btn-default"
                      (click)="discardDocument(item)"
                    >
                      Discard
                    </button>
                  </td>
                </tr>
                <tr *ngIf="item.isUnverified">
                  <td>
                    This E-signed document is unverified. Do you want to continue uploading the document?
                    <button
                      class="btn btn-default btn-warning"
                      (click)="continueDocument(item)"
                    >
                      Continue
                    </button>
                    <button
                      class="btn btn-default"
                      (click)="discardDocument(item)"
                    >
                      Discard
                    </button>
                  </td>
                </tr>
                <tr *ngIf="configuration?.customUiConfig?.objectDate">
                  <td colspan="2">
                    <div class="form-group">
                      <label
                        [for]="dateFieldId"
                        class="col-lg-3 control-label label-to-right"
                        [ngClass]="{'req-lab-right': configuration?.customUiConfig?.objectDate?.isRequired && !configuration.customUiConfig.objectDate.value}"
                      >
                        {{ configuration.customUiConfig.objectDate.label }}
                      </label>
                      <div class="col-lg-4 d-block">
                        <app-phx-date-box
                          [id]="dateFieldId"
                          type="date"
                          [required]="configuration?.customUiConfig?.objectDate?.isRequired"
                          (valueChanged)="configuration?.customUiConfig.objectDate.value = $event; handleStartUploadButton()"
                        >
                        </app-phx-date-box>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="configuration?.customUiConfig?.objectComment">
                  <td colspan="2">
                    <div class="form-group">
                      <label
                        [for]="commentFieldId"
                        class="col-lg-3 control-label label-to-right"
                        [ngClass]="{ 'req-lab-right': configuration.customUiConfig.objectComment.isRequired &&
                            (!configuration.customUiConfig.objectComment.value?.trim() ||
                              configuration.customUiConfig.objectComment.value.length < configuration.customUiConfig.objectComment.minlength ||
                          configuration.customUiConfig.objectComment.value.length > configuration.customUiConfig.objectComment.maxlength)}"
                      >
                        {{ configuration.customUiConfig.objectComment.label }}
                      </label>
                      <div class="col-lg-4">
                        <input
                          [id]="commentFieldId"
                          class="form-control"
                          name="objectComment"
                          maxlength="{{ configuration.customUiConfig.objectComment.maxlength }}"
                          minlength="{{ configuration.customUiConfig.objectComment.minlength }}"
                          required="configuration.customUiConfig.objectComment.isRequired"
                          [(ngModel)]="configuration.customUiConfig.objectComment.value"
                          (ngModelChange)="handleStartUploadButton()"
                        />
                        <span class="help-block">{{ configuration.customUiConfig.objectComment.helpBlock }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="configuration?.customUiConfig?.objectDocumentType">
                  <td colspan="2">
                    <div class="form-group">
                      <label
                        class="col-lg-3 control-label label-to-right"
                        [ngClass]="
                          configuration.customUiConfig.objectDocumentType.isRequired && configuration.customUiConfig.objectDocumentType.value === null && !item.DocumentTypeId ? 'req-lab-right' : ''
                        "
                        for="documentTypeSelectBox"
                      >
                        {{ configuration.customUiConfig.objectDocumentType.label }}
                      </label>
                      <div class="col-lg-4">
                        <app-phx-select-box
                          id="documentTypeSelectBox"
                          [items]="docTypeList"
                          textField="text"
                          [(ngModel)]="item.DocumentTypeId"
                          valueField="id"
                        >
                        </app-phx-select-box>
                        <span class="help-block">{{ configuration?.customUiConfig.objectDocumentType.helpBlock }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="configuration?.documentSubTypes?.length > 1">
                  <td colspan="2">
                    <div class="form-group">
                      <label
                        class="col-lg-3 control-label label-to-right"
                        [ngClass]="{
                          'req-lab-right': configuration.customUiConfig.complianceDocumentSubTypes[idx].value === null && !configuration.customUiConfig.complianceDocumentSubTypes[idx].value
                        }"
                      for="complianceDocumentSubType{{idx}}"
                      >
                        {{ configuration.customUiConfig.complianceDocumentSubTypes[idx].label }}
                      </label>
                      <div class="col-lg-4 d-block">
                        <app-phx-select-box
                        id="complianceDocumentSubType{{idx}}"
                          [items]="configuration.documentSubTypes"
                          textField="name"
                          [(ngModel)]="configuration.customUiConfig.complianceDocumentSubTypes[idx].value"
                          valueField="id"
                          (ngModelChange)="handleStartUploadButton()"
                          [disabled]="item.isUploaded || isBulkUploadInProgress"
                        >
                        </app-phx-select-box>
                        <span class="help-block">{{ configuration?.customUiConfig.complianceDocumentSubTypes.helpBlock }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!isReplaceAndMergeEnabled">
              {{ 'common.phxDocumentFileUpload.progressLabel' | phxTranslate }}:
              <mat-progress-bar
                mode="determinate"
                [value]="uploader.progress"
              >
              </mat-progress-bar>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-phx-document-file-upload-preview-modal
  (uploadCurrentFile)="uploadAfterPreview($event)"
  (getNewFile)="replacePreviewDocument()"
></app-phx-document-file-upload-preview-modal>
