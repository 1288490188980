import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, forkJoin, lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { find, forEach, filter as lodashFilter, cloneDeep } from 'lodash';

// TODO: this import couples the service with the commission module, we should move this to a shared service or find a better way to handle this
import { CommissionPickerInputDto, CommissionPickerSummaryDto } from '@commission/model';
// TODO: this import couples the service with the commission module, we should move this to a shared service or find a better way to handle this
import { CommissionPickerService } from '@commission/services';
import { ApiService, CommonService, DialogService, LoadingSpinnerService, PhxConstants, PhxLocalizationService } from '@common';
import { ICommonListsItem } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { CommandResponse } from '@common/model';
import { AuthService } from '@common/services/auth.service';
import { environment } from '@environment';
// TODO: this import couples the service with the organization module, we should move this to shared or find a better way to handle this
import { IOrganization } from '@organization/models';

import { WorkOrderCommissionPickerService } from './commission-picker/workorder-commission-picker.service';
import { WorkorderService } from './workorder.service';
import { WorkOrdernWorkflowComponent } from '../components/workorder-workflow/workorder-workflow.component';
import { ControlFieldAccessibility } from '../control-field-accessibility';
import { IWorkOrder, IAssignmentDto, IWorkOrderVersion, IWorkOrderResult, IWorkOrderVersionCommission, IJobOwner } from '../models';

const isDebugMode = true;

interface IGetWorkOrderParams {
  assignment?: IAssignmentDto;
  // routerParams?: any;
  workerProfileTypes: any;
  Templates?: any;
  templateId?: any;
}

@Injectable()
export class WorkOrderDataService {
  private showTemplate: boolean;
  private workOrders: Array<IWorkOrderResult> = [];

  userProfileCommissionsList: Array<ICommonListsItem> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthService,
    private locationService: Location,
    public commonService: CommonService,
    private dialogService: DialogService,
    private locale: PhxLocalizationService,
    public workOrderService: WorkorderService,
    private loadingSpinnerService: LoadingSpinnerService,
    private commonListsObservableService: CommonListsObservableService,
    private commissionPickerService: CommissionPickerService,
    private workOrderCommissionPickerService: WorkOrderCommissionPickerService
  ) {
    this.commonListsObservableService.listUserProfileInternalCommission$(true).subscribe(data => {
      this.userProfileCommissionsList = data;
    });
  }

  loadWorkOrder(routerParams: any, showTemplate: boolean, isDestroyed$: Subject<boolean>, showLoader = true): Observable<IWorkOrderResult> {
    this.showTemplate = showTemplate;
    const entityId = +(showTemplate ? routerParams.templateId : routerParams.versionId);

    return new Observable<IWorkOrderResult>(observer => {
      const res = this.workOrders.find(x => x.Id === entityId);
      if (res) {
        observer.next(res);
        observer.complete();
      } else {
        this.preloadCommonList();
        forkJoin([
          this.getEntity(routerParams, showTemplate).pipe(
            switchMap(workOrder => this.commonListsObservableService.getUserProfileWorkers$(
              workOrder.UserProfileIdWorker, showLoader)
              .pipe(
                filter(response => response != null),
                take(1),
                map(response => {
                  return { workOrder, listWorker: response };
                })
              ))),
          this.authService.getCurrentProfile()
            .pipe(
              filter(response => response != null),
              take(1)
            )
        ]).pipe(takeUntil(isDestroyed$))
          .subscribe((results: Array<any>) => {
            const assignment: any = results[0].workOrder;
            const workerProfileTypes = results[0].listWorker;

            WorkOrdernWorkflowComponent.currentProfile = results[1];
            const valid = this.authenticateRoute(assignment, routerParams, showTemplate);
            if (!valid) {
              this.router.navigate(['/home'], { relativeTo: this.activatedRoute.parent });
              this.commonService.logError('Cannot match any routes');
            }

            const workOrder = showTemplate
              ? {
                ...this.getWorkOrder(
                  {
                    Templates: assignment,
                    templateId: entityId,
                    workerProfileTypes
                  },
                  true
                )
              }
              : {
                ...this.getWorkOrder(
                  {
                    assignment,
                    // routerParams: routerParams,
                    workerProfileTypes
                  },
                  false
                )
              };


            const result: IWorkOrderResult = {
              Id: entityId,
              WorkOrder: workOrder,
              WorkerProfiles: workerProfileTypes
            };

            this.workOrders.push(result);

            this.apiService.entitySubscribe(PhxConstants.EntityType.WorkOrder, entityId, (command) => {
              this.apiService.entityUnsubscribe(PhxConstants.EntityType.WorkOrder, entityId);
              if (!command.IsOwner && this.locationService.path().includes('workorder/' + entityId)) {
                this.dialogService.notify('Work Order Updated', this.locale.translate('common.dialog.notifyPageUpdated')).then(() => {
                  this.removeItem(entityId);
                });
              } else if (!command.IsOwner) {
                this.removeItem(entityId);
              }
            });

            if (workOrder && !showTemplate && (+routerParams.assignmentId <= 0 || +routerParams.workorderId <= 0 || +routerParams.versionId <= 0)) {
              this.router.navigate(['/next', 'workorder', workOrder.AssignmentId, workOrder.WorkOrderId, workOrder.WorkOrderVersion.Id, 'core']).then(() => {
                observer.next(result);
                observer.complete();
              });
            } else {
              observer.next(result);
              observer.complete();
            }
          }, error => observer.error(error));
      }
    });
  }

  updateWorkOrder(workOrder: IWorkOrder) {
    const entityId = +(this.showTemplate ? workOrder.TemplateId : workOrder.WorkOrderVersion.Id);
    const index = this.workOrders.findIndex(x => x.Id === entityId);

    if (index !== -1) {
      this.workOrders[index] = {
        ...this.workOrders[index],
        WorkOrder: workOrder
      };
    }
  }

  getCachedWorkOrder(versionId: number, templateId: number) {
    const entityId = +(this.showTemplate ? templateId : versionId);
    const res = this.workOrders.find(x => x.Id === entityId);
    return res ? res.WorkOrder : null;
  }

  updateWorkOrderValidationError(workOrder: IWorkOrder, apiError: CommandResponse) {
    const entityId = +(this.showTemplate ? workOrder.TemplateId : workOrder.WorkOrderVersion.Id);
    const index = this.workOrders.findIndex(x => x.Id === entityId);

    if (index !== -1) {
      const result = this.workOrders[index];

      this.workOrders[index] = {
        ...result,
        WorkOrder: {
          ...result.WorkOrder,
          WorkorderValidationErrors: apiError
        }
      };
    }
  }

  clear() {
    this.workOrders = [];
  }

  public getBranchById(branchId: number) {
    return this.apiService.query('branch?id=' + branchId, false);
  }

  async onChangeSalesPattern(workOrder: IWorkOrder, salesPatternId: number) {
    if (salesPatternId) {
      this.loadingSpinnerService.show();
      try {
        const response: any = await firstValueFrom(this.workOrderService
          .getSalesPattern(salesPatternId));

        if (response.Items?.length > 0) {
          const resp = response.Items[0];
          const jobOwner = resp.CommissionSalesPatternSupporters
            .map(obj => {
              return { UserProfileIdSales: obj.UserProfileId, CommissionRoleId: obj.CommissionRoleId, FullName: obj.FullName };
            })
            .find(obj => {
              return obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleWithSupport || obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleNoSupport;
            });

          const supportingJobOwners = resp.CommissionSalesPatternSupporters
            .map(obj => {
              return { UserProfileIdSales: obj.UserProfileId, CommissionRoleId: obj.CommissionRoleId, FullName: obj.FullName };
            })
            .filter(obj => {
              return obj.CommissionRoleId === PhxConstants.CommissionRole.SupportingJobOwner;
            });

          const jobOwnerUsesSupport = jobOwner ? jobOwner.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleWithSupport || supportingJobOwners.length > 0 : null;

          workOrder.WorkOrderVersion.JobOwner = jobOwner;
          workOrder.WorkOrderVersion.SupportingJobOwners = supportingJobOwners;
          workOrder.WorkOrderVersion.JobOwnerUsesSupport = jobOwnerUsesSupport;

          workOrder = await this.getCommissionRates(workOrder);
          this.updateWorkOrder(workOrder);
        }

      } finally {
        this.loadingSpinnerService.hide();
      }
    } else {
      workOrder = await this.updateCommissionRate(workOrder, true);
    }

    return workOrder;
  }

  async updateCommissionRate(workOrder: IWorkOrder, override = false) {
    if (override || workOrder.readOnlyStorage.IsCommissionsEditable || workOrder.combinedAvailableStateActions.includes(PhxConstants.StateAction.WorkOrderVersionFinalize)) {
      this.loadingSpinnerService.show();
      workOrder = await this.getCommissionRates(workOrder);
      this.loadingSpinnerService.hide();
      this.updateWorkOrder(workOrder);
    }
    return workOrder;
  }

  async getCommissionRates(workOrder: IWorkOrder): Promise<IWorkOrder> {
    const jobOwner = workOrder.WorkOrderVersion.JobOwner;
    const supportOwners = workOrder.WorkOrderVersion.SupportingJobOwners;
    const recruiters = workOrder.WorkOrderVersion.Recruiters;
    const nationalAccountManagers = workOrder.WorkOrderVersion.WorkOrderVersionCommissions.filter(wovc => wovc.CommissionRoleId === PhxConstants.CommissionRole.NationalAccountsRole);
    const branchManagers = workOrder.WorkOrderVersion.WorkOrderVersionCommissions.filter(wovc => wovc.CommissionRoleId === PhxConstants.CommissionRole.BranchManagerRole);

    if (this.canViewCommissionRates(workOrder)) {

      const isNewAssignment = workOrder.RootObject.StatusId === PhxConstants.AssignmentStatus.Onboarding;
      const commissionPickerInputDto: CommissionPickerInputDto = {
        UserProfileIdJobOwner: jobOwner ? jobOwner.UserProfileIdSales : null,
        UserProfileIdSupportingJobOwners: supportOwners.map(c => c.UserProfileIdSales),
        UserProfileIdRecruiters: recruiters.map(c => c.UserProfileIdSales),
        UserProfileIdNationalAccountManagers: isNewAssignment ? [] : nationalAccountManagers.map(nam => nam.UserProfileIdSales),
        UserProfileIdBranchManagers: isNewAssignment ? [] : branchManagers.map(bm => bm.UserProfileIdSales),
        JobOwnerUsesSupport: workOrder.WorkOrderVersion.JobOwnerUsesSupport,
        OrganizationIdInternal: workOrder.OrganizationIdInternal,
        OrganizationIdClient: workOrder.WorkOrderVersion.BillingInfoes?.length
          ? workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient
          : null,
        BranchId: workOrder.WorkOrderVersion.InternalOrganizationDefinition1Id,
        LineOfBusinessId: workOrder.WorkOrderVersion.LineOfBusinessId
      };

      const summaries = await lastValueFrom(this.workOrderService.getWorkOrderVersionCommissionPicker(commissionPickerInputDto));

      this.setCommissionNewList(summaries || [], workOrder);

    } else {
      const summaries = cloneDeep(workOrder.WorkOrderVersion.WorkOrderVersionCommissions);
      const newList = [];

      const newItem = this.getNewSummary(workOrder, summaries, this.mapToCommissionPickerDto(jobOwner ? [jobOwner] : []), {
        IsApplicable: true,
        CommissionRates: [],
        CommissionRateHeaderId: null,
        CommissionRoleId: supportOwners.length > 0 ? PhxConstants.CommissionRole.JobOwnerRoleWithSupport : PhxConstants.CommissionRole.JobOwnerRoleNoSupport
      });

      newList.push(...newItem);
      newList.push(...this.getNewSummary(workOrder, summaries, this.mapToCommissionPickerDto(supportOwners), {
        IsApplicable: true,
        CommissionRates: [],
        CommissionRateHeaderId: null
      }));
      newList.push(...this.getNewSummary(workOrder, summaries, this.mapToCommissionPickerDto(recruiters), {
        IsApplicable: true,
        CommissionRates: [],
        CommissionRateHeaderId: null
      }));

      workOrder.WorkOrderVersion.WorkOrderVersionCommissions = newList;

    }

    return workOrder;
  }

  setCommissionNewList(summaries: CommissionPickerSummaryDto[] = [], workOrder: IWorkOrder): IWorkOrder {

    const filterByCommissionRole = (role: PhxConstants.CommissionRole) => {
      return summaries.filter(obj => obj.CommissionRoleId === role);
    };

    const jobOwner = summaries.filter(obj => {
      return obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleNoSupport || obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleWithSupport;
    });
    const support = filterByCommissionRole(PhxConstants.CommissionRole.SupportingJobOwner);
    const recruiters = filterByCommissionRole(PhxConstants.CommissionRole.RecruiterRole);
    const nationalAccounts = filterByCommissionRole(PhxConstants.CommissionRole.NationalAccountsRole);
    const branchManagers = filterByCommissionRole(PhxConstants.CommissionRole.BranchManagerRole);
    const summariesList = cloneDeep(workOrder.WorkOrderVersion.WorkOrderVersionCommissions) || [];

    const newList = [];
    newList.push(...this.getNewSummary(workOrder, summariesList, jobOwner));
    newList.push(...this.getNewSummary(workOrder, summariesList, support));
    newList.push(...this.getNewSummary(workOrder, summariesList, recruiters));
    newList.push(...this.getNewSummary(workOrder, summariesList, nationalAccounts));
    newList.push(...this.getNewSummary(workOrder, summariesList, branchManagers));
    workOrder.WorkOrderVersion.WorkOrderVersionCommissions = newList;

    return workOrder;
  }

  canViewCommissionRates(workOrder: IWorkOrder): boolean {
    return workOrder.WorkOrderVersion.InternalOrganizationDefinition1Id > 0
      && workOrder.WorkOrderVersion.LineOfBusinessId > 0
      && workOrder.OrganizationIdInternal > 0
      && workOrder.WorkOrderVersion.BillingInfoes?.length
      && workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient > 0;
  }

  getNewSummary(
    workOrder: IWorkOrder,
    existingSummaries: IWorkOrderVersionCommission[],
    commissionPickerResults: CommissionPickerSummaryDto[],
    reset: Partial<IJobOwner> = {}
  ): IWorkOrderVersionCommission[] {

    return this.workOrderCommissionPickerService.getNewSummary(
      existingSummaries,
      commissionPickerResults,
      workOrder.RootObject.StatusId,
      workOrder.StatusId,
      workOrder.PreviousCommissions.map(x => x.CommissionRateHeaderId).filter(x => x != null),
      this.userProfileCommissionsList,
      reset
    );
  }

  mapToCommissionPickerDto(value: IJobOwner[]): CommissionPickerSummaryDto[] {
    return value.map(x => {
      return {
        UserProfileId: x.UserProfileIdSales,
        CommissionRoleId: x.CommissionRoleId,
        IsApplicable: x.IsApplicable,
        CommissionRates: x.CommissionRates?.map(r => {
          return {
            CommissionRateHeaderId: r.CommissionRateHeaderId,
            CommissionRateHeaderStatusId: r.CommissionRateHeaderStatusId,
            Description: r.Description,
            CommissionRateVersions: r.CommissionRateVersions || []
          };
        })
      };
    });
  }

  getOrganizationLatestVersion(id: number, showSpinner = true): Observable<IOrganization> {
    return this.apiService.httpGetRequest<IOrganization>(`organization/${id}/latest-relevant-version`, environment.organizationServiceApiEndpoint, showSpinner);
  }

  getFeatureConfiguration<T>(documentType: string, groupName: string): Observable<T | null> {
    const adminApiEndpoint = environment.adminServiceApiEndpoint;
    const path = this.getFeatureConfigurationPath(documentType, groupName);

    return this.apiService.httpGetRequest<Array<{ GroupName: string; DocumentType: string; Data: T }>>(path, adminApiEndpoint).pipe(
      map(responseArray => {
        const config = responseArray.find(
          item => item.GroupName === groupName && item.DocumentType === documentType
        );

        return config ? this.transformToCamelCase(config.Data) as T : null;
      })
    );
  }

  private transformToCamelCase(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(item => this.transformToCamelCase(item));
    }
  
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      acc[camelCaseKey] = this.transformToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }

  private getFeatureConfigurationPath(documentType: string, groupName: string): string {
    return `GlobalConfiguration/${documentType}/${groupName}`;
  }

  /**
   * pre-load common list
   * will not call API if it's still cached
   */
  private preloadCommonList() {
    // this.commonListsObservableService.listDetailedOrganizationClients$().pipe(filter(response => response != null), take(1)).subscribe();
    this.commonListsObservableService.listCommissionSalesPatterns$().pipe(filter(response => response != null), take(1)).subscribe();
    this.commonListsObservableService.listUserProfileInternal$().pipe(filter(response => response != null), take(1)).subscribe();
    this.commonListsObservableService.listOrganizationInternals$().pipe(filter(response => response != null), take(1)).subscribe();
    this.commonListsObservableService.listHolidayScheduleName$().pipe(filter(response => response != null), take(1)).subscribe();
  }

  private authenticateRoute(assignment, routerParams, showTemplate: boolean) {
    if (+routerParams.assignmentId && +routerParams.workorderId && +routerParams.versionId) {
      if (!showTemplate) {
        if (assignment.Id === +routerParams.assignmentId) {
          const workorder = assignment.WorkOrders.find(a => a.Id === +routerParams.workorderId);
          if (workorder) {
            const version = workorder.WorkOrderVersions.find(a => a.Id === +routerParams.versionId);
            if (version) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private getEntity(routerParams, showTemplate: boolean): Observable<any> {
    const entityId: number = Number(showTemplate ? routerParams.templateId : routerParams.versionId);
    if (showTemplate) {
      return this.apiService.query('template/' + entityId);
    } else {
      const assignmentId: number = Number(routerParams.assignmentId);
      const workOrderId: number = Number(routerParams.workorderId);
      return this.apiService.query(`assignment/getWorkOrderVersion/${assignmentId}/${workOrderId}/${entityId}`);
    }
  }

  private getWorkOrder(params: IGetWorkOrderParams, forTemplate: boolean) {
    const workorderDetails: IWorkOrder = {} as IWorkOrder;
    const workorder: any = forTemplate
      ? params.Templates.Entity.WorkOrders.find(a => a.Id === 0)
      : params.assignment.WorkOrders[0];  // api returns only 1 work order

    if (forTemplate) {
      workorderDetails.TemplateId = params.templateId;
    }

    workorderDetails.TerminationTypeId = workorder.TerminationTypeId;
    workorderDetails.TerminationReasons = workorder.TerminationReasons;
    workorderDetails.TerminationDate = workorder.TerminationDate;
    workorderDetails.TerminationNoticeDate = workorder.TerminationNoticeDate;
    workorderDetails.AtsPlacementId = forTemplate ? params.Templates.Entity.AtsPlacementId : params.assignment.AtsPlacementId;
    workorderDetails.AssignmentId = workorder.AssignmentId;
    if (!forTemplate) {
      workorderDetails.OnboardingPackageId = params.assignment.OnboardingPackageId;
      workorderDetails.WorkOrderId = workorder.Id;
      workorderDetails.UserProfileIdWorker = params.assignment.UserProfileIdWorker;
      workorderDetails.ExcludeFromBI = params.assignment.ExcludeFromBI ?? false;
    }

    workorderDetails.AssignmentStartDate = forTemplate ? params.Templates.Entity.StartDate : params.assignment.StartDate;
    workorderDetails.StatusId = workorder.StatusId;
    workorderDetails.IsDraftStatus = workorder.StatusId === PhxConstants.WorkOrderStatus.Processing && ControlFieldAccessibility.currentProfileUnderComplianceRole();

    workorderDetails.IsExtended = workorder.IsExtended;
    workorderDetails.IsOffboarding = workorder.IsOffboarding;
    workorderDetails.HasOffboardingAccess = workorder.HasOffboardingAccess;
    workorderDetails.OffboardingCompletionDate = workorder.OffboardingCompletionDate;
    workorderDetails.LastSubmitedTimesheetStartDate = workorder.LastSubmitedTimesheetStartDate;
    workorderDetails.LastSubmitedTimesheetEndDate = workorder.LastSubmitedTimesheetEndDate;
    workorderDetails.IsClientRateNegotiationEnabled = workorder.IsClientRateNegotiationEnabled;
    workorderDetails.HasProfileWithUSProvinceTax_NoNeedtoHavePaymentSalesTax = workorder.HasProfileWithUSProvinceTax_NoNeedtoHavePaymentSalesTax;

    if (!forTemplate) {
      workorderDetails.OrganizationIdInternal = params.assignment.OrganizationIdInternal;
    } else {
      workorderDetails.WorkOrderId = workorder.Id;
      workorderDetails.UserProfileIdWorker = params.Templates.Entity.UserProfileIdWorker;
      workorderDetails.OrganizationIdInternal = params.Templates.Entity.OrganizationIdInternal;
    }

    workorderDetails.StartDate = workorder.StartDate;
    workorderDetails.EndDate = workorder.EndDate;
    workorderDetails.IsPaymentStopped = workorder.IsPaymentStopped;
    workorderDetails.AssignmentStatus = forTemplate ? params.Templates.IsDraft : params.assignment.IsDraft;

    const templateUserProfileWorker = params.Templates ? this.getWorkerProfileTypeId(params.workerProfileTypes, params.Templates.Entity.UserProfileIdWorker) : null;
    const assignmentUserProfileWorker = params.assignment ? this.getWorkerProfileTypeId(params.workerProfileTypes, params.assignment.UserProfileIdWorker) : null;
    workorderDetails.workerProfileTypeId = forTemplate
      ? params.Templates.Entity.UserProfileIdWorker
        ? (templateUserProfileWorker ? templateUserProfileWorker.ProfileTypeId : null)
        : null
      : params.assignment.UserProfileIdWorker
        ? (assignmentUserProfileWorker ? assignmentUserProfileWorker.ProfileTypeId : null)
        : null;

    workorderDetails.workerContactId = forTemplate
      ? params.Templates.Entity.UserProfileIdWorker
        ? (templateUserProfileWorker ? templateUserProfileWorker.ContactId : null)
        : null
      : params.assignment.UserProfileIdWorker
        ? (assignmentUserProfileWorker ? assignmentUserProfileWorker.ContactId : null)
        : null;

    const version: IWorkOrderVersion = {} as IWorkOrderVersion;
    const currentVersion = workorder.WorkOrderVersions[0];  // api returns only 1 version
    workorderDetails.PreviousCommissions = (workorder.SourceId ? currentVersion.PreviousWorkOrderActiveVersionCommissions : currentVersion.PreviousWorkOrderVersionCommissions) || [];
    workorderDetails.PreviousWorkOrderVersionCommissions = currentVersion.PreviousWorkOrderVersionCommissions || [];
    workorderDetails.LastSavedWorkOrderVersionCommissions = currentVersion.WorkOrderVersionCommissions.map(x => ({ ...x }));
    workorderDetails.IsLastSavedWOVCommImpactApproved = currentVersion.IsCommissionChangeImpactApproved;

    version.wovEndDate = currentVersion.wovEndDate;
    version.WorkOrderId = forTemplate ? params.Templates.Entity.Id : params.assignment.Id;
    version.Id = currentVersion.Id;
    version.WorkOrderNumber = forTemplate ? currentVersion.WorkOrderNumber : workorder.WorkOrderNumber;

    version.VmsWorkOrderReference = currentVersion.VmsWorkOrderReference;
    version.VersionNumber = currentVersion.VersionNumber;
    version.CreatedDatetime = currentVersion.CreatedDatetime;
    version.LastModifiedDatetime = currentVersion.LastModifiedDatetime;
    version.EffectiveDate = currentVersion.EffectiveDate;
    version.EffectiveToDate = currentVersion.EffectiveToDate;

    version.WorkOrderStartDateState = currentVersion.WorkOrderStartDateState;
    version.WorkOrderEndDateState = currentVersion.WorkOrderEndDateState;

    version.WorkOrderCreationReasonId = currentVersion.WorkOrderCreationReasonId;
    version.StatusId = currentVersion.StatusId;

    version.BillingInfoes = currentVersion.BillingInfoes;
    version.PaymentInfoes = currentVersion.PaymentInfoes;
    version.PaymentInfoes.forEach(paymentInfo => {
      paymentInfo.PaymentSalesTaxes = paymentInfo.PaymentSalesTaxes || [];
    });
    version.TimeSheetApprovers = currentVersion.TimeSheetApprovers ? currentVersion.TimeSheetApprovers : [];
    version.ExpenseApprovers = currentVersion.ExpenseApprovers ? currentVersion.ExpenseApprovers : [];
    version.WorkOrderVersionCommissions = currentVersion.WorkOrderVersionCommissions;

    version.SourceId = currentVersion.SourceId;
    version.IsDraft = currentVersion.IsDraft;
    version.IsComplianceDraftStatus = version.StatusId === PhxConstants.WorkOrderVersionStatus.ComplianceDraft || version.StatusId === PhxConstants.WorkOrderVersionStatus.RecalledCompliance;
    version.IsDraftStatus =
      version.StatusId === PhxConstants.WorkOrderVersionStatus.Draft ||
      version.StatusId === PhxConstants.WorkOrderVersionStatus.Declined ||
      version.StatusId === PhxConstants.WorkOrderVersionStatus.Recalled ||
      (version.IsComplianceDraftStatus && ControlFieldAccessibility.currentProfileUnderComplianceRole());

    version.LineOfBusinessId = currentVersion.LineOfBusinessId;
    version.AssignedToUserProfileId = currentVersion.AssignedToUserProfileId;

    version.TimeSheetCycleId = currentVersion.TimeSheetCycleId;
    version.TimeSheetDescription = currentVersion.TimeSheetDescription;
    version.IsSeasonalOrPartTimeTimesheet = currentVersion.IsSeasonalOrPartTimeTimesheet;
    version.TimeSheetPreviousApprovalRequired = currentVersion.TimeSheetPreviousApprovalRequired;
    version.TimeSheetMethodologyId = currentVersion.TimeSheetMethodologyId;
    version.TimeSheetApprovalFlowId = currentVersion.TimeSheetApprovalFlowId;
    version.IsTimeSheetUsesProjects = currentVersion.IsTimeSheetUsesProjects;
    version.IsTimesheetProjectMandatory = currentVersion.IsTimesheetProjectMandatory;
    version.IsDisplayEstimatedInvoiceAmount = currentVersion.IsDisplayEstimatedInvoiceAmount;
    version.IsDisplayEstimatedPaymentAmount = currentVersion.IsDisplayEstimatedPaymentAmount;

    version.IsExpenseRequiresOriginal = currentVersion.IsExpenseRequiresOriginal;
    version.IsExpenseUsesProjects = currentVersion.IsExpenseUsesProjects;
    version.ExpenseMethodologyId = currentVersion.ExpenseMethodologyId;
    version.ExpenseDescription = currentVersion.ExpenseDescription;
    version.ExpenseThirdPartyWorkerReference = currentVersion.ExpenseThirdPartyWorkerReference;
    version.ExpenseApprovalFlowId = currentVersion.ExpenseApprovalFlowId;

    version.WorksiteId = currentVersion.WorksiteId;
    version.WorkerLocationId = currentVersion.WorkerLocationId;
    version.HolidayScheduleNameId = currentVersion.HolidayScheduleNameId;

    version.InternalOrganizationDefinition1Id = currentVersion.InternalOrganizationDefinition1Id;
    version.InternalOrganizationDefinition2Id = currentVersion.InternalOrganizationDefinition2Id;
    version.InternalOrganizationDefinition3Id = currentVersion.InternalOrganizationDefinition3Id;
    version.InternalOrganizationDefinition4Id = currentVersion.InternalOrganizationDefinition4Id;
    version.InternalOrganizationDefinition5Id = currentVersion.InternalOrganizationDefinition5Id;

    version.WorkerCompensationId = currentVersion.WorkerCompensationId;

    version.WCBIsApplied = currentVersion.WCBIsApplied;
    version.WCBPositionTitle = currentVersion.WCBPositionTitle;

    version.SalesPatternId = currentVersion.SalesPatternId;
    version.JobOwnerUsesSupport = currentVersion.JobOwnerUsesSupport;

    version.HasRebate = currentVersion.HasRebate;
    version.RebateHeaderId = currentVersion.RebateHeaderId;
    version.RebateTypeId = currentVersion.RebateTypeId;
    version.RebateRate = currentVersion.RebateRate;
    version.HasVmsFee = currentVersion.HasVmsFee;
    version.VmsFeeHeaderId = currentVersion.VmsFeeHeaderId;
    version.VmsFeeTypeId = currentVersion.VmsFeeTypeId;
    version.VmsFeeRate = currentVersion.VmsFeeRate;
    version.IsFlowdownFee = currentVersion.IsFlowdownFee;

    version.HasClientDiscount = currentVersion.HasClientDiscount;
    version.HasClientPercentDiscount = currentVersion.HasClientPercentDiscount;
    version.HasClientFlatDiscountAmount = currentVersion.HasClientFlatDiscountAmount;
    version.HasClientPerHourDiscount = currentVersion.HasClientPerHourDiscount;
    version.ClientPercentDiscount = currentVersion.ClientPercentDiscount;
    version.ClientFlatDiscountAmount = currentVersion.ClientFlatDiscountAmount;
    version.ClientPerHourDiscount = currentVersion.ClientPerHourDiscount;
    version.ClientDiscountDescription = currentVersion.ClientDiscountDescription;

    version.IsEligibleForCommission = currentVersion.IsEligibleForCommission;
    version.IsEligibleForAllowance = currentVersion.IsEligibleForAllowance == null ? false : currentVersion.IsEligibleForAllowance;
    version.IsThirdPartyImport = currentVersion.IsThirdPartyImport;
    version.CommissionThirdPartyWorkerReference = currentVersion.CommissionThirdPartyWorkerReference;
    version.ApplyFlatStatPay = currentVersion.ApplyFlatStatPay;
    version.IsRecruiterRemoved = currentVersion.IsRecruiterRemoved;

    version.InitialAskPayRate = currentVersion.InitialAskPayRate;
    version.InitialAskBillRate = currentVersion.InitialAskBillRate;
    version.FeeSavings = currentVersion.FeeSavings;
    version.BurdenSavings = currentVersion.BurdenSavings;
    version.AdditionalRateSavings = currentVersion.AdditionalRateSavings;
    version.JobPostingNumber = currentVersion.JobPostingNumber;
    version.RateCardJobTitle = currentVersion.RateCardJobTitle;
    version.ClientGroup = currentVersion.ClientGroup;
    version.RateNegotiationAdditionalInfo = currentVersion.RateNegotiationAdditionalInfo;
    version.MaxBillRate = currentVersion.MaxBillRate;
    version.BurdenUsedPercentage = currentVersion.BurdenUsedPercentage;
    version.IsRatePrenegotiated = currentVersion.IsRatePrenegotiated;
    version.IsRateCardUsed = currentVersion.IsRateCardUsed;
    version.IsRateWithinRateCard = currentVersion.IsRateWithinRateCard;
    version.SubvendorActualPayRate = currentVersion.SubvendorActualPayRate;
    version.SubvendorActualProfileTypeId = currentVersion.SubvendorActualProfileTypeId;
    version.SubvendorActualBurdenCosts = currentVersion.SubvendorActualBurdenCosts;
    version.SubvendorMargin = currentVersion.SubvendorMargin;
    version.IsCommissionChangeImpactApproved = currentVersion.IsCommissionChangeImpactApproved;
    version.HasApprovedCommissionImpact = currentVersion.HasApprovedCommissionImpact;
    version.AutoCalculateOvertime = currentVersion.AutoCalculateOvertime;
    version.AutoCalculateOvertimeExemptionReason = currentVersion.AutoCalculateOvertimeExemptionReason;
    version.IsOvertimeExempt = currentVersion.IsOvertimeExempt;
    version.OvertimeExemptionReason = currentVersion.OvertimeExemptionReason;

    version.JobCategoryId = currentVersion.JobCategoryId;
    version.JobFunctionId = currentVersion.JobFunctionId;
    version.JobSkills = currentVersion.JobSkills;
    version.ClientPositionTitle = currentVersion.ClientPositionTitle;
    version.IsClientPositionTitleSameAsJobFunction = currentVersion.IsClientPositionTitleSameAsJobFunction;
    version.CommissionAttribution = currentVersion.CommissionAttribution;

    version.CNRWithholdingTaxIsApplied = currentVersion.CNRWithholdingTaxIsApplied;
    version.CNRWithholdingTaxOverrideReason = currentVersion.CNRWithholdingTaxOverrideReason;
    
    // UI
    version.ValidateComplianceDraft = !(version.IsDraftStatus && !version.IsComplianceDraftStatus);

    version.IsFederalWorker = currentVersion.IsFederalWorker;
    version.IsControlledGoods = currentVersion.IsControlledGoods;

    version.OverrideTimesheetExceptions = (forTemplate
      ? JSON.parse(currentVersion.OverrideTimesheetExceptions)
      : currentVersion.OverrideTimesheetExceptions)
      ?? [];

    forEach(version.WorkOrderVersionCommissions, com => {
      com.CommissionRates = [];
      com.CommissionRates.push({
        CommissionRateHeaderId: com.CommissionRateHeaderId,
        CommissionRateHeaderStatusId: com.CommissionRateStatusId,
        Description: com.Description,
        DisplayText: this.commissionPickerService.getCommissionRateDisplayText(com.CommissionRateHeaderId, com.Description, com.CommissionRateStatusId),
        CommissionRateVersions: com.CommissionRateVersions || []
      });
    });

    version.JobOwner = find(currentVersion.WorkOrderVersionCommissions, obj => {
      return obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleNoSupport || obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleWithSupport;
    });
    version.SupportingJobOwners = lodashFilter(currentVersion.WorkOrderVersionCommissions, obj => {
      return obj.CommissionRoleId === PhxConstants.CommissionRole.SupportingJobOwner;
    });
    version.Recruiters = lodashFilter(currentVersion.WorkOrderVersionCommissions, obj => {
      return obj.CommissionRoleId === PhxConstants.CommissionRole.RecruiterRole;
    });

    version.IsRateNegotiationEnabled = version.BillingInfoes[0].IsClientRateNegotiationEnabled
      && (currentVersion.LineOfBusinessId === PhxConstants.LineOfBusiness.Payroll || currentVersion.LineOfBusinessId === PhxConstants.LineOfBusiness.SubVendorPayroll);

    version.PreviousBillingRate = currentVersion.PreviousBillingPrimaryRate;
    version.PreviousPaymentRate = currentVersion.PreviousPaymentPrimaryRate;

    // check if wov has IncentiveCompensation payment invoice type (for old templates, maybe delete in the future if all templates are updated later)
    if (forTemplate && version.PaymentInfoes[0].PaymentInvoices.findIndex(i => i.InvoiceTypeId === PhxConstants.InvoiceType.IncentiveCompensation) === -1) {
      // not included in payment invoices so push onto payment invoices
      version.PaymentInfoes[0].PaymentInvoices.push({
        Id: 0,
        IsDraft: false,
        PaymentInfoId: 0,
        InvoiceTypeId: PhxConstants.InvoiceType.IncentiveCompensation,
        PaymentReleaseScheduleId: null,
        PaymentFrequency: null,
        PaymentInvoiceTemplateId: null,
        PaymentInvoiceTermsId: null,
        PaymentMethodId: null,
        SourceId: null,
        IsSalesTaxAppliedOnVmsImport: null,
      });
    }

    const hasSaveAction = (currentVersion.AvailableStateActions || []).some(x => x === PhxConstants.StateAction.WorkOrderVersionSave);

    workorderDetails.readOnlyStorage = {
      IsDebugMode: isDebugMode,
      IsEditable: forTemplate || hasSaveAction,
      IsCommissionsEditable: forTemplate || (
        hasSaveAction && (
          workorder.StatusId === PhxConstants.WorkOrderStatus.Processing ||
          (version.IsComplianceDraftStatus && this.authService.hasFunctionalOperation(PhxConstants.FunctionalOperation.WorkOrderVersionCommissionFieldsEdit))
        )),
      AccessActions: forTemplate ? params.Templates.Entity.AccessActions : params.assignment.AccessActions,
      showTerminationSection: workorder.StatusId === PhxConstants.WorkOrderStatus.PendingTermination ||
        workorder.StatusId === PhxConstants.WorkOrderStatus.PendingTerminationNotice ||
        workorder.StatusId === PhxConstants.WorkOrderStatus.Terminated,
      isTemplate: forTemplate
    };

    workorderDetails.WorkOrderVersion = version;
    if (!forTemplate) {
      workorderDetails.RootObject = params.assignment;
      workorderDetails.combinedAvailableStateActions = [].concat(params.assignment.AvailableStateActions || [], workorder.AvailableStateActions || [], currentVersion.AvailableStateActions || []);
      workorderDetails.WorkOrderHeaders = workorderDetails.RootObject.WorkOrderHeaders;
    } else {
      workorderDetails.RootObject = {
        Id: 0,
        OrganizationCode: params.Templates.Entity.OrganizationCode,
        OrganizationIdInternal: params.Templates.Entity.OrganizationIdInternal,
        StatusId: params.Templates.StatusId,
        UserProfileIdWorker: params.Templates.Entity.UserProfileIdWorker,
        LastModifiedDateTime: params.Templates.LastModifiedDateTime,
        WorkOrders: params.Templates.Entity.WorkOrders
      };
      workorderDetails.combinedAvailableStateActions = [];
      workorderDetails.WorkOrderHeaders = [{
        Id: workorder.Id,
        StatusId: workorder.StatusId,
        WorkOrderNumber: version.WorkOrderNumber,
        WorkOrderVersionHeaders: [{
          Id: version.Id,
          StatusId: version.StatusId,
          VersionNumber: version.VersionNumber,
          EffectiveDate: version.EffectiveToDate
        }]
      }];
    }
    workorderDetails.isSourceWOTempLayoff = workorderDetails.WorkOrderHeaders.some(x => x.Id === workorder.SourceId && x.TerminationTypeId === PhxConstants.TerminationType.TemporaryLayoff);
    return workorderDetails;
  }

  private getWorkerProfileTypeId(workerProfileTypes: any, id: number) {
    const worker = workerProfileTypes.find(w => {
      return w.Id === id;
    });
    return worker;
  }

  private removeItem(id: number) {
    const index = this.workOrders.findIndex(x => x.Id === id);
    if (index !== -1) {
      this.workOrders.splice(index, 1);
    }
  }
}
