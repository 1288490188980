
import { takeUntil, take, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  IPaymentPartiesRateDetail,
  ITabPartiesandRates,
  IWorkOrder,
  IPaymentInfo,
  IPaymentContact,
  IReadOnlyStorage,
  IPaymentPaySideDeduction
} from '../../models/workorder.interface';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { PhxConstants, CodeValueService } from '../../../common';
import { CodeValue, CodeValueGroups } from '../../../common/model';
import { PartyPaymentInfoFormService, WorkOrderFormService, WorkorderService } from '../../services';
import { cloneDeep, each, uniq, isArray, slice, remove, indexOf } from 'lodash';
import { Subscription, combineLatest, Observable } from 'rxjs';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-payment-party',
  templateUrl: './workorder-payment-party.component.html',
  styleUrls: ['./workorder-payment-party.component.less']
})
export class WorkorderPaymentPartyComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() paymentInfoIndex: number;
  @Input() inputFormGroup: FormGroup<IPaymentPartiesRateDetail>;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  UserProfileIdWorkerForm: FormGroup<ITabPartiesandRates>;
  oldPaymentInfoes: IPaymentInfo[];
  AtsPlacementId: number;
  workerProfileTypeId: number;
  organizationClientId: number;
  setCurrency = false;
  currentWorkOrderVersionId: number;
  workorderDetails: IWorkOrder;
  worker: any;
  listRoleTypes: CodeValue[] = [];
  html: {
    codeValueLists: {
      listCurrency: Array<CodeValue>;
      listProfileTypeList: Array<CodeValue>;
      listOrganizationRoleType: Array<CodeValue>;
    };
    commonLists: {
      listProfilesListForPaymentOrganization: Array<any>;
      listOrganizationSupplier: Array<any>;
      listUserProfileWorker: Array<any>;
      listavailableVmsFees: Array<any>;
    };
    lists: {
      t4PrintableYears: Array<number>;
      phxConstants: typeof PhxConstants;
    };
  } = {
      codeValueLists: {
        listCurrency: [],
        listProfileTypeList: [],
        listOrganizationRoleType: []
      },
      commonLists: {
        listProfilesListForPaymentOrganization: [],
        listOrganizationSupplier: [],
        listUserProfileWorker: [],
        listavailableVmsFees: []
      },
      lists: {
        t4PrintableYears: [2018, 2019],
        phxConstants: PhxConstants
      }
    };

  firstModelInitialized = false;
  codeValueGroups = CodeValueGroups;
  private subscription$: Subscription;

  get contactLength(): number {
    return this.formArrayPaymentContacts ? this.formArrayPaymentContacts.length : 0;
  }

  get PaySideDeductionLength(): number {
    return this.formArrayPaymentPaySideDeductions ? this.formArrayPaymentContacts.length : 0;
  }

  get formArrayPaymentContacts() {
    const formArray = this.inputFormGroup ? this.inputFormGroup.get('PaymentContacts') as FormArray<IPaymentContact> : null;
    return formArray ? formArray.controls : [];
  }

  get formArrayPaymentPaySideDeductions() {
    const formArray = this.inputFormGroup ? this.inputFormGroup.get('PaymentPaySideDeductions') as FormArray<IPaymentPaySideDeduction> : null;
    return formArray ? formArray.controls : [];
  }

  get organizationIdSupplier(): number {
    const organizationIdSupplier = this.inputFormGroup ? this.inputFormGroup.get('OrganizationIdSupplier') : null;
    return organizationIdSupplier ? organizationIdSupplier.value : 0;
  }

  constructor(
    private workorderService: WorkorderService,
    private workOrderFormService: WorkOrderFormService,
    private commonListsObservableService: CommonListsObservableService,
    private codeValueService: CodeValueService,
    private paymentInfoFormService: PartyPaymentInfoFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.inputFormGroup) {
      // Form reads/writes should go here, not in the workorderOnRouteChange$ subscription.
      // Model updates are received before the form gets updated/re-initialized
      this.onInputFormGroupChange();
      this.setupFormGroupListeners();
    }
  }

  onInputFormGroupChange() {
    if (this.inputFormGroup.controls.Id.value === 0 && !this.inputFormGroup.controls.OrganizationIdSupplier.value) {
      this.html.commonLists.listProfilesListForPaymentOrganization = [];
    }

    // ASYNC CALL HERE, POTENTIAL RACE CONDITION
    this.getListUserProfile().subscribe();

    this.filterOrganizationRoleTypes();
  }

  ngOnInit() {
    combineLatest([
      this.workOrderFormService.workOrder$,
      this.getListProfilesListByOrganizationId(),
      this.getListUserProfile(),
      this.commonListsObservableService.listOrganizationSuppliers$()
        .pipe(filter(response => response != null))
    ])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([workorder, , , list]) => {
        if (list) {
          this.html.commonLists.listOrganizationSupplier = list.map((orgSupplier: any) => {
            orgSupplier.DisplayValue = (orgSupplier.DisplayName ? orgSupplier.DisplayName : '') + ' - ' + orgSupplier.Id;
            return orgSupplier;
          });
        }

        if (workorder) {
          this.workorderDetails = workorder;
          this.oldPaymentInfoes = cloneDeep(workorder.WorkOrderVersion.PaymentInfoes);
          this.AtsPlacementId = workorder.AtsPlacementId;
          this.workerProfileTypeId = workorder.workerProfileTypeId;
          this.currentWorkOrderVersionId = workorder.WorkOrderVersion.Id;
          this.organizationClientId = workorder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient;

          if (!this.firstModelInitialized) {
            this.firstModelInitialized = true;
            this.onInputFormGroupChange();
          }
        }
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('OrganizationIdSupplier').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(value => {
        if (value && value > 0) {
          each(this.html.commonLists.listOrganizationSupplier, i => {
            if (i.Id === value) {
              this.inputFormGroup.controls.OrganizationSupplierDisplayName.patchValue(i.DisplayName);
              this.inputFormGroup.get('IsOrganizationSupplierSubVendor').setValue(i.IsOrganizationSubVendorRole);
            }
          });
          this.inputFormGroup.get('OrganizationRoleTypeId').patchValue(null);
          this.getPrimaryContact(value);
          this.filterOrganizationRoleTypes();
        }
      })
    );
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listCurrency = this.codeValueService.getCodeValues(this.codeValueGroups.Currency, true);
    this.html.codeValueLists.listProfileTypeList = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true);
    this.html.codeValueLists.listOrganizationRoleType =
      this.codeValueService.getCodeValues(this.codeValueGroups.OrganizationRoleType, true)
        .filter(n => (n.id !== this.phxConstants.OrganizationRoleType.Client && n.id !== this.phxConstants.OrganizationRoleType.Internal));
    this.listRoleTypes = this.html.codeValueLists.listOrganizationRoleType;
  }

  filterOrganizationRoleTypes() {
    if (this.inputFormGroup.controls.OrganizationIdSupplier.value &&
      this.inputFormGroup.controls.OrganizationIdSupplier.value > 0) {
      const organizationSuppliers = this.html.commonLists.listOrganizationSupplier
        .filter(i => i.Id === this.inputFormGroup.controls.OrganizationIdSupplier.value);
      if (organizationSuppliers?.length > 0) {
        this.inputFormGroup.get('IsOrganizationSupplierSubVendor').setValue(organizationSuppliers[0].IsOrganizationSubVendorRole);
        this.inputFormGroup.get('IsOrganizationIndependentContractorRole').setValue(organizationSuppliers[0].IsOrganizationIndependentContractorRole);
        this.inputFormGroup.get('IsOrganizationLimitedLiabilityCompanyRole').setValue(organizationSuppliers[0].IsOrganizationLimitedLiabilityCompanyRole);
      }
    }

    if (this.inputFormGroup && !this.inputFormGroup.get('IsOrganizationSupplierSubVendor').value) {
      this.listRoleTypes =
        this.html.codeValueLists.listOrganizationRoleType.filter(n => n.id !== PhxConstants.OrganizationRoleType.SubVendor);
    }
    if (this.inputFormGroup && !this.inputFormGroup.get('IsOrganizationIndependentContractorRole').value) {
      if (this.listRoleTypes.length > 0) {
        this.listRoleTypes =
          this.listRoleTypes.filter(n => n.id !== PhxConstants.OrganizationRoleType.IndependentContractor);
      } else {
        this.listRoleTypes =
          this.html.codeValueLists.listOrganizationRoleType.filter(n => n.id !== PhxConstants.OrganizationRoleType.IndependentContractor);
      }

    }
    if (this.inputFormGroup && !this.inputFormGroup.get('IsOrganizationLimitedLiabilityCompanyRole').value) {
      if (this.listRoleTypes.length > 0) {
        this.listRoleTypes =
          this.listRoleTypes.filter(n => n.id !== PhxConstants.OrganizationRoleType.LimitedLiabilityCompany);
      } else {
        this.listRoleTypes =
          this.html.codeValueLists.listOrganizationRoleType.filter(n => n.id !== PhxConstants.OrganizationRoleType.LimitedLiabilityCompany);
      }
    }
  }

  populatinglistProfilesListForPaymentOrganization(list: any) {
    this.html.commonLists.listProfilesListForPaymentOrganization = list;
    this.removeInactiveProfileWithConfig(null, this.html.commonLists.listProfilesListForPaymentOrganization, this.inputFormGroup.controls.UserProfileIdSupplier.value);
    this.html.commonLists.listProfilesListForPaymentOrganization = this.html.commonLists
      .listProfilesListForPaymentOrganization
      .filter(e => e.ProfileTypeId !== this.phxConstants.UserProfileType.WorkerSubVendor);
    this.html.commonLists.listProfilesListForPaymentOrganization.forEach((val: any) => {
      val.DisplayValue = val.Contact.FullName + ' - ' + val.Contact.Id;
    });
  }


  getListProfilesListByOrganizationId(): Observable<any> {
    if (this.inputFormGroup.controls.OrganizationIdSupplier.value !== null) {
      // ASYNC CALL HERE, POTENTIAL RACE CONDITION
      return this.workorderService
        .getProfilesListByOrganizationId(this.inputFormGroup.controls.OrganizationIdSupplier.value)
        .pipe(
          map((response) => {
            const list = response ? response.map(item => item.Data) : [];
            return this.populatinglistProfilesListForPaymentOrganization(list);
          }),
          takeUntil(this.isDestroyed$));
    } else {
      return this.commonListsObservableService.getUserProfileWorkers$(this.inputFormGroup.controls.UserProfileIdSupplier.value)
        .pipe(
          filter(response => response != null),
          take(1),
          map(response => {
            if (response) {
              return this.populatinglistProfilesListForPaymentOrganization(cloneDeep(response));
            }
          }),
          takeUntil(this.isDestroyed$)
        );
    }
  }

  removeInactiveProfileWithConfig(config = null, profiles: any, exceptionIds: any) {
    const inactiveProfileStatusIds = [PhxConstants.ProfileStatus.Inactive, PhxConstants.ProfileStatus.PendingActivation];
    const settings = Object.assign({}, { profileStatusId: 'ProfileStatusId', id: 'Id' }, config);
    const exceptionProfileIds = uniq(isArray(exceptionIds) ? exceptionIds : slice(arguments, 2));
    remove(profiles, profile => {
      return indexOf(inactiveProfileStatusIds, profile[settings.profileStatusId]) > -1 && indexOf(exceptionProfileIds, profile[settings.id]) < 0;
    });
  }

  onDeleteContactEvent(index: number) {
    const paymentContacts = this.inputFormGroup ? this.inputFormGroup.get('PaymentContacts') as FormArray<IPaymentContact> : null;
    if (paymentContacts) {
      paymentContacts.removeAt(index);
    }
  }

  onDeletePaySideDeductionEvent(index: number) {
    const paymentContacts = this.inputFormGroup ? this.inputFormGroup.get('PaymentPaySideDeductions') as FormArray<IPaymentPaySideDeduction> : null;
    if (paymentContacts) {
      paymentContacts.removeAt(index);
    }
  }

  populatinglistUserProfileWorker(listUserProfileWorker: any) {
    if (listUserProfileWorker) {
      this.html.commonLists.listUserProfileWorker = listUserProfileWorker;
      this.html.commonLists.listUserProfileWorker.forEach(value => {
        const profileName = this.html.codeValueLists.listProfileTypeList.find(f => f.id === value.ProfileTypeId).text;
        value.DisplayValue = value.Contact.FullName + ' - ' + value.Contact.Id + ' - ' + profileName + (value.Id ? ' - Profile: ' + value.Id : '');
      });
      this.html.commonLists.listUserProfileWorker = this.filterGetListUserProfileWorker(this.html.commonLists.listUserProfileWorker);
    }
    if (this.inputFormGroup?.controls.Id.value === 0 && !this.inputFormGroup.controls.OrganizationIdSupplier.value) {
      this.html.commonLists.listProfilesListForPaymentOrganization = [];
    }
  }


  getListUserProfile(): Observable<any> {
    return this.commonListsObservableService
      .getUserProfileWorkers$((this.workorderDetails) ? this.workorderDetails.UserProfileIdWorker : this.inputFormGroup.controls.UserProfileIdWorker.value)
      .pipe(
        filter(response => response != null),
        take(1),
        map((listUserProfileWorker: any) => {
          this.populatinglistUserProfileWorker(listUserProfileWorker);
          if (this.workorderDetails) {
            this.worker = this.workorderService.getWorker(this.workorderDetails, this.html.commonLists.listUserProfileWorker);
            this.getPrimaryContact(this.inputFormGroup ? this.inputFormGroup.controls.OrganizationIdSupplier.value : null); /// hit here after view init
          }
          return this.html.commonLists.listUserProfileWorker;
        }),
        takeUntil(this.isDestroyed$));
  }

  filterGetListUserProfileWorker(profilesListWorker: any) {
    const profileTypesWithOrg = [
      PhxConstants.UserProfileType.WorkerCanadianInc,
      PhxConstants.UserProfileType.WorkerSubVendor,
      PhxConstants.UserProfileType.WorkerUnitedStatesLLC
    ];
    const workers = profilesListWorker.filter(item => {
      return profileTypesWithOrg.includes(item.ProfileTypeId)
        ? item.OrganizationId != null
        : true;
    });
    return workers;
  }

  getPrimaryContact(orgIdSupplier: number = 0) {
    if (this.inputFormGroup && orgIdSupplier > 0) {
      this.getListProfilesListByOrganizationId().subscribe();
    } else if (
      this.worker &&
      !this.worker.OrganizationId &&
      (this.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerTemp || this.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerCanadianSp)
    ) {
      this.html.commonLists.listProfilesListForPaymentOrganization = [this.worker];
      this.html.commonLists.listProfilesListForPaymentOrganization = this.html.commonLists.listProfilesListForPaymentOrganization
        .filter(e => e.ProfileTypeId !== this.phxConstants.UserProfileType.WorkerSubVendor);
      this.html.commonLists.listProfilesListForPaymentOrganization.forEach((val: any) => {
        val.DisplayValue = val.Contact.FullName + ' - ' + val.Contact.Id;
      });
    } else if (
      this.worker &&
      !this.worker.OrganizationId &&
      this.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerUnitedStatesW2
    ) {
      this.html.commonLists.listProfilesListForPaymentOrganization = [this.worker];
      this.html.commonLists.listProfilesListForPaymentOrganization = this.html.commonLists.listProfilesListForPaymentOrganization
        .filter(e => e.ProfileTypeId !== this.phxConstants.UserProfileType.WorkerSubVendor);
      this.html.commonLists.listProfilesListForPaymentOrganization.forEach((val: any) => {
        val.DisplayValue = val.Contact.FullName + ' - ' + val.Contact.Id;
      });
    } else {
      this.html.commonLists.listProfilesListForPaymentOrganization = [];
    }
  }

  AddPaymentContact() {
    this.paymentInfoFormService.addPaymentContact(this.paymentInfoIndex, this.inputFormGroup.value);
  }

  AddPaymentPaySideDeduction() {
    this.paymentInfoFormService.addPaymentPaySideDeduction(this.paymentInfoIndex, this.inputFormGroup.value);
  }

  showPaySideDeductionAddButton(): boolean {
    return this.formArrayPaymentPaySideDeductions.some(control => {
      const paySideDeduction = control.value;
      return paySideDeduction.PaySideDeductionHeaderId == null;
    });
  }
  
  onRemovePaymentParty() {
    this.paymentInfoFormService.deletePaymentPartiesRateDetailFormGroup(this.paymentInfoIndex);
  }
}
