<form [formGroup]="inputFormGroup">
    <div role="form">
        <div class="form-group">
            <div class="col-lg-10">
                <p class="form-control-static" *ngIf="inputFormGroup.controls.OrganizationIdSupplier.value>0">
                    <a routerLink="/next/organization/{{inputFormGroup.controls.OrganizationIdSupplier.value}}/details"
                      [appCollectClickDataAnalytics]="{ action: 'View Supplier Organization', feature: 'Work Order', type: 'Redirection link' }"
                    >{{inputFormGroup.controls.OrganizationSupplierDisplayName.value}}
                        - {{inputFormGroup.controls.OrganizationIdSupplier.value}}</a>
                </p>
                <p class="form-control-static"
                    *ngIf="!inputFormGroup.controls.OrganizationIdSupplier.value && (workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerTemp || workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerCanadianSp || workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerUnitedStatesW2)">
                    <a *ngIf="workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerTemp && inputFormGroup.controls.UserProfileIdWorker"
                        routerLink="/next/contact/{{inputFormGroup.controls.UserProfileIdWorker.value|lookup:html.commonLists.listUserProfileWorker:'Id':'ContactId'}}/profile/workertemp/{{inputFormGroup.controls.UserProfileIdWorker.value}}">{{inputFormGroup.controls.OrganizationSupplierDisplayName.value}}</a>
                    <a *ngIf="workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerCanadianSp && inputFormGroup.controls.UserProfileIdWorker"
                        routerLink="/next/contact/{{inputFormGroup.controls.UserProfileIdWorker.value|lookup:html.commonLists.listUserProfileWorker:'Id':'ContactId'}}/profile/workercanadiansp/{{inputFormGroup.controls.UserProfileIdWorker.value}}">{{inputFormGroup.controls.OrganizationSupplierDisplayName.value}}</a>
                    <a *ngIf="workerProfileTypeId === html.lists.phxConstants.UserProfileType.WorkerUnitedStatesW2 && inputFormGroup.controls.UserProfileIdWorker"
                        routerLink="/next/contact/{{inputFormGroup.controls.UserProfileIdWorker.value|lookup:html.commonLists.listUserProfileWorker:'Id':'ContactId'}}/profile/workerunitedstatesw2/{{inputFormGroup.controls.UserProfileIdWorker.value}}">{{inputFormGroup.controls.OrganizationSupplierDisplayName.value}}</a>
                </p>
            </div>
            <div class="col-lg-2">
                <a *ngIf="paymentInfoIndex > 0 && readOnlyStorage?.IsEditable"
                    class="form-control-static remove-payment-party-link" (click)="onRemovePaymentParty()">delete</a>
            </div>
        </div>
        <ng-container
            *ngIf="paymentInfoIndex ===0 && (!inputFormGroup.controls.UserProfileIdWorker.value || inputFormGroup.controls.UserProfileIdWorker.value === 0)">
            <phx-form-control labelText="Worker" [control]="inputFormGroup.controls.UserProfileIdWorker"
                viewModeText="{{inputFormGroup.controls.UserProfileIdWorker.value | lookup:html.commonLists.listUserProfileWorker:'Id':'DisplayValue'}}">
                <app-phx-select-box [items]="html.commonLists.listUserProfileWorker" textField="DisplayValue"
                    valueField="Id" [formControl]="inputFormGroup.controls.UserProfileIdWorker">
                </app-phx-select-box>
            </phx-form-control>
        </ng-container>
        <ng-container
            *ngIf="paymentInfoIndex > 0 && (!inputFormGroup.controls.OrganizationIdSupplier.value || inputFormGroup.controls.OrganizationIdSupplier.value===0) ">
            <phx-form-control labelText="Supplier Organization"
                [control]="inputFormGroup.controls.OrganizationIdSupplier"
                viewModeText="{{inputFormGroup.controls.OrganizationIdSupplier.value}}">
                <app-phx-select-box
                    [items]="html.commonLists.listOrganizationSupplier | filterSelectChoicesByUsage: oldPaymentInfoes: inputFormGroup.value: 'OrganizationIdSupplier': 'Id': 'DisplayName': 'Id' "
                    textField="DisplayValue" valueField="Id"
                    [formControl]="inputFormGroup.controls.OrganizationIdSupplier">
                </app-phx-select-box>
            </phx-form-control>
        </ng-container>
        <ng-container *ngIf="paymentInfoIndex > 0">
            <phx-form-control labelText="Organization Role Type"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','OrganizationRoleTypeId')"
                [control]="inputFormGroup.controls.OrganizationRoleTypeId"
                viewModeText="{{inputFormGroup.controls.OrganizationRoleTypeId.value | lookup:html.codeValueLists.listOrganizationRoleType:'id':'text'}}">
                <app-phx-select-box [formControl]="inputFormGroup.controls.OrganizationRoleTypeId"
                    [items]="listRoleTypes" textField="text" valueField="id">
                </app-phx-select-box>
            </phx-form-control>
        </ng-container>
        <ng-container *ngIf="!paymentInfoIndex && inputFormGroup.controls.UserProfileIdWorker.value >0">
            <phx-form-control labelText="Worker" [control]="inputFormGroup.controls.UserProfileIdWorker"
                [editable]="true" viewModeText="">
                <div class="col-lg-12 padding-0">
                    <p class="form-control-static">
                        {{inputFormGroup.controls.UserProfileIdWorker.value |
                        lookup:html.commonLists.listUserProfileWorker:'Id':'DisplayValue'}}
                    </p>
                </div>
            </phx-form-control>
        </ng-container>
        <phx-form-control labelText="Primary Contact" [control]="inputFormGroup.controls.UserProfileIdSupplier"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','UserProfileIdSupplier')"
            viewModeText="{{inputFormGroup.controls.UserProfileIdSupplier.value | lookup:html.commonLists.listProfilesListForPaymentOrganization:'Id':'Contact.FullName'}} - {{inputFormGroup.controls.UserProfileIdSupplier.value}}">
            <app-phx-select-box [items]="html.commonLists.listProfilesListForPaymentOrganization"
                textField="DisplayValue" valueField="Id"
                [formControl]="inputFormGroup.controls.UserProfileIdSupplier">
            </app-phx-select-box>
        </phx-form-control>
        <phx-form-control [control]="inputFormGroup.controls.Hours" labelText="Hours Per Day"
            viewModeText="{{inputFormGroup.controls.Hours.value}}" [editable]="false">
            <input [formControl]="inputFormGroup.controls.Hours" type="text" class="form-control" />
        </phx-form-control>
        <phx-form-control [control]="inputFormGroup.controls.CurrencyId" labelText="Currency"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','CurrencyId')"
            viewModeText="{{inputFormGroup.controls.CurrencyId.value | CodeValue:codeValueGroups.Currency }}">
            <app-phx-select-box [formControl]="inputFormGroup.controls.CurrencyId"
                [items]="html.codeValueLists.listCurrency" textField="text" valueField="id">
            </app-phx-select-box>
        </phx-form-control>
    </div>
    <app-workorder-payment-rates
            [inputFormGroup]="inputFormGroup.controls.PaymentRatesDetail"
            [readOnlyStorage]="readOnlyStorage"
            [paymentInfoIndex]="paymentInfoIndex"
            [currencyId]="inputFormGroup.controls.CurrencyId.value"
    >
    </app-workorder-payment-rates>

    <div class="panel-internal" *ngIf="organizationClientId > 0">
        <h4>PaySide Deduction</h4>
        <ng-container *ngFor="let paySideDeductions of formArrayPaymentPaySideDeductions; let paySideDeductionIndex = index;">
            <app-workorder-payment-pay-side-deductions
                [inputFormGroup]="paySideDeductions" [readOnlyStorage]="readOnlyStorage"
                [paymentInfo]="inputFormGroup" [paySideDeductionIndex]="paySideDeductionIndex"
                [organizationIdClient]="organizationClientId" [lineOfBusinessId]="workorderDetails.WorkOrderVersion.LineOfBusinessId"
                (deletePaySideDeductionEvent)="onDeletePaySideDeductionEvent($event)"
            >
            </app-workorder-payment-pay-side-deductions>
        </ng-container>
        <div class="form-group" *ngIf="readOnlyStorage.IsEditable">
            <div class="col-lg-2 pull-right">
                <button type="button" class="btn btn-default btn-material pull-right material-icons"
                    [disabled]="showPaySideDeductionAddButton()"
                    (click)="AddPaymentPaySideDeduction()">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </div>
 
    <div class="panel-internal" *ngIf="organizationIdSupplier > 0">
        <div>
            <h4>General Contacts</h4>
            <div *ngIf="readOnlyStorage && !readOnlyStorage.IsEditable && contactLength === 0"
                class="no-general-contact">
                No general contact!
            </div>
            <div *ngFor="let contact of formArrayPaymentContacts; let contactIndex = index;">
                <div>
                    <app-workorder-payment-contact [inputFormGroup]="contact" [readOnlyStorage]="readOnlyStorage"
                        [paymentInfo]="inputFormGroup" [contactIndex]="contactIndex"
                        [organizationIdSupplier]="organizationIdSupplier"
                        (deleteContactEvent)="onDeleteContactEvent($event)">
                    </app-workorder-payment-contact>
                </div>
            </div>
            <div class="form-group" *ngIf="readOnlyStorage.IsEditable">
                <div class="col-lg-2 pull-right">
                    <button type="button" class="btn btn-default btn-material pull-right material-icons"
                        (click)="AddPaymentContact()"
                        [disabled]="contactLength >= html.commonLists.listProfilesListForPaymentOrganization.length"
                        title="used {{contactLength}} from {{html.commonLists.listProfilesListForPaymentOrganization.length}} existing contacts">
                        <i class="material-icons">add</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
