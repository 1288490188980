import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { of as observableOf, throwError, Subject, Observable, combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import moment from 'moment';

import { CodeValueService, CommonService, CustomFieldService,  DialogService } from '@common';
import { PhxDialogComponent } from '@common/components/phx-dialog/phx-dialog.component';
import { PhxDialogComponentConfigModel } from '@common/components/phx-dialog/phx-dialog.component.model';
import { OrganizationDataService } from '@common/data-services/organization-data/organization-data.service';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { ICommonListsItem } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { CodeValue, CodeValueGroups, DialogResultType, PhxConstants } from '@common/model';
import { ToolTipField } from '@common/model/phx-select-box-w-tooltip';
import { FormArray, FormBuilder, FormGroup } from '@common/ngx-strongly-typed-forms';
import { HashModel } from '@common/utility/hash-model';

import { IBillingRateSetUp, IFormGroupSetup, IPaymentRateSetUp, IWorkorderNew } from '../../models';
import { WorkorderService } from '../../services';

@Component({
    selector: 'app-assignment-create',
    templateUrl: './assignment-create.component.html',
    styleUrls: ['./assignment-create.component.less']
})
export class AssignmentCreateComponent extends BaseComponentOnDestroy implements OnInit {
    public routerParams: any;
    public phxDialogComponentConfigModel: PhxDialogComponentConfigModel = null;
    assignment: IWorkorderNew;
    formGroupSetup: IFormGroupSetup;
    inputFormGroup: FormGroup<IWorkorderNew>;
    listProfileType: Array<CodeValue>;
    listOrganizationClient: Array<ICommonListsItem>;
    listWorkOrderTemplates: any;
    readonly codeValueGroups = CodeValueGroups;
    phxConstants = PhxConstants;
    isRegularPlacement: boolean;
    atsOwnerLabel: string;
    sourceId: number;
    placementId: number;
    listFilteredWorkOrderTemplates: Array<any> = [];
    listUserProfileWorker: any;
    workOrderCreateInProgress = false;
    @ViewChild(PhxDialogComponent, { static: true })
    phxDialogComponent: PhxDialogComponent;
    ValidationMessages: any;
    defaultValues: IWorkorderNew;
    selectedProfileWorker: any;
    selectedProfileWorkerUrlLink: string;
    tooltipFields: Array<ToolTipField> = [
        { label: 'Email', value: 'PrimaryEmail' },
        { label: 'Organization', value: 'OrganizationName' },
        { label: 'Update Date', value: 'LastModifiedDatetime' }
    ];

    constructor(
        private fb: FormBuilder,
        private customFieldService: CustomFieldService,
        private router: Router,
        public commonService: CommonService,
        private codeValueService: CodeValueService,
        private workorderService: WorkorderService,
        private listObservableService: CommonListsObservableService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private organizationDataService: OrganizationDataService
    ) {
        super();
        this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.fb, customFieldService: this.customFieldService };
    }

    get billingRatesFormArray(): FormArray<IBillingRateSetUp> {
        return this.inputFormGroup.get('BillingRates') as FormArray<IBillingRateSetUp>;
    }

    get paymentRatesFormArray(): FormArray<IPaymentRateSetUp> {
        return this.inputFormGroup.get('PaymentRates') as FormArray<IPaymentRateSetUp>;
    }
    
    public static formGroupWorkorderSetupForBillingRate(formGroupSetup: IFormGroupSetup, billingRates: Array<IBillingRateSetUp>): FormArray<IBillingRateSetUp> {
        return formGroupSetup.formBuilder.array<IBillingRateSetUp>(
            billingRates.map((rate: IBillingRateSetUp, index) => {
                return formGroupSetup.hashModel.getFormGroup<IBillingRateSetUp>(formGroupSetup.toUseHashCode, 'IBillingRateSetUp', rate, index, () =>
                    formGroupSetup.formBuilder.group<IBillingRateSetUp>({
                        RateTypeId: [rate.RateTypeId],
                        Rate: [rate.Rate]
                    })
                );
            })
        );
    }

    public static formGroupWorkorderSetupForPayementRate(formGroupSetup: IFormGroupSetup, paymentRates: Array<IPaymentRateSetUp>): FormArray<IPaymentRateSetUp> {
        return formGroupSetup.formBuilder.array<IPaymentRateSetUp>(
            paymentRates.map((rate: IPaymentRateSetUp, index) => {
                return formGroupSetup.hashModel.getFormGroup<IPaymentRateSetUp>(formGroupSetup.toUseHashCode, 'IPaymentRateSetUp', rate, index, () =>
                    formGroupSetup.formBuilder.group<IPaymentRateSetUp>({
                        RateTypeId: [rate.RateTypeId],
                        Rate: [rate.Rate],
                        IsApplyDeductions: [rate.IsApplyDeductions],
                        IsApplyVacation: [rate.IsApplyVacation],
                        IsApplyStatHoliday: [rate.IsApplyStatHoliday]
                    })
                );
            })
        );
    }

    ngOnInit() {
        this.getCodeValueListsStatic();
        this.getTemplatesByEntityTypeId();
        combineLatest([this.getListUserProfileWorker(), this.setupPage()])
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe(
                ([, assignment]) => {
                    if (assignment.SuggestedUserProfileIdWorker) {
                        this.setselectedProfileWorker(assignment.SuggestedUserProfileIdWorker);
                    }
                }
            );
    }
    setupPage(): Observable<IWorkorderNew> {
        const sub = new Subject<IWorkorderNew>();

        this.activatedRoute.params
            .pipe(
                switchMap((params: Params) => {
                    this.routerParams = {
                        ...params,
                        importFromAts: params.importfromats === 1,
                        atsSourceId: Number(params.atssourceId),
                        atsPlacementId: Number(params.atsplacementId)
                    };

                    this.isRegularPlacement = false;
                    this.atsOwnerLabel = 'Owner';
                    this.sourceId = Number(this.routerParams.atsSourceId);
                    this.placementId = this.routerParams.atsPlacementId ? Number(this.routerParams.atsPlacementId) : 0;
                    return this.routerParams.atsSourceId > 0 && this.routerParams.atsPlacementId > 0
                        ? this.workorderService.getAts(this.routerParams.atsSourceId, this.routerParams.atsPlacementId)
                        : observableOf(null);
                }),
                catchError((e: HttpErrorResponse) => {
                  // eslint-disable-next-line max-len
                    const contactSupportMessage = 'Please wait several minutes and try again. If the issue continues, please contact Application Support for assistance (see the Resource Center > Support section)';
                    if (e.status === 500 && e.statusText === 'BullhornConnectivityException') {
                        this.navigateOnError(e.statusText);
                    } else if (e.status === 500 && e.statusText === 'BullhornDataException') {
                        this.navigateOnError('Unable to create Work Order - ' + e.error);
                    } else if (e.status === 500 && e.statusText === 'BullhornMdmLobMatchException') {
                        this.navigateOnError(`Placement of type ${e.error} cannot be created as a Work Order`);
                    } else if (e.status === 500 && e.statusText === 'BullhornMdmDataException') {
                        this.navigateOnError(`Error while retrieving data from the MDM database. ${contactSupportMessage}`);
                    } else if (e.status === 500 && e.statusText === 'BullhornMdmConnectivityException') {
                        this.navigateOnError(`Unable to connect to MDM database. ${contactSupportMessage}`);
                    } else {
                        this.navigateOnError(`Unable to create Work Order - ${e.error.Message} - ${e.error.Description}`);
                    }

                    return throwError(() => e);
                }),
                takeUntil(this.isDestroyed$))
            .subscribe((newWorkorder) => {
                if (this.routerParams.atsSourceId > 0 && this.routerParams.atsPlacementId > 0 && newWorkorder?.AtsSourceId === 0 && newWorkorder.AtsPlacementId === 0) {
                    this.navigateOnError('AtsPlacementNotFound');
                } else if (newWorkorder && newWorkorder.Status !== 'Approved') {
                    this.navigateOnError('AtsPlacementNotApproved');
                } else {
                    this.assignment = (newWorkorder ? newWorkorder : this.setDefaultValues()) as IWorkorderNew;
                    this.onInitSetup(this.assignment);
                    sub.next(this.assignment);
                }
            }
            );
        return sub.asObservable();

    }

    navigateOnError(errorCode: string) {
        this.router.navigate(['next', 'workorder', 'createsetup', 'importfromats',
            this.routerParams.importfromats ? 1 : 0,
            'atssource',
            this.routerParams.atsSourceId,
            'atsplacement',
            this.routerParams.atsPlacementId
        ], { queryParams: { errorCode: `${errorCode}` } });
    }

    setDefaultValues() {
        const newWorkorder: IWorkorderNew = {
            Status: '',
            AtsClientDisplayName: '',
            AtsClientId: null,
            AtsPlacementId: this.placementId,
            AtsSourceId: this.sourceId,
            AtsWorkerId: null,
            AtsWorkerName: '',
            AtsPlacementType: '',
            AtsJobOwnerId: null,
            AtsJobOwnerName: '',
            AtsRecruiterId: null,
            AtsRecruiterName: '',
            BillingRates: [],
            PaymentRates: [],
            BillingRateUnitId: null,
            EndDate: null,
            StartDate: null,
            MappedOrganizationIdClients: [],
            MappedUserProfileIdWorker: null,
            PaymentRateUnitId: null,
            SuggestedOrganizationClientLegalName: '',
            SuggestedOrganizationIdClient: null,
            SuggestedUserProfileIdWorker: null,
            SuggestedUserProfileWorkerName: '',
            FboJobOwnerUserProfileId: null,
            FboJobOwnerName: '',
            FboJobOwnerIsActive: null,
            FboRecruiterUserProfileId: null,
            FboRecruiterName: '',
            FboRecruiterIsActive: null,
            LineOfBusinessId: null,
            TemplateId: null
        };
        return newWorkorder;
    }
    validateLOBAndSubmit() {
        if (this.inputFormGroup.value.LineOfBusinessId === this.phxConstants.LineOfBusiness.PermPlacement) {
          const errMessage = 'Cannot create Work Order - ATS Placement Id is a Perm Placement';
          this.navigateOnError(errMessage);
          return;
        }
    
        this.organizationDataService.getClientRoleSelectedLOBCodes(this.inputFormGroup.value.SuggestedOrganizationIdClient)
        .subscribe(async (selectedLOBCodes: string[]) => {
            const currentLOB = this.codeValueService.getCodeValues(this.codeValueGroups.LineOfBusiness, true).find(lob => this.inputFormGroup.value.LineOfBusinessId === lob.id);
            const doSelectedLOBCodesIncludeCurrentLOBCode = selectedLOBCodes.includes(currentLOB?.code);
      
            if (!doSelectedLOBCodesIncludeCurrentLOBCode) {
              const errMessage = 'Line of Business <b>' + currentLOB?.description + '</b>  is not activated for this client. Do you want to proceed anyway?';
  
              const result = await this.dialogService.confirmDialog('Inactive Line of Businesss', errMessage);
              if (result === DialogResultType.No) {
                this.navigateOnError(errMessage);
                return;
              }
            }
            
            this.workOrderCreate();
        });
    }
    
    workOrderCreate() {
        this.workOrderCreateInProgress = true;
        this.commonService.logSuccess('Work Order Creation in Progress');
        
        const command = {
            UserProfileIdWorker: this.inputFormGroup.value.MappedUserProfileIdWorker || this.inputFormGroup.value.SuggestedUserProfileIdWorker,
            OrganizationIdClient: this.inputFormGroup.get('SuggestedOrganizationIdClient').value,

            AtsSourceId: null,
            AtsPlacementId: null,
            LineOfBusiness: this.inputFormGroup.value.LineOfBusinessId,
            TemplateId: null,
            UiVersion: 1
        };

        if (this.inputFormGroup.value.AtsSourceId && this.inputFormGroup.value.AtsPlacementId) {
            command.AtsSourceId = this.inputFormGroup.value.AtsSourceId;
            command.AtsPlacementId = this.inputFormGroup.value.AtsPlacementId;
        }
        if (this.inputFormGroup.value.TemplateId) {
            command.TemplateId = this.inputFormGroup.value.TemplateId;
        }

        this.workorderService
            .workOrderNew(command)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe({
                next: (workOrderNewResponseSucces: any) => {
                    if (workOrderNewResponseSucces.EntityId) {
                        this.router.navigate(['/next', 'workorder', 0, 0, workOrderNewResponseSucces.EntityId, 'core']);
                    }
                },
                error: (responseError) => {
                    this.ValidationMessages = this.commonService.responseErrorMessages(responseError, null);
                    this.commonService.logValidationMessages(this.ValidationMessages);
                    this.workOrderCreateInProgress = false;
                }
            });
    }

    onInitSetup(workOrderSetup: IWorkorderNew) {

        this.inputFormGroup = this.formGroupWorkorderSetup(this.formGroupSetup, workOrderSetup);
        this.getListOrganizationClient();
        this.updateFilteredTemplates();

        this.inputFormGroup.controls.SuggestedOrganizationIdClient.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed$))
            .subscribe(() => {
                this.updateFilteredTemplates();
            });

        // Attach listener
        this.inputFormGroup.controls.SuggestedUserProfileIdWorker.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.isDestroyed$)
        ).subscribe(x => {
            this.setselectedProfileWorker(x);
        });
    }

    formGroupWorkorderSetup(formGroupSetup: IFormGroupSetup, workOrderSetup: IWorkorderNew) {
        const responseError = { ValidationMessages: [] };
        const errFields = [];

        if (workOrderSetup.AtsPlacementId !== 0) {
            if (workOrderSetup.StartDate == null) {
                errFields.push('Start Date');
            }

            if (workOrderSetup.EndDate == null) {
                errFields.push('End Date');
            }

            if (workOrderSetup.AtsJobOwnerId == null || workOrderSetup.AtsJobOwnerId === 0) {
                errFields.push('Owner');
            }

            if (errFields.length > 0) {
                const errMessage = 'Invalid ' + errFields.join(', ') + ' from ATS';

                responseError.ValidationMessages.push({ PropertyName: '', Message: errMessage });
                this.ValidationMessages = responseError;
            } 
        }

        this.isRegularPlacement = workOrderSetup.LineOfBusinessId === this.phxConstants.LineOfBusiness.Regular
          || workOrderSetup.LineOfBusinessId === this.phxConstants.LineOfBusiness.SubVendorPlacement;
        this.atsOwnerLabel = this.isRegularPlacement ? 'Primary Owner' : 'Owner';

        const formGroup = formGroupSetup.hashModel.getFormGroup<IWorkorderNew>(formGroupSetup.toUseHashCode, 'IWorkorderNew', workOrderSetup, 0, () =>
            formGroupSetup.formBuilder.group<IWorkorderNew>({
                Status: workOrderSetup.Status,
                AtsSourceId: [this.sourceId],
                AtsPlacementId: [workOrderSetup.AtsPlacementId],
                StartDate: [moment(workOrderSetup.StartDate).format('YYYY-MM-DD')],
                EndDate: [moment(workOrderSetup.EndDate).format('YYYY-MM-DD')],
                BillingRates: AssignmentCreateComponent.formGroupWorkorderSetupForBillingRate(formGroupSetup, workOrderSetup.BillingRates),
                BillingRateUnitId: [workOrderSetup.BillingRateUnitId],
                PaymentRates: AssignmentCreateComponent.formGroupWorkorderSetupForPayementRate(formGroupSetup, workOrderSetup.PaymentRates),
                PaymentRateUnitId: [workOrderSetup.PaymentRateUnitId],
                AtsClientId: [workOrderSetup.AtsClientId],
                AtsClientDisplayName: [workOrderSetup.AtsClientDisplayName],
                AtsWorkerId: [workOrderSetup.AtsWorkerId],
                AtsWorkerName: [workOrderSetup.AtsWorkerName],
                AtsPlacementType: [workOrderSetup.AtsPlacementType],
                AtsJobOwnerId: [workOrderSetup.AtsJobOwnerId],
                AtsJobOwnerName: [workOrderSetup.AtsJobOwnerName],
                AtsRecruiterId: [workOrderSetup.AtsRecruiterId],
                AtsRecruiterName: [workOrderSetup.AtsRecruiterName],
                MappedOrganizationIdClients: [workOrderSetup.MappedOrganizationIdClients],
                MappedUserProfileIdWorker: [workOrderSetup.MappedUserProfileIdWorker],
                SuggestedOrganizationIdClient: [workOrderSetup.SuggestedOrganizationIdClient],
                SuggestedUserProfileIdWorker: [workOrderSetup.SuggestedUserProfileIdWorker],
                FboJobOwnerUserProfileId: [workOrderSetup.FboJobOwnerUserProfileId],
                FboJobOwnerName: [workOrderSetup.FboJobOwnerName],
                FboJobOwnerIsActive: [workOrderSetup.FboJobOwnerIsActive],
                FboRecruiterUserProfileId: [workOrderSetup.FboRecruiterUserProfileId],
                FboRecruiterName: [workOrderSetup.FboRecruiterName],
                FboRecruiterIsActive: [workOrderSetup.FboRecruiterIsActive],
                LineOfBusinessId: [workOrderSetup.LineOfBusinessId],
                TemplateId: [null]
            })
        );

        // Don't validate suggested controls if precisely one mapping exists
        if (workOrderSetup.MappedOrganizationIdClients.length !== 1) {
            formGroup.controls.SuggestedOrganizationIdClient
                .setValidators(Validators.required);
        }
        if (!workOrderSetup.MappedUserProfileIdWorker) {
            formGroup.controls.SuggestedUserProfileIdWorker
                .setValidators(Validators.required);
        }

        return formGroup;
    }

    private setselectedProfileWorker(wId: any): any {

        this.selectedProfileWorker = this.listUserProfileWorker.find(i => i.Id === wId);

        if (this.selectedProfileWorker) {
            this.selectedProfileWorkerUrlLink = `/next/contact/${this.selectedProfileWorker.Contact.Id}/profile/`;
            this.selectedProfileWorkerUrlLink += `${this.commonService.getUserProfileTypeSufix(this.selectedProfileWorker.ProfileTypeId)}/${this.selectedProfileWorker.Id}`;
        }
        return null;
    }

    private getCodeValueListsStatic() {
        this.listProfileType = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true);
    }

    private getListOrganizationClient() {
        const isMappedClient = this.inputFormGroup.get('MappedOrganizationIdClients').value
            && this.inputFormGroup.get('MappedOrganizationIdClients').value.length > 0;

        this.listObservableService
            .listDetailedOrganizationClients$()
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((response) => {
                if (response) {
                    this.listOrganizationClient = response
                        .filter(i => isMappedClient ? this.inputFormGroup.get('MappedOrganizationIdClients').value.includes(i.Id) : true)
                        .map(i => ({
                            ...i,
                            DisplayText: i.Id + ' - ' + i.DisplayText
                        }));
                }
            });
    }

    private getListUserProfileWorker(): Observable<any> {
        const sub = new Subject<any>();
        this.listObservableService.listUserProfileWorkers$()
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((response) => {
                if (response) {
                    this.listUserProfileWorker = this.getFilteredListUserProfileWorker(response);
                    this.listUserProfileWorker.forEach((item: any) => {
                        const profileName = this.listProfileType.find(i => i.id === item.ProfileTypeId);
                        const displayItem = item.Id + '-' + item.Contact.FullName + '-' + profileName.description;
                        item.OrganizationName = item.Organization ? item.Organization.DisplayName : null;
                        if (item.LastModifiedDatetime) {
                            item.LastModifiedDatetime = moment(item.LastModifiedDatetime).format('MMM DD YYYY');
                        }
                        item.DisplayValue = displayItem;
                    });
                    sub.next(this.listUserProfileWorker);
                }
            });
        return sub.asObservable();
    }

    private getFilteredListUserProfileWorker(items: any) {
        // 15634 - workers belonging to certain profileTypes should not appear if they are missing an org
        return items.filter(item => item.ProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianInc
            || item.ProfileTypeId === PhxConstants.UserProfileType.WorkerSubVendor
            || item.ProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesLLC
            ? item.OrganizationId != null
            : true);
    }

    private getTemplatesByEntityTypeId() {
        this.workorderService
            .getTemplatesByEntityTypeId(this.phxConstants.EntityType.Assignment).pipe(
                takeUntil(this.isDestroyed$))
            .subscribe(result => {
                this.listWorkOrderTemplates = result.Items;
                this.updateFilteredTemplates();
            });
    }

    private updateFilteredTemplates() {
        const listFilteredWorkOrderTemplates = [];
        if (this.inputFormGroup) {
            // getting the value from the control is more reliable here
            const organizationIdClient = this.inputFormGroup.get('SuggestedOrganizationIdClient').value;
            const lineOfBusiness = this.inputFormGroup.get('LineOfBusinessId').value;

            if (this.listWorkOrderTemplates && organizationIdClient) {
                this.listWorkOrderTemplates.forEach(entity => {
                    entity.Entity.WorkOrders.forEach(versions => {
                        if (versions.hasOwnProperty('WorkOrderVersions')) {
                            versions.WorkOrderVersions.forEach(v => {
                                if (v.LineOfBusinessId === lineOfBusiness) {
                                    v.BillingInfoes.forEach(bi => {
                                        if (bi.OrganizationIdClient === organizationIdClient) {
                                            entity.DisplayValue = entity.Name + ' (' + entity.Description + ')';
                                            if (!listFilteredWorkOrderTemplates.includes(entity)) {
                                                listFilteredWorkOrderTemplates.push(entity);
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                });
            }

            this.listFilteredWorkOrderTemplates = listFilteredWorkOrderTemplates;
            if (!listFilteredWorkOrderTemplates.some(x => x.Id === this.inputFormGroup.value.TemplateId)) {
                this.inputFormGroup.controls.TemplateId.setValue(null, { emitEvent: false });
            }
        }
    }
}
