import { Pipe, PipeTransform } from '@angular/core';

/** The purpose of this pipe is to ensure the key exists on the object,
 * it is a type-safe alternative to passing loose strings representing object keys */

@Pipe({
  name: 'fieldName'
})
export class FieldNamePipe implements PipeTransform {
  transform<T>(i: T, k: FieldNameOf<T>): FieldNameOf<T> {
    return k;
  }
}

type FieldNameOf<T> = T extends Array<infer A> ? keyof A : keyof T;
