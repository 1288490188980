import { FormBuilder, FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { TypedClientSpecificFields } from './model/typed-client-specific-fields';
import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IWorkOrder } from 'src/app/workorder/models';
import { Validators } from '@angular/forms';
import { startWith, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class TypedClientSpecificFieldsFormService {
  formGroup: FormGroup<TypedClientSpecificFields>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  createForm(workOrder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<TypedClientSpecificFields> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<TypedClientSpecificFields>({
      IsFederalWorker: [workOrder.WorkOrderVersion.IsFederalWorker, Validators.required],
      IsControlledGoods: [workOrder.WorkOrderVersion.IsControlledGoods, Validators.required]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners(organizationHasFederalWorkers$: Observable<boolean>) {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.setupFederalWorkerListener(organizationHasFederalWorkers$);
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder.WorkOrderVersion.IsFederalWorker = this.formGroup.getRawValue().IsFederalWorker;
    workOrder.WorkOrderVersion.IsControlledGoods = this.formGroup.getRawValue().IsControlledGoods;

    return workOrder;
  }

  updateForm(workOrder: IWorkOrder) {
    const workOrderVersion = workOrder.WorkOrderVersion;

    this.formGroup.patchValue({
      IsFederalWorker: workOrderVersion.IsFederalWorker,
      IsControlledGoods: workOrderVersion.IsControlledGoods
    }, { emitEvent: false });
  }

  setupFederalWorkerListener(organizationHasFederalWorkers$: Observable<boolean>) {
    const IsControlledGoodsControl = this.formGroup.controls.IsControlledGoods;
    this.formGroup.controls.IsFederalWorker.valueChanges.pipe(
      startWith(this.formGroup.controls.IsFederalWorker.value),
      withLatestFrom(organizationHasFederalWorkers$),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(([isFederalWorker, organizationHasFederalWorker]) => {
      if (organizationHasFederalWorker) {
        if (isFederalWorker) {
          IsControlledGoodsControl.enable({ emitEvent: false });
        }
        else {
          IsControlledGoodsControl.patchValue(null);
          IsControlledGoodsControl.disable({ emitEvent: false });
        }
      } else {
        this.formGroup.disable();
      }
    });
  }
}
