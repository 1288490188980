import { Component, OnInit, Input, Output, HostBinding, OnDestroy, EventEmitter } from '@angular/core';

import { PhxAccordionComponent } from '../phx-accordion/phx-accordion.component';

@Component({
  selector: 'app-phx-accordion-group',
  templateUrl: './phx-accordion-group.component.html',
  styleUrls: ['./phx-accordion-group.component.less']
})
export class PhxAccordionGroupComponent implements OnInit, OnDestroy {
  @Output() isOpenChange = new EventEmitter<boolean>();

  @Input() public heading: string;
  @Input() public panelClass: string;
  @Input() public isDisabled: boolean;
  @Input() public showToggle = true;
  @Input() public showBorders = false;
  @Input() public isAnimated = true;

  @HostBinding('class.panel-open')
  @Input()
  public get isOpen(): boolean {
    return this.isAccordionOpen ?? false;
  }

  public set isOpen(value: boolean) {
    this.isAccordionOpen = value;
    this.isOpenChange.emit(this.isOpen);
    if (value) {
      this.accordion.closeOtherPanels(this);
    }
  }

  protected isAccordionOpen: boolean;

  public constructor(private accordion: PhxAccordionComponent) {}

  public ngOnInit(): void {
    this.panelClass = this.panelClass || 'panel-default';
    this.accordion.addGroup(this);
  }

  public ngOnDestroy(): void {
    this.accordion.removeGroup(this);
  }

  public toggleOpen(): void {
    if (!this.isDisabled) {
      this.isOpen = !this.isOpen;
    }
  }
}
