<div class="clearfix">
    <a data-target="#note-list" data-toggle="collapse" (click)="showNote()"
       class="panel-header panel-header-link col-lg-6 notes-panel notes-side-header" >
        <span i18n="@@phx.noteHeader.notes">NOTES</span>
        <span class="badge" *ngIf="noteCount > 0">{{noteCount}}</span>
    </a>
    <button type="button" class="btn btn-material pull-right" data-toggle="collapse" (click)="navigateToContactNotes()">
        <i class="material-icons md-dark">launch</i>
    </button>
</div>

<div class="note-list" *ngIf="showPreview" id="note-list">
    <div class="note-list-container">
        <div class="note-mini" *ngFor="let note of notes| slice:0:5; let i=index">
            <div>
                <div>
                    <label class="label-summary">
                        {{note.CreatedDatetime| date:dateColumnFormat}}<br/>by {{note.CommentOwnerName}}
                    </label>
                </div>
                <div>
                    <span class="note-description" [innerHTML]="note.Comment"></span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="notes?.length > 2" class="text-center">
        <a (click)="navigateToContactNotes()" class="cursor-pointer" type="button" data-toggle="collapse">More...</a>
    </div>
</div>
