import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDataService {
  private readonly apiEndPoint = environment.organizationServiceApiEndpoint;
  constructor(private apiService: ApiService) {}

  public getClientRoleSelectedLOBCodes(id: number) {
    return this.apiService.httpGetRequest<string[]>(`organization/${id}/client-role/selected-lob-codes`, this.apiEndPoint);
  }
}
