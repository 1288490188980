// angular
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// common
import { WorkflowService, CodeValueGroups, PhxConstants } from '../../../common';
import { ITabCoreDetails, IWorkOrder } from '../../models';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { ClickAnalyticsData } from '../../../common/services/google-analytics/models/click-analytics-data.model';
// workorder
import { CoreDetailFormService } from '../../services';

@Component({
  selector: 'app-workorder-header',
  templateUrl: './workorder-header.component.html'
})
export class WorkorderHeaderComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  coreDetailsFormGroup: FormGroup<ITabCoreDetails>;

  @Input()
  workOrder: IWorkOrder;
  html = {
    nextStatusId: null as number | null,
    codeValueGroups: CodeValueGroups,
    phxConstants: PhxConstants
  };

  readonly workerLinkClickAnalyticsData: ClickAnalyticsData = {
    feature: 'Work Order',
    type: 'Redirection link',
    action: 'View Worker Profile'
  };

  constructor(private workflowService: WorkflowService, private router: Router, private coreDetailFormService: CoreDetailFormService) {
    super();
  }

  ngOnInit() {
    this.coreDetailsFormGroup = this.coreDetailFormService.formGroup;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.workOrder) {
      const wovStatusId = this.workOrder?.WorkOrderVersion?.StatusId ?? null;
      this.html.nextStatusId = this.workflowService.getPreferredEntityStatusId(PhxConstants.EntityType.WorkOrderVersion, wovStatusId);
    }
  }

  onViewHistory() {
    if (this.workOrder) {
      this.router.navigate(['/next', 'workorder', this.workOrder.AssignmentId, this.workOrder.WorkOrderId, this.workOrder.WorkOrderVersion.Id, 'activity', 'workflow']);
    }
  }

  isWOUnderTermination() {
    return (
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTerminationNotice ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTermination ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Terminated
    );
  }
}
