import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ValidationExtensions } from 'src/app/common';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IRateNegotiation, IWorkOrder } from '../../models';
import { Validators } from '@angular/forms';

@Injectable()
export class PartyRateNegotiationFormService implements IFormService {

  formGroup: FormGroup<IRateNegotiation>;
  private isRootComponentDestroyed$: Subject<boolean>;

  get isRateCardUsedFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsRateCardUsed') as FormControl<boolean>;
  }

  get isRateCardUsedChange$() {
    return this.isRateCardUsedFormControl.valueChanges;
  }

  constructor(private fb: FormBuilder) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const rateNegotiation: IRateNegotiation = this.mapWorkOrderToFormData(workorder);

    // Blank required comment for now since we are using [forceValidation] in phx-form-control since our form never get's dirty (re-created on change)
    const initialAskBillRateValidators = [ValidationExtensions.required(' ')];

    if (rateNegotiation.InitialAskPayRate != null && !isNaN(rateNegotiation.InitialAskPayRate)) {
      // Putting with syncrounous validators for now (can't use an async validator)
      // Async validators don't work with our Tab Navigation invalid icon, since the Valid property is not async.
      // Ideally, we would pass a Valid$ observable, but since we keep re-creating the form on every change, it's never dirty
      initialAskBillRateValidators.push(
        ValidationExtensions.minNumber(+rateNegotiation.InitialAskPayRate, 'Must be equal or greater than Initial Ask Pay Rate')
      );
    } else {
      initialAskBillRateValidators.push(Validators.min(0));
    }

    this.formGroup = this.fb.group<IRateNegotiation>({
      InitialAskPayRate: [rateNegotiation.InitialAskPayRate, [Validators.required, Validators.min(0)]],
      InitialAskBillRate: [rateNegotiation.InitialAskBillRate, initialAskBillRateValidators],
      FeeSavings: [rateNegotiation.FeeSavings, [Validators.required, Validators.min(0)]],
      BurdenSavings: [rateNegotiation.BurdenSavings, [Validators.required, Validators.min(0)]],
      AdditionalRateSavings: [rateNegotiation.AdditionalRateSavings, [Validators.required, Validators.min(0)]],
      JobPostingNumber: [rateNegotiation.JobPostingNumber, [Validators.required, Validators.maxLength(20)]],
      RateCardJobTitle: [rateNegotiation.RateCardJobTitle, [Validators.required, Validators.maxLength(100)]],
      ClientGroup: [rateNegotiation.ClientGroup, [Validators.maxLength(100)]],
      RateNegotiationAdditionalInfo: [rateNegotiation.RateNegotiationAdditionalInfo, [Validators.maxLength(100)]],
      MaxBillRate: [rateNegotiation.MaxBillRate, [Validators.required, Validators.min(0)]],
      BurdenUsedPercentage: [rateNegotiation.BurdenUsedPercentage,
      [Validators.required, Validators.min(0), Validators.max(100)]
      ],
      IsRatePrenegotiated: [rateNegotiation.IsRatePrenegotiated, [Validators.required]],
      IsRateCardUsed: [rateNegotiation.IsRateCardUsed ?? true, [Validators.required]],
      IsRateWithinRateCard: [rateNegotiation.IsRateWithinRateCard, rateNegotiation.IsRateCardUsed ? [Validators.required] : null],
      SubvendorActualPayRate: [rateNegotiation.SubvendorActualPayRate, [Validators.min(0)]],
      SubvendorActualProfileTypeId: [rateNegotiation.SubvendorActualProfileTypeId],
      SubvendorActualBurdenCosts: [rateNegotiation.SubvendorActualBurdenCosts, [Validators.min(0)]],
      SubvendorMargin: [rateNegotiation.SubvendorMargin, [Validators.min(0)]]
    });

    this.setFormGroupStatus(workorder.WorkOrderVersion.IsRateNegotiationEnabled);

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.isRateCardUsedChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(() => {

      this.formGroup.get('IsRateWithinRateCard').setValidators([Validators.required]);

      this.formGroup.patchValue({
        IsRateWithinRateCard: this.formGroup.get('IsRateWithinRateCard').value || null,
      }, {
        emitEvent: false
      });
    });

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const rateNegotiation: IRateNegotiation = this.formGroup.getRawValue();
    workOrder.WorkOrderVersion.InitialAskPayRate = rateNegotiation.InitialAskPayRate;
    workOrder.WorkOrderVersion.InitialAskBillRate = rateNegotiation.InitialAskBillRate;
    workOrder.WorkOrderVersion.FeeSavings = rateNegotiation.FeeSavings;
    workOrder.WorkOrderVersion.BurdenSavings = rateNegotiation.BurdenSavings;
    workOrder.WorkOrderVersion.AdditionalRateSavings = rateNegotiation.AdditionalRateSavings;
    workOrder.WorkOrderVersion.JobPostingNumber = rateNegotiation.JobPostingNumber;
    workOrder.WorkOrderVersion.RateCardJobTitle = rateNegotiation.RateCardJobTitle;
    workOrder.WorkOrderVersion.ClientGroup = rateNegotiation.ClientGroup;
    workOrder.WorkOrderVersion.RateNegotiationAdditionalInfo = rateNegotiation.RateNegotiationAdditionalInfo;
    workOrder.WorkOrderVersion.MaxBillRate = rateNegotiation.MaxBillRate;
    workOrder.WorkOrderVersion.BurdenUsedPercentage = rateNegotiation.BurdenUsedPercentage;
    workOrder.WorkOrderVersion.IsRatePrenegotiated = rateNegotiation.IsRatePrenegotiated;
    workOrder.WorkOrderVersion.IsRateCardUsed = rateNegotiation.IsRateCardUsed;
    workOrder.WorkOrderVersion.IsRateWithinRateCard = rateNegotiation.IsRateWithinRateCard;
    workOrder.WorkOrderVersion.SubvendorActualPayRate = rateNegotiation.SubvendorActualPayRate;
    workOrder.WorkOrderVersion.SubvendorActualProfileTypeId = rateNegotiation.SubvendorActualProfileTypeId;
    workOrder.WorkOrderVersion.SubvendorActualBurdenCosts = rateNegotiation.SubvendorActualBurdenCosts;
    workOrder.WorkOrderVersion.SubvendorMargin = rateNegotiation.SubvendorMargin;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const rateNegotiation: IRateNegotiation = this.mapWorkOrderToFormData(workorder);

    // Blank required comment for now since we are using [forceValidation] in phx-form-control since our form never get's dirty (re-created on change)
    const initialAskBillRateValidators = [ValidationExtensions.required(' ')];

    if (rateNegotiation.InitialAskPayRate != null && !isNaN(rateNegotiation.InitialAskPayRate)) {
      // Putting with syncrounous validators for now (can't use an async validator)
      // Async validators don't work with our Tab Navigation invalid icon, since the Valid property is not async.
      // Ideally, we would pass a Valid$ observable, but since we keep re-creating the form on every change, it's never dirty
      initialAskBillRateValidators.push(
        ValidationExtensions.minNumber(+rateNegotiation.InitialAskPayRate, 'Must be equal or greater than Initial Ask Pay Rate')
      );
    } else {
      initialAskBillRateValidators.push(Validators.min(0));
    }

    this.formGroup.get('InitialAskPayRate').setValidators([Validators.required, Validators.min(0)]);
    this.formGroup.get('InitialAskBillRate').setValidators(initialAskBillRateValidators);
    this.formGroup.get('FeeSavings').setValidators([Validators.required, Validators.min(0)]);
    this.formGroup.get('BurdenSavings').setValidators([Validators.required, Validators.min(0)]);
    this.formGroup.get('AdditionalRateSavings').setValidators([Validators.required, Validators.min(0)]);
    this.formGroup.get('JobPostingNumber').setValidators([Validators.required, Validators.maxLength(20)]);
    this.formGroup.get('RateCardJobTitle').setValidators([Validators.required, Validators.maxLength(100)]);
    this.formGroup.get('ClientGroup').setValidators([Validators.maxLength(100)]);
    this.formGroup.get('RateNegotiationAdditionalInfo').setValidators([Validators.maxLength(100)]);
    this.formGroup.get('MaxBillRate').setValidators([Validators.required, Validators.min(0)]);
    this.formGroup.get('BurdenUsedPercentage').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
    this.formGroup.get('IsRatePrenegotiated').setValidators([Validators.required]);
    this.formGroup.get('IsRateCardUsed').setValidators([Validators.required]);
    this.formGroup.get('IsRateWithinRateCard').setValidators(rateNegotiation.IsRateCardUsed ? [Validators.required] : null);
    this.formGroup.get('SubvendorActualPayRate').setValidators([Validators.min(0)]);
    this.formGroup.get('SubvendorActualBurdenCosts').setValidators([Validators.min(0)]);
    this.formGroup.get('SubvendorMargin').setValidators([Validators.min(0)]);

    this.formGroup.patchValue({
      InitialAskPayRate: rateNegotiation.InitialAskPayRate,
      InitialAskBillRate: rateNegotiation.InitialAskBillRate,
      FeeSavings: rateNegotiation.FeeSavings,
      BurdenSavings: rateNegotiation.BurdenSavings,
      AdditionalRateSavings: rateNegotiation.AdditionalRateSavings,
      JobPostingNumber: rateNegotiation.JobPostingNumber,
      RateCardJobTitle: rateNegotiation.RateCardJobTitle,
      ClientGroup: rateNegotiation.ClientGroup,
      RateNegotiationAdditionalInfo: rateNegotiation.RateNegotiationAdditionalInfo,
      MaxBillRate: rateNegotiation.MaxBillRate,
      BurdenUsedPercentage: rateNegotiation.BurdenUsedPercentage,
      IsRatePrenegotiated: rateNegotiation.IsRatePrenegotiated,
      IsRateCardUsed: rateNegotiation.IsRateCardUsed == null ? true : rateNegotiation.IsRateCardUsed,
      IsRateWithinRateCard: rateNegotiation.IsRateWithinRateCard,
      SubvendorActualPayRate: rateNegotiation.SubvendorActualPayRate,
      SubvendorActualProfileTypeId: rateNegotiation.SubvendorActualProfileTypeId,
      SubvendorActualBurdenCosts: rateNegotiation.SubvendorActualBurdenCosts,
      SubvendorMargin: rateNegotiation.SubvendorMargin
    }, { emitEvent: false });

    this.setFormGroupStatus(workorder.WorkOrderVersion.IsRateNegotiationEnabled);
  }

  private setFormGroupStatus(isEnabled: boolean) {
    if (isEnabled && this.formGroup.disabled) {
      this.formGroup.enable({ emitEvent: false });
    } else if (!isEnabled && !this.formGroup.disabled) {
      this.formGroup.disable({ emitEvent: false });
    }
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): IRateNegotiation {
    return {
      InitialAskPayRate: workorder.WorkOrderVersion.InitialAskPayRate,
      InitialAskBillRate: workorder.WorkOrderVersion.InitialAskBillRate,
      FeeSavings: workorder.WorkOrderVersion.FeeSavings,
      BurdenSavings: workorder.WorkOrderVersion.BurdenSavings,
      AdditionalRateSavings: workorder.WorkOrderVersion.AdditionalRateSavings,
      JobPostingNumber: workorder.WorkOrderVersion.JobPostingNumber,
      RateCardJobTitle: workorder.WorkOrderVersion.RateCardJobTitle,
      ClientGroup: workorder.WorkOrderVersion.ClientGroup,
      RateNegotiationAdditionalInfo: workorder.WorkOrderVersion.RateNegotiationAdditionalInfo,
      MaxBillRate: workorder.WorkOrderVersion.MaxBillRate,
      BurdenUsedPercentage: workorder.WorkOrderVersion.BurdenUsedPercentage,
      IsRatePrenegotiated: workorder.WorkOrderVersion.IsRatePrenegotiated,
      IsRateCardUsed: workorder.WorkOrderVersion.IsRateCardUsed,
      IsRateWithinRateCard: workorder.WorkOrderVersion.IsRateWithinRateCard,
      SubvendorActualPayRate: workorder.WorkOrderVersion.SubvendorActualPayRate,
      SubvendorActualProfileTypeId: workorder.WorkOrderVersion.SubvendorActualProfileTypeId,
      SubvendorActualBurdenCosts: workorder.WorkOrderVersion.SubvendorActualBurdenCosts,
      SubvendorMargin: workorder.WorkOrderVersion.SubvendorMargin
    };
  }
}
