import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent, interval, merge } from 'rxjs';
import { debounceTime, filter, repeat, takeUntil } from 'rxjs/operators';

import { ConfigurationService } from '@configuration/service/configuration.service';

import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { PhxConstants } from '../../model';
import { UserBehavior } from '../../model/user-behavior';
import { CommonService } from '../../services/common.service';
import { PhxSideBarService } from '../../services/phx-side-bar.service';
import { UserBehaviorService } from '../../services/user-behavior.service';
import { PhxModalComponent } from '../phx-modal/phx-modal.component';

@Component({
  selector: 'app-current-users-with-behavior',
  templateUrl: './current-users-with-behavior.component.html',
  styleUrls: ['./current-users-with-behavior.component.less']
})
export class CurrentUsersWithBehaviorComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {

  @Input() pageTypeId: number;
  @Input() primaryId: number;
  @Input() secondaryId: number = 0;

  @ViewChild('currentUsersModal') currentUsersModal: PhxModalComponent;

  private setShowDetails$ = new BehaviorSubject<number>(-1);
  private activityIndicatorEvents$ = merge(
    ...['click', 'mousemove', 'mousedown', 'scroll', 'keypress'].map(
      (eventName) => fromEvent(document, eventName)
    )
  );
  private isInactive = false;
  private inactiveBrowserSubscription: Subscription;
  private pastGASelectedBehaviors: UserBehavior[] = [];
  
  phxConstants = PhxConstants;
  allBehaviors: UserBehavior[] = [];
  filteredBehaviors$ = new BehaviorSubject<UserBehavior[]>([]);
  behaviorIndex = -1;
  behaviorDetails = '';
  behaviorName = '';

  /** NOTE: behavior details for 'all users' modal */
  allUsersDetails: { userId: number; name: string; details: string; behaviorCode: string; ProfilePicture: string; }[] = [];

  userCount = 0;
  displayCount = 0;
  sideBarPanelIsOpen = false;
  token: string;

  readonly userLimit = 6;
  readonly inactivityThresholdInMinutes = 8;

  constructor(
    private userBehaviorService: UserBehaviorService,
    private phxSideBarService: PhxSideBarService,
    private configurationService: ConfigurationService,
    private commonService: CommonService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.configurationService.isFeatureActive(PhxConstants.FeatureFlags.ShowRealTimeUserBehaviors)) {
      this.initBehaviorListener();
      this.initDetailsListener();

      this.phxSideBarService.showSideBar$().pipe(
        takeUntil(this.isDestroyed$)
      ).subscribe(panelIsOpen => this.sideBarPanelIsOpen = panelIsOpen);

      this.initInactiveBrowserListener(this.inactivityThresholdInMinutes * 60000);
      this.initActiveBrowserListener();

      this.token = this.commonService.bearerToken();

      this.initEntityChangeListener();
    }
  }

  ngOnDestroy(): void {
    this.inactiveBrowserSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  showBehaviorDetails(behaviorIndex: number, selectedBehavior: UserBehavior) {
    this.setShowDetails$.next(behaviorIndex);
    this.behaviorName = selectedBehavior.Name;
    this.behaviorDetails = this.getBehaviorSummary(selectedBehavior);
    
    this.sendSelectedBehaviorGAEvent(selectedBehavior);
  }

  hideBehaviorDetails() {
    this.setShowDetails$.next(-1);
    this.behaviorIndex = -1;
  }

  showAllUsersModal() {
    this.allUsersDetails = [];
    this.allBehaviors.forEach(behavior => {
      this.allUsersDetails.push({
        userId: behavior.UserId,
        ProfilePicture: behavior.ProfilePicture,
        name: behavior.Name,
        details: this.getBehaviorSummary(behavior),
        behaviorCode: behavior.BehaviorCode
      });
    });

    this.currentUsersModal.show();
  }

  closeModal() {
    this.currentUsersModal.hide();
  }

  /** NOTE: if profile picture doesnt load we fallback to initial */
  handleMissingPicture(event: any, behavior: UserBehavior) {
    (event.target as HTMLImageElement).style.display = 'none';
    behavior.ProfilePicture = null;
  }

  private initInactiveBrowserListener(graceTime: number) {
    /** NOTE: if the 'graceTime' transpires and no events defined in activityIndicatorEvents are fired - user is inactive */
    this.inactiveBrowserSubscription = interval(graceTime).pipe(
      filter(() => !this.isInactive),
      takeUntil(this.activityIndicatorEvents$),
      repeat(),
    ).subscribe(() => {
      this.userBehaviorService.setToInactive();
      this.isInactive = true;
    });
  }

  private initActiveBrowserListener() {
    /** NOTE: if any event defined in activityIndicatorEvents are fired - user is active */
    this.activityIndicatorEvents$.pipe(
      filter(() => this.isInactive),
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.userBehaviorService.setToActive();
      this.isInactive = false;
    });
  }

  private getBehaviorSummary(selectedBehavior: UserBehavior) {
    const minutes = this.getTimespanInMinutes(selectedBehavior);
    switch (selectedBehavior.BehaviorCode) {
      case PhxConstants.UserBehaviorCode.ReadEntity:
        return this.getBehaviorText(minutes, $localize`:@@userBehavior.viewing:viewing`);
      case PhxConstants.UserBehaviorCode.WriteEntity:
        return this.getBehaviorText(minutes, $localize`:@@userBehavior.editing:editing`);
      case PhxConstants.UserBehaviorCode.Inactive:
        return this.getBehaviorText(minutes, $localize`:@@userBehavior.idleon:idle on`);
      default:
        return this.getBehaviorText(minutes, $localize`:@@userBehavior.viewing:viewing`);
    }
  }

  private getTimespanInMinutes(selectedBehavior: UserBehavior): number {
    const tmpDate = new Date();

    const diffMs = tmpDate.getTime() - selectedBehavior.CreateDateTime.getTime();
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

    if (selectedBehavior.BehaviorCode === PhxConstants.UserBehaviorCode.Inactive) {
      diffMins = diffMins + this.inactivityThresholdInMinutes;
    }

    return diffMins;
  }

  initEntityChangeListener() {
    this.userBehaviorService.entityHasChanged$(this.pageTypeId, this.primaryId, this.secondaryId).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.commonService.logInfo(this.getUpdatedEntityMessage(this.pageTypeId), true);
    });
  }

  private getUpdatedEntityMessage(pageTypeId: number) {
    switch (pageTypeId) {
      case PhxConstants.EntityType.WorkOrder:
        return $localize`:@@userBehavior.workOrderUpdated:NOTICE: This work order has been updated by another user. Please refresh the page to view the updated version.`;
      case PhxConstants.EntityType.Organization:
        return $localize`:@@userBehavior.organizationUpdated:NOTICE: This organization has been updated by another user. Please refresh the page to view the updated version.`;
      case PhxConstants.EntityType.Contact:
        return $localize`:@@userBehavior.contactUpdated:NOTICE: This contact has been updated by another user. Please refresh the page to view the updated version.`;
      default:
        return $localize`:@@userBehavior.pageUpdated:NOTICE: This page has been updated by another user. Please refresh the page to view the updated version.`;
    }

  }

  /** TODO: sort out if this will work with translations - may need each possible string*/
  private getBehaviorText(diffMins: number, behaviorVerb: string) {
    if (diffMins === 0) {
      return $localize`:@@userBehavior.underMinuteSummary:has been ${behaviorVerb} this page for the past < 1 minute.`;
    } else if (diffMins === 1) {
      return $localize`:@@userBehavior.minuteSummary:has been ${behaviorVerb} this page for the past 1 minute.`;
    } else {
      return $localize`:@@userBehavior.overMinuteSummary:has been ${behaviorVerb} this page for the past ${diffMins} minutes.`;
    }
  }


  private initBehaviorListener() {
    this.userBehaviorService.getCurrentBehaviors$().pipe(
      filter(() => !!this.pageTypeId && !!this.primaryId),
      takeUntil(this.isDestroyed$)
    ).subscribe(behaviors => {
      /** NOTE: sort by behavior so writing behavior is listed first */
      this.allBehaviors = [...behaviors].sort((a, b) => a.BehaviorCode?.localeCompare(b.BehaviorCode));
      const filteredBehaviors = behaviors?.filter(f => +f.PageTypeId === +this.pageTypeId && +f.PrimaryId === +this.primaryId && +f.SecondaryId === +this.secondaryId);

      this.userCount = filteredBehaviors.length;
      this.displayCount = filteredBehaviors.length > this.userLimit ? this.userLimit : filteredBehaviors.length;

      this.filteredBehaviors$.next(filteredBehaviors.slice(0, this.userLimit).sort((a, b) => a.BehaviorCode?.localeCompare(b.BehaviorCode)));
    });
  }

  private initDetailsListener() {
    /** NOTE: on avatar mouse over */
    this.setShowDetails$.pipe(
      debounceTime(300),
      takeUntil(this.isDestroyed$)
    ).subscribe(detailsIndex => {
      this.behaviorIndex = detailsIndex;
    });
  }

  private sendSelectedBehaviorGAEvent(selectedBehavior: UserBehavior) {
    if (!this.pastGASelectedBehaviors.some(ub => ub.BehaviorCode === selectedBehavior.BehaviorCode && ub.UserId === selectedBehavior.UserId)) {
      this.pastGASelectedBehaviors.push(selectedBehavior);
      this.userBehaviorService.addUserBehaviorGAEvent(selectedBehavior);
    }
  }
}
