<app-phx-panel headerTitle="Collaborators">
  <form [formGroup]="formGroup">
    <phx-form-control [control]="formGroup.controls.AssignedToUserProfileId" labelText="Assigned To"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','AssignedToUserProfileId')"
      viewModeText="{{ formGroup.controls.AssignedToUserProfileId.value | lookup: html.lists.userProfileAssignedTo:'Id':'DisplayText'}}">
      <app-phx-select-box [formControl]="formGroup.controls.AssignedToUserProfileId"
        [items]="html.lists.userProfileAssignedTo" textField="DisplayText" valueField="Id">
      </app-phx-select-box>
    </phx-form-control>
  </form>
</app-phx-panel>