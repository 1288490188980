import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { startWith, takeUntil, filter, switchMap, tap, take } from 'rxjs/operators';
import { IAttributionManager, IAttributionShare, IAttributionSplitOption, ICommisionAttribution, ISalesAttribution } from 'src/app/attributions/attribution.interface';
import { CodeValueService } from 'src/app/common';
import { PhxModalComponent } from 'src/app/common/components/phx-modal/phx-modal.component';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ICommonListsItem } from 'src/app/common/lists/lists.interface';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { CodeValue, CodeValueGroups, PhxConstants, PhxFormControlLayoutType } from 'src/app/common/model';
import { FormArray, FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { IReadOnlyStorage, IWorkOrder } from '../../models';
import { CoreCommissionFormService, CoreDetailFormService } from '../../services';
import { AttributionsFormService } from '../../services/forms/attributions-form.service';

interface LocalUserProfile {
  userProfileId: number;
  displayName: string;
}

@Component({
  selector: 'app-workorder-tab-attributions',
  templateUrl: './workorder-tab-attributions.component.html',
  styleUrls: ['./workorder-tab-attributions.component.less']
})
export class WorkorderTabAttributionsComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() workOrder: IWorkOrder;

  /** NOTE: move to child component if more features are added to this tab */
  profileListLookup: LocalUserProfile[] = [];

  salesProfiles: ICommonListsItem[];
  recruiterProfiles: ICommonListsItem[];

  filteredSalesSplitOptions: IAttributionSplitOption[];
  filteredRecruiterSplitOptions: IAttributionSplitOption[];

  attributionsFormGroup: FormGroup<ICommisionAttribution>;
  currentLineOfBusinessSplit?: ISalesAttribution;
  salesSplitOptions: number[][] = [];

  haveWoLob = false;

  modalTitle = '';
  modalMessage = '';
  modalLabel = '';

  lobCodeValues: CodeValue[] = [];
  stackedLayout = PhxFormControlLayoutType.Stacked;

  readonly SALES = 'Sales Person';
  readonly RECRUITER = 'Recruiter';

  @ViewChild('phxModalComponent', { static: true })
  phxModalComponent: PhxModalComponent;

  /** NOTE: local form - split option drop down */
  splitOptionsForm = this.formBuilder.group({
    salesSplitOption: null,
    recruiterSplitOption: null
  });

  /** NOTE: local form - modal drop down */
  profileListForm = this.formBuilder.group({
    profile: [null, Validators.required],
    profileType: null
  });

  nationalAccountManagerDisplay: string = '-';
  branchManagerDisplay: string = '-';

  constructor(
    private coreDetailFormService: CoreDetailFormService,
    private coreCommissionFormService: CoreCommissionFormService,
    private attributionsFormService: AttributionsFormService,
    private formBuilder: UntypedFormBuilder,
    private commonListsObservableService: CommonListsObservableService,
    private codeValueService: CodeValueService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.attributionsFormGroup = this.attributionsFormService.formGroup;
    this.attributionsFormService.initAttributionSplitOptions();

    this.initLOBList();
    this.initInternalProfilesLists();
    this.initFilterSplitOptions();
    this.initSplitOptionSelection();

    this.initObserveManagerFormArrays();
    this.initObserveWorkOrderLineOfBusiness();
    this.initObserveSplitOptions();
    this.initObserveSharesArrays();
    this.initObserveJobOwner();
    this.initObserveCommisionRecruiters();
  }

  onClickOpenAddShareModal(profileType: string) {
    /** NOTE: open modal for user to add new sales/recruiter profile */
    this.modalTitle = `Add New ${profileType}`;
    this.modalMessage = `Select the new ${profileType.toLowerCase()} from the list below.`;
    this.modalLabel = `New ${profileType}`;

    this.profileTypeControl.patchValue(profileType);
    this.phxModalComponent.show();
  }


  //#region change selected Sales/Recruiter rows
  /** NOTE: the sales/recruiter profiles are moved up and down to match the attribution share percentage.
   *  because the attribution shares are static and in a set order - we have to move the names
   * 
   *  ** moving up in the context of the user is moving the profile to a lower index of the form array
   */
  onClickMoveUp(index: number, formArray: FormArray<IAttributionShare>) {
    const holder = { ...formArray.at(index).value };
    this.patchShare(index, formArray.at(index - 1).value, formArray);
    this.patchShare(index - 1, holder, formArray);
  }

  onClickMoveDown(index: number, formArray: FormArray<IAttributionShare>) {
    const holder = { ...formArray.at(index).value };
    this.patchShare(index, formArray.at(index + 1).value, formArray);
    this.patchShare(index + 1, holder, formArray);
  }

  onClickRemoveSales(index: number) {
    this.attributionsFormService.removeSalesShare(index);
  }

  onClickRemoveRecruiter(index: number) {
    this.attributionsFormService.removeRecruiterShare(index);
  }
  //#endregion

  onClickModalCancel() {
    this.profileListForm.reset();
    this.phxModalComponent.hide();
  }

  /** NOTE: user has selected a new profile in modal - add that profile to the attribution component */
  onClickAddProfile() {
    const newProfile = this.profileListControl.value;
    const profileType = this.profileTypeControl.value;

    this.addProfile(newProfile.Id, profileType);

    this.profileListForm.reset();
    this.phxModalComponent.hide();
  }

  private addProfile(profileId: number, profileType: string) {
    if (profileType === this.RECRUITER) {
      this.attributionsFormService.addRecruiterShare(profileId);
    } else {
      this.attributionsFormService.addSalesShare(profileId);
    }
  }

  /** NOTE: get LOB list for template - name lookup by code */
  private initLOBList() {
    this.lobCodeValues = this.codeValueService.getCodeValues(CodeValueGroups.LineOfBusiness, true);
  }

  /** NOTE: update attribution profile (move up or move down) */
  private patchShare(indexOfShareToPatch: number, newShareValues: IAttributionShare, formArray: FormArray<IAttributionShare>) {
    formArray.at(indexOfShareToPatch).patchValue({
      UserProfileId: newShareValues.UserProfileId,
    }, { emitEvent: false });
  }

  /** NOTE: current WO LOB could change - if it does then update LOB code */
  private initObserveWorkOrderLineOfBusiness() {
    this.coreDetailFormService.formGroup.controls.LineOfBusinessId.valueChanges.pipe(
      startWith(this.coreDetailFormService.formGroup.controls.LineOfBusinessId.value),
      tap(() => this.haveWoLob = false),
      switchMap(lobId => forkJoin([
        of(lobId),
        this.attributionsFormService.getLineOfBusinessAttributionSplits()
      ])),
      takeUntil(this.isDestroyed$)
    ).subscribe(([lobId, lobAttributionSplits]) => {
      this.haveWoLob = Boolean(lobId);
      if (this.haveWoLob) {
        const lobCode = this.codeValueService.getCodeValue(lobId, CodeValueGroups.LineOfBusiness);

        /** NOTE: if we have an LOB but the form is missing LOB attribution values then populate them */
        this.currentLineOfBusinessSplit = lobAttributionSplits.find(f => f.LineOfBusinessCode === lobCode.code);
        if (!this.attributionsFormGroup.controls.RecruiterAttribution.value) {
          this.attributionsFormGroup.patchValue({ RecruiterAttribution: this.currentLineOfBusinessSplit?.RecruiterSplit });
        }
        if (!this.attributionsFormGroup.controls.SalesAttribution.value) {
          this.attributionsFormGroup.patchValue({ SalesAttribution: this.currentLineOfBusinessSplit?.SalesSplit });
        }
      }
    });
  }

  /** NOTE: add the core tab commisions section job owners as attribution sales profile if editable, currently no other sales profiles and its in our profile list */
  private initObserveJobOwner() {
    /** NOTE: these dont have to be subscriptions since the values cant change while user is on the attributions tab - but will be valid for WO 1.5 */
    this.coreCommissionFormService.jobOwnerFormGroup.valueChanges.pipe(
      startWith(this.coreCommissionFormService.jobOwnerFormGroup.value),
      filter(jobOwner => !!jobOwner.UserProfileIdSales && this.readOnlyStorage.IsEditable && !this.salesSharesFormArray.length),
      switchMap(jobOwner => forkJoin([
        of(jobOwner),
        this.commonListsObservableService.listUserProfileInternalAll$().pipe(filter(e => !!e), take(1))
      ])),
      takeUntil(this.isDestroyed$)
    ).subscribe(([jobOwner, activeInternalProfiles]) => {
      const joInternalProfile = activeInternalProfiles.find(f => f.Id === jobOwner.UserProfileIdSales &&
        f.Data.UserProfileFunctionalRoles.map(role => role.FunctionalRoleId).includes(PhxConstants.FunctionalRole.AccountManager));
      if (!!joInternalProfile) {
        this.addProfile(jobOwner.UserProfileIdSales, this.SALES);
      }

      /** NOTE: add any supporting job owners */
      this.coreCommissionFormService.supportingJobOwnerFormArray.value.forEach(salesToAdd => {
        /** NOTE: max of 3 sales profiles */
        if (this.salesSharesFormArray.length < 3) {
          const internalProfile = activeInternalProfiles.find(f => f.Id === salesToAdd.UserProfileIdSales &&
            f.Data.UserProfileFunctionalRoles.map(role => role.FunctionalRoleId).includes(PhxConstants.FunctionalRole.AccountManager));
          if (internalProfile) {
            this.addProfile(salesToAdd.UserProfileIdSales, this.SALES);
          }
        }
      });
    });
  }

  /** NOTE: add core tab commisions section recruiters as an attribution recruiter profile if editable, currently no recruiter profiles and profile is in our profile list */
  private initObserveCommisionRecruiters() {
    /** NOTE: these dont have to be subscriptions since the values cant change while user is on the attributions tab - but will be valid for WO 1.5 */
    this.coreCommissionFormService.recruitersFormArray.valueChanges.pipe(
      startWith(this.coreCommissionFormService.recruitersFormArray.value),
      filter(recruiters => recruiters.length && this.readOnlyStorage.IsEditable && !this.recruiterSharesFormArray.length),
      switchMap(recruiters => forkJoin([
        of(recruiters),
        this.commonListsObservableService.listUserProfileInternalAll$().pipe(filter(e => !!e), take(1))
      ])),
      takeUntil(this.isDestroyed$)
    ).subscribe(([recruiters, activeInternalProfiles]) => {
      recruiters.forEach(recruiterToAdd => {
        /** NOTE: max of 2 recruiter profiles */
        if (this.recruiterSharesFormArray.length < 2) {
          const recruiterProfile = activeInternalProfiles.find(f => f.Id === recruiterToAdd.UserProfileIdSales &&
            f.Data.UserProfileFunctionalRoles.map(role => role.FunctionalRoleId).includes(PhxConstants.FunctionalRole.Recruiter));
          if (!!recruiterProfile) {
            this.addProfile(recruiterToAdd.UserProfileIdSales, this.RECRUITER);
          }
        }
      });
    });
  }

  /** NOTE: when WO is not editable managers are populated from saved data in the version - else populated from api calls */
  private initObserveManagerFormArrays() {
    this.branchManagersFormArray.valueChanges.pipe(
      startWith(this.branchManagersFormArray.value),
      tap(() => this.branchManagerDisplay = '-'),
      filter(branchManagers => !!branchManagers && !!branchManagers.length),
      takeUntil(this.isDestroyed$)
    ).subscribe(branchManagers =>
      this.branchManagerDisplay = this.arrayToCdString(branchManagers.map(m => m.FullName))
    );

    this.nationalAccountManagersFormArray.valueChanges.pipe(
      startWith(this.nationalAccountManagersFormArray.value),
      tap(() => this.nationalAccountManagerDisplay = '-'),
      filter(nationalAccountManagers => !!nationalAccountManagers && !!nationalAccountManagers.length),
      takeUntil(this.isDestroyed$)
    ).subscribe(nationalAccountManagers =>
      this.nationalAccountManagerDisplay = this.arrayToCdString(nationalAccountManagers.map(m => m.FullName))
    );
  }

  /** NOTE: when a profile is added/removed - we need to set the split option controls accordingly */
  private initObserveSharesArrays() {
    this.salesSharesFormArray.valueChanges.pipe(
      /** NOTE: if there is a null attributionShare then the user just added a profile */
      filter(array => !array.some(f => !f.AttributionShare)),
      takeUntil(this.isDestroyed$)
      /** NOTE: we need to patch the split option control when the attribution service has already assigned default attribution shares according to number of profiles */
    ).subscribe(salesProfiles => this.patchSplitOptionControl(this.salesSplitOptionControl, salesProfiles));

    this.recruiterSharesFormArray.valueChanges.pipe(
      filter(array => !array.some(f => !f.AttributionShare)),
      takeUntil(this.isDestroyed$)
    ).subscribe(recruiterProfiles => this.patchSplitOptionControl(this.recruiterSplitOptionControl, recruiterProfiles));
  }

  private arrayToCdString(managers: string[]) {
    return managers?.length ? managers.join(', ') : '-';
  }

  /** NOTE: when component fires up we need to set split option drop downs since they are not part of the CommisionAttribution form */
  private initSplitOptionSelection() {
    if (this.salesSharesFormArray.length) {
      this.patchSplitOptionControl(this.salesSplitOptionControl, this.salesSharesFormArray.value);
    }

    if (this.recruiterSharesFormArray.length) {
      this.patchSplitOptionControl(this.recruiterSplitOptionControl, this.recruiterSharesFormArray.value);
    }
  }

  /** NOTE: the selected split option value is determined by the attributionShare property of each profile in 'profiles' */
  private patchSplitOptionControl(splitOptionControl: AbstractControl, profiles: IAttributionShare[]) {
    splitOptionControl.patchValue(profiles.map(m => m.AttributionShare).sort((a, b) => b - a).join('-'), { emitEvent: false });
  }

  /** NOTE: the split options presented to the user changes depending on the number of sales/recruiter profiles added to the list */
  private initFilterSplitOptions() {
    this.attributionsFormService.filteredSalesSplitOptions$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(filteredSalesSplitOptions => this.filteredSalesSplitOptions = filteredSalesSplitOptions);

    this.attributionsFormService.filteredRecruiterSplitOptions$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(filteredRecruiterSplitOptions => this.filteredRecruiterSplitOptions = filteredRecruiterSplitOptions);
  }

  /** NOTE: when user selects a split option we update form to update UI table values */
  private initObserveSplitOptions() {
    this.salesSplitOptionControl.valueChanges.pipe(
      filter(splitOption => !!splitOption),
      takeUntil(this.isDestroyed$)
    ).subscribe(splitOption => this.attributionsFormService.updateSalesSharesAttributionShares(splitOption));

    this.recruiterSplitOptionControl.valueChanges.pipe(
      filter(splitOption => !!splitOption),
      takeUntil(this.isDestroyed$)
    ).subscribe(splitOption => this.attributionsFormService.updateRecruiterSharesAttributionShares(splitOption));
  }

  /** NOTE: get list of profiles to populate profileListForm profileListControl */
  private initInternalProfilesLists() {
    this.commonListsObservableService.listUserProfileInternalAll$().pipe(
      filter(profiles => !!profiles)
    ).subscribe(profiles => {
      this.salesProfiles = profiles.filter(f => f.Data.UserProfileFunctionalRoles.map(role => role.FunctionalRoleId).includes(PhxConstants.FunctionalRole.AccountManager));
      this.recruiterProfiles = profiles.filter(f => f.Data.UserProfileFunctionalRoles.map(role => role.FunctionalRoleId).includes(PhxConstants.FunctionalRole.Recruiter));
      /** NOTE: list for profile name look ups in template */
      this.profileListLookup = profiles.map(profile => ({ userProfileId: profile.Id, displayName: profile.Data.Contact.FullName }));
    });
  }

  /** NOTE: local split options form */
  get salesSplitOptionControl() {
    return this.splitOptionsForm.controls.salesSplitOption;
  }
  get recruiterSplitOptionControl() {
    return this.splitOptionsForm.controls.recruiterSplitOption;
  }

  /** NOTE: attribution form service form */
  get salesSharesFormArray() {
    return this.attributionsFormGroup.controls.SalesAttributionShare as FormArray<IAttributionShare>;
  }
  get recruiterSharesFormArray() {
    return this.attributionsFormGroup.controls.RecruiterAttributionShare as FormArray<IAttributionShare>;
  }
  /** NOTE: read only - holds the value for the LOB sales attribution percentage */
  get salesAttributionControl() {
    return this.attributionsFormGroup.controls.SalesAttribution;
  }
  /** NOTE: read only - holds the value for the LOB recruiter attribution percentage */
  get recruiterAttributionControl() {
    return this.attributionsFormGroup.controls.RecruiterAttribution;
  }

  get branchManagersFormArray() {
    return this.attributionsFormGroup.controls.BranchManagers as FormArray<IAttributionManager>;
  }
  get nationalAccountManagersFormArray() {
    return this.attributionsFormGroup.controls.NationalAccountManagers as FormArray<IAttributionManager>;
  }

  /** NOTE: local modal profile form */
  get profileListControl() {
    return this.profileListForm.controls.profile;
  }
  get profileTypeControl() {
    return this.profileListForm.controls.profileType;
  }
}
