<div class="margin-20-v-b">
    <form [formGroup]="otFormGroup">
        <div class="panel-internal">
            <div style="margin-top:30px">
                <h4>Overtime</h4>
            </div>
        </div>
        <phx-form-control [control]="overtimeDetailsFormGroup.controls.IsOvertimeExempt" labelText="Overtime Exempt"
            [editable]="readOnlyStorage.IsEditable" viewModeText="{{overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === true ? 'Yes' :
                overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === false ? 'No' : ''}}">
            <fieldset>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsOvertimeExempt" type="radio" [value]="true"
                        [checked]="overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === true"
                        [formControl]="overtimeDetailsFormGroup.controls.IsOvertimeExempt">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsOvertimeExempt" type="radio" [value]="false"
                        [checked]="overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === false"
                        [formControl]="overtimeDetailsFormGroup.controls.IsOvertimeExempt">No</label>
            </fieldset>
        </phx-form-control>

        <phx-form-control [control]="overtimeDetailsFormGroup.controls.OvertimeExemptionReason" labelText="Exemption Reason"
            *ngIf="overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === true" [editable]="readOnlyStorage.IsEditable"
            viewModeText="{{overtimeDetailsFormGroup.controls.OvertimeExemptionReason.value}}">
            <input [formControl]="overtimeDetailsFormGroup.controls.OvertimeExemptionReason" type="text" class="form-control"
                maxlength="250" />
        </phx-form-control>
        <ng-container *ngIf="autoCalculateOvertimeIsActiveForWorkorder && overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === false && haveHourRateUnit">
            <div class="autocalculate-ot-container">
                <phx-form-control [control]="overtimeDetailsFormGroup.controls.AutoCalculateOvertime"
                    labelText="Auto-calculate Overtime Hours"
                    [editable]="readOnlyStorage.IsEditable">
                  <div view-mode class="ot-autocalculation-value">
                    <ng-container *ngIf="overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value !== null">
                      <span *ngIf="overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value" i18n="@@common.message.yes">Yes</span>
                      <span *ngIf="!overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value && overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === false" i18n="@@common.message.no">No</span>
                    </ng-container>
                  </div>
                    <fieldset>
                        <label class="radio-inline">
                            <input class="form-check-input" name="AutoCalculateOvertime" type="radio" [value]="true"
                                [checked]="overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value === true"
                                [formControl]="overtimeDetailsFormGroup.controls.AutoCalculateOvertime">Yes</label>
                        <label class="radio-inline">
                            <input class="form-check-input" name="AutoCalculateOvertime" type="radio" [value]="false"
                                [checked]="overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value === false"
                                [formControl]="overtimeDetailsFormGroup.controls.AutoCalculateOvertime">No</label>
                    </fieldset>
                </phx-form-control>
                <phx-form-control [control]="overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason" 
                    labelText="Auto-Calculate Overtime Override Reason"
                    *ngIf="(overtimeDetailsFormGroup.controls.AutoCalculateOvertime.enabled || !readOnlyStorage.IsEditable)
                            && overtimeDetailsFormGroup.controls?.AutoCalculateOvertime.value === false"
                    [editable]="readOnlyStorage.IsEditable" viewModeText="{{overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.value}}">
                    <input [formControl]="overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason" type="text" class="form-control"
                        maxlength="250" />
                </phx-form-control>
                <div class="autocalculate-ot-tip-container"><i class="material-icons md-64"
                        id="autocalculateottooltip">info</i></div>
            </div>
            <app-phx-tool-tip targetId="#autocalculateottooltip">
                Auto-calculate currently supported only when Timesheet Methodology is 'Online Approval' or 'Offline
                Approval', Timesheet Uses Projects = No, and Payment Primary Rate has a Rate Unit of 'Hour'
            </app-phx-tool-tip>
        </ng-container>
    </form>
</div>
