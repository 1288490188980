<form [formGroup]="purchaseorderForm" *ngIf="purchaseorderForm">
    <div class="slider-content">
        <div class="slider-header">
            <div class="panel-heading">
                <div class="row keystone-row">
                    <div class="col-lg-2">
                        <span class="h6 bold">Total Funds</span>
                    </div>
                    <div class="col-lg-2">
                        <span class="h6 bold">Funds Allocated</span>
                    </div>
                    <div class="col-lg-2">
                        <span class="h6 bold">Funds Unallocated</span>
                    </div>
                    <div class="col-lg-2">
                        <span class="h6 bold">Funds Spent</span>
                    </div>
                    <div class="col-lg-2">
                        <span class="h6 bold">Funds Reserved</span>
                    </div>
                    <div class="col-lg-2">
                        <span class="h6 bold">Funds Remaining</span>
                    </div>
                </div>
                <div class="row keystone-row">
                    <div class="col-lg-2">
                        <span>
                            {{ purchaseorderForm.get('Amount').value | currency }} {{ purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code' }}
                        </span>
                    </div>
                    <div class="col-lg-2">
                        <span>
                            {{ totalamountCommited | currency  }} {{ purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code' }}
                        </span>
                    </div>
                    <div class="col-lg-2">
                        <span>
                            {{ purchaseorderForm.get('Amount').value-totalamountCommited | currency }} {{purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code'}}
                        </span>
                    </div>
                    <div class="col-lg-2">
                        <span>
                            {{ totalamountSpent | currency }} {{purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code' }}
                        </span>
                    </div>
                    <div class="col-lg-2">
                        <span>
                            {{ totalamountReserved | currency }} {{purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code'}}
                        </span>
                    </div>
                    <div class="col-lg-2">
                        <span>
                            <span
                                *ngIf="purchaseorderForm.controls.DepletionOptionId.value === phxConstants.DepletionOption.FundsAllocated"
                                class="form-control-static"
                            >
                                {{ totalamountCommited-totalamountSpent-totalamountReserved | currency }} {{purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code'}}
                            </span>
                            <span
                                *ngIf="purchaseorderForm.controls.DepletionOptionId.value !== phxConstants.DepletionOption.FundsAllocated"
                                class="form-control-static"
                            >
                                {{ (purchaseorderForm.get('Amount').value - totalamountSpent - totalamountReserved ) | currency }} {{ purchaseorderForm.get('CurrencyId').value | lookup:listCurrency:'id':'code' }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-body">
            <app-phx-navigation-bar [navigationBarContent]="html.navigationBarContent" *ngIf="html.navigationBarContent"
                                    (tabSelected)="onTabSelected($event)" [activeTabObj]="selectedTab"></app-phx-navigation-bar>
            <div>
                <div *ngIf="selectedTab.Name === phxConstants.WorkOrderPurchaseOrderLineModalNavigationName.details">
                    <app-workorder-purchaseorderline-tab-details [purchaseorderForm]="purchaseorderForm"
                        [poNumber]="poNumber">
                    </app-workorder-purchaseorderline-tab-details>
                </div>
                <div *ngIf="selectedTab.Name === phxConstants.WorkOrderPurchaseOrderLineModalNavigationName.workorders">
                    <app-workorder-purchaseorderline-tab-workorders [purchaseorderForm]="purchaseorderForm"
                        [lineNew]="lineNew" [workOderPurchaseOrderLineId]="workOderPurchaseOrderLineId"
                        [workRateUnitTypes]="workRateUnitTypes" (CommitChange)="CommitChange($event)"
                        (Navigate)="navigate($event)">

                    </app-workorder-purchaseorderline-tab-workorders>
                </div>
                <div class="content-group form-horizontal"
                    *ngIf="selectedTab.Name === phxConstants.WorkOrderPurchaseOrderLineModalNavigationName.documents">
                    <div class="col-lg-12">
                        <phx-entity-documents-list [entityId]="lineNew.PurchaseOrderId"
                            [canDeleteDocumentFn]="canDeleteDocument"
                            [entityTypeId]="phxConstants.EntityType.PurchaseOrder">
                        </phx-entity-documents-list>
                    </div>
                </div>
            </div>
            <div class="panel-body">
                <div *ngIf="validationMessages.length > 0" class="alert alert-danger alert-dismissable error-box">
                    <div *ngFor="let msg of validationMessages">
                        <div>
                            {{msg}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-footer">
            <div class="row">
                <div class="pull-right btn-group">
                    <button type="button" class="btn btn-default" (click)="onClickCancel()"
                        [disabled]="hasPendingRequests">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="onClickSubmit()"
                        [disabled]="hasPendingRequests || !purchaseorderForm.valid">Save</button>
                </div>
            </div>
        </div>

    </div>
</form>
