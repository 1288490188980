import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FormGroup as LegacyFormGroup } from 'src/app/common/ngx-strongly-typed-forms';
import { Subject, forkJoin, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractControl as LegacyAbstractControl } from 'src/app/common/ngx-strongly-typed-forms/model';
import { VersionComparisonService } from 'src/app/common/services/version-comparison.service';
import * as wo from '../models';
import { ToTypedFormGroup } from 'src/app/common/model/to-typed-form-group.model';
import { PhxConstants } from 'src/app/common';
import { WorkOrderDataService } from './work-order-data.service';
import { IOverrideTimesheetException } from 'src/app/common/model/override-timesheet-exception';
import { CustomField } from 'src/app/client-specific-fields/model/custom-field';
import { ClientSpecificFieldsService } from 'src/app/client-specific-fields/client-specific-fields.service';
import { CoreDetailFormService } from './forms';
import { VersionComparisonToggleService } from 'src/app/common/services/version-comparison-toggle.service';

type PhxFormControl = AbstractControl<any, any> | LegacyAbstractControl<any>;
type PhxFormGroup<T> = FormGroup<ToTypedFormGroup<T>> | LegacyFormGroup<T>;
type PhxFormArray<T> = FormArray<FormGroup<ToTypedFormGroup<T>>> | LegacyFormGroup<T[]>;

export interface WorkOrderVersionGetParams {
  assignmentId: number;
  workorderId: number;
  versionId: number;
}
@Injectable()
export class WorkOrderVersionComparisonService extends VersionComparisonService<WorkOrderVersionGetParams, wo.IWorkOrder> {

  private previousWorkOrderVersion: wo.IWorkOrder;
  private currentWorkOrderVersion: wo.IWorkOrder;

  private previousVersion: wo.IWorkOrderVersion;
  private previousVersionWorkOrderId: number;

  private previousClientSpecificFields: CustomField[];
  private currentForm: PhxFormGroup<wo.IRoot>;

  constructor(
    private workOrderDataService: WorkOrderDataService,
    private clientSpecificFieldsService: ClientSpecificFieldsService,
    private coreDetailFormService: CoreDetailFormService,
    protected versionComparisonToggleService: VersionComparisonToggleService
  ) {
    super(versionComparisonToggleService);

    this.customFormNames = {
      TimesheetOverrideExceptions: 'TimesheetOverrideExceptions'
    };
  }

  clearCachedComparisonControlListByVersion(versionId: number) {
    this.versionComparisonsCache?.delete(versionId);
  }

  compareEntities(
    currentWorkOrderFormGroup: PhxFormGroup<wo.IRoot>,
    currentWorkOrderVersion: wo.IWorkOrder,
    previousEntityVersionGetParams: WorkOrderVersionGetParams,
    isDestroyed$: Subject<boolean>
  ): void {

    this.resetComparisonData();

    const previousVersionVersionId = previousEntityVersionGetParams.versionId;
    const previousVersionWorkOrderId = previousEntityVersionGetParams.workorderId;

    this.versionComparisonToggleService.setVersionComparisonSourceNumber(previousVersionVersionId);

    this.currentWorkOrderVersion = currentWorkOrderVersion;

    /** NOTE: if we are on the same work order - check if we have cached the request version */
    if (previousVersionWorkOrderId === this.previousVersionWorkOrderId && this.versionComparisonsCache.has(previousVersionVersionId)) {
      this.changedControls$.next(this.versionComparisonsCache.get(previousVersionVersionId));
      this.versionCompareMessage$.next(this.versionCompareMessagesCache.get(previousVersionVersionId));
    } else {
      /** NOTE: if we are on a different work order - clear cached data */
      if (previousVersionWorkOrderId !== this.previousVersionWorkOrderId) {

        this.versionComparisonsCache = new Map();
        this.versionCompareMessagesCache = new Map();

        this.previousVersionWorkOrderId = previousVersionWorkOrderId;
      }

      this.workOrderDataService.loadWorkOrder(previousEntityVersionGetParams, false, isDestroyed$).pipe(
        switchMap(previousWoVersion => {
          const clientSpecificFields = currentWorkOrderFormGroup.controls.ClientSpecificFields;

          /** NOTE: if current work order has client specific fields fetch the ones from the previous version */
          if (!!Object.keys(clientSpecificFields.value).length) {
            const clientId = previousWoVersion.WorkOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient;
            const entityId = previousWoVersion.WorkOrder.WorkOrderVersion.Id;
            const entityTypeId = PhxConstants.EntityType.WorkOrderVersion;

            return forkJoin([
              from(this.clientSpecificFieldsService.getCustomFields(clientId, entityId, entityTypeId)),
              of(previousWoVersion)
            ]);
          } else {
            return forkJoin([
              of([]),
              of(previousWoVersion)
            ]);
          }
        })
      ).subscribe(([previousClientFields, previousWoVersion]) => {
        /** NOTE: compare function needs this.previousClientSpecificFields */
        this.previousClientSpecificFields = previousClientFields;

        this.previousVersion = previousWoVersion.WorkOrder.WorkOrderVersion;
        this.previousWorkOrderVersion = previousWoVersion.WorkOrder;
        this.currentForm = currentWorkOrderFormGroup;

        this.compare();
        this.compareCustomForms();

        this.customFormsForComparison = new Map();

        this.changedControls$.next(this.changedControlsList);

        const comparisonMessage = this.getComparisonMessage();
        this.versionCompareMessage$.next(comparisonMessage);

        this.versionCompareMessagesCache.set(previousVersionVersionId, comparisonMessage);
        this.versionComparisonsCache.set(previousVersionVersionId, this.changedControlsList);
      });
    }
  }

  private compare(): void {

    this.compareTab(PhxConstants.WorkorderNavigationName.core);
    this.compareTab(PhxConstants.WorkorderNavigationName.parties);
    this.compareTab(PhxConstants.WorkorderNavigationName.timematerialinvoice);
    this.compareTab(PhxConstants.WorkorderNavigationName.expensemanagement);
    this.compareTab(PhxConstants.WorkorderNavigationName.earningsanddeductions);
    this.compareTab(PhxConstants.WorkorderNavigationName.taxes);
    this.compareTab(PhxConstants.WorkorderNavigationName.clientspecificfields);
  }

  private compareTab(tabNavigationName: string) {
    this.activeChangedControlsList = new Map<PhxFormControl, string>;

    switch (tabNavigationName) {
      case PhxConstants.WorkorderNavigationName.core:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.core;
        this.compareCoreTab();
        break;
      case PhxConstants.WorkorderNavigationName.parties:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.parties;
        this.comparePartiesAndRatesTab();
        break;
      case PhxConstants.WorkorderNavigationName.timematerialinvoice:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.timematerialinvoice;
        this.compareTimesheetAndInvoiceTab();
        break;
      case PhxConstants.WorkorderNavigationName.expensemanagement:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.expensemanagement;
        this.compareExpenseAndInvoiceTab();
        break;
      case PhxConstants.WorkorderNavigationName.earningsanddeductions:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.earningsanddeductions;
        this.compareEarningsAndDeductionsTab();
        break;
      case PhxConstants.WorkorderNavigationName.taxes:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.taxes;
        this.compareTaxTab();
        break;
      case PhxConstants.WorkorderNavigationName.clientspecificfields:
        this.activeComparisonTab = PhxConstants.WorkorderNavigationName.clientspecificfields;
        this.compareClientSpecicFieldsTab();
        break;
      default:
        console.error('Please provide tab navigation name.');
    }

    if (this.activeChangedControlsList.size > 0) {
      this.changedControlsList = new Map([...this.changedControlsList, ...this.activeChangedControlsList]);
    }
  }

  protected compareCustomForms() {
    this.activeChangedControlsList = new Map<PhxFormControl, string>;

    /** NOTE: customFormsForComparison could be null if there are no custom forms */
    if (this.customFormsForComparison?.has(this.customFormNames.TimesheetOverrideExceptions)) {
      this.compareCustomFormTimesheetOverrides();
    }

    if (this.activeChangedControlsList.size > 0) {
      this.customFormsChangedControls$.next(this.activeChangedControlsList);
    }
  }

  private compareCustomFormTimesheetOverrides() {
    if (this.previousVersion) {
      this.activeComparisonTab = PhxConstants.WorkorderNavigationName.timematerialinvoice;

      const overRideExceptionsFormGroup = this.customFormsForComparison.get(this.customFormNames.TimesheetOverrideExceptions) as PhxFormGroup<wo.IOverrideTimesheetExceptions>;
      const overrideExceptions = overRideExceptionsFormGroup.controls.OverrideTimesheetExceptions as PhxFormArray<IOverrideTimesheetException>;
      this.compareOverrideTimesheetExceptions(overrideExceptions, this.previousVersion.OverrideTimesheetExceptions);
    }
  }

  private compareClientSpecicFieldsTab(): void {
    const currentClientSpecificFieldsKeys = Object.keys(this.currentForm.controls.ClientSpecificFields.value);
    const previousClientSpecificFieldsKeys = this.previousClientSpecificFields.map(m => m.FieldName);

    if (currentClientSpecificFieldsKeys.length < previousClientSpecificFieldsKeys.length) {
      this.removedItemsList.push('Client specific field');
      /** NOTE: we add a null control so the client specific fields tab shows as having a change */
      this.cacheChangedControl(null);
    }

    const clientSpecificFields = this.currentForm.controls.ClientSpecificFields as PhxFormGroup<any>;
    currentClientSpecificFieldsKeys.forEach(fieldName => {
      const previousField = this.previousClientSpecificFields.find(f => f.FieldName === fieldName);
      if (!previousField) {
        this.cacheChangedControl(clientSpecificFields.controls[fieldName]);
      } else {
        this.compareValue(clientSpecificFields.controls[fieldName], previousField.FieldValue);
      }
    });
  }

  private compareTaxTab(): void {
    const tabTaxesForm = this.currentForm.controls.TabTaxes as PhxFormGroup<wo.ITabTaxes>;

    this.compareValue(tabTaxesForm.controls.OrganizationIdInternal, this.previousWorkOrderVersion.OrganizationIdInternal);
    this.compareValue(tabTaxesForm.controls.ValidateComplianceDraft, this.previousVersion.ValidateComplianceDraft);

    const taxesBillingInfo = tabTaxesForm.controls.BillingInfoes as PhxFormArray<wo.IBillingInfo>;
    const taxesBillingInfoFormGroup = taxesBillingInfo.controls[0] as PhxFormGroup<wo.IBillingInfo>;
    const previousVersionBillingInfoes = this.previousVersion.BillingInfoes[0];

    this.compareValue(taxesBillingInfoFormGroup.controls.JurisdictionId, previousVersionBillingInfoes.JurisdictionId);
    this.compareValue(taxesBillingInfoFormGroup.controls.OrganizationClientDisplayName, previousVersionBillingInfoes.OrganizationClientDisplayName);
    this.compareValue(taxesBillingInfoFormGroup.controls.OrganizationIdClient, previousVersionBillingInfoes.OrganizationIdClient);
    this.compareValue(taxesBillingInfoFormGroup.controls.SubdivisionIdSalesTax, previousVersionBillingInfoes.SubdivisionIdSalesTax);

    const billingSalesTaxes = taxesBillingInfoFormGroup.controls.BillingSalesTaxes as PhxFormArray<wo.IBillingSalesTax>;
    if (billingSalesTaxes) {
      if (billingSalesTaxes.value.length < previousVersionBillingInfoes.BillingSalesTaxes.length) {
        this.removedItemsList.push('Billing sales tax');
        /** NOTE: we add a null control so the 'Taxes' tab shows as having a change */
        this.cacheChangedControl(null);
      }

      for (const billingSalesTax of billingSalesTaxes.controls as PhxFormGroup<wo.IBillingSalesTax>[]) {
        const previousSalesTaxes = previousVersionBillingInfoes.BillingSalesTaxes?.find(f => f.SalesTaxId === billingSalesTax.value.SalesTaxId);
        if (!previousSalesTaxes) {
          this.setAllChildControlsAsChanged(billingSalesTax);
        } else {
          this.compareValue(billingSalesTax.controls.SalesTaxId, previousSalesTaxes.SalesTaxId);
          this.compareValue(billingSalesTax.controls.IsApplied, previousSalesTaxes.IsApplied);
        }
      }
    }

    const taxesPaymentInfo = tabTaxesForm.controls.PaymentInfoes as PhxFormArray<wo.IPaymentInfo>;
    if (taxesPaymentInfo) {
      for (const paymentTax of taxesPaymentInfo.controls as PhxFormGroup<wo.IPaymentInfo>[]) {
        const previousPaymentTax = this.previousVersion.PaymentInfoes.find(f => f.OrganizationIdSupplier === paymentTax?.value?.OrganizationIdSupplier);

        if (!previousPaymentTax) {
          this.cacheChangedControl(paymentTax.controls.ApplySalesTax);
          this.cacheChangedControl(paymentTax.controls.SubdivisionIdSalesTax);
          this.cacheChangedControl(paymentTax.controls.JurisdictionId);
        } else {
          this.compareValue(paymentTax.controls.ApplySalesTax, previousPaymentTax.ApplySalesTax);
          this.compareValue(paymentTax.controls.SubdivisionIdSalesTax, previousPaymentTax.SubdivisionIdSalesTax);
          this.compareValue(paymentTax.controls.JurisdictionId, previousPaymentTax.JurisdictionId);

          const paymentTaxSalesTaxes = paymentTax.controls.PaymentSalesTaxes as PhxFormArray<wo.IPaymentSalesTax>;
          if (paymentTaxSalesTaxes?.value?.length < previousPaymentTax?.PaymentSalesTaxes?.length) {
            this.removedItemsList.push('Payment sales tax');
            /** NOTE: we add a null control so the 'Taxes' tab shows as having a change */
            this.cacheChangedControl(null);

          }
          for (const salesTax of paymentTaxSalesTaxes.controls as PhxFormGroup<wo.IPaymentSalesTax>[]) {
            const previousSalesTax = previousPaymentTax.PaymentSalesTaxes?.find(f => f.SalesTaxId === salesTax.value.SalesTaxId);
            if (!previousSalesTax) {
              this.cacheChangedControl(salesTax.controls.IsApplied);
            } else {
              this.compareValue(salesTax.controls.IsApplied, previousSalesTax.IsApplied);
            }
          }
        }
      }
    }
  }

  private compareEarningsAndDeductionsTab(): void {
    const tabEarningsForm = this.currentForm.controls.TabEarningsAndDeductions as PhxFormGroup<wo.ITabEarningsAndDeductions>;

    this.compareValue(tabEarningsForm.controls.AccrueEmployerHealthTaxLiability, this.previousVersion.AccrueEmployerHealthTaxLiability);
    this.compareValue(tabEarningsForm.controls.WorkerContactId, this.previousWorkOrderVersion.workerContactId);
    this.compareValue(tabEarningsForm.controls.WorkerProfileTypeId, this.previousWorkOrderVersion.workerProfileTypeId);

    const tabEarningsStatHolidayForm = tabEarningsForm.controls.StatutoryHoliday as PhxFormGroup<wo.IStatutoryHoliday>;
    this.compareValue(tabEarningsStatHolidayForm.controls.ApplyFlatStatPay, this.previousVersion.ApplyFlatStatPay);

    const tabEarningsSafetyInsuranceForm = tabEarningsForm.controls.WorkplaceSafetyInsurance as PhxFormGroup<wo.IWorkplaceSafetyInsurance>;
    this.compareValue(tabEarningsSafetyInsuranceForm.controls.WCBIsApplied, this.previousVersion.WCBIsApplied);
    this.compareValue(tabEarningsSafetyInsuranceForm.controls.WCBPositionTitle, this.previousVersion.WCBPositionTitle);
    this.compareValue(tabEarningsSafetyInsuranceForm.controls.WorkerCompensationId, this.previousVersion.WorkerCompensationId);

    const tabEarningsOtherEarningsForm = tabEarningsForm.controls.OtherEarnings as PhxFormGroup<wo.IOtherEarning>;
    const otherEarnings = tabEarningsOtherEarningsForm.controls.OtherEarning as PhxFormArray<wo.IOtherEarnings>;

    /** NOTE: other earnings is only relevant to workers - meaning a null payment info OrganizationIdSupplier */
    for (const otherEarning of otherEarnings.controls as PhxFormGroup<wo.IOtherEarnings>[]) {
      if (!otherEarning.value.OrganizationIdSupplier) {
        const previousVersionPaymentInfo = this.previousVersion.PaymentInfoes.find(f => !f.OrganizationIdSupplier);
        const paymentOtherEarnings = otherEarning.controls.PaymentOtherEarnings as PhxFormArray<wo.IPaymentOtherEarning>;

        for (const paymentOtherEarning of paymentOtherEarnings.controls as PhxFormGroup<wo.IPaymentOtherEarning>[]) {
          const previousVersionOtherEarning = previousVersionPaymentInfo.PaymentOtherEarnings?.find(f => f.PaymentOtherEarningTypeId === paymentOtherEarning.value.PaymentOtherEarningTypeId);

          if (!previousVersionOtherEarning) {
            this.setAllChildControlsAsChanged(paymentOtherEarning);
          } else {
            this.compareValue(paymentOtherEarning.controls.IsAccrued, previousVersionOtherEarning.IsAccrued);
            this.compareValue(paymentOtherEarning.controls.IsApplied, previousVersionOtherEarning.IsApplied);
            this.compareValue(paymentOtherEarning.controls.RatePercentage, previousVersionOtherEarning.RatePercentage);
            this.compareValue(paymentOtherEarning.controls.UseWorkerProfileVacationSetup, previousVersionOtherEarning.UseWorkerProfileVacationSetup);
          }
        }
      }
    }

    const tabEarningsPaymentInfoesForm = tabEarningsForm.controls.PaymentInfoes as PhxFormArray<wo.IPaymentInfoDetails>;
    /** NOTE: source deductions is only relevant to workers - meaning a null payment info OrganizationIdSupplier */
    for (const paymentInfo of tabEarningsPaymentInfoesForm.controls as PhxFormGroup<wo.IPaymentInfoDetails>[]) {
      if (!paymentInfo.value.OrganizationIdSupplier) {
        const previousVersionPaymentInfoes = this.previousVersion.PaymentInfoes.find(f => !f.OrganizationIdSupplier);
        const paymentSourceDeductions = paymentInfo.controls.PaymentSourceDeductions as PhxFormArray<wo.IPaymentSourceDeductions>;

        if (paymentSourceDeductions?.value?.length < previousVersionPaymentInfoes?.PaymentSourceDeductions?.length) {
          this.removedItemsList.push('Payment source deduction');
          /** NOTE: we add a null control so the 'Earning and Deductions' tab shows as having a change */
          this.cacheChangedControl(null);
        }

        if (!previousVersionPaymentInfoes) {
          this.setAllChildControlsAsChanged(paymentInfo.controls.SourceDeductions as PhxFormGroup<wo.ISourceDeductions>);
          this.cacheChangedControl(paymentInfo.controls.SubdivisionIdSourceDeduction);

          for (const paymentSourceDeduction of paymentSourceDeductions.controls as PhxFormGroup<wo.IPaymentSourceDeductions>[]) {
            this.setAllChildControlsAsChanged(paymentSourceDeduction);
          }
        } else {
          this.compareValue(paymentInfo.controls.SubdivisionIdSourceDeduction, previousVersionPaymentInfoes.SubdivisionIdSourceDeduction);
          for (const paymentSourceDeduction of paymentSourceDeductions.controls as PhxFormGroup<wo.IPaymentSourceDeductions>[]) {
            const previousSourceDeduction = previousVersionPaymentInfoes.PaymentSourceDeductions?.find(f => f.SubdivisionIdSourceDeduction === paymentSourceDeduction.value.SourceDeductionTypeId);

            if (!previousSourceDeduction) {
              this.setAllChildControlsAsChanged(paymentSourceDeduction);
            } else {
              this.compareValue(paymentSourceDeduction.controls.IsApplied, previousSourceDeduction.IsApplied);
              this.compareValue(paymentSourceDeduction.controls.IsOverWritable, previousSourceDeduction.IsOverWritable);
              this.compareValue(paymentSourceDeduction.controls.RateAmount, previousSourceDeduction.RateAmount);
              this.compareValue(paymentSourceDeduction.controls.RatePercentage, previousSourceDeduction.RatePercentage);
              this.compareValue(paymentSourceDeduction.controls.ToShow, previousSourceDeduction.ToShow);
            }
          }
        }
      }
    }
  }

  private compareExpenseAndInvoiceTab(): void {
    const tabExpenseForm = this.currentForm.controls.TabExpenseInvoice as PhxFormGroup<wo.ITabExpenseInvoice>;

    const tabExpenseDetailsForm = tabExpenseForm.controls.TabExpenseInvoiceDetail as PhxFormGroup<wo.ITabExpenseInvoiceDetail>;
    this.compareValue(tabExpenseDetailsForm.controls.ExpenseApprovalFlowId, this.previousVersion.ExpenseApprovalFlowId);
    this.compareValue(tabExpenseDetailsForm.controls.ExpenseMethodologyId, this.previousVersion.ExpenseMethodologyId);
    this.compareValue(tabExpenseDetailsForm.controls.ExpenseThirdPartyWorkerReference, this.previousVersion.ExpenseThirdPartyWorkerReference);
    this.compareValue(tabExpenseDetailsForm.controls.IsExpenseUsesProjects, this.previousVersion.IsExpenseUsesProjects);
    this.compareValue(tabExpenseDetailsForm.controls.OrganizationIdInternal, this.previousWorkOrderVersion.OrganizationIdInternal);

    const expenseApprovers = tabExpenseDetailsForm.controls.ExpenseApprovers as PhxFormGroup<wo.ITabExpenseInvoiceApprovers>;
    const compareApprovers = (approvers: PhxFormArray<wo.IExpenseApprover>, approverType: number) => {
      const previousApprovers = this.previousVersion.ExpenseApprovers?.filter(f => f.ApproverTypeId === approverType);
      if (approvers?.value?.length < previousApprovers?.length) {
        this.removedItemsList.push('Expense approver');
        /** NOTE: we add a null control so the 'Expense and Invoice' tab shows as having a change */
        this.cacheChangedControl(null);

      }
      for (const approver of approvers.controls as PhxFormGroup<wo.IExpenseApprover>[]) {
        if (approver.value.UserProfileId && !previousApprovers?.some(f => f.UserProfileId === approver.value.UserProfileId)) {
          this.cacheChangedControl(approver.controls.UserProfileId);
        }
      }
    };

    const clientApprovers = expenseApprovers.controls.ClientApprover as PhxFormArray<wo.IExpenseApprover>;
    const internalApprovers = expenseApprovers.controls.InternalApprover as PhxFormArray<wo.IExpenseApprover>;
    const supplierApprovers = expenseApprovers.controls.SupplierApprover as PhxFormArray<wo.IExpenseApprover>;

    compareApprovers(clientApprovers, PhxConstants.ApproverType.ClientApprover);
    compareApprovers(internalApprovers, PhxConstants.ApproverType.InternalApprover);
    compareApprovers(supplierApprovers, PhxConstants.ApproverType.SupplierApprover);

    const tabExpenseBillingInfoes = tabExpenseForm.controls.TabExpenseInvoiceBillingInfoes as PhxFormGroup<wo.IBillingInfoes>;
    const billingInfoes = tabExpenseBillingInfoes.controls.BillingInfoes as PhxFormArray<wo.IBillingInfo>;
    const billingInfo = billingInfoes.controls[0] as PhxFormGroup<wo.IBillingInfo>;
    const previousBillingInfo = this.previousVersion.BillingInfoes[0];
    const billingInvoices = billingInfo.controls.BillingInvoices as PhxFormArray<wo.IBillingInvoice>;
    this.compareBillingInvoice(billingInvoices, previousBillingInfo.BillingInvoices, PhxConstants.InvoiceType.Expense);

    const tabExpensePaymentInfoes = tabExpenseForm.controls.TabExpenseInvoicePaymentInfoes as PhxFormGroup<wo.IPaymentInfoes>;
    const paymentInfoes = tabExpensePaymentInfoes.controls.PaymentInfoes as PhxFormArray<wo.IPaymentInfo>;
    this.comparePaymentInfoInvoice(paymentInfoes, this.previousVersion.PaymentInfoes, PhxConstants.InvoiceType.Expense);
  }

  private compareTimesheetAndInvoiceTab(): void {
    const tabTimesheetForm = this.currentForm.controls.TabTimeMaterialInvoice as PhxFormGroup<wo.ITabTimeMaterialInvoice>;

    const tabTimesheetFormDetails = tabTimesheetForm.controls.TabTimeMaterialInvoiceDetail as PhxFormGroup<wo.ITabTimeMaterialInvoiceDetail>;
    this.compareValue(tabTimesheetFormDetails.controls.IsSeasonalOrPartTimeTimesheet, this.previousVersion.IsSeasonalOrPartTimeTimesheet);
    this.compareValue(tabTimesheetFormDetails.controls.IsTimeSheetUsesProjects, this.previousVersion.IsTimeSheetUsesProjects);
    this.compareValue(tabTimesheetFormDetails.controls.IsTimesheetProjectMandatory, this.previousVersion.IsTimesheetProjectMandatory);
    this.compareValue(tabTimesheetFormDetails.controls.OrganizationIdClient, this.previousVersion.BillingInfoes[0].OrganizationIdClient);
    this.compareValue(tabTimesheetFormDetails.controls.TimeSheetApprovalFlowId, this.previousVersion.TimeSheetApprovalFlowId);
    this.compareValue(tabTimesheetFormDetails.controls.TimeSheetCycleId, this.previousVersion.TimeSheetCycleId);
    this.compareValue(tabTimesheetFormDetails.controls.TimeSheetDescription, this.previousVersion.TimeSheetDescription);
    this.compareValue(tabTimesheetFormDetails.controls.TimeSheetMethodologyId, this.previousVersion.TimeSheetMethodologyId);
    this.compareValue(tabTimesheetFormDetails.controls.VmsWorkOrderReference, this.previousVersion.VmsWorkOrderReference);

    const timesheetApprovers = tabTimesheetFormDetails.controls.TimeSheetApprovers as PhxFormArray<wo.ITimeSheetApprover>;
    const previousApproverProfileIds = this.previousVersion.TimeSheetApprovers?.map(f => f.UserProfileId);
    if (timesheetApprovers?.value?.length < previousApproverProfileIds?.length) {
      this.removedItemsList.push('Timesheet approver');
      /** NOTE: we add a null control so the 'Timesheet and Invoice' tab shows as having a change */
      this.cacheChangedControl(null);
    }
    for (const approver of timesheetApprovers.controls as PhxFormGroup<wo.ITimeSheetApprover>[]) {
      if (!!approver.value.UserProfileId && !previousApproverProfileIds?.some(f => f === approver.value.UserProfileId)) {
        this.cacheChangedControl(approver.controls.UserProfileId);
      }
    }

    /** NOTE: the override exception controls are in a custom form - thos values are compared as a custom form when the tab renders - this comparison
     * needs to happen so the 'timesheet and invoice' tab shows an icon before the tab renders
     */
    const timesheetExceptionsForm = tabTimesheetForm.controls.TabMaterialTimesheetExceptions as PhxFormGroup<wo.IOverrideTimesheetExceptions>;
    const timesheetExceptions = timesheetExceptionsForm.controls.OverrideTimesheetExceptions as PhxFormArray<IOverrideTimesheetException>;
    this.compareOverrideTimesheetExceptions(timesheetExceptions, this.previousVersion.OverrideTimesheetExceptions);

    const tabTimesheetBillingInfoes = tabTimesheetForm.controls.TabTimeMaterialInvoiceBillingInfoes as PhxFormGroup<wo.IBillingInfoes>;
    const billingInfoes = tabTimesheetBillingInfoes.controls.BillingInfoes as PhxFormArray<wo.IBillingInfo>;
    const billingInfo = billingInfoes.controls[0] as PhxFormGroup<wo.IBillingInfo>;
    const previousBillingInfo = this.previousVersion.BillingInfoes[0];

    const billingInvoices = billingInfo.controls.BillingInvoices as PhxFormArray<wo.IBillingInvoice>;
    this.compareBillingInvoice(billingInvoices, previousBillingInfo.BillingInvoices, PhxConstants.InvoiceType.TimeSheet);

    const tabTimesheetPaymentInfoes = tabTimesheetForm.controls.TabTimeMaterialInvoicePaymentInfoes as PhxFormGroup<wo.IPaymentInfoes>;
    const paymentInfoes = tabTimesheetPaymentInfoes.controls.PaymentInfoes as PhxFormArray<wo.IPaymentInfo>;
    this.comparePaymentInfoInvoice(paymentInfoes, this.previousVersion.PaymentInfoes, PhxConstants.InvoiceType.TimeSheet);
  }

  private comparePartiesAndRatesTab(): void {

    const tabPartiesForm = this.currentForm.controls.TabParties as PhxFormGroup<wo.ITabPartiesandRates>;

    //#region RATE NEGOTIATION
    /** TODO: some of these may not need to be compared - need to compare to visible UI controls */
    const tabPartiesRateNegotiation = tabPartiesForm.controls.RateNegotiation as PhxFormGroup<wo.IRateNegotiation>;
    this.compareValue(tabPartiesRateNegotiation.controls.AdditionalRateSavings, this.previousVersion.AdditionalRateSavings);
    this.compareValue(tabPartiesRateNegotiation.controls.BurdenSavings, this.previousVersion.BurdenSavings);
    this.compareValue(tabPartiesRateNegotiation.controls.BurdenUsedPercentage, this.previousVersion.BurdenUsedPercentage);
    this.compareValue(tabPartiesRateNegotiation.controls.ClientGroup, this.previousVersion.ClientGroup);
    this.compareValue(tabPartiesRateNegotiation.controls.FeeSavings, this.previousVersion.FeeSavings);
    this.compareValue(tabPartiesRateNegotiation.controls.InitialAskBillRate, this.previousVersion.InitialAskBillRate);
    this.compareValue(tabPartiesRateNegotiation.controls.InitialAskPayRate, this.previousVersion.InitialAskPayRate);
    this.compareValue(tabPartiesRateNegotiation.controls.IsRateCardUsed, this.previousVersion.IsRateCardUsed);
    this.compareValue(tabPartiesRateNegotiation.controls.IsRatePrenegotiated, this.previousVersion.IsRatePrenegotiated);
    this.compareValue(tabPartiesRateNegotiation.controls.IsRateWithinRateCard, this.previousVersion.IsRateWithinRateCard);
    this.compareValue(tabPartiesRateNegotiation.controls.JobPostingNumber, this.previousVersion.JobPostingNumber);
    this.compareValue(tabPartiesRateNegotiation.controls.MaxBillRate, this.previousVersion.MaxBillRate);
    this.compareValue(tabPartiesRateNegotiation.controls.RateCardJobTitle, this.previousVersion.RateCardJobTitle);
    this.compareValue(tabPartiesRateNegotiation.controls.RateNegotiationAdditionalInfo, this.previousVersion.RateNegotiationAdditionalInfo);
    this.compareValue(tabPartiesRateNegotiation.controls.SubvendorActualBurdenCosts, this.previousVersion.SubvendorActualBurdenCosts);
    this.compareValue(tabPartiesRateNegotiation.controls.SubvendorActualPayRate, this.previousVersion.SubvendorActualPayRate);
    this.compareValue(tabPartiesRateNegotiation.controls.SubvendorActualProfileTypeId, this.previousVersion.SubvendorActualProfileTypeId);
    this.compareValue(tabPartiesRateNegotiation.controls.SubvendorMargin, this.previousVersion.SubvendorMargin);
    //#endregion

    const tabPartiesBillingInfo = tabPartiesForm.controls.TabPartyBillingInfoes as PhxFormGroup<wo.IBillingPartyInfoes>;
    if (tabPartiesBillingInfo) {
      const partiesRateDetails = tabPartiesBillingInfo.controls.PartiesRateDetails as PhxFormArray<wo.IPartiesRateDetail>;
      const billingRateDetailsFormGroup = partiesRateDetails.controls[0] as PhxFormGroup<wo.IPartiesRateDetail>;
      const previousVersionBillingInfoes = this.previousVersion.BillingInfoes[0];

      this.compareValue(billingRateDetailsFormGroup.controls.OrganizationClientDisplayName, previousVersionBillingInfoes.OrganizationClientDisplayName);
      this.compareValue(billingRateDetailsFormGroup.controls.OrganizationIdClient, previousVersionBillingInfoes.OrganizationIdClient);
      this.compareValue(billingRateDetailsFormGroup.controls.UserProfileIdClient, previousVersionBillingInfoes.UserProfileIdClient);
      this.compareValue(billingRateDetailsFormGroup.controls.Hours, previousVersionBillingInfoes.Hours);
      this.compareValue(billingRateDetailsFormGroup.controls.CurrencyId, previousVersionBillingInfoes.CurrencyId);

      const billingRateDetail = billingRateDetailsFormGroup.controls.BillingRatesDetail as PhxFormGroup<wo.IBillingRatesDetails>;
      if (billingRateDetail) {

        const overTimeFormGroup = (tabPartiesForm.controls.TabPartyPaymentInfoes as PhxFormGroup<wo.IPaymentPartyInfoes>).controls.OverTimeDetails as PhxFormGroup<wo.OvertimeDetails>;
        this.compareValue(overTimeFormGroup.controls.AutoCalculateOvertime, this.previousVersion.AutoCalculateOvertime);
        this.compareValue(overTimeFormGroup.controls.AutoCalculateOvertimeExemptionReason, this.previousVersion.AutoCalculateOvertimeExemptionReason);
        this.compareValue(overTimeFormGroup.controls.IsOvertimeExempt, this.previousVersion.IsOvertimeExempt);
        this.compareValue(overTimeFormGroup.controls.OvertimeExemptionReason, this.previousVersion.OvertimeExemptionReason);

        const billingRateDetailRates = billingRateDetail.controls.BillingRates as PhxFormArray<wo.IBillingRate>;
        if (billingRateDetailRates.controls.length < previousVersionBillingInfoes.BillingRates?.length) {
          this.removedItemsList.push('Billing rate');
          /** NOTE: we add a null control so the 'Parties and Rates' tab shows as having a change */
          this.cacheChangedControl(null);
        }

        for (const billingRate of billingRateDetailRates.controls as PhxFormGroup<wo.IBillingRate>[]) {
          const previousRate = previousVersionBillingInfoes.BillingRates?.find(f => f.RateTypeId === billingRate?.value?.RateTypeId);
          if (!previousRate) {
            this.setAllChildControlsAsChanged(billingRate);
          } else {
            this.compareValue(billingRate.controls.Description, previousRate.Description);
            this.compareValue(billingRate.controls.Rate, previousRate.Rate);
            this.compareValue(billingRate.controls.RateUnitId, previousRate.RateUnitId);
          }
        }
      }

      const billingClientDiscount = billingRateDetailsFormGroup.controls.ClientDiscount as PhxFormGroup<wo.IClientDiscount>;
      if (billingClientDiscount) {
        this.compareValue(billingClientDiscount.controls.HasClientDiscount, this.previousVersion.HasClientDiscount);
        this.compareValue(billingClientDiscount.controls.HasClientPercentDiscount, this.previousVersion.HasClientPercentDiscount);
        this.compareValue(billingClientDiscount.controls.HasClientPerHourDiscount, this.previousVersion.HasClientPerHourDiscount);
        this.compareValue(billingClientDiscount.controls.HasClientFlatDiscountAmount, this.previousVersion.HasClientFlatDiscountAmount);
        this.compareValue(billingClientDiscount.controls.ClientPercentDiscount, this.previousVersion.ClientPercentDiscount);
        this.compareValue(billingClientDiscount.controls.ClientFlatDiscountAmount, this.previousVersion.ClientFlatDiscountAmount);
        this.compareValue(billingClientDiscount.controls.ClientPerHourDiscount, this.previousVersion.ClientPerHourDiscount);
        this.compareValue(billingClientDiscount.controls.ClientDiscountDescription, this.previousVersion.ClientDiscountDescription);
      }

      const billingRebateVms = billingRateDetailsFormGroup.controls.RebateAndVMSFee as PhxFormGroup<wo.IRebateAndVMSFee>;
      if (billingRebateVms) {
        this.compareValue(billingRebateVms.controls.HasRebate, this.previousVersion.HasRebate);
        this.compareValue(billingRebateVms.controls.HasVmsFee, this.previousVersion.HasVmsFee);
        this.compareValue(billingRebateVms.controls.RebateHeaderId, this.previousVersion.RebateHeaderId);
        this.compareValue(billingRebateVms.controls.RebateTypeId, this.previousVersion.RebateTypeId);
        this.compareValue(billingRebateVms.controls.RebateRate, this.previousVersion.RebateRate);
        this.compareValue(billingRebateVms.controls.VmsFeeHeaderId, this.previousVersion.VmsFeeHeaderId);
        this.compareValue(billingRebateVms.controls.VmsFeeTypeId, this.previousVersion.VmsFeeTypeId);
        this.compareValue(billingRebateVms.controls.VmsFeeRate, this.previousVersion.VmsFeeRate);
        this.compareValue(billingRebateVms.controls.IsFlowdownFee, this.previousVersion.IsFlowdownFee);
      }
    }

    const tabPartiesPaymentInfo = tabPartiesForm.controls.TabPartyPaymentInfoes as PhxFormGroup<wo.IPaymentPartyInfoes>;
    if (tabPartiesPaymentInfo) {
      const tabPartiesPaymentInfoRateDetails = tabPartiesPaymentInfo.controls.PaymentPartiesRateDetails as PhxFormArray<wo.IPaymentPartiesRateDetail>;

      for (const paymentInfo of tabPartiesPaymentInfoRateDetails.controls as PhxFormGroup<wo.IPaymentPartiesRateDetail>[]) {
        const previousVersionPaymentInfo = this.previousVersion.PaymentInfoes.find(f => f.OrganizationIdSupplier === paymentInfo?.value?.OrganizationIdSupplier);
        const paymentInfoRatesFormGroup = paymentInfo.controls.PaymentRatesDetail as PhxFormGroup<wo.IPaymentRatesDetail>;
        const paymentInfoRates = paymentInfoRatesFormGroup.controls.PaymentRates as PhxFormArray<wo.IPaymentRate>;
        const paymentPaySideDeductions = paymentInfo.controls.PaymentPaySideDeductions as PhxFormArray<wo.IPaymentPaySideDeduction>;

        if (paymentInfoRates.value.length < previousVersionPaymentInfo?.PaymentRates?.length) {
          this.removedItemsList.push('Payment rate');
          /** NOTE: we add a null control so the 'Parties and Rates' tab shows as having a change */
          this.cacheChangedControl(null);
        }

        if (!previousVersionPaymentInfo) {
          this.cacheChangedControl(paymentInfo.controls.UserProfileIdWorker);
          this.cacheChangedControl(paymentInfo.controls.OrganizationRoleTypeId);
          this.cacheChangedControl(paymentInfo.controls.UserProfileIdSupplier);
          this.cacheChangedControl(paymentInfo.controls.Hours);
          this.cacheChangedControl(paymentInfo.controls.CurrencyId);

          for (const rate of paymentInfoRates.controls as PhxFormGroup<wo.IPaymentRate>[]) {
            this.setAllChildControlsAsChanged(rate);
          }

          const paymentInfoContact = paymentInfo.controls.PaymentContacts as PhxFormArray<wo.IPaymentContact>;
          for (const contact of paymentInfoContact.controls as PhxFormGroup<wo.IPaymentContact>[]) {
            this.setAllChildControlsAsChanged(contact);
          }

          for (const paySideDeduction of paymentPaySideDeductions.controls as PhxFormGroup<wo.IPaymentPaySideDeduction>[]) {
            this.setAllChildControlsAsChanged(paySideDeduction);
          }

        } else {
          this.compareValue(paymentInfo.controls.OrganizationIdSupplier, previousVersionPaymentInfo.OrganizationIdSupplier);
          this.compareValue(paymentInfo.controls.UserProfileIdWorker, this.previousWorkOrderVersion.UserProfileIdWorker);
          this.compareValue(paymentInfo.controls.OrganizationRoleTypeId, previousVersionPaymentInfo.OrganizationRoleTypeId);
          this.compareValue(paymentInfo.controls.UserProfileIdSupplier, previousVersionPaymentInfo.UserProfileIdSupplier);
          this.compareValue(paymentInfo.controls.Hours, previousVersionPaymentInfo.Hours);
          this.compareValue(paymentInfo.controls.CurrencyId, previousVersionPaymentInfo.CurrencyId);

          for (const rate of paymentInfoRates.controls as PhxFormGroup<wo.IPaymentRate>[]) {
            const previousVersionRate = previousVersionPaymentInfo.PaymentRates?.find(f => f.RateTypeId === rate.value.RateTypeId);
            if (!previousVersionRate) {
              this.setAllChildControlsAsChanged(rate);
            } else {
              this.compareValue(rate.controls.Description, previousVersionRate.Description);
              this.compareValue(rate.controls.IsApplyDeductions, previousVersionRate.IsApplyDeductions);
              this.compareValue(rate.controls.IsApplyStatHoliday, previousVersionRate.IsApplyStatHoliday);
              this.compareValue(rate.controls.IsApplyVacation, previousVersionRate.IsApplyVacation);
              this.compareValue(rate.controls.Rate, previousVersionRate.Rate);
              this.compareValue(rate.controls.RateUnitId, previousVersionRate.RateUnitId);

            }
          }

          for (let i = 0; i < (paymentPaySideDeductions.controls as PhxFormGroup<wo.IPaymentPaySideDeduction>[]).length; i++) {
            const paySideDeduction = (paymentPaySideDeductions.controls as PhxFormGroup<wo.IPaymentPaySideDeduction>[])[i];
            const previousVersionPaySideDeduction = previousVersionPaymentInfo.PaymentPaySideDeductions?.[i];
          
            if (!previousVersionPaySideDeduction) {
              this.setAllChildControlsAsChanged(paySideDeduction);
            } else {
              this.compareValue(paySideDeduction.controls.PaySideDeductionHeaderId, previousVersionPaySideDeduction.PaySideDeductionHeaderId);
            }
          }
        }
      }
    }

    const tabPartiesIncentive = tabPartiesForm.controls.IncentiveCompensation as PhxFormGroup<wo.IIncentiveCompensation>;
    if (tabPartiesIncentive) {
      this.compareValue(tabPartiesIncentive.controls.CommissionThirdPartyWorkerReference, this.previousVersion.CommissionThirdPartyWorkerReference);
      this.compareValue(tabPartiesIncentive.controls.IsCommissionVacation, this.previousVersion.PaymentInfoes[0].IsCommissionVacation);
      this.compareValue(tabPartiesIncentive.controls.IsEligibleForAllowance, this.previousVersion.IsEligibleForAllowance);
      this.compareValue(tabPartiesIncentive.controls.IsEligibleForCommission, this.previousVersion.IsEligibleForCommission);
      this.compareValue(tabPartiesIncentive.controls.IsThirdPartyImport, this.previousVersion.IsThirdPartyImport);

      const tabPartiesIncentivePayment = tabPartiesIncentive.controls.TabPartiesAndRatesPaymentInfoes as PhxFormGroup<wo.IPaymentInfoes>;
      const tabPartiesIncentivePaymentInfoes = tabPartiesIncentivePayment.controls.PaymentInfoes as PhxFormArray<wo.IPaymentInfo>;

      for (const paymentInfo of tabPartiesIncentivePaymentInfoes.controls as PhxFormGroup<wo.IPaymentInfo>[]) {

        const previousVersionPaymentInfo = this.previousVersion.PaymentInfoes.find(f => f.OrganizationIdSupplier === paymentInfo?.value?.OrganizationIdSupplier);

        const paymentInfoInvoices = paymentInfo.controls.PaymentInvoices as PhxFormArray<wo.IPaymentInvoice>;
        for (const invoiceControl of paymentInfoInvoices.controls as PhxFormGroup<wo.IPaymentInvoice>[]) {
          if (invoiceControl.value.InvoiceTypeId === PhxConstants.InvoiceType.IncentiveCompensation) {
            if (!previousVersionPaymentInfo) {
              this.setAllChildControlsAsChanged(invoiceControl);
            } else {
              this.comparePaymentInvoiceFormGroup(invoiceControl,
                previousVersionPaymentInfo.PaymentInvoices?.find(f => f.InvoiceTypeId === PhxConstants.InvoiceType.IncentiveCompensation)
              );
            }
          }
        }
      }
    }
  }

  private compareCoreTab(): void {

    const tabCoreForm = this.currentForm.controls.TabCore as PhxFormGroup<wo.ICoreTabRoot>;

    const tabCoreFormDetails = tabCoreForm.controls.Details as PhxFormGroup<wo.ITabCoreDetails>;
    this.compareValue((tabCoreForm.controls.Collaborators as PhxFormGroup<wo.ITabCoreCollaborators>).controls.AssignedToUserProfileId, this.previousVersion.AssignedToUserProfileId);

    this.compareValue(tabCoreFormDetails.controls.WorksiteId, this.previousVersion.WorksiteId);
    this.compareValue(tabCoreFormDetails.controls.WorkerLocationId, this.previousVersion.WorkerLocationId);

    const currentUseStateDate = this.coreDetailFormService.displayWorkOrderStartEndDateState(this.currentWorkOrderVersion);
    const previousUseStateDate = this.coreDetailFormService.displayWorkOrderStartEndDateState(this.previousWorkOrderVersion);

    if (currentUseStateDate && previousUseStateDate) {
      this.compareValue(tabCoreFormDetails.controls.WorkOrderStartDateState, this.previousVersion.WorkOrderStartDateState);
      this.compareValue(tabCoreFormDetails.controls.WorkOrderEndDateState, this.previousVersion.WorkOrderEndDateState);
    } else if (!currentUseStateDate && previousUseStateDate) {
      this.compareValue(tabCoreFormDetails.controls.StartDate, this.previousVersion.WorkOrderStartDateState);
      this.compareValue(tabCoreFormDetails.controls.EndDate, this.previousVersion.WorkOrderEndDateState);
    } else if (currentUseStateDate && !previousUseStateDate) {
      this.compareValue(tabCoreFormDetails.controls.WorkOrderStartDateState, this.previousWorkOrderVersion.StartDate);
      this.compareValue(tabCoreFormDetails.controls.WorkOrderEndDateState, this.previousWorkOrderVersion.EndDate);
    } else {
      this.compareValue(tabCoreFormDetails.controls.StartDate, this.previousWorkOrderVersion.StartDate);
      this.compareValue(tabCoreFormDetails.controls.EndDate, this.previousWorkOrderVersion.EndDate);
    }

    this.compareValue(tabCoreFormDetails.controls.OrganizationIdInternal, this.previousWorkOrderVersion.OrganizationIdInternal);
    this.compareValue(tabCoreFormDetails.controls.HolidayScheduleNameId, this.previousVersion.HolidayScheduleNameId);
    this.compareValue(tabCoreFormDetails.controls.InternalOrganizationDefinition1Id, this.previousVersion.InternalOrganizationDefinition1Id);

    const tabCoreFormJob = tabCoreForm.controls.Job as PhxFormGroup<wo.ITabCoreJob>;
    this.compareValue(tabCoreFormJob.controls.ClientPositionTitle, this.previousVersion.ClientPositionTitle);
    this.compareValue(tabCoreFormJob.controls.IsClientPositionTitleSameAsJobFunction, this.previousVersion.IsClientPositionTitleSameAsJobFunction);
    this.compareValue(tabCoreFormJob.controls.JobCategoryId, this.previousVersion.JobCategoryId);
    this.compareValue(tabCoreFormJob.controls.JobFunctionId, this.previousVersion.JobFunctionId);
    this.compareAsNumberArray(tabCoreFormJob.controls.JobSkills, this.previousVersion.JobSkills.map(skill => skill.JobSkillId));

    const tabCoreFormCommisions = tabCoreForm.controls.Commissions as PhxFormGroup<wo.ITabCoreCommissions>;
    this.compareValue(tabCoreFormCommisions.controls.IsRecruiterRemoved, this.previousVersion.IsRecruiterRemoved);
    this.compareValue(tabCoreFormCommisions.controls.JobOwnerUsesSupport, this.previousVersion.JobOwnerUsesSupport);
    this.compareValue(tabCoreFormCommisions.controls.SalesPatternId, this.previousVersion.SalesPatternId);

    const tabCoreFormCommisionsJobOwner = tabCoreFormCommisions.controls.JobOwner as PhxFormGroup<wo.IJobOwner>;
    this.compareValue(tabCoreFormCommisionsJobOwner.controls.UserProfileIdSales, this.previousVersion.JobOwner?.UserProfileIdSales);

    /** NOTE: compare support */
    if (tabCoreFormCommisions.controls.SupportingJobOwners.value?.length !== this.previousVersion.SupportingJobOwners?.length) {
      this.cacheChangedControl(tabCoreFormCommisions.controls.SupportingJobOwners);
    } else if (this.areNumberArraysDifferent(
      tabCoreFormCommisions.controls.SupportingJobOwners.value?.map(m => m.UserProfileIdSales),
      this.previousVersion.SupportingJobOwners?.map(m => m.UserProfileIdSales)
    )) {
      this.cacheChangedControl(tabCoreFormCommisions.controls.SupportingJobOwners);
    }

    /** NOTE: compare recruiters */
    if (tabCoreFormCommisions.controls.Recruiters.value?.length !== this.previousVersion.Recruiters?.length) {
      this.cacheChangedControl(tabCoreFormCommisions.controls.Recruiters);
    } else if (this.areNumberArraysDifferent(
      tabCoreFormCommisions.controls.Recruiters.value.map(m => m.UserProfileIdSales),
      this.previousVersion.Recruiters.map(m => m.UserProfileIdSales)
    )) {
      this.cacheChangedControl(tabCoreFormCommisions.controls.Recruiters);
    }
  }

  private comparePaymentInvoiceFormGroup(formGroup: PhxFormGroup<wo.IPaymentInvoice>, previousVersion: wo.IPaymentInvoice): void {
    this.compareValue(formGroup.controls.PaymentInvoiceTermsId, previousVersion.PaymentInvoiceTermsId);
    this.compareValue(formGroup.controls.PaymentMethodId, previousVersion.PaymentMethodId);
    this.compareValue(formGroup.controls.PaymentInvoiceTemplateId, previousVersion.PaymentInvoiceTemplateId);
    this.compareValue(formGroup.controls.InvoiceTypeId, previousVersion.InvoiceTypeId);
    this.compareValue(formGroup.controls.PaymentFrequency, previousVersion.PaymentFrequency);
    this.compareValue(formGroup.controls.IsSalesTaxAppliedOnVmsImport, previousVersion.IsSalesTaxAppliedOnVmsImport);
    this.compareValue(formGroup.controls.PaymentReleaseScheduleId, previousVersion.PaymentReleaseScheduleId);
  }

  private compareBillingInvoice(billingInvoices: PhxFormArray<wo.IBillingInvoice>, previousBillingInvoices: wo.IBillingInvoice[], invoiceType: number): void {
    if (billingInvoices?.value?.length < previousBillingInvoices?.length) {
      this.removedItemsList.push('Billing invoice');
      /** NOTE: we add a null control so the tab being compared shows as having a change */
      this.cacheChangedControl(null);
    }
    for (const billingInvoice of billingInvoices.controls as PhxFormGroup<wo.IBillingInvoice>[]) {
      if (billingInvoice.value.InvoiceTypeId === invoiceType) {
        const previousBillingInvoice = previousBillingInvoices?.find(f => f.InvoiceTypeId === invoiceType);

        this.compareValue(billingInvoice.controls.BillingInvoicePresentationStyleId, previousBillingInvoice.BillingInvoicePresentationStyleId);
        this.compareValue(billingInvoice.controls.BillingConsolidationTypeId, previousBillingInvoice.BillingConsolidationTypeId);
        this.compareValue(billingInvoice.controls.BillingTransactionGenerationMethodId, previousBillingInvoice.BillingTransactionGenerationMethodId);
        this.compareValue(billingInvoice.controls.IsUsesAlternateBilling, previousBillingInvoice.IsUsesAlternateBilling);
        this.compareValue(billingInvoice.controls.OrganizatonClientRoleAlternateBillingId, previousBillingInvoice.OrganizatonClientRoleAlternateBillingId);
        this.compareValue(billingInvoice.controls.BillingFrequencyId, previousBillingInvoice.BillingFrequencyId);
        this.compareValue(billingInvoice.controls.BillingInvoiceTermsId, previousBillingInvoice.BillingInvoiceTermsId);
        this.compareValue(billingInvoice.controls.IsSalesTaxAppliedOnVmsImport, previousBillingInvoice.IsSalesTaxAppliedOnVmsImport);
        this.compareValue(billingInvoice.controls.BillingInvoiceTemplateId, previousBillingInvoice.BillingInvoiceTemplateId);
        this.compareValue(billingInvoice.controls.BillingReferenceContactProfileId, previousBillingInvoice.BillingReferenceContactProfileId);
        this.compareValue(billingInvoice.controls.InvoiceNote1, previousBillingInvoice.InvoiceNote1);
        this.compareValue(billingInvoice.controls.InvoiceNote2, previousBillingInvoice.InvoiceNote2);
        this.compareValue(billingInvoice.controls.InvoiceNote3, previousBillingInvoice.InvoiceNote3);
        this.compareValue(billingInvoice.controls.InvoiceNote4, previousBillingInvoice.InvoiceNote4);

        const billingRecipients = billingInvoice.controls.BillingRecipients as PhxFormArray<wo.IBillingRecipient>;
        for (const billingRecipient of billingRecipients.controls as PhxFormGroup<wo.IBillingRecipient>[]) {
          const previousRecipient = previousBillingInvoice.BillingRecipients?.find(f => f.UserProfileId === billingRecipient.value.UserProfileId);
          if (!previousRecipient) {
            this.setAllChildControlsAsChanged(billingRecipient);
          } else {
            this.compareValue(billingRecipient.controls.UserProfileId, previousRecipient.UserProfileId);
            this.compareValue(billingRecipient.controls.DeliveryMethodId, previousRecipient.DeliveryMethodId);
            this.compareValue(billingRecipient.controls.FileExportFormatId, previousRecipient.FileExportFormatId);
            this.compareValue(billingRecipient.controls.DeliverToUserProfileId, previousRecipient.DeliverToUserProfileId);
          }
        }
      }
    }
  }

  private comparePaymentInfoInvoice(paymentInfoes: PhxFormArray<wo.IPaymentInfo>, previousPaymentInfoes: wo.IPaymentInfo[], invoiceType: number): void {
    if (paymentInfoes?.value?.length < previousPaymentInfoes?.length) {
      this.removedItemsList.push('Payment party');
      /** NOTE: we add a null control so the tab being compared shows as having a change */
      this.cacheChangedControl(null);
    }

    for (const paymentInfo of paymentInfoes.controls as PhxFormGroup<wo.IPaymentInfo>[]) {
      const previousVersionPaymentInfo = previousPaymentInfoes.find(f => f.OrganizationIdSupplier === paymentInfo.value.OrganizationIdSupplier);
      const paymentInvoices = paymentInfo.controls.PaymentInvoices as PhxFormArray<wo.IPaymentInvoice>;

      if (paymentInvoices?.value?.length < previousVersionPaymentInfo?.PaymentInvoices?.length) {
        this.removedItemsList.push('Payment invoice');
        /** NOTE: we add a null control so the tab being compared shows as having a change */
        this.cacheChangedControl(null);
      }

      if (!previousVersionPaymentInfo) {
        this.cacheChangedControl(paymentInfo.controls.IsOrganizationSupplierSubVendor);
        this.cacheChangedControl(paymentInfo.controls.OrganizationSupplierDisplayName);

        for (const invoice of paymentInvoices.controls as PhxFormGroup<wo.IPaymentInvoice>[]) {
          this.setAllChildControlsAsChanged(invoice);
        }
      } else {
        this.compareValue(paymentInfo.controls.IsOrganizationSupplierSubVendor, previousVersionPaymentInfo.IsOrganizationSupplierSubVendor);
        this.compareValue(paymentInfo.controls.OrganizationSupplierDisplayName, previousVersionPaymentInfo.OrganizationSupplierDisplayName);

        const paymentInfoInvoices = paymentInfo.controls.PaymentInvoices as PhxFormArray<wo.IPaymentInvoice>;
        for (const invoiceControl of paymentInfoInvoices.controls as PhxFormGroup<wo.IPaymentInvoice>[]) {
          if (invoiceControl.value.InvoiceTypeId === invoiceType) {
            this.comparePaymentInvoiceFormGroup(invoiceControl,
              previousVersionPaymentInfo.PaymentInvoices.find(f => f.InvoiceTypeId === invoiceType)
            );
          }
        }
      }
    }
  }

  private getComparisonMessage() {
    return this.removedItemsList?.length ? `Removal of ${this.removedItemsList.join(', ')}` : '';
  }
}
