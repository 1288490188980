import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';

import { CnrWithholdingTaxFormService } from './cnr-withholding-tax-form.service';
import { OtherEarningsFormService } from './other-earnings-form.service';
import { StatutoryHolidayFormService } from './statutory-holiday-form.service';
import { WorkplaceSafetyInsuranceFormService } from './workplace-safety-insurance-form.service';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormBuilder, FormGroup, FormArray } from '../../../common/ngx-strongly-typed-forms/model';
import { IPaymentInfo, IPaymentInfoDetails, IPaymentSourceDeductions, ISourceDeductions, ITabEarningsAndDeductions, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';

@Injectable()
export class EarningsDeductionsTabFormService implements IFormService {

  formGroup: FormGroup<ITabEarningsAndDeductions>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private cnrWithholdingTaxFormService: CnrWithholdingTaxFormService,
    private otherEarningsFormService: OtherEarningsFormService,
    private statutoryHolidayFormService: StatutoryHolidayFormService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
  ) { }

  get paymentInfoFormArray(): FormArray<IPaymentInfoDetails> {
    return this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfoDetails>;
  }

  get workerProfileTypeIdValue(): number {
    return this.formGroup.get('WorkerProfileTypeId').value;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabEarningsAndDeductions> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<ITabEarningsAndDeductions>({
      OtherEarnings: this.otherEarningsFormService.createForm(workorder, isDestroyed$),
      WorkplaceSafetyInsurance: this.workplaceSafetyInsuranceFormService.createForm(workorder, isDestroyed$),
      CanadianNonResidentWithholdingTax: this.cnrWithholdingTaxFormService.createForm(workorder, isDestroyed$),
      StatutoryHoliday: this.statutoryHolidayFormService.createForm(workorder, isDestroyed$),
      PaymentInfoes: this.createPaymentInfoFormArray(workorder.WorkOrderVersion.PaymentInfoes, workorder),
      AccrueEmployerHealthTaxLiability: [workorder.WorkOrderVersion.AccrueEmployerHealthTaxLiability],
      WorkerProfileTypeId: [workorder.workerProfileTypeId],
      WorkerContactId: [workorder.workerContactId]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.otherEarningsFormService.setupFormListeners();
    this.workplaceSafetyInsuranceFormService.setupFormListeners();
    this.cnrWithholdingTaxFormService.setupFormListeners();
    this.statutoryHolidayFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.otherEarningsFormService.formGroupToPartial(workOrder);
    workOrder = this.statutoryHolidayFormService.formGroupToPartial(workOrder);
    workOrder = this.workplaceSafetyInsuranceFormService.formGroupToPartial(workOrder);
    workOrder = this.cnrWithholdingTaxFormService.formGroupToPartial(workOrder);
    workOrder = this.sourceDeductionFormGroupToPartial(workOrder);
    workOrder = this.paymentSourceDeductionFormArrayToPartial(workOrder);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    this.otherEarningsFormService.updateForm(workorder);
    this.workplaceSafetyInsuranceFormService.updateForm(workorder);
    this.cnrWithholdingTaxFormService.updateForm(workorder);
    this.statutoryHolidayFormService.updateForm(workorder);

    this.formGroup.patchValue({
      AccrueEmployerHealthTaxLiability: workorder.WorkOrderVersion.AccrueEmployerHealthTaxLiability,
      WorkerProfileTypeId: workorder.workerProfileTypeId,
      WorkerContactId: workorder.workerContactId
    }, { emitEvent: false });

    this.updatePaymentInfoesForm(workorder);
  }

  updatePaymentInfoesForm(workorder: IWorkOrder) {
    this.updatePaymentInfoFormArray(this.paymentInfoFormArray, workorder.WorkOrderVersion.PaymentInfoes, workorder);
  }

  getPaymentSourceDeductionsFormArray(paymentSourceDeductions: Array<IPaymentSourceDeductions>) {
    return this.createPaymentSourceDeductionsFormArray(paymentSourceDeductions);
  }

  getPaymentSourceDeductionsFormGroup(paymentSourceDeduction: IPaymentSourceDeductions) {
    return this.createPaymentSourceDeductionsFormGroup(paymentSourceDeduction);
  }

  updateAccrueEmployerHealthTaxLiability(value: boolean, emitEvent = false) {
    this.formGroup.get('AccrueEmployerHealthTaxLiability').patchValue(value, { emitEvent });
  }

  setPaymentInfoSourceDeductions(index: number) {
    const formGroup = this.paymentInfoFormArray.at(index) as FormGroup<IPaymentInfoDetails>;
    formGroup.setControl('PaymentSourceDeductions', this.getPaymentSourceDeductionsFormArray([]));
  }

  get subdivisionIdSourceDeductionControl() {
    const formGroup = this.paymentInfoFormArray.at(0) as FormGroup<IPaymentInfoDetails>;
    if (!formGroup) {
      return null;
    }
    return formGroup.get('SubdivisionIdSourceDeduction');
  }

  public get cNRWithholdingTaxIsAppliedFormControl() {
    return this.cnrWithholdingTaxFormService.cNRWithholdingTaxIsAppliedFormControl;
  }

  updateSubdivisionIdSourceDeduction(paymentInfoes: Array<IPaymentInfo>, workOrder: IWorkOrder, emitEvent = false) {
    paymentInfoes.forEach((item, index) => {
      const formGroup = this.paymentInfoFormArray.at(index) as FormGroup<IPaymentInfoDetails>;
      if (formGroup) {
        formGroup.get('SubdivisionIdSourceDeduction').patchValue(item.SubdivisionIdSourceDeduction, { emitEvent });
        this.updateSourceDeductionFormGroup(formGroup.get('SourceDeductions') as FormGroup<ISourceDeductions>, item, workOrder);
      }
    });
  }

  private createPaymentInfoFormArray(paymentInfoes: IPaymentInfo[], workorder: IWorkOrder): FormArray<IPaymentInfoDetails> {
    return this.fb.array<IPaymentInfoDetails>(
      paymentInfoes.map((paymentInfo: IPaymentInfo) => this.createPaymentInfoFormGroup(paymentInfo, workorder))
    );
  }

  private createPaymentInfoFormGroup(paymentInfo: IPaymentInfo, workorder: IWorkOrder) {
    return this.fb.group<IPaymentInfoDetails>({
      PaymentInfoId: [paymentInfo.Id],
      OrganizationIdSupplier: [paymentInfo.OrganizationIdSupplier],
      SubdivisionIdSourceDeduction: [paymentInfo.SubdivisionIdSourceDeduction],
      SourceDeductions: this.createSourceDeductionFormGroup(paymentInfo, workorder),
      PaymentSourceDeductions: this.createPaymentSourceDeductionsFormArray(paymentInfo.PaymentSourceDeductions)
    });
  }

  private createSourceDeductionFormGroup(paymentInfo: IPaymentInfo, workorder: IWorkOrder): FormGroup<ISourceDeductions> {
    const isValidate = paymentInfo.OrganizationIdSupplier === null &&
      (workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp ||
        workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp);


    return this.fb.group<ISourceDeductions>({
      SubdivisionIdSourceDeduction: [
        paymentInfo.SubdivisionIdSourceDeduction,
        isValidate && PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'SubdivisionIdSourceDeduction', [Validators.required]
        )
      ],
      IsUseUserProfileWorkerSourceDeduction: [
        paymentInfo.IsUseUserProfileWorkerSourceDeduction,
        isValidate && PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'IsUseUserProfileWorkerSourceDeduction', [Validators.required]
        )
      ]
    });
  }

  private createPaymentSourceDeductionsFormArray(paymentSourceDeductions: Array<IPaymentSourceDeductions>): FormArray<IPaymentSourceDeductions> {
    return this.fb.array<IPaymentSourceDeductions>(paymentSourceDeductions.map((paymentSourceDeduction: any) =>
      this.createPaymentSourceDeductionsFormGroup(paymentSourceDeduction))
    );
  }

  private createPaymentSourceDeductionsFormGroup(paymentSourceDeduction: IPaymentSourceDeductions): FormGroup<IPaymentSourceDeductions> {
    return this.fb.group<IPaymentSourceDeductions>({
      IsApplied: [
        paymentSourceDeduction.IsApplied,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'IsApplied', [
          Validators.required
        ])
      ],
      RatePercentage: [
        paymentSourceDeduction.RatePercentage,
        paymentSourceDeduction.IsApplied && paymentSourceDeduction.RatePercentage !== null
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'RatePercentage', [
            Validators.required
          ])
          : null
      ],
      RateAmount: [
        paymentSourceDeduction.RateAmount,
        paymentSourceDeduction.IsApplied && paymentSourceDeduction.SourceDeductionTypeId === PhxConstants.SourceDeductionType.AdditionalTax
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'RateAmount', [
            Validators.required
          ])
          : null
      ],
        IsOverWritable: [paymentSourceDeduction.IsOverWritable],
      SourceDeductionTypeId: [paymentSourceDeduction.SourceDeductionTypeId],
      ToShow: [paymentSourceDeduction.ToShow]
    });
  }

  private sourceDeductionFormGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const paymentInfoesValues = this.paymentInfoFormArray.value;
    paymentInfoesValues.map((paymentInfo: IPaymentInfoDetails) => {
      const currentPaymentInfoIndex = workOrder.WorkOrderVersion.PaymentInfoes.findIndex(a => a.Id === paymentInfo.PaymentInfoId);
      workOrder.WorkOrderVersion.PaymentInfoes[currentPaymentInfoIndex].SubdivisionIdSourceDeduction = paymentInfo.SourceDeductions.SubdivisionIdSourceDeduction;
      workOrder.WorkOrderVersion.PaymentInfoes[currentPaymentInfoIndex].IsUseUserProfileWorkerSourceDeduction = paymentInfo.SourceDeductions.IsUseUserProfileWorkerSourceDeduction;
    });
    return workOrder;
  }

  private paymentSourceDeductionFormArrayToPartial(workOrder: IWorkOrder): IWorkOrder {
    const sourceDeductions = this.paymentInfoFormArray.value;
    sourceDeductions.forEach((paymentInfo: IPaymentInfoDetails) => {
      const paymentInfoIndex = workOrder.WorkOrderVersion.PaymentInfoes.findIndex(a => a.Id === paymentInfo.PaymentInfoId);
      workOrder.WorkOrderVersion.PaymentInfoes[paymentInfoIndex].PaymentSourceDeductions = paymentInfo.PaymentSourceDeductions;
    });
    return workOrder;
  }

  private updatePaymentInfoFormArray(
    formArray: FormArray<IPaymentInfoDetails>,
    paymentInfoes: IPaymentInfo[],
    workorder: IWorkOrder
  ) {
    if (formArray.length && paymentInfoes.length) {
      paymentInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentInfoDetails>;
        if (formGroup) {
          this.updatePaymentInfoFormGroup(formGroup, item, workorder);
        } else {
          formArray.push(this.createPaymentInfoFormGroup(item, workorder));
        }
      });
      if (formArray.length > paymentInfoes.length) {
        this.clearArray(formArray, paymentInfoes.length);
      }
    } else if (paymentInfoes.length) {
      const array = this.createPaymentInfoFormArray(paymentInfoes, workorder);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentInfoFormGroup(
    formGroup: FormGroup<IPaymentInfoDetails>,
    paymentInfo: IPaymentInfo,
    workorder: IWorkOrder
  ) {
    formGroup.patchValue({
      PaymentInfoId: paymentInfo.Id,
      OrganizationIdSupplier: paymentInfo.OrganizationIdSupplier,
      SubdivisionIdSourceDeduction: paymentInfo.SubdivisionIdSourceDeduction
    }, { emitEvent: false });

    const sourceDeductionsFormGroup = formGroup.get('SourceDeductions') as FormGroup<ISourceDeductions>;
    this.updateSourceDeductionFormGroup(sourceDeductionsFormGroup, paymentInfo, workorder);

    const paymentSourceDeductionsFormArray = formGroup.get('PaymentSourceDeductions') as FormArray<IPaymentSourceDeductions>;
    this.updatePaymentSourceDeductionsFormArray(paymentSourceDeductionsFormArray, paymentInfo.PaymentSourceDeductions);
  }

  private updateSourceDeductionFormGroup(
    formGroup: FormGroup<ISourceDeductions>,
    paymentInfo: IPaymentInfo,
    workorder: IWorkOrder
  ) {
    const isValidate = paymentInfo.OrganizationIdSupplier === null &&
      (workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp ||
        workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp);

    formGroup.get('SubdivisionIdSourceDeduction').setValidators(
      isValidate && PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'SubdivisionIdSourceDeduction', [Validators.required]
      )
    );

    formGroup.get('IsUseUserProfileWorkerSourceDeduction').setValidators(
      isValidate && PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'IsUseUserProfileWorkerSourceDeduction', [Validators.required]
      )
    );

    formGroup.patchValue({
      SubdivisionIdSourceDeduction: paymentInfo.SubdivisionIdSourceDeduction,
      IsUseUserProfileWorkerSourceDeduction: paymentInfo.IsUseUserProfileWorkerSourceDeduction,
    }, { emitEvent: false });
  }

  private updatePaymentSourceDeductionsFormArray(
    formArray: FormArray<IPaymentSourceDeductions>,
    paymentSourceDeductions: Array<IPaymentSourceDeductions>
  ) {
    if (formArray.length && paymentSourceDeductions.length) {
      paymentSourceDeductions.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentSourceDeductions>;
        if (formGroup) {
          this.updatePaymentSourceDeductionsFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentSourceDeductionsFormGroup(item));
        }
      });
      if (formArray.length > paymentSourceDeductions.length) {
        this.clearArray(formArray, paymentSourceDeductions.length);
      }
    } else if (paymentSourceDeductions.length) {
      const array = this.createPaymentSourceDeductionsFormArray(paymentSourceDeductions);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentSourceDeductionsFormGroup(
    formGroup: FormGroup<IPaymentSourceDeductions>,
    paymentSourceDeduction: IPaymentSourceDeductions
  ) {
    formGroup.get('IsApplied').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'IsApplied', [
        Validators.required
      ])
    );

    formGroup.get('RatePercentage').setValidators(
      paymentSourceDeduction.IsApplied && paymentSourceDeduction.RatePercentage !== null
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'RatePercentage', [
          Validators.required
        ])
        : null
    );

    formGroup.get('RateAmount').setValidators(
      paymentSourceDeduction.IsApplied && paymentSourceDeduction.SourceDeductionTypeId === PhxConstants.SourceDeductionType.AdditionalTax
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions', 'RateAmount', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      IsApplied: paymentSourceDeduction.IsApplied,
      RatePercentage: paymentSourceDeduction.RatePercentage,
      RateAmount: paymentSourceDeduction.RateAmount,
      IsOverWritable: paymentSourceDeduction.IsOverWritable,
      SourceDeductionTypeId: paymentSourceDeduction.SourceDeductionTypeId,
      ToShow: paymentSourceDeduction.ToShow
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IPaymentInfoDetails | IPaymentSourceDeductions>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
