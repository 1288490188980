import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IRuleResult, RuleStrictness } from '../../model/rule-engine';
import { RuleDisplay } from '../../model/rule-display';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-phx-checklist-list',
  templateUrl: './phx-checklist-list.component.html',
  styleUrls: ['./phx-checklist-list.component.less']
})
export class PhxChecklistListComponent implements OnChanges {
  @Input() entityToValidate: any;
  @Input() entityType: string;
  @Input() action: string;
  @Input() displayConfiguration: RuleDisplay;

  /** NOTE: parent components can pass in rule results for display instead of this component fetching them */
  @Input() entityRuleResults: IRuleResult[];

  @Output() hasFailedRules = new EventEmitter<boolean>();
  @Output() hasPassedRules = new EventEmitter<boolean>();
  @Output() hasNaRules = new EventEmitter<boolean>();

  RuleStrictness = RuleStrictness;

  ruleResults: IRuleResult[] = [];
  ranRuleSet = false;
  loading = true;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.entityToValidate) {
      this.setCompliancyRuleResults();
    } else if (changes?.entityRuleResults) {
      this.ruleResults = changes.entityRuleResults.currentValue;
      this.loading = false;
    }
  }

  private setCompliancyRuleResults(): void {
    if (this.canValidate() && !this.ranRuleSet) {
      this.loading = true;
      this.ranRuleSet = true;
      this.apiService.httpPostRequest<IRuleResult[]>(`RuleEngine/RunRuleSet/${this.entityType}/${this.action}`, { Entity: this.entityToValidate }, environment.ruleEngineApiEndpoint, false).subscribe(
        ruleResults => {
          this.loading = false;
          this.ruleResults = ruleResults;

          let hasPassedRules = false;
          let hasFailedRules = false;
          let hasNaRules = false;

          if (ruleResults.some(f => f.isValid)) {
            hasPassedRules = true;
          }

          if (ruleResults.some(f => !f.isValid)) {
            hasFailedRules = true;
          }

          if (ruleResults.some(f => f.strictness === 10)) {
            hasNaRules = true;
          }

          this.hasFailedRules.emit(hasFailedRules);
          this.hasPassedRules.emit(hasPassedRules);
          this.hasNaRules.emit(hasNaRules);
        }

      );
    }
  }

  private canValidate() {
    if (!this.displayConfiguration) {
      this.displayConfiguration = {
        showValid: true,
        showInvalid: true,
        showNotApplicable: true
      };
    }
    return !!this.entityToValidate && !!this.entityType && !!this.action;
  }
}
