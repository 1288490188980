import { Component, Input, OnInit } from '@angular/core';
import { IPaymentInfo, IPaymentSalesTax, IReadOnlyStorage } from '../../models';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { CodeValueService, PhxConstants } from '../../../common';
import { CodeValueGroups } from '../../../common/model';
import { WorkorderService } from '../../services';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-payment-taxes',
  templateUrl: './workorder-payment-taxes.component.html',
  styleUrls: ['./workorder-payment-taxes.component.less']
})
export class WorkorderPaymentTaxesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() inputFormGroup: FormGroup<IPaymentInfo>;
  @Input() salesTaxJurisdictions: Array<any>;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() editable: boolean;
  @Input() userProfileIdWorker: number;
  @Input() paymentInfoIndex: number;
  @Input() workerProfileTypeId: number;
  @Input() validateComplianceDraft: boolean;
  phxConstants = PhxConstants;
  salesTaxTerritories: Array<any>;
  codeValueGroups = CodeValueGroups;

  constructor(
    private workorderService: WorkorderService,
    private codeValueService: CodeValueService,
    private authService: AuthService
  ) {
    super();
  }

  get paymentSalesTaxesFormArray(): FormArray<IPaymentSalesTax> {
    return this.inputFormGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
  }

  get taxJurisdictions() {
    if (this.salesTaxJurisdictions === undefined) {
      return [];
    }
    return this.salesTaxJurisdictions[this.paymentInfoIndex];
  }

  ngOnInit() {
    this.salesTaxTerritories = this.codeValueService.getRelatedCodeValues(this.codeValueGroups.Subdivision, PhxConstants.Country.CA, this.codeValueGroups.Country);
    if (this.inputFormGroup?.controls) {
      const subdivisionIdSalesTax = this.inputFormGroup.controls.SubdivisionIdSalesTax ? this.inputFormGroup.controls.SubdivisionIdSalesTax.value : null;
      const paymentSalesTaxes = this.inputFormGroup.controls.PaymentSalesTaxes as FormArray<IPaymentSalesTax>;
      const needPercentage = paymentSalesTaxes?.value?.length ? paymentSalesTaxes.value.some(x => !x.ratePercentage && x.ratePercentage !== 0) : false;
      if (needPercentage && subdivisionIdSalesTax) {

        // ASYNC CALL HERE, POTENTIAL RACE CONDITION
        this.workorderService.getPaymentSalesTaxes(this.inputFormGroup.value, this.paymentInfoIndex, this.userProfileIdWorker, this.workerProfileTypeId).then((response: Array<IPaymentSalesTax>) => {
          paymentSalesTaxes.controls.forEach(i => {
            if (i instanceof FormGroup) {
              const z = response.find(x => x.SalesTaxId === i.controls.SalesTaxId.value);
              if (z) {
                i.controls.DisplayName.setValue(z.DisplayName, { emitEvent: false });
                i.controls.hasNumber.setValue(z.hasNumber, { emitEvent: false });
                i.controls.ratePercentage.setValue(z.ratePercentage, { emitEvent: false });
              }
            }
          });
        });
      }
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  isEligebleForPaymentSalesTax() {
    const allowedProfileTypes = [this.phxConstants.UserProfileType.WorkerCanadianSp  , this.phxConstants.UserProfileType.WorkerUnitedStatesLLC];
    return  allowedProfileTypes.indexOf(this.workerProfileTypeId) !== -1;
  }

}
