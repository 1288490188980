
import { Component, Input, OnInit } from '@angular/core';

import { ICanadianNonResidentWithholdingTax, IReadOnlyStorage } from '@workorder/models';
import { CnrWithholdingTaxFormService } from '@workorder/services/forms/cnr-withholding-tax-form.service';

import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';


@Component({
  selector: 'app-workorder-tab-earnings-and-deduction-non-resident-withholding',
  templateUrl: './workorder-tab-earnings-and-deduction-cnr-withholding-tax.component.html'
})
export class WorkorderTabEarningsAndDeductionCNRWithholdingTaxComponent implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  
  formGroup: FormGroup<ICanadianNonResidentWithholdingTax>;

  constructor(
    private cnrWithholdingTaxFormService: CnrWithholdingTaxFormService,
  ) { }

  ngOnInit() {
    this.formGroup = this.cnrWithholdingTaxFormService.formGroup;
  }
}
