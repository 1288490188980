import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { BusinessConfigurationParams } from './models/business-configuration-params.model';

@Injectable({
  providedIn: 'root'
})
export class FboMonolithAdminDataService {
  constructor(private apiService: ApiService) {}

  public getBusinessRuleConfiguration(businessConfigurationKeyId: number, configParams: BusinessConfigurationParams = {}, showSpinner?: boolean): Observable<string> {
    const path = `admin/business-rule-configuration-data/${businessConfigurationKeyId}`;
    const params = new HttpParams({ fromObject: configParams });
    return this.apiService.httpGetRequest<string>({ path, params, showSpinner });
  }
}
