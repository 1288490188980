import { Injectable } from '@angular/core';
import { BehaviorSubject, from as observableFrom, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdjustmentNewDto } from '../../workorder/models';
import { remove, uniq } from 'lodash';
import { ApiService, CommonService, PhxConstants } from '../../common';
import {
  BranchManaged,
  BranchManager,
  CollaboratorWO,
  IUserProfileListItemDto,
  ProfileReassignFrom,
  RecruiterProfile,
  RecruiterWO
} from '../models/model';
import { EntityList } from '../../common/model';
import { IProfile } from '../models/profile.interface';

@Injectable()
export class ContactService {

  private defaultCountry: string = PhxConstants.CodeCountry.CA;
  private countrySubject$ = new BehaviorSubject<string>(this.defaultCountry);
  private loginInfoCache: Map<number, any>;

  countryChanged$ = this.countrySubject$.asObservable();
  branchList = [];

  constructor(
    private apiService: ApiService,
    private commonService: CommonService
  ) {
  }

  public getBranchList(): Observable<any> {
    if (this.branchList.length) {
      return of(this.branchList);
    }

    const oDataParams = oreq
      .request()
      .withSelect(['Id', 'Name', 'IsActive'])
      .url();
    return this.apiService.query(`branch/list?${oDataParams}&`).pipe(tap(branches => this.branchList = branches));
  }


  setCountry(countryCode: string) {
    this.defaultCountry = countryCode;
    this.countrySubject$.next(countryCode);
  }

  getWorkOrderVersionsAssociatedToCollaborator(assignedToUserProfileId: number) {
    return this.apiService.query<EntityList<CollaboratorWO>>(`assignment/collaborator/${assignedToUserProfileId}`)
      .pipe(map(x => x.Items));
  }

  getBranchByManager(assignedToUserProfileId: number) {
    return this.apiService.query<EntityList<BranchManager>>(`branch/manager/${assignedToUserProfileId}`)
      .pipe(map(x => {
        return x.Items.map(branch => {
          const branchManaged = new BranchManaged();
          branchManaged.CodeInternalOrganizationDefinition1Id = branch.CodeInternalOrganizationDefinition1Id;
          branchManaged.ManagerId = branch.Id;
          return branchManaged;
        });
      }));
  }

  getWorkOrdersByRecruiter(recruiterProfileId: number) {
    return this.apiService.query<EntityList<RecruiterWO>>(`assignment/getWorkOrdersByRecruiter/${recruiterProfileId}`)
      .pipe(map(x => x.Items));
  }

  getActiveRecruiters(branchId: number = null): Observable<RecruiterProfile[]> {
    let filter = oreq
      .filter('UserProfileCommissions')
      .any(
        oreq
          .filter('x/CommissionRoleId')
          .eq(PhxConstants.CommissionRole.RecruiterRole)
          .and()
          .filter('x/CommissionRateHeaderStatusId')
          .eq(PhxConstants.CommissionRateHeaderStatus.Active)
      )
      .and()
      .filter('UserProfileFunctionalRoles')
      .any(oreq.filter('x/FunctionalRoleId').eq(PhxConstants.FunctionalRole.Recruiter))
      .and()
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.Active);

    if (branchId) {
      filter = filter
        .and()
        .filter('InternalOrganizationDefinition1Id')
        .eq(branchId);
    }
    const oDataParams = oreq
      .request()
      .withExpand(['UserProfileCommissions', 'Contact'])
      .withSelect(['Id', 'Contact/FullName', 'InternalOrganizationDefinition1Id'])
      .withFilter(filter)
      .url();
    return this.apiService.query<EntityList<IProfile>>('UserProfile/getListUserProfileInternal' + (oDataParams ? '?' + oDataParams : ''))
      .pipe(map(res => {
        return res.Items.map(x => {
          const recruiterProfile = new RecruiterProfile();
          recruiterProfile.ProfileId = x.Id;
          recruiterProfile.FullName = x.Contact.FullName;
          recruiterProfile.BranchId = x.InternalOrganizationDefinition1Id;
          return recruiterProfile;
        });
      }));
  }

  getProfileReassignFrom(sourceProfileId: number) {
    const filter = oreq.filter('Id').eq(sourceProfileId);
    const oDataParams = oreq
      .request()
      .withExpand(['Contact', 'UserProfileFunctionalRoles'])
      .withSelect(['Id', 'ChildId', 'ContactId', 'ChildContactId', 'Contact/FullName', 'UserProfileFunctionalRoles'])
      .withFilter(filter)
      .url();
    return this.apiService.query<EntityList<IProfile>>('UserProfile/getListUserProfileInternal' + (oDataParams ? '?' + oDataParams : ''))
      .pipe(map(res => {
        const profileReassignFrom: ProfileReassignFrom = new ProfileReassignFrom();
        if (res.Items.length) {
          const profileInfo = res.Items[0];
          profileReassignFrom.latestProfileId = profileInfo.ChildId ? profileInfo.ChildId : profileInfo.Id;
          profileReassignFrom.latestContactId = profileInfo.ChildContactId ? profileInfo.ChildContactId : profileInfo.ContactId;
          profileReassignFrom.FullName = profileInfo.Contact.FullName;
          profileReassignFrom.UserProfileFunctionalRoles = profileInfo.UserProfileFunctionalRoles;
        }
        return profileReassignFrom;
      }));
  }

  public getLoginInfo(id) {
    const cachedInfo = this.loginInfoCache?.get(id);
    if (cachedInfo) {
      return of(cachedInfo);
    } else {
      return this.apiService.query('Contact/LoginInfo?loginUserId=' + id, false).pipe(
        tap(response => {
          if (!this.loginInfoCache) {
            this.loginInfoCache = new Map();
          }
          this.loginInfoCache.set(id, response);
        })
      );
    }
  }

  public getContactPortalMigrationStatus(contactId) {
    return this.apiService.query<string>('Contact/ContactPortalMigrationStatus?contactId=' + contactId, false);
  }

  getListOrganizationClientForWorkerProfile(userProfileIdWorker, oDataParams?: any) {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code', 'IsTest'])
        .url();
    return this.apiService.query('org/getListOrganizationClientForWorkerProfile/' + userProfileIdWorker + (oDataParams ? '?' + oDataParams : ''));
  }

  public getAllOriginalAccessSubscriptions(tableState, oDataParams) {
    const tableStateParams = this.commonService.generateRequestObject(tableState).url();
    return this.apiService.query('AccessSubscription/getAllOriginalAccessSubscriptions' + '?' + (oDataParams ? oDataParams + '&' : '') + tableStateParams);
  }

  public getUserProfileGarnisheeDetail(profileId, garnisheeId) {
    return this.apiService.query('UserProfile/getUserProfileGarnisheeDetail/profile/' + profileId + '/garnishee/' + garnisheeId, false);
  }

  public garnisheeNewSave(command) {
    return observableFrom(this.apiService.command('GarnisheeNew', command));
  }

  public garnisheeSubmit(command) {
    return observableFrom(this.apiService.command('GarnisheeSubmit', command));
  }

  public getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole(oDataParams = null) {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code', 'IsTest'])
        .url();
    return this.apiService
      .query<EntityList<any>>('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole' + (oDataParams ? '?' + oDataParams : ''), false);
  }

  public getListGarnisheePayToGroup() {
    return this.apiService.query('org/getListGarnisheePayToGroup', false);
  }

  public getUserProfileAdvanceDetail(profileId, advanceId) {
    return this.apiService.query('UserProfile/getUserProfileAdvanceDetail/profile/' + profileId + '/advance/' + advanceId);
  }

  public getListOriginalOrganizations(oDataQuery) {
    return this.apiService.query<EntityList<any>>(`org/getListOriginalOrganizations?${oDataQuery}`);
  }

  public getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientOrIndependentContractorRoleOrSubVendorRole(oDataParams) {
    oDataParams = oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code'])
        .url();
    return this.apiService.query<EntityList<any>>(
      'org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientOrIndependentContractorRoleOrSubVendorRole' + (oDataParams ? '?' + oDataParams : ''));
  }

  public searchProfiles(organizationId: number) {
    let oDataQuery: string;

    if (organizationId) {
      oDataQuery = oreq
        .request()
        .withExpand(['Contact'])
        .withSelect(['Id', 'OrganizationId', 'Contact/Id', 'Contact/FullName'])
        .withFilter(oreq.filter('OrganizationId').eq(organizationId))
        .withOrderby(['Contact/FullName'])
        .url();
    } else {
      oDataQuery = oreq
        .request()
        .withExpand(['Contact'])
        .withSelect(['Id', 'OrganizationId', 'Contact/Id', 'Contact/FullName'])
        .withOrderby(['Contact/FullName'])
        .url();
    }

    return this.apiService.query<EntityList<IUserProfileListItemDto>>('UserProfile/' + 0 + `/getUserProfileTypes?${oDataQuery}`)
      .pipe(map(response => {
        this.setProfileListDisplayName(response.Items);
        return response.Items;
      }));
  }

  public searchProfiles2(organizationId: number, profileTypeId: number) {
    const oDataQuery = oreq
      .request()
      .withExpand(['Contact'])
      .withSelect(['Id', 'Contact/Id', 'Contact/FullName'])
      .withFilter(oreq.filter('ProfileTypeId').eq(profileTypeId))
      .withOrderby(['Contact/FullName'])
      .url();

    return this.apiService.query<EntityList<IUserProfileListItemDto>>(`UserProfile/getProfilesForOrganization/${organizationId || 0}?${oDataQuery}`)
      .pipe(map(response => {
        this.setProfileListDisplayName(response.Items);
        return response.Items;
      }));
  }

  public removeInactiveProfile(profiles: IUserProfileListItemDto[], exceptionIds: number[]) {
    this.removeInactiveProfileWithConfig(profiles, exceptionIds, 'ProfileStatusId', 'Id');
  }

  public removeInactiveProfileWithConfig<T>(profiles: T[], exceptionIds: number[], profileStatusIdField: string, idField: string) {
    const inactiveProfileStatusIds = [
      PhxConstants.ProfileStatus.Inactive,
      PhxConstants.ProfileStatus.PendingDeactivation,
      PhxConstants.ProfileStatus.PendingActivation
    ];

    const exceptionProfileIds = uniq(exceptionIds);
    remove(profiles, profile => {
      return inactiveProfileStatusIds.includes(profile[profileStatusIdField]) && !exceptionProfileIds.includes(+profile[idField]);
    });
  }

  public getAdjustmentNew(workOrderVersionId, oDataParams = null) {
    return this.apiService.query<AdjustmentNewDto>('transactionHeader/getAdjustmentNew/' + workOrderVersionId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getSearchByUserProfileIdWorker(userProfileIdWorker) {
    const oDataQuery = oreq
      .request()
      .withSelect([
        'WorkOrderFullNumber',
        'WorkerName',
        'WorkerProfileType',
        'WorkOrderStatus',
        'AssignmentId',
        'WorkOrderId',
        'WorkOrderStatusId',
        'WorkOrderVersionId',
        'InternalCompanyDisplayName',
        'OrganizationIdInternal',
        'UserProfileIdWorker'
      ])
      .withFilter(
        oreq
          .filter('UserProfileIdWorker')
          .eq(userProfileIdWorker)
          .and()
          .filter('WorkOrderStatusId')
          .eq(PhxConstants.WorkOrderStatus.Active)
          .or()
          .filter('UserProfileIdWorker')
          .eq(userProfileIdWorker)
          .and()
          .filter('WorkOrderStatusId')
          .eq(PhxConstants.WorkOrderStatus.Complete)
          .or()
          .filter('UserProfileIdWorker')
          .eq(userProfileIdWorker)
          .and()
          .filter('WorkOrderStatusId')
          .eq(PhxConstants.WorkOrderStatus.Terminated)
          .or()
          .filter('UserProfileIdWorker')
          .eq(userProfileIdWorker)
          .and()
          .filter('WorkOrderStatusId')
          .eq(PhxConstants.WorkOrderStatus.ChangeInProgress)
      )
      .url();

    return this.apiService.query('assignment/getSearch' + '?' + oDataQuery);
  }

  public isEmailAlreadyInUse(contactId: number, email: string, sourceContactId: number = null) {
    return this.apiService.query<boolean>(`UserProfile/isEmailAlreadyInUse?contactId=${contactId}&sourceContactId=${sourceContactId}&email=${email}`, false);
  }

  getActiveInternalUserProfileList() {
    const filter = oreq.filter('ProfileStatusId').eq(PhxConstants.ProfileStatus.Active);
    const oDataParams = oreq
      .request()
      .withExpand(['Contact', 'UserProfileFunctionalRoles'])
      .withSelect(['Id', 'Contact/Id', 'Contact/FullName', 'PrimaryEmail', 'UserProfileFunctionalRoles'])
      .withFilter(filter)
      .url();
    return this.apiService.query<EntityList<any>>('UserProfile/getListUserProfileInternal' + (oDataParams ? '?' + oDataParams : ''))
      .pipe(map(x => x.Items));
  }

  private setProfileListDisplayName(profiles: IUserProfileListItemDto[]) {
    profiles.forEach(profile => profile.DisplayName = profile.Contact.FullName + ' - ' + profile.Contact.Id);
  }
}
