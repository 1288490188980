import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IJobOwner, IReadOnlyStorage } from '../../models';
import { FormControl, FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { PhxConstants } from 'src/app/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

// TODO: put this in commission-shared module, make it generic for WO and PP
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[app-workorder-commission-row]',
  templateUrl: './workorder-commission-row.component.html',
  styleUrls: ['./workorder-commission-row.component.less']
})
export class WorkorderCommissionRowComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() rowIndex: number;
  @Input() inputFormGroup: FormGroup<IJobOwner>;
  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  showTooltip: boolean;

  constructor(private authService: AuthService) {
    super();
  }

  get commissionRateHeaderIdFormControl(): FormControl<number> {
    return this.inputFormGroup.get('CommissionRateHeaderId') as FormControl<number>;
  }

  get commissionRateHeaderIdChange$() {
    return this.commissionRateHeaderIdFormControl.valueChanges;
  }

  ngOnInit() {
    this.showTooltip = this.inputFormGroup.value.CommissionRates.some(x => x.CommissionRateVersions.length);
  }

  setupFormListeners() {
    this.commissionRateHeaderIdChange$.pipe(
      filter(() => this.readOnlyStorage.IsCommissionsEditable),
      distinctUntilChanged(),
      takeUntil(this.isDestroyed$),
    ).subscribe((value: number) => {
      this.valueChanged.emit(value);
    });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

}
