import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { ApiService, CommonService, PhxConstants } from '../../../common';
import { PhxButton, StateAction } from '../../../common/model';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IFormGroupSetup, IJobOwner, IReadOnlyStorage, ITabCoreCommissions, ITabCoreDetails, IWorkOrder } from '../../models';
import { CoreCommissionFormService, CoreDetailFormService, WorkOrderDataService, WorkOrderFormService, WorkorderService } from '../../services';
import { CommissionChangeImpactComponent } from '../../../commission/commission-change-impact/commission-change-impact.component';
import { CommissionChangeImpactModalButtons, ICommissionChangeImpactSummary } from '../../../commission/commission-change-impact/commission-change-impact.interface';
import { WorkOrdernWorkflowComponent } from '../workorder-workflow/workorder-workflow.component';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-tab-core-commission',
  templateUrl: './workorder-tab-core-commission.component.html',
  styleUrls: ['./workorder-tab-core-commission.component.less']
})
export class WorkorderTabCoreCommissionComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  formGroup: FormGroup<ITabCoreCommissions>;

  @ViewChild('CommissionChangeImpact', { static: true }) commissionChangeImpact: CommissionChangeImpactComponent;
  @ViewChild('workOrderWorkFlow', { static: true }) workOrdernWorkflowComponent: WorkOrdernWorkflowComponent;

  workOrder: IWorkOrder;
  formGroupSetup: IFormGroupSetup;
  tabCoreDetailFormGroup: FormGroup<ITabCoreDetails>;
  SupportingJobOwners: FormArray<IJobOwner>;
  listUserProfileInternalCommission$: Subscription;
  listCommissionSalesPatterns$: Subscription;
  html: {
    lists: {
      salesPatterns: Array<{
        Id: number;
        Description: string;
      }>;
      JobOwners: Array<any>;
      UserProfileCommissions: Array<any>;
      JobOwnersWithSupport: Array<any>;
      JobOwnersNoSupport: Array<any>;
      SupportJobOwners: Array<any>;
      Recruiters: Array<any>;
    },
    isRecruitersAllowed: boolean;
    showChangeImpactMsg: boolean;
    showApproveImpactButton: boolean;
    impactMsgBeforeChange: string;
    impactMsgAfterChange: string;
    impactMsgUnapprovedCommissionChanges: string;
    showImpactApprovedBtn: boolean;
    impactMsgOnEmptyEffectiveDate: string;
    impactMsgLoading: string;
  } = {
    lists: {
      salesPatterns: [],
      JobOwners: [],
      UserProfileCommissions: [],
      JobOwnersWithSupport: [],
      SupportJobOwners: [],
      JobOwnersNoSupport: [],
      Recruiters: []
    },
    isRecruitersAllowed: false,
    showChangeImpactMsg: false,
    showApproveImpactButton: false,
    showImpactApprovedBtn: false,
    impactMsgBeforeChange: 'Changes may impact commissions which were already paid or pending payment.',
    impactMsgAfterChange: 'You must review and confirm any commission corrections before you can submit the Work Order.',
    impactMsgOnEmptyEffectiveDate: 'An effective date must be selected to enable commission updates.',
    impactMsgUnapprovedCommissionChanges: `<strong>You cannot submit this Work Order</strong> because there are some unapproved commission
      corrections. Please contact Finance and ask them to complete the change.`,
    impactMsgLoading: 'Please wait...'
  };
  InternalOrganizationDefinition1Id: number;
  OrganizationIdInternal: number;
  LineOfBusinessId: number;
  currentWorkOrderVersionId: number;
  originalWovCommissions: IJobOwner[] = [];
  commissionChangeImpactBtns: PhxButton[] = [];
  statusesNotToShowImpactApprovedBtn = [
    PhxConstants.WorkOrderVersionStatus.Deleted,
    PhxConstants.WorkOrderVersionStatus.PendingUnterminate
  ];

  hasWorkOrderVersionCommissionFieldsEditFunctionalRole: boolean;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private workorderService: WorkorderService,
    private workOrderFormService: WorkOrderFormService,
    private workOrderDataService: WorkOrderDataService,
    private coreDetailFormService: CoreDetailFormService,
    private coreCommissionFormService: CoreCommissionFormService,
    private commonListsObservableService: CommonListsObservableService,
    private authService: AuthService
  ) {
    super();
  }

  get CommissionImpactMessage() {
    if (this.workOrder.WorkOrderVersion.IsWovCommissionChanged == null) {
      return this.html.impactMsgLoading;
    } else if (!this.workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved && this.workOrder.WorkOrderVersion.IsWovCommissionChanged) {
      if (!this.hasWorkOrderVersionCommissionFieldsEditFunctionalRole) {
        return this.html.impactMsgUnapprovedCommissionChanges;
      }
      return this.workOrder.WorkOrderVersion.EffectiveDate ? this.html.impactMsgAfterChange : this.html.impactMsgOnEmptyEffectiveDate;
    } else {
      return this.html.impactMsgBeforeChange;
    }
  }

  get jobOwnerFormGroup(): FormGroup<IJobOwner> {
    return this.formGroup.get('JobOwner') as FormGroup<IJobOwner>;
  }

  get recruiterFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('Recruiters') as FormArray<IJobOwner>;
  }

  get supportingJobOwnerFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('SupportingJobOwners') as FormArray<IJobOwner>;
  }

  get commissionFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('WorkOrderVersionCommissions') as FormArray<IJobOwner>;
  }

  ngOnInit() {
    this.formGroup = this.coreCommissionFormService.formGroup;
    this.observeUseSupportChange();
    this.hasWorkOrderVersionCommissionFieldsEditFunctionalRole = this.authService.hasFunctionalOperation(PhxConstants.FunctionalOperation.WorkOrderVersionCommissionFieldsEdit);

    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.onWorkOrderChange(workorder);
        }
      });
  }

  onWorkOrderChange(workorder: IWorkOrder) {
    this.workOrder = workorder;

    const lob = workorder.WorkOrderVersion.LineOfBusinessId;
    this.setIsRecruitersAllowed(lob);

    this.html.showApproveImpactButton = this.workOrder.StatusId === PhxConstants.WorkOrderStatus.ChangeInProgress &&
      this.hasWorkOrderVersionCommissionFieldsEditFunctionalRole &&
      this.workOrder.WorkOrderVersion.IsComplianceDraftStatus &&
      !this.workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved;

    this.html.showChangeImpactMsg = this.workOrder.StatusId === PhxConstants.WorkOrderStatus.ChangeInProgress &&
      (this.hasWorkOrderVersionCommissionFieldsEditFunctionalRole
        || (this.workOrder.WorkOrderVersion.IsWovCommissionChanged && !this.workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved)) &&
      this.workOrder.WorkOrderVersion.IsComplianceDraftStatus;

    this.refreshShowImpactApprovedBtn();

    this.originalWovCommissions = workorder.PreviousWorkOrderVersionCommissions.map(wovc => wovc);
    this.tabCoreDetailFormGroup = this.coreDetailFormService.formGroup;

    const workOrderVersionId = this.workOrder.WorkOrderVersion.Id;

    if (workOrderVersionId !== this.currentWorkOrderVersionId) {

      // ASYNC CALL HERE, POTENTIAL RACE CONDITION
      this.getListSalesPatten();
      this.getUserProfileCommissionsList();
    }

    if (this.readOnlyStorage.IsCommissionsEditable) {
      this.coreCommissionFormService.checkRecruiters(this.html.isRecruitersAllowed);
    }

    this.currentWorkOrderVersionId = workOrderVersionId;
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  setIsRecruitersAllowed(lob: PhxConstants.LineOfBusiness) {
    this.html.isRecruitersAllowed = this.workorderService.getIsRecruitersAllowed(lob);
  }

  observeUseSupportChange() {
    this.coreCommissionFormService.usesSupportChange$.pipe(
      debounceTime(100),
      filter(() => this.coreCommissionFormService.isBusinessRulesEnabled),
      distinctUntilChanged(),
      takeUntil(this.isDestroyed$)
    ).subscribe(value => {
      const jobOwnerValue = this.coreCommissionFormService.jobOwnerFormGroup.value;
      if (value) {
        if (
          jobOwnerValue?.UserProfileIdSales &&
          !this.html.lists.JobOwnersWithSupport.some(obj => {
            return obj.Id === jobOwnerValue.UserProfileIdSales;
          })
        ) {
          this.coreCommissionFormService.updateUserProfileIdSale(null);
        }
        this.html.lists.JobOwners = this.html.lists.JobOwnersWithSupport;
        if (!this.coreCommissionFormService.salePatternIdFormControl.value) {
          this.addSupportingJobOwner();
        }
      } else {
        if (
          jobOwnerValue?.UserProfileIdSales &&
          !this.html.lists.JobOwnersNoSupport.some(obj => {
            return obj.Id === jobOwnerValue.UserProfileIdSales;
          })
        ) {
          this.coreCommissionFormService.updateUserProfileIdSale(null);
        }
        this.coreCommissionFormService.resetSupportingJobOwner();
        this.html.lists.JobOwners = this.html.lists.JobOwnersNoSupport;
      }
    });
  }

  /**
   * THIS IS FOR UI TO DISPLAY SALES PATTERN OPTIONS
   * ONLY REASON IT NEEDS THE CURRENT SALES PATTERN ID IS TO PREVENT A BLANK SELECTION
   */
  getListSalesPatten() {
    if (this.listCommissionSalesPatterns$) {
      this.listCommissionSalesPatterns$.unsubscribe();
    }
    this.listCommissionSalesPatterns$ = this.commonListsObservableService.listCommissionSalesPatterns$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        this.html.lists.salesPatterns = list
          .filter(item => item.SalesPatternStatusId === PhxConstants.CommissionRateHeaderStatus.Active
            || this.formGroup.controls.SalesPatternId.value === item.Id // THIS LOGIC NEEDS TO BE TAKEN OUT
          );
      });
  }

  /**
   * THIS IS FOR UI TO DISPLAY PROFILE DROPDOWN OPTIONS
   * REASON IT NEEDS THE MODEL:
   *  - FLAG IN THE MODEL IS TO MAKE SURE WE POPULATE THE RIGHT OPTIONS
   *      JOB OWNERS WITH SUPPORT VS JOB OWNERS NO SUPPORT
   *  - DRAFT FLAG TO CALL ANOTHER API
   */
  getUserProfileCommissionsList() {
    if (this.listUserProfileInternalCommission$) {
      this.listUserProfileInternalCommission$.unsubscribe();
    }
    this.listUserProfileInternalCommission$ = this.commonListsObservableService.listUserProfileInternalCommission$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        this.html.lists.UserProfileCommissions = list;
        const filterByCommissionRole = (role: any) => {
          return list.filter(res => res.ProfileStatusId !== 2 && res.UserProfileCommissions.some(obj => obj.CommissionRoleId === role));
        };
        this.html.lists.JobOwnersWithSupport = filterByCommissionRole(PhxConstants.CommissionRole.JobOwnerRoleWithSupport);
        this.html.lists.JobOwnersNoSupport = filterByCommissionRole(PhxConstants.CommissionRole.JobOwnerRoleNoSupport);
        this.html.lists.SupportJobOwners = filterByCommissionRole(PhxConstants.CommissionRole.SupportingJobOwner);
        this.html.lists.Recruiters = filterByCommissionRole(PhxConstants.CommissionRole.RecruiterRole);
        if (this.workOrder.WorkOrderVersion.JobOwnerUsesSupport == null) {
          this.html.lists.JobOwners = [];
        } else {
          // THIS LOGIC NEEDS TO BE TAKEN OUT
          this.html.lists.JobOwners = this.workOrder.WorkOrderVersion.JobOwnerUsesSupport ? this.html.lists.JobOwnersWithSupport : this.html.lists.JobOwnersNoSupport;
        }

      });
  }

  addRecruiter() {
    this.coreCommissionFormService.addRecruiter();
  }

  deleteRecruiter(index: number) {
    this.coreCommissionFormService.deleteRecruiter(index);
  }

  addSupportingJobOwner() {
    this.coreCommissionFormService.addSupportingJobOwner();
  }

  deleteSupportingJobOwner(index: number) {
    this.coreCommissionFormService.deleteSupportingJobOwner(index);
  }

  canViewCommissionRates() {
    return (
      this.tabCoreDetailFormGroup &&
      (this.tabCoreDetailFormGroup.get('InternalOrganizationDefinition1Id').value !== null &&
        this.tabCoreDetailFormGroup.get('InternalOrganizationDefinition1Id').value > 0 &&
        this.tabCoreDetailFormGroup.get('LineOfBusinessId').value !== null &&
        this.tabCoreDetailFormGroup.get('LineOfBusinessId').value > 0 &&
        this.tabCoreDetailFormGroup.get('OrganizationIdInternal').value !== null &&
        this.tabCoreDetailFormGroup.get('OrganizationIdInternal').value > 0 &&
        this.workOrder.WorkOrderVersion.BillingInfoes !== null &&
        this.workOrder.WorkOrderVersion.BillingInfoes.length > 0 &&
        this.workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient !== null &&
        this.workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient > 0)
    );
  }

  showChangeImpact(isImpactApproved: boolean) {
    const wovStatus = this.workOrder.WorkOrderVersion.StatusId;
    this.workOrderFormService.formGroupToPartial(this.workOrder);
      this.workOrderDataService.updateWorkOrder(this.workOrder);
    if (!isImpactApproved) {
      const commandBody = {
        EntityIds: [this.workOrder.WorkOrderVersion.Id],
        ...this.workOrder
      };      
      const action: StateAction = {
        actionId: PhxConstants.StateAction.WorkOrderVersionSave,
        commandName: 'WorkOrderVersionSave',
        onClick: () => {
        }
      };
      this.workOrdernWorkflowComponent.onClickStateAction(action, null, null, this.workOrder, () => {
        this.workorderService.getCommissionChangeImpactForWov(this.workOrder.WorkOrderVersion.Id).then(data => {
          // If the corrections are generated, show the impact modal
          if (data?.CommissionTransactionChangeImpacts?.length > 0) {
            this.workorderService.refreshWorkOrder(
              this.workOrder.WorkOrderId,
              this.workOrder.AssignmentId,
              this.workOrder.WorkOrderVersion.Id,
              this.workOrder.TemplateId
            );
            this.initItemButtons(this.workOrder, commandBody, 'Commission Change Impact Approved');
            this.commissionChangeImpact.showModal(data, isImpactApproved);
          } else {
            // If no corrections are generated, don't show the modal. Approve impact directly.
            this.apiService.command('WorkOrderVersionCommissionImpactApprove', commandBody)
              .then(() => {
                this.workorderService.refreshWorkOrder(
                  this.workOrder.WorkOrderId,
                  this.workOrder.AssignmentId,
                  this.workOrder.WorkOrderVersion.Id,
                  this.workOrder.TemplateId
                );
                const messageOnSuccessResponse = 'Commission change impact has been automatically approved because no corrections are needed.';
                this.commonService.logSuccess(messageOnSuccessResponse);
              })
              .catch(() => {
                // console.error(err);
              });
          }
        })
          .catch(() => {
            // console.error(err);
          });
      }, false);
    } else if (wovStatus === PhxConstants.WorkOrderVersionStatus.Approved || wovStatus === PhxConstants.WorkOrderVersionStatus.Replaced) {
      this.workorderService.getApprovedCommissionChangeImpactForWov(this.workOrder.WorkOrderVersion.Id).then(data => {
        this.showApprovedImpactModal(this.workOrder, data);
      })
        .catch(() => {
          // console.error(err);
        });
    } else {
      this.workorderService.getCommissionChangeImpactForWov(this.workOrder.WorkOrderVersion.Id).then(data => {
        this.showApprovedImpactModal(this.workOrder, data);
      })
        .catch(() => {
          // console.error(err);
        });
    }
  }

  private initItemButtons(workorder: IWorkOrder, commandBody: any, messageOnSuccessResponse?: string) {
    this.commissionChangeImpactBtns = [];
    if (!workorder.WorkOrderVersion.IsCommissionChangeImpactApproved) {
      this.commissionChangeImpactBtns.push({
        icon: null,
        tooltip: CommissionChangeImpactModalButtons.ConfirmApproval,
        btnType: 'primary',
        action: () => {
          this.apiService.command('WorkOrderVersionCommissionImpactApprove', commandBody).then(() => {
            this.workorderService.refreshWorkOrder(
              this.workOrder.WorkOrderId,
              this.workOrder.AssignmentId,
              this.workOrder.WorkOrderVersion.Id,
              this.workOrder.TemplateId
            );
            this.commonService.logSuccess(messageOnSuccessResponse);
          })
            .catch(() => {
              // console.error(err);
            })
            .finally(() => {
              this.commissionChangeImpact.closeModal();
            });
        }
      });
    }
    this.commissionChangeImpactBtns.push({
      icon: null,
      tooltip: CommissionChangeImpactModalButtons.Cancel,
      btnType: 'default',
      action: () => {
        this.commissionChangeImpact.closeModal();
      }
    });
  }

  private showApprovedImpactModal(workOrder: IWorkOrder, impact: ICommissionChangeImpactSummary) {
    this.initItemButtons(workOrder, null, null);
    this.commissionChangeImpact.showModal(impact, this.workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved);
  }

  private refreshShowImpactApprovedBtn() {
    const applicableToShowApprovedBtn = this.workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved &&
      this.hasWorkOrderVersionCommissionFieldsEditFunctionalRole &&
      !this.statusesNotToShowImpactApprovedBtn.includes(this.workOrder.WorkOrderVersion.StatusId);

    if (this.workOrder.WorkOrderVersion.StatusId === this.phxConstants.WorkOrderVersionStatus.Approved
      || this.workOrder.WorkOrderVersion.StatusId === this.phxConstants.WorkOrderVersionStatus.Replaced) {
      if (!this.workOrder.WorkOrderVersion.HasApprovedCommissionImpact) {
        // In Approved and Replaced wov, if there was no corrections made, do not show the button
        this.html.showImpactApprovedBtn = false;
      } else {
        // If there were corrections made, follow applicableToshowApprovedBtn logic to show the button
        this.html.showImpactApprovedBtn = applicableToShowApprovedBtn;
      }
    } else {
      // If the wov status is not Approved or Replaced, follow applicableToshowApprovedBtn logic to show the button
      this.html.showImpactApprovedBtn = applicableToShowApprovedBtn;
    }
  }
}
