import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import moment from 'moment';

import { CodeValueService, CommonService, NavigationService, PhxConstants } from '@common';
import { FboMonolithAdminDataService } from '@common/data-services/fbo-monolith-admin-data/fbo-monolith-admin-data.service';
import { FboMonolithAssignmentDataService } from '@common/data-services/fbo-monolith-assignment-data/fbo-monolith-assignment-data.service';
import { NonATSLineOfBusinessRestrictionDto } from '@common/data-services/fbo-monolith-assignment-data/models/non-ats-line-of-business-restriction.dto';
import { OrganizationDataService } from '@common/data-services/organization-data/organization-data.service';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { ICommonListsItem } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { CodeValue, CodeValueGroups } from '@common/model';
import { ToolTipField } from '@common/model/phx-select-box-w-tooltip';
import { FormGroup } from '@common/ngx-strongly-typed-forms/model';

import { IWorkorderSetup } from '../../models';
import { WorkorderService } from '../../services';
import { AssignmentCreateSetupFormService } from '../../services/forms';

@Component({
  selector: 'app-assignment-create-setup',
  templateUrl: './assignment-create-setup.component.html',
  styleUrls: ['./assignment-create-setup.component.less']
})
export class AssignmentCreateSetupComponent extends BaseComponentOnDestroy implements OnInit {
  workOrderSetup: IWorkorderSetup;
  formGroup: FormGroup<IWorkorderSetup>;
  listLineOfBusiness: Array<CodeValue>;
  listAtsSource: Array<CodeValue>;
  codeValueGroups = CodeValueGroups;
  disableATS: boolean;
  getDataInProgress = false;
  duplicateWorkOrders: Array<any> = [];
  displayWarningMessage = false;
  checkingDuplicateWorkOrders = false;
  ValidationMessages: any;
  listOrganizationClient: Array<ICommonListsItem>;
  listWorkOrderTemplates: any;
  listUserProfileWorker: Array<any> = [];
  listFilteredWorkOrderTemplates: Array<any> = [];
  listProfileType: Array<CodeValue>;
  selectedProfileWorker: any;
  selectedProfileWorkerUrlLink: string;
  primaryButtonLabel: string;
  private nonAtsLineOfBusinessRestrictions: NonATSLineOfBusinessRestrictionDto[] = [];
  allowLineOfBusinessEdit = false;
  isTenantATSIntegrationEnabled: boolean;

  tooltipFields: Array<ToolTipField> = [
    { label: 'Email', value: 'PrimaryEmail' },
    { label: 'Organization', value: 'OrganizationName' },
    { label: 'Update Date', value: 'LastModifiedDatetime' }
  ];

  constructor(
    private router: Router,
    private codeValueService: CodeValueService,
    private workorderService: WorkorderService,
    private fboMonolithAdminDataService: FboMonolithAdminDataService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private commonService: CommonService,
    public listObservableService: CommonListsObservableService,
    public assignmentCreateSetupFormService: AssignmentCreateSetupFormService,
    public organizationDataService: OrganizationDataService,
    private fboMonolithAssignmentDataService: FboMonolithAssignmentDataService
  ) {
    super();
    this.getApplicationConfigurationByTypeId();
  }

  ngOnInit() {
    this.getCodeValueListsStatic();
    this.getNonATSLineOfBusinessRestrictions();

    this.navigationService.setTitle('workorder-new');
    this.activatedRoute.params.pipe(takeUntil(this.isDestroyed$)).subscribe(params => {
      this.workOrderSetup = {
        // Import from ATS radio button needs to be the default if there are no parameters
        ImportFromAts: params.importfromats == null ? true : +params.importfromats === 1,
        AtsPlacementId: +params.atsplacementId > 0 ? Number(params.atsplacementId) : null,
        AtsSourceId: params.atssourceId > 0 ? Number(params.atssourceId) : 1,
        TemplateId: null,
        LineOfBusinessId: PhxConstants.LineOfBusiness.SubVendorPayroll
      };
    });
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(params => params.errorCode)
      )
      .subscribe(params => {
        const responseError = { ValidationMessages: [] };
        responseError.ValidationMessages.push({ PropertyName: '', Message: this.getErrorDescriptionFromCode(params.errorCode) });
        this.ValidationMessages = responseError;
      });

    this.formGroup = this.assignmentCreateSetupFormService.createForm(this.workOrderSetup, this.isDestroyed$);
    this.setupFormGroupListners();

    // TODO: Investigate why we call this twice. Race condition?
    this.getListOrganizationClient();
    this.getTemplatesByEntityTypeId();
    this.getListUserProfileWorker();

    this.refreshPrimaryButtonLabel();
    this.getIsTenantATSIntegrationEnabled();
  }

  // Attach Change listener
  setupFormGroupListners() {
    this.observeImportFromAtsFormControl();
    this.observeAtsSourceIdFormControl();
    this.observeAtsPlacementIdFormControl();
    this.observeOrganizationIdClientFormControl();
    this.observeLineOfBusinessIdFormControl();
    this.observeUserProfileIdWorkerFormControl();
  }

  // Change listners
  observeImportFromAtsFormControl() {
    this.assignmentCreateSetupFormService.importFromAtsFormControlChange$.pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$)).subscribe(() => {
      this.displayWarningMessage = false;
      this.duplicateWorkOrders = [];
      this.ValidationMessages = null;
      this.refreshPrimaryButtonLabel();
    });
  }

  observeAtsSourceIdFormControl() {
    this.assignmentCreateSetupFormService.atsSourceIdFormControlChange$.pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$)).subscribe(() => {
      this.displayWarningMessage = false;
      this.duplicateWorkOrders = [];
      this.getDataInProgress = false;
      this.ValidationMessages = null;
    });
  }

  observeAtsPlacementIdFormControl() {
    this.assignmentCreateSetupFormService.atsPlacementIdFormControlChange$.pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$)).subscribe(() => {
      this.displayWarningMessage = false;
      this.duplicateWorkOrders = [];
      this.getDataInProgress = false;
      this.ValidationMessages = null;
    });
  }

  observeOrganizationIdClientFormControl() {
    this.assignmentCreateSetupFormService.organizationIdClientFormControlChange$.pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$)).subscribe(id => {
      this.updateFilteredTemplates();

      const nonAtsLineOfBusinessRestriction =
        this.nonAtsLineOfBusinessRestrictions.find(x => x.ClientOrganizationId === id) ?? this.nonAtsLineOfBusinessRestrictions.find(x => x.ClientOrganizationId === null);

      this.allowLineOfBusinessEdit = !nonAtsLineOfBusinessRestriction?.IsRestricted;
      this.assignmentCreateSetupFormService.updateLineOfBusinessIdFormControl(PhxConstants.LineOfBusiness.SubVendorPayroll);

      const lobCodeValues = this.getLOBCodeValues();
      this.listLineOfBusiness = lobCodeValues;

      if (this.allowLineOfBusinessEdit && id) {
        this.organizationDataService
          .getClientRoleSelectedLOBCodes(id)
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe((lobs: string[]) => {
            if (lobs?.length) {
              const lobsSet = new Set(lobs);
              this.listLineOfBusiness = lobCodeValues.filter(lob => lobsSet.has(lob.code));
            } else {
              this.listLineOfBusiness = [];
            }
          });
      } else {
        this.listLineOfBusiness = [];
      }
    });
  }

  observeLineOfBusinessIdFormControl() {
    this.assignmentCreateSetupFormService.lineOfBusinessIdFormControlChange$
      .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.isDestroyed$))
      .subscribe(() => this.updateFilteredTemplates());
  }

  observeUserProfileIdWorkerFormControl() {
    this.assignmentCreateSetupFormService.userProfileIdWorkerFormControlChange$.pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$)).subscribe(x => {
      this.selectedProfileWorker = this.listUserProfileWorker.find(i => i.Id === x);
      if (this.selectedProfileWorker) {
        this.selectedProfileWorkerUrlLink = `/next/contact/${this.selectedProfileWorker.Contact.Id}/profile/`;
        this.selectedProfileWorkerUrlLink += `${this.commonService.getUserProfileTypeSufix(this.selectedProfileWorker.ProfileTypeId)}/${this.selectedProfileWorker.Id}`;
      }
    });
  }

  refreshPrimaryButtonLabel() {
    if (this.assignmentCreateSetupFormService.importFromAtsFormControl.value) {
      if (this.duplicateWorkOrders.length > 0) {
        this.primaryButtonLabel = 'Create New';
      } else {
        this.primaryButtonLabel = 'Next';
      }
    } else {
      this.primaryButtonLabel = 'Create New Work Order';
    }
  }

  getErrorDescriptionFromCode(errorCodeOrDescription: string) {
    const contactSupportMessage =
      'Please wait several minutes and try again. If the issue continues, ' + 'please contact Application Support for assistance (see the Resource Center > Support section)';

    switch (errorCodeOrDescription) {
      case 'AtsPlacementNotFound':
        return `The ATS Placement ID cannot be found in Bullhorn - please check the number again. If the Placement is new in Bullhorn, 
        it might take a few minutes for the Placement ID to be available. ${contactSupportMessage}`;
      case 'AtsPlacementNotApproved':
        return 'Placement needs to be in an approved state';
      case 'BullhornConnectivityException':
        return `Bullhorn connection failed. ${contactSupportMessage}`;
      default:
        return errorCodeOrDescription;
    }
  }

  getApplicationConfigurationByTypeId() {
    this.workorderService
      .getApplicationConfigurationByTypeId(PhxConstants.ApplicationConfigurationType.DisableATS, null)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response: any) => {
        this.disableATS = response.ConfigurationValue.toLowerCase() === 'true';
      });
  }

  workOrderCreate() {
    this.getDataInProgress = true;

    if (this.assignmentCreateSetupFormService.importFromAtsFormControl.value) {
      if (
        (this.duplicateWorkOrders.length > 0 && this.displayWarningMessage === true) ||
        (this.formGroup.valid && this.workOrderSetup.AtsSourceId && !this.assignmentCreateSetupFormService.atsPlacementIdFormControl.value)
      ) {
        this.goToNextStep();
      } else {
        this.duplicateWorkOrders = [];
        this.displayWarningMessage = false;
        if (this.formGroup.valid && this.workOrderSetup.AtsSourceId && this.assignmentCreateSetupFormService.atsPlacementIdFormControl.value) {
          this.checkingDuplicateWorkOrders = true;
          this.workorderService
            .getDuplicateAtsWorkOrder(this.workOrderSetup.AtsSourceId, this.assignmentCreateSetupFormService.atsPlacementIdFormControl.value)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe((data: any) => {
              this.duplicateWorkOrders = data.Items?.length ? data.Items : [];
              if (data.Items.length > 0) {
                this.checkingDuplicateWorkOrders = false;
                this.displayWarningMessage = true;
                this.getDataInProgress = false;
                this.refreshPrimaryButtonLabel();
              } else {
                this.goToNextStep();
              }
            });
        }
      }
    } else {
      // No ATS data - create work order based on user selection of data

      const command = {
        UserProfileIdWorker: this.assignmentCreateSetupFormService.userProfileIdWorkerFormControl.value,
        OrganizationIdClient: this.assignmentCreateSetupFormService.organizationIdClientFormControl.value,
        LineOfBusiness: this.assignmentCreateSetupFormService.lineOfBusinessIdFormControl.value,
        UIVersion: 1,
        AtsSourceId: null,
        AtsPlacementId: null,
        TemplateId: null
      };

      if (this.assignmentCreateSetupFormService.templateIdFormControl.value) {
        command.TemplateId = this.assignmentCreateSetupFormService.templateIdFormControl.value;
      }

      this.commonService.logSuccess('Work Order Creation in Progress');

      this.workorderService
        .workOrderNew(command)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next:
            (workOrderNewResponseSucces: any) => {
              if (workOrderNewResponseSucces.EntityId) {
                this.router.navigate(['/next', 'workorder', 0, 0, workOrderNewResponseSucces.EntityId, 'core']);
              }
            },
          error: responseError => {
            this.commonService.logError('Work Order Creation Failed');
            this.ValidationMessages = this.commonService.responseErrorMessages(responseError, null);
            this.commonService.logValidationMessages(this.ValidationMessages);
            this.getDataInProgress = false;
          }
        });
    }
  }

  openWorkOrder(workorder) {
    const navigateTo = (assignmentId: number, workorderId: number, workorderVersionId: number, tabNavigationName: PhxConstants.WorkorderNavigationName) => {
      const navigatePath = `/next/workorder/${assignmentId}/${workorderId}/${workorderVersionId}/${tabNavigationName}`;

      this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent });
    };
    navigateTo(workorder.AssignmentId, workorder.WorkOrderId, workorder.WorkOrderVersionId, 'core');
  }

  private getCodeValueListsStatic() {
    this.listProfileType = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true);
    this.listLineOfBusiness = this.getLOBCodeValues();
    this.listAtsSource = this.codeValueService.getCodeValues(this.codeValueGroups.AtsSource, true);
  }

  private getLOBCodeValues(): CodeValue[] {
    return this.codeValueService
      .getCodeValues(this.codeValueGroups.LineOfBusiness, true)
      .filter(item => item.id !== PhxConstants.LineOfBusiness.PermPlacement && item.id !== PhxConstants.LineOfBusiness.Expense);
  }

  private getListOrganizationClient() {
    this.listObservableService
      .listDetailedOrganizationClients$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        if (response) {
          this.listOrganizationClient = response.map(i => ({
            ...i,
            DisplayText: i.Id + '-' + i.DisplayText
          }));
        }
      });
  }

  private getNonATSLineOfBusinessRestrictions() {
    this.fboMonolithAssignmentDataService
      .getNonATSLineOfBusinessRestrictions()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        this.nonAtsLineOfBusinessRestrictions = response;
      });
  }

  private getTemplatesByEntityTypeId() {
    this.workorderService
      .getTemplatesByEntityTypeId(PhxConstants.EntityType.Assignment)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(result => {
        this.listWorkOrderTemplates = result.Items;
        this.updateFilteredTemplates();
      });
  }

  private updateFilteredTemplates() {
    const listFilteredWorkOrderTemplates = [];
    if (this.formGroup) {
      // getting the value from the control is more reliable here
      const organizationIdClient = this.assignmentCreateSetupFormService.organizationIdClientFormControl.value;

      if (this.listWorkOrderTemplates && organizationIdClient) {
        this.listWorkOrderTemplates.forEach(entity => {
          entity.Entity.WorkOrders.forEach(versions => {
            if (versions.hasOwnProperty('WorkOrderVersions')) {
              versions.WorkOrderVersions.forEach(v => {
                if (v.LineOfBusinessId === this.assignmentCreateSetupFormService.lineOfBusinessIdFormControl.value) {
                  v.BillingInfoes.forEach(bi => {
                    if (bi.OrganizationIdClient === organizationIdClient) {
                      entity.DisplayValue = entity.Name + ' (' + entity.Description + ')';
                      if (!listFilteredWorkOrderTemplates.includes(entity)) {
                        listFilteredWorkOrderTemplates.push(entity);
                      }
                    }
                  });
                }
              });
            }
          });
        });
      }

      this.listFilteredWorkOrderTemplates = listFilteredWorkOrderTemplates;
      if (!listFilteredWorkOrderTemplates.some(x => x.Id === this.assignmentCreateSetupFormService.templateIdFormControl.value)) {
        this.assignmentCreateSetupFormService.updateTemplateIdFormControl(null);
      }
    }
  }

  private getListUserProfileWorker() {
    this.listObservableService
      .listUserProfileWorkers$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        if (response) {
          this.listUserProfileWorker = this.getFilteredListUserProfileWorker(response);
          this.listUserProfileWorker.forEach((item: any) => {
            const profileName = this.listProfileType.find(i => i.id === item.ProfileTypeId);
            const displayItem = item.Id + '-' + item.Contact.FullName + '-' + profileName.description;
            item.OrganizationName = item.Organization ? item.Organization.DisplayName : null;
            item.LastModifiedDatetime = moment(item.LastModifiedDatetime).format('MMM DD YYYY');
            item.DisplayValue = displayItem;
          });
        }
      });
  }

  private getFilteredListUserProfileWorker(items: any) {
    // 15634 - workers belonging to certain profileTypes should not appear if they are missing an org
    return items.filter(item =>
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianInc ||
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerSubVendor ||
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesLLC
        ? item.OrganizationId != null
        : true
    );
  }

  private goToNextStep() {
    this.router.navigate([
      '/next',
      'workorder',
      'create',
      'importfromats',
      this.assignmentCreateSetupFormService.importFromAtsFormControl.value ? 1 : 0,
      'atssource',
      this.assignmentCreateSetupFormService.atsSourceIdFormControl.value,
      'atsplacement',
      this.assignmentCreateSetupFormService.atsPlacementIdFormControl.value ? this.assignmentCreateSetupFormService.atsPlacementIdFormControl.value : 0
    ]);
  }

  private getIsTenantATSIntegrationEnabled() {
    this.fboMonolithAdminDataService
      .getBusinessRuleConfiguration(PhxConstants.BusinessConfigurationKey.IsATSIntegrationEnabled)
      .pipe(this.takeUntilDestroyed)
      .subscribe(response => {
        this.isTenantATSIntegrationEnabled = response === '1';
        if (this.formGroup.controls.ImportFromAts.value && !this.isTenantATSIntegrationEnabled) {
          this.formGroup.controls.ImportFromAts.setValue(this.isTenantATSIntegrationEnabled);
        }
      });
  }
}
