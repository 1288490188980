<div class="content-group form-horizontal">
    <div class="col-lg-12">
        <div class="panel-internal">
            <div class="form-group">
                <div class="col-lg-12">
                    <h4 style="display: inline-block;">Documents</h4>

                    <div style="float: right; margin: 5px 2px 0 0;" class="btn-group">
                        <button data-toggle="dropdown" class="btn btn-default dropdown-toggle btn-narrow" type="button">Add</button>
                        <ul class="dropdown-menu bordered">
                            <li style="cursor: pointer;" *ngFor="let docType of html.documentTypeList" data-toggle="collapse"
                                (click)="uploadDocument(docType.id)"><a>{{docType.text}}</a></li>
                        </ul>
                    </div>
                    <div class="row" *ngIf="workorderId">
                        <phx-entity-documents-list #documentList 
                            [entityId]="workorderId" 
                            [entityTypeId]="html.phxConstants.EntityType.WorkOrder">

                        </phx-entity-documents-list>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-phx-document-file-upload #fileUpload [configuration]="html.documentFileUploadConfiguration"
                                  (successItem)="onSuccessItem($event)"
                                  [editable]="true" [docTypeList]="html.documentTypeSubList" [showAddButton]="false" [fileUploaderOptions]="html.fileUploaderOptionsDocumentMain"
                                  (completeAll)="documentUploadCallbackOnDone()"></app-phx-document-file-upload>

</div>
