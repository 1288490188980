import { filter } from 'lodash';

import { IPaymentRate, IWorkOrder, IWorkOrderVersion } from './models';
import { PhxConstants } from '../common';
import { WorkOrdernWorkflowComponent } from './components';
import { AuthService } from '../common/services/auth.service';

const TFConstants = {
  view: 0,
  edit: 1
} as const;

export type TFConstant = (typeof TFConstants)[keyof typeof TFConstants];
export type ModeValidator = IWorkOrderVersion | IPaymentRate;

export class ControlFieldAccessibility {
  static workorder: IWorkOrder;
  static routerState: any;
  static isTenantATSIntegrationEnabled: boolean;

  public static currentProfileUnderComplianceRole(): boolean {
    return (
      filter(WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles, item => {
        return (
          item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOffice ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.Finance ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.Controller ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOfficeARAP
        );
      }).length > 0
    );
  }

  public static ptFieldViewEventOnChangeStatusId(modelPrefix: string, fieldName: string, authService: AuthService, modelValidation: ModeValidator | null = null): TFConstant {
    // core tab
    if (fieldName === 'WorkOrderStartDateState' || fieldName === 'WorkOrderEndDateState') {
      if (ControlFieldAccessibility.canEditWorkOrderStartAndEndDate(ControlFieldAccessibility.workorder)) {
        return TFConstants.edit;
      } else {
        return TFConstants.view;
      }
    }

    if (ControlFieldAccessibility.isTenantATSIntegrationEnabled && ControlFieldAccessibility.workorder.AtsPlacementId > 0) {
      if (fieldName === 'Rate' || fieldName === 'RateUnitId') {
        if (ControlFieldAccessibility.editableRatesAndLineOfBusiness(ControlFieldAccessibility.workorder)) {
          return TFConstants.edit;
        }
      }

      // core tab
      if (fieldName === 'LineOfBusinessId' && ControlFieldAccessibility.editableRatesAndLineOfBusiness(ControlFieldAccessibility.workorder)) {
        return TFConstants.edit;
      }
      // // core tab
      if (fieldName === 'OrganizationIdInternal' && ControlFieldAccessibility.editableInternalCompany(ControlFieldAccessibility.workorder)) {
        return TFConstants.edit;
      }

      // core tab
      if (modelPrefix === 'WorkOrder' && (fieldName === 'StartDate' || fieldName === 'EndDate')) {
        if (
          (fieldName === 'StartDate' && ControlFieldAccessibility.workorder.isSourceWOTempLayoff) ||
          (ControlFieldAccessibility.workorder.WorkOrderVersion.IsComplianceDraftStatus && ControlFieldAccessibility.currentProfileUnderComplianceRole())
        ) {
          return TFConstants.edit;
        } else {
          return TFConstants.view;
        }
      }
    }

    // core tab
    if (modelPrefix === 'WorkOrder' && fieldName === 'OrganizationIdInternal') {
      if (ControlFieldAccessibility.workorder.AssignmentStatus) {
        return TFConstants.edit;
      } else {
        return TFConstants.view;
      }
    }

    // // core tab
    if (modelPrefix === 'WorkOrder' && (fieldName === 'StartDate' || fieldName === 'EndDate')) {
      if (
        ControlFieldAccessibility.workorder.StatusId === PhxConstants.WorkOrderStatus.Processing &&
        ControlFieldAccessibility.workorder.WorkOrderVersion.WorkOrderCreationReasonId !== PhxConstants.WorkOrderCreationReason.ScheduleChange
      ) {
        return TFConstants.edit;
      } else {
        return TFConstants.view;
      }
    }

    if (modelPrefix === 'WorkOrderVersion' && fieldName === 'LineOfBusinessId' && (modelValidation as IWorkOrderVersion)?.LineOfBusinessId > 0) {
      return TFConstants.view;
    }

    if (
      (modelPrefix === 'WorkOrderVersion' ||
        modelPrefix === 'WorkOrderVersion.TimeSheetApprovers' || // timeSheetApprover
        modelPrefix === 'WorkOrderVersion.ExpenseApprovers' || // expenseApprover
        modelPrefix === 'WorkOrderVersion.BillingInfoes' || // billingInfo
        modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingInvoice' || // billingInvoice
        modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingRate' || // billingRate
        modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients' || // billingRecipient
        modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingSalesTaxes' || // billingSalesTax
        modelPrefix === 'WorkOrderVersion.PaymentInfoes' || // paymentInfo
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentInvoices' || // paymentInvoice
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentContacts' || // paymentContact
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions' || // paymentSourceDeduction
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings' || // paymentOtherEarning
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSalesTaxes' || // paymentSalesTax
        modelPrefix === 'WorkOrderVersion.JobOwner' ||
        modelPrefix === 'WorkOrderVersion.SupportingJobOwners' ||
        modelPrefix === 'WorkOrderVersion.Recruiters' ||
        modelPrefix === 'WorkOrderVersion.WorkOrderVersionCommissions') &&
      (ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved ||
        ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Replaced ||
        ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingReview ||
        ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Cancelled ||
        ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingUnterminate ||
        ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Deleted ||
        (ControlFieldAccessibility.workorder.WorkOrderVersion.IsComplianceDraftStatus && !ControlFieldAccessibility.workorder.WorkOrderVersion.IsDraftStatus))
    ) {
      return TFConstants.view;
    }

    if (modelPrefix === 'WorkOrderVersion' && fieldName === 'EffectiveDate') {
      if (
        ControlFieldAccessibility.workorder.WorkOrderVersion.IsDraftStatus &&
        ControlFieldAccessibility.workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange
      ) {
        return TFConstants.edit;
      } else {
        return TFConstants.view;
      }
    }

    if (
      ((modelPrefix === 'WorkOrderVersion.PaymentInfoes' && (fieldName === 'IsUseUserProfileWorkerSourceDeduction' || fieldName === 'SubdivisionIdSourceDeduction')) ||
        modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions') &&
      !authService.hasFunctionalOperation(PhxConstants.FunctionalOperation.WorkOrderVersionChangePaymentSourceDeduction)
    ) {
      return TFConstants.view;
    }
    if (
      !ControlFieldAccessibility.editableComplianceDraftFields(this.workorder) &&
      ((modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingInvoice' &&
        (fieldName === 'BillingInvoicePresentationStyleId' || fieldName === 'BillingInvoiceTermsId' || fieldName === 'BillingInvoiceTemplateId' || fieldName === 'BillingConsolidationTypeId')) ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentInvoices' && fieldName === 'PaymentMethodId') ||
        // for earnings and deduction controls
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings' && fieldName === 'IsApplied') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings' && fieldName === 'RatePercentage') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings' && fieldName === 'IsAccrued') ||
        (modelPrefix === 'WorkOrderVersion' && fieldName === 'WorkerCompensationId') ||
        (modelPrefix === 'WorkOrderVersion' && fieldName === 'WCBIsApplied') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes' && fieldName === 'SubdivisionIdSourceDeduction') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes' && fieldName === 'IsUseUserProfileWorkerSourceDeduction') ||
        (modelPrefix === 'WorkOrderVersion' && fieldName === 'ApplyFlatStatPay') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions' && fieldName === 'IsApplied') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions' && fieldName === 'RatePercentage') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSourceDeductions' && fieldName === 'RateAmount') ||
        // for taxed control fields
        (modelPrefix === 'WorkOrderVersion.BillingInfoes' && fieldName === 'SubdivisionIdSalesTax') ||
        (modelPrefix === 'WorkOrderVersion.BillingInfoes' && fieldName === 'JurisdictionId') ||
        (modelPrefix === 'WorkOrderVersion.BillingInfoes.BillingSalesTaxes' && fieldName === 'IsApplied') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes' && fieldName === 'ApplySalesTax') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes' && fieldName === 'SubdivisionIdSalesTax') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes' && fieldName === 'JurisdictionId') ||
        (modelPrefix === 'WorkOrderVersion.PaymentInfoes.PaymentSalesTaxes' && fieldName === 'IsApplied') ||
        // for core tab details fields
        (modelPrefix === 'WorkOrderVersion' && fieldName === 'InternalOrganizationDefinition1Id'))
    ) {
      return TFConstants.view;
    }

    // parties and rates tab
    if (modelPrefix === 'WorkOrder' && fieldName === 'IsFlowdownFee') {
      return ControlFieldAccessibility.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerSubVendor ? TFConstants.edit : TFConstants.view;
    }

    return TFConstants.edit;
  }

  private static correction(workorder: IWorkOrder): boolean {
    return (
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionEarliest ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionLatest ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionMiddle ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionUnique
    );
  }

  private static currentProfileUnderCertainRoles(): boolean {
    return (
      filter(
        WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles,
        item =>
          item.FunctionalRoleId === PhxConstants.FunctionalRole.AccountManager ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.ClientServices ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.ClientServicesLead ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.BranchManager ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.Executive
      ).length > 0
    );
  }

  private static currentProfileUnderAccountingRole(): boolean {
    return (
      filter(
        WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles,
        item =>
          item.FunctionalRoleId === PhxConstants.FunctionalRole.Finance ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.Controller ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOffice ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.AccountsReceivable ||
          item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOfficeARAP
      ).length > 0
    );
  }

  private static currentProfileIsSystemAdministrator(): boolean {
    return filter(WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles, item => item.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator).length > 0;
  }

  private static editableInternalCompany(workorder: IWorkOrder): boolean {
    let editable = false;
    if (ControlFieldAccessibility.currentProfileUnderAccountingRole() || ControlFieldAccessibility.currentProfileIsSystemAdministrator()) {
      if (workorder.WorkOrderVersion.IsDraftStatus) {
        editable = true;
      }
    }
    if (workorder.WorkOrderVersion.IsDraftStatus && ControlFieldAccessibility.correction(workorder)) {
      editable = true;
    }

    if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange) {
      editable = false;
    }

    if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.Extend) {
      editable = false;
    }

    return editable;
  }

  private static editableRatesAndLineOfBusiness(workorder: IWorkOrder): boolean {
    let editable = false;
    if (ControlFieldAccessibility.currentProfileUnderAccountingRole() || ControlFieldAccessibility.currentProfileIsSystemAdministrator()) {
      if (workorder.WorkOrderVersion.IsDraftStatus) {
        editable = true;
      }
      if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.Extend) {
        editable = true;
      }
      if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange) {
        editable = true;
      }
      if (workorder.WorkOrderVersion.IsDraftStatus && ControlFieldAccessibility.correction(workorder)) {
        editable = true;
      }
    }
    return editable;
  }

  private static editableComplianceDraftFields(workorder: IWorkOrder): boolean {
    return workorder.WorkOrderVersion.IsComplianceDraftStatus;
  }

  private static canEditWorkOrderStartAndEndDate(workorder: IWorkOrder): boolean {
    let editable = false;

    if (
      ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Draft ||
      ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.ComplianceDraft ||
      ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.RecalledCompliance ||
      ControlFieldAccessibility.workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Recalled
    ) {
      if (ControlFieldAccessibility.workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange) {
        editable = false;
      } else if (ControlFieldAccessibility.currentProfileUnderAccountingRole() || ControlFieldAccessibility.currentProfileIsSystemAdministrator()) {
        editable = true;
      } else if (ControlFieldAccessibility.currentProfileUnderCertainRoles()) {
        editable = !(ControlFieldAccessibility.isTenantATSIntegrationEnabled && workorder.AtsPlacementId > 0);
      }
    }
    return editable;
  }
}
