import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import moment from 'moment';

import { ValidationExtensions, PhxConstants, CodeValueService, ApiService } from '@common';
import { PhxDocumentFileUploadComponent } from '@common/components/phx-document-file-upload/phx-document-file-upload.component';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import {
  PhxButton,
  PhxFormControlLayoutType,
  CodeValueGroups,
  PhxDocumentFileUploaderOptions,
  PhxDocumentFileUploadConfiguration,
  PhxDocumentFileUploadFileItemActionEventArg,
  PhxDocumentFileUploadResult,
  PhxDocument
} from '@common/model';
import { FormGroup, FormBuilder, ValidatorFn } from '@common/ngx-strongly-typed-forms/model';
import { DocumentService } from '@common/services/document.service';

import { IOffboardingTask } from '../../models';
import { WorkorderOffboardingService } from '../../services';

export interface IWorkOrderOffboardingCompleteForm {
  OffboardingTaskTypeId: number;
  Documents: Array<PhxDocument>;
  Notes: string;
}

@Component({
  selector: 'app-workorder-offboarding-compliancetask-modal',
  templateUrl: './workorder-offboarding-compliancetask-modal.component.html',
  styleUrls: ['./workorder-offboarding-compliancetask-modal.component.less']
})
export class WorkorderOffboardingCompliancetaskModalComponent implements OnInit {
  @Input() buttons: PhxButton[];
  @Input() contactFullName: string;

  @Output() actionClick = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  @ViewChild(PhxModalComponent) modal: PhxModalComponent;
  @ViewChild(PhxDocumentFileUploadComponent) documentFileUploadComponent: PhxDocumentFileUploadComponent;

  form: FormGroup<IWorkOrderOffboardingCompleteForm>;
  layoutType = PhxFormControlLayoutType.Stacked;
  validationMessages: any;
  // action: StateAction;
  entity: IOffboardingTask;
  savedDocuments: Array<PhxDocument>;
  title = 'Complete Task';
  detail: string;
  instructions: string;
  editable = false;
  uploadedDocuments: Array<PhxDocument>;
  documentFileUploadConfiguration: PhxDocumentFileUploadConfiguration;
  fileUploaderOptionsDocumentMain: PhxDocumentFileUploaderOptions;
  isCanadianWorksite = false;
  alertMessage = 'A note or document is required to complete the task';

  phxConstants: typeof PhxConstants;
  codeValueGroups: typeof CodeValueGroups;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private codeValueService: CodeValueService,
    private documentService: DocumentService,
    private offboardingService: WorkorderOffboardingService,
    private cdr: ChangeDetectorRef
  ) {
    this.phxConstants = PhxConstants;
    this.codeValueGroups = CodeValueGroups;
    this.fileUploaderOptionsDocumentMain = {
      queueLimit: 10,
      maxFileSize: 20 * 1024 * 1024, // 20971520 == 20 MB
      allowedMimeType: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      allowedFileType: ['image', 'pdf', 'doc']
    };
    this.uploadedDocuments = [];
  }

  ngOnInit() {
    this.buildForm();
  }

  setNotesValidator() {
    const notesControl = this.form.get('Notes');
    const documentsControl = this.form.get('Documents');

    const validators = [ValidationExtensions.minLength(10, 'Minimum of 10 characters required')];
    if (this.requiresDocumentsOrComment() && (!documentsControl.value || documentsControl.value.length === 0)) {
      validators.push(ValidationExtensions.required(' '));
    }

    notesControl.setValidators(validators);
  }

  open(entity: IOffboardingTask, isEditable: boolean = true, isCanadianWorksite: boolean = true) {
    this.entity = entity;

    this.editable = isEditable;
    this.isCanadianWorksite = isCanadianWorksite;
    this.title = this.getTitle(entity.StatusId);
    this.detail = '';
    if (entity.completed) {
      this.detail = 'Completed by ' + this.entity.LastStateHistory.CreatedByFullName + ' at ' + moment(this.entity.LastStateHistory.CreatedDatetime).format('MMM DD YYYY HH:mm');
    }
    this.instructions = entity.Config.Instructions;
    if (entity?.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice && isCanadianWorksite) {
      this.instructions = 'Please upload the letter sent to the worker (or received for resignation). Include details of when it was sent (or received)';
      this.alertMessage = 'A note and document are required to complete the task';
    }

    this.form.markAsPristine();
    this.fillForm(entity);
    if (!this.editable) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.uploadedDocuments = [];
    this.documentService.getEntityDocuments(PhxConstants.EntityType.WorkOrderOffboardingTask, this.entity.Id).then(
      res => {
        this.savedDocuments = res.Items;
        this.entity.Documents = res.Items;
        this.form.patchValue({
          Documents: [...this.savedDocuments]
        });
        this.cdr.detectChanges();
        this.modal.show();
      },
      () => { }
    );
  }

  closed() {
    const payload = this.getPayload();
    if (
      payload?.Documents &&
      this.entity.StatusId !== PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting &&
      this.entity.StatusId !== PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices
    ) {
      const publicIds = payload.Documents.map(doc => doc.PublicId);
      this.deleteDocuments(publicIds);
      this.closeModal.emit();
    }
  }

  deleteDocuments(unUsedPublicIds: string[]) {
    if (unUsedPublicIds.length > 0) {
      const payload = { EntityIds: [this.entity.Id], PublicIds: unUsedPublicIds };
      this.apiService.command(PhxConstants.StateAction[PhxConstants.StateAction.OffboardDeleteDocument], payload).then(
        () => {
          this.offboardingService.refreshDocuments();
        },
        () => { }
      );
    }
  }

  hasChanges() {
    if (this.form.get('Notes').value !== this.entity.Notes) {
      return true;
    }

    if (this.entity.Documents != null) {
      const oldDocumentIds = this.entity.Documents.map(d => d.PublicId);
      const currDocumetIds = this.form.get('Documents').value.map(d => d.PublicId);
      if (currDocumetIds.length !== oldDocumentIds.length) {
        return true;
      }
      if (!oldDocumentIds.every(old => currDocumetIds.includes(old))) {
        return true;
      }
    }

    return false;
  }

  private requiresDocumentsOrComment(): boolean {
    return this.entity && !this.entity.completed && !this.entity.exemption;
  }

  offboardingCompleteValidator(): ValidatorFn<IWorkOrderOffboardingCompleteForm> {
    return (formGroup: FormGroup<IWorkOrderOffboardingCompleteForm>): ValidationErrors | null => {
      if (!this.requiresDocumentsOrComment()) {
        return null;
      }

      const errors: ValidationErrors = {};
      const documents = formGroup.get('Documents').value;
      const comment = formGroup.get('Notes').value;
      if (documents?.length <= 0 && !comment) {
        errors.DocumentOrComment = {
          message: 'Notes or a Document is required.'
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  private getTitle(statusId: PhxConstants.WorkOrderOffboardingTaskStatus): string {
    switch (statusId) {
      case PhxConstants.WorkOrderOffboardingTaskStatus.PendingClientServices:
      case PhxConstants.WorkOrderOffboardingTaskStatus.PendingAccounting:
        return 'Complete Task';
      case PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices:
      case PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting:
      default:
        return 'View Task';
    }
  }

  private buildForm() {
    this.form = this.fb.group<IWorkOrderOffboardingCompleteForm>(
      {
        OffboardingTaskTypeId: [null, []],
        Documents: [],
        Notes: [null]
      },
      { validator: this.offboardingCompleteValidator() }
    );
  }

  private fillForm(entity: IOffboardingTask) {
    this.form.patchValue({
      Notes: entity.Notes,
      OffboardingTaskTypeId: entity.OffboardingTaskId
    });

    this.setNotesValidator();
  }

  /**
   * @description validates Compliance task model
   * @param updateUI Display UI Erros if exists
   */
  public isValidForm(updateUI: boolean = true) {
    if (updateUI) {
      this.form.updateValueAndValidity();
      const noteControl = this.form.get('Notes');
      noteControl.updateValueAndValidity();
      if (!noteControl.valid) {
        noteControl.markAsDirty();
      }
    }
    return this.form.valid;
  }

  public getPayload(): any {
    return {
      ...this.form.value,
      Id: this.entity.Id
    };
  }

  removeDocument(document: PhxDocument) {
    const docControl = this.form.get('Documents');
    const documents: PhxDocument[] = docControl.value;
    if (documents) {
      documents.splice(documents.indexOf(document), 1);
    }
    this.setNotesValidator();
    docControl.markAsDirty();
    this.form.updateValueAndValidity();
  }

  addDocument(response: PhxDocumentFileUploadResult) {
    const documents: PhxDocument[] = this.form.get('Documents').value;
    const newDoc: PhxDocument = {
      Name: response.documentName,
      PublicId: response.publicId,
      DocumentTypeId: response.documentTypeId
    };
    this.uploadedDocuments.push(newDoc);
    if (documents) {
      documents.push(newDoc);
      this.offboardingService.refreshDocuments();
    }
    this.setNotesValidator();
    // this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  uploadDocument(complianceTask: IOffboardingTask) {
    this.fileUploaderOptionsDocumentMain.queueLimit = 10;
    this.documentFileUploadConfiguration = {
      entityTypeId: PhxConstants.EntityType.WorkOrderOffboardingTask,
      entityId: complianceTask.Id,
      documentTypeId: PhxConstants.DocumentType.OffboardingDocument,
      UploadTitle: `Upload <strong>${this.codeValueService.getCodeValueText(this.entity.OffboardingTaskId, this.codeValueGroups.WorkOrderOffboardingTask)}</strong> for ` + this.contactFullName,
      SupportedFileExtensions: 'JPEG, JPG, PNG, PDF, DOC, DOCX'
    };
    this.documentFileUploadComponent.showModal(this.fileUploaderOptionsDocumentMain);
  }

  callBackOnFileUploadItem(event: PhxDocumentFileUploadFileItemActionEventArg) {
    if (event?.response) {
      // TODO: discuss with UX
      // this.commonService.logSuccess(`${event.item.file.name} uploaded successfully.`, event);
      this.addDocument(event.response);
    }
  }

  openDocument(doc: PhxDocument): void {
    return this.documentService.openDocument(doc.PublicId, true);
  }

  isNotesAsteriskEnabled() {
    return this.isCanadianWorksite && this.form.get('Notes') && !this.form.get('Notes').value;
  }

  isDocumentsAsteriskEnabled() {
    return this.isCanadianWorksite && this.form.get('Documents') && (!this.form.get('Documents').value || this.form.get('Documents').value.length <= 0);
  }
}
