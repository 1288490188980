import { Component, Input, OnChanges } from '@angular/core';

import moment from 'moment';

import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { StateAction, PhxConstants } from '@common/model';

import { IWorkOrder } from '../../models/workorder.interface';

@Component({
  selector: 'app-workorder-offboarding-banner',
  templateUrl: './workorder-offboarding-banner.component.html',
  styleUrls: ['./workorder-offboarding-banner.component.less']
})
export class WorkorderOffboardingBannerComponent extends BaseComponentOnDestroy implements OnChanges {

  @Input() workOrder: IWorkOrder;
  @Input() stateActions: StateAction[] = [];
  @Input() availableStateActions: number[] = [];
  @Input() editable: boolean;

  stateActionsFiltered: StateAction[] = [];
  availableStateActionsFiltered: number[] = [];

  endDate: string = null;
  daysForEndOfAssignment = 0;
  showBanner = false;
  showExtend = false;
  showOffboarding = false;
  isOffboardingCompleted = false;
  daysOffboardWasCompleted = 0;

  head = '';
  messageBody = '';
  directionsMessage = '';
  style = '';
  icon = 'info';

  ngOnChanges() {
      const today =  moment(new Date()).format(PhxConstants.DateFormat.API_Date);
      this.endDate = (this.workOrder.TerminationDate !== '' && this.workOrder.TerminationDate  < this.workOrder.EndDate) ? this.workOrder.TerminationDate : this.workOrder.EndDate;
      this.daysForEndOfAssignment = 0;

      if (this.endDate != null) {
        this.daysForEndOfAssignment = this.getDays(new Date(today), new Date(this.endDate));
      }

      this.isOffboardingCompleted = false;
      this.daysOffboardWasCompleted = 0;
      if (this.workOrder.OffboardingCompletionDate != null) {
          this.daysOffboardWasCompleted = this.getDays(new Date(this.workOrder.OffboardingCompletionDate), new Date(today));
          this.isOffboardingCompleted = true;
      }
      this.checkBanner();
  }

  checkBanner() {

    this.showOffboarding = false;
    this.showExtend = false;
    this.showBanner = false;
    this.directionsMessage = '';

    if (this.workOrder.IsExtended ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Processing ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Complete ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Cancelled ) {
      this.showBanner = false;
      return;
    }
    this.head = this.workOrder.TerminationDate !== null ? 'Termination:' : 'End of Assignment:';
    if (this.isOffboardingCompleted) {
      if (this.daysOffboardWasCompleted <= 14) {
        this.style = 'alert-success';
        this.messageBody = 'Offboarding is complete!';
        this.showBanner = true;
      }
    } else {
        if (this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.Terminated && this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.ChangeInProgress
          && this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.PendingTerminationNotice && this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.PendingTermination) {
          this.showExtend = true;
        }
        if (!this.workOrder.IsOffboarding && this.workOrder.TerminationDate === null && (this.daysForEndOfAssignment > 0 && this.daysForEndOfAssignment <= 30)) {
          this.style = 'alert-warning';
          this.messageBody = 'In ' + this.daysForEndOfAssignment +
                             ' day(s).';
          this.directionsMessage = this.workOrder.HasOffboardingAccess ? ' Please confirm extension or initiate offboarding.' : '';
          this.showBanner = true;
          this.showOffboarding = true;
        } else {
          if (this.daysForEndOfAssignment > 7 && this.daysForEndOfAssignment <= 30) {
            this.style = 'alert-primary';
            this.messageBody = 'In ' + this.daysForEndOfAssignment +
                               ' day(s).';
            this.directionsMessage = this.workOrder.HasOffboardingAccess ? ' Make sure the offboarding checklist is completed by then.' : '';
            this.showBanner = true;
          } else if (this.daysForEndOfAssignment > 0 && this.daysForEndOfAssignment <= 7) {
            this.style = 'alert-warning';
            this.messageBody = 'In ' + this.daysForEndOfAssignment +
                               ' day(s).';
            this.directionsMessage = this.workOrder.HasOffboardingAccess ? ' Make sure the offboarding checklist is completed by then.' : '';
            this.showBanner = true;
            if (!this.workOrder.IsOffboarding && this.workOrder.TerminationDate === null) {
              this.showOffboarding = true;
            }
          } else if (this.daysForEndOfAssignment <= 0) {
            this.icon = 'error';
            this.style = 'alert-danger';
            this.messageBody = this.daysForEndOfAssignment === 0 ? ' Is today.' :
                               ('was ' + (this.daysForEndOfAssignment < 0 ? (this.daysForEndOfAssignment * -1) : this.daysForEndOfAssignment)) +
                               ' day(s) ago.';
            this.directionsMessage = this.workOrder.HasOffboardingAccess ? ' Please make sure the offboarding checklist is completed.' : '';
            this.showBanner = true;
          }
          if (this.showExtend) {
            this.directionsMessage += this.workOrder.HasOffboardingAccess ? ' If the work order is supposed to be extended, please confirm.' : '';
          }
        }
        if (!this.workOrder.HasOffboardingAccess) {
          if (this.workOrder.IsOffboarding) {
            this.messageBody += ' Offboarding confirmed.';
          } else {
            this.messageBody += ' Pending offboarding confirmation.';
          }
          if (this.showExtend) {
            this.directionsMessage = ' If the work order is supposed to be extended, please confirm.';
          }
        }
        this.configStateActions();
    }
  }

  configStateActions() {
    let hasActions = false;
    this.stateActionsFiltered = [];
    this.availableStateActionsFiltered = [];
    this.stateActions.forEach((stateAction: StateAction) => {
      if (stateAction.actionId === PhxConstants.StateAction.AssignmentExtend) {
        if (this.showExtend) {
          stateAction.hiddenFn = null;
        } else {
          stateAction.hiddenFn = () => true;
        }
        this.stateActionsFiltered.push(stateAction);
      }
      if (stateAction.actionId === PhxConstants.StateAction.WorkOrderMarkOffboarding) {
        if (this.showOffboarding) {
          stateAction.hiddenFn = null;
        } else {
          stateAction.hiddenFn = () => true;
        }
        this.stateActionsFiltered.push(stateAction);
      }
    });

    this.availableStateActions.forEach((action: number) => {
      if (action === PhxConstants.StateAction.AssignmentExtend || action === PhxConstants.StateAction.WorkOrderMarkOffboarding) {
        this.availableStateActionsFiltered.push(action);
        if (action === PhxConstants.StateAction.WorkOrderMarkOffboarding || action === PhxConstants.StateAction.AssignmentExtend) {
          hasActions = true;
        }
      }
    });
    if (hasActions) {
      this.messageBody += this.directionsMessage;
    }

  }

  getDays(StartDate: Date, EndDate: Date): number {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;
    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());
    // so it's safe to divide by 24 hours
    return (start - end) / oneDay;
  }

  closeBanner() {
    this.showBanner = false;
  }
}
