import { takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WorkOrderDataService, WorkOrderFormService, WorkorderService } from '../../services';
import { IWorkOrder, IPurchaseOrderLineLists } from '../../models/workorder.interface';
import { CodeValueService, CommonService, PhxConstants, DialogService, PhxLocalizationService, CodeValueGroups } from '../../../common';
import { CodeValue, DialogResultType, UserProfile } from '../../../common/model';
import { each } from 'lodash';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { AuthService } from 'src/app/common/services/auth.service';
import { PhoenixCommonModuleResourceKeys } from 'src/app/common/PhoenixCommon.module';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';

@Component({
  selector: 'app-workorder-tab-purchase-order',
  templateUrl: './workorder-tab-purchase-order.component.html'
})
export class WorkorderTabPurchaseOrderComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() workOrderId: number;
  @ViewChild('modalLine', { static: true }) modalLine: PhxModalComponent;
  @ViewChild('modalLineEdit', { static: true }) modalLineEdit: PhxModalComponent;
  readonly codeValueGroups = CodeValueGroups;
  purchaseOrderDepletedGroupList: Array<CodeValue>;
  purchaseOrderStatuses: Array<CodeValue>;
  phxConstants: typeof PhxConstants;
  workOrder: IWorkOrder;
  primaryworkunit: string;
  primaryFirstBillingRate: number;
  organizationId: number;
  purchaseOrderSearchLines: any = [];
  LineId: number;
  poLineNum: string;
  polines: any;
  poNumber: string;
  workOderPurchaseOrderLineId: number;
  AssignmentId: number;
  workorderId: number;
  workorderNumber: number;
  workorderVersion: number;
  workerProfileId: number;
  hasWorkOrderPurchaseOrderLineDeleteAccess: boolean;
  isInternalUser: boolean;
  validationMessages: Array<any> = [];

  constructor(
    private workorderService: WorkorderService,
    private workorderDataService: WorkOrderDataService,
    private codevalueservice: CodeValueService,
    private commonservice: CommonService,
    private workOrderFormService: WorkOrderFormService,
    private authService: AuthService,
    private dialogService: DialogService,
    private localizationService: PhxLocalizationService) {
    super();
    this.purchaseOrderDepletedGroupList = this.codevalueservice.getCodeValues(this.codeValueGroups.PurchaseOrderDepletedGroups, true);
    this.purchaseOrderStatuses = this.purchaseOrderStatuses = this.codevalueservice.getCodeValues(this.codeValueGroups.PurchaseOrderStatus, true);
  }

  ngOnInit() {
    this.getWorkOrderModel();
    this.phxConstants = PhxConstants;
    this.modalLine.addClassToConfig('modal-lg purchaseorder-modal');
    this.modalLineEdit.addClassToConfig('modal-lg purchaseorder-modal');
    this.authService.getCurrentProfile()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((user: UserProfile) => {
        if (user?.ProfileTypeId) {
          this.isInternalUser = (user.ProfileTypeId === PhxConstants.UserProfileType.Internal);
        }
      });
  }

  getWorkOrderPurchaseOrderLine() {
    this.getWorkOrderPurchaseOrderLinesByWorkOrderId();
    this.getBillingRate();
    this.organizationId = this.workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient;
    this.hasWorkOrderPurchaseOrderLineDeleteAccess = this.authService.hasFunctionalOperation(PhxConstants.FunctionalOperation.WorkOrderPurchaseOrderLineDelete);
  }

  getWorkOrderModel() {
    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .pipe(tap((response: any) => {
        if (response) {
          this.workOrder = response;
          this.AssignmentId = this.workOrder.AssignmentId;
          this.workorderId = this.workOrder.WorkOrderId;
          this.workorderNumber = this.workOrder.WorkOrderVersion.WorkOrderNumber;
          this.workorderVersion = this.workOrder.WorkOrderVersion.Id;
          this.workerProfileId = this.workOrder.UserProfileIdWorker;
        }
      }))
      .pipe(distinctUntilChanged((prev: IWorkOrder, curr: IWorkOrder) => {
        return curr && prev?.WorkOrderVersion.Id === curr.WorkOrderVersion.Id;
      }))
      .subscribe((response: any) => {
        if (response) {

          // ASYNC CALL HERE, POTENTIAL RACE CONDITION
          this.getWorkOrderPurchaseOrderLine();
        }
      });
  }

  getWorkOrderPurchaseOrderLinesByWorkOrderId() {
    this.workorderService.getWorkOrderPurchaseOrderLinesByWorkOrderId(this.workorderId).subscribe((result: any) => {
      this.workOrder.WorkOrderVersion.WorkOrderPurchaseOrderLines = result?.Items?.length
        ? result.Items.map(item => {
          return {
            Id: item.Id,
            PurchaseOrderDepletionGroupId: item.PurchaseOrderDepletionGroupId,
            StartDate: item.PurchaseOrderLineStartDate,
            EndDate: item.PurchaseOrderLineEndDate,
            PurchaseOrderId: item.PurchaseOrderId,
            PurchaseOrderLineId: item.PurchaseOrderLineId,
            PurchaseOrderNumber: item.PurchaseOrderNumber,
            PurchaseOrderLineNumber: item.PurchaseOrderLineNumber,
            Amount: item.Amount,
            AmountCommited: item.AmountCommited,
            AmountSpent: item.AmountSpent,
            AmountReserved: item.AmountReserved,
            StatusId: item.StatusId,
            AmountRemaining: item.AmountRemaining,
            NoOfPurchaseOrderTransactions: item.NoOfPurchaseOrderTransactions || 0,
            SumSalesTaxVersionRate: item.SumSalesTaxVersionRate,
            IsTaxIncluded: item.IsTaxIncluded,
            CurrencyCode: this.codevalueservice.getCodeValue(item.PurchaseOrderLineCurrencyId, this.codeValueGroups.Currency).code
          };
        })
        : [];

      const cachedWorkOrder = this.workorderDataService.getCachedWorkOrder(
        this.workOrder.WorkOrderVersion.Id,
        this.workOrder.TemplateId
      );
      if (cachedWorkOrder) {
        cachedWorkOrder.WorkOrderVersion.WorkOrderPurchaseOrderLines = this.workOrder.WorkOrderVersion.WorkOrderPurchaseOrderLines;
        this.workorderDataService.updateWorkOrder(cachedWorkOrder);
      } else {
        this.workorderDataService.updateWorkOrder(this.workOrder);
      }
    });
  }

  addWorkOrderPurchaseOrderLine() {
    this.workOderPurchaseOrderLineId = -1;
    const organizationIdClient = this.organizationId ? this.organizationId : 0;
    this.workorderService
      .getPurchaseOrderLineByOrganizationIdClientAndWorkOrderId(
        organizationIdClient,
        this.workorderId,
        oreq
          .request()
          .withExpand(['WorkOrderPurchaseOrderLines'])
          .withSelect([
            'CurrencyId',
            'Id',
            'StatusId',
            'PurchaseOrderNumber',
            'DepletionGroupId',
            'PurchaseOrderLineNumber',
            'PurchaseOrderId',
            'Amount',
            'WorkOrderPurchaseOrderLines/Id',
            'WorkOrderPurchaseOrderLines/AmountCommitted',
            'WorkOrderPurchaseOrderLines/AmountSpent',
            'WorkOrderPurchaseOrderLines/NoOfPurchaseOrderTransactions',
          ])
          .url()
      )
      .subscribe((response: any) => {
        if (response) {
          let responseItems: Array<IPurchaseOrderLineLists> = [];
          if (response.Items?.length) {
            responseItems = response.Items.map(item => {
              const newItem: IPurchaseOrderLineLists = {
                Id: item.Id,
                PurchaseOrderId: item.PurchaseOrderId,
                PurchaseOrderDepletionGroupId: item.DepletionGroupId,
                PurchaseOrderNumber: item.PurchaseOrderNumber,
                PurchaseOrderLineNumber: item.PurchaseOrderLineNumber,
                Amount: item.Amount,
                AmountCommited: 0,
                AmountSpent: 0,
                CurrencyCode: this.codevalueservice.getCodeValueCode(item.CurrencyId, this.codeValueGroups.Currency),
                StatusId: item.StatusId,
                PurchaseOrderLineStatusName: this.codevalueservice.getCodeValueCode(item.StatusId, this.codeValueGroups.WorkOrderPurchaseOrderLineStatus)
              };
              if (item.WorkOrderPurchaseOrderLines?.length) {
                item.WorkOrderPurchaseOrderLines.forEach(wopol => {
                  newItem.AmountCommited += wopol.AmountCommitted;
                  newItem.AmountSpent += wopol.AmountSpent;
                });
              }
              return newItem;
            });
          }
          this.purchaseOrderSearchLines = this.purchaseOrderLinesFilteredByUsage(responseItems, this.workOrder.WorkOrderVersion.WorkOrderPurchaseOrderLines);
        }
      });
    this.modalLine.show();
  }

  purchaseOrderLinesFilteredByUsage(inputList: any, excludeCollection: any) {
    const result = [];
    if (inputList) {
      inputList.forEach(i => {
        let exists = false;
        if (excludeCollection) {
          excludeCollection.forEach(e => {
            if (e.PurchaseOrderNumber === i.PurchaseOrderNumber && e.PurchaseOrderLineNumber === i.PurchaseOrderLineNumber) {
              exists = true;
            }
          });
        }
        if (!exists) {
          result.push(i);
        }
      });
    }
    return result;
  }

  purchaseOrderLineOpen(po: any) {
    this.LineId = po.PurchaseOrderLineId;
    this.poLineNum = po.PurchaseOrderLineNumber;
    this.poNumber = po.PurchaseOrderNumber;
    this.workOderPurchaseOrderLineId = po.Id;
    this.modalLineEdit.show();
  }

  purchaseOrderLineActivate(pol: any) {
    this.validationMessages = [];
    this.workorderService.getByPurchaseOrderId(pol.PurchaseOrderId).subscribe(response => {
      if (response) {
        this.polines = response;
        this.workorderService
          .workOrderPurchaseOrderLineStatusToActivate({
            WorkOrderPurchaseOrderLineId: pol.Id,
            LastModifiedDatetime: this.getMaxLastModified()
          })
          .subscribe({
            next: rsp => {
              if (rsp) {
                this.ngOnInit();
              }
            },
            error: error => {
              const validationMessages = this.commonservice.parseResponseError(error);
              if (validationMessages.length > 0) {
                validationMessages.forEach(element => {
                  this.validationMessages.push(element.Message);
                });
              }
            }
          });
      }
    });
  }

  purchaseOrderLineDelete(pol: any) {
    this.dialogService.confirmDialog(
      'Discard Purchase Order Line?',
      `Are you sure you want to discard the Purchase Order - ${pol.PurchaseOrderNumber} Line ${pol.PurchaseOrderLineNumber}?`,
      {
        size: 'md'
      },
      this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.discard),
      this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.cancel)
    ).then((button) => {
        if (button === DialogResultType.Yes){
          this.workorderService
            .workOrderPurchaseOrderLineDelete({
              WorkOrderPurchaseOrderLineId: pol.Id,
            })
            .subscribe(rsp => {
              if (rsp) {
                this.ngOnInit();
              }
            });
        }        
      });
  }

  getMaxLastModified() {
    if (!this.polines.LastModifiedDatetime) {
      return new Date(Date.now());
    }
    let dates = [this.polines.LastModifiedDatetime];
    each(this.polines.PurchaseOrderLines, (pol) => {
      if (!pol.LastModifiedDatetime || typeof pol.LastModifiedDatetime === 'string') {
        return;
      }
      dates.push(pol.LastModifiedDatetime);
      each(pol.WorkOrderPurchaseOrderLines, (wopol) => {
        if (!wopol.LastModifiedDatetime || typeof wopol.LastModifiedDatetime === 'string') {
          return;
        }
        dates.push(wopol.LastModifiedDatetime);
      });
    });
    if (this.polines.deletedPurchaseOrderLines) {
      dates = dates.concat(this.polines.deletedPurchaseOrderLines);
    }
    // eslint-disable-next-line prefer-spread
    return new Date(Math.max.apply(Math, dates));
  }

  getBillingRate() {
    const allRates = this.workOrder.WorkOrderVersion.BillingInfoes[0].BillingRates;
    const billingRate = allRates ? allRates.find(r => r.RateTypeId === 1) : null;
    this.primaryFirstBillingRate = Number(billingRate ? billingRate.Rate : 0);

    switch (billingRate ? billingRate.RateUnitId : 0) {
      case 1:
        this.primaryworkunit = 'H';
        break;
      case 2:
        this.primaryworkunit = 'D';
        break;
      case 3:
        this.primaryworkunit = 'F';
        break;
      default:
        this.primaryworkunit = '-';
    }
  }

  getCodeText(statusId: number) {
    const code = this.codevalueservice.getCodeValue(statusId, this.codeValueGroups.WorkOrderPurchaseOrderLineStatus);
    if (code) {
      return code.text;
    } else {
      return null;
    }
  }

  onCancel() {
    this.workOderPurchaseOrderLineId = 0;
  }

  onCancelPOLine() {
    this.modalLine.hide();
  }
}
