import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { CodeValueGroups, IFormService } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IJobSkill, ITabCoreJob, IWorkOrder } from '../../models';
import { CodeValueService } from 'src/app/common';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';


@Injectable()
export class CoreJobFormService implements IFormService {
  formGroup: FormGroup<ITabCoreJob>;

  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private codeValueService: CodeValueService,
  ) { }


  get JobCategoryIdControl(): FormControl<number> {
    return this.formGroup.get('JobCategoryId') as FormControl<number>;
  }

  get JobFunctionIdControl(): FormControl<number> {
    return this.formGroup.get('JobFunctionId') as FormControl<number>;
  }

  get ClientPositionTitleControl(): FormControl<string> {
    return this.formGroup.get('ClientPositionTitle') as FormControl<string>;
  }

  get isSameJobFunctionControl(): FormControl<boolean> {
    return this.formGroup.get('IsClientPositionTitleSameAsJobFunction') as FormControl<boolean>;
  }

  get JobCategoryIdChange$() {
    return this.JobCategoryIdControl.valueChanges;
  }

  get JobFunctionIdChange$() {
    return this.JobFunctionIdControl.valueChanges;
  }

  get isSameJobFunctionChange$() {
    return this.isSameJobFunctionControl.valueChanges;
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabCoreJob {
    return {
      JobCategoryId: workorder.WorkOrderVersion.JobCategoryId,
      JobFunctionId: workorder.WorkOrderVersion.JobFunctionId,
      JobSkills: workorder.WorkOrderVersion.JobSkills?.map(skill => skill.JobSkillId) || [],
      ClientPositionTitle: workorder.WorkOrderVersion.ClientPositionTitle,
      IsClientPositionTitleSameAsJobFunction: workorder.WorkOrderVersion.IsClientPositionTitleSameAsJobFunction || workorder.WorkOrderVersion.IsClientPositionTitleSameAsJobFunction === null
    };
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const jobDetails = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabCoreJob>({
      JobCategoryId: [
        jobDetails.JobCategoryId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'JobCategoryId',
          [Validators.required]
        )
      ],
      JobFunctionId: [
        jobDetails.JobFunctionId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'JobFunctionId',
          [Validators.required]
        )
      ],
      JobSkills: [jobDetails.JobSkills],
      IsClientPositionTitleSameAsJobFunction: [jobDetails.IsClientPositionTitleSameAsJobFunction],
      ClientPositionTitle: [
        jobDetails.ClientPositionTitle,
        jobDetails.IsClientPositionTitleSameAsJobFunction ?
          null : PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ClientPositionTitle', [Validators.required]
          )
      ],
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.JobCategoryIdChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(() => {
      this.JobFunctionIdControl.patchValue(null, { emitEvent: false });
    });

    this.JobFunctionIdChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      if (this.isSameJobFunctionControl.value) {
        const code = this.codeValueService.getCodeValue(value, CodeValueGroups.JobFunction);

        this.ClientPositionTitleControl.patchValue(
          code ? code.text : null,
          { emitEvent: false });
      }
    });

    this.isSameJobFunctionChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      this.ClientPositionTitleControl.setValidators(
        value ?
          null : PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ClientPositionTitle', [Validators.required]
          )
      );

      if (value) {
        const code = this.codeValueService.getCodeValue(this.JobFunctionIdControl.value, CodeValueGroups.JobFunction);

        this.ClientPositionTitleControl.patchValue(
          code ? code.text : null, { emitEvent: false });
      } else {
        this.ClientPositionTitleControl.patchValue(null, { emitEvent: false });
      }

    });
  }

  updateForm(workorder: IWorkOrder) {
    const jobDetails = this.mapWorkOrderToFormData(workorder);

    this.ClientPositionTitleControl.setValidators(
      jobDetails.IsClientPositionTitleSameAsJobFunction ?
        null : PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ClientPositionTitle', [Validators.required]
        )
    );

    this.formGroup.patchValue({
      JobFunctionId: jobDetails.JobFunctionId,
      JobCategoryId: jobDetails.JobCategoryId,
      JobSkills: jobDetails.JobSkills,
      ClientPositionTitle: jobDetails.ClientPositionTitle,
      IsClientPositionTitleSameAsJobFunction: jobDetails.IsClientPositionTitleSameAsJobFunction
    }, { emitEvent: false });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const jobDetails = this.formGroup.value;
    const savedJobSkills = workOrder.WorkOrderVersion.JobSkills || [];
    const jobSkills: Array<IJobSkill> = jobDetails.JobSkills.map(skillId => {
      const savedSkill = savedJobSkills.find(skill => skill.JobSkillId === skillId);
      return {
        Id: savedSkill ? savedSkill.Id : 0,
        JobSkillId: skillId,
        WorkorderVersionId: workOrder.WorkOrderVersion.Id
      };
    });

    workOrder.WorkOrderVersion.JobSkills = jobSkills;
    workOrder.WorkOrderVersion.JobCategoryId = jobDetails.JobCategoryId;
    workOrder.WorkOrderVersion.JobFunctionId = jobDetails.JobFunctionId;
    workOrder.WorkOrderVersion.ClientPositionTitle = jobDetails.ClientPositionTitle;
    workOrder.WorkOrderVersion.IsClientPositionTitleSameAsJobFunction = jobDetails.IsClientPositionTitleSameAsJobFunction;

    return workOrder;
  }

}
