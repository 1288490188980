<form [formGroup]="formGroup">
    <phx-form-control labelText="Apply Withholding Tax" [control]="formGroup.controls.CNRWithholdingTaxIsApplied"
      [editable]="readOnlyStorage.IsEditable"
      viewModeText="{{formGroup.controls.CNRWithholdingTaxIsApplied.value ? 'Yes' : 'No'}}">
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="true" formControlName="CNRWithholdingTaxIsApplied">Yes</label>
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="false" formControlName="CNRWithholdingTaxIsApplied">No</label>
    </phx-form-control>
    <phx-form-control *ngIf="formGroup.controls.CNRWithholdingTaxIsApplied.value === false"
      labelText="Override Reason" [control]="formGroup.controls.CNRWithholdingTaxOverrideReason"
      [editable]="readOnlyStorage.IsEditable"
      viewModeText="{{formGroup.controls.CNRWithholdingTaxOverrideReason.value}}">
      <input formControlName="CNRWithholdingTaxOverrideReason" class="form-control" type="text" maxlength="128"/>
    </phx-form-control>
</form>
