<app-phx-panel headerTitle="Source Deductions">
  <div [formGroup]="inputFormGroup">
    <phx-form-control [control]="inputFormGroup.controls.SubdivisionIdSourceDeduction"
      labelText="Deduction Province/State"
      viewModeText="{{inputFormGroup.controls.SubdivisionIdSourceDeduction.value | CodeValue:codeValueGroups.Subdivision}}"
      [editable]="readOnlyStorage?.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','SubdivisionIdSourceDeduction')">
      <app-phx-select-box [formControl]="inputFormGroup.controls.SubdivisionIdSourceDeduction"
        [items]="html.lists.workOrderSalesTaxTerritories" textField="text" valueField="id">
      </app-phx-select-box>
    </phx-form-control>
    <phx-form-control labelText="Use deductions from Worker Profile?"
      [control]="inputFormGroup.controls.IsUseUserProfileWorkerSourceDeduction"
      [editable]="readOnlyStorage?.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','IsUseUserProfileWorkerSourceDeduction')"
      viewModeText="{{inputFormGroup.controls.IsUseUserProfileWorkerSourceDeduction.value ? 'Yes' : 'No'}}">
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="true"
          formControlName="IsUseUserProfileWorkerSourceDeduction">
        Yes
      </label>
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="false"
          formControlName="IsUseUserProfileWorkerSourceDeduction">
        No
      </label>
    </phx-form-control>
  </div>
</app-phx-panel>
<app-phx-modal #modal [buttons]="modalFabButtons" title="Source Deduction">
  <div class="attachment-container">
    Are you sure you would like to take from Worker Profile? This will erase all current selections.
  </div>
</app-phx-modal>