import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AtsIdForm } from './models/ats-id-form.model';
import { ModalMode } from './models/modal-mode.model';
import { PreviewForm } from './models/preview-form.model';
import { CodeValueService } from '../../../common';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { CodeValueGroups, PhxButton } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkorderNew } from '../../models';
import { ApplicationTransactionSystemDto } from '../../models/dto/application-transaction-system.dto';
import { WorkorderService } from '../../services';

@Component({
  selector: 'app-enter-ats-id-modal',
  templateUrl: './enter-ats-id-modal.component.html'
})
export class EnterAtsIdModalComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() showModal$: Observable<void>;
  @Input() setATSId$: Subject<number>;
  @ViewChild('enterAtsIdModal', { static: false }) enterAtsIdModal: PhxModalComponent;

  public buttons: PhxButton[] = [];
  public title: string;
  public atsIdSearchForm?: FormGroup<AtsIdForm>;
  public previewForm?: FormGroup<PreviewForm>;
  public isATSCheckInProgress = false;
  public workOrder?: IWorkorderNew;
  private readonly defaultAtsSourceId = 1;
  private subscription?: Subscription;

  constructor(private workOrderService: WorkorderService, private fb: FormBuilder, private codeValueService: CodeValueService) {
    super();
  }

  public ngOnInit(): void {
    this.subscribeToShowModal();
  }

  public closeModal(): void {
    this.atsIdSearchForm = null;
    this.previewForm = null;
    this.subscription?.unsubscribe();
  }

  public checkATSId(): void {
    this.isATSCheckInProgress = true;
    this.initConfirmationForm();
    this.subscription = this.workOrderService.getAts(this.defaultAtsSourceId, this.atsIdSearchForm.controls.id.value, false).subscribe({
      next: (wo) => {
        this.setConfirmFormValue(wo);
      },
      error: () => {
        this.setSearchForm();
      },
      complete: () => {
        this.isATSCheckInProgress = false;
      }
    });
  }

  private subscribeToShowModal(): void {
    this.showModal$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      this.setSearchForm();
      this.enterAtsIdModal.show();
    });
  }

  private initATSSearchForm(): void {
    this.atsIdSearchForm = this.fb.group<AtsIdForm>({
      id: [null, [Validators.required, Validators.min(1), Validators.max(999999)]]
    });
  }

  private setModalButtons(mode: ModalMode): void {
    const allButtons: Array<PhxButton & { mode: Array<ModalMode> | ModalMode }> = [
      {
        mode: 'search',
        icon: '',
        tooltip: $localize`:@@common.button.next:Next`,
        btnType: 'primary',
        action: () => this.checkATSId(),
        disabled: () => this.isATSCheckInProgress || this.atsIdSearchForm?.invalid
      },
      {
        mode: 'confirm',
        icon: '',
        tooltip: $localize`:@@common.button.continue:Continue`,
        btnType: 'primary',
        action: () => this.confirmATSId(),
        disabled: () => this.isATSCheckInProgress || this.previewForm?.invalid || !this.previewForm?.controls.atsId.value
      },
      {
        mode: ['search', 'confirm'],
        icon: '',
        tooltip: $localize`:@@common.button.cancel:Cancel`,
        btnType: 'default',
        action: () => {
          if (mode === 'confirm') {
            this.setSearchForm();
          } else {
            this.enterAtsIdModal.hide();
          }
        }
      }
    ];

    this.buttons = allButtons.filter(b => (Array.isArray(b.mode) ? b.mode.includes(mode) : b.mode === mode));
  }

  private confirmATSId(): void {
    this.setATSId$.next(this.previewForm.value.atsId);
    this.enterAtsIdModal.hide();
  }

  private setSearchForm(): void {
    if (!this.atsIdSearchForm) {
      this.initATSSearchForm();
    }
    this.subscription?.unsubscribe();
    this.isATSCheckInProgress = false;
    this.previewForm = null;
    this.title = $localize`:@@workOrder.enterATSId:Enter ATS Id`;
    this.setModalButtons('search');
  }

  private initConfirmationForm(): void {
    this.previewForm = this.fb.group<PreviewForm>({
      atsId: [null, Validators.required],
      clientCompany: null,
      userProfileWorker: null,
      lineOfBusiness: null,
      placementDates: null
    });
    this.title = $localize`:@@workOrder.confirmATSResults:Confirm ATS Results`;
    this.setModalButtons('confirm');
  }

  private setConfirmFormValue(wo: ApplicationTransactionSystemDto): void {
    this.previewForm?.patchValue({
      atsId: wo.AtsPlacementId,
      clientCompany: [wo.AtsClientId, wo.AtsClientDisplayName].filter(Boolean).join(' - '),
      userProfileWorker: [wo.AtsWorkerId, wo.AtsWorkerName].filter(Boolean).join(' - '),
      lineOfBusiness: this.codeValueService.getCodeValueText(wo.LineOfBusinessId, CodeValueGroups.LineOfBusiness),
      placementDates: [wo.StartDate, wo.EndDate].filter(Boolean).join(' to ')
    });
  }
}
