import { Component, OnInit, ViewChild, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { groupBy } from 'lodash';
import moment from 'moment';

import { PhxConstants, ApiService, CodeValueService, DialogService } from '@common';
import { PhxToastType } from '@common/components/phx-toast/phx-toast-types';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { PhxButton, CodeValueGroups, CodeValue, CommandResponse, DialogResultType } from '@common/model';
import { NoteService } from '@common/services/note.service';
import { PhxSideBarService } from '@common/services/phx-side-bar.service';
import { PhxToastService } from '@common/services/phxToast.service';

import { IOffboardingTask, ModalType, IWorkOrder } from '../../models';
import { WorkorderOffboardingService, WorkorderService } from '../../services';
import { WorkorderOffboardingCompliancetaskModalComponent } from '../workorder-offboarding-compliancetask-modal/workorder-offboarding-compliancetask-modal.component';
import { WorkorderOffboardingExempttaskModalComponent } from '../workorder-offboarding-exempttask-modal/workorder-offboarding-exempttask-modal.component';

@Component({
  selector: 'app-workorder-offboarding',
  templateUrl: './workorder-offboarding.component.html',
  styleUrls: ['./workorder-offboarding.component.less']
})
export class WorkorderOffboardingComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() workOrderId: number;
  @Input() workOrderStatusId: number;
  @Input() actualEndDate: string;
  @Input() isExtended: boolean;
  @Input() userProfileIdWorker: number;
  @Input() terminationNoticeDate: string;
  @Input() workOrder: IWorkOrder;
  @Input() workOrderVersionWorksite: number;

  @ViewChild('modalComplianceTask') modalComplianceTask: WorkorderOffboardingCompliancetaskModalComponent;
  @ViewChild('modalExemptTask') modalExemptTask: WorkorderOffboardingExempttaskModalComponent;

  listUserProfileWorkers$: Subscription;
  contactFullName: string;
  listUserProfileWorker: Array<any>;

  currentTask: IOffboardingTask;
  collapseChecklist = false;

  allButtons: Array<{ actionId: number; button: PhxButton; }>;
  complianceTaskModalbuttons: PhxButton[];

  offboardingTasks: IOffboardingTask[] = [];
  offboardingTasksByRole: { title: string; completeCount: number; tasks: IOffboardingTask[]; isOpen: boolean }[] = [];

  countCompleted = 0;
  countTotal = 0;

  phxConstants: typeof PhxConstants;
  codeValueGroups = CodeValueGroups;

  exemptActionButton: PhxButton;
  modalComplianceCancelButton: PhxButton;
  modalExemptCancelButton: PhxButton;

  canadianWorksiteIds: number[];
  isCanadianWorkSite = true;

  constructor(
    private apiService: ApiService,
    private offboardingService: WorkorderOffboardingService,
    private codeValueService: CodeValueService,
    private toastService: PhxToastService,
    private commonListsObservableService: CommonListsObservableService,
    private dialogService: DialogService,
    private noteService: NoteService,
    private workOrderService: WorkorderService,
    private cdr: ChangeDetectorRef,
    private phxSideBarService: PhxSideBarService
  ) {
    super();
    this.phxConstants = PhxConstants;
    this.setCanadianWorksiteIds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workOrderId) {
      this.setOffboardingTasks();
    }
  }

  private setCanadianWorksiteIds() {
    const canadianSubdividions: CodeValue[] = this.codeValueService.getRelatedCodeValues(CodeValueGroups.Subdivision, PhxConstants.Country.CA, CodeValueGroups.Country);

    this.canadianWorksiteIds = [];

    canadianSubdividions.forEach(subdivision =>
      this.canadianWorksiteIds = this.canadianWorksiteIds.concat(
        this.codeValueService.getRelatedCodeValues(CodeValueGroups.Worksite, subdivision.id, CodeValueGroups.Subdivision).map((worksite: CodeValue) => worksite.id)
      )
    );
  }

  private initializeButtons() {
    this.allButtons = [
      {
        actionId: this.phxConstants.StateAction.OffboardSubmit,
        button: {
          icon: null,
          tooltip: 'Complete',
          btnType: 'primary',
          disabled: () => {
            if (!this.modalComplianceTask.form) {
              return true;
            } else if (this.modalComplianceTask.entity?.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice &&
              this.workOrder && this.canadianWorksiteIds.includes(this.workOrder.WorkOrderVersion.WorksiteId) &&
              (this.modalComplianceTask.form.controls.Documents.value.length < 1 || !this.modalComplianceTask.form.controls.Notes.value)) {
              return true;
            } else if (this.modalComplianceTask.entity?.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice &&
              this.workOrder && !this.canadianWorksiteIds.includes(this.workOrder.WorkOrderVersion.WorksiteId) &&
              (this.modalComplianceTask.form.controls.Documents.value.length < 1 && !this.modalComplianceTask.form.controls.Notes.value)) {
              return true;
            } else {
              return false;
            }
          },
          action: () => {
            if (this.modalComplianceTask.isValidForm()) {
              const payload = this.modalComplianceTask.getPayload();
              this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardSubmit], payload).then(
                () => {
                  this.toastService.showToast('Success:', 'Task completed.', PhxToastType.success, null);
                  this.setOffboardingTasks();
                  this.modalComplianceTask.modal.hide(false);
                  this.workOrderService.updateOffboardingStatus();
                  if (payload.OffboardingTaskTypeId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice) {
                    this.workOrderService.refreshWorkOrder(
                      this.workOrder.WorkOrderId,
                      this.workOrder.AssignmentId,
                      this.workOrder.WorkOrderVersion.Id,
                      this.workOrder.TemplateId
                    );
                  }
                },
              ).catch(() => { });
            }
          }
        }
      },
      {
        actionId: this.phxConstants.StateAction.OffboardExemption,
        button: {
          icon: null,
          tooltip: 'Make Exemption',
          btnType: 'link',
          isTertiaryAction: true,
          disabled: () => false,
          action: () => this.makeExemption()
        }
      },
      {
        actionId: this.phxConstants.StateAction.OffboardSaveExemption,
        button: {
          icon: null,
          tooltip: 'Save',
          btnType: 'primary',
          disabled: () => !this.modalExemptTask.form || !this.modalExemptTask.hasChanges(),
          action: () => {
            if (this.modalExemptTask.isValidForm()) {
              const payload = this.modalExemptTask.getPayload();
              this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardSaveExemption], payload).then(
                () => {
                  this.setOffboardingTasks();
                  this.modalExemptTask.modal.hide(false);
                },
              ).catch(() => { });
            }
          }
        }
      },
      {
        actionId: this.phxConstants.StateAction.OffboardSave,
        button: {
          icon: null,
          tooltip: 'Save',
          btnType: 'primary',
          disabled: () => {
            if (!this.modalComplianceTask.form || !this.modalComplianceTask.hasChanges()) {
              return true;
            } else if (this.modalComplianceTask.entity?.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice &&
              this.workOrder && this.canadianWorksiteIds.includes(this.workOrder.WorkOrderVersion.WorksiteId) &&
              ((this.modalComplianceTask.form.controls.Documents.value.length >= 1 && !this.modalComplianceTask.form.controls.Notes.value) ||
                (this.modalComplianceTask.form.controls.Documents.value.length < 1 && this.modalComplianceTask.form.controls.Notes.value))) {
              return true;
            } else {
              return false;
            }
          },
          action: () => {
            if (this.modalComplianceTask.isValidForm()) {
              const payload = this.modalComplianceTask.getPayload();

              const save = () =>
                this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardSave], payload).then(
                  res => {
                    if (res.CustomPayload && this.offboardingService.statusesPending.includes(res.CustomPayload.StatusId)) {
                      this.toastService.showToast(null, '<strong>Task is reopened.</strong>', PhxToastType.warning);
                      this.workOrderService.updateOffboardingStatus();
                      this.workOrderService.refreshWorkOrder(
                        this.workOrder.WorkOrderId,
                        this.workOrder.AssignmentId,
                        this.workOrder.WorkOrderVersion.Id,
                        this.workOrder.TemplateId
                      );
                    }
                    this.setOffboardingTasks();
                    this.modalComplianceTask.modal.hide(false);
                    this.offboardingService.refreshDocuments();
                  },
                ).catch(() => { });

              const incomplete = (!payload.Notes || payload.Notes.length === 0) && (!payload.Documents || payload.Documents.length === 0);
              if (incomplete) {
                this.dialogService.confirmDialog(
                    'Reopen Task',
                    'The task requires a note and/or document to be completed.<br/><br/>Removing both would reopen the task.',
                    { },
                    'Reopen Task',
                    'Cancel'
                  )
                  .then(button => {
                    if (button === DialogResultType.Yes){
                      save();
                    }                    
                  });
              } else {
                save();
              }
            }
          }
        }
      },
      {
        actionId: this.phxConstants.StateAction.OffboardUndoExemption,
        button: {
          icon: null,
          tooltip: 'Recall Exemption',
          btnType: 'link',
          isTertiaryAction: true,
          disabled: () => false,
          action: () => {
            const payload = this.modalExemptTask.getPayload();
            this.recallExemptionTask(payload).then(
              () => {
                this.modalExemptTask.modal.hide(false);
                this.workOrderService.refreshWorkOrder(
                  this.workOrder.WorkOrderId,
                  this.workOrder.AssignmentId,
                  this.workOrder.WorkOrderVersion.Id,
                  this.workOrder.TemplateId
                );
              },
            ).catch(() => { });
          }
        }
      }
    ];
    this.modalComplianceCancelButton = {
      icon: null,
      tooltip: 'Cancel',
      btnType: 'default',
      action: () => {
        this.cancelModal(ModalType.ComplianceType);
      }
    };
    this.modalExemptCancelButton = {
      icon: null,
      tooltip: 'Cancel',
      btnType: 'default',
      action: () => {
        this.cancelModal(ModalType.ExemptType);
      }
    };
    this.exemptActionButton = {
      icon: null,
      tooltip: 'Exempt',
      btnType: 'primary',
      disabled: () => !this.modalExemptTask.isValidForm(),
      action: () => {
        if (this.modalExemptTask.isValidForm()) {
          const payload = this.modalExemptTask.getPayload();
          this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardExemption], payload).then(
            () => {
              this.setOffboardingTasks();
              this.modalExemptTask.modal.hide(false);
              this.toastService.showToast('Success', 'Task exempted.', PhxToastType.success);
              if (this.modalComplianceTask.modal.isVisible) {
                this.modalComplianceTask.modal.hide(false);
              }
              this.workOrderService.updateOffboardingStatus();
              this.workOrderService.refreshWorkOrder(
                this.workOrder.WorkOrderId,
                this.workOrder.AssignmentId,
                this.workOrder.WorkOrderVersion.Id,
                this.workOrder.TemplateId
              );
            }
          ).catch(() => { });
        }
      }
    };
  }

  ngOnInit() {
    this.setOffboardingTasks();
    this.initializeButtons();

    this.isCanadianWorkSite = this.canadianWorksiteIds.includes(this.workOrderVersionWorksite);

    this.offboardingTasks?.forEach(element => {
      if (element.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice) {
        element.Config.AllowExemption = this.isTerminationLetterAllowExemption();
      }
    });
    this.getListUserProfileWorker();
  }

  getListUserProfileWorker() {
    if (this.listUserProfileWorkers$) {
      this.listUserProfileWorkers$.unsubscribe();
    }
    this.listUserProfileWorkers$ = this.commonListsObservableService
      .getUserProfileWorkers$(this.workOrder.UserProfileIdWorker)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((listUserProfileWorker: any) => {
        if (listUserProfileWorker) {
          this.listUserProfileWorker = listUserProfileWorker;
          for (const item of this.listUserProfileWorker) {
            if (item.Id === this.userProfileIdWorker) {
              this.contactFullName = item.Contact.FullName;
            }
          }
        }
      });
  }

  isTaskDisabled(task: IOffboardingTask) {
    return this.isExtended || task.IsLocked || !task.AvailableStateActions?.length;
  }

  disableClickTask(task: IOffboardingTask) {
    // Disable click for state actions or view
    if (task.pending) {
      return this.isTaskDisabled(task);
    } else if (task.completed) {
      return !task.isCompliance ? this.isTaskDisabled(task) : false;
    } else if (task.exemption) {
      return false;
    } else {
      // Shoudn't reach here
      return true;
    }
  }

  isEnableExemption(task: IOffboardingTask) {
    return !this.isExtended && !task.IsLocked && task.AvailableStateActions?.length && task.Config.AllowExemption && (task.exemption || task.pending);
  }

  processOffboardingTasks() {
    const endDate = moment(this.actualEndDate, PhxConstants.DateFormat.API_Date);
    const today = moment().startOf('day');
    this.offboardingTasks.forEach((task: IOffboardingTask) => {
      let taskDueDate: moment.Moment;
      if (task.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice && this.terminationNoticeDate) {
        taskDueDate = moment(this.terminationNoticeDate, PhxConstants.DateFormat.API_Date);
      } else {
        taskDueDate = endDate.clone().subtract(task.Config.DueDays, 'day');
      }

      if (taskDueDate) {
        task.Config.NoOfDaysDueFromToday = taskDueDate.diff(today, 'day');
        task.Config.DueDate = taskDueDate.toDate();
      }
    });
  }

  setOffboardingTasks() {
    this.offboardingService.getOffboardingTasksByWorkOrderId(this.workOrderId).then(response => {
      const statusCompleted = [
        PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices,
        PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting,
        PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionClientServices,
        PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionAccounting
      ];
      this.offboardingTasks = response != null ? response.OffboardingTasks : [];
      this.processOffboardingTasks();
      this.countCompleted = this.offboardingTasks.filter(t => statusCompleted.includes(t.StatusId)).length;
      this.countTotal = this.offboardingTasks.length;

      this.groupTasksByRole();
      this.cdr.detectChanges();
    });
    this.noteService.loadNotes(PhxConstants.EntityType.WorkOrder, this.workOrderId, this.isDestroyed$, true);
  }

  private getTaskOwnerName(task: IOffboardingTask): string {
    const statusClientServices = [
      PhxConstants.WorkOrderOffboardingTaskStatus.PendingClientServices,
      PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices,
      PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionClientServices
    ];
    const statusAccounting = [
      PhxConstants.WorkOrderOffboardingTaskStatus.PendingAccounting,
      PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting,
      PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionAccounting
    ];

    if (statusClientServices.includes(task.StatusId)) {
      return 'Client Services';
    } else if (statusAccounting.includes(task.StatusId)) {
      return 'Back Office';
    } else {
      return 'N/A';
    }
  }

  private getSortOrder(taskOwenerName: string) {
    const order = ['Client Services', 'Back Office', 'N/A'];

    return order.indexOf(taskOwenerName);
  }

  private groupTasksByRole() {
    const statusCompleted = [
      PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices,
      PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting,
      PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionClientServices,
      PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionAccounting
    ];

    const groups = groupBy(this.offboardingTasks, task => this.getTaskOwnerName(task));

    this.offboardingTasksByRole = Object.keys(groups)
      .sort(key => this.getSortOrder(key))
      .map(key => {
        return {
          isOpen: true,
          title: key,
          completeCount: groups[key]?.filter(t => statusCompleted.includes(t.StatusId)).length,
          tasks: groups[key]
            .map(task => {
              return {
                sortOrder: this.codeValueService.getCodeValue(task.OffboardingTaskId, CodeValueGroups.WorkOrderOffboardingTask).sortOrder,
                task
              };
            })
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(obj => obj.task)
        };
      });
    this.offboardingTasks.forEach(element => {
      if (element.OffboardingTaskId === PhxConstants.WorkOrderOffboardingTask.TerminationNotice) {
        element.Config.AllowExemption = this.isTerminationLetterAllowExemption();
      }
    });

    if (!this.offboardingTasksByRole.length) {
      this.phxSideBarService.setActivePanel(PhxConstants.PanelBarType.CHECKLIST);
    }
  }


  completeAction(command: string, payload: any): Promise<CommandResponse> {
    return this.apiService.command(command, { ...payload, EntityIds: [payload.Id] });
  }

  onCheckboxClicked(task: IOffboardingTask, $event) {
    $event.preventDefault();
    this.currentTask = task;
    if (this.currentTask.isCompliance || this.currentTask.exemption) {
      if (this.currentTask.exemption) {
        this.openRecallExemptTaskModal(this.currentTask);
      } else {
        this.openComplianceModal(task);
      }
    } else {
      this.executeReminderTask(this.currentTask);
    }
  }

  isTerminationLetterAllowExemption() {
    let result = true;
    if (this.isCanadianWorkSite && this.workOrder &&
      this.workOrder.TerminationTypeId !== PhxConstants.TerminationType.NoStartClientInitiated && this.workOrder.TerminationTypeId !== PhxConstants.TerminationType.NoStartWorkerInitiated) {
      result = false;
    } else if (this.workOrder && ((this.isCanadianWorkSite &&
      (this.workOrder.TerminationTypeId === PhxConstants.TerminationType.NoStartClientInitiated || this.workOrder.TerminationTypeId === PhxConstants.TerminationType.NoStartWorkerInitiated)) ||
      !this.isCanadianWorkSite)) {
      result = true;
    }
    return result;
  }

  executeReminderTask(task: IOffboardingTask) {
    const payload = { Id: this.currentTask.Id, OffboardingTaskId: this.currentTask.OffboardingTaskId, StatusId: this.currentTask.StatusId };
    if (task.StatusId === PhxConstants.WorkOrderOffboardingTaskStatus.PendingAccounting || task.StatusId === PhxConstants.WorkOrderOffboardingTaskStatus.PendingClientServices) {
      this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardSubmit], payload).then(
        (response: CommandResponse) => {
          if (response.IsValid) {
            this.toastService.showToast('Success:', 'Task completed.', PhxToastType.success, null);
            this.setOffboardingTasks();
            this.workOrderService.updateOffboardingStatus();
          }
        }
      ).catch(() => { });
    }
    if (task.StatusId === PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting || task.StatusId === PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices) {
      this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardUncheck], payload).then(
        (response: CommandResponse) => {
          if (response.IsValid) {
            this.toastService.showToast(null, '<strong>Task is reopened.</strong>', PhxToastType.warning, null);
            this.setOffboardingTasks();
            this.workOrderService.updateOffboardingStatus();
          }
        }
      ).catch(() => { });
    }
  }

  openComplianceModal(task: IOffboardingTask) {
    const buttons: PhxButton[] = [];
    let availableButtons = this.allButtons;

    if (!task.Config.AllowExemption) {
      availableButtons = availableButtons.filter(obj => obj.actionId !== PhxConstants.StateAction.OffboardExemption);
    }

    if (!this.isTaskDisabled(task)) {
      buttons.push(...availableButtons.filter(obj => task.AvailableStateActions.includes(obj.actionId)).map(obj => obj.button));
    }

    this.showModal(buttons, task, ModalType.ComplianceType);
  }

  openExemptTaskModal(task: IOffboardingTask) {
    const buttons: PhxButton[] = [];
    buttons.push(this.exemptActionButton);
    this.showModal(buttons, task, ModalType.ExemptType);
  }

  openRecallExemptTaskModal(task: IOffboardingTask) {
    const buttons: PhxButton[] = [];
    if (this.isEnableExemption(task)) {
      const offboardUndoExemptionButton = this.allButtons.find(obj => obj.actionId === this.phxConstants.StateAction.OffboardUndoExemption).button;
      const offboardSaveExemptionButton = this.allButtons.find(obj => obj.actionId === this.phxConstants.StateAction.OffboardSaveExemption).button;
      buttons.push(offboardSaveExemptionButton, offboardUndoExemptionButton);
    }
    this.showModal(buttons, task, ModalType.ExemptType);
  }

  recallExemptionTask(task: IOffboardingTask) {
    return new Promise((resolve, error) => {
      this.completeAction(PhxConstants.StateAction[PhxConstants.StateAction.OffboardUndoExemption], task).then(
        res => {
          this.setOffboardingTasks();
          this.toastService.showToast(null, '<strong>Exemption recalled.</strong>', PhxToastType.warning, null);
          this.workOrderService.updateOffboardingStatus();
          resolve(res);
        },
      ).catch((err) => { error(err); });
    });
  }

  makeExemption() {
    const exempt = () => {
      this.modalComplianceTask.modal.hide(false);
      this.openExemptTaskModal(this.currentTask);
    };
    if (this.modalComplianceTask.uploadedDocuments.length > 0 || (this.modalComplianceTask.getPayload().Notes && this.modalComplianceTask.getPayload().Notes.length > 0)) {
      this.dialogService
        .confirmDialog('Make Exemption', 'Are you sure you want to exempt the task? Be aware, unsaved data will be lost. Do you want to continue?', { }, 'Continue', 'Cancel')
        .then(button => {
          if (button === DialogResultType.Yes){
            exempt();
          }
        });
    } else {
      exempt();
    }
  }

  showModal(buttons: PhxButton[], task: IOffboardingTask, modalType: ModalType) {
    this.currentTask = task;
    switch (modalType) {
      case ModalType.ComplianceType: {
        this.complianceTaskModalbuttons = buttons;
        buttons.push(this.modalComplianceCancelButton);
        this.modalComplianceTask.open(task, !this.isTaskDisabled(task), this.isCanadianWorkSite);
        break;
      }
      case ModalType.ExemptType: {
        const exemptTaskModalButtons = buttons.concat(this.modalExemptCancelButton);
        const publicIds = this.modalComplianceTask.uploadedDocuments.map(doc => doc.PublicId);
        this.deleteDocuments(publicIds);
        this.modalExemptTask.open(task, exemptTaskModalButtons, this.isEnableExemption(task));
        break;
      }
      default: {
        throw new Error('Undefined Modal type');
      }
    }
  }

  cancelModal(modalType: ModalType) {
    if (modalType === ModalType.ComplianceType) {
      const publicIds = this.modalComplianceTask.savedDocuments.map(doc => doc.PublicId);
      const unUsedDocs = this.modalComplianceTask.uploadedDocuments.filter(doc => !publicIds.includes(doc.PublicId));
      const unUsedPublicIds = unUsedDocs.map(doc => doc.PublicId);
      this.deleteDocuments(unUsedPublicIds);
      this.modalComplianceTask.modal.hide(false);
    } else if (modalType === ModalType.ExemptType) {
      this.modalExemptTask.modal.hide(false);
    }
  }

  private deleteDocuments(unUsedPublicIds: string[]) {
    if (unUsedPublicIds.length > 0) {
      const payload = { EntityIds: [this.currentTask.Id], PublicIds: unUsedPublicIds };
      this.apiService.command(PhxConstants.StateAction[PhxConstants.StateAction.OffboardDeleteDocument], payload).then(() => {
        this.offboardingService.refreshDocuments();
      }).catch(() => { });
    }
  }
}
