import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { IFormService } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IBillingInfo, IBillingSalesTax, IPaymentInfo, IPaymentSalesTax, ITabTaxes, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { WorkorderService } from '../workorder.service';
import { Validators } from '@angular/forms';

@Injectable()
export class TaxesTabFormService implements IFormService {
  formGroup: FormGroup<ITabTaxes>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private workOrder: IWorkOrder;
  private subscription$ = new Subscription();

  billingSalesTaxJurisdictions: Array<any>;
  paymentSalesTaxJurisdictions: Array<any>;

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkorderService
  ) {
  }

  get billingInfoesFormArray(): FormArray<IBillingInfo> {
    return this.formGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
  }

  get paymentInfoesFormArray(): FormArray<IPaymentInfo> {
    return this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
  }

  get billingSalesTaxesFormArray(): FormArray<IBillingSalesTax> {
    const formGroup = this.billingInfoesFormArray.at(0);
    return formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
  }

  get internalIdFormControl(): FormControl<number> {
    return this.formGroup.get('OrganizationIdInternal') as FormControl<number>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTaxes> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<ITabTaxes>({
      BillingInfoes: this.createBillingInfoFormArray(workorder),
      PaymentInfoes:
         this.createPaymentInfoFormArray(workorder.WorkOrderVersion.PaymentInfoes, workorder.WorkOrderVersion.ValidateComplianceDraft),
      OrganizationIdInternal: [workorder.OrganizationIdInternal],
      StatusId: workorder.WorkOrderVersion.StatusId,
      ValidateComplianceDraft: workorder.WorkOrderVersion.ValidateComplianceDraft
    });

    this.workOrder = workorder;

    this.calculateSalesTaxFormArray();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
    this.workOrder = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
      if (this.subscription$ && !this.subscription$.closed) {
        this.subscription$.unsubscribe();
        this.subscription$ = null;
      }
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const billingInfoes = this.billingInfoesFormArray.value;
    const paymentInfoes = this.paymentInfoesFormArray.value;

    billingInfoes.forEach(i => {
      workOrder.WorkOrderVersion.BillingInfoes.forEach(() => {
        this.billingInfoFormGroupToPartial(workOrder, i);
      });
    });

    if (paymentInfoes) {
      paymentInfoes.forEach(i => {
          workOrder.WorkOrderVersion.PaymentInfoes.forEach(() => {
            this.paymentInfoFormGroupToPartial(workOrder, i);
          });
      });
    }
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
      this.subscription$ = new Subscription();
    }

    this.formGroup.patchValue({
      OrganizationIdInternal: workorder.OrganizationIdInternal,
      StatusId: workorder.WorkOrderVersion.StatusId,
      ValidateComplianceDraft: workorder.WorkOrderVersion.ValidateComplianceDraft
    }, { emitEvent: false });

    this.updatePaymentInfoForm(workorder);
    this.updateBillingInfoForm(workorder);
    this.workOrder = workorder;

    this.calculateSalesTaxFormArray();
  }

  updatePaymentInfoForm(workorder: IWorkOrder): void {
    const paymentInfoFormArray = this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
    this.updatePaymentInfoFormArray(paymentInfoFormArray, workorder.WorkOrderVersion.PaymentInfoes, workorder.WorkOrderVersion.ValidateComplianceDraft);
  }

  updateBillingInfoForm(workorder: IWorkOrder) {
    this.workOrder = workorder;
    const billingInfoFormArray = this.formGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
    this.updateBillingInfoFormArray(billingInfoFormArray, workorder);
    this.updateBillingSalesTaxesFormArray(this.billingSalesTaxesFormArray, this.workOrder.WorkOrderVersion.BillingInfoes[0].BillingSalesTaxes, this.workOrder.WorkOrderVersion.ValidateComplianceDraft);
  }

  billingInfoFormGroupToPartial(workOrder: IWorkOrder, billingInfo: IBillingInfo) {
    const index = workOrder.WorkOrderVersion.BillingInfoes.findIndex(x => x.Id === billingInfo.Id);
    workOrder.WorkOrderVersion.BillingInfoes[index] = {
      ...workOrder.WorkOrderVersion.BillingInfoes[index],
      SubdivisionIdSalesTax: billingInfo.SubdivisionIdSalesTax,
      JurisdictionId: billingInfo.JurisdictionId,
      BillingSalesTaxes: billingInfo.BillingSalesTaxes
    };
    return workOrder;
  }

  paymentInfoFormGroupToPartial(workOrder: IWorkOrder, paymentInfo: IPaymentInfo) {
    const index = workOrder.WorkOrderVersion.PaymentInfoes.findIndex(x => x.Id === paymentInfo.Id);
    workOrder.WorkOrderVersion.PaymentInfoes[index] = {
      ...workOrder.WorkOrderVersion.PaymentInfoes[index],
      SubdivisionIdSalesTax: paymentInfo.ApplySalesTax ? paymentInfo.SubdivisionIdSalesTax : null,
      JurisdictionId: paymentInfo.ApplySalesTax ? paymentInfo.JurisdictionId : null,
      ApplySalesTax: paymentInfo.ApplySalesTax,
      PaymentSalesTaxes: paymentInfo.PaymentSalesTaxes.map(entry => ({
        ...entry,
        IsApplied: paymentInfo.ApplySalesTax
      }))
    };
    return workOrder;
  }

  updatePaymentInfoSalesTax(paymentInfoId: number, paymentSalesTaxes: Array<IPaymentSalesTax>) {
    const paymentInfoes = this.paymentInfoesFormArray.value;
    const index = paymentInfoes.findIndex(info => info.Id === paymentInfoId);
    if (index > -1) {
      const formGroup = this.paymentInfoesFormArray.at(index);
      const paymentSalesTaxesFormArray = formGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
      this.updatePaymentSalesTaxFormArray(paymentSalesTaxesFormArray, paymentSalesTaxes);
    }
  }

  private createBillingInfoFormArray(workOrder: IWorkOrder): FormArray<IBillingInfo> {
    return this.fb.array<IBillingInfo>(
      workOrder.WorkOrderVersion.BillingInfoes.map((billingInfo: IBillingInfo) => this.createBillingInfoFormGroup(
        billingInfo,
        workOrder.WorkOrderVersion.ValidateComplianceDraft
      ))
    );
  }

  private createBillingInfoFormGroup(billingInfo: IBillingInfo, validateComplianceDraft: boolean): FormGroup<IBillingInfo> {
    return this.fb.group<IBillingInfo>({
      Id: [billingInfo.Id],
      OrganizationClientDisplayName: [billingInfo.OrganizationClientDisplayName],
      OrganizationIdClient: [billingInfo.OrganizationIdClient],
      SubdivisionIdSalesTax: [
        billingInfo.SubdivisionIdSalesTax,
        validateComplianceDraft
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'SubdivisionIdSalesTax', [
            Validators.required
          ])
          : null
      ],
      JurisdictionId: [billingInfo.JurisdictionId],
      BillingSalesTaxes: this.createBillingSalesTaxFormArray(billingInfo.BillingSalesTaxes, validateComplianceDraft)
    });
  }

  private createBillingSalesTaxFormArray(taxes: IBillingSalesTax[], validateComplianceDraft: boolean): FormArray<IBillingSalesTax> {
    return this.fb.array<IBillingSalesTax>(
      taxes.map((billingTax: IBillingSalesTax) => this.createBillingSalesTaxFormGroup(billingTax, validateComplianceDraft))
    );
  }

  private createBillingSalesTaxFormGroup(billingSalesTax: IBillingSalesTax, validateComplianceDraft: boolean): FormGroup<IBillingSalesTax> {
    return this.fb.group<IBillingSalesTax>({
      SalesTaxId: [billingSalesTax.SalesTaxId],
      DisplayName: [billingSalesTax.DisplayName],
      ratePercentage: [billingSalesTax.ratePercentage],
      hasNumber: [billingSalesTax.hasNumber],
      IsApplied: [
        billingSalesTax.IsApplied,
        validateComplianceDraft
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingSalesTaxes', 'IsApplied', [
            Validators.required
          ])
          : null
      ],
      Id: [billingSalesTax.Id]
    });
  }

  private createPaymentInfoFormArray(paymentInfoes: Array<IPaymentInfo>, validateComplianceDraft: boolean): FormArray<IPaymentInfo> {
    return this.fb.array<IPaymentInfo>(
      paymentInfoes.map((paymentInfo: IPaymentInfo) => this.createPaymentInfoFormGroup(paymentInfo, validateComplianceDraft))
    );
  }

  private createPaymentInfoFormGroup(paymentInfo: IPaymentInfo, validateComplianceDraft: boolean): FormGroup<IPaymentInfo> {
    return this.fb.group<IPaymentInfo>({
      Id: [paymentInfo.Id],
      OrganizationSupplierDisplayName: [paymentInfo.OrganizationSupplierDisplayName],
      IsOrganizationSupplierSubVendor: [paymentInfo.IsOrganizationSupplierSubVendor],
      IsOrganizationIndependentContractorRole: [paymentInfo.IsOrganizationIndependentContractorRole],
      IsOrganizationLimitedLiabilityCompanyRole: [paymentInfo.IsOrganizationLimitedLiabilityCompanyRole],
      OrganizationIdSupplier: [paymentInfo.OrganizationIdSupplier],
      OrganizationRoleTypeId: [paymentInfo.OrganizationRoleTypeId],
      SubdivisionIdSalesTax: [
        paymentInfo.SubdivisionIdSalesTax,
        validateComplianceDraft && paymentInfo.ApplySalesTax
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'SubdivisionIdSalesTax', [
            Validators.required
          ])
          : null
      ],
      JurisdictionId: [paymentInfo.JurisdictionId],
      ApplySalesTax: [paymentInfo.ApplySalesTax],
      PaymentSalesTaxes: this.createPaymentSalesTaxFormArray(paymentInfo.PaymentSalesTaxes)
    });
  }

  private createPaymentSalesTaxFormArray(taxes: IPaymentSalesTax[]): FormArray<IPaymentSalesTax> {
    return this.fb.array<IPaymentSalesTax>(
      taxes.map((paymentTax: IPaymentSalesTax) => this.createPaymentSalesTaxFormGroup(paymentTax))
    );
  }

  private createPaymentSalesTaxFormGroup(paymentSalesTax: IBillingSalesTax): FormGroup<IPaymentSalesTax> {
    return this.fb.group<IPaymentSalesTax>({
      SalesTaxId: [paymentSalesTax.SalesTaxId],
      DisplayName: [paymentSalesTax.DisplayName],
      ratePercentage: [paymentSalesTax.ratePercentage],
      hasNumber: [paymentSalesTax.hasNumber],
      IsApplied: [paymentSalesTax.IsApplied],
      Id: [paymentSalesTax.Id]
    });
  }

  private updateBillingInfoFormArray(
    formArray: FormArray<IBillingInfo>,
    workOrder: IWorkOrder
  ) {
    const billingInfoes = workOrder.WorkOrderVersion.BillingInfoes;
    const validateComplianceDraft = workOrder.WorkOrderVersion.ValidateComplianceDraft;

    if (formArray.length && billingInfoes.length) {
      billingInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IBillingInfo>;
        if (formGroup) {
          this.updateBillingInfoFormGroup(formGroup, item, validateComplianceDraft);
        } else {
          formArray.push(this.createBillingInfoFormGroup(
            item,
            validateComplianceDraft
          ));
        }
      });
      if (formArray.length > billingInfoes.length) {
        this.clearArray(formArray, billingInfoes.length);
      }
    } else if (billingInfoes.length) {
      const array = this.createBillingInfoFormArray(workOrder);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingInfoFormGroup(
    formGroup: FormGroup<IBillingInfo>,
    billingInfo: IBillingInfo,
    validateComplianceDraft: boolean
  ) {
    formGroup.get('SubdivisionIdSalesTax').setValidators(
      validateComplianceDraft
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'SubdivisionIdSalesTax', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: billingInfo.Id,
      OrganizationClientDisplayName: billingInfo.OrganizationClientDisplayName,
      OrganizationIdClient: billingInfo.OrganizationIdClient,
      SubdivisionIdSalesTax: billingInfo.SubdivisionIdSalesTax,
      JurisdictionId: billingInfo.JurisdictionId,
    }, { emitEvent: false });

    const billingSalesTaxesFormArray = formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
    this.updateBillingSalesTaxesFormArray(billingSalesTaxesFormArray, billingInfo.BillingSalesTaxes, validateComplianceDraft);
  }

  private updateBillingSalesTaxes(billingInfo: IBillingInfo, orgInternalId: number, validateComplianceDraft: boolean) {
    this.workOrderService.getBillingSalesTaxes(billingInfo, orgInternalId).then(saleTaxes => {
      this.billingSalesTaxJurisdictions = this.workOrderService.billingSalesTaxJurisdictions;
      this.updateBillingSalesTaxesFormArray(this.billingSalesTaxesFormArray, saleTaxes, validateComplianceDraft);
    });
  }

  private async calculateSalesTaxFormArray() {
    const internalId = this.internalIdFormControl.value;
    const promiseArray = this.billingInfoesFormArray.controls.map((group: FormGroup<IBillingInfo>) => this.calculateSalesTaxFormGroup(group, internalId));
    await Promise.all(promiseArray);

    const billingInfoFormGroup = this.billingInfoesFormArray.at(0);
    const billingSubdivisionIdSalesTaxControl = billingInfoFormGroup ? billingInfoFormGroup.get('SubdivisionIdSalesTax') : null;
    const billingJurisdictionIdControl = billingInfoFormGroup ? billingInfoFormGroup.get('JurisdictionId') : null;

    if (!this.subscription$ || this.subscription$.closed) {
      this.subscription$ = new Subscription();
    }
    if (billingSubdivisionIdSalesTaxControl && this.workOrder) {
      this.subscription$.add(billingSubdivisionIdSalesTaxControl.valueChanges.pipe(
        startWith(billingSubdivisionIdSalesTaxControl.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(([prev, next]) => {
        if (next !== prev) {
          this.workOrder.WorkOrderVersion.BillingInfoes[0].SubdivisionIdSalesTax = next;
          this.workOrder.WorkOrderVersion.BillingInfoes[0].JurisdictionId = null;
          billingJurisdictionIdControl.setValue(null);
          this.updateBillingSalesTaxes(
            this.workOrder.WorkOrderVersion.BillingInfoes[0],
            this.internalIdFormControl.value,
            this.workOrder.WorkOrderVersion.ValidateComplianceDraft
          );
        }
      }));
    }
    if (billingJurisdictionIdControl && this.workOrder) {
      this.subscription$.add(billingJurisdictionIdControl.valueChanges.pipe(
        startWith(billingJurisdictionIdControl.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(([prev, next]) => {
        if (next !== prev) {
          this.workOrder.WorkOrderVersion.BillingInfoes[0].JurisdictionId = next;
          this.updateBillingSalesTaxes(
            this.workOrder.WorkOrderVersion.BillingInfoes[0],
            this.internalIdFormControl.value,
            this.workOrder.WorkOrderVersion.ValidateComplianceDraft
          );
        }
      }));
    }

    const paymentInfoFormArray = this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
    paymentInfoFormArray.controls.forEach((group, index) => {
      this.subscription$.add(this.getSubdivisionSalesTaxListener(group as FormGroup<IPaymentInfo>, index));
      this.subscription$.add(this.getJurisdictionIdListener(group as FormGroup<IPaymentInfo>, index));
    });
  }

  private getSubdivisionSalesTaxListener(formGroup: FormGroup<IPaymentInfo>, index: number) {
    const initialValue = formGroup ? formGroup.get('SubdivisionIdSalesTax').value : null;
    return formGroup.get('SubdivisionIdSalesTax').valueChanges
      .pipe(
        startWith(initialValue),
        distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(async (subsdivisionIdSalesTax) => {
        const paymentInfo = formGroup.value;
        paymentInfo.SubdivisionIdSalesTax = subsdivisionIdSalesTax;
        if (initialValue !== subsdivisionIdSalesTax) {
          paymentInfo.JurisdictionId = null;
          formGroup.get('JurisdictionId').setValue(null);
        }
        const paymentSalesTaxes = await this.workOrderService.getPaymentSalesTaxes(
          paymentInfo,
          index,
          this.workOrder.UserProfileIdWorker,
          this.workOrder.workerProfileTypeId
        );
        this.paymentSalesTaxJurisdictions = this.workOrderService.paymentSalesTaxJurisdictions;

        this.updatePaymentInfoSalesTax(
          paymentInfo.Id,
          paymentSalesTaxes,
        );
      });
  }

  private getJurisdictionIdListener(formGroup: FormGroup<IPaymentInfo>, index: number) {
    const paymentJurisdictionIdControl = formGroup ? formGroup.get('JurisdictionId') : null;
    return paymentJurisdictionIdControl.valueChanges.pipe(
        startWith(paymentJurisdictionIdControl.value),
        distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(async (jurisdictionId) => {
        const paymentInfo = formGroup.value;
        paymentInfo.JurisdictionId = jurisdictionId;
        const paymentSalesTaxes = await this.workOrderService.getPaymentSalesTaxes(
          paymentInfo,
          index,
          this.workOrder.UserProfileIdWorker,
          this.workOrder.workerProfileTypeId
        );
        this.paymentSalesTaxJurisdictions = this.workOrderService.paymentSalesTaxJurisdictions;

        this.updatePaymentInfoSalesTax(
          paymentInfo.Id,
          paymentSalesTaxes,
        );
      });
  }

  private async calculateSalesTaxFormGroup(
    formGroup: FormGroup<IBillingInfo>,
    orgInternalId: number
  ): Promise<void> {
    const subdivisionIdSalesTax = formGroup.get('SubdivisionIdSalesTax').value;
    const billingSalesTaxFormArray = formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
    const billingSalesTaxesFormValue = billingSalesTaxFormArray?.value || [];
    const needPercentage = billingSalesTaxFormArray?.value
    && billingSalesTaxFormArray.value.length ? billingSalesTaxFormArray.value.some(x => !x.ratePercentage && x.ratePercentage !== 0) : false;

    if (needPercentage && subdivisionIdSalesTax && orgInternalId) {
      const response: Array<IBillingSalesTax> = await this.workOrderService.getBillingSalesTaxes(
        formGroup.value,
        orgInternalId,
        this.workOrder.WorkOrderVersion.ValidateComplianceDraft
      );
      this.billingSalesTaxJurisdictions = this.workOrderService.billingSalesTaxJurisdictions;

      const billingSalesTaxes: Array<IBillingSalesTax> = [];
      response.forEach(salesTax => {
        const existingSalesTax = billingSalesTaxesFormValue.find(x => x.SalesTaxId === salesTax.SalesTaxId);
        billingSalesTaxes.push({
          Id: existingSalesTax?.Id || 0,
          SalesTaxId: salesTax.SalesTaxId,
          DisplayName: salesTax.DisplayName,
          ratePercentage: salesTax.ratePercentage,
          hasNumber: salesTax.hasNumber,
          IsApplied: existingSalesTax?.IsApplied ?? salesTax.IsApplied
        });
      });

      this.updateBillingSalesTaxesFormArray(billingSalesTaxFormArray, billingSalesTaxes, this.workOrder.WorkOrderVersion.ValidateComplianceDraft);
    }
  }

  private updateBillingSalesTaxesFormArray(
    formArray: FormArray<IBillingSalesTax>,
    taxes: IBillingSalesTax[],
    validateComplianceDraft: boolean
  ) {
    if (formArray.length && taxes.length) {
      taxes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IBillingSalesTax>;
        if (formGroup) {
          this.updateBillingSalesTaxFormGroup(formGroup, item, validateComplianceDraft);
        } else {
          formArray.push(this.createBillingSalesTaxFormGroup(item, validateComplianceDraft));
        }
      });
      if (formArray.length > taxes.length) {
        this.clearArray(formArray, taxes.length);
      }
    } else if (taxes.length) {
      const array = this.createBillingSalesTaxFormArray(taxes, validateComplianceDraft);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingSalesTaxFormGroup(
    formGroup: FormGroup<IBillingSalesTax>,
    billingSalesTax: IBillingSalesTax,
    validateComplianceDraft: boolean
  ) {
    formGroup.get('IsApplied').setValidators(
      validateComplianceDraft
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingSalesTaxes', 'IsApplied', [
          Validators.required
        ])
        : null
    );
    formGroup.patchValue({
      SalesTaxId: billingSalesTax.SalesTaxId,
      DisplayName: billingSalesTax.DisplayName,
      ratePercentage: billingSalesTax.ratePercentage,
      hasNumber: billingSalesTax.hasNumber,
      IsApplied: billingSalesTax.IsApplied,
      Id: billingSalesTax.Id
    }, { emitEvent: false });
  }

  private updatePaymentInfoFormArray(
    formArray: FormArray<IPaymentInfo>,
    paymentInfoes: Array<IPaymentInfo>,
    validateComplianceDraft: boolean
  ) {
    if (formArray.length && paymentInfoes.length) {
      paymentInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentInfo>;
        if (formGroup) {
          this.updatePaymentInfoFormGroup(formGroup, item, validateComplianceDraft);
        } else {
          formArray.push(this.createPaymentInfoFormGroup(item, validateComplianceDraft));
        }
      });
      if (formArray.length > paymentInfoes.length) {
        this.clearArray(formArray, paymentInfoes.length);
      }
    } else if (paymentInfoes.length) {
      const array = this.createPaymentInfoFormArray(paymentInfoes, validateComplianceDraft);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentInfoFormGroup(
    formGroup: FormGroup<IPaymentInfo>,
    paymentInfo: IPaymentInfo,
    validateComplianceDraft: boolean
  ) {
    formGroup.get('SubdivisionIdSalesTax').setValidators(
      validateComplianceDraft && paymentInfo.ApplySalesTax
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'SubdivisionIdSalesTax', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: paymentInfo.Id,
      OrganizationSupplierDisplayName: paymentInfo.OrganizationSupplierDisplayName,
      IsOrganizationSupplierSubVendor: paymentInfo.IsOrganizationSupplierSubVendor,
      IsOrganizationIndependentContractorRole: paymentInfo.IsOrganizationIndependentContractorRole,
      IsOrganizationLimitedLiabilityCompanyRole: paymentInfo.IsOrganizationLimitedLiabilityCompanyRole,
      OrganizationIdSupplier: paymentInfo.OrganizationIdSupplier,
      OrganizationRoleTypeId: paymentInfo.OrganizationRoleTypeId,
      SubdivisionIdSalesTax: paymentInfo.SubdivisionIdSalesTax,
      JurisdictionId: paymentInfo.JurisdictionId,
      ApplySalesTax: paymentInfo.ApplySalesTax
    }, { emitEvent: false });

    const paymentSalesTaxesFormArray = formGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
    this.updatePaymentSalesTaxFormArray(paymentSalesTaxesFormArray, paymentInfo.PaymentSalesTaxes);

  }

  private updatePaymentSalesTaxFormArray(
    formArray: FormArray<IPaymentSalesTax>,
    taxes: IPaymentSalesTax[],
  ) {
    if (formArray.length && taxes.length) {
      taxes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentSalesTax>;
        if (formGroup) {
          this.updatePaymentSalesTaxFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentSalesTaxFormGroup(item));
        }
      });
      if (formArray.length > taxes.length) {
        this.clearArray(formArray, taxes.length);
      }
    } else if (taxes.length) {
      const array = this.createPaymentSalesTaxFormArray(taxes);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentSalesTaxFormGroup(
    formGroup: FormGroup<IPaymentSalesTax>,
    paymentSalesTax: IPaymentSalesTax,
  ) {
    formGroup.patchValue({
      SalesTaxId: paymentSalesTax.SalesTaxId,
      DisplayName: paymentSalesTax.DisplayName,
      ratePercentage: paymentSalesTax.ratePercentage,
      hasNumber: paymentSalesTax.hasNumber,
      IsApplied: paymentSalesTax.IsApplied,
      Id: paymentSalesTax.Id
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IBillingInfo | IBillingSalesTax | IPaymentInfo | IPaymentSalesTax>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }
}
