import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

// Angular
import { forEach, find, cloneDeep } from 'lodash';

import { PhxDialogComponent } from '@common/components/phx-dialog/phx-dialog.component';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { PhxButton } from '@common/model';

import { PhxConstants, CustomFieldService } from '../../../common';
import { PhxDialogComponentConfigModel } from '../../../common/components/phx-dialog/phx-dialog.component.model';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { FormGroup, FormArray, FormBuilder, AbstractControl } from '../../../common/ngx-strongly-typed-forms/model';
// common
import { HashModel } from '../../../common/utility/hash-model';
import {
  IFormGroupSetup,
  IReadOnlyStorage,
  IWorkOrder,
  IPaymentInfoDetails,
  ISourceDeductions,
  ITabEarningsAndDeductions,
  IPaymentSourceDeductions
} from '../../models/workorder.interface';
import { EarningsDeductionsTabFormService, WorkOrderFormService, WorkorderService } from '../../services';

// workorder

@Component({
  selector: 'app-workorder-tab-earnings-deductions',
  templateUrl: './workorder-tab-earnings-deduction.component.html'
})
export class WorkorderTabEarningsDeductionsComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  formGroup: FormGroup<ITabEarningsAndDeductions>;

  @ViewChild('phxDialogComponent', { static: false })
  phxDialogComponent: PhxDialogComponent;

  @ViewChild('modal', { static: true })
  modal: PhxModalComponent;

  subdivisionIdSourceDeductionId: number;
  sourceDeductionChanged = false;
  workOrder: IWorkOrder;
  sourceDeductionPreviousState: ISourceDeductions;
  formGroupSetup: IFormGroupSetup;
  phxDialogComponentConfigModel: PhxDialogComponentConfigModel;
  phxConstants = PhxConstants;
  listUserProfileWorker: Array<any> = [];
  modalFabButtons: PhxButton[] = [];
  firstModelInitialized = false;
  loadedUserProfileWorker$ = new Subject<boolean>();
  
  showCanadianNonResidentWithholdingTax = false;

  oDataParamsForFederalTax = oreq
    .request()
    .withSelect(['Id', 'TaxVersionStatusId', 'EffectiveDate'])
    .url();
  oDataParamsForProvincialTaxVersionTaxType = oreq
    .request()
    .withSelect([
      'Id',
      'ProvincialTaxHeaderId',
      'ProvincialTaxHeaderSubdivisionId',
      'ProvincialTaxVersionId',
      'ProvincialTaxVersionEffectiveDate',
      'SourceDeductionTypeId',
      'IsEligible',
      'EmployeeRatePercentage'
    ])
    .withFilter(
      oreq
      .filter('EmployeeRatePercentage').gt(0)
      .or()
      .filter('IsEligible').eq(true)
    )
    .url();

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private workorderService: WorkorderService,
    private customFieldService: CustomFieldService,
    private formBuilder: FormBuilder,
    private commonListsObservableService: CommonListsObservableService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService
  ) {
    super();
  }

  onIsUseUserProfileWorkerSourceDeductionChangeEvent(value: boolean) {
    if (value) {
      this.modal.show();
    } else {
      this.getSubdivisionSourceDeduction();
    }
  }

  ngOnInit() {
    this.formGroup = this.earningsDeductionsTabFormService.formGroup;

    this.loadedUserProfileWorker$.asObservable()
      .pipe(takeUntil(this.isDestroyed$), take(1))
      .subscribe({
        complete: () => {
          const psds = this.tabEarningsAndDeductionsFormGroup.get('PaymentSourceDeductions') as FormArray<IPaymentSourceDeductions>;
          // Fixes the Taxes section not loading the first time for new work orders 
          // the first time this tab is loaded when 'Use deductions from Worker Profile' defaults to false.
          if (!this.isUseUserProfileWorkerSourceDeductionFormControl.value &&
              (!psds?.length || !psds.value.length)) {
            this.getSubdivisionSourceDeduction();
          }
        }
      });

    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.workOrder = cloneDeep(workorder);
          this.detectSourceDeductionChange();
          this.sourceDeductionPreviousState = {
            SubdivisionIdSourceDeduction: this.workOrder.WorkOrderVersion.PaymentInfoes[0].SubdivisionIdSourceDeduction,
            IsUseUserProfileWorkerSourceDeduction: this.workOrder.WorkOrderVersion.PaymentInfoes[0].IsUseUserProfileWorkerSourceDeduction
          };
          if (!this.firstModelInitialized) {
            this.firstModelInitialized = true;
            this.updateIncomeTaxFields();
          }
          if (!this.listUserProfileWorker.length) {
            this.getUserProfileWorker();
          }
          if (this.listUserProfileWorker.length && this.sourceDeductionChanged) {
            this.getSubdivisionSourceDeduction();
          }
        }
      });

    this.subdivisionIdSourceDeductionControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((value: number) => {
        this.workOrder.WorkOrderVersion.PaymentInfoes[0].SubdivisionIdSourceDeduction = value;
        // Sometimes the earningsDeductionsTabFormService form was getting out of sync with 
        // the form in this component, which would cause the taxes section to get out of sync
        // when changing to a different tab and then back to this one.
        this.earningsDeductionsTabFormService.updateForm(this.workOrder);
      });
      
    if (this.cNRWithholdingTaxIsAppliedFormControl.value !== null) {
      this.showCanadianNonResidentWithholdingTax = true;
    }
    this.cNRWithholdingTaxIsAppliedFormControl.valueChanges
    .pipe(takeUntil(this.isDestroyed$))
    .subscribe((cNRWithholdingTaxIsApplied) => {
      this.showCanadianNonResidentWithholdingTax = cNRWithholdingTaxIsApplied !== null;
    });
    
    this.modalFabButtons = [
      {
        icon: 'done',
        tooltip: 'Yes',
        btnType: 'primary',
        action: () => {
          this.modal.hide();
          this.onClickIsUseUserProfileWorkerSourceDeductionSuccess();
        }
      },
      {
        icon: 'library_add',
        tooltip: 'No',
        btnType: 'default',
        action: () => {
          this.modal.hide();
          this.onClickIsUseUserProfileWorkerSourceDeductionReject();
        }
      }
    ];
    this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
  }

  detectSourceDeductionChange() {
    if (this.sourceDeductionPreviousState &&
        (this.sourceDeductionPreviousState.SubdivisionIdSourceDeduction !== this.workOrder.WorkOrderVersion.PaymentInfoes[0].SubdivisionIdSourceDeduction ||
         (this.sourceDeductionPreviousState.IsUseUserProfileWorkerSourceDeduction !== this.workOrder.WorkOrderVersion.PaymentInfoes[0].IsUseUserProfileWorkerSourceDeduction)
    ))
    {
      this.sourceDeductionChanged = true;
    } else {
      this.sourceDeductionChanged = false;
    }
  }

  public onClickIsUseUserProfileWorkerSourceDeductionSuccess() {
    const control = this.earningsDeductionsTabFormService.getPaymentSourceDeductionsFormArray([]);
    this.tabEarningsAndDeductionsFormGroup.setControl('PaymentSourceDeductions', control);
  }

  onClickIsUseUserProfileWorkerSourceDeductionReject() {
    this.isUseUserProfileWorkerSourceDeductionFormControl.patchValue(false, { emit: false });
    this.earningsDeductionsTabFormService.setPaymentInfoSourceDeductions(0);
  }

  getUserProfileWorker() {
    // ASYNC CALL HERE, POTENTIAL RACE CONDITION
    this.commonListsObservableService
      .getDetailedUserProfileWorkers$(this.workOrder.UserProfileIdWorker)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        this.listUserProfileWorker = list;
        // This gets called twice when the page initially loads,
        // the first time the list is empty.
        if (list.length) {
          this.loadedUserProfileWorker$.next(true);
          this.loadedUserProfileWorker$.complete();
        }
    });
  }

  get tabEarningsAndDeductionsFormGroup(): FormGroup<IPaymentInfoDetails> {
    const paymentInfoes = this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfoDetails>;
    return paymentInfoes.at(0) as FormGroup<IPaymentInfoDetails>;
  }

  get sourceDeductionsFormGroup(): FormGroup<ISourceDeductions> {
    return this.tabEarningsAndDeductionsFormGroup.get('SourceDeductions') as FormGroup<ISourceDeductions>;
  }

  get subdivisionIdSourceDeductionControl(): AbstractControl<number> {
    return this.sourceDeductionsFormGroup.get('SubdivisionIdSourceDeduction');
  }

  get isUseUserProfileWorkerSourceDeductionFormControl(): AbstractControl<boolean> {
    return this.sourceDeductionsFormGroup.get('IsUseUserProfileWorkerSourceDeduction');
  }

  public get cNRWithholdingTaxIsAppliedFormControl() {
    return this.earningsDeductionsTabFormService.cNRWithholdingTaxIsAppliedFormControl;
  }

  getSubdivisionSourceDeduction() {
    const tabEarningsAndDeductionsFormGroup = this.tabEarningsAndDeductionsFormGroup;
    if (tabEarningsAndDeductionsFormGroup.get('OrganizationIdSupplier').value !== null) {
      this.phxDialogComponentConfigModel = {
        HeaderTitle: 'SubDivisionSourceDeductions must apply only for NULL OrganizationIdSupplier',
        BodyMessage: 'Find the message variable',
        Buttons: [
          {
            Id: 1,
            SortOrder: 1,
            CheckValidation: true,
            Name: 'Ok',
            Class: 'btn-primary',
            ClickEvent: () => {
              this.dialogActionCallBackObButtonClick();
            }
          }
        ],
        ObjectDate: null,
        ObjectComment: null
      };
      this.phxDialogComponent.open();
    }
    
    const subdivisionIdSourceDeduction = this.subdivisionIdSourceDeductionControl.value || null;
    let sourceDeductions: any[] = [];

    const worker = this.getWorker();
    const profileTypeSourceDeductions = [PhxConstants.UserProfileType.WorkerTemp, PhxConstants.UserProfileType.WorkerCanadianSp];
    if (worker && !worker.OrganizationId && profileTypeSourceDeductions.includes(this.workOrder.workerProfileTypeId)) {
      sourceDeductions = worker.UserProfileWorkerSourceDeductions;
    }

    if (
      subdivisionIdSourceDeduction &&
      sourceDeductions?.length
    ) {
      const control = this.earningsDeductionsTabFormService.getPaymentSourceDeductionsFormArray([]);
      tabEarningsAndDeductionsFormGroup.setControl('PaymentSourceDeductions', control);

      combineLatest([
        this.workorderService.getActiveCurrentlyEffectiveFederalTaxVersionBySubdivisionId(
          subdivisionIdSourceDeduction,
          this.oDataParamsForFederalTax
        ),
        this.workorderService.getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId(
          subdivisionIdSourceDeduction,
          this.oDataParamsForProvincialTaxVersionTaxType
        )
      ]).pipe(takeUntil(this.isDestroyed$))
        .subscribe(response => {
          const sourceDeductionTypesFederal = [PhxConstants.SourceDeductionType.FederalTax, PhxConstants.SourceDeductionType.AdditionalTax];
          const paymentSourceDeductions = tabEarningsAndDeductionsFormGroup.get('PaymentSourceDeductions') as FormArray<IPaymentSourceDeductions>;

          if (response?.[0]) {
            forEach(sourceDeductions, userProfileWorkerSourceDeduction => {
              if (sourceDeductionTypesFederal.includes(userProfileWorkerSourceDeduction.SourceDeductionTypeId)) {
                const paymentSourceDeduction: IPaymentSourceDeductions = {
                  SourceDeductionTypeId: userProfileWorkerSourceDeduction.SourceDeductionTypeId,
                  IsApplied: userProfileWorkerSourceDeduction.IsApplied,
                  IsOverWritable: userProfileWorkerSourceDeduction.SourceDeductionTypeId === PhxConstants.SourceDeductionType.AdditionalTax,
                  ToShow: true,
                  RatePercentage: userProfileWorkerSourceDeduction.RatePercentage === 0 ? null : userProfileWorkerSourceDeduction.RatePercentage,
                  RateAmount: userProfileWorkerSourceDeduction.RateAmount === 0 ? null : userProfileWorkerSourceDeduction.RateAmount
                };
                paymentSourceDeductions.push(this.earningsDeductionsTabFormService.getPaymentSourceDeductionsFormGroup(paymentSourceDeduction));
              }
            });
          }
          if (response?.[1]) {
            forEach(sourceDeductions, userProfileWorkerSourceDeduction => {
              forEach(response[1].Items, taxType => {
                if (
                  userProfileWorkerSourceDeduction.SourceDeductionTypeId === taxType.SourceDeductionTypeId &&
                  !sourceDeductionTypesFederal.includes(userProfileWorkerSourceDeduction.SourceDeductionTypeId)
                ) {
                  const paymentSourceDeduction: IPaymentSourceDeductions = {
                    SourceDeductionTypeId: userProfileWorkerSourceDeduction.SourceDeductionTypeId,
                    IsApplied: taxType.IsEligible && userProfileWorkerSourceDeduction.IsApplied,
                    IsOverWritable: taxType.IsEligible,
                    ToShow: taxType.IsEligible,
                    RatePercentage: userProfileWorkerSourceDeduction.RatePercentage === 0 ? null : userProfileWorkerSourceDeduction.RatePercentage,
                    RateAmount: userProfileWorkerSourceDeduction.RateAmount === 0 ? null : userProfileWorkerSourceDeduction.RateAmount
                  };
                  paymentSourceDeductions.push(this.earningsDeductionsTabFormService.getPaymentSourceDeductionsFormGroup(paymentSourceDeduction));
                }
              });
            });
          }
        });

    } else {
      const control = this.earningsDeductionsTabFormService.getPaymentSourceDeductionsFormArray([]);
      tabEarningsAndDeductionsFormGroup.setControl('PaymentSourceDeductions', control);
    }
  }

  dialogActionCallBackObButtonClick() {
    this.phxDialogComponent.close();
  }

  get workerProfileTypeIdControl(): AbstractControl<number> {
    return this.formGroup.get('WorkerProfileTypeId');
  }

  get workerContactIdControl(): AbstractControl<number> {
    return this.formGroup.get('WorkerContactId');
  }

  getWorker() {
    let worker = null;
    if (this.workOrder.UserProfileIdWorker > 0) {
      worker = find(this.listUserProfileWorker, w => {
        return w.Id === this.workOrder.UserProfileIdWorker;
      });
      if (worker) {
        this.workerProfileTypeIdControl.patchValue(worker.ProfileTypeId);
        this.workerContactIdControl.patchValue(worker.ContactId);
      } else {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Wrong Worker User Profile',
          BodyMessage: 'Worker User Profile with id "' + this.workOrder.UserProfileIdWorker + '" is broken or does not exists',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Ok',
              Class: 'btn-primary',
              ClickEvent: () => {
                this.dialogActionCallBackObButtonClick();
              }
            }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
        this.workerProfileTypeIdControl.patchValue(null);
        worker = null;
      }
    } else {
      this.workerProfileTypeIdControl.patchValue(null);
      this.workerContactIdControl.patchValue(null);
    }
    return worker;
  }

  updateIncomeTaxFields() {
    forEach(this.formGroup.get('PaymentInfoes').value, (sourceDeductionsAndTaxes: IPaymentInfoDetails, index: number) => {
      const paymentInfoes = this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfoDetails>;
      const paymentInfo = paymentInfoes.at(index) as FormGroup<IPaymentInfoDetails>;
      if (sourceDeductionsAndTaxes.SubdivisionIdSourceDeduction) {
        // ASYNC CALL HERE, POTENTIAL RACE CONDITION
        this.workorderService
          .getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId(sourceDeductionsAndTaxes.SubdivisionIdSourceDeduction, this.oDataParamsForProvincialTaxVersionTaxType)
          .subscribe((response: any) => {

            const paymentSourceDeductions = paymentInfo.controls.PaymentSourceDeductions as FormArray<IPaymentSourceDeductions>;

            forEach(sourceDeductionsAndTaxes.PaymentSourceDeductions, (paymentSourceDeduction: IPaymentSourceDeductions, j: number) => {
              const paymentSourceDeductionFormGroup: FormGroup<IPaymentSourceDeductions> = paymentSourceDeductions.at(j) as FormGroup<IPaymentSourceDeductions>;
              if (!paymentSourceDeductionFormGroup) {
                return;
              }

              const sourceDeductionTypeId = paymentSourceDeductionFormGroup.get('SourceDeductionTypeId');

              forEach(response.Items, taxType => {
                if (sourceDeductionTypeId.value === taxType.SourceDeductionTypeId) {
                  if (sourceDeductionTypeId.value === PhxConstants.SourceDeductionType.FederalTax) {
                    paymentSourceDeductionFormGroup.get('IsOverWritable').patchValue(false);
                    paymentSourceDeductionFormGroup.get('ToShow').patchValue(true);
                  } else if (sourceDeductionTypeId.value === PhxConstants.SourceDeductionType.AdditionalTax) {
                    paymentSourceDeductionFormGroup.get('IsOverWritable').patchValue(true);
                    paymentSourceDeductionFormGroup.get('ToShow').patchValue(true);
                  } else {
                    paymentSourceDeductionFormGroup.get('IsOverWritable').patchValue(taxType.IsEligible);
                    paymentSourceDeductionFormGroup.get('ToShow').patchValue(taxType.IsEligible);
                  }
                }
              });
            });
          });
      }
    });
  }

  filterOnPaymentSourceDeductionsByIncomeTaxes(controls: any) {
    return controls.filter(
      (a: any) =>
        a.value.SourceDeductionTypeId === PhxConstants.SourceDeductionType.FederalTax ||
        a.value.SourceDeductionTypeId === PhxConstants.SourceDeductionType.Provincial ||
        a.value.SourceDeductionTypeId === PhxConstants.SourceDeductionType.AdditionalTax
    );
  }

  filterOnPaymentSourceDeductionsByPayrollTaxes(controls: any) {
    return controls.filter(
      (a: any) =>
        a.value.SourceDeductionTypeId !== PhxConstants.SourceDeductionType.FederalTax &&
        a.value.SourceDeductionTypeId !== PhxConstants.SourceDeductionType.Provincial &&
        a.value.SourceDeductionTypeId !== PhxConstants.SourceDeductionType.AdditionalTax
    );
  }

}
