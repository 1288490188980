import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PhxModalComponent } from 'src/app/common/components/phx-modal/phx-modal.component';
import { IRoot, IWorkOrder } from '../../models';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiService, CodeValueGroups, CodeValueService, CommonService, DialogService, PhxConstants } from 'src/app/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogResultType } from 'src/app/common/model';
import { from } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-release-sick-pay-modal',
  templateUrl: './release-sick-pay-modal.component.html',
  styleUrls: ['./release-sick-pay-modal.component.less']
})
export class ReleaseSickPayModalComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() workOrder: IRoot;
  @Input() workOrderDto: IWorkOrder;
  @ViewChild(PhxModalComponent) modal: PhxModalComponent;

  workorderId: string;
  userProfileIdWorker: number;
  profileTypeId: number;
  internalCompany: string;

  listUserProfileWorker = [];
  organizationInternalList = [];
  profileTypeList = [];
  validationMessages: string[] = [];

  form: FormGroup;
  isSubmitting = false;

  currentRate: string;
  currentRateUnitId: number;
  currentCurrencyRateId: number;
  currentTotal: string = '';

  private minFromDate: Date;
  private maxToDate: Date;
  private releaseAmountLimit = 0;

  readonly CodeValueGroups = CodeValueGroups;
  readonly PhxConstants = PhxConstants;

  constructor(
    private formBuilder: FormBuilder,
    private commonListsObservableService: CommonListsObservableService,
    private codeValueService: CodeValueService,
    private dialogService: DialogService,
    private apiService: ApiService,
    private commonService: CommonService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProfileTypeList();
    this.getListOrganizationInternal();
    this.initForm();

    this.releaseAmountLimit = parseFloat(environment.sickPayReleaseAmountLimit);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workOrderDto) {
      this.updateWoValues();
    }
  }

  openModal() {
    this.validationMessages = [];
    this.currentTotal = '';
    this.form.reset();
    this.modal.show();
  }

  onCancel() {
    this.modal.hide();
  }

  onSubmit() {
    if (this.isFormValid()) {
      if (parseFloat(this.currentTotal) > this.releaseAmountLimit) {
        this.dialogService.confirmDialog('Release Sick Pay', `You have entered more than $${this.releaseAmountLimit} for the sick pay amount.  Do you want to proceed?`).then(button => {
          if (button === DialogResultType.Yes) {
            this.releaseSickPay();
          }
        });
      } else { this.releaseSickPay(); }
    }
  }

  private isFormValid(): boolean {
    this.validationMessages = [];
    const formFromDate = new Date(this.form.controls.fromDate.value);
    const formToDate = new Date(this.form.controls.toDate.value);

    if (formFromDate < this.minFromDate) {
      this.validationMessages.push('\'Work Period From\' must be equal to or after Work Order Start Date.');
    }
    if (formToDate > this.maxToDate) {
      this.validationMessages.push('\'Work Period To\' must be equal to or before Work Order End Date and/or Termination Date.');
    }
    if (formToDate < formFromDate) {
      this.validationMessages.push('\'Work Period To\' must be after \'Work Period From\'.');
    }

    return !(this.validationMessages.length > 0);
  }

  private releaseSickPay() {
    const request = {
      EntityIds: [this.workOrderDto.WorkOrderId],
      TransactionStartDate: this.form.controls.fromDate.value,
      TransactionEndDate: this.form.controls.toDate.value,
      UnitsToRelease: isNaN(parseFloat(this.form.controls.unitsToRelease.value)) ? 0 : parseFloat(this.form.controls.unitsToRelease.value)
    };
    this.isSubmitting = true;
    from(this.apiService.command('WorkOrderReleaseSickPay', request)).subscribe({
      next: () => {
        this.modal.hide();
        this.commonService.logSuccess('Sick Pay Release Submitted');
        this.isSubmitting = false;
      },
      error: (errorResponse: HttpErrorResponse) => {
        const validationMessages = this.commonService.parseResponseError(errorResponse);
        if (validationMessages.length > 0) {
          validationMessages.forEach(element => {
            this.validationMessages.push(element.Message);
          });
        }
        this.isSubmitting = false;
      }
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      unitsToRelease: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
    });

    this.form.controls.unitsToRelease.valueChanges.pipe(
      filter(value => !!value),
      takeUntil(this.isDestroyed$)
    ).subscribe(value => {
      const multiplier = isNaN(value) ? 0 : parseFloat(value);
      this.currentTotal = parseFloat(multiplier * parseFloat(this.currentRate) + '').toFixed(2);
    });
  }

  private updateWoValues() {
    this.workorderId = `${this.workOrderDto.AssignmentId}.${this.workOrderDto.WorkOrderVersion.WorkOrderNumber}.${this.workOrderDto.WorkOrderVersion.VersionNumber}`;
    this.internalCompany = this.organizationInternalList?.find(f => f.Id === this.workOrderDto.OrganizationIdInternal)?.DisplayText;

    if (this.userProfileIdWorker !== this.workOrderDto.UserProfileIdWorker) {
      this.getListUserProfileWorker(this.workOrderDto.UserProfileIdWorker);
    }

    const paymentCurrencyId = this.workOrderDto?.WorkOrderVersion?.PaymentInfoes?.[0].CurrencyId;
    const paymentInfoRates = (this.workOrderDto?.WorkOrderVersion?.PaymentInfoes.map(f => f.PaymentRates));
    const primaryRate = paymentInfoRates.find(f => f.find(q => q.RateTypeId === PhxConstants.RateType.Primary))?.[0];

    if (primaryRate) {
      this.currentRate = primaryRate.Rate;
      this.currentRateUnitId = primaryRate.RateUnitId;
      this.currentCurrencyRateId = paymentCurrencyId;
    }
  }

  private getListUserProfileWorker(userProfileIdWorker: number) {
    this.userProfileIdWorker = userProfileIdWorker;
    this.commonListsObservableService.getUserProfileWorkers$(userProfileIdWorker).pipe(
      filter(listUserProfileWorker => !!listUserProfileWorker?.length),
      takeUntil(this.isDestroyed$))
      .subscribe(listUserProfileWorker => this.listUserProfileWorker = listUserProfileWorker);
  }

  private getListOrganizationInternal() {
    this.commonListsObservableService.listOrganizationInternals$().pipe(
      filter(listOrganizationInternals => !!listOrganizationInternals?.length),
      takeUntil(this.isDestroyed$))
      .subscribe(listOrganizationInternals => {
        this.organizationInternalList = listOrganizationInternals;
      });
  }

  private getProfileTypeList() {
    this.profileTypeList = this.codeValueService.getCodeValues(CodeValueGroups.ProfileType, true);
  }
}
