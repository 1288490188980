<form [formGroup]="inputFormGroup" *ngIf="inputFormGroup">
  <p class="form-control-static" *ngIf="inputFormGroup.controls.OrganizationIdClient.value>0">
    <a routerLink="/next/organization/{{inputFormGroup.controls.OrganizationIdClient.value}}/details"
    [appCollectClickDataAnalytics]="{ action: 'View Client Organization', feature: 'Work Order', type: 'Redirection link' }"
    >
      {{inputFormGroup.controls.OrganizationIdClient.value |
        lookup:html.commonLists.listOrganizationClient:'Id':'DisplayText'}}
      - {{ inputFormGroup.controls.OrganizationIdClient.value }}</a>
  </p>

  <!-- WE DO NOT ALLOW CHANGE OF CLIENT COMPANY ONCE WO IS CREATED IN DRAFT
  DOUBLE CHECK WO TEMPLATE, IF NOT USED, CAN BE REMOVED -->
  <ng-container
      *ngIf="!inputFormGroup.controls.OrganizationIdClient.value">
    <phx-form-control labelText="Client Company" [control]="inputFormGroup.controls.OrganizationIdClient"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{inputFormGroup.controls.OrganizationIdClient.value}}">
      <app-phx-select-box [items]="html.commonLists.listOrganizationClient" textField="DisplayText" valueField="Id"
                          [formControl]="inputFormGroup.controls.OrganizationIdClient"
                          (valueChanged)="onChangeOrganizationIdClient()">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>

  <ng-container *ngIf="inputFormGroup.controls.OrganizationIdClient.value>0">
    <phx-form-control labelText="Client Company" [control]="inputFormGroup.controls.OrganizationIdClient"
                      [editable]="true" viewModeText="">
      <div class="col-lg-12 padding-0">
        <p class="form-control-static">
          {{inputFormGroup.controls.OrganizationIdClient.value | lookup:
            html.commonLists.listOrganizationClient:'Id':'DisplayText'
          }} - {{inputFormGroup.controls.OrganizationIdClient.value}}
        </p>
      </div>
    </phx-form-control>
  </ng-container>

  <phx-form-control labelText="Client Manager" [control]="inputFormGroup.controls.UserProfileIdClient"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes','UserProfileIdClient')"
                    viewModeText="{{inputFormGroup.controls.UserProfileIdClient.value |lookup: html.commonLists.ProfilesListForBillingOrganization:'Id':'Contact.FullName'}} -
      {{inputFormGroup.controls.UserProfileIdClient.value}}">
    <app-phx-select-box [items]="html.commonLists.ProfilesListForBillingOrganization" textField="DisplayValue"
                        valueField="Id" [formControl]="inputFormGroup.controls.UserProfileIdClient">
    </app-phx-select-box>
  </phx-form-control>

  <ng-container *ngIf="!billingInfoIndex">
    <phx-form-control [control]="inputFormGroup.controls.Hours" labelText="Hours Per Day"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes','Hours')"
                      viewModeText="{{inputFormGroup.controls.Hours.value}}">
      <input [formControl]="inputFormGroup.controls.Hours" type="text" class="form-control"
              (blur)="floatApplyTwoDecimalPlaces()"
              phxFloatBetweenInput='{"from":0, "to":24.99, "decimalplaces":2}'/>
    </phx-form-control>
  </ng-container>

  <p *ngIf="billingInfoIndex>0" 
    class="form-control-static"
  >
    {{inputFormGroup.controls.Hours.value }}
  </p>
  
  <phx-form-control [control]="inputFormGroup.controls.CurrencyId" labelText="Currency"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes','Currency')"
                    viewModeText="{{inputFormGroup.controls.CurrencyId.value | CodeValue:codeValueGroups.Currency }}">
    <app-phx-select-box [formControl]="inputFormGroup.controls.CurrencyId" [items]="html.codeValueLists.listCurrency"
                        textField="text" valueField="id">
    </app-phx-select-box>
  </phx-form-control>

  <app-workorder-billing-rates
          [inputFormGroup]="inputFormGroup.controls.BillingRatesDetail"
          [readOnlyStorage]="readOnlyStorage"
          [billingInfoIndex]="billingInfoIndex"
          [currencyId]="inputFormGroup.controls.CurrencyId.value"
  >
  </app-workorder-billing-rates>

  <app-workorder-rebate-vmsfee [inputFormGroup]="inputFormGroup.controls.RebateAndVMSFee"
                               [readOnlyStorage]="readOnlyStorage">
  </app-workorder-rebate-vmsfee>

  <app-workorder-client-discount [billingRateDetails]="inputFormGroup.get('BillingRatesDetail')"
                                 [inputFormGroup]="inputFormGroup.controls.ClientDiscount"
                                 [readOnlyStorage]="readOnlyStorage">
  </app-workorder-client-discount>

</form>
