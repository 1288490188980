<app-phx-modal #modal title="{{modalTitle}}" (closeModal)="resetForm()" [noMessagePadding]="true" [messages]="modalMessages">
    <ng-container *ngIf="formGroup">
        <div style="line-height: 21px; color: #5E6E90; padding-left: 10px; padding-bottom: 10px">
            {{bodyMessage}}
        </div>
        <phx-form-control [control]="formGroup.controls.TerminationType" labelText="Termination Type" [editable]="true"
            [layoutType]="layoutType.Stacked">
            <app-phx-select-box [items]="TerminationTypes" textField="TerminationTypeText"
                valueField="TerminationTypeId" (valueChanged)="terminationTypeSelected($event)"
                [formControl]="formGroup.controls.TerminationType" placeholder="Select Termination Type">
            </app-phx-select-box>
            <span *ngIf="!html.IsTerminationTypeValidWhenTypeIsNoStart" class="error-message">{{html.TerminationTypeValidationMessageWhenTypeIsNoStart}}</span>
            <span *ngIf="!html.IsWOHasSubmittedTimesheetAndIsTerminationTypeNoStartValid" class="error-message">{{html.WOHasSubmittedTimesheetValidationMessage}}</span>
        </phx-form-control>
        <phx-form-control [control]="formGroup.controls.TerminationReasons" [editable]="true"
            [layoutType]="layoutType.Stacked" *ngIf="TerminationReasons.length > 0">
            <phx-form-control-label [control]="formGroup.controls.TerminationReasons">
                Termination Reason
                <div style="position: absolute; display: contents">
                    <i class="material-icons md-64" id="TerminationReasonInfo" style="font-size: 16px; position: relative; top: 3px">info</i>
                </div>
            </phx-form-control-label>
            <app-phx-tool-tip targetId="#TerminationReasonInfo">
                For internal reporting, not shared externally.
            </app-phx-tool-tip>
            <app-phx-tag-box [dataSource]="TerminationReasons" valueExpr="TerminationReasonId"
                displayExpr="TerminationReasonText" showSelectionControls="true" [searchEnabled]="true"
                applyValueMode="instantly" placeholder="Select Termination Reason"
                [formControl]="formGroup.controls.TerminationReasons" [maxDisplayedTags]="1">
            </app-phx-tag-box>

        </phx-form-control>
        <ng-container *ngIf="formGroup.controls.TerminationReasons.value?.length > 0">
            <phx-form-control [control]="formGroup.controls.TerminationDates" [editable]="true"
                [layoutType]="layoutType.InputOnly">
                <phx-form-control [control]="formGroup.get('TerminationDates').get('PlannedDateOfNotification')"
                    class="col-lg-6" [editable]="true" style="padding: 0" [layoutType]="layoutType.Stacked">
                    <phx-form-control-label
                        [control]="formGroup.get('TerminationDates').get('PlannedDateOfNotification')">
                        Planned Date of Notification
                        <div style="position: absolute; display: contents">
                            <i class="material-icons md-64" id="PlannedDateOfNotificationInfo"
                            style="font-size: 16px; position: relative; top: 3px">info</i>
                        </div>
                    </phx-form-control-label>
                    <app-phx-tool-tip targetId="#PlannedDateOfNotificationInfo">
                        Date of the consultant was notified of termination
                    </app-phx-tool-tip>
                    <app-phx-date-box type="date"
                        [formControl]="formGroup.get('TerminationDates').get('PlannedDateOfNotification')" required>
                    </app-phx-date-box>
                </phx-form-control>
                <phx-form-control [control]="formGroup.get('TerminationDates').get('LastDayOfWork')"
                                  class="col-lg-6" labelText="Last Day of Work" [editable]="true" style="padding: 0"
                                  [layoutType]="layoutType.Stacked">
                    <app-phx-date-box type="date"
                                      [formControl]="formGroup.get('TerminationDates').get('LastDayOfWork')"
                                      [min]="LastDayOfWorkMin" [max]="LastDayOfWorkMax" [required]="true" [disabled]="isLastDayOfWorkDisabled()"
                                      (valueChanged)="onLastDayOfWorkChange($event)"></app-phx-date-box>
                </phx-form-control>
            </phx-form-control>
            <phx-form-control [control]="formGroup.controls.AdditionalInstruction"
                labelText="Additional Comments or Client Instructions" [editable]="true" class="col-lg-12"
                style="padding: 0" [layoutType]="layoutType.Stacked">
                <textarea class="comments" [formControl]="formGroup.controls.AdditionalInstruction" maxlength="8000" minlength="10" (focusout)="onCommentChange()" required>
                </textarea>
                <span class="error-message" *ngIf="!html.IsCommentValid">{{html.CommentValidationMessage}}</span>
            </phx-form-control>
        </ng-container>
        <hr>
        <div class="col-lg-12" style="text-align: right" *ngIf="formGroup.controls.TerminationReasons.value">
            <button type="button" class="btn" *ngFor="let btn of modalButtons|orderBy:'SortOrder'" style="margin:5px"
                [ngClass]="btn.Class" (click)="onButtonClick(btn)"
                [disabled]="!isSubmitButtonEnabled(btn)">{{btn.Name}}</button>
        </div>
    </ng-container>
</app-phx-modal>
