<nav>
  <ng-container *ngFor="let tab of navigationBarContent; trackBy: trackByFn">
    <div *ngIf="!tab.IsHidden" class="position-relative">
      <ng-template #disabledLink>
        <a [class.active]="activeTab.Name === tab.Name" (click)="onTabSelect(tab)"
           matRipple>
          <mat-icon class="tab-icon-right" *ngIf="tab.Icon">{{tab.Icon}}</mat-icon>
          <span>{{tab.DisplayText}}</span>
          <ng-container [ngTemplateOutlet]='tab.IsFormValid ? form_valid_tpl : tab_valid_tpl'
                        [ngTemplateOutletContext]="{tab:tab}"></ng-container>
          <span *ngIf="tab.BadgeCount" class="badge badge-warning">{{tab.BadgeCount}}</span>
        </a>
      </ng-template>
      <a *ngIf="tab.Path;else disabledLink" [routerLink]="[tab.Path]"
         matRipple
         [replaceUrl]="navigationExtras.replaceUrl"
         [class.active]="activeTab.Name === tab.Name"
         (click)="onTabSelect(tab)">
        <mat-icon class="tab-icon-right" *ngIf="tab.Icon">{{tab.Icon}}</mat-icon>
        <span>{{tab.DisplayText}}</span>
        <ng-container [ngTemplateOutlet]='tab.IsFormValid ? form_valid_tpl : tab_valid_tpl'
                      [ngTemplateOutletContext]="{tab:tab}"></ng-container>
        <span *ngIf="tab.BadgeCount" class="badge badge-warning">{{tab.BadgeCount}}</span>
        <span *ngIf="tab.HasVersionChanges | async" class="material-icons-outlined version-change-icon" title="Changed from last version">edit</span>
                    </a>
      <div class="unread-notes-message" *ngIf="tab.Name === notesTabNavigationName && showUnreadNotesMessage"
           (click)="$event.stopPropagation()">
        <div class="pointer-shape"></div>
        <div class="message-container">
          <span class="message">You have {{unreadNotesCount}} unread items</span>
          <button type="button" mat-icon-button (click)="hideUnreadNotesNotification($event)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nav>

<ng-template #form_valid_tpl let-tab='tab'>
  <mat-icon class="tab-icon-left" *ngIf="tab.IsFormValid && (tab.IsFormValid | async) === false">error</mat-icon>
</ng-template>

<ng-template #tab_valid_tpl let-tab='tab'>
  <mat-icon class="tab-icon-left" *ngIf="tab.Valid === false">error</mat-icon>
</ng-template>
