// Angular
import { Component, OnInit, Input } from '@angular/core';
// Common
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { PhxConstants } from '../../../common';
// Work order
import { ICoreTabRoot, IReadOnlyStorage } from '../../models';
import { CoreTabFormService } from '../../services';
import { ConfigurationService } from 'src/app/configuration/service/configuration.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-workorder-tab-core',
  templateUrl: './workorder-tab-core.component.html',
  styleUrls: ['./workorder-tab-core.component.less']
})

export class WorkorderTabCoreComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() readOnlyStorage: IReadOnlyStorage;

  phxConstants: any;
  formGroup: FormGroup<ICoreTabRoot>;
  hideCommissions = false;

  constructor(
    private coreTabFormService: CoreTabFormService,
    private configurationService: ConfigurationService
  ) {
    super();
    this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.HideCommissions]).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(featureFlagState => {
      this.hideCommissions = featureFlagState[PhxConstants.FeatureFlags.HideCommissions];
    });
  }

  ngOnInit() {
    this.phxConstants = PhxConstants;
    this.formGroup = this.coreTabFormService.formGroup;
  }
}
