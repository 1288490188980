import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IBillingInfo, IBillingInfoes, IBillingInvoice, IReadOnlyStorage, IRoot } from '../../models';
import { AbstractControl, FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { PhxConstants } from '../../../common';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { AuthService } from '../../../common/services/auth.service';

@Component({
  selector: 'app-workorder-billing-invoices',
  templateUrl: './workorder-billing-invoices.component.html',
  styleUrls: ['./workorder-billing-invoices.component.less']
})
export class WorkorderBillingInvoicesComponent extends BaseComponentOnDestroy {

  @Input() invoiceType: number;
  @Input() inputFormGroup: FormGroup<IBillingInfoes>;

  @Output() addTimeSheetNote = new EventEmitter();
  @Output() removeTimeSheetNote = new EventEmitter();

  @Output() addRecipient = new EventEmitter();
  @Output() removeRecipient = new EventEmitter();

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  constructor(private authService: AuthService) {
    super();
  }

  get billingInfoFormArray(): FormArray<IBillingInfo> {
    return this.inputFormGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
  }

  billingInvoicesFormArray(index: number): FormArray<IBillingInvoice> {
    return (this.billingInfoFormArray.at(index).get('BillingInvoices') as FormArray<IBillingInvoice>);
  }

  trackByForBillingInvoice(index: number, item: AbstractControl<IBillingInvoice>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  trackByForBillingInfo(index: number, item: AbstractControl<IBillingInfo>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  addTimesheetBillingInvoiceNote(data: any) {
    this.addTimeSheetNote.emit(data);
  }

  removeTimesheetBillingInvoiceNote(data: any) {
    this.removeTimeSheetNote.emit(data);
  }

  addBillingRecipient(data: any) {
    this.addRecipient.emit(data);
  }

  removeBillingRecipient(data: any) {
    this.removeRecipient.emit(data);
  }
}
