import { PhxConstants } from '../../common';
import { IUserProfileFunctionalRole } from './profile.interface';

export interface IUserProfileListItemDto {
  Id: number;
  OrganizationId?: number;
  DisplayName: string;
  Contact: {
    Id: number;
    FullName: string;
  };
}

export class FunctionalRole {
  Id: number;
  Name: string;
  FunctionalRoleId: number;
}

export class ProfileReassignFrom {
  latestProfileId: number;
  latestContactId: number;
  FullName: string;
  UserProfileFunctionalRoles: IUserProfileFunctionalRole[];
}

export class AssignType {
  Id: PhxConstants.ReassignmentType;
  Description: string;
}

export class InternalUserProfile {
  ContactId: number;
  ProfileId: number;
  FullName: string;
}

export class RecruiterProfile {
  ProfileId: number;
  FullName: string;
  BranchId: number;
}

export class BranchManaged {
  CodeInternalOrganizationDefinition1Id: number;
  ManagerId: number;
}

export class BranchManager {
  Id: number;
  UserProfileInternalId: number;
  CodeInternalOrganizationDefinition1Id: number;
}

export class ExpenseApprover {
  UserProfileId: number;
}

export class CollaboratorWO {
  ClientOrganizationId: number;
  ClientDisplayName: string;
  AssignedToUserProfileId: number;
  WorkOrderVersionId: number;
  StatusId: number;
  VersionNumber: number;
  EffectiveDate: Date;
  ExpenseApprovers: ExpenseApprover[];
}

export class RecruiterWO {
  BranchId: number;
  BranchName: string;
  RecruiterUserProfileId: number;
  WorkOrderVersionId: number;
}

export class WOVExpenseApproverInfo {
  WorkOrderVersionId: number;
  IsExpenseApprover: boolean;
}

export class GroupedWOsByClient {
  ClientOrganizationId: number;
  ClientDisplayName: string;
  WOVExpenseApproverInfos: WOVExpenseApproverInfo[];
  WorkorderCount: number;
}

export class WorkOrdersByBranch {
  BranchId: number;
  BranchName: string;
  WorkorderCount: number;
  WorkOrderVersionIdList: number[];
}

export class OrganizationCollaborator {
  Id: number;
  DisplayName: string;
}

export class ContactCollaborator {
  Id: number;
  FullName: string;
}

export class WOReassignmentFormModel {
  WOClient: GroupedWOsByClient;
  AssignedToUserProfileId: number;
}

export class OrgReassignmentFormModel {
  Organization: OrganizationCollaborator;
  AssignedToUserProfileId: number;
}

export class ProfileReassignmentFormModel {
  Profile: ContactCollaborator;
  AssignedToUserProfileId: number;
}

export class InternalUserOffboardingFormModel {
  AssignType: AssignType;
  BranchManagerReassignedTo: InternalUserProfile;
  ReassignAllItemsTo: InternalUserProfile;
  ReassignAllWOsTo: InternalUserProfile;
  GroupedWOsByClientList: WOReassignmentFormModel[];
  ReassignAllOrgsTo: InternalUserProfile;
  OrganizationList: OrgReassignmentFormModel[];
  ReassignAllProfilesTo: InternalUserProfile;
  ProfileList: ProfileReassignmentFormModel[];
}

