import { Injectable } from '@angular/core';
import { PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IPaymentInfo, IPaymentInfoes, IPaymentInvoice, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { Validators } from '@angular/forms';

@Injectable()
export class PaymentInfoFormService {

  constructor(private fb: FormBuilder) { }

  createPaymentInfoesFormGroup(paymentInfoes: Array<IPaymentInfo>, validations: any, invoiceType: number): FormGroup<IPaymentInfoes> {
    return this.fb.group<IPaymentInfoes>({
      PaymentInfoes: this.createPaymentInfoFormArray(paymentInfoes, validations, invoiceType)
    });
  }


  formGroupToPartial(workOrder: IWorkOrder, paymentInfoesFormGroup: FormGroup<IPaymentInfoes>, invoiceType: number): IWorkOrder {
    const paymentInfoes: Array<IPaymentInfo> = paymentInfoesFormGroup.value.PaymentInfoes;
    paymentInfoes.forEach((info, index) => {
      if (workOrder.WorkOrderVersion.PaymentInfoes[index]) {
        workOrder.WorkOrderVersion.PaymentInfoes[index] = {
          ...workOrder.WorkOrderVersion.PaymentInfoes[index],
          PaymentInvoices: [...workOrder.WorkOrderVersion.PaymentInfoes[index].PaymentInvoices.filter(x => x.InvoiceTypeId !== invoiceType),
          ...info.PaymentInvoices.filter(x => x.InvoiceTypeId === invoiceType)]
        };
      }
    });
    return workOrder;
  }

  updatePaymentInfoFormArray(
    formArray: FormArray<IPaymentInfo>,
    paymentInfoes: Array<IPaymentInfo>,
    validations: any,
    invoiceType: number
  ) {

    if (formArray.length && paymentInfoes.length) {
      paymentInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentInfo>;
        if (formGroup) {
          this.updatePaymentInfoFormGroup(formGroup, item, validations, invoiceType);
        } else {
          formArray.push(this.createPaymentInfoFormGroup(item, validations, invoiceType));
        }
      });
      if (formArray.length > paymentInfoes.length) {
        this.clearArray(formArray, paymentInfoes.length);
      }
    } else if (paymentInfoes.length) {
      const array = this.createPaymentInfoFormArray(paymentInfoes, validations, invoiceType);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }

  }

  private setValidationsForPaymentInfoes(workOrder) {
    return {
      isPaymentTermsNoValidation: !workOrder.WorkOrderVersion.IsEligibleForCommission
    };
  }

  private createPaymentInfoFormArray(paymentInfoes: Array<IPaymentInfo>, validations: any, invoiceType: number): FormArray<IPaymentInfo> {
    return this.fb.array<IPaymentInfo>(
      paymentInfoes.map((info: IPaymentInfo) =>
        this.createPaymentInfoFormGroup(info, validations, invoiceType)
      )
    );
  }

  private isRequired(invoice: IPaymentInvoice, validations: any, invoiceType: number) {
    return (
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) &&
      !(validations.isPaymentTermsNoValidation && invoice.InvoiceTypeId === invoiceType) &&
      invoice.InvoiceTypeId === invoiceType
    );
  }

  private createPaymentInfoFormGroup(info: IPaymentInfo, validations: any, invoiceType: number): FormGroup<IPaymentInfo> {
    return this.fb.group<IPaymentInfo>({
      Id: [info.Id],
      PaymentInvoices: this.createPaymentInvoiceFormArray(info.PaymentInvoices, validations, invoiceType),
      OrganizationIdSupplier: [info.OrganizationIdSupplier],
      OrganizationRoleTypeId: [info.OrganizationRoleTypeId],
      OrganizationSupplierDisplayName: [info.OrganizationSupplierDisplayName],
      IsOrganizationSupplierSubVendor: [info.IsOrganizationSupplierSubVendor],
      IsOrganizationIndependentContractorRole: [info.IsOrganizationIndependentContractorRole],
      IsOrganizationLimitedLiabilityCompanyRole: [info.IsOrganizationLimitedLiabilityCompanyRole],
    });
  }

  private createPaymentInvoiceFormArray(invoices: IPaymentInvoice[], validations: any, invoiceType: number) {
    if (invoices) {
      return this.fb.array<IPaymentInvoice>(
        invoices.map((invoice: IPaymentInvoice) => this.createPaymentInvoiceFormGroup(invoice, validations, invoiceType))
      );
    }
  }

  private createPaymentInvoiceFormGroup(invoice: IPaymentInvoice, validations: any, invoiceType: number) {
    return this.fb.group<IPaymentInvoice>({
      Id: [invoice.Id],
      InvoiceTypeId: [invoice.InvoiceTypeId],
      IsDraft: [invoice.IsDraft],
      IsSalesTaxAppliedOnVmsImport: [
        invoice.IsSalesTaxAppliedOnVmsImport,
        this.isRequired(invoice, validations, invoiceType) && validations.isSalesTaxAppliedOnVmsImport
          ? [Validators.required]
          : null,
      ],
      PaymentInfoId: [invoice.PaymentInfoId],
      PaymentInvoiceTermsId: [
        invoice.PaymentInvoiceTermsId,
        this.isRequired(invoice, validations, invoiceType)
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTermsId', [
            Validators.required,
          ])
          : null,
      ],
      PaymentReleaseScheduleId: [
        invoice.PaymentReleaseScheduleId,
        this.isRequired(invoice, validations, invoiceType) && invoice.PaymentInvoiceTermsId === PhxConstants.PaymentInvoiceTerms.ScheduledTerms
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentReleaseScheduleId', [
            Validators.required,
          ])
          : null,
      ],
      PaymentFrequency: [
        invoice.PaymentFrequency,
        this.isRequired(invoice, validations, invoiceType) && invoice.PaymentInvoiceTermsId === PhxConstants.PaymentInvoiceTerms.Term
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentFrequency', [
            Validators.required,
          ])
          : null,
      ],
      PaymentInvoiceTemplateId: [
        invoice.PaymentInvoiceTemplateId,
        // not required validation for IncentiveCompensation
        this.isRequired(invoice, validations, invoiceType) && invoice.InvoiceTypeId !== invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTemplateId', [
            Validators.required,
          ])
          : null,
      ],
      PaymentMethodId: [
        invoice.PaymentMethodId,
        // not required validation for IncentiveCompensation
        this.isRequired(invoice, validations, invoiceType) && invoice.InvoiceTypeId !== invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentMethodId', [
            Validators.required,
          ])
          : null,
      ],
      SourceId: [invoice.SourceId],
    });
  }

  private updatePaymentInfoFormGroup(
    formGroup: FormGroup<IPaymentInfo>,
    info: IPaymentInfo,
    validations: any,
    invoiceType: number
  ) {

    formGroup.patchValue({
      Id: info.Id,
      OrganizationIdSupplier: info.OrganizationIdSupplier,
      OrganizationRoleTypeId: info.OrganizationRoleTypeId,
      OrganizationSupplierDisplayName: info.OrganizationSupplierDisplayName,
      IsOrganizationSupplierSubVendor: info.IsOrganizationSupplierSubVendor,
      IsOrganizationIndependentContractorRole: info.IsOrganizationIndependentContractorRole,
      IsOrganizationLimitedLiabilityCompanyRole: info.IsOrganizationLimitedLiabilityCompanyRole,
    }, { emitEvent: false });

    const paymentInvoicesFormArray = formGroup.get('PaymentInvoices') as FormArray<IPaymentInvoice>;
    this.updatePaymentInvoiceFormArray(paymentInvoicesFormArray, info.PaymentInvoices, validations, invoiceType);

  }

  private updatePaymentInvoiceFormArray(
    formArray: FormArray<IPaymentInvoice>,
    invoices: IPaymentInvoice[],
    validations: any,
    invoiceType: number
  ) {

    if (formArray.length && invoices.length) {
      invoices.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentInvoice>;
        if (formGroup) {
          this.updatePaymentInvoiceFormGroup(formGroup, item, validations, invoiceType);
        } else {
          formArray.push(this.createPaymentInvoiceFormGroup(item, validations, invoiceType));
        }
      });
      if (formArray.length > invoices.length) {
        this.clearArray(formArray, invoices.length);
      }
    } else if (invoices.length) {
      const array = this.createPaymentInvoiceFormArray(invoices, validations, invoiceType);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }

  }

  private updatePaymentInvoiceFormGroup(
    formGroup: FormGroup<IPaymentInvoice>,
    invoice: IPaymentInvoice,
    validations: any,
    invoiceType: number
  ) {

    formGroup.get('IsSalesTaxAppliedOnVmsImport').setValidators(
      this.isRequired(invoice, validations, invoiceType) && validations.isSalesTaxAppliedOnVmsImport
        ? [Validators.required]
        : null
    );

    formGroup.get('PaymentInvoiceTermsId').setValidators(
      this.isRequired(invoice, validations, invoiceType)
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTermsId', [
          Validators.required,
        ])
        : null
    );

    formGroup.get('PaymentReleaseScheduleId').setValidators(
      this.isRequired(invoice, validations, invoiceType) && invoice.PaymentInvoiceTermsId === PhxConstants.PaymentInvoiceTerms.ScheduledTerms
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentReleaseScheduleId', [
          Validators.required,
        ])
        : null
    );

    formGroup.get('PaymentFrequency').setValidators(
      this.isRequired(invoice, validations, invoiceType) && invoice.PaymentInvoiceTermsId === PhxConstants.PaymentInvoiceTerms.Term
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentFrequency', [
          Validators.required,
        ])
        : null
    );

    formGroup.get('PaymentInvoiceTemplateId').setValidators(
      // not required validation for IncentiveCompensation
      this.isRequired(invoice, validations, invoiceType) && invoice.InvoiceTypeId !== invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTemplateId', [
          Validators.required,
        ])
        : null
    );

    formGroup.get('PaymentMethodId').setValidators(
      // not required validation for IncentiveCompensation
      this.isRequired(invoice, validations, invoiceType) && invoice.InvoiceTypeId !== invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentMethodId', [
          Validators.required,
        ])
        : null
    );

    formGroup.patchValue({
      Id: invoice.Id,
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsDraft: invoice.IsDraft,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      PaymentInfoId: invoice.PaymentInfoId,
      PaymentInvoiceTermsId: invoice.PaymentInvoiceTermsId,
      PaymentReleaseScheduleId: invoice.PaymentReleaseScheduleId,
      PaymentFrequency: invoice.PaymentFrequency,
      PaymentInvoiceTemplateId: invoice.PaymentInvoiceTemplateId,
      PaymentMethodId: invoice.PaymentMethodId,
      SourceId: invoice.SourceId,
    }, { emitEvent: false });

  }

  private clearArray(formArray: FormArray<IPaymentInfo | IPaymentInvoice>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
