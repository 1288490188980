<div class="alert" *ngIf="showBanner"
  [ngClass]="{
    'alert--info': type === 'info',
    'alert--warning': type === 'warning',
    'alert--danger': type === 'danger',
    'alert--success': type === 'success'
  }"
>
  <span class="alert__message">
    <i class="material-icons alert__message__icon"> info </i>
    <span >
      <strong
        *ngIf="message.title"
        class="alert__message__title">
          {{ message.title }}
      </strong>
      {{ message.body }}
    </span>
  </span>
  <button type="button" class="close alert__close" *ngIf="canClose" (click)="showBanner = false">
      <i class="large material-icons">close</i>
  </button> 
</div>
