import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phxFormatPhone',
  pure: true
})
/**
 * Format numbers into phone format. Only formats when there are 10+ characters, otherwise returns the original string.
 */
export class PhxFormatPhonePipe implements PipeTransform {

  constructor() { }

  transform(value: string, ...args: any[]): any {
    const scrubbed = value ? value.replace(/\D/g,'') : ''; //scrub non numbers
    
    if(scrubbed.length < 10) return scrubbed; // dont format string less than 10 characters
    return `(${scrubbed.slice(0,3)}) ${scrubbed.slice(3,6)}-${scrubbed.slice(6)}`;
  }
}
