import { ValidatorFn } from '@angular/forms';

import { WorkOrderApiServiceLocator } from './workorder.api.service.locator';
import { ControlFieldAccessibility, ModeValidator } from './control-field-accessibility';
import { AuthService } from '../common/services/auth.service';

export class PtFieldViewCustomValidator {
  static authService: AuthService;

  static checkPtFieldViewCustomValidator(modelPrefix: string, fieldName: string, validator: ValidatorFn | Array<ValidatorFn> = [], modeValidator: ModeValidator | null = null) {
    this.authService = WorkOrderApiServiceLocator.injector.get<AuthService>(AuthService, null);
    if (ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService, modeValidator)) {
      return validator;
    } else {
      return null;
    }
  }
}
