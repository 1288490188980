import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'phx-banner',
  templateUrl: './phx-banner.component.html',
  styleUrls: ['./phx-banner.component.less']
})
export class PhxBannerComponent implements OnInit {

  @Input() message: {title: string, body: string} = {title: '', body: ''};
  @Input() canClose: boolean = true;
  @Input() type: 'info'|'danger'|'warning'|'success' = 'info';

  showBanner: boolean = true;

  @HostBinding('class.PhxBannerComponent') PhxBannerComponent: boolean = true;
  
  constructor() { }

  ngOnInit() {
  }

}
