<app-phx-panel headerTitle="Rate Negotiation Service" *ngIf="formGroup">

    <form [formGroup]="formGroup">
        <phx-form-control [control]="formGroup.controls.JobPostingNumber" labelText="Job Posting Number"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','JobPostingNumber')"
            [viewModeText]="formGroup.controls.JobPostingNumber.value">
            <input type="text" class="form-control" [formControl]="formGroup.controls.JobPostingNumber" maxLength="20">
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.RateCardJobTitle" labelText="Rate Card Job Title"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','RateCardJobTitle')"
            [viewModeText]="formGroup.controls.RateCardJobTitle.value">
            <input type="text" class="form-control" [formControl]="formGroup.controls.RateCardJobTitle" maxLength="100">
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.ClientGroup" labelText="Client Group"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','ClientGroup')"
            [viewModeText]="formGroup.controls.ClientGroup.value">
            <input type="text" class="form-control" [formControl]="formGroup.controls.ClientGroup" maxLength="100">
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.RateNegotiationAdditionalInfo" labelText="Notes"
        [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','RateNegotiationAdditionalInfo')"
        [viewModeText]="formGroup.controls.RateNegotiationAdditionalInfo.value">
        <input type="text" class="form-control" [formControl]="formGroup.controls.RateNegotiationAdditionalInfo" maxLength="100">
        </phx-form-control>
            
        <i class="col-xs-12 form-control-static">&nbsp;</i>

        <phx-form-control
          [control]="formGroup.controls.InitialAskPayRate"
          labelText="Initial Ask Pay Rate"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','InitialAskPayRate')"
          [viewModeText]="(formGroup.controls.InitialAskPayRate.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.InitialAskPayRate" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control
          [control]="formGroup.controls.InitialAskBillRate"
          labelText="Initial Ask Bill Rate"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','InitialAskBillRate')"
          [viewModeText]="(formGroup.controls.InitialAskBillRate.value || 0.00) | currency"
          [forceValidation]="true"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.InitialAskBillRate" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>
    
        <phx-form-control
          [control]="formGroup.controls.MaxBillRate"
          labelText="Max Bill Rate"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','MaxBillRate')"
          [viewModeText]="(formGroup.controls.MaxBillRate.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.MaxBillRate" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.BurdenUsedPercentage" labelText="Burden Used"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','BurdenUsedPercentage')"
            [viewModeText]="formGroup.controls.BurdenUsedPercentage.value | phxPercentWithDecimals">
            <div class="input-group col-xxxl-4 col-lg-6">
                <input [formControl]="formGroup.controls.BurdenUsedPercentage" type="text" class="form-control" min="0" max="100" phxFloatBetweenInput='{"from":0, "to":100, "decimalplaces":4}' />
                <span class="input-group-addon">%</span>
            </div>
        </phx-form-control>

        <i class="col-xs-12 form-control-static">&nbsp;</i>

        <phx-form-control
          [control]="formGroup.controls.FeeSavings"
          labelText="Fee Savings"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','FeeSavings')"
          [viewModeText]="(formGroup.controls.FeeSavings.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.FeeSavings" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control
          [control]="formGroup.controls.BurdenSavings"
          labelText="Burden Savings"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','BurdenSavings')"
          [viewModeText]="(formGroup.controls.BurdenSavings.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.BurdenSavings" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control
          [control]="formGroup.controls.AdditionalRateSavings"
          labelText="Additional Rate Savings"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','AdditionalRateSavings')"
          [viewModeText]="(formGroup.controls.AdditionalRateSavings.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.AdditionalRateSavings" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <i class="col-xs-12 form-control-static">&nbsp;</i>
    
        <phx-form-control [control]="formGroup.controls.IsRatePrenegotiated" labelText="Rate Prenegotiated?"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsRatePrenegotiated')"
            [viewModeText]="formGroup.controls.IsRatePrenegotiated.value | boolToYesNo">
            <fieldset>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsRatePrenegotiated" type="radio" [value]="true" [checked]="formGroup.controls.IsRatePrenegotiated.value"
                    [formControl]="formGroup.controls.IsRatePrenegotiated">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsRatePrenegotiated" type="radio" [value]="false" [checked]="!formGroup.controls.IsRatePrenegotiated.value"
                    [formControl]="formGroup.controls.IsRatePrenegotiated">No</label>
            </fieldset>
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.IsRateCardUsed" labelText="Is there a Rate Card?"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsRateCardUsed')"
            [viewModeText]="formGroup.controls.IsRateCardUsed.value | boolToYesNo">
            <fieldset>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsRateCardUsed" type="radio" [value]="true" [checked]="formGroup.controls.IsRateCardUsed.value"
                    [formControl]="formGroup.controls.IsRateCardUsed">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsRateCardUsed" type="radio" [value]="false" [checked]="!formGroup.controls.IsRateCardUsed.value"
                    [formControl]="formGroup.controls.IsRateCardUsed">No</label>
            </fieldset>
        </phx-form-control>

        <ng-container *ngIf="formGroup.controls.IsRateCardUsed.value" >
            <phx-form-control [control]="formGroup.controls.IsRateWithinRateCard" labelText="Within Rate Card?"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsRateWithinRateCard')"
                [viewModeText]="formGroup.controls.IsRateWithinRateCard.value | boolToYesNo">
                <fieldset>
                    <label class="radio-inline">
                        <input class="form-check-input" name="IsRateWithinRateCard" type="radio" [value]="true" [checked]="formGroup.controls.IsRateWithinRateCard.value"
                        [formControl]="formGroup.controls.IsRateWithinRateCard">Yes</label>
                    <label class="radio-inline">
                        <input class="form-check-input" name="IsRateWithinRateCard" type="radio" [value]="false" [checked]="formGroup.controls.IsRateWithinRateCard.value === false"
                        [formControl]="formGroup.controls.IsRateWithinRateCard">No</label>
                </fieldset>
            </phx-form-control>
        </ng-container>

        <i class="col-xs-12 form-control-static">&nbsp;</i>

        <h5>Sub Vendor Rates</h5>


        <phx-form-control
          [control]="formGroup.controls.SubvendorActualPayRate"
          labelText="Actual Pay Rate"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','SubvendorActualPayRate')"
          [viewModeText]="(formGroup.controls.SubvendorActualPayRate.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.SubvendorActualPayRate" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control [control]="formGroup.controls.SubvendorActualProfileTypeId" labelText="Actual Legal Status"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','SubvendorActualProfileTypeId')"
            [viewModeText]="formGroup.controls.SubvendorActualProfileTypeId.value | CodeValue:codeValueGroups.ProfileType">
            <app-phx-select-box [formControl]="formGroup.controls.SubvendorActualProfileTypeId" [items]="html.lists.profileTypes" textField="text" valueField="id">
            </app-phx-select-box>
        </phx-form-control>

        <phx-form-control
          [control]="formGroup.controls.SubvendorActualBurdenCosts"
          labelText="Actual Burden Costs"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','SubvendorActualBurdenCosts')"
          [viewModeText]="(formGroup.controls.SubvendorActualBurdenCosts.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.SubvendorActualBurdenCosts" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

        <phx-form-control
          [control]="formGroup.controls.SubvendorMargin"
          labelText="Margin"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','SubvendorMargin')"
          [viewModeText]="(formGroup.controls.SubvendorMargin.value || 0.00) | currency"
        >
            <div class="input-group">
                <input [formControl]="formGroup.controls.SubvendorMargin" type="text" class="form-control" min="0" phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}' />
                <span class="input-group-addon">$</span>
            </div>
        </phx-form-control>

    </form>
</app-phx-panel>
