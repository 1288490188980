<form [formGroup]="inputFormGroup">
  <phx-form-control
    [control]="inputFormGroup.controls.Rate"
    [labelText]="inputFormGroup.controls.RateTypeId.value | lookup:html.codeValueLists.listworkOrderRateTypes"
    [editable]="readOnlyStorage.IsEditable"
    [viewModeText]="inputFormGroup.controls.Rate.value | currency:currencyCode:'symbol-narrow'"
    [warningMessage]="inputFormGroup.get('RateTypeId').value === phxConstants.RateType.Primary ? html.rateWarningMessage : null"
  >
    <div
        [ngClass]="{'col-lg-12 input-group payroll-taxes-gray-box-fields' : inputFormGroup.get('RateTypeId').value === phxConstants.RateType.Primary}">
      <input [formControl]="inputFormGroup.controls.Rate" type="text" class="form-control"
             phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}'/>
      <span *ngIf="inputFormGroup.get('RateTypeId').value === phxConstants.RateType.Primary"
            class="input-group-addon">$</span>
    </div>
  </phx-form-control>
  <ng-container *ngIf="inputFormGroup.controls.RateTypeId.value>0">
    <phx-form-control [control]="inputFormGroup.controls.RateUnitId" labelText="Rate Unit"
                      [editable]="!paymentInfoIndex && readOnlyStorage.IsEditable
                        && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentRates','RateUnitId')"
                      viewModeText="{{inputFormGroup.controls.RateUnitId.value | CodeValue:codeValueGroups.RateUnit }}">
      <app-phx-select-box [formControl]="inputFormGroup.controls.RateUnitId"
                          [items]="html.codeValueLists.listWorkOrderRateUnits"
                          textField="text" valueField="id">
      </app-phx-select-box>
    </phx-form-control>
    <div>
      <phx-form-control [control]="inputFormGroup.controls.Description" labelText="Description"
                        [editable]="readOnlyStorage.IsEditable"
                        viewModeText="{{inputFormGroup.controls.Description?.value}}">
        <input [formControl]="inputFormGroup.controls.Description" type="text" class="form-control" maxlength="64"/>
      </phx-form-control>
    </div>
  </ng-container>
  <div
      *ngIf="workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp || workerProfileTypeId === phxConstants.UserProfileType.WorkerCanadianSp">
    <div class="form-group">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div class="pull-left" style="margin-right: 15px;">
          <div *ngIf="readOnlyStorage.IsEditable">
            <phx-form-control [control]="inputFormGroup.controls.IsApplyDeductions" [layoutType]="layoutType"
                              [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentRates','IsApplyDeductions')">
              <label class="checkbox-inline">
                <input type="checkbox" class="custom-control-input"
                       [formControl]="inputFormGroup.controls.IsApplyDeductions"> Apply Deduction
              </label>
            </phx-form-control>
          </div>
          <div *ngIf="!readOnlyStorage.IsEditable">
            <phx-form-control [control]="inputFormGroup.controls.IsApplyDeductions" [layoutType]="layoutType">
              <label class="checkbox-inline">
                <input type="checkbox" disabled class="custom-control-input"
                       [checked]="inputFormGroup.controls.IsApplyDeductions.value">
                Apply Deduction
              </label>
            </phx-form-control>
          </div>
        </div>
        <div *ngIf="workerProfileTypeId===phxConstants.UserProfileType.WorkerTemp">
          <div class="pull-left" style="margin-right: 15px;">
            <div *ngIf="readOnlyStorage.IsEditable">
              <phx-form-control [control]="inputFormGroup.controls.IsApplyVacation" [layoutType]="layoutType"
                                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentRates','IsApplyVacation')">
                <label class="checkbox-inline">
                  <input type="checkbox" class="custom-control-input"
                         [formControl]="inputFormGroup.controls.IsApplyVacation">
                  Apply Vacation
                </label>
              </phx-form-control>
            </div>
            <div *ngIf="!readOnlyStorage.IsEditable">
              <phx-form-control [control]="inputFormGroup.controls.IsApplyVacation" [layoutType]="layoutType">
                <label class="checkbox-inline">
                  <input type="checkbox" disabled class="custom-control-input"
                         [checked]="inputFormGroup.controls.IsApplyVacation.value">
                  Apply Vacation
                </label>
              </phx-form-control>
            </div>
          </div>
        </div>
        <div
            *ngIf="workerProfileTypeId===phxConstants.UserProfileType.WorkerTemp && (inputFormGroup.controls.RateUnitId.value === phxConstants.RateUnit.Hour || inputFormGroup.controls.RateUnitId.value === phxConstants.RateUnit.Day) ">
          <div *ngIf="readOnlyStorage.IsEditable">
            <phx-form-control [control]="inputFormGroup.controls.IsApplyStatHoliday" [layoutType]="layoutType"
                              [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentRates','IsApplyStatHoliday')">
              <label class="checkbox-inline">
                <input type="checkbox" class="custom-control-input"
                       [formControl]="inputFormGroup.controls.IsApplyStatHoliday">
                Apply Stat Holiday
              </label>
            </phx-form-control>
          </div>
          <div *ngIf="!readOnlyStorage.IsEditable">
            <phx-form-control [control]="inputFormGroup.controls.IsApplyStatHoliday" [layoutType]="layoutType">
              <label class="checkbox-inline">
                <input type="checkbox" disabled class="custom-control-input"
                       [checked]="inputFormGroup.controls.IsApplyStatHoliday.value">
                Apply Stat Holiday
              </label>
            </phx-form-control>
          </div>
        </div>
        <div *ngIf="workerProfileTypeId===phxConstants.UserProfileType.WorkerTemp && 
        (inputFormGroup.controls.RateUnitId.value !== phxConstants.RateUnit.Hour && 
        inputFormGroup.controls.RateUnitId.value !== phxConstants.RateUnit.Day)">
          <div style="margin-top:80px;"></div>
        </div>
      </div>

    </div>
  </div>
</form>
