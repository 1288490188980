import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ITabTimeMaterialInvoiceDetail, ITimeSheetApprover, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';

@Injectable()
export class TimeMaterialInvoiceDetailFormService implements IFormService {

  formGroup: FormGroup<ITabTimeMaterialInvoiceDetail>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private hasFixedRateUnit: boolean = false;

  constructor(private fb: FormBuilder) { }

  get timeSheetApproverFormArray(): FormArray<ITimeSheetApprover> {
    return this.formGroup.get('TimeSheetApprovers') as FormArray<ITimeSheetApprover>;
  }

  get organizationIdFormControl(): FormControl<number> {
    return this.formGroup.get('OrganizationIdClient') as FormControl<number>;
  }

  get timeSheetMethodologyIdFormControl(): FormControl<number> {
    return this.formGroup.get('TimeSheetMethodologyId') as FormControl<number>;
  }

  get isTimeSheetUsesProjectsFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsTimeSheetUsesProjects') as FormControl<boolean>;
  }

  get organizationIdValue(): number {
    return this.organizationIdFormControl.value;
  }

  get timeSheetMethodologyIdValue(): number {
    return this.timeSheetMethodologyIdFormControl.value;
  }

  get organizationIdChange$() {
    return this.organizationIdFormControl.valueChanges;
  }

  get timeSheetMethodologyIdChange$() {
    return this.timeSheetMethodologyIdFormControl.valueChanges;
  }

  get isTimeSheetUsesProjectsChange$() {
    return this.isTimeSheetUsesProjectsFormControl.valueChanges;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTimeMaterialInvoiceDetail> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const timeMaterialInvoiceDetail: ITabTimeMaterialInvoiceDetail = this.mapWorkOrderToFormData(workorder);
    const validations = this.setValidationsForTimeMaterialInvoiceDetails(
      workorder.WorkOrderVersion.TimeSheetMethodologyId,
      workorder.WorkOrderVersion.TimeSheetApprovers?.length > 1
    );

    this.formGroup = this.fb.group<ITabTimeMaterialInvoiceDetail>({
      TimeSheetMethodologyId: [
        timeMaterialInvoiceDetail.TimeSheetMethodologyId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetMethodologyId', [
          Validators.required
        ])
      ],
      TimeSheetCycleId: [
        timeMaterialInvoiceDetail.TimeSheetCycleId,
        validations.isTimeSheetCycleId
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetCycleId', [
            Validators.required
          ])
          : null
      ],
      IsSeasonalOrPartTimeTimesheet: [
        timeMaterialInvoiceDetail.IsSeasonalOrPartTimeTimesheet,
        validations.isSeasonalOrPartTimeTimesheet
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsSeasonalOrPartTimeTimesheet', [
            Validators.required
          ])
          : null
      ],
      TimeSheetApprovalFlowId: [
        timeMaterialInvoiceDetail.TimeSheetApprovalFlowId,
        validations.isTimeSheetApprovalFlowId
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetApprovalFlowId', [
            Validators.required
          ])
          : null
      ],
      TimeSheetApprovers: this.createTimeSheetApproverFormArray(timeMaterialInvoiceDetail.TimeSheetApprovers, validations.isTimeSheetApprovers),
      IsTimeSheetUsesProjects: [
        timeMaterialInvoiceDetail.IsTimeSheetUsesProjects,
        validations.isTimeSheetUsesProjects
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimeSheetUsesProjects', [
            Validators.required
          ])
          : null
      ],
      IsTimesheetProjectMandatory: [
        timeMaterialInvoiceDetail.IsTimeSheetUsesProjects ? timeMaterialInvoiceDetail.IsTimesheetProjectMandatory : null,
        timeMaterialInvoiceDetail.IsTimeSheetUsesProjects
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimesheetProjectMandatory', [
            Validators.required
          ])
          : null
      ],
      VmsWorkOrderReference: [
        timeMaterialInvoiceDetail.VmsWorkOrderReference,
        validations.isVmsWorkOrderReference
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'VmsWorkOrderReference', [
            Validators.required
          ])
          : null
      ],
      TimeSheetDescription: [timeMaterialInvoiceDetail.TimeSheetDescription, Validators.maxLength(200)],
      OrganizationIdClient: [timeMaterialInvoiceDetail.OrganizationIdClient]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  /** NOTE: track if WO has any rates with a fixed rate unit */
  setHasFixedRateUnit(hasFixedRate: boolean) {
    this.hasFixedRateUnit = hasFixedRate;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.timeSheetMethodologyIdChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(
      value => {
        const validations = this.setValidationsForTimeMaterialInvoiceDetails(
          value,
          this.timeSheetApproverFormArray.length > 1
        );
        this.setTimeMaterialInvoiceDetailValidations(validations);

        /** NOTE: if the new methodology is online or offline, the 'use projects' selection is shown */
        if (value === PhxConstants.TimeSheetMethodology.OnlineApproval || value === PhxConstants.TimeSheetMethodology.OfflineApproval) {
          /** NOTE: if the WO has a rate with a fixed rate unit then set 'use project' to false */
          this.isTimeSheetUsesProjectsFormControl.patchValue(this.hasFixedRateUnit ? false : this.isTimeSheetUsesProjectsFormControl.value, { emitEvent: false });
        } else {
          /** NOTE: we are hiding 'use projects' so clear values */
          this.isTimeSheetUsesProjectsFormControl.patchValue(null, { emitEvent: false });
          this.formGroup.get('IsTimesheetProjectMandatory').patchValue(null, { emitEvent: false });
        }
      }
    );

    this.isTimeSheetUsesProjectsChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(
      value => {
        this.formGroup.get('IsTimesheetProjectMandatory').setValidators(
          value
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimesheetProjectMandatory', [
              Validators.required
            ])
            : null
        );
        this.formGroup.patchValue({
          IsTimesheetProjectMandatory: this.formGroup.get('IsTimesheetProjectMandatory').value || null
        }, { emitEvent: false });
      }
    );
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const timeMaterialInvoiceDetails: ITabTimeMaterialInvoiceDetail = this.formGroup.value;

    workOrder.WorkOrderVersion.TimeSheetMethodologyId = timeMaterialInvoiceDetails.TimeSheetMethodologyId;
    workOrder.WorkOrderVersion.TimeSheetCycleId =
      timeMaterialInvoiceDetails.TimeSheetMethodologyId && timeMaterialInvoiceDetails.TimeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.NoTimesheet
        ? timeMaterialInvoiceDetails.TimeSheetCycleId
        : null;
    workOrder.WorkOrderVersion.IsSeasonalOrPartTimeTimesheet = timeMaterialInvoiceDetails.IsSeasonalOrPartTimeTimesheet ?? null;  
    workOrder.WorkOrderVersion.TimeSheetApprovalFlowId =
      timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
        ? timeMaterialInvoiceDetails.TimeSheetApprovalFlowId
          ? timeMaterialInvoiceDetails.TimeSheetApprovalFlowId
          : PhxConstants.TimeSheetApprovalFlow.Sequential
        : null;
    workOrder.WorkOrderVersion.TimeSheetApprovers =
      timeMaterialInvoiceDetails.TimeSheetMethodologyId && timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
        ? timeMaterialInvoiceDetails.TimeSheetApprovers
        : null;
    workOrder.WorkOrderVersion.IsTimeSheetUsesProjects =
      timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
        || timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval
        ? timeMaterialInvoiceDetails.IsTimeSheetUsesProjects
        : null;
    workOrder.WorkOrderVersion.IsTimesheetProjectMandatory = timeMaterialInvoiceDetails.IsTimeSheetUsesProjects ? timeMaterialInvoiceDetails.IsTimesheetProjectMandatory
      : null;
    workOrder.WorkOrderVersion.VmsWorkOrderReference = timeMaterialInvoiceDetails.VmsWorkOrderReference === '' ? null : timeMaterialInvoiceDetails.VmsWorkOrderReference;
    workOrder.WorkOrderVersion.TimeSheetDescription =
      timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
        || timeMaterialInvoiceDetails.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval
        ? timeMaterialInvoiceDetails.TimeSheetDescription
        : null;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const timeMaterialInvoiceDetail: ITabTimeMaterialInvoiceDetail = this.mapWorkOrderToFormData(workorder);
    const validations = this.setValidationsForTimeMaterialInvoiceDetails(
      workorder.WorkOrderVersion.TimeSheetMethodologyId,
      workorder.WorkOrderVersion.TimeSheetApprovers?.length > 1
    );

    this.formGroup.get('TimeSheetMethodologyId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetMethodologyId', [
        Validators.required
      ])
    );

    this.formGroup.get('TimeSheetCycleId').setValidators(
      validations.isTimeSheetCycleId
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetCycleId', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('TimeSheetApprovalFlowId').setValidators(
      validations.isTimeSheetApprovalFlowId
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetApprovalFlowId', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('IsTimeSheetUsesProjects').setValidators(
      validations.isTimeSheetUsesProjects
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimeSheetUsesProjects', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('IsTimesheetProjectMandatory').setValidators(
      timeMaterialInvoiceDetail.IsTimeSheetUsesProjects
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimesheetProjectMandatory', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('VmsWorkOrderReference').setValidators(
      validations.isVmsWorkOrderReference
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'VmsWorkOrderReference', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('TimeSheetDescription').setValidators(Validators.maxLength(200));

    this.formGroup.patchValue({
      TimeSheetMethodologyId: timeMaterialInvoiceDetail.TimeSheetMethodologyId,
      TimeSheetCycleId: timeMaterialInvoiceDetail.TimeSheetCycleId,
      TimeSheetApprovalFlowId: timeMaterialInvoiceDetail.TimeSheetApprovalFlowId,
      IsTimeSheetUsesProjects: timeMaterialInvoiceDetail.IsTimeSheetUsesProjects,
      IsTimesheetProjectMandatory: timeMaterialInvoiceDetail.IsTimeSheetUsesProjects ? timeMaterialInvoiceDetail.IsTimesheetProjectMandatory : null,
      VmsWorkOrderReference: timeMaterialInvoiceDetail.VmsWorkOrderReference,
      TimeSheetDescription: timeMaterialInvoiceDetail.TimeSheetDescription,
      OrganizationIdClient: timeMaterialInvoiceDetail.OrganizationIdClient
    }, { emitEvent: false });

    const timeSheetApproversFormArray = this.formGroup.get('TimeSheetApprovers') as FormArray<ITimeSheetApprover>;
    this.updateTimeSheetApproverFormArray(timeSheetApproversFormArray, timeMaterialInvoiceDetail.TimeSheetApprovers, validations.isTimeSheetApprovers);

    this.organizationIdFormControl.updateValueAndValidity();

  }

  addTimeSheetApproverDefinitionFormGroup() {
    const sequence = this.timeSheetApproverFormArray.length + 1;
    const formGroup = this.createBlankTimeSheetApproverFormArray(sequence);
    this.timeSheetApproverFormArray.push(formGroup);
  }

  removeTimeSheetApproverDefinitionFormGroup(index: number) {
    this.timeSheetApproverFormArray.removeAt(index);
    this.updateApproverSequences();
  }

  private updateApproverSequences() {
    this.timeSheetApproverFormArray.value.forEach((approver, index) => {
      this.timeSheetApproverFormArray.at(index).patchValue({
        ...approver,
        Sequence: index + 1
      }, { emitEvent: false });
    });
  }


  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabTimeMaterialInvoiceDetail {
    return {
      IsTimeSheetUsesProjects: workorder.WorkOrderVersion.IsTimeSheetUsesProjects,
      IsTimesheetProjectMandatory: workorder.WorkOrderVersion.IsTimesheetProjectMandatory,
      TimeSheetApprovalFlowId: workorder.WorkOrderVersion.TimeSheetApprovalFlowId,
      TimeSheetApprovers: workorder.WorkOrderVersion.TimeSheetApprovers,
      TimeSheetCycleId: workorder.WorkOrderVersion.TimeSheetCycleId,
      IsSeasonalOrPartTimeTimesheet: workorder.WorkOrderVersion.IsSeasonalOrPartTimeTimesheet,
      TimeSheetDescription: workorder.WorkOrderVersion.TimeSheetDescription,
      TimeSheetMethodologyId: workorder.WorkOrderVersion.TimeSheetMethodologyId,
      VmsWorkOrderReference: workorder.WorkOrderVersion.VmsWorkOrderReference,
      OrganizationIdClient: workorder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient
    };
  }

  private setTimeMaterialInvoiceDetailValidations(validations: any) {
    this.formGroup.get('TimeSheetCycleId').setValidators(
      validations.isTimeSheetCycleId
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetCycleId', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('TimeSheetApprovalFlowId').setValidators(
      validations.isTimeSheetApprovalFlowId
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'TimeSheetApprovalFlowId', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('IsTimeSheetUsesProjects').setValidators(
      validations.isTimeSheetUsesProjects
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsTimeSheetUsesProjects', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('VmsWorkOrderReference').setValidators(
      validations.isVmsWorkOrderReference
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'VmsWorkOrderReference', [
          Validators.required
        ])
        : null
    );

    this.formGroup.patchValue({
      TimeSheetCycleId: this.formGroup.get('TimeSheetCycleId').value || null,
      TimeSheetApprovalFlowId: this.formGroup.get('TimeSheetApprovalFlowId').value || null,
      IsTimeSheetUsesProjects: this.formGroup.get('IsTimeSheetUsesProjects').value || null,
      VmsWorkOrderReference: this.formGroup.get('VmsWorkOrderReference').value || null,
    }, { emitEvent: false });

    const formArray = this.formGroup.get('TimeSheetApprovers') as FormArray<ITimeSheetApprover>;
    this.setTimeSheetApproversValidators(formArray, validations.isTimeSheetApprovers);
  }

  private setTimeSheetApproversValidators(formArray: FormArray<ITimeSheetApprover>, isApproverValid: boolean) {
    formArray.controls.forEach(group => {
      group.get('UserProfileId').setValidators(
        isApproverValid
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.TimeSheetApprovers', 'UserProfileId', [
            Validators.required
          ])
          : null
      );
      group.patchValue({
        UserProfileId: group.get('UserProfileId').value || null
      }, { emitEvent: false });
    });
  }

  private setValidationsForTimeMaterialInvoiceDetails(TimeSheetMethodologyId: number, timeSheetApproversExists: boolean) {
    return {
      isTimeSheetMethodologyId: true,
      isSeasonalOrPartTimeTimesheet : true,
      isTimeSheetCycleId: (TimeSheetMethodologyId && TimeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.NoTimesheet),
      isTimeSheetApprovers: (TimeSheetMethodologyId && TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval),
      isTimeSheetApprovalFlowId: ((TimeSheetMethodologyId && TimeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.NoTimesheet) && timeSheetApproversExists),
      isTimeSheetUsesProjects: (TimeSheetMethodologyId && (TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
        || TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval)),
      isVmsWorkOrderReference: (TimeSheetMethodologyId && TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.ThirdPartyImport),
      isDisplayEstimatedPaymentAmount: (TimeSheetMethodologyId && TimeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.NoTimesheet)
    };
  }

  private createTimeSheetApproverFormArray(timesheetApprovers: Array<ITimeSheetApprover> = [], isApproverValid: boolean): FormArray<ITimeSheetApprover> {
    if (!timesheetApprovers || timesheetApprovers.length === 0) {
      timesheetApprovers = !timesheetApprovers ? [] : timesheetApprovers;
      const timeSheetApprover: ITimeSheetApprover = {
        Id: 0,
        IsDraft: true,
        MustApprove: true,
        Sequence: 1,
        SourceId: null,
        UserProfileId: null,
        WorkOrderVersionId: 0
      };
      timesheetApprovers.push(timeSheetApprover);
    }

    return this.fb.array<ITimeSheetApprover>(
      timesheetApprovers
        .sort((a1: ITimeSheetApprover, a2: ITimeSheetApprover) => a1.Sequence - a2.Sequence)
        .map((approver: ITimeSheetApprover) => this.createTimeSheetApproverFormGroup(approver, isApproverValid))
    );
  }

  private createTimeSheetApproverFormGroup(approver: ITimeSheetApprover, isApproverValid: boolean): FormGroup<ITimeSheetApprover> {
    return this.fb.group<ITimeSheetApprover>({
      Id: [approver.Id],
      IsDraft: [approver.IsDraft],
      MustApprove: [approver.MustApprove],
      Sequence: [approver.Sequence],
      SourceId: [approver.SourceId],
      UserProfileId: [
        approver.UserProfileId,
        isApproverValid
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.TimeSheetApprovers', 'UserProfileId', [
            Validators.required
          ])
          : null
      ],
      WorkOrderVersion: [approver.WorkOrderVersion],
      WorkOrderVersionId: [approver.WorkOrderVersionId]
    });
  }

  private updateTimeSheetApproverFormArray(
    formArray: FormArray<ITimeSheetApprover>,
    timesheetApprovers: Array<ITimeSheetApprover> = [],
    isApproverValid: boolean
  ) {
    if (!timesheetApprovers || timesheetApprovers.length === 0) {
      timesheetApprovers = !timesheetApprovers ? [] : timesheetApprovers;
      const timeSheetApprover: ITimeSheetApprover = {
        Id: 0,
        IsDraft: true,
        MustApprove: true,
        Sequence: 1,
        SourceId: null,
        UserProfileId: null,
        WorkOrderVersionId: 0
      };
      timesheetApprovers.push(timeSheetApprover);
    }

    if (formArray.length && timesheetApprovers.length) {
      timesheetApprovers.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<ITimeSheetApprover>;
        if (formGroup) {
          this.updateTimeSheetApproverFormGroup(formGroup, item, isApproverValid);
        } else {
          formArray.push(this.createTimeSheetApproverFormGroup(item, isApproverValid));
        }
      });
      if (formArray.length > timesheetApprovers.length) {
        this.clearArray(formArray, timesheetApprovers.length);
      }
    } else if (timesheetApprovers.length) {
      const array = this.createTimeSheetApproverFormArray(timesheetApprovers, isApproverValid);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }

  }

  private createBlankTimeSheetApproverFormArray(sequence: number): FormGroup<ITimeSheetApprover> {
    return this.fb.group<ITimeSheetApprover>({
      Id: 0,
      IsDraft: true,
      MustApprove: true,
      Sequence: sequence,
      SourceId: null,
      UserProfileId: [null, [Validators.required]],
      WorkOrderVersion: null,
      WorkOrderVersionId: 0
    });
  }

  private updateTimeSheetApproverFormGroup(
    formGroup: FormGroup<ITimeSheetApprover>,
    approver: ITimeSheetApprover,
    isApproverValid: boolean
  ) {

    formGroup.get('UserProfileId').setValidators(
      isApproverValid
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.TimeSheetApprovers', 'UserProfileId', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: approver.Id,
      IsDraft: approver.IsDraft,
      MustApprove: approver.MustApprove,
      Sequence: approver.Sequence,
      SourceId: approver.SourceId,
      UserProfileId: approver.UserProfileId,
      WorkOrderVersion: approver.WorkOrderVersion,
      WorkOrderVersionId: approver.WorkOrderVersionId
    }, { emitEvent: false });

  }

  private clearArray(formArray: FormArray<ITimeSheetApprover>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
