<div class="note-container">
    <section class="note-form">
        <mat-form-field class="w-100">
            <mat-label i18n="@@notes.writeANote">Write a note</mat-label>
            <textarea matInput [formControl]="noteControl" rows="3" maxlength="32000"
                (keydown.control.enter)="addNote()">
            </textarea>
            <mat-hint align="end" i18n="@@notes.writeANoteHint">Press (ctrl + enter) to add note</mat-hint>
        </mat-form-field>
        <div class="w-100 button-container">
            <div>
                <button type="button" class="mark-all-as-read" mat-button (click)="markAllNotesAsRead()"
                    [disabled]="unreadNoteCount < 1">
                    <mat-icon class="margin-10-r">drafts</mat-icon>
                    <span i18n="@@notes.markNotesAsRead">Mark All Notes as Read</span>
                </button>

                <button type="button" class="refresh" mat-button (click)="loadNotes()">
                    <mat-icon class="margin-10-r">refresh</mat-icon>
                    <span i18n="@@notes.refresh">Refresh</span>
                </button>
            </div>
            <div class="text-right">
                <button class="add-note-btn" type="button" mat-icon-button aria-label="Add note" (click)="addNote()">
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </div>
    </section>

    <section class="note-list">
        <div *ngIf="notes?.length > 0; else noNotesAvailable">
            <ng-container *ngFor="let note of notes; let i = index; trackBy: trackByNotes">
                <div class="note-list-item">
                    <div class="note-list-item-icon">
                        <mat-icon mat-list-icon>
                            <ng-container *ngIf="note.IsRead; else unreadIcon">drafts</ng-container>
                            <ng-template #unreadIcon>mail</ng-template>
                        </mat-icon>
                    </div>
                    <div class="note-list-item-details">
                        <div class="note-title-line">
                            <div>
                                <span [class.bold]="!note.IsRead">{{note.CommentOwnerName}}</span>
                                <ng-container *ngIf="note.Source"> - {{note.Source}} </ng-container>
                            </div>
                            <div>
                                {{note.CreatedDatetime | date:dateFormat.medium}}
                            </div>
                            <div class="delete-btn-container">
                                <button
                                    *ngIf="isCurrentUserHasAdminRole && entityTypeId === note.EntityTypeId && isEntityStatusDraft"
                                    type="button" mat-icon-button aria-label="Delete note" (click)="deleteNote(note)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="mat-line-comment"><span class="note-comment" [innerHTML]="note.Comment"></span>
                        </div>
                        <div class="button-line">
                            <button *ngIf="!note.IsRead; else markAsUnreadBtn" type="button" class="mark-as-read no-padding"
                                mat-button (click)="markRead(note, true)" i18n="@@notes.markAsRead">Mark as Read
                            </button>
                            <ng-template #markAsUnreadBtn>
                                <button type="button" class="mark-as-unread no-padding" mat-button
                                    (click)="markRead(note, false)">
                                    <mat-icon class="md-18">done</mat-icon>
                                    <span i18n="@@notes.markAsUnread">Mark as Unread</span>
                                </button>
                            </ng-template>
                        </div>
                        <mat-divider *ngIf="i !== notes.length -1"></mat-divider>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-template #noNotesAvailable>
            <div class="no-notes text-center">
                <h4 i18n="@@notes.noNotes">No notes here</h4>
                <p i18n="@@notes.useThisAreaToInform">Use this area to inform your team</p>
            </div>
        </ng-template>
    </section>
</div>
