<div class="content-group form-horizontal">
  <div class="content-left">
    <app-workorder-tab-core-details [readOnlyStorage]="readOnlyStorage">
    </app-workorder-tab-core-details>
    
    <app-workorder-tab-core-job [readOnlyStorage]="readOnlyStorage">
    </app-workorder-tab-core-job>

    <app-workorder-tab-core-collaborators [readOnlyStorage]="readOnlyStorage">
    </app-workorder-tab-core-collaborators>
  </div>
  <app-workorder-tab-core-termination *ngIf="readOnlyStorage.showTerminationSection">
  </app-workorder-tab-core-termination>
  <div class="content-right">
    <app-workorder-tab-core-commission [readOnlyStorage]="readOnlyStorage" *ngIf="!hideCommissions">
    </app-workorder-tab-core-commission>
  </div>
</div>