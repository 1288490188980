<app-phx-modal
  #enterAtsIdModal
  [buttons]="buttons"
  [showCloseButton]="true"
  cssClass="modal-lg"
  [title]="title"
  (closeModal)="closeModal()"
>
  <ng-container *ngIf="!previewForm; else workOrderPreview">
    <form
      *ngIf="atsIdSearchForm"
      class="form-horizontal"
      [formGroup]="atsIdSearchForm"
      (ngSubmit)="atsIdSearchForm.valid && checkATSId()"
    >
      <phx-form-control
        [control]="atsIdSearchForm.controls.id"
        [editable]="true"
        labelText="ATS Id"
        i18n-labelText="@@workorder.atsId"
      >
        <input
          class="form-control"
          [formControl]="atsIdSearchForm.controls.id"
          phxFloatBetweenInput='{"from":1, "to":999999, "decimalplaces":0}'
          type="text"
        />
      </phx-form-control></form
  ></ng-container>
</app-phx-modal>

<ng-template #workOrderPreview>
  <ng-container *ngIf="isATSCheckInProgress || (!isATSCheckInProgress && previewForm.controls.atsId.value); else noResults">
    <form
      [formGroup]="previewForm"
      class="form-horizontal"
    >
      <phx-form-control
        [control]="previewForm.controls.atsId"
        [editable]="false"
        labelText="ATS Id"
        i18n-labelText="@@workorder.atsId"
        [viewModeText]="previewForm.controls.atsId.value?.toString()"
        [canDisplayValue]="!isATSCheckInProgress"
      >
      </phx-form-control>
      <phx-form-control
        [control]="previewForm.controls.clientCompany"
        [editable]="false"
        labelText="Client Company"
        i18n-labelText="@@common.clientCompany"
        [viewModeText]="previewForm.controls.clientCompany.value"
        [canDisplayValue]="!isATSCheckInProgress"
      >
      </phx-form-control>
      <phx-form-control
        [control]="previewForm.controls.userProfileWorker"
        [editable]="false"
        labelText="User Profile Worker"
        i18n-labelText="@@common.userProfileWorker"
        [viewModeText]="previewForm.controls.userProfileWorker.value"
        [canDisplayValue]="!isATSCheckInProgress"
      >
      </phx-form-control>
      <phx-form-control
        [control]="previewForm.controls.lineOfBusiness"
        [editable]="false"
        labelText="Line of Business"
        i18n-labelText="@@common.lineOfBusiness"
        [viewModeText]="previewForm.controls.lineOfBusiness.value"
        [canDisplayValue]="!isATSCheckInProgress"
      >
      </phx-form-control>
      <phx-form-control
        [control]="previewForm.controls.placementDates"
        [editable]="false"
        labelText="Placement Dates"
        i18n-labelText="@@workorder.placementDates"
        [viewModeText]="previewForm.controls.placementDates.value"
        [canDisplayValue]="!isATSCheckInProgress"
      >
      </phx-form-control>
    </form>
  </ng-container>

  <ng-template #noResults
    ><p i18n="@@workOrder.noATSIdResult">No results found for ATS Id {{ atsIdSearchForm.controls.id.value }}.</p></ng-template
  >
</ng-template>
