<form [formGroup]="formGroup">
  <phx-form-control [control]="formGroup.controls.WorkerCompensationId" labelText="Worker Classification"
    [editable]="readOnlyStorage.IsEditable  && checkPtFiledAccessibility('WorkOrderVersion','WorkerCompensationId')"
    viewModeText="{{formGroup.controls.WorkerCompensationId.value | lookup: html.commonLists.wcbCodeList:'WorkerCompensationId':'DisplayText'}}">
    <app-phx-select-box [formControl]="formGroup.controls.WorkerCompensationId" [items]="html.commonLists.wcbCodeList"
      textField="DisplayText" valueField="WorkerCompensationId">
    </app-phx-select-box>
  </phx-form-control>
  <phx-form-control labelText="WCB Position Title" [control]="formGroup.controls.WCBPositionTitle"
    [editable]="readOnlyStorage.IsEditable"
    viewModeText="{{formGroup.controls.WCBPositionTitle.value}}">
    <input formControlName="WCBPositionTitle" class="form-control" type="text" maxlength="128"/>
  </phx-form-control>
  <phx-form-control labelText="Apply Worker's Compensation" [control]="formGroup.controls.WCBIsApplied"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','WCBIsApplied')"
    viewModeText="{{formGroup.controls.WCBIsApplied.value ? 'Yes' : 'No'}}">
    <label class="radio-inline">
      <input class="form-check-input" type="radio" [value]="true" formControlName="WCBIsApplied">Yes</label>
    <label class="radio-inline">
      <input class="form-check-input" type="radio" [value]="false" formControlName="WCBIsApplied">No</label>
  </phx-form-control>
</form>
