<form [formGroup]="inputFormGroup">
    <h4>Client Discount</h4>
    <phx-form-control [control]="inputFormGroup.controls.HasClientDiscount" labelText="Apply Discount"
        [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','HasClientDiscount')"
        viewModeText="{{inputFormGroup.controls.HasClientDiscount.value? 'Yes' : 'No'}}">
        <fieldset>
            <label class="radio-inline">
                <input class="form-check-input" name="HasClientDiscount" type="radio" [value]="true"
                    [checked]="inputFormGroup.controls.HasClientDiscount.value"
                    [formControl]="inputFormGroup.controls.HasClientDiscount">Yes</label>
            <label class="radio-inline">
                <input class="form-check-input" name="HasClientDiscount" type="radio" [value]="false"
                    [checked]="!inputFormGroup.controls.HasClientDiscount.value"
                    [formControl]="inputFormGroup.controls.HasClientDiscount">No</label>
        </fieldset>
    </phx-form-control>
    <div *ngIf="inputFormGroup.controls.HasClientDiscount.value">
        <div class="col-lg-4">
            <div class="text-right">
                <phx-form-control-label [control]="inputFormGroup.get('DiscountOptions')">Discount Options
                </phx-form-control-label>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="row">
                <div class="col-lg-6">
                    <phx-form-control [control]="inputFormGroup.controls.HasClientPercentDiscount"
                        [disabled]="readOnlyStorage.IsEditable" [layoutType]="phxFormControlLayoutType.InputOnly">
                        <label class="checkbox-inline">
                            <input type="checkbox" class="custom-control-input"
                                [attr.disabled]="!readOnlyStorage.IsEditable ? '' : null"
                                [formControl]="inputFormGroup.controls.HasClientPercentDiscount" value="true"> Percent
                            Discount
                        </label>

                    </phx-form-control>
                </div>
                <div class="col-lg-6" *ngIf="inputFormGroup.controls.HasClientPercentDiscount.value">
                    <phx-form-control [control]="inputFormGroup.controls.ClientPercentDiscount"
                        [editable]="readOnlyStorage.IsEditable" [layoutType]="phxFormControlLayoutType.InputOnly"
                        viewModeText="{{inputFormGroup.controls.ClientPercentDiscount?.value? ((inputFormGroup.controls.ClientPercentDiscount?.value/100) | percent:'1.2'): ''}}">
                        <div class="col-xl-12 input-group">
                            <input formControlName="ClientPercentDiscount" type="text"
                                phxFloatBetweenInput='{"from":0, "to":100.00, "decimalplaces":2}'
                                class="discount form-control" />
                            <span class="input-group-addon">%</span>
                        </div>
                    </phx-form-control>

                </div>
            </div>

            <div class="row paddingtop5">
                <div class="col-lg-6">
                    <phx-form-control [control]="inputFormGroup.controls.HasClientPerHourDiscount"
                        [layoutType]="phxFormControlLayoutType.InputOnly">
                        <label class="checkbox-inline">
                            <input type="checkbox" class="custom-control-input"
                                [attr.disabled]="!readOnlyStorage.IsEditable ? '' : null"
                                [formControl]="inputFormGroup.controls.HasClientPerHourDiscount" value="true"> Per
                            Hour Discount
                        </label>
                    </phx-form-control>
                </div>
                <div class="col-lg-6" *ngIf="inputFormGroup.controls.HasClientPerHourDiscount.value">
                    <phx-form-control
                      [control]="inputFormGroup.controls.ClientPerHourDiscount"
                      [layoutType]="phxFormControlLayoutType.InputOnly"
                      [editable]="readOnlyStorage.IsEditable"
                      [viewModeText]="inputFormGroup.controls.ClientPerHourDiscount?.value | currency"
                    >
                        <div class="col-xl-12 input-group">
                            <input formControlName="ClientPerHourDiscount" type="text"
                                phxFloatBetweenInput='{"from":0, "to":9999999999999.99, "decimalplaces":2}'
                                class="discount form-control" />
                            <span class="input-group-addon">$</span>
                        </div>
                    </phx-form-control>
                </div>
            </div>
            <div class="row paddingtop5">
                <div class="col-lg-6">
                    <phx-form-control [control]="inputFormGroup.controls.HasClientFlatDiscountAmount"
                        [layoutType]="phxFormControlLayoutType.InputOnly">
                        <label class="checkbox-inline">
                            <input type="checkbox" class="custom-control-input"
                                [attr.disabled]="!readOnlyStorage.IsEditable ? '' : null"
                                [formControl]="inputFormGroup.controls.HasClientFlatDiscountAmount" value="true"> Flat
                            Amount Discount
                        </label>
                    </phx-form-control>
                </div>
                <div class="col-lg-6" *ngIf="inputFormGroup.controls.HasClientFlatDiscountAmount.value">
                    <phx-form-control
                      [control]="inputFormGroup.controls.ClientFlatDiscountAmount"
                      [layoutType]="phxFormControlLayoutType.InputOnly"
                      [editable]="readOnlyStorage.IsEditable"
                      [viewModeText]="inputFormGroup.controls.ClientFlatDiscountAmount?.value | currency"
                    >
                        <div class="col-xl-12 input-group">
                            <input formControlName="ClientFlatDiscountAmount" type="text"
                                phxFloatBetweenInput='{"from":0, "to":9999999999999.99, "decimalplaces":2}'
                                class="discount form-control" />
                            <span class="input-group-addon">$</span>
                        </div>
                    </phx-form-control>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <phx-form-control [control]="inputFormGroup.controls.ClientDiscountDescription" labelText="Description"
                    [editable]="readOnlyStorage.IsEditable"
                    viewModeText="{{inputFormGroup.controls.ClientDiscountDescription?.value}}">
                    <div class="col-xl-12 input-group payroll-taxes-gray-box-fields paddingtop5">
                        <input [formControl]="inputFormGroup.controls.ClientDiscountDescription" type="text"
                            class="form-control" maxlength="64" />
                    </div>
                </phx-form-control>
            </div>
            <div class="col-lg-4">
                <div class="text-right">
                    <label class="control-label label-to-right">Effective Bill Rate
                    </label>
                </div>
            </div>
            <div class="col-lg-4">
                <label class="control-label">
                    {{ effectiveBillRate | currency }}
                </label>
            </div>
        </div>
    </div>
</form>
