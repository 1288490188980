import { CodeValueGroups, DialogComment, OnClickStateActionOption, StateAction, StateActionButtonsOption, UserProfile } from '../../../common/model';
// angular
import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// common
import { ApiService, CommonService, DialogService, PhxConstants, PhxLocalizationService } from '../../../common';
import { PhxDialogComponent } from '../../../common/components/phx-dialog/phx-dialog.component';
import {
  PhxDialogComponentConfigModel,
  PhxDialogComponentConfigModelButton,
  PhxDialogComponentConfigModelComment,
  PhxDialogComponentConfigModelDate,
  PhxDialogComponentConfigModelDropdown,
  PhxDialogComponentEventEmitterInterface
} from '../../../common/components/phx-dialog/phx-dialog.component.model';
import { filter, forEach, values } from 'lodash';
// workOrder
import { IWorkOrder, IWorkOrderCreateTransactionPreCheckDto } from '../../models';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { WorkorderTerminateModalComponent } from '../workorder-terminate-modal/workorder-terminate-modal.component';
import { CommandResponse } from 'src/app/common/model';
import ReportType = PhxConstants.ReportType;
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { UserBehaviorService } from 'src/app/common/services/user-behavior.service';
import { ATS_INTEGRATION_ENABLED } from '../../services';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-workorder-workflow',
  templateUrl: './workorder-workflow.component.html'
})
export class WorkOrdernWorkflowComponent extends BaseComponentOnDestroy {
  static currentProfile: UserProfile;

  readonly codeValueGroups = CodeValueGroups;

  @Output() commandExecuted = new EventEmitter<any>();
  @Output() commandValidation = new EventEmitter<any>();

  @ViewChild('modalTemplate', { static: true }) modalTemplate: PhxModalComponent;
  workOrderDetails: IWorkOrder;

  @ViewChild(PhxDialogComponent, { static: true })
  phxDialogComponent: PhxDialogComponent;

  @ViewChild('phxModalComponent', { static: true })
  phxModalComponent: PhxModalComponent;

  @ViewChild('TerminationModal', { static: true })
  terminationModal: WorkorderTerminateModalComponent;

  private apiError: CommandResponse;
  phxDialogComponentConfigModel: PhxDialogComponentConfigModel = null;
  private modelvalMessages: Array<any> = [];
  showPhxModal = false;
  isCommentValid = true;

  dateRangeWarningMessageTitle = 'Transaction Date Range Warning';
  // eslint-disable-next-line max-len
  dateRangeWarningMessageBody = 'You are creating a transaction outside of this Work Order\'s date range. You may Cancel and select a different Work Order or click Continue to proceed using this Work Order.';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private localizationService: PhxLocalizationService,
    private userBehaviorService: UserBehaviorService,
    @Inject(ATS_INTEGRATION_ENABLED) public isTenantATSIntegrationEnabled$: Observable<boolean>
  ) {
    super();
  }

  public onClickStateAction(
    action: StateAction, componentOption: StateActionButtonsOption,
    actionOption: OnClickStateActionOption, workOrder: IWorkOrder,
    onStateActionComplete: (response?: CommandResponse) => void = null,
    isUserRefreshRequired: boolean = true
  ) {
    const workorder = { ...workOrder };
    delete workorder.RootObject;
    delete workorder.readOnlyStorage;
    workorder.WorkOrderVersion.TimeSheetApprovers = workorder.WorkOrderVersion.TimeSheetApprovers ? workorder.WorkOrderVersion.TimeSheetApprovers : [];
    workorder.WorkOrderVersion.ExpenseApprovers = workorder.WorkOrderVersion.ExpenseApprovers ? workorder.WorkOrderVersion.ExpenseApprovers : [];
    const stateActionsMap = {
      [PhxConstants.StateAction.WorkOrderVersionApprove]: async () => {
        const hasReadAllNotes = await lastValueFrom(this.apiService.query<boolean>(`note/hasUserReadAllNotes/${PhxConstants.EntityType.WorkOrder}/${workorder.WorkOrderId}`));
        if (hasReadAllNotes) {
          this.phxDialogComponentConfigModel = {
            HeaderTitle: 'Approve Workorder',
            BodyMessage: 'Are you sure you want to approve this Workorder?',
            Buttons: [
              {
                Id: 1,
                SortOrder: 1,
                CheckValidation: true,
                Name: 'Yes',
                Class: 'btn-primary',
                ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                  this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Approved');
                }
              },
              { Id: 2, SortOrder: 2, CheckValidation: false, Name: 'No', Class: 'btn-default' }
            ],
            ObjectDate: null,
            ObjectComment: null
          };

          this.phxDialogComponent.open();
        } else {
          this.handleNotesUnreadError('You have to read (mark as read) all the notes before approving this work order.', workorder);
        }
      },

      [PhxConstants.StateAction.WorkOrderVersionRecallToDraft]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Recall Workorder',
          BodyMessage: 'Are you sure you want to recall this Workorder?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Recalled');
              }
            },
            { Id: 2, SortOrder: 2, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderVersionDecline]: async () => {
        const hasReadAllNotes = await lastValueFrom(this.apiService.query<boolean>(`note/hasUserReadAllNotes/${PhxConstants.EntityType.WorkOrder}/${workorder.WorkOrderId}`));
        if (hasReadAllNotes) {
          this.dialogService.commentWithReasons({
              title: action.displayText,
              helpBlock: this.localizationService.translate('common.phxWorkflowButtons.declineHelpblock', action.displayText),
              label: this.localizationService.translate('common.phxWorkflowButtons.declineReasonLabel', action.displayText.toLowerCase()),
              maxLength: 1024,
              saveButtonText: action.displayText,
              showSelectionControls: false,
              showDeclineReasonList: true,
              entityTypeId: PhxConstants.EntityType.WorkOrderVersion
            },
            { size: 'md' }
          ).then(dialogComment => {
            this.commandExecuteByApi(workorder, action.actionId, action.commandName, 'Work Order declined', null, null, null, null, null, true, dialogComment);
          }, (err) => {
            console.error(err)
          });
        } else {
          this.handleNotesUnreadError('You have to read (mark as read) all the notes before declining this work order.', workorder);
        }
      },

      [PhxConstants.StateAction.AssignmentExtend]: () => {
        this.workOrderDetails = workOrder;
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Extend Work Order',
          BodyMessage: 'This Work Order will be extended. Continue?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Continue',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Extended');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Cancel', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderVersionDiscard]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved ? 'Cancel Work Order' : 'Discard Work Order',
          BodyMessage: workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved 
          ? 'Are you sure you want to cancel this Workorder?' : 'Are you sure you want to discard this Workorder?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(
                  workorder,
                  action.actionId,
                  action.commandName,
                  callBackObj.config,
                  workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved ? 'Work Order Cancelled' : 'Work Order Discarded'
                );
              }
            },
            { Id: 2, SortOrder: 2, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderVersionDeclineActivation]: () => {
        this.commandExecuteByApi(workorder, action.actionId, action.commandName, 'Work Order declined', null, null, null, actionOption.comment, null, true, null);
      },

      [PhxConstants.StateAction.WorkOrderUnterminate]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Unterminate Work Order',
          BodyMessage: 'Please enter your reason for unterminating this work order. Your reason will be stored in the Notes section',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Unterminate',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Untermination request submitted');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Cancel', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: {
            Label: null,
            HelpBlock: null,
            Value: '',
            IsRequired: true,
            LengthMin: 10,
            LengthMax: 8000
          },
          CommentValidationMessage: [
            {
              Message: 'Please enter at least 10 characters',
              showMessage: () => {
                return this.phxDialogComponentConfigModel.ObjectComment.Value.length < this.phxDialogComponentConfigModel.ObjectComment.LengthMin;
              }
            },
            {
              Message: 'Comment length cannot be greater than 8000 characters',
              showMessage: () => {
                return this.phxDialogComponentConfigModel.ObjectComment.Value.length > this.phxDialogComponentConfigModel.ObjectComment.LengthMax;
              }
            }
          ]
        };
        this.showPhxModal = true;
        this.phxModalComponent.show();
      },
      [PhxConstants.StateAction.WorkOrderTerminate]: () => {
        this.terminationModal.showModal(workOrder);
      },
      [PhxConstants.StateAction.WorkOrderReviseTermination]: () => {
        this.terminationModal.showModal(workOrder, true);
      },
      [PhxConstants.StateAction.WorkOrderVersionApproveReactivation]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Unterminate Work Order',
          BodyMessage: 'Are you sure you want to Unterminate this Workorder?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Work Order Unterminated');
              }
            },
            { Id: 2, SortOrder: 2, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderReleaseVacationPay]: () => {
        this.router.navigate(['/next', 'transaction', 'releasevacationpay', workOrder.WorkOrderVersion.Id]);
      },

      [PhxConstants.StateAction.WorkOrderCreateGovernmentAdjustment]: () => {
        this.apiService.query<IWorkOrderCreateTransactionPreCheckDto>(`assignment/getWorkOrderCreateAdjustmentTransactionPreCheck/${workorder.WorkOrderId}`)
          .subscribe(result => {
            const workOrderStartDate = new Date(workorder.StartDate);
            const workOrderEndDate = new Date(workorder.EndDate);
            const transactionDate = new Date(result.CalculatedTransactionDate);

            if (transactionDate >= workOrderStartDate && transactionDate <= workOrderEndDate) {
              this.router.navigate(['/next', 'workorder', 'transaction', 'adjustment', workOrder.UserProfileIdWorker, workOrder.WorkOrderId]);
            } else {
              this.phxDialogComponentConfigModel = {
                HeaderTitle: this.dateRangeWarningMessageTitle,
                BodyMessage: this.dateRangeWarningMessageBody,
                Buttons: [
                  {
                    Id: 1,
                    SortOrder: 2,
                    CheckValidation: true,
                    Name: 'Continue',
                    Class: 'btn-primary',
                    ClickEvent: () => {
                      this.router.navigate(['/next', 'workorder', 'transaction', 'adjustment', workOrder.UserProfileIdWorker, workOrder.WorkOrderId]);
                    }
                  },
                  { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Cancel', Class: 'btn-default' }
                ],
                ObjectDate: null,
                ObjectComment: null
              };
              this.phxDialogComponent.open();
            }
          });
      },

      [PhxConstants.StateAction.WorkOrderCreateTransaction]: () => {
        this.apiService.query<IWorkOrderCreateTransactionPreCheckDto>(`assignment/getWorkOrderCreateManualTransactionPreCheck/${workorder.WorkOrderId}`)
          .subscribe(result => {
            const workOrderStartDate = new Date(workorder.StartDate);
            const workOrderEndDate = new Date(workorder.EndDate);
            const transactionDate = new Date(result.CalculatedTransactionDate);

            if (transactionDate >= workOrderStartDate && transactionDate <= workOrderEndDate) {
              this.commandExecuteByApi(workorder, action.actionId, action.commandName, null, null, null);
            } else {
              this.phxDialogComponentConfigModel = {
                HeaderTitle: this.dateRangeWarningMessageTitle,
                BodyMessage: this.dateRangeWarningMessageBody,
                Buttons: [
                  {
                    Id: 1,
                    SortOrder: 2,
                    CheckValidation: true,
                    Name: 'Continue',
                    Class: 'btn-primary',
                    ClickEvent: () => {
                      this.commandExecuteByApi(workorder, action.actionId, action.commandName, null, null, null);
                    }
                  },
                  { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Cancel', Class: 'btn-default' }
                ],
                ObjectDate: null,
                ObjectComment: null
              };
              this.phxDialogComponent.open();
            }
          });
      },

      [PhxConstants.StateAction.WorkOrderVersionEdit]: () => {
        this.workOrderDetails = workOrder;
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Correct a Work Order Version',
          BodyMessage: 'This action will replace the selected work order version',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Proceed',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Corrected');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Discard', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: {
            Label: 'Enter reason for correction',
            HelpBlock: '',
            Value: '',
            IsRequired: true,
            LengthMin: 1,
            LengthMax: 1000
          }
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderVersionTerminatedEdit]: () => {
        this.workOrderDetails = workOrder;
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Correct a Work Order Version for Terminated Work Order',
          BodyMessage: 'This action will replace the selected work order version',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Proceed',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Corrected');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Discard', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: {
            Label: 'Enter reason for correction',
            HelpBlock: '',
            Value: '',
            IsRequired: true,
            LengthMin: 1,
            LengthMax: 1000
          }
        };
        this.phxDialogComponent.open();
      },

      [PhxConstants.StateAction.WorkOrderScheduleChange]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Schedule a Work Order Change',
          BodyMessage: 'This action will create a new work order version',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Proceed',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Work Order Schedule Changed');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Discard', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: {
            Label: 'Enter the reason for the schedule change',
            HelpBlock: '',
            Value: null,
            IsRequired: true,
            LengthMin: 1,
            LengthMax: 1000
          }
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderVersionFinalize]: () => {
        workorder.WorkOrderVersion.TimeSheetPreviousApprovalRequired = false;
        this.commandExecuteByApi(workorder, action.actionId, action.commandName, 'Work Order submitted for review', null, null);
      },

      [PhxConstants.StateAction.WorkOrderVersionSubmit]: () => {
        workorder.WorkOrderVersion.TimeSheetPreviousApprovalRequired = false;
        this.commandExecuteByApi(workorder, action.actionId, action.commandName, null, null, null, null, null, (response: CommandResponse) => {
          if (response.CustomPayload?.message) {
            this.commonService.logSuccess(response.CustomPayload.message);
          } else {
            this.commonService.logSuccess('Work Order Submitted');
          }
        });
      },

      [PhxConstants.StateAction.WorkOrderVersionSave]: () => {
        this.editableWorkOrderStartDateState(workorder).subscribe(editable => {
          workorder.StartDate = editable ? workorder.WorkOrderVersion.WorkOrderStartDateState : workorder.StartDate;
          this.commandExecuteByApi(workorder, action.actionId, action.commandName, 'Work Order Saved', null, null, null, null, onStateActionComplete, isUserRefreshRequired);
        });
      },

      [PhxConstants.StateAction.WorkOrderVersionReSyncATS]: () => {
        this.editableWorkOrderStartDateState(workorder).subscribe(editable => {
          workorder.StartDate = editable ? workorder.WorkOrderVersion.WorkOrderStartDateState : workorder.StartDate;
          this.commandExecuteByApi(workorder, action.actionId, action.commandName, 'Work Order synced with ATS', null, null);
        });
      },

      [PhxConstants.StateAction.WorkOrderReactivate]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Workorder',
          BodyMessage: 'This Work Order will be changed. Continue?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Activated');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderStopPayment]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Stop Payments',
          BodyMessage: 'Are you sure you want to stop payments for all parties in this work order?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Payments Stopped');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderMarkOffboarding]: () => {
        this.workOrderDetails = workOrder;
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Offboard Work Order',
          BodyMessage: 'This Work Order will start offboarding. Continue?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Continue',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Offboarding');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'Cancel', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderResumePayment]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Resume Payments',
          BodyMessage: 'Are you sure you want to Resume payments for all parties in this work order?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Payments Resumed');
              }
            },
            { Id: 2, SortOrder: 2, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderVersionRecallToCompliance]: () => {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Workorder',
          BodyMessage: 'This Work Order will be changed. Continue?',
          Buttons: [
            {
              Id: 1,
              SortOrder: 2,
              CheckValidation: true,
              Name: 'Yes',
              Class: 'btn-primary',
              ClickEvent: (callBackObj: PhxDialogComponentEventEmitterInterface) => {
                this.commandExecuteByDialog(workorder, action.actionId, action.commandName, callBackObj.config, 'Workorder Recalled to Compliance Draft');
              }
            },
            { Id: 2, SortOrder: 1, CheckValidation: false, Name: 'No', Class: 'btn-default' }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
      },
      [PhxConstants.StateAction.WorkOrderVersionAddAtsId]: () => {
        this.commandExecuteByApi({...workorder, AtsPlacementId: actionOption.atsPlacementId}, action.actionId, action.commandName);
      }
    };

    if (action.commandName === PhxConstants.CommandNamesSupportedByUi.WorkOrderActionCommand.workOrderSaveAsTemplate) {
      this.workOrderDetails = workOrder;
      this.modalTemplate.show();
      return;
    }
    stateActionsMap[action.actionId]();
  }

  editableWorkOrderStartDateState(workorder: IWorkOrder): Observable<boolean> {
    return this.isTenantATSIntegrationEnabled$.pipe(map(isTenantATSIntegrationEnabled => {
      if (!this.displayWorkOrderStartDateState(workorder)) {
        return false;
      }

      if (isTenantATSIntegrationEnabled && workorder.AtsPlacementId > 0) {
        return this.editableStartAndEndDate(workorder);
      } else {
        return true;
      }
    }));
  }

  editableStartAndEndDate(workorder: IWorkOrder) {
    let editable = false;
    if (workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.New
      && workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingReview) {
      editable = true;
    } else if (this.currentProfileUnderAccountingRole() || this.currentProfileIsSystemAdministrator()) {
      if (workorder.WorkOrderVersion.IsDraftStatus) {
        editable = true;
      }

      if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.Extend) {
        editable = true;
      }

      if (workorder.WorkOrderVersion.IsDraftStatus && this.correction(workorder)) {
        editable = true;
      }

      if (workorder.WorkOrderVersion.IsDraftStatus && workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange) {
        editable = false;
      }
    }

    return editable;
  }

  displayWorkOrderStartDateState(workorder: IWorkOrder) {
    return (
      (workorder.WorkOrderVersion.IsDraftStatus || workorder.WorkOrderVersion.IsComplianceDraftStatus || workorder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingReview) &&
      (workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionEarliest ||
        workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionUnique)
    );
  }

  currentProfileUnderAccountingRole() {
    return (
      filter(WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles, item => (
        item.FunctionalRoleId === PhxConstants.FunctionalRole.Finance ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.Controller ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOffice ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.AccountsReceivable ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOfficeARAP
      )).length > 0
    );
  }

  currentProfileIsSystemAdministrator() {
    return (
      filter(WorkOrdernWorkflowComponent.currentProfile.FunctionalRoles, item => item.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator).length > 0
    );
  }

  /// assignment filed accessibility
  correction(workorder: IWorkOrder) {
    return (
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionEarliest ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionLatest ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionMiddle ||
      workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.CorrectWorkOrderVersionUnique
    );
  }

  onPhxModalButtonClick(button: PhxDialogComponentConfigModelButton) {
    this.phxModalComponent.hide();
    this.showPhxModal = false;
    this.isCommentValid = true;
    const callBackObj: PhxDialogComponentEventEmitterInterface = {
      buttonId: button.Id,
      config: this.phxDialogComponentConfigModel
    };
    if (button.ClickEvent !== null) {
      button.ClickEvent(callBackObj);
    }
  }

  onCommentChange() {
    if (this.phxDialogComponentConfigModel?.ObjectComment?.Value) {
      this.isCommentValid = this.phxDialogComponentConfigModel.ObjectComment.Value.length >= this.phxDialogComponentConfigModel.ObjectComment.LengthMin &&
        this.phxDialogComponentConfigModel.ObjectComment.Value.length <= this.phxDialogComponentConfigModel.ObjectComment.LengthMax;
    }
  }

  isUnterminateBtnDisabled(btn: any) {
    return (btn?.CheckValidation && this.phxDialogComponentConfigModel
      && (this.phxDialogComponentConfigModel.ObjectDate !== null
        || (this.phxDialogComponentConfigModel.ObjectComment?.IsRequired
          && (this.phxDialogComponentConfigModel.ObjectComment.Value.length < this.phxDialogComponentConfigModel.ObjectComment.LengthMin
            || this.phxDialogComponentConfigModel.ObjectComment.Value.length > this.phxDialogComponentConfigModel.ObjectComment.LengthMax)
        )));
  }

  terminateWorkorder(event) {
    if (!event) {
      return;
    }
    this.apiError = event.error;
    this.parseValidationMessages(event.workorderVersionId);
  }

  handleNotesUnreadError(message: string, workorder: IWorkOrder) {
    this.phxDialogComponentConfigModel = {
      HeaderTitle: 'You did not read all the notes',
      BodyMessage: message,
      Buttons: [
        {
          Id: 1,
          SortOrder: 1,
          CheckValidation: false,
          Name: 'Review Notes',
          Class: 'btn-primary',
          ClickEvent: () => {
            this.navigateTo(workorder.AssignmentId, workorder.WorkOrderId, workorder.WorkOrderVersion.Id, PhxConstants.WorkorderNavigationName.notes);
          }
        }
      ]
    };

    this.phxDialogComponent.open();
  }

  private commandExecuteByDialog(
    workorder: IWorkOrder,
    actionId: number,
    commandName: string,
    model: PhxDialogComponentConfigModel,
    messageOnSuccessResponse?: string,
    additionalComments?: any,
    onStateActionComplete?: () => void) {
    this.phxDialogComponent.close();
    this.commandExecuteByApi(workorder, actionId, commandName, messageOnSuccessResponse, model.ObjectDate, model.ObjectComment, model.ObjectDropdown, additionalComments, onStateActionComplete);
  }

  private navigateTo(assignmentId: number, workorderId: number, workorderVersionId: number, tabNavigationName: PhxConstants.WorkorderNavigationName) {
    const navigatePath = `/next/workorder/${assignmentId}/${workorderId}/${workorderVersionId}/${tabNavigationName}`;
    return this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent });
  }

  private commandExecuteByApi(
    workorder: IWorkOrder,
    actionId: number,
    commandName: string,
    messageOnSuccessResponse?: string,
    phxDialogComponentConfigModelDate?: PhxDialogComponentConfigModelDate, // ie. TerminationDate
    phxDialogComponentConfigModelComment?: PhxDialogComponentConfigModelComment, // ie. AdditionalNotes
    phxDialogComponentConfigModelDropdown?: PhxDialogComponentConfigModelDropdown, // ie. TerminationReasonId
    otherComments?: any, // ie. Decline Comments,
    onStateActionComplete?: (responseSuccessOnExecuteCommand?: CommandResponse) => void,
    isUserRefreshRequired: boolean = true,
    dialogComment?: DialogComment
  ) {

    const navigateToSearch = () => {
      this.router.navigate([`/next/report-azsearch/${ReportType.WorkOrderReport}`]);
    };

    this.phxDialogComponent.close();
    this.apiError = null;

    let commandBody: any = null;
    const templateId = workorder.TemplateId;
    const workorderId = workorder.WorkOrderId;
    const assignmentId = workorder.AssignmentId;
    const versionId = workorder.WorkOrderVersion.Id;

    switch (actionId) {
      case PhxConstants.StateAction.AssignmentExtend:
        commandBody = {
          EntityIds: [workorder.AssignmentId]
        };
        break;
      case PhxConstants.StateAction.WorkOrderScheduleChange:
      case PhxConstants.StateAction.WorkOrderUnterminate:
        commandBody = {
          EntityIds: [workorder.WorkOrderId],
          Comment: phxDialogComponentConfigModelComment.Value
        };
        break;
      case PhxConstants.StateAction.WorkOrderMarkOffboarding:
      case PhxConstants.StateAction.WorkOrderStopPayment:
      case PhxConstants.StateAction.WorkOrderResumePayment:
      case PhxConstants.StateAction.WorkOrderReactivate:
      case PhxConstants.StateAction.WorkOrderCreateTransaction:
      case PhxConstants.StateAction.WorkOrderReleaseVacationPay:
      case PhxConstants.StateAction.WorkOrderCreateGovernmentAdjustment:
        commandBody = {
          EntityIds: [workorder.WorkOrderId]
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionSave:
      case PhxConstants.StateAction.WorkOrderVersionReSyncATS:
      case PhxConstants.StateAction.WorkOrderVersionSubmit:
      case PhxConstants.StateAction.WorkOrderVersionFinalize:
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id],
          ...workorder
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionDecline:
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id],
          Comment: dialogComment.Comment,
          DeclineReasonIds: dialogComment?.DeclineReasons?.toString()
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionDeclineActivation:
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id],
          Comment: otherComments,
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionTerminatedEdit:
      case PhxConstants.StateAction.WorkOrderVersionEdit:
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id],
          Comment: phxDialogComponentConfigModelComment.Value
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionApprove:
      case PhxConstants.StateAction.WorkOrderVersionRecallToDraft:
      case PhxConstants.StateAction.WorkOrderVersionRecallToCompliance:
      case PhxConstants.StateAction.WorkOrderVersionDiscard:
      case PhxConstants.StateAction.WorkOrderVersionApproveReactivation:
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id]
        };
        break;
      case PhxConstants.StateAction.WorkOrderVersionAddAtsId: {
        commandBody = {
          EntityIds: [workorder.WorkOrderVersion.Id],
          AtsPlacementId: +workorder.AtsPlacementId
        };
        break;
      }
      default:
        alert('Action is not supported');
        break;
    }

    this.apiError = null;

    this.apiService
      .command(commandName, commandBody)
      .then(responseSuccessOnExecuteCommand => {
        this.modelvalMessages = [];
        this.commandValidation.emit(this.modelvalMessages);
        if (!responseSuccessOnExecuteCommand.IsValid) {
          return;
        }
        this.userBehaviorService.trackBehavior(PhxConstants.UserBehaviorCode.UpdateEntity, PhxConstants.EntityType.WorkOrder, workorder.WorkOrderVersion.Id);

        if (messageOnSuccessResponse?.length > 0) {
          this.commonService.logSuccess(messageOnSuccessResponse);
        }

        /** NOTE: after schedule change submit - message user to update any WOV with a later effective date */
        /** NOTE: in post schedule change submit - current WO is in draft, we dont want to do this check when switching to edit when creation reason 
            * is still schedule change*/
        if (workorder.WorkOrderVersion.WorkOrderCreationReasonId === PhxConstants.WorkOrderCreationReason.ScheduleChange
            && workorder.WorkOrderVersion.IsDraft 
        ) {
          const currentWovVersionNumber = workorder.WorkOrderVersion.VersionNumber;
          const currentWoId = workorder.WorkOrderId;
          const wovHeaders = workorder.WorkOrderHeaders.find(header => header.Id === currentWoId)?.WorkOrderVersionHeaders ?? [];

          if(wovHeaders.length === currentWovVersionNumber){
            const currentEffectiveDateAsInt = parseInt(workorder.WorkOrderVersion.EffectiveDate.replace(/-/g, ''), 10);
            if(wovHeaders.some(header => parseInt(header.EffectiveDate.replace(/-/g, ''), 10) > currentEffectiveDateAsInt)){
              const currentEffectiveDate = new Date(workorder.WorkOrderVersion.EffectiveDate);
              const noTzEffectiveDate = new Date(currentEffectiveDate.getTime() + currentEffectiveDate.getTimezoneOffset() * 60 * 1000);

              this.dialogService.notify(
                'Effective Date', 
                `Please update all Work Order Versions with an effective date greater than ${noTzEffectiveDate.toLocaleDateString('en-CA', {year: 'numeric', month: 'long', day: 'numeric'})}.`
              );
            }
          }
        }

        if (responseSuccessOnExecuteCommand.EntityTypeIdRedirect > 0 && responseSuccessOnExecuteCommand.EntityIdRedirect > 0) {
          // if there is EntityIdRedirect
          if (responseSuccessOnExecuteCommand.EntityTypeIdRedirect === PhxConstants.EntityType.WorkOrderVersion) {
            // if EntityTypeIdRedirect is work order version
            if (responseSuccessOnExecuteCommand.EntityIdRedirect !== versionId) {
              // navigation to a different version
              this.commandExecuted.emit({ templateId, versionId, assignmentId, workorderId });
              this.navigateTo(0, 0, responseSuccessOnExecuteCommand.EntityIdRedirect, PhxConstants.WorkorderNavigationName.core);
            } else {
              // reloading the same version
              this.commandExecuted.emit({ templateId, versionId, assignmentId, workorderId });
            }
          } else if (responseSuccessOnExecuteCommand.EntityTypeIdRedirect === PhxConstants.EntityType.TransactionHeader) {
            // if EntityTypeIdRedirect is transaction header
            this.commandExecuted.emit({ templateId, versionId, assignmentId, workorderId });
            this.router.navigate(['/next', 'transaction', responseSuccessOnExecuteCommand.EntityIdRedirect, PhxConstants.TransactionNavigationName.detail], { relativeTo: this.activatedRoute.parent });
          } else {
            navigateToSearch();
          }
          return; // already navigated away
        }

        if (actionId === PhxConstants.StateAction.WorkOrderVersionDiscard) {
          // if work order version is deleted and there's no redirect entity, UI should navigate back to search view
          navigateToSearch();
        } else if ((actionId === PhxConstants.StateAction.WorkOrderVersionSave
          || actionId === PhxConstants.StateAction.WorkOrderVersionFinalize
          || actionId === PhxConstants.StateAction.WorkOrderVersionSubmit
          || actionId === PhxConstants.StateAction.WorkOrderVersionApprove
          || actionId === PhxConstants.StateAction.WorkOrderVersionRecallToDraft
          || actionId === PhxConstants.StateAction.WorkOrderVersionRecallToCompliance
          || actionId === PhxConstants.StateAction.WorkOrderVersionAddAtsId)
          && isUserRefreshRequired) {
          this.commandExecuted.emit({ templateId, versionId, assignmentId, workorderId });
          this.navigateTo(workorder.AssignmentId, workorder.WorkOrderId, workorder.WorkOrderVersion.Id, PhxConstants.WorkorderNavigationName.core);
        } else if (isUserRefreshRequired) {
          this.commandExecuted.emit({ templateId, versionId, assignmentId, workorderId });
        }
        if (onStateActionComplete) {
          onStateActionComplete(responseSuccessOnExecuteCommand);
        }
      })
      .catch((error: CommandResponse) => {
        this.apiError = error;
        if (error?.ValidationMessages?.some(x => x.Code === PhxConstants.ValidationCodes.UserHasNotReadAllEntityNotes)) {
          const message = error.ValidationMessages.find(x => x.Code === PhxConstants.ValidationCodes.UserHasNotReadAllEntityNotes).Message;
          this.handleNotesUnreadError(message, workorder);
        } else {
          this.parseValidationMessages(versionId);
        }
      });
  }

  private parseValidationMessages(versionId: number) {
    this.modelvalMessages = [];
    let checkInvalid = true;
    if (this.apiError) {
      Object.keys(this.apiError).forEach(item => {
        if (item === 'ValidationMessages' && Object.keys(this.apiError[item]).length) {
          checkInvalid = false;
          const val = values(this.apiError.ValidationMessages);
          forEach(val, value => {
            this.modelvalMessages.push(value.Message);
          });
        }
        if (checkInvalid) {
          if (item === 'ModelState' && Object.keys(this.apiError[item]).length) {
            const val = values(this.apiError.ModelState);
            forEach(val, value => {
              for (const msg of value) {
                this.modelvalMessages.push(msg);
              }
            });
          }
        }
      });

      if (this.modelvalMessages.length > 0) {
        this.commonService.logError('Your Submission has ' + this.modelvalMessages.length + ' validation message(s)');
      }

      this.commandValidation.emit({
        versionId,
        apiError: this.apiError,
        messages: this.modelvalMessages
      });
    } else {
      this.commandValidation.emit({
        versionId,
        apiError: null,
        messages: null
      });
    }
  }
}
