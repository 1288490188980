import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IPaymentContact, IPaymentPartiesRateDetail, IReadOnlyStorage } from '../../models';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { WorkorderService } from '../../services';
import { PhxConstants } from '../../../common/model';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

interface IHtml {
  commonLists: {
    listProfilesForApproval: Array<any>;
    listProfilesForPaymentOrganization: Array<any>;
  };
}

@Component({
  selector: 'app-workorder-payment-contact',
  templateUrl: './workorder-payment-contact.component.html',
  styleUrls: ['./workorder-payment-contact.component.less']
})
export class WorkorderPaymentContactComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() inputFormGroup: FormGroup<IPaymentContact>;
  @Input() paymentInfo: FormGroup<IPaymentPartiesRateDetail>;
  @Input() contactIndex: number;
  @Input() organizationIdSupplier: number;
  @Output() deleteContactEvent = new EventEmitter();

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  html: IHtml = {
    commonLists: {
      listProfilesForApproval: [],
      listProfilesForPaymentOrganization: []
    }
  };

  constructor(
    private workOrderService: WorkorderService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.workOrderService.getProfilesListByOrganizationId(this.organizationIdSupplier)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        const list = response ? response.map(item => item.Data) : [];
        this.html.commonLists.listProfilesForPaymentOrganization = this.html.commonLists.listProfilesForPaymentOrganization.concat(list);
        this.html.commonLists.listProfilesForPaymentOrganization.forEach(element => {
          element.DisplayValue = element.Contact.FullName + ' - ' + element.Contact.Id;
        });
        this.html.commonLists.listProfilesForPaymentOrganization = this.html.commonLists.listProfilesForPaymentOrganization.filter(
          i =>
            i.ProfileStatusId !== PhxConstants.ProfileStatus.Inactive &&
            i.ProfileStatusId !== PhxConstants.ProfileStatus.PendingDeactivation &&
            i.ProfileStatusId !== PhxConstants.ProfileStatus.PendingActivation &&
            i.ProfileTypeId !== PhxConstants.UserProfileType.WorkerSubVendor
        );
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  removePaymentContact(contactIndex: number) {
    this.deleteContactEvent.emit(contactIndex);
  }
}
