
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { IPaymentInfo, IPaymentInfoes, IWorkOrder, IRoot, IPaymentInvoice } from '../../models/workorder.interface';
import { FormGroup, FormArray, AbstractControl } from '../../../common/ngx-strongly-typed-forms/model';
import { PhxConstants } from '../../../common/model';
import { IReadOnlyStorage } from '../../../organization/models';
import { WorkOrderFormService } from '../../services';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';

@Component({
  selector: 'app-workorder-payment-invoices',
  templateUrl: './workorder-payment-invoices.component.html',
  styleUrls: ['./workorder-payment-invoices.component.less']
})

export class WorkorderPaymentInvoicesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() invoiceType: number;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() inputFormGroup: FormGroup<IPaymentInfoes>;

  workOrder: IWorkOrder;
  phxConstants = PhxConstants;

  get paymentInfoFormArray(): FormArray<IPaymentInfo> {
    return this.inputFormGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
  }

  constructor(
    private workOrderFormService: WorkOrderFormService) {
    super();
  }

  ngOnInit() {
    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        this.workOrder = workorder;
      });
  }

  trackByFn(index: number, item: AbstractControl<IPaymentInvoice>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

}
