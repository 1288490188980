import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ClickAnalyticsData } from '../../../common/services/google-analytics/models/click-analytics-data.model';
import { ICommonListsItem } from '../../../common/lists';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { CodeValueGroups, CodeValueService, PhxConstants } from '../../../common';

@Component({
  selector: 'app-workorder-worker-link',
  templateUrl: './workorder-worker-link.component.html',
  styleUrls: ['./workorder-worker-link.component.less']
})
export class WorkorderWorkerLinkComponent extends BaseComponentOnDestroy {
  @Input() set workerProfileId(id: number) {
    this.userProfileIdWorker = id;
    this.listUserProfileWorker$ = this.commonListsObservableService.getUserProfileWorkers$<ICommonListsItem & { ProfileTypeId: number }>(id).pipe(
      this.takeUntilDestroyed,
      filter(list => Boolean(list))
    );
  }

  @Input() clickAnalyticsData?: ClickAnalyticsData;

  public listUserProfileWorker$: Observable<Array<ICommonListsItem & { ProfileTypeId: number }>>;
  public userProfileIdWorker: number;
  public readonly phxConstants = PhxConstants;
  public readonly profileTypeList = this.codeValueService.getCodeValues(CodeValueGroups.ProfileType, true);

  constructor(private commonListsObservableService: CommonListsObservableService, private codeValueService: CodeValueService) {
    super();
  }
}
