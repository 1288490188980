import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IBusinessTaxEINNumberInUse, IIsInUse, IOrganization, IOrganizationCimsBankAccountInfo, IOrganizationLegalName, IReadOnlyStorage } from '../models';
import { CodeValueGroups, EntityVersion, IAvailableStateAction, IDuplicateCheckNumberInUseResponse, StateAction } from '../../common/model';
import { environment } from '../../../environments/environment';
import CodeOrganizationStatus = PhxConstants.CodeOrganizationStatus;
import { ApiService, CodeValueService, CommonService, PhxConstants } from '../../common';
import { NationAccountManager } from '../models/national-account-manager';
import { JsonPatchDocument } from '../../common/model/json-patch-document';

@Injectable()
export class OrganizationDataService {
  private readonly apiEndPoint: string;
  private readonly generalServiceApiEndpoint: string;

  private loadedOrganizationSubject = new BehaviorSubject<IOrganization>(null);
  loadedOrganization$ = this.loadedOrganizationSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private codeValueService: CodeValueService,
    public commonService: CommonService,
    private router: Router
  ) {
    this.apiEndPoint = environment.organizationServiceApiEndpoint;
    this.generalServiceApiEndpoint = environment.generalServiceApiEndpoint;
  }

  isExistsOrganizationLegalName(name: string, organizationId: number) {
    return this.apiService.httpPostRequest<{ IsValid: boolean; }>(`Organization/rule-check/LegalNameUnique`,
      {
        Entity: {
          OrganizationId: organizationId,
          LegalName: name
        }
      }, environment.organizationServiceApiEndpoint, false)
      .pipe(map(res => !res.IsValid));
  }

  isExistsOrganizationCode(code: string, organizationId: number) {
    return this.apiService.httpPostRequest<{ IsValid: boolean; }>(`Organization/rule-check/CodeUnique`,
      {
        Entity: {
          OrganizationId: organizationId,
          Code: code.trim()
        }
      }, environment.organizationServiceApiEndpoint, false)
      .pipe(map(res => !res.IsValid));
  }

  getOrganization(id: number, showSpinner = true) {
    return this.apiService.httpGetRequest<IOrganization>(`organization/${id}`, this.apiEndPoint, showSpinner);
  }

  patchAndExecuteAction(organizationId: number, action: string, patchDocument: JsonPatchDocument, comment?: string, declineReasons?: string): Observable<IOrganization> {
    return this.apiService.httpPatchRequest<IOrganization>(
      `organization/${organizationId}/actions/${action}`,
      {
        patchDocument: patchDocument.patchOperations,
        comment,
        declineReasons
      },
      this.apiEndPoint,
      true
    );
  }

  getOrganizationVersion(id: number, versionId: number) {
    return this.apiService.httpGetRequest<IOrganization>(`organization/${id}/${versionId}`, this.apiEndPoint);
  }

  getOrganizationVersions(id: number) {
    return this.apiService.httpGetRequest<EntityVersion[]>(`organization/${id}/versions`, this.apiEndPoint);
  }

  getActiveOrganizationVersion(id: number): Observable<IOrganization> {
    return this.getOrganizationVersions(id).pipe(
      map(versions => {
        return versions.find(version => version.StatusCode === PhxConstants.CodeOrganizationStatus.Active);
      }),
      switchMap(activeOrgVersion => {
        return activeOrgVersion ? this.getOrganization(activeOrgVersion.Id) : of(undefined);
      })
    );
  }

  getAvailableStateActions(organizationId: number) {
    return this.apiService.httpGetRequest<IAvailableStateAction[]>(`organization/${organizationId}/available-actions`,
      environment.organizationServiceApiEndpoint);
  }

  getEntityWorkflowProgress(workflowInstanceId: string, entityTypeId: PhxConstants.EntityType) {
    return this.apiService.httpGetRequest<boolean>(`WorkflowProgress/${entityTypeId}/${workflowInstanceId}/IsInProgress`, this.generalServiceApiEndpoint);
  }

  getOrganizationCimsBankAccountInfo(id: number, version: number) {
    return this.apiService.httpGetRequest<IOrganizationCimsBankAccountInfo[]>(`organization/${id}/${version}/cims-bank-info`, this.apiEndPoint);
  }

  callMultipleWorkFlowActions(actions: StateAction[], organization: IOrganization, comment: string, declineReasons: string) {
    if (!actions.length) {
      return of(organization);
    }

    const [currentAction, ...remainingActions] = actions;

    return this.callWorkflowAction(currentAction, organization, comment, declineReasons).pipe(
      switchMap(({ Entity }) => this.getOrganization(Entity.OrganizationId).pipe(
        switchMap(updatedOrganization => this.callMultipleWorkFlowActions(remainingActions, updatedOrganization, comment, declineReasons))
      ))
    );
  }

  callWorkflowAction(action: StateAction, organization: IOrganization, comment: string, declineReasons: string) {
    return this.apiService.httpPostRequest<{ Entity: IOrganization; }>(`Organization/${organization.OrganizationId}/actions/${action.actionName}`,
      {
        Organization: organization,
        Comment: comment,
        DeclineReasons: declineReasons
      },
      environment.organizationServiceApiEndpoint,
      true
    );
  }

  rolloverInternalRoleApplicationDate(organizationId: number) {
    return this.apiService.httpPostRequest<IOrganization>(`Organization/${organizationId}/internal-role-date-rollover`,
      null, environment.organizationServiceApiEndpoint);
  }

  /** NOTE: load org version : we pass in the actions because the version may be the most recent - need to know if save is an action in that scenario */
  loadOrganizationVersion(organizationId: number, versionId: number, availableActions: IAvailableStateAction[]):
    Observable<{ organization: IOrganization, availableActions: IAvailableStateAction[], versions: EntityVersion[]; }> {
    return this.getOrganizationVersion(organizationId, versionId).pipe(
      switchMap(organization => forkJoin([
        of(organization),
        organization.IsDraft === false && organization.OrganizationInternalRoles?.length > 0
          ?
          this.getOrganizationCimsBankAccountInfo(organization.OrganizationId, organization.Version)
          : of([] as IOrganizationCimsBankAccountInfo[])
      ])),
      switchMap(([organization, cimsBankAccountInfoList]) => {
        const updatedOrg = this.getUpdatedOrganization(organization, availableActions, cimsBankAccountInfoList);
        this.loadedOrganizationSubject.next(updatedOrg);
        return of({
          organization: updatedOrg, availableActions, versions: null
        });
      })
    );
  }

  /** NOTE: load organization by organization Id - along with available actions and organization versions */
  loadOrganizationAndSupportData(organizationId: number): Observable<{ organization: IOrganization, availableActions: IAvailableStateAction[], versions: EntityVersion[]; }> {
    return this.getOrganization(organizationId).pipe(
      switchMap(organization => forkJoin([
        of(organization),
        this.getAvailableStateActions(organizationId),
        this.getOrganizationVersions(organizationId)
      ])),
      switchMap(([organization, availableActions, versions]) => {
        return forkJoin([
          of(organization),
          of(availableActions),
          of(versions),
          organization.IsDraft === false && organization.OrganizationInternalRoles?.length > 0
            ?
            this.getOrganizationCimsBankAccountInfo(organization.OrganizationId, organization.Version)
            : of([] as IOrganizationCimsBankAccountInfo[])
        ]);
      }),
      switchMap(([organization, availableActions, versions, cimsBankAccountInfoList]) => {
        const updatedOrg = this.getUpdatedOrganization(organization, availableActions, cimsBankAccountInfoList);
        this.loadedOrganizationSubject.next(updatedOrg);
        return of({
          organization: updatedOrg, availableActions, versions
        });
      })
    );
  }

  /** NOTE: update api response organization with UI data requirements - readOnlyStorage, status, isEditable, etc */
  private getUpdatedOrganization(organization: IOrganization, availableActions: IAvailableStateAction[], cimsBankAccountInfoList: IOrganizationCimsBankAccountInfo[]): IOrganization {
    let isEditable = false;
    if (organization.MostRecent) {
      isEditable = availableActions.some(element => element.ActionName.toLowerCase() === 'save');
    }

    if (cimsBankAccountInfoList.length) {
      organization.OrganizationInternalRoles[0].BankAccounts.forEach(bankAccount => {
        const cimsBankAccountInfo = cimsBankAccountInfoList.find(infoItem => infoItem.Id === bankAccount.Id);
        if (cimsBankAccountInfo) {
          bankAccount.NextChequeNumber = cimsBankAccountInfo.NextChequeNumber;
          bankAccount.NextDirectDepositBatchNumber = cimsBankAccountInfo.NextDirectDepositBatchNumber;
          bankAccount.NextWireTransferBatchNumber = cimsBankAccountInfo.NextWireTransferBatchNumber;
        }
      });
    }

    const isDraftStatus = [CodeOrganizationStatus.Draft, CodeOrganizationStatus.Recalled, CodeOrganizationStatus.Declined].includes(organization.WorkflowStatus);
    const isComplianceDraftStatus = [CodeOrganizationStatus.ComplianceDraft, CodeOrganizationStatus.RecalledCompliance].includes(organization.WorkflowStatus);

    const readOnlyStorage: Readonly<IReadOnlyStorage> = {
      IsEditable: isEditable,
      IsComplianceFieldEditable: isEditable,
      ShouldValidateComplianceField: !isDraftStatus,
      IsComplianceDraftStatus: isComplianceDraftStatus,
      LastModifiedDateTime: organization.LastModifiedDateTime,
      Version: organization.Version
    };

    return {
      ...organization,
      IsOriginal: organization.SourceId == null,
      IsOriginalAndStatusIsActiveOrPendingChange: organization.SourceId == null &&
        [CodeOrganizationStatus.Active, CodeOrganizationStatus.PendingChange].includes(organization.WorkflowStatus),
      OrganizationClientRoles: organization.OrganizationClientRoles || [],
      OrganizationInternalRoles: organization.OrganizationInternalRoles || [],
      OrganizationSubVendorRoles: organization.OrganizationSubVendorRoles || [],
      OrganizationIndependentContractorRoles: organization.OrganizationIndependentContractorRoles || [],
      OrganizationLimitedLiabilityCompanyRoles: organization.OrganizationLimitedLiabilityCompanyRoles || [],
      ReadOnlyStorage: readOnlyStorage,
      IsOrganizationInternalRole: organization.OrganizationInternalRoles?.length > 0,
      IsOrganizationSubVendorRole: organization.OrganizationSubVendorRoles?.length > 0,
      IsOrganizationClientRole: organization.OrganizationClientRoles?.length > 0,
      IsOrganizationIndependentContractorRole: organization.OrganizationIndependentContractorRoles?.length > 0,
      IsOrganizationLimitedLiabilityCompanyRole: organization.OrganizationLimitedLiabilityCompanyRoles?.length > 0
    };
  }

  getListTaxSubdivision(countryCode: string) {
    if (countryCode) {
      const countryId = this.codeValueService.getCodeValueIdByCode(countryCode, CodeValueGroups.Country);
      return this.codeValueService.getRelatedCodeValues(CodeValueGroups.Subdivision, countryId, CodeValueGroups.Country).sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }


  organizationNewOnQuickAdd(organization: IOrganization) {
    return this.apiService.httpPostRequest<IOrganization>(`Organization/canadian-inc`,
      {
        Organization: organization,
        PrimaryContact: organization.Contact
      },
      environment.organizationServiceApiEndpoint,
      true);
  }

  isTaxNumberInUse(taxNumber: string, taxCode: string, organizationId: number) {
    return this.apiService.httpPostRequest<IDuplicateCheckNumberInUseResponse>(
      `Organization/${organizationId}/duplicate-check/org-tax-numbers`,
      [{
        SalesTaxCode: taxCode,
        SalesTaxNumber: taxNumber
      }], environment.organizationServiceApiEndpoint, false)
      .pipe(this.mapIIsInUse());
  }

  isBusinessNumberInUse(businessNumber: string, organizationId: number, countryCode: string) {
    return this.apiService.httpPostRequest<IDuplicateCheckNumberInUseResponse>(
      `Organization/${organizationId}/duplicate-check/org-business-numbers`,
      {
        CountryCode: countryCode,
        BusinessNumbers: [
          businessNumber
        ]
      }, environment.organizationServiceApiEndpoint, false)
      .pipe(this.mapIIsInUse());
  }

  isEmployerIdentificationNumberInUse(employerIdentificationNumber: string, organizationId: number) {
    return this.apiService.httpPostRequest<IDuplicateCheckNumberInUseResponse>(
      `Organization/${organizationId}/duplicate-check/employer-ids`,
      [
        employerIdentificationNumber
      ], environment.organizationServiceApiEndpoint, false)
      .pipe(this.mapIIsInUse());
  }

  getNationalAccountManagersByOrganizationId(organizationId: number): Observable<NationAccountManager[]> {
    return this.apiService.httpGetRequest<NationAccountManager[]>(`organization/${organizationId}/latest-relevant-version/client-role/national-account-managers`, this.apiEndPoint);
  }

  getSubVendorOrganizations(): Observable<IOrganizationLegalName[]> {
    return this.apiService.httpGetRequest<IOrganizationLegalName[]>(`Organization/subvendor-role-organizations-active-version`, this.apiEndPoint);
  }

  private mapIIsInUse() {
    return map((response: any) => {
      const result = Array.isArray(response) && response.length ? response[0] : response;

      const rep: IIsInUse = {
        IsInUse: false,
        IsInUseName: '',
        IsInUseLink: ''
      };
      if (result?.IsInUse) {
        rep.IsInUse = true;
        rep.IsInUseName = result.InUseName;
        rep.IsInUseLink = this.createBusinessTaxEINNumberInUseLink(result);
      }
      return rep;
    });
  }

  private createBusinessTaxEINNumberInUseLink(response: IBusinessTaxEINNumberInUse): string {
    if (response.InUseOrgId) {
      return this.router.createUrlTree([`/next/organization/${response.InUseOrgId}`]).toString();
    } else if (response.InUseUserProfileId) {
      return this.router.createUrlTree([`/next/contact/userprofile/${response.InUseUserProfileId}`]).toString();
    }
  }
}
