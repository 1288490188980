import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingInfo, IBillingInfoes, IBillingInvoice, IPaymentInfo, IPaymentInfoes, ITabExpenseInvoice, IWorkOrder } from '../../models';
import { BillingInfoFormService } from './billing-info-form.service';
import { ExpenseDetailFormService } from './expense-detail-form.service';
import { PaymentInfoFormService } from './payment-info-form.service';

@Injectable()
export class ExpenseInvoiceTabFormService implements IFormService {

  formGroup: FormGroup<ITabExpenseInvoice>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService,
    private billingInfoFormService: BillingInfoFormService,
    private expenseDetailFormService: ExpenseDetailFormService,
  ) { }

  get billingInfoesFormGroup(): FormGroup<IBillingInfoes> {
    return this.formGroup.get('TabExpenseInvoiceBillingInfoes') as FormGroup<IBillingInfoes>;
  }

  get billingInfoFormArray(): FormArray<IBillingInfo> {
    return this.billingInfoesFormGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
  }

  get paymentInfoesFormGroup(): FormGroup<IPaymentInfoes> {
    return this.formGroup.get('TabExpenseInvoicePaymentInfoes') as FormGroup<IPaymentInfoes>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabExpenseInvoice> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<ITabExpenseInvoice>({
      TabExpenseInvoiceDetail: this.expenseDetailFormService.createForm(workorder, isDestroyed$),
      TabExpenseInvoiceBillingInfoes: this.billingInfoFormService.createBillingInfoesFormGroup(
        workorder.WorkOrderVersion.BillingInfoes,
        this.setBillingInfoesValidations(workorder),
        PhxConstants.InvoiceType.Expense
      ),
      TabExpenseInvoicePaymentInfoes: this.paymentInfoFormService.createPaymentInfoesFormGroup(
        workorder.WorkOrderVersion.PaymentInfoes,
        this.setPaymentInfoesValidations(workorder),
        PhxConstants.InvoiceType.Expense
      )
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.expenseDetailFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.expenseDetailFormService.formGroupToPartial(workOrder);
    workOrder = this.billingInfoFormService.formGroupToPartial(
      workOrder,
      this.billingInfoesFormGroup,
      PhxConstants.InvoiceType.Expense
    );
    workOrder = this.paymentInfoFormService.formGroupToPartial(
      workOrder,
      this.paymentInfoesFormGroup,
      PhxConstants.InvoiceType.Expense
    );
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    this.expenseDetailFormService.updateForm(workorder);

    this.updateBillingInfoes(workorder);

    const tabExpenseInvoicePaymentInfoesFormGroup = this.formGroup.get('TabExpenseInvoicePaymentInfoes') as FormGroup<IPaymentInfoes>;
    const paymentInfoFormArray = tabExpenseInvoicePaymentInfoesFormGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
    this.paymentInfoFormService.updatePaymentInfoFormArray(
      paymentInfoFormArray,
      workorder.WorkOrderVersion.PaymentInfoes,
      this.setPaymentInfoesValidations(workorder),
      PhxConstants.InvoiceType.Expense
    );
  }

  updateBillingInfoes(workorder: IWorkOrder) {
    const tabExpenseInvoiceBillingInfoesFormGroup = this.formGroup.get('TabExpenseInvoiceBillingInfoes') as FormGroup<IBillingInfoes>;
    const billingInfoFormArray = tabExpenseInvoiceBillingInfoesFormGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
    this.billingInfoFormService.updateBillingInfoFormArray(
      billingInfoFormArray,
      workorder.WorkOrderVersion.BillingInfoes,
      this.setBillingInfoesValidations(workorder),
      PhxConstants.InvoiceType.Expense
    );
  }

  getInvoiceFormGroup(infoIndex: number, invoiceIndex: number): FormGroup<IBillingInvoice> {
    const infoFormGroup = this.billingInfoFormArray.at(infoIndex);
    const invoiceFormArray = infoFormGroup.get('BillingInvoices') as FormArray<IBillingInvoice>;
    return invoiceFormArray.at(invoiceIndex) as FormGroup<IBillingInvoice>;
  }

  addTimesheetBillingInvoiceNote(infoIndex: number, invoiceIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.addTimesheetBillingInvoiceNote(invoiceFormGroup);
  }

  removeTimesheetBillingInvoiceNote(infoIndex: number, invoiceIndex: number, timeSheetIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.removeTimeSheetBillingInvoiceNote(
      timeSheetIndex,
      invoiceFormGroup
    );
  }

  addBillingRecipient(infoIndex: number, invoiceIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.addBillingRecipientFormGroup(invoiceFormGroup);
  }

  removeBillingRecipient(infoIndex: number, invoiceIndex: number, recipientIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.removeBillingRecipientFormGroup(
      recipientIndex,
      invoiceFormGroup
    );
  }


  private setBillingInfoesValidations(workorder: IWorkOrder) {
    const methodologyId = workorder.WorkOrderVersion.ExpenseMethodologyId;
    const valid = {
      isBillingValid:
        methodologyId &&
        (methodologyId === PhxConstants.ExpenseMethodology.OnlineApproval ||
          methodologyId === PhxConstants.ExpenseMethodology.OfflineApproval) &&
        workorder.WorkOrderVersion.IsExpenseUsesProjects,
      isNoExpenseValid: methodologyId && methodologyId === PhxConstants.ExpenseMethodology.NoExpense,
      isSalesTaxAppliedOnVmsImport: methodologyId && methodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport
    };
    return valid;
  }

  private setPaymentInfoesValidations(workorder: IWorkOrder) {
    const valid = {
      isNoExpenseValid: workorder.WorkOrderVersion.ExpenseMethodologyId && workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.NoExpense,
      isSalesTaxAppliedOnVmsImport: workorder.WorkOrderVersion.ExpenseMethodologyId && workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport
    };
    return valid;
  }
}
