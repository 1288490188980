import {Component, Input, OnInit} from '@angular/core';
import {IExpenseApprover, IReadOnlyStorage, ITabExpenseInvoiceDetail} from '../../models';
import {FormArray, FormGroup} from '../../../common/ngx-strongly-typed-forms';
import {CodeValueService, PhxConstants} from '../../../common';
import {CodeValue, CodeValueGroups} from '../../../common/model';
import {ExpenseDetailFormService, WorkorderService} from '../../services';
import {distinctUntilChanged, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {combineLatest, of, Subscription} from 'rxjs';
import {ICommonListsItem} from 'src/app/common/lists';
import {CommonListsObservableService} from 'src/app/common/lists/lists.observable.service';
import {AuthService} from 'src/app/common/services/auth.service';
import {BaseComponentOnDestroy} from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

interface IHtml {
  codeValueLists: {
    listExpenseMethodologies: Array<CodeValue>;
    listExpenseApprovalFlows: Array<CodeValue>;
  };
  expenseCard: {
    expenseApproval: boolean;
    projectsAndCoding: boolean;
    configurationAndDescriptors: boolean;
    expenseDescription: boolean;
  };
  commonLists: {
    listProfilesForClientApproval: Array<any>;
    listProfilesForInternalApproval: Array<any>;
    listProfilesForSupplierApproval: Array<any>;
  };
  phxConstants: typeof PhxConstants;
}

@Component({
  selector: 'app-workorder-expense-detail',
  templateUrl: './workorder-expense-detail.component.html',
  styleUrls: ['./workorder-expense-detail.component.less']
})
export class WorkorderExpenseDetailComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  codeValueGroups = CodeValueGroups;
  formGroup: FormGroup<ITabExpenseInvoiceDetail>;

  html: IHtml = {
    codeValueLists: {
      listExpenseMethodologies: [],
      listExpenseApprovalFlows: []
    },
    expenseCard: {
      expenseApproval: false,
      projectsAndCoding: false,
      configurationAndDescriptors: false,
      expenseDescription: false
    },
    phxConstants: PhxConstants,
    commonLists: {
      listProfilesForClientApproval: [],
      listProfilesForInternalApproval: [],
      listProfilesForSupplierApproval: []
    }
  };

  private subscription$: Subscription;

  constructor(
    private codeValueService: CodeValueService,
    private workorderService: WorkorderService,
    private expenseDetailFormService: ExpenseDetailFormService,
    private commonListsObservableService: CommonListsObservableService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  get supplierApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('SupplierApprover') as FormArray<IExpenseApprover>;
  }

  get internalApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('InternalApprover') as FormArray<IExpenseApprover>;
  }

  get clientApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('ClientApprover') as FormArray<IExpenseApprover>;
  }

  ngOnInit() {
    this.formGroup = this.expenseDetailFormService.formGroup;
    this.setupFormGroupListeners();
    // INVESTIGATE HOW THIS COMPONENT IS BEING USED
    // IF TOGGLE BY URL, THIS ASYNC CALL MIGHT NOT GET TRIGGER AGAIN WITH UPDATED VALUES
    this.getApprovalLists$();
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.expenseDetailFormService.organizationIdClientFormArray.valueChanges.pipe(
        startWith(this.expenseDetailFormService.organizationIdClientFormArray.value),
        filter(values => values?.length > 0),
        map(values => values[0].OrganizationIdClient),
        distinctUntilChanged(),
        switchMap(orgId => {
          if (orgId) {
            return this.workorderService.getProfilesListOrganizational(orgId);
          } else {
            return of([] as ICommonListsItem[]);
          }
        }),
        takeUntil(this.isDestroyed$)
      ).subscribe(list => {
        this.html.commonLists.listProfilesForClientApproval = this.formatApproverList(list);
      })
    );

    this.subscription$.add(
      this.expenseDetailFormService.organizationIdSupplierFormArray.valueChanges
        .pipe(
          startWith(this.expenseDetailFormService.organizationIdSupplierFormArray.value),
          filter(suppliers => suppliers?.length > 0),
          map(suppliers => suppliers.map(x => x.OrganizationIdSupplier).filter(x => x != null)),
          distinctUntilChanged((prev, curr) => prev.sort().join(',') === curr.sort().join(',')),
          switchMap(orgIds =>
            combineLatest(orgIds.map(orgId =>
              this.workorderService.getProfilesListOrganizational(orgId)
            ))
          ),
          map(lists => ([] as ICommonListsItem[]).concat(...lists.filter(x => x != null))),
          takeUntil(this.isDestroyed$)
        ).subscribe(list => {
          this.html.commonLists.listProfilesForSupplierApproval = this.formatApproverList(list);
        })
    );

    this.subscription$.add(
      this.expenseDetailFormService.expenseIdChange$.pipe(
        startWith(this.expenseDetailFormService.expenseIdFormControl.value),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        this.html.expenseCard.expenseApproval = value && (
          value === PhxConstants.ExpenseMethodology.OnlineApproval ||
          value === PhxConstants.ExpenseMethodology.OfflineApproval);
        this.html.expenseCard.projectsAndCoding = value && (
          value === PhxConstants.ExpenseMethodology.OnlineApproval ||
          value === PhxConstants.ExpenseMethodology.OfflineApproval);
        this.html.expenseCard.configurationAndDescriptors = value &&
          value !== PhxConstants.ExpenseMethodology.NoExpense;
        this.html.expenseCard.expenseDescription = value && (
          value === PhxConstants.ExpenseMethodology.OnlineApproval ||
          value === PhxConstants.ExpenseMethodology.OfflineApproval);
      })
    );
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listExpenseMethodologies = this.codeValueService.getCodeValues(this.codeValueGroups.ExpenseMethodology, true);
    this.html.codeValueLists.listExpenseApprovalFlows = this.codeValueService.getCodeValues(this.codeValueGroups.TimeSheetApprovalFlow, true);
  }

  getApprovalLists$() {
    this.commonListsObservableService
      .listUserProfileInternal$().pipe(
        takeUntil(this.isDestroyed$))
      .subscribe(list => {
        this.html.commonLists.listProfilesForInternalApproval = this.formatApproverList(list);
      });
  }

  formatApproverList(list: ICommonListsItem[]) {
    return list ? list.map(item => ({
      ...item.Data,
      DisplayValue: item.Data.Contact.FullName + ' - ' + item.Data.Contact.Id
    })) : [];
  }

  onClickAddExpenseApproverDefinition() {
    this.expenseDetailFormService.addTimeSheetApproverDefinitionFormGroup();
  }

  onClickRemoveExpenseApproverDefinition(index: number) {
    this.expenseDetailFormService.removeTimeSheetApproverDefinitionFormGroup(index);
  }

}
