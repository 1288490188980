import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingInfo, IBillingInfoes, IBillingInvoice, IOverrideTimesheetExceptions, IPaymentInfo, IPaymentInfoes, ITabTimeMaterialInvoice, IWorkOrder } from '../../models';
import { BillingInfoFormService } from './billing-info-form.service';
import { PaymentInfoFormService } from './payment-info-form.service';
import { TimeMaterialInvoiceDetailFormService } from './time-material-invoice-detail-form.service';
import { TimesheetExceptionsFormService } from './timesheet-exceptions-form.service';
import { IOverrideTimesheetException } from 'src/app/common/model/override-timesheet-exception';
@Injectable()
export class TimeMaterialInvoiceTabFormService implements IFormService {

  formGroup: FormGroup<ITabTimeMaterialInvoice>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService,
    private billingInfoFormService: BillingInfoFormService,
    private timeMaterialInvoiceDetailFormService: TimeMaterialInvoiceDetailFormService,
    private timesheetExceptionFormService: TimesheetExceptionsFormService
  ) { }

  get billingInfoesFormGroup(): FormGroup<IBillingInfoes> {
    return this.formGroup.get('TabTimeMaterialInvoiceBillingInfoes') as FormGroup<IBillingInfoes>;
  }

  get billingInfoFormArray(): FormArray<IBillingInfo> {
    return this.billingInfoesFormGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
  }

  get paymentInfoesFormGroup(): FormGroup<IPaymentInfoes> {
    return this.formGroup.get('TabTimeMaterialInvoicePaymentInfoes') as FormGroup<IPaymentInfoes>;
  }

  get timesheetExceptionsFormGroup(): FormGroup<IOverrideTimesheetExceptions> {
    return this.formGroup.get('TabMaterialTimesheetExceptions') as FormGroup<IOverrideTimesheetExceptions>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTimeMaterialInvoice> {
    this.isRootComponentDestroyed$ = isDestroyed$;
    this.formGroup = this.fb.group<ITabTimeMaterialInvoice>({
      TabTimeMaterialInvoiceDetail: this.timeMaterialInvoiceDetailFormService.createForm(workorder, isDestroyed$),
      TabTimeMaterialInvoiceBillingInfoes: this.billingInfoFormService.createBillingInfoesFormGroup(
        workorder.WorkOrderVersion.BillingInfoes,
        this.setBillingInfoesValidations(workorder),
        PhxConstants.InvoiceType.TimeSheet
      ),
      TabTimeMaterialInvoicePaymentInfoes: this.paymentInfoFormService.createPaymentInfoesFormGroup(
        workorder.WorkOrderVersion.PaymentInfoes,
        this.setPaymentInfoesValidations(),
        PhxConstants.InvoiceType.TimeSheet
      ),
      TabMaterialTimesheetExceptions: this.timesheetExceptionFormService.createTimesheetExceptionsFormGroup(
        workorder.WorkOrderVersion.OverrideTimesheetExceptions
      )
      
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.timeMaterialInvoiceDetailFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.timeMaterialInvoiceDetailFormService.formGroupToPartial(workOrder);
    workOrder = this.billingInfoFormService.formGroupToPartial(
      workOrder,
      this.billingInfoesFormGroup,
      PhxConstants.InvoiceType.TimeSheet
    );
    workOrder = this.paymentInfoFormService.formGroupToPartial(
      workOrder,
      this.paymentInfoesFormGroup,
      PhxConstants.InvoiceType.TimeSheet
    );
    workOrder = this.timesheetExceptionFormService.formGroupToPartial(
      workOrder,
      this.timesheetExceptionsFormGroup
    );
    
    
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    this.timeMaterialInvoiceDetailFormService.updateForm(workorder);

    this.updateBillingInfoes(workorder);

    this.updatePaymentInfoes(workorder);

    this.updateOverrideTimesheetExceptions(workorder);
    
  }

  private updateOverrideTimesheetExceptions(workorder: IWorkOrder) {
    const tabMaterialTimesheetExceptionsFormGroup = this.formGroup.get('TabMaterialTimesheetExceptions') as FormGroup<IOverrideTimesheetExceptions>;
    const timesheetExceptionsFormArray = tabMaterialTimesheetExceptionsFormGroup.get('OverrideTimesheetExceptions') as FormArray<IOverrideTimesheetException>;
    this.timesheetExceptionFormService.updateOverrideTimesheetExceptionFormArray(
      timesheetExceptionsFormArray,
      workorder.WorkOrderVersion.OverrideTimesheetExceptions
    );
  }

  private updatePaymentInfoes(workorder: IWorkOrder) {
    const tabPartiesAndRatesPaymentInfoesFormGroup = this.formGroup.get('TabTimeMaterialInvoicePaymentInfoes') as FormGroup<IPaymentInfoes>;
    const paymentInfoesFormArray = tabPartiesAndRatesPaymentInfoesFormGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
    this.paymentInfoFormService.updatePaymentInfoFormArray(
      paymentInfoesFormArray,
      workorder.WorkOrderVersion.PaymentInfoes,
      this.setPaymentInfoesValidations(),
      PhxConstants.InvoiceType.TimeSheet
    );
  }

  updateBillingInfoes(workorder: IWorkOrder) {
    const tabTimeMaterialInvoiceBillingInfoesFormGroup = this.formGroup.get('TabTimeMaterialInvoiceBillingInfoes') as FormGroup<IBillingInfoes>;
    const billingInfoesFormArray = tabTimeMaterialInvoiceBillingInfoesFormGroup.get('BillingInfoes') as FormArray<IBillingInfo>;
    this.billingInfoFormService.updateBillingInfoFormArray(
      billingInfoesFormArray,
      workorder.WorkOrderVersion.BillingInfoes,
      this.setBillingInfoesValidations(workorder),
      PhxConstants.InvoiceType.TimeSheet
    );
  }

  getInvoiceFormGroup(infoIndex: number, invoiceIndex: number): FormGroup<IBillingInvoice> {
    const infoFormGroup = this.billingInfoFormArray.at(infoIndex);
    const invoiceFormArray = infoFormGroup.get('BillingInvoices') as FormArray<IBillingInvoice>;
    return invoiceFormArray.at(invoiceIndex) as FormGroup<IBillingInvoice>;
  }

  addTimesheetBillingInvoiceNote(infoIndex: number, invoiceIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.addTimesheetBillingInvoiceNote(invoiceFormGroup);
  }

  removeTimesheetBillingInvoiceNote(infoIndex: number, invoiceIndex: number, timeSheetIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.removeTimeSheetBillingInvoiceNote(
      timeSheetIndex,
      invoiceFormGroup
    );
  }

  addBillingRecipient(infoIndex: number, invoiceIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.addBillingRecipientFormGroup(invoiceFormGroup);
  }

  removeBillingRecipient(infoIndex: number, invoiceIndex: number, recipientIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex, invoiceIndex);
    this.billingInfoFormService.removeBillingRecipientFormGroup(
      recipientIndex,
      invoiceFormGroup
    );
  }

  addNewTimesheetException() {
    const tabTimesheetExceptionsFormGroup = this.formGroup.get('TabMaterialTimesheetExceptions') as FormGroup<IOverrideTimesheetExceptions>;
    this.timesheetExceptionFormService.addTimesheetExceptionFormGroup(tabTimesheetExceptionsFormGroup);
  }

  removeTimesheetException(timesheetExceptionIndex:number){
    const tabTimesheetExceptionsFormGroup = this.formGroup.get('TabMaterialTimesheetExceptions') as FormGroup<IOverrideTimesheetExceptions>;
    this.timesheetExceptionFormService.removeTimesheetExceptionFormGroup(
      timesheetExceptionIndex,
      tabTimesheetExceptionsFormGroup
    )
  }

  private setBillingInfoesValidations(workOrder: IWorkOrder) {
    const methodologyId = workOrder.WorkOrderVersion.TimeSheetMethodologyId;

    const valid = {
      isBillingValid: (methodologyId
        && (methodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
          || methodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval)
        && workOrder.WorkOrderVersion.IsTimeSheetUsesProjects),
      isNoExpenseValid: false
    };
    return valid;
  }

  private setPaymentInfoesValidations() {
    const valid = {
      isNoExpenseValid: false
    };
    return valid;
  }
}
