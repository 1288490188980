import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { GlobalConfiguration } from '@common/model/gobal-configuration/global-configuration';
import { environment } from '@environment';

import { ApiService, CommonService } from '..';
import { ICommonListsAction, ICommonListsItem } from './lists.interface';
import { EntityList, PhxConstants } from '../model';
import { UserProfileWorkerListItem } from './models/user-profile-worker-list-item.model';

declare let oreq: any;

const getUserProfileByType = (profileType: PhxConstants.UserProfileType): ICommonListsAction => {
  const commonListsAction: ICommonListsAction = {
    ListName: `getUserProfileTypes_${profileType.toString()}`,
    ApiQueryPath: `UserProfile/${profileType}/getUserProfileTypes`,
    oDataParams: oreq
      .request()
      .withExpand(['Contact'])
      .withSelect(['Id', 'Contact/FullName'])
      .withFilter(
        oreq
          .filter('ProfileStatusId')
          .eq(PhxConstants.ProfileStatus.Active)
          .or()
          .filter('ProfileStatusId')
          .eq(PhxConstants.ProfileStatus.PendingChange)
      )
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      const resultList = responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Contact.FullName
        };
      });
      return resultList;
    }
  };
  return commonListsAction;
};

@Injectable()
export class CommonListsObservableService {

  private isLoading: { [ListName: string]: boolean } = {};

  private commonListsSubjectState: { [listName: string]: BehaviorSubject<ICommonListsItem[]> } = {};

  private UserProfileInternal: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileInternal,
    ApiQueryPath: 'UserProfile/getListUserProfileInternal',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'UserProfileFunctionalRoles'])
      .withSelect(['Id', 'PrimaryEmail', 'UserProfileFunctionalRoles/FunctionalRoleId', 'Contact/FullName', 'Contact/Id'])
      .withFilter(
        oreq
          .filter('ProfileStatusId')
          .eq(PhxConstants.ProfileStatus.Active)
          .or()
          .filter('ProfileStatusId')
          .eq(PhxConstants.ProfileStatus.PendingChange)
      )
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      const resultList = responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Contact.FullName + ' - ' + item.Contact.Id,
          Data: item
        } as ICommonListsItem;
      });
      return resultList;
    }
  };
  private UserProfileInternalAll: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileInternalAll,
    ApiQueryPath: 'UserProfile/getListUserProfileInternal',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'UserProfileFunctionalRoles'])
      .withSelect(['Id', 'PrimaryEmail', 'UserProfileFunctionalRoles/FunctionalRoleId', 'Contact/FullName', 'Contact/Id'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      const resultList = responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Contact.FullName + ' - ' + item.Contact.Id,
          Data: item
        } as ICommonListsItem;
      });
      return resultList;
    }
  };
  private UserProfileInternalCommission: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileInternalCommission,
    ApiQueryPath: 'Commission/getCommonListCommissionUserProfilesWithRates',
    oDataParams: oreq
      .request()
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Contact.FullName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };
  private OrganizationInternals: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.OrganizationInternals,
    ApiQueryPath: 'org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole',
    oDataParams: oreq
      .request()
      .withExpand('OrganizationAddresses')
      .withSelect(['Id', 'DisplayName', 'Code', 'IsTest', 'OrganizationAddresses/IsPrimary', 'OrganizationAddresses/CountryId', 'OrganizationAddresses/SubdivisionId'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };
  private OrganizationClients: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.OrganizationClients,
    ApiQueryPath: 'org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole',
    oDataParams: oreq
      .request()
      .withSelect(['Id', 'DisplayName', 'Code', 'IsPurchaseOrderAddressRequired'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };
  private DetailedOrganizationClients: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.DetailedOrganizationClients,
    ApiQueryPath: 'org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole',
    oDataParams: oreq
      .request()
      .withExpand([
        'OrganizationClientRoles',
        'OrganizationClientRoles/OrganizationClientRoleLOBs',
        'OrganizationClientRoles/OrganizationClientRoleAlternateBills',
        'OrganizationClientRoles/OrganizationClientRoleNationalAccountManagers'
      ])
      .withSelect(['Id', 'DisplayName', 'Code', 'OrganizationAddresses', 'OrganizationClientRoles'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };

  private DetailedOrganizationClientsSingle: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.DetailedOrganizationClients,
    ApiQueryPath: 'org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole',
    oDataParams: oreq
      .request()
      .withExpand([
        'OrganizationClientRoles',
        'OrganizationClientRoles/OrganizationClientRoleLOBs',
        'OrganizationClientRoles/OrganizationClientRoleAlternateBills',
        'OrganizationClientRoles/OrganizationClientRoleNationalAccountManagers'
      ])
      .withSelect(['Id', 'DisplayName', 'Code', 'OrganizationAddresses', 'OrganizationClientRoles'])
    ,
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };
  private OrganizationSuppliers: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.OrganizationSuppliers,
    ApiQueryPath: 'org/getListSupplierOrganizationsOriginal',
    oDataParams: oreq
      .request()
      .withSelect(['Id', 'DisplayName', 'IsOrganizationSubVendorRole', 'IsOrganizationIndependentContractorRole', 'IsOrganizationLimitedLiabilityCompanyRole'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => item);
    }
  };
  private UserProfileWorker: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileWorker,
    ApiQueryPath: 'UserProfile/GetListUserProfileWorker',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'Organization'])
      .withSelect([
        'Id',
        'ProfileTypeId',
        'PrimaryEmail',
        'ContactId',
        'OrganizationId',
        'UserProfileIdOrgRep',
        'TaxSubdivisionId',
        'Contact/Id',
        'Contact/FullName',
        'Contact/FirstName',
        'Contact/LastName',
        'Organization/DisplayName'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => item);
    }
  };
  private UserProfileWorkerSingle: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileWorkerSingle,
    ApiQueryPath: 'UserProfile/GetListUserProfileWorker',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'Organization'])
      .withSelect([
        'Id',
        'ProfileTypeId',
        'PrimaryEmail',
        'ContactId',
        'OrganizationId',
        'UserProfileIdOrgRep',
        'TaxSubdivisionId',
        'Contact/Id',
        'Contact/FullName',
        'Contact/FirstName',
        'Contact/LastName',
        'Organization/DisplayName'])
    ,
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => item);
    }
  };
  private DetailedUserProfileWorker: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.DetailedUserProfileWorker,
    ApiQueryPath: 'UserProfile/GetListUserProfileWorker',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'Organization', 'UserProfileWorkerOtherEarnings', 'UserProfileWorkerSourceDeductions'])
      .withSelect([
        'Id',
        'ProfileTypeId',
        'OrganizationId',
        'PrimaryEmail',
        'LastModifiedDatetime',
        'ContactId',
        'Contact/Id',
        'Contact/FullName',
        'Organization/DisplayName',
        'UserProfileWorkerSourceDeductions/IsApplied',
        'UserProfileWorkerSourceDeductions/SourceDeductionTypeId',
        'UserProfileWorkerSourceDeductions/RatePercentage',
        'UserProfileWorkerSourceDeductions/RateAmount',
        'UserProfileWorkerOtherEarnings/IsApplied',
        'UserProfileWorkerOtherEarnings/IsAccrued',
        'UserProfileWorkerOtherEarnings/PaymentOtherEarningTypeId',
        'UserProfileWorkerOtherEarnings/RatePercentage'
      ])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };

  private DetailedUserProfileWorkerSingle: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.DetailedUserProfileWorkerSingle,
    ApiQueryPath: 'UserProfile/GetListUserProfileWorker',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'Organization', 'UserProfileWorkerOtherEarnings', 'UserProfileWorkerSourceDeductions'])
      .withSelect([
        'Id',
        'ProfileTypeId',
        'OrganizationId',
        'PrimaryEmail',
        'LastModifiedDatetime',
        'ContactId',
        'Contact/Id',
        'Contact/FullName',
        'Organization/DisplayName',
        'UserProfileWorkerSourceDeductions/IsApplied',
        'UserProfileWorkerSourceDeductions/SourceDeductionTypeId',
        'UserProfileWorkerSourceDeductions/RatePercentage',
        'UserProfileWorkerSourceDeductions/RateAmount',
        'UserProfileWorkerOtherEarnings/IsApplied',
        'UserProfileWorkerOtherEarnings/IsAccrued',
        'UserProfileWorkerOtherEarnings/PaymentOtherEarningTypeId',
        'UserProfileWorkerOtherEarnings/RatePercentage'
      ]),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.DisplayName,
          Data: item
        } as ICommonListsItem;
      });
    }
  };
  private CommissionSalesPatterns: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.CommissionSalesPatterns,
    ApiQueryPath: 'commission/getAllSalesPatterns',
    oDataParams: oreq
      .request()
      .withSelect(['Id', 'Description', 'SalesPatternStatusId'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      return responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Description,
          Data: item
        } as ICommonListsItem;
      });
    }
  };

  private HolidayScheduleNames: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.HolidayScheduleNames,
    ApiQueryPath: 'holiday/holidayschedule',
    oDataParams: oreq
      .request()
      .url(),
    MappingFunction: (responseSuccess: any) => {
      if (responseSuccess) {
        return responseSuccess.Items.map(item => {
          return {
            Id: item.HolidayScheduleNameId,
            DisplayText: item.HolidayScheduleName,
            Data: item
          };
        });
      } else {
        return [];
      }
    }
  };
  private UserProfileOrganizational: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.Organizational);
  private UserProfileWorkerTemp: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerTemp);
  private UserProfileWorkerCanadianSP: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerCanadianSp);
  private UserProfileWorkerCanadianInc: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerCanadianInc);
  private UserProfileWorkerSubVendor: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerSubVendor);
  private UserProfileWorkerUnitedStatesW2: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerUnitedStatesW2);
  private UserProfileWorkerUnitedStatesLLC: ICommonListsAction = getUserProfileByType(PhxConstants.UserProfileType.WorkerUnitedStatesLLC);
  private ParentOrganization: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.ParentOrganization,
    ApiQueryPath: 'org/getListParentOrganizations',
    oDataParams: oreq
      .request()
      .withSelect(['Id', 'Name', 'IsDraft'])
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => {
      const resultList = responseSuccess.Items.map(item => {
        return {
          Id: item.Id,
          DisplayText: item.Name,
          Data: { IsDraft: item.IsDraft }
        };
      });
      return resultList;
    }
  };

  private UserProfileInternalActiveAccountManagers: ICommonListsAction = {
    ListName: PhxConstants.CommonListsNames.UserProfileInternalActiveAccountManagers,
    ApiQueryPath: 'UserProfile/getListUserProfileInternal',
    oDataParams: oreq
      .request()
      .withExpand(['Contact', 'UserProfileFunctionalRoles'])
      .withSelect(['Id', 'PrimaryEmail', 'UserProfileFunctionalRoles/FunctionalRoleId', 'Contact/FullName', 'Contact/Id'])
      .withFilter(
        oreq
          .filter('ProfileStatusId')
          .eq(PhxConstants.ProfileStatus.Active)
          .and()
          .filter('UserProfileFunctionalRoles')
          .any(
            oreq.filter('x/FunctionalRoleId')
              .eq(PhxConstants.FunctionalRole.AccountManager)
          )
      )
      .url(),
    MappingFunction: (responseSuccess: EntityList<any>) => responseSuccess.Items.map(item => ({
        Id: item.Id,
        DisplayText: item.Contact.FullName + ' - ' + item.Contact.Id,
        Data: item
      }))
  };

  constructor(private apiService: ApiService, public commonService: CommonService) { }

  listUserProfileInternal$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.UserProfileInternal, showLoader);
  };

  listManagedServiceProvider$ = (showLoader: boolean = false) => {
    return this.apiService.httpGetRequest<GlobalConfiguration<{Value: Array<ICommonListsItem>}>>
    ('globalConfiguration/Configuration/ManagedServiceProviders', environment.adminServiceApiEndpoint, showLoader);
  };

  listVendorManagementSystem$ = (showLoader: boolean = false) => {
    return this.apiService.httpGetRequest<GlobalConfiguration<{Value: Array<ICommonListsItem>}>>
    ('globalConfiguration/Configuration/VendorManagementSystemProviders', environment.adminServiceApiEndpoint, showLoader);
  };

  listUserProfileInternalAll$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.UserProfileInternalAll, showLoader);
  };

  listUserProfileInternalCommission$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.UserProfileInternalCommission, showLoader);
  };

  listParentOrganization$ = (showLoader: boolean = false, forceRefresh = false) => {
    if (forceRefresh) {
      this.clearListByName(this.ParentOrganization.ListName);
    }
    return this.getlistByCommonListsAction$(this.ParentOrganization, showLoader);
  };

  listOrganizationInternals$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.OrganizationInternals, showLoader);
  };

  listOrganizationClients$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.OrganizationClients, showLoader);
  };

  listDetailedOrganizationClients$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.DetailedOrganizationClients, showLoader);
  };

  getDetailedOrganizationClients$ = (clientId, showLoader: boolean = false) => {
    const listAction = { ...this.DetailedOrganizationClientsSingle };
    if (clientId) {
      listAction.ListName += clientId;
      listAction.oDataParams = listAction.oDataParams.withFilter(oreq.filter('Id').eq(clientId)).url();
    } else {
      listAction.oDataParams = listAction.oDataParams.url();
    }
    return this.getlistByCommonListsAction$(listAction, showLoader);
  };

  listOrganizationSuppliers$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.OrganizationSuppliers, showLoader);
  };

  listUserProfileWorkers$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$<UserProfileWorkerListItem>(this.UserProfileWorker, showLoader);
  };

  getUserProfileWorkers$ = <T extends ICommonListsItem = ICommonListsItem>(userProfileId, showLoader: boolean = false) => {
    const listAction = { ...this.UserProfileWorkerSingle };
    if (userProfileId) {
      listAction.ListName += userProfileId;
      listAction.oDataParams = listAction.oDataParams.withFilter(oreq.filter('Id').eq(userProfileId)).url();
    } else {
      listAction.oDataParams = listAction.oDataParams.url();
    }
    return this.getlistByCommonListsAction$<T>(listAction, showLoader);
  };

  listDetailedUserProfileWorkers$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.DetailedUserProfileWorker, showLoader);
  };

  getDetailedUserProfileWorkers$ = (userProfileId, showLoader: boolean = false) => {
    const listAction = { ...this.DetailedUserProfileWorkerSingle };
    if (userProfileId) {
      listAction.ListName += userProfileId;
      listAction.oDataParams = listAction.oDataParams.withFilter(oreq.filter('Id').eq(userProfileId)).url();
    } else {
      listAction.oDataParams = listAction.oDataParams.url();
    }
    return this.getlistByCommonListsAction$(listAction, showLoader);
  };

  listCommissionSalesPatterns$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.CommissionSalesPatterns, showLoader);
  };

  listUserProfileByTypeAndOrg$ = (profileType: PhxConstants.UserProfileType | 0 = 0, orgId: number = 0, showLoader: boolean = false, excludedProfileStatusId: PhxConstants.ProfileStatus | 0 = 0) => {
    return this.getlistByCommonListsAction$(this.getUserProfileByTypeAndOrg(profileType, orgId, excludedProfileStatusId), showLoader);
  };

  clearList(): void {
    this.commonListsSubjectState = {};
  }

  listHolidayScheduleName$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.HolidayScheduleNames, showLoader);
  };

  listUserProfileInternalActiveAccountManagers$ = (showLoader: boolean = false) => {
    return this.getlistByCommonListsAction$(this.UserProfileInternalActiveAccountManagers, showLoader);
  };

  public clearListByName(listName: string): void {
    if (this.commonListsSubjectState[listName]) {
      this.commonListsSubjectState[listName].next(null);
    } else {
      this.commonListsSubjectState[listName] = new BehaviorSubject(null);
    }
  }

  private getUserProfileByTypeAndOrg = (profileType: PhxConstants.UserProfileType = 0, organizationId: number = 0, excludedProfileStatusId: number = 0): ICommonListsAction => {
    let oDataParams = oreq
      .request()
      .withExpand(['Contact'])
      .withSelect(['Id', 'ProfileStatusId', 'ContactId', 'ProfileTypeId', 'OrganizationId', 'Contact/Id', 'Contact/FullName']);

    let filter = null;

    if (organizationId > 0) {
      filter = oreq.filter('OrganizationId').eq(organizationId);
    }
    if (excludedProfileStatusId > 0) {
      if (filter === null) {
        filter = oreq.filter('ProfileStatusId').ne(excludedProfileStatusId);
      } else {
        filter = filter.and().filter('ProfileStatusId').ne(excludedProfileStatusId);
      }
    }

    if (filter !== null) {
      oDataParams = oDataParams.withFilter(filter);
    }

    return {
      ListName: `UserProfiles_${profileType.toString()}_org${organizationId}`,
      ApiQueryPath: `UserProfile/${profileType}/UserProfiles`,
      oDataParams: oDataParams.url(),
      MappingFunction: (responseSuccess: EntityList<any>) => {
        return responseSuccess.Items.map(item => {
          return {
            Id: item.Id,
            DisplayText: item.Contact.FullName,
            Data: item
          } as ICommonListsItem;
        });
      }
    } as ICommonListsAction;
  };

  /** NOTE: the output interface depends on the action, ICommonListsItem is merely a common interface,
   * shared by all responses, produced by this method.
   * */
  private getlistByCommonListsAction$<T extends ICommonListsItem = ICommonListsItem>(commonListsAction: ICommonListsAction, showLoader: boolean = true): Observable<T[]> {
    if (!this.commonListsSubjectState[commonListsAction.ListName]) {
      this.commonListsSubjectState[commonListsAction.ListName] = new BehaviorSubject(null);
    }

    if (!this.commonListsSubjectState[commonListsAction.ListName].value && !this.getIsLoading(commonListsAction.ListName)) {
      const oDataParams = commonListsAction.oDataParams;
      this.apiService.query(commonListsAction.ApiQueryPath + (oDataParams ? '?' + oDataParams : ''), showLoader)
        .subscribe({
          next: (responseSuccess: EntityList<any>) => {
            const resultList: ICommonListsItem[] = commonListsAction.MappingFunction(responseSuccess);
            this.isLoading[commonListsAction.ListName] = false;
            this.commonListsSubjectState[commonListsAction.ListName].next(resultList);
          },
        error: () => {
          this.commonListsSubjectState[commonListsAction.ListName].next(null);
        }
        });
    }

    return this.commonListsSubjectState[commonListsAction.ListName].asObservable() as Observable<T[]>;
  }

  private getIsLoading(listName: string) {
    const isLoading = this.isLoading[listName];
    if (!isLoading) {
      this.isLoading[listName] = true;
    }
    return isLoading;
  }
}
