import { Component, Input } from '@angular/core';

import { DocumentCollectorService } from '../../services/document-collector/document-collector.service';
import { DocumentCollectorButtonParams } from './models/document-collector-button-params.model';

@Component({
  selector: 'app-document-collector-button',
  templateUrl: './document-collector-button.component.html',
  styleUrls: ['./document-collector-button.component.less']
})
export class DocumentCollectorButtonComponent {
  @Input() set collectorParameters(params: DocumentCollectorButtonParams) {
    this.params = params;
    this.isButtonVisible = this.documentCollectorService.checkCollectDocumentsIsAvailable(params);
  }

  public isButtonVisible: boolean;

  private params: DocumentCollectorButtonParams;
  constructor(private documentCollectorService: DocumentCollectorService) {}

  public goToDocumentsCollector(): void {
    this.documentCollectorService.goToDocumentCollector(this.params);
  }
}
