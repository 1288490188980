import { IFormGroupSetup, IWorkOrder, IWorkOrderDto, IWOSaveTemplate } from '../../models';

import { Component, Input, OnInit } from '@angular/core';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { CustomFieldService } from '../../../common';
import { PhxConstants } from '../../../common/model';
import { HashModel } from '../../../common/utility/hash-model';
import { WorkorderService } from '../../services';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-workorder-save-as-template',
  templateUrl: './workorder-save-as-template.component.html',
  styleUrls: ['./workorder-save-as-template.component.less']
})
export class WorkorderSaveAsTemplateComponent implements OnInit {

  WOTemplate: IWOSaveTemplate;
  formGroupSetup: IFormGroupSetup;
  saveAsTemplateForm: FormGroup<IWOSaveTemplate>;
  TemplateData: any;
  TemplateBody: any;
  @Input() Templatemodal: PhxModalComponent;
  @Input() workorderDetails: IWorkOrder;

  constructor(
    private formbuilder: FormBuilder,
    private customFieldService: CustomFieldService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private workOrderService: WorkorderService
  ) {
    this.formInitialize();
  }

  ngOnInit() {
    this.formInitialize();
  }

  formInitialize() {
    this.WOTemplate = {
      templateName: null,
      templateDescription: null
    };
    this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formbuilder, customFieldService: this.customFieldService };
    this.saveAsTemplateForm = this.formBuilderGroupSetup(this.formGroupSetup, this.WOTemplate);
  }

  formBuilderGroupSetup(formGroupSetup: IFormGroupSetup, saveTemplateForm: IWOSaveTemplate): FormGroup<IWOSaveTemplate> {
    const formGroup = formGroupSetup.hashModel.getFormGroup<IWOSaveTemplate>(formGroupSetup.toUseHashCode, 'Save Template', saveTemplateForm, 0, () =>
      formGroupSetup.formBuilder.group<IWOSaveTemplate>({
        templateName:
          [
            saveTemplateForm.templateName,
            [
              Validators.required
            ]
          ],
        templateDescription:
          [
            saveTemplateForm.templateDescription,
            [
              Validators.required
            ]
          ]

      })
    );
    return formGroup;
  }

  saveTemplate() {
    this.TemplateData = {
      Description: this.saveAsTemplateForm.controls.templateDescription.value,
      EntityTypeId: PhxConstants.EntityType.Assignment,
      IsPrivate: false,
      Name: this.saveAsTemplateForm.controls.templateName.value,
      TemplateBody: this.getTemplateBody()
    };
    this.workOrderService.templateNew(this.TemplateData)
    .subscribe((response: any) => {
      this.workOrderService.get(response.EntityId).subscribe((result: any) => {
        const navigateTo = (Id: number, tabNavigationName: PhxConstants.WorkorderNavigationName) => {
          const navigatePath = `/next/template/workorder/${Id}/${tabNavigationName}`;
          this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent });
        };
        navigateTo(result.Id, 'core');
        this.Templatemodal.hide();
      });
    });
  }

  Cancel() {
    this.Templatemodal.hide();
  }

  getTemplateBody() {
    const assignment = this.workorderDetails.RootObject;
    const workOrder = assignment.WorkOrders.find(wo => wo.Id === this.workorderDetails.WorkOrderId);
    const workOrderVersion: any = workOrder.WorkOrderVersions.find(wov => wov.Id === this.workorderDetails.WorkOrderVersion.Id);
    delete workOrderVersion.extended;

    // Note: This has to be able to deserialize as the Assignment Entity Framework model.
    // If form data is stored differently, we need to do the transformations here to make it compatible with the EF model
    // IN WO 1.0, UI model has differences from the DB model
    // In WO 1.5, UI model should match the DB model

    this.TemplateBody = {
      Id: 0,
      StatusId: PhxConstants.AssignmentStatus.Onboarding,
      OrganizationIdInternal: assignment.OrganizationIdInternal,    // sourceAssignment.OrganizationIdInternal,
      OrganizationCode: assignment.OrganizationCode,
      UserProfileIdWorker: assignment.UserProfileIdWorker,
      WorkOrders: [
        {
          ...workOrder,
          StatusId: PhxConstants.WorkOrderStatus.Processing,
          WorkOrderNumber: 1,
          WorkOrderVersions: [
            {
              ...workOrderVersion,
              StatusId: PhxConstants.WorkOrderVersionStatus.Draft,
              VersionNumber: 1,
              IsOvertimeExempt: null,
              OvertimeExemptionReason: null,
              AutoCalculateOvertime: null
            }
          ]
        } as IWorkOrderDto as IWorkOrderDto
      ]
    };

    return this.TemplateBody;
  }
}
