import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: any, format: string = '', ...args: any[]): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const givenDate = new Date(value);
    givenDate.setHours(0, 0, 0, 0);

    if (today.valueOf() === givenDate.valueOf()) {
      return 'Today';
    }
    today.setDate(today.getDate() - 1); // `today` is now yesterday
    if (today.valueOf() === givenDate.valueOf()) {
      return 'Yesterday';
    }

    return formatDate(value, format, this.locale);
  }
}
