<div class="tab-content">
  <div class="tab-pane active">

      <div class="panel-heading">
        <div class="row d-flex">
          <div class="col-xs-12 col-lg-1">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">ID</label>
            <div class="ng-binding">
              {{workOrder.AssignmentId}}.{{workOrder.WorkOrderVersion.WorkOrderNumber}}.{{workOrder.WorkOrderVersion.VersionNumber}}
            </div>
          </div>
          <div class="col-xs-12 col-lg-2">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">Client</label>
            <div class="ng-binding">
              {{workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationClientDisplayName}}
              - {{workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient}}
            </div>
          </div>
          <div class="col-auto">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">Worker</label>
              <app-workorder-worker-link
                [workerProfileId]="workOrder.UserProfileIdWorker"
                [clickAnalyticsData]="workerLinkClickAnalyticsData"
              ></app-workorder-worker-link>
          </div>
          <div class="col-xs-12 col-lg-2">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">Work Order Period</label>
            <div class="ng-binding">
              <span>{{coreDetailsFormGroup.get('StartDate').value | date:html.phxConstants.DateFormat.mediumDate}}</span>
              -
              <span *ngIf="!isWOUnderTermination()">{{coreDetailsFormGroup.get('EndDate').value |
                date:html.phxConstants.DateFormat.mediumDate}}</span>
              <span *ngIf="isWOUnderTermination()">{{coreDetailsFormGroup.get('TerminationDate').value |
                date:html.phxConstants.DateFormat.mediumDate}}</span>

            </div>
          </div>
          <div class="col-xs-12 col-lg-2">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">Status</label>
            <div>
              <span>
                {{workOrder.StatusId | CodeValue: html.codeValueGroups.WorkOrderStatus}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-lg-2">
            <label class="col-xs-3 col-md-12 control-label no-padding-h-l">Version Status</label>
            <div>
              <span id="current-status" class="current-status">
                <div class="milestone-icon current"></div>
                <div>
                  {{workOrder.WorkOrderVersion.StatusId | CodeValue: html.codeValueGroups.WorkOrderVersionStatus}}
                </div>
              </span>
              <ng-container *ngIf="html.nextStatusId">
                <div class="milestone-separator hidden-lg hidden-xl hidden-xxl hidden-xxxl"></div>
                <span
                  class="future-status visible-lg-block visible-md-inline-block visible-sm-inline-block visible-xs-inline-block">
                  <div class="milestone-icon future"></div>
                  <div>
                    {{'workflow.stateHistory.NextStep' | phxTranslate}}
                    {{html.nextStatusId | CodeValue: html.codeValueGroups.WorkOrderVersionStatus}}
                  </div>
                </span>
              </ng-container>
              <app-phx-state-history-tooltip targetId="#current-status"
                [entityTypeId]="html.phxConstants.EntityType.WorkOrderVersion"
                [entityId]="workOrder.WorkOrderVersion.Id" [currentStatusId]="workOrder.WorkOrderVersion.StatusId"
                (viewHistory)="onViewHistory()">
              </app-phx-state-history-tooltip>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
