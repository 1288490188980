<ng-container [formGroup]="inputFormGroup">
  <td *ngIf="inputFormGroup.value.IsApplicable">
      <phx-form-control [control]="inputFormGroup.get('CommissionRateHeaderId')"
        labelText="{{inputFormGroup.value.FullName}}"
        [editable]="readOnlyStorage.IsCommissionsEditable && inputFormGroup.value.CommissionRates.length > 1 && checkPtFiledAccessibility('WorkOrderVersion.WorkOrderVersionCommissions','CommissionRateHeaderId')">
        <!-- control -->
        <app-phx-select-box-w-tooltip [formControl]="inputFormGroup.get('CommissionRateHeaderId')"
          [items]="inputFormGroup.get('CommissionRates').value" textField="DisplayText"
          valueField="CommissionRateHeaderId" [showTooltip]="showTooltip">
          <!-- select-box tooltip template -->
          <ng-template let-item>
            <app-commission-picker-tooltip-content [versions]="item?.CommissionRateVersions">
            </app-commission-picker-tooltip-content>
          </ng-template>
        </app-phx-select-box-w-tooltip>
        <!-- view mode -->
        <ng-container view-mode>
          <app-commission-picker-view-mode [commissionRateHeaderId]="inputFormGroup.value.CommissionRateHeaderId"
            [commissionRates]="inputFormGroup.value.CommissionRates" [showTooltip]="showTooltip"
            tooltipId="tooltip-commission-row-{{rowIndex}}">
          </app-commission-picker-view-mode>
        </ng-container>
      </phx-form-control>
  </td>
</ng-container>