<div class="workorder-container" *ngIf="workOrder && rootFormGroup && readOnlyStorage">
  <app-phx-header-version initials="WO" entityName="Work Order" entityPath="Work Order List / Work Order"
    [currentVersion]="workOrder.WorkOrderVersion.VersionNumber" [entityVersions]="html.versionsOrdered"
    [statusGroup]="html.codeValueGroups.WorkOrderVersionStatus" (selected)="onVersionClick($event)"
    [customEntityName]="customEntityName" [entityStatus]="workorderCompliancyStatus"
  >
  </app-phx-header-version>

  <div class="behaviors-container">
    <app-current-users-with-behavior [pageTypeId]="html.phxConstants.EntityType.WorkOrder"
      [primaryId]="behaviorPrimaryId"></app-current-users-with-behavior>
  </div>
  <div class="organization-wrap">
    <div class="row hidden-xs hidden-sm hidden-md">
      <app-workorder-offboarding-banner [editable]="readOnlyStorage.IsEditable" [workOrder]="workOrder"
        [stateActions]="stateActionsOffboarding" [availableStateActions]="workOrder.combinedAvailableStateActions">
      </app-workorder-offboarding-banner>
    </div>
  </div>

  <div class="organization-wrap">
    <div class="row organization-workflow-buttons-row-spacer">
      <app-phx-state-action-buttons [displayType]="stateActionDisplayType.DROPDOWN" [stateActions]="stateActions"
        [availableStateActions]="workOrder.combinedAvailableStateActions"></app-phx-state-action-buttons>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <app-workorder-header [workOrder]="workOrder"></app-workorder-header>
        </div>
        <div class="row">
          <app-phx-navigation-bar [navigationBarContent]="html.navigationBarContent" [url]="routerState.url"
            *ngIf="html.navigationBarContent" [entityId]="workOrder.WorkOrderId"
            [entityTypeId]="html.phxConstants.EntityType.WorkOrder">
          </app-phx-navigation-bar>
        </div>

        <div class="alert alert-info alert-dismissable error-box info-container"
          *ngIf="compareRemovedItemsMessage$ | async as compareRemovedItemsMessage">
          <a class="close" data-dismiss="alert" aria-label="close">
            <i class="material-icons">clear</i>
          </a>
          <div class="row">
            <div class="col-xs-12">
              <strong i18n="@@common.versionChangesInclude">Version changes include the following item(s) :
              </strong>{{compareRemovedItemsMessage}}
            </div>
          </div>
        </div>

        <div class="row">
          <app-workorder-tab-core *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.core"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-core>

          <app-workorder-tab-attributions
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.attributions && showAttributionsTab"
            [readOnlyStorage]="readOnlyStorage" [workOrder]="workOrder">
          </app-workorder-tab-attributions>

          <app-workorder-tab-parties *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.parties"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-parties>

          <app-workorder-tab-time-material
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.timematerialinvoice"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-time-material>

          <app-workorder-tab-expense-invoice
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.expensemanagement"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-expense-invoice>

          <ng-container
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.purchaseorder && showTemplate">
            Purchase Order not available in template mode
          </ng-container>

          <app-workorder-tab-purchase-order
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.purchaseorder && !showTemplate"
            [workOrderId]="workOrder.WorkOrderId">
          </app-workorder-tab-purchase-order>

          <app-workorder-tab-earnings-deductions
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.earningsanddeductions"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-earnings-deductions>

          <app-workorder-tab-taxes *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.taxes"
            [readOnlyStorage]="readOnlyStorage">
          </app-workorder-tab-taxes>

          <app-workorder-tab-documents
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.compliancedocuments">
          </app-workorder-tab-documents>

          <app-client-specific-fields
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.clientspecificfields"
            [fields]="clientSpecificFieldsData" [editable]="readOnlyStorage.IsEditable">
          </app-client-specific-fields>

          <app-phx-notes *ngIf="routerState?.routerPath === html.phxConstants.WorkorderNavigationName.notes"
            [entityId]="workOrder.WorkOrderId" [entityTypeId]="html.phxConstants.EntityType.WorkOrder"
            [entityStatusId]="workOrder.WorkOrderVersion.StatusId" [workOrderVersionId]="workOrder.WorkOrderVersion.Id">
          </app-phx-notes>

          <app-workorder-tab-activity
            *ngIf="routerState.routerPath===html.phxConstants.WorkorderNavigationName.activity">
          </app-workorder-tab-activity>
        </div>
        <div class="row panel-footer">
          <div class="row form-horizontal">
            <div class="col-md-5">
              <app-workorder-footer-effective-date [readOnlyStorage]="readOnlyStorage" [workOrder]="workOrder"
                [effectiveToDate]="effectiveToDate">
              </app-workorder-footer-effective-date>
            </div>
            <div class="col-md-7">
              <app-phx-state-action-buttons [stateActions]="stateActions"
                [availableStateActions]="workOrder.combinedAvailableStateActions"></app-phx-state-action-buttons>
            </div>
          </div>
        </div>
        <div *ngIf="html.validationMessages.length > 0 && !showTemplate"
          class="alert alert-danger alert-dismissable error-box">
          <div *ngFor="let message of html.validationMessages">
            <div>
              {{message}}
            </div>
          </div>
        </div>
        <div *ngIf="haveClientSpecificFieldError" class="alert alert-danger alert-dismissable error-box">
          <div>Client Specific Fields could not be verified or loaded. Please try refreshing the page.</div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!showTemplate">
      <div class="col-lg-12">
        <app-workorder-workflow #workFlow (commandExecuted)="refreshWorkOrder($event)"
          (commandValidation)="setValidationMessages($event)"></app-workorder-workflow>
      </div>
    </div>
  </div>

  <app-phx-modal
    #modalAtsReSync
    [buttons]="atsReSyncButtons"
    [title]="atsReSyncDialogTitle"
  >
    <!--ATS Bullhorn System-->
    <div class="commission-profile-top-group">
      <app-phx-panel
        *ngIf="hasJobOwnerDifferencesToShow || atsReSyncWarningMessage || hasReferenceContactDifferencesToShow"
        headerTitle="Sync Changes"
      >
        <h5>{{ atsReSyncMessageHeader }}</h5>
        <div *ngIf="hasJobOwnerDifferencesToShow">
          <span>ATS Job Owner: </span>
          <span
            ><strong>{{ atsJobOwner?.FullName }}</strong></span
          >
        </div>
        <div *ngIf="atsAddedBy?.UserProfileIdSales > 0 && showRecruiters">
          <span>ATS Added By: </span>
          <span
            ><strong>{{ atsAddedBy?.FullName }}</strong></span
          >
        </div>
        <div class="row margin-top-30">
          <div class="col-xs-6">
            <h5>{{ atsReSyncMessageBefore }}</h5>
          </div>
          <div class="col-xs-6">
            <h5>{{ atsReSyncMessageAfter }}</h5>
          </div>
        </div>
        <div class="row" *ngIf="hasJobOwnerDifferencesToShow">
          <!--Before-->
          <div class="col-xs-6">
            <span>Job Owner: </span>
            <span
              ><strong>{{ selectedFboJobOwner?.FullName }}</strong></span
            >
          </div>
          <!--After -->
          <div class="col-xs-6">
            <span>Job Owner: </span>
            <span
              ><strong>{{ atsJobOwner?.FullName }}</strong></span
            >
          </div>
        </div>
        <div class="row" *ngIf="timesheetRefenceContactHasDifference">
          <!--Before-->
          <div class="col-xs-6">
            <span>Timesheet Billing Invoice Reference Contact: </span>
            <span
              ><strong>{{ selectedTimesheetReferenceContactProfileName }}</strong></span
            >
          </div>
          <!--After -->
          <div class="col-xs-6">
            <span>Timesheet Billing Invoice Reference Contact: </span>
            <span
              ><strong>{{ reSyncedTimesheetReferenceContactProfileName }}</strong></span
            >
          </div>
        </div>
        <div class="row" *ngIf="expenseReferenceContactHasDifference">
          <!--Before-->
          <div class="col-xs-6">
            <span>Expense Billing Invoice Reference Contact: </span>
            <span
              ><strong>{{ selectedExpenseReferenceContactProfileName }}</strong></span
            >
          </div>
          <!--After -->
          <div class="col-xs-6">
            <span>Expense Billing Invoice Reference Contact: </span>
            <span
              ><strong>{{ reSyncedExpenseReferenceContactProfileName }}</strong></span
            >
          </div>
        </div>
        <div
          *ngIf="showRecruiters"
          class="row"
        >
          <!--Before-->
          <div class="col-xs-6">
            <div *ngFor="let selectedFboRecruiter of selectedFboRecruiters">
              <span>Recruiter: </span>
              <span
                ><strong>{{ selectedFboRecruiter?.FullName }}</strong></span
              >
            </div>
          </div>
          <!--After -->
          <div
            *ngIf="atsAddedBy?.UserProfileIdSales"
            class="col-xs-6"
          >
            <div *ngFor="let reSyncedFboRecruiter of reSyncedFboRecruiters">
              <span>Recruiter: </span>
              <span
                ><strong>{{ reSyncedFboRecruiter?.FullName }}</strong></span
              >
            </div>
          </div>
        </div>
        <!-- NOTE: attribution data is controlled by a feature flag configuration -->
        <div
          *ngIf="showAttributionsTab"
          class="row"
        >
          <!--Before-->
          <div class="col-xs-6">
            <div *ngFor="let salesProfileIds of selectedAttributionSaleUserProfileIds">
              <div>
                <span>Attribution Sales: </span>
                <span>
                  <strong>
                    {{ salesProfileIds | lookup : html.commonLists.listUserProfileInternal : 'userProfileId' : 'displayName' }}
                  </strong>
                </span>
              </div>
            </div>
            <div *ngFor="let recruiterProfileIds of selectedAttributionRecruiterUserProfileIds">
              <div>
                <span>Attribution Recruiter: </span>
                <span>
                  <strong>
                    {{ recruiterProfileIds | lookup : html.commonLists.listUserProfileInternal : 'userProfileId' : 'displayName' }}
                  </strong>
                </span>
              </div>
            </div>
          </div>
          <!--After -->
          <div class="col-xs-6">
            <div>
              <span>Attribution Sales: </span>
              <span
                ><strong>{{ atsJobOwner?.FullName }}</strong></span
              >
            </div>
            <div *ngIf="atsAddedBy?.UserProfileIdSales > 0 && showRecruiters">
              <div *ngFor="let reSyncedFboRecruiter of reSyncedFboRecruiters">
                <span>Attribution Recruiter: </span>
                <span
                  ><strong>{{ reSyncedFboRecruiter?.FullName }}</strong></span
                >
              </div>
            </div>
          </div>
        </div>
      </app-phx-panel>
    </div>

    <phx-banner
      *ngIf="atsReSyncWarningMessage"
      class="margin-top-30 block"
      type="danger"
      [message]="atsReSyncWarningMessage"
      [canClose]="false"
    ></phx-banner>
  </app-phx-modal>

  <app-termination-planning-modal [workOrder]="rootFormGroup.value" [workOrderDto]="workOrder"
    (loadAdditionalDocuments)="openAdditionalDocuments()"></app-termination-planning-modal>

  <app-release-sick-pay-modal [workOrder]="rootFormGroup.value" [workOrderDto]="workOrder"></app-release-sick-pay-modal>

</div>
<app-enter-ats-id-modal [showModal$]="showEnterAtsIdModal$.asObservable()"
  [setATSId$]="setATSId$"></app-enter-ats-id-modal>
