import { Component, Input, OnInit } from '@angular/core';
import { IBillingInfo, IBillingSalesTax, IReadOnlyStorage } from '../../models';
import { CodeValueGroups, CodeValueService, PhxConstants } from '../../../common';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { PhxFormControlLayoutType } from '../../../common/model';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-billing-taxes',
  templateUrl: './workorder-billing-taxes.component.html',
  styleUrls: ['./workorder-billing-taxes.component.less']
})
export class WorkorderBillingTaxesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() editable: boolean;
  @Input() inputFormGroup: FormGroup<IBillingInfo>;
  @Input() salesTaxJurisdictions: Array<any>;
  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;
  salesTaxTerritories: Array<any>;
  isAppliedList: Array<any>;
  layoutType: typeof PhxFormControlLayoutType;
  codeValueGroups = CodeValueGroups;

  constructor(
    private codeValueService: CodeValueService,
    private authService: AuthService
  ) {
    super();
    this.isAppliedList = [{ id: true, text: 'Yes' }, { id: false, text: 'No' }];
    this.layoutType = PhxFormControlLayoutType;
  }

  get salesTaxFormArray(): FormArray<IBillingSalesTax> {
    return this.inputFormGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
  }

  ngOnInit() {
    this.salesTaxTerritories = this.codeValueService.getRelatedCodeValues(this.codeValueGroups.Subdivision, PhxConstants.Country.CA, this.codeValueGroups.Country)
      .concat(this.codeValueService.getRelatedCodeValues(this.codeValueGroups.Subdivision, PhxConstants.Country.US, this.codeValueGroups.Country));
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

}
