<form [formGroup]="inputFormGroup">
  <h4>Rebate and VMS Fee</h4>

  <phx-form-control [control]="inputFormGroup.controls.HasRebate" labelText="Rebate" [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','HasRebate')"
    viewModeText="{{inputFormGroup.controls.HasRebate.value? 'Yes' : 'No'}}">
    <fieldset [disabled]="!workorderLineOfBusinessId || !billingOraganizationId">
      <label class="radio-inline">
        <input class="form-check-input" name="HasRebate" type="radio" [value]="true" [checked]="inputFormGroup.controls.HasRebate.value"
          [formControl]="inputFormGroup.controls.HasRebate">Yes</label>
      <label class="radio-inline">
        <input class="form-check-input" name="HasRebate" type="radio" [value]="false" [checked]="!inputFormGroup.controls.HasRebate.value"
          [formControl]="inputFormGroup.controls.HasRebate">No</label>
    </fieldset>
  </phx-form-control>

  <div *ngIf="inputFormGroup.controls.HasRebate.value">

    <phx-form-control [control]="inputFormGroup.controls.RebateHeaderId" labelText="Rate Selection"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','RebateHeaderId')"
      viewModeText="{{inputFormGroup.controls.RebateHeaderId.value | lookup:html.commonLists.listavailableRebates:'headerId':'description' }}">
      <app-phx-select-box [formControl]="inputFormGroup.controls.RebateHeaderId" [items]="html.commonLists.listavailableRebates"
        textField="description" valueField="headerId">
      </app-phx-select-box>
    </phx-form-control>

    <phx-form-control [control]="inputFormGroup.controls.RebateTypeId" labelText="Rebate Type" [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','RebateTypeId')"
      viewModeText="{{inputFormGroup.controls.RebateHeaderId.value ? getRebateType(html.commonLists.listavailableRebates, inputFormGroup.controls.RebateHeaderId.value)
        : (inputFormGroup.controls.RebateTypeId.value | CodeValue:codeValueGroups.RebateType) }}">
      <div *ngIf="!inputFormGroup.controls.RebateHeaderId.value">
        <app-phx-select-box [formControl]="inputFormGroup.controls.RebateTypeId" [items]="html.codeValueLists.listRebateTypes"
          textField="text" valueField="id">
        </app-phx-select-box>
      </div>
      <div class="form-control-static" *ngIf="inputFormGroup.controls.RebateHeaderId.value">
          {{getRebateType(html.commonLists.listavailableRebates, inputFormGroup.controls.RebateHeaderId.value)}}
      </div>
    </phx-form-control>

    <phx-form-control [control]="inputFormGroup.controls.RebateRate" labelText="Rebate Rate" 
    viewModeText="{{inputFormGroup.controls.RebateHeaderId.value ? getRebateOrVmsFeeRate(html.commonLists.listavailableRebates, inputFormGroup.controls.RebateHeaderId.value)
      : ( (inputFormGroup.controls.RebateTypeId.value === phxConstants.RebateType.Amount ? '$' : '')
        + inputFormGroup.controls.RebateRate.value
        + (inputFormGroup.controls.RebateTypeId.value === phxConstants.RebateType.Percentage ? '%' : '')
      )}}"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','RebateRate')">
      <div class="input-group" *ngIf="!inputFormGroup.controls.RebateHeaderId.value &&
      (inputFormGroup.controls.RebateTypeId.value === phxConstants.RebateType.Percentage)">
        <input [formControl]="inputFormGroup.controls.RebateRate" type="text" class="form-control" phxFloatBetweenInput='{"from":0, "to":100, "decimalplaces":4}'/>
        <span class="input-group-addon">
          <b>%</b>
        </span>
      </div>
      <div class="input-group" *ngIf="!inputFormGroup.controls.RebateHeaderId.value && 
      (inputFormGroup.controls.RebateTypeId.value === phxConstants.RebateType.Amount)">
        <span class="input-group-addon rebate-addon">
          <b>$</b>
        </span>
        <input [formControl]="inputFormGroup.controls.RebateRate" type="text" class="form-control" phxFloatBetweenInput='{"from":0, "to":9999999999999.9999, "decimalplaces":4}' />
      </div>
      <div class="form-control-static" *ngIf="inputFormGroup.controls.RebateHeaderId.value">
        <div>{{getRebateOrVmsFeeRate(html.commonLists.listavailableRebates,
          inputFormGroup.controls.RebateHeaderId.value)}}</div>
      </div>
    </phx-form-control>

  </div>

    <phx-form-control [control]="inputFormGroup.controls.HasVmsFee" labelText="VMS Fee" viewModeText="{{inputFormGroup.controls.HasVmsFee.value? 'Yes' : 'No'}}"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','HasVmsFee')">
      <fieldset [disabled]="!workorderLineOfBusinessId || !billingOraganizationId">
        <label class="radio-inline">
          <input class="form-check-input" name="HasVmsFee" type="radio" [value]="true" [checked]="inputFormGroup.controls.HasVmsFee.value"
            [formControl]="inputFormGroup.controls.HasVmsFee">Yes</label>
        <label class="radio-inline">
          <input class="form-check-input" name="HasVmsFee" type="radio" [value]="false" [checked]="!inputFormGroup.controls.HasVmsFee.value"
            [formControl]="inputFormGroup.controls.HasVmsFee">No</label>
      </fieldset>
    </phx-form-control>

  <div *ngIf="inputFormGroup.controls.HasVmsFee.value">

      <phx-form-control [control]="inputFormGroup.controls.VmsFeeHeaderId" labelText="VMS Fee Selection"
        [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsFeeHeaderId')"
        viewModeText="{{inputFormGroup.controls.VmsFeeHeaderId.value | lookup:html.commonLists.listavailableVmsFees:'headerId':'description' }}">
        <app-phx-select-box [formControl]="inputFormGroup.controls.VmsFeeHeaderId" [items]="html.commonLists.listavailableVmsFees"
          textField="description" valueField="headerId">
        </app-phx-select-box>
      </phx-form-control>

    <!-- [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsFeeTypeId')" -->
      <phx-form-control [control]="inputFormGroup.controls.VmsFeeTypeId" labelText="VMS Fee Type" [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsFeeTypeId')"
        viewModeText="{{ inputFormGroup.controls.VmsFeeHeaderId.value ? getRebateType(html.commonLists.listavailableVmsFees, inputFormGroup.controls.VmsFeeHeaderId.value) :
          (inputFormGroup.controls.VmsFeeTypeId.value | CodeValue:codeValueGroups.RebateType)
        }}">
        <div class="form-control-static" *ngIf="!inputFormGroup.controls.VmsFeeHeaderId.value && (readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsFeeTypeId'))">
          <app-phx-select-box [formControl]="inputFormGroup.controls.VmsFeeTypeId" [items]="html.codeValueLists.listRebateTypes"
            textField="text" valueField="id">
          </app-phx-select-box>
        </div>
        <div class="form-control-static" *ngIf="inputFormGroup.controls.VmsFeeHeaderId.value">
          {{getRebateType(html.commonLists.listavailableVmsFees, inputFormGroup.controls.VmsFeeHeaderId.value)}}
        </div>
      </phx-form-control>

      <phx-form-control [control]="inputFormGroup.controls.VmsFeeRate" labelText="VMS Fee Rate" 
      viewModeText="{{inputFormGroup.controls.VmsFeeHeaderId.value ? getRebateOrVmsFeeRate(html.commonLists.listavailableVmsFees,inputFormGroup.controls.VmsFeeHeaderId.value) : 
        ( (inputFormGroup.controls.VmsFeeTypeId.value === phxConstants.RebateType.Amount ? '$': '')
        + inputFormGroup.controls.VmsFeeRate.value 
        + (inputFormGroup.controls.VmsFeeTypeId.value === phxConstants.RebateType.Percentage ? '%': '')
        )}}"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsFeeRate')">
        <div class="input-group" *ngIf="!inputFormGroup.controls.VmsFeeHeaderId.value &&
              (inputFormGroup.controls.VmsFeeTypeId.value === phxConstants.RebateType.Percentage)">
          <input [formControl]="inputFormGroup.controls.VmsFeeRate" type="text" class="form-control" phxFloatBetweenInput='{"from":0, "to":100, "decimalplaces":4}'/>
          <span class="input-group-addon">
            <b>%</b>
          </span>
        </div>
        <div class="input-group" *ngIf="!inputFormGroup.controls.VmsFeeHeaderId.value && 
              (inputFormGroup.controls.VmsFeeTypeId.value === phxConstants.RebateType.Amount)">
          <span class="input-group-addon rebate-addon">
            <b>$</b>
          </span>
          <input [formControl]="inputFormGroup.controls.VmsFeeRate" type="text" class="form-control" phxFloatBetweenInput='{"from":0, "to":9999999999999.9999, "decimalplaces":4}'/>
        </div>
        <div class="form-control-static" *ngIf="inputFormGroup.controls.VmsFeeHeaderId.value">
          {{getRebateOrVmsFeeRate(html.commonLists.listavailableVmsFees,inputFormGroup.controls.VmsFeeHeaderId.value)}}
        </div>
      </phx-form-control>
      
    <div *ngIf="showFlowdownFee">

      <phx-form-control labelText="Flowdown Fee to Subvendor Organization" [control]="inputFormGroup.controls.IsFlowdownFee" 
      [editable]="readOnlyStorage.IsEditable"
        viewModeText="{{inputFormGroup.controls.VmsFeeHeaderId.value 
          ? getFlowdownFee(html.commonLists.listavailableVmsFees,inputFormGroup.controls.VmsFeeHeaderId.value) ? 'Yes' : 'No'
          : inputFormGroup.controls.IsFlowdownFee.value ? 'Yes' : 'No' }}">
        <fieldset *ngIf="!inputFormGroup.controls.VmsFeeHeaderId.value">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="true"
            [formControl]="inputFormGroup.controls.IsFlowdownFee">Yes</label>
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="false"
            [formControl]="inputFormGroup.controls.IsFlowdownFee">No</label>
        </fieldset>
        <div class="form-control-static" *ngIf="inputFormGroup.controls.VmsFeeHeaderId.value">
            {{getFlowdownFee(html.commonLists.listavailableVmsFees,inputFormGroup.controls.VmsFeeHeaderId.value) ? 'Yes' : 'No'}}
          </div>
      </phx-form-control>
      
    </div>
  </div>
</form>