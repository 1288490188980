import { Component, OnInit } from '@angular/core';
import { PhxConstants, CodeValueService, WindowRefService, CodeValueGroups } from 'src/app/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PhxDataTableConfiguration, RowHighlightingConfig, PhxDataTableColumn, PhxDataTableColumnFormat } from 'src/app/common/model';

@Component({
  selector: 'app-workorder-tab-activity-transactions',
  templateUrl: './workorder-tab-activity-transactions.component.html',
  styleUrls: ['./workorder-tab-activity-transactions.component.less']
})
export class WorkorderTabActivityTransactionsComponent implements OnInit {

  readonly phxConstants = PhxConstants;
  readonly totalColumnFormat: PhxDataTableColumnFormat = { type: 'fixedPoint', precision: 2 };
  // eslint-disable-next-line max-len
  odataParams = '$select=Id,TransactionNumber,TransactionDate,FromDate,ToDate,BillingCurrencyId,Total,PaymentCurrencyId,PaymentTotal,TransactionHeaderStatus,PlannedReleaseDate,ActualReleaseDate,PaymentMethodId,PaymentMethodReference,InvoiceReleaseDate,InvoiceNumber,BillingTransactionARStatusId';
  datasourceurl = '';
  dataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    rowHighlightingConfig: new RowHighlightingConfig()
  });

  columns: Array<PhxDataTableColumn> = [
    new PhxDataTableColumn({
      dataField: 'Id',
      width: 100,
      caption: 'ID',
      dataType: 'number',
    }),
    new PhxDataTableColumn({
      dataField: 'TransactionNumber',
      caption: 'Transaction Number',
    }),
    new PhxDataTableColumn({
      dataField: 'TransactionDate',
      caption: 'CreateDate',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'FromDate',
      caption: 'StartDate',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'ToDate',
      caption: 'EndDate',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'BillingCurrencyId',
      caption: 'Billing Currency',
      lookup: {
        dataSource: this.getCodeValue(CodeValueGroups.Currency),
        valueExpr: 'id',
        displayExpr: 'code'
      },
      alignment: 'right'
    }),
    new PhxDataTableColumn({
      dataField: 'Total',
      caption: 'Total Billing(s)',
      dataType: 'decimal',
      format: this.totalColumnFormat,
      alignment: 'right',
    }),
    new PhxDataTableColumn({
      dataField: 'PaymentCurrencyId',
      caption: 'Payment Currency',
      lookup: {
        dataSource: this.getCodeValue(CodeValueGroups.Currency),
        valueExpr: 'id',
        displayExpr: 'code'
      },
      alignment: 'right'
    }),
    new PhxDataTableColumn({
      dataField: 'PaymentTotal',
      caption: 'Total Payment(s)',
      dataType: 'decimal',
      format: this.totalColumnFormat,
      alignment: 'right',
    }),
    new PhxDataTableColumn({
      dataField: 'TransactionHeaderStatus',
      caption: 'Status',
      lookup: {
        dataSource: this.codeValueService.getCodeValues(CodeValueGroups.TransactionHeaderStatus, true)
        .filter(codeValue => codeValue.id !== PhxConstants.TransactionHeaderStatus.New &&
         codeValue.id !== PhxConstants.TransactionHeaderStatus.Deleted),
        valueExpr: 'id',
        displayExpr: 'text'
      },
    }),
    new PhxDataTableColumn({
      dataField: 'PlannedReleaseDate',
      caption: 'Worker Schedule Payment Release Date',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'ActualReleaseDate',
      caption: 'Worker Actual Payment Release Date',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'PaymentMethodId',
      caption: 'Worker Payment Method',
      lookup: {
        dataSource: this.getCodeValue(CodeValueGroups.PaymentMethodType),
        valueExpr: 'id',
        displayExpr: 'text'
      }
    }),
    new PhxDataTableColumn({
      dataField: 'PaymentMethodReference',
      caption: 'Worker Payment Method reference',
      dataType: 'number',
    }),
    new PhxDataTableColumn({
      dataField: 'InvoiceReleaseDate',
      caption: 'Invoice Release Date',
      dataType: 'date',
    }),
    new PhxDataTableColumn({
      dataField: 'InvoiceNumber',
      caption: 'Invoice Number',
      dataType: 'string',
    }),
    new PhxDataTableColumn({
      dataField: 'BillingTransactionARStatusId',
      caption: 'AR Status',
      lookup: {
        dataSource: this.getCodeValue(CodeValueGroups.ARStatus),
        valueExpr: 'id',
        displayExpr: 'text'
      }
    })
  ];
  constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private codeValueService: CodeValueService,
    private winRef: WindowRefService,
      ) { }

  ngOnInit() {
    this.activateRouter.params.subscribe(params => {
      const workorderId = + params.workorderId;
      this.datasourceurl = `transactionHeader/getAllByWorkOrderId/${workorderId}`;
    });
  }

  getCodeValue(codeTable: string) {
    return this.codeValueService
      .getCodeValues(codeTable, true)
      .sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      })
      .map(codeValue => {
        return {
          text: codeValue.text,
          id: codeValue.id,
          code: codeValue.code
        };
      });
  }

  public onRowSelected(event: any) {
    if (event?.data) {
      this.goToTransaction(event.data);
    }
  }

  goToTransaction(transaction) {
    this.router.navigate(['/next', 'transaction', transaction.Id, PhxConstants.TransactionNavigationName.summary]);
  }
  onContextMenuPreparing(event: any) {
    if (event?.row?.rowType === 'data') {
    event.items = [{
      text: 'Open transaction in new tab',
      onItemClick: () => {
        if (event.row.data.IsDraft && (event.row.data.TransactionTypeId === PhxConstants.TransactionType.Manual)) {
          return this.winRef.nativeWindow.open(`/next/transaction/${event.row.data.Id}/detail`,  '_blank');
        } else {
          return this.winRef.nativeWindow.open(`/next/transaction/${event.row.data.Id}/summary`,  '_blank');
        }
      }}];
    }
  }
}
