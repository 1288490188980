<form [formGroup]="inputFormGroup">
  <div *ngIf="inputFormGroup.controls.RateTypeId.value === phxConstants.RateType.Primary; else nonPrimaryRateType"
       style="margin-top:25px;">
    <phx-form-control
            [control]="inputFormGroup.controls.Rate"
            labelText="Primary"
            [editable]="readOnlyStorage.IsEditable  && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingRate','Rate')"
            [viewModeText]="inputFormGroup.controls.Rate.value | currency:currencyCode:'symbol-narrow'"
            [warningMessage]="html.rateWarningMessage"
    >
      <div class="col-xl-12 input-group payroll-taxes-gray-box-fields">
        <input [formControl]="inputFormGroup.controls.Rate" type="text" class="form-control"
               phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}'/>
        <span class="input-group-addon">$</span>
      </div>
    </phx-form-control>
  </div>
  <ng-template #nonPrimaryRateType>
    <div *ngIf="!inputFormGroup.controls.RateTypeId.value">
      <phx-form-control [control]="inputFormGroup.controls.RateTypeId" labelText="Rate Type"
                        [editable]="readOnlyStorage.IsEditable"
                        viewModeText="{{inputFormGroup.controls.RateTypeId.value | CodeValue:codeValueGroups.RateType }}">
        <app-phx-select-box [formControl]="inputFormGroup.controls.RateTypeId"
                            [items]="(overtimeIsExempt 
                              ? html.codeValueLists.listworkOrderRateTypesNoOt
                              : html.codeValueLists.listworkOrderRateTypes
                            ) | filterSelectChoicesByUsage: selectedRateType.value: inputFormGroup.value: 'RateTypeId': 'id': 'text'"
                            textField="text" valueField="id">
        </app-phx-select-box>
      </phx-form-control>
    </div>
    <div *ngIf="inputFormGroup.controls.RateTypeId.value>0" [ngClass]="{'margin-top-90':showOptions}">
      <phx-form-control
              [control]="inputFormGroup.controls.Rate"
              [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingRate','Rate')"
              [labelText]="inputFormGroup.controls.RateTypeId.value | lookup:html.codeValueLists.listworkOrderRateTypes"
              [viewModeText]="inputFormGroup.controls.Rate.value | currency:currencyCode:'symbol-narrow'"
      >
        <div class="col-lg-10 padding-0">
          <input [formControl]="inputFormGroup.controls.Rate" type="text" class="form-control"
                 phxFloatBetweenInput='{"from":0, "to":999999.99, "decimalplaces":2}'/>
        </div>
        <div class="col-xs-1 pull-right padding-0">
          <button type="button" class="btn btn-default btn-material pull-right"
                  (click)="onRemoveBillingPartyRate()">
            <i class="material-icons">delete</i>
          </button>
        </div>
      </phx-form-control>
    </div>
  </ng-template>
  <div *ngIf="inputFormGroup.controls.RateTypeId.value > 0">
    <phx-form-control [control]="inputFormGroup.controls.RateUnitId" labelText="Rate Unit"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingRate','RateUnit')"
                      viewModeText="{{inputFormGroup.controls.RateUnitId.value | CodeValue:codeValueGroups.RateUnit }}">
      <app-phx-select-box [formControl]="inputFormGroup.controls.RateUnitId"
                          [items]="html.codeValueLists.listWorkOrderRateUnits" textField="text" valueField="id">
      </app-phx-select-box>
    </phx-form-control>

    <phx-form-control [control]="inputFormGroup.controls.Description" labelText="Description"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{inputFormGroup.controls.Description?.value}}">
      <input [formControl]="inputFormGroup.controls.Description" type="text" class="form-control"
             maxlength="64"/>
    </phx-form-control>
  </div>
</form>
