import { Injectable } from '@angular/core';
import { PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingInfo, IBillingInfoes, IBillingInvoice, IBillingRecipient, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { Validators } from '@angular/forms';

@Injectable()
export class BillingInfoFormService {

  constructor(private fb: FormBuilder) { }

  createBillingInfoesFormGroup(billingInfoes: Array<IBillingInfo>, validations: any, invoiceType: number): FormGroup<IBillingInfoes> {
    return this.fb.group<IBillingInfoes>({
      BillingInfoes: this.createBillingInfoFormArray(billingInfoes, validations, invoiceType)
    });
  }

  updateBillingInfoFormArray(
    formArray: FormArray<IBillingInfo>,
    billingInfoes: Array<IBillingInfo>,
    validations: any,
    invoiceType: number
  ) {
    if (formArray.length && billingInfoes.length) {
      billingInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IBillingInfo>;
        if (formGroup) {
          this.updateBillingInfoeFormGroup(formGroup, item, validations, invoiceType);
        } else {
          formArray.push(this.createBillingInfoeFormGroup(item, validations, invoiceType));
        }
      });
      if (formArray.length > billingInfoes.length) {
        this.clearArray(formArray, billingInfoes.length);
      }
    } else if (billingInfoes.length) {
      const array = this.createBillingInfoFormArray(billingInfoes, validations, invoiceType);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  formGroupToPartial(workOrder: IWorkOrder, billingInfoesFormGroup: FormGroup<IBillingInfoes>, invoiceType: number): IWorkOrder {
    const fn = billing => {
      if (billing.InvoiceTypeId === PhxConstants.InvoiceType.TimeSheet) {
        billing.BillingTransactionGenerationMethodId =
          (workOrder.WorkOrderVersion.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval ||
            workOrder.WorkOrderVersion.TimeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval) &&
            workOrder.WorkOrderVersion.IsTimeSheetUsesProjects
            ? billing.BillingTransactionGenerationMethodId
            : null;
      } else {
        billing.BillingTransactionGenerationMethodId =
          (workOrder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval ||
            workOrder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval) &&
            workOrder.WorkOrderVersion.IsExpenseUsesProjects
            ? billing.BillingTransactionGenerationMethodId
            : null;
      }
      return billing;
    };
    const billingInfoes: any = billingInfoesFormGroup.value;

    billingInfoes.BillingInfoes.forEach(i => {
      const index = workOrder.WorkOrderVersion.BillingInfoes.findIndex(x => x.Id === i.Id);
      if (index > -1) {
        workOrder.WorkOrderVersion.BillingInfoes[index] = {
          ...workOrder.WorkOrderVersion.BillingInfoes[index],
          BillingInvoices: [
            ...workOrder.WorkOrderVersion.BillingInfoes[index].BillingInvoices
              .map(a => fn(a))
              .filter(x => x.InvoiceTypeId !== invoiceType),
            ...i.BillingInvoices
              .map(a => fn(a))
              .filter(x => x.InvoiceTypeId === invoiceType)
          ]
        };
      }
    });

    return workOrder;
  }

  addBillingRecipientFormGroup(formGroup: FormGroup<IBillingInvoice>) {
    // first recipient is the "to" recipient
    const formArray = formGroup.get('BillingRecipients') as FormArray<IBillingRecipient>;
    const isRecipientTypeTo = !formArray?.length;
    formArray.push(this.createBlankBillingRecipientFormGroup(formGroup.value.Id, isRecipientTypeTo));
  }

  removeBillingRecipientFormGroup(index: number, formGroup: FormGroup<IBillingInvoice>) {
    const formArray = formGroup.get('BillingRecipients') as FormArray<IBillingRecipient>;
    formArray.removeAt(index);
  }

  addTimesheetBillingInvoiceNote(formGroup: FormGroup<IBillingInvoice>, emitEvent = false) {
    const data = [2, 3, 4];
    for (const noteNumber of data) {
      const propertyName = 'ShowBillingInfoNote' + noteNumber;
      if (!formGroup.controls[propertyName].value) {
        formGroup.controls[propertyName].patchValue(true, { emitEvent });
        break;
      }
    }
  }

  removeTimeSheetBillingInvoiceNote(index: number, formGroup: FormGroup<IBillingInvoice>, emitEvent = false) {
    formGroup.controls['InvoiceNote' + index].patchValue(null, { emitEvent });
    const data = [2, 3, 4];
    let lastValue: string;
    let lastIndex: number;
    for (let i = 0; i < data.length; i++) {
      const propertyName = 'ShowBillingInfoNote' + data[i];
      if (formGroup.controls[propertyName].value) {
        lastValue = propertyName;
        lastIndex = i;
      }
    }
    formGroup.controls[lastValue].patchValue(false, { emitEvent });
    formGroup.controls['InvoiceNote' + data[lastIndex]].patchValue(null, { emitEvent });
  }

  private createBillingInfoFormArray(billingInfoes: Array<IBillingInfo>, validations: any, invoiceType: number): FormArray<IBillingInfo> {
    return this.fb.array<IBillingInfo>(
      billingInfoes.map((billingInfo: IBillingInfo) => this.createBillingInfoeFormGroup(billingInfo, validations, invoiceType))
    );
  }

  private createBillingInfoeFormGroup(billingInfo: IBillingInfo, validations: any, invoiceType: number): FormGroup<IBillingInfo> {
    return this.fb.group<IBillingInfo>({
      BillingInvoices: this.createBillingInvoiceFormArray(billingInfo.BillingInvoices, validations, invoiceType),
      OrganizationIdClient: [billingInfo.OrganizationIdClient],
      OrganizationClientDisplayName: [billingInfo.OrganizationClientDisplayName],
      Id: [billingInfo.Id]
    });
  }

  private createBillingInvoiceFormArray(invoices: Array<IBillingInvoice>, validations: any, invoiceType: number): FormArray<IBillingInvoice> {
    return this.fb.array<IBillingInvoice>(
      invoices.map((invoice: IBillingInvoice) => this.createBillingInvoiceFormGroup(invoice, validations, invoiceType))
    );
  }

  private createBillingInvoiceFormGroup(invoice: IBillingInvoice, validations: any, invoiceType: number): FormGroup<IBillingInvoice> {
    return this.fb.group<IBillingInvoice>({
      Id: [invoice.Id],
      InvoiceTypeId: [invoice.InvoiceTypeId],
      IsDraft: [invoice.IsDraft],
      BillingInfoId: [invoice.BillingInfoId],
      BillingInvoicePresentationStyleId: [
        invoice.BillingInvoicePresentationStyleId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoicePresentationStyleId', [
            Validators.required
          ])
          : null
      ],
      BillingConsolidationTypeId: [
        invoice.BillingConsolidationTypeId,
        invoice.BillingInvoicePresentationStyleId === PhxConstants.BillingInvoicePresentationStyle.Consolidated &&
          !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) &&
          invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingConsolidationTypeId', [
            Validators.required
          ])
          : null
      ],
      BillingTransactionGenerationMethodId: [
        invoice.BillingTransactionGenerationMethodId,
        validations.isBillingValid && !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingTransactionGenerationMethodId', [
            Validators.required
          ])
          : null
      ],
      IsUsesAlternateBilling: [
        invoice.IsUsesAlternateBilling,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'IsUsesAlternateBilling', [
            Validators.required
          ])
          : null
      ],
      OrganizatonClientRoleAlternateBillingId: [
        invoice.OrganizatonClientRoleAlternateBillingId,
        invoice.IsUsesAlternateBilling && !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'OrganizatonClientRoleAlternateBillingId', [
            Validators.required
          ])
          : null
      ],
      BillingFrequencyId: [
        invoice.BillingFrequencyId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingFrequencyId', [
            Validators.required
          ])
          : null
      ],
      BillingInvoiceTermsId: [
        invoice.BillingInvoiceTermsId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTermsId', [
            Validators.required
          ])
          : null
      ],
      IsSalesTaxAppliedOnVmsImport: [
        invoice.IsSalesTaxAppliedOnVmsImport,
        invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense && !validations.isNoExpenseValid && invoice.InvoiceTypeId === invoiceType && validations.isSalesTaxAppliedOnVmsImport
          ? [Validators.required]
          : null
      ],
      BillingInvoiceTemplateId: [
        invoice.BillingInvoiceTemplateId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTemplateId', [
            Validators.required
          ])
          : null
      ],
      BillingReferenceContactProfileId: [
        invoice.BillingReferenceContactProfileId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingReferenceContactProfileId', [
            Validators.required
          ])
          : null
      ],
      SourceId: [invoice.SourceId],
      InvoiceNote1: [invoice.InvoiceNote1],
      InvoiceNote2: [invoice.InvoiceNote2],
      InvoiceNote3: [invoice.InvoiceNote3],
      InvoiceNote4: [invoice.InvoiceNote4],
      ShowBillingInfoNote2: [invoice.ShowBillingInfoNote2 ? invoice.ShowBillingInfoNote2 : !!invoice.InvoiceNote2],
      ShowBillingInfoNote3: [invoice.ShowBillingInfoNote3 ? invoice.ShowBillingInfoNote3 : !!invoice.InvoiceNote3],
      ShowBillingInfoNote4: [invoice.ShowBillingInfoNote4 ? invoice.ShowBillingInfoNote4 : !!invoice.InvoiceNote4],
      BillingRecipients: this.createBillingRecipientFormArray(invoice, validations, invoiceType)
    });
  }

  private createBillingRecipientFormArray(invoice: IBillingInvoice, validations: any, invoiceType: number) {
    return this.fb.array<any>(
      invoice.BillingRecipients.map((recipient: IBillingRecipient) => this.createBillingRecipientFormGroup(recipient, invoice, validations, invoiceType))
    );
  }

  private createBillingRecipientFormGroup(recipient: IBillingRecipient, invoice: IBillingInvoice, validations: any, invoiceType: number) {
    return this.fb.group<any>({
      BillingInvoiceId: [recipient.BillingInvoiceId],
      DeliveryMethodId: [
        recipient.DeliveryMethodId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliveryMethodId', [
              Validators.required
            ])
          : null
      ],
      FileExportFormatId: [
        recipient.FileExportFormatId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) &&
          invoice.InvoiceTypeId === invoiceType &&
          recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.FileExportFormat
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'FileExportFormatId', [
            Validators.required
          ])
          : null
      ],
      Id: [recipient.Id],
      IsDraft: [recipient.IsDraft],
      RecipientTypeId: [
        recipient.RecipientTypeId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'RecipientTypeId', [
            Validators.required
          ])
          : null
      ],
      SourceId: [recipient.SourceId],
      UserProfileId: [
        recipient.UserProfileId,
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'UserProfileId', [
            Validators.required
          ])
          : null
      ],
      DeliverToUserProfileId: [
        recipient.DeliverToUserProfileId,
        recipient.DeliveryMethodId && (recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.InternalProfile
          || recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.ClientProfile)
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliverToUserProfileId', [
            Validators.required
          ])
          : null
      ]
    });
  }

  private createBlankBillingRecipientFormGroup(id: number, isRecipientTypeTo: boolean = false): FormGroup<any> {
    return this.fb.group<any>({
      Id: 0,
      IsDraft: true,
      SourceId: null,
      UserProfileId: [null, [Validators.required]],
      BillingInvoiceId: [id],
      DeliveryMethodId: [null, [Validators.required]],
      FileExportFormatId: null,
      RecipientTypeId: [
        isRecipientTypeTo
          ? PhxConstants.RecipientType.InvoiceRecipient
          : null,
        [Validators.required]
      ],
      DeliverToUserProfileId: null
    });
  }

  private updateBillingInfoeFormGroup(
    formGroup: FormGroup<IBillingInfo>,
    billingInfo: IBillingInfo,
    validations: any,
    invoiceType: number
  ) {

    formGroup.patchValue({
      OrganizationIdClient: billingInfo.OrganizationIdClient,
      OrganizationClientDisplayName: billingInfo.OrganizationClientDisplayName,
      Id: billingInfo.Id
    }, { emitEvent: false });

    const billingInvoicesFormArray = formGroup.get('BillingInvoices') as FormArray<IBillingInvoice>;
    this.updateBillingInvoiceFormArray(billingInvoicesFormArray, billingInfo.BillingInvoices, validations, invoiceType);

  }

  private updateBillingInvoiceFormArray(
    formArray: FormArray<IBillingInvoice>,
    invoices: Array<IBillingInvoice>,
    validations: any,
    invoiceType: number
  ) {
    if (formArray.length && invoices.length) {
      invoices.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IBillingInvoice>;
        if (formGroup) {
          this.updateBillingInvoiceFormGroup(formGroup, item, validations, invoiceType);
        } else {
          formArray.push(this.createBillingInvoiceFormGroup(item, validations, invoiceType));
        }
      });
      if (formArray.length > invoices.length) {
        this.clearArray(formArray, invoices.length);
      }
    } else if (invoices.length) {
      const array = this.createBillingInvoiceFormArray(invoices, validations, invoiceType);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingInvoiceFormGroup(
    formGroup: FormGroup<IBillingInvoice>,
    invoice: IBillingInvoice,
    validations: any,
    invoiceType: number
  ) {

    formGroup.get('BillingInvoicePresentationStyleId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoicePresentationStyleId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('BillingConsolidationTypeId').setValidators(
      invoice.BillingInvoicePresentationStyleId === PhxConstants.BillingInvoicePresentationStyle.Consolidated &&
        !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) &&
        invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingConsolidationTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('BillingTransactionGenerationMethodId').setValidators(
      validations.isBillingValid && !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingTransactionGenerationMethodId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('IsUsesAlternateBilling').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'IsUsesAlternateBilling', [
          Validators.required
        ])
        : null
    );

    formGroup.get('OrganizatonClientRoleAlternateBillingId').setValidators(
      invoice.IsUsesAlternateBilling && !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'OrganizatonClientRoleAlternateBillingId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('BillingFrequencyId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingFrequencyId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('BillingInvoiceTermsId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTermsId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('IsSalesTaxAppliedOnVmsImport').setValidators(
      invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense && !validations.isNoExpenseValid && invoice.InvoiceTypeId === invoiceType && validations.isSalesTaxAppliedOnVmsImport
        ? [Validators.required]
        : null
    );

    formGroup.get('BillingInvoiceTemplateId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTemplateId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('BillingReferenceContactProfileId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingReferenceContactProfileId', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: invoice.Id,
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsDraft: invoice.IsDraft,
      BillingInfoId: invoice.BillingInfoId,
      BillingInvoicePresentationStyleId: invoice.BillingInvoicePresentationStyleId,
      BillingConsolidationTypeId: invoice.BillingConsolidationTypeId,
      BillingTransactionGenerationMethodId: invoice.BillingTransactionGenerationMethodId,
      IsUsesAlternateBilling: invoice.IsUsesAlternateBilling,
      OrganizatonClientRoleAlternateBillingId: invoice.OrganizatonClientRoleAlternateBillingId,
      BillingFrequencyId: invoice.BillingFrequencyId,
      BillingInvoiceTermsId: invoice.BillingInvoiceTermsId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      BillingInvoiceTemplateId: invoice.BillingInvoiceTemplateId,
      BillingReferenceContactProfileId: invoice.BillingReferenceContactProfileId,
      SourceId: invoice.SourceId,
      InvoiceNote1: invoice.InvoiceNote1,
      InvoiceNote2: invoice.InvoiceNote2,
      InvoiceNote3: invoice.InvoiceNote3,
      InvoiceNote4: invoice.InvoiceNote4,
      ShowBillingInfoNote2: invoice.ShowBillingInfoNote2 ? invoice.ShowBillingInfoNote2 : !!invoice.InvoiceNote2,
      ShowBillingInfoNote3: invoice.ShowBillingInfoNote3 ? invoice.ShowBillingInfoNote3 : !!invoice.InvoiceNote3,
      ShowBillingInfoNote4: invoice.ShowBillingInfoNote4 ? invoice.ShowBillingInfoNote4 : !!invoice.InvoiceNote4
    }, { emitEvent: false });

    const billingRecipientsFormArray = formGroup.get('BillingRecipients') as FormArray<any>;
    this.updateBillingRecipientFormArray(billingRecipientsFormArray, invoice.BillingRecipients, invoice, validations, invoiceType);

  }

  private updateBillingRecipientFormArray(
    formArray: FormArray<any>,
    recipients: Array<IBillingRecipient>,
    invoice: IBillingInvoice,
    validations: any,
    invoiceType: number
  ) {

    if (formArray.length && recipients.length) {
      recipients.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<any>;
        if (formGroup) {
          this.updateBillingRecipientFormGroup(formGroup, item, invoice, validations, invoiceType);
        } else {
          formArray.push(this.createBillingRecipientFormGroup(item, invoice, validations, invoiceType));
        }
      });
      if (formArray.length > recipients.length) {
        this.clearArray(formArray, recipients.length);
      }
    } else if (recipients.length) {
      const array = this.createBillingRecipientFormArray(invoice, validations, invoiceType);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }

  }

  private updateBillingRecipientFormGroup(
    formGroup: FormGroup<any>,
    recipient: IBillingRecipient,
    invoice: IBillingInvoice,
    validations: any,
    invoiceType: number
  ) {

    formGroup.get('DeliveryMethodId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliveryMethodId', [
            Validators.required
          ])
        : null
    );

    formGroup.get('FileExportFormatId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) &&
        invoice.InvoiceTypeId === invoiceType &&
        recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.FileExportFormat
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'FileExportFormatId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('RecipientTypeId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'RecipientTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('UserProfileId').setValidators(
      !(validations.isNoExpenseValid && invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) && invoice.InvoiceTypeId === invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'UserProfileId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('DeliverToUserProfileId').setValidators(
      recipient.DeliveryMethodId && (recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.InternalProfile
        || recipient.DeliveryMethodId === PhxConstants.DeliveryMethod.ClientProfile)
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients', 'DeliverToUserProfileId', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      BillingInvoiceId: recipient.BillingInvoiceId,
      DeliveryMethodId: recipient.DeliveryMethodId,
      FileExportFormatId: recipient.FileExportFormatId,
      Id: recipient.Id,
      IsDraft: recipient.IsDraft,
      RecipientTypeId: recipient.RecipientTypeId,
      SourceId: recipient.SourceId,
      UserProfileId: recipient.UserProfileId,
      DeliverToUserProfileId: recipient.DeliverToUserProfileId
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<any>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
