<div class="smart-table-wrapper">
  <div style="position: relative;">
    <table class="table table-striped table-content table-hover fixed-columns ng-isolate-scope table-layout-fixed">
      <thead>
        <tr>
          <th (click)="sortBy(html.paymentReleaseScheduleColumnsKey.Id)" style="width: 20%;"
            [ngClass]="(html.tableState.sort.predicate === html.paymentReleaseScheduleColumnsKey.Id && sortNumber === 0)
            ? 'st-sort-ascent': (html.tableState.sort.predicate === html.paymentReleaseScheduleColumnsKey.Id && sortNumber === 1
            ? 'st-sort-descent': '')">ID</th>
          <th (click)="sortBy(html.paymentReleaseScheduleColumnsKey.TimesheetEndDate)" style="width: 40%;"
            [ngClass]="(html.tableState.sort.predicate === html.paymentReleaseScheduleColumnsKey.TimesheetEndDate)
            ? (sortNumber === 0 ? 'st-sort-ascent':(sortNumber === 1 ? 'st-sort-descent': '')): ''">Timesheet End Date</th>
          <th (click)="sortBy(html.paymentReleaseScheduleColumnsKey.PlannedPaymentDate)" style="width: 40%;"
            [ngClass]="(html.tableState.sort.predicate === html.paymentReleaseScheduleColumnsKey.PlannedPaymentDate)
            ? (sortNumber === 0 ? 'st-sort-ascent':(sortNumber === 1 ? 'st-sort-descent': '')): ''">Planned Payment Date</th>
        </tr>
      </thead>
      <tbody style="max-height: 400px; width:100%;" infiniteScroll [infiniteScrollDistance]="html.scrollConfig.infiniteScrollDistance" [scrollWindow]="html.scrollConfig.scrollWindow"
        [infiniteScrollThrottle]="html.scrollConfig.infiniteScrollThrottle" (scrolled)="onScroll()" *ngIf="html.commonLists.paymentReleaseScheduleList.Items?.length > 0">
        <tr *ngFor="let item of html.commonLists.paymentReleaseScheduleList.Items" class="ng-scope">
          <td style="width: 20%; padding-left:15px;" class="ng-binding">{{item.Id}}</td>
          <td style="width: 40%; padding-left:15px;" class="ng-binding">{{item.TimesheetEndDate | date:phxConstants.DateFormat.mediumDate}}</td>
          <td style="width: 40%; padding-left:15px;" class="ng-binding">{{item.PlannedPaymentDate | date:phxConstants.DateFormat.mediumDate}}</td>         
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" style="padding: 20px 0;">
            <span class="ng-binding">Total Count: {{html.commonLists.paymentReleaseScheduleList.Count}}; Items Loaded: {{ html.commonLists.paymentReleaseScheduleList.Items
              ? html.commonLists.paymentReleaseScheduleList.Items.length: 0}}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>