import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, Validator } from '@angular/forms';

import { DxCalendarComponent } from 'devextreme-angular';
import moment from 'moment';

@Component({
  selector: 'app-phx-calendar',
  templateUrl: './phx-calendar.component.html',
  styleUrls: ['./phx-calendar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxCalendarComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  cellTemplate = 'custom';
  @Input() min: any; // Date|Number|String
  @Input() max: any; // Date|Number|String
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() showTodayButton = false;
  @Input() rtlEnabled = false;
  @Input() width: any = '80%'; // "55px", "80%", "auto", "inherit"
  @Input() maxZoomLevel: string;
  @Input() minZoomLevel: string;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('PhxCalendarComponent', { static: true })
  dxCalendar: DxCalendarComponent;
  private isDestroyed = false;

  constructor(@Self() @Optional() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @ViewChild(PhxCalendarComponent)
  set content(content: DxCalendarComponent) {
    this.dxCalendar = content;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename, @typescript-eslint/naming-convention
  @Input('value') _value: any; // Date|Number|String

  get value() {
    // eslint-disable-next-line no-underscore-dangle
    return this._value ? new Date(this._value) : null;
  }

  // Set in onValueChangedHandler because isValid doesn't update at the same time as value, only on emitValueChanged
  set value(val) {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngAfterViewInit(): void {
    const isValid = !this.dxCalendar || this.dxCalendar.isValid;
    this.isValidChange.emit(isValid);
  }

  writeValue(value: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (this._value !== value) {
      // eslint-disable-next-line no-underscore-dangle
      this._value = value;
      if (!this.isDestroyed) {
        this.cdr.detectChanges();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = _ => {
  };

  onTouched = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onValueChangedHandler(event: any) {
    this.value = event.value;
    if (event.event) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: UntypedFormControl) {
    return !this.dxCalendar || this.dxCalendar.isValid ? null : {
      phxDateBox: {
        valid: false
      }
    };
  }

  isValidChangeHandler(event: any) {
    this.isValidChange.emit(event);
    if (this.ngControl != null) {
      this.ngControl.control.updateValueAndValidity({ emitEvent: false });
    }
  }

  getCellCssClass(date) {
    // eslint-disable-next-line no-underscore-dangle
    const selectedDate = moment(this._value).format('YYYY-MM-DD');
    const currentDate = moment(date).format('YYYY-MM-DD');
    return moment(selectedDate).isSame(moment(currentDate)) ? 'active' : '';
  }
}
