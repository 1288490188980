import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, first, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { cloneDeep } from 'lodash';

// TODO: this import couples the component to the client-specific-fields module, we should move this to a shared service or find a better way to handle this
import { ClientSpecificFieldsFormService } from '@client-specific-fields/client-specific-fields-form.service';
// TODO: this import couples the component to the client-specific-fields module, we should move this to a shared service or find a better way to handle this
import { ClientSpecificFieldsService } from '@client-specific-fields/client-specific-fields.service';
// TODO: this import couples the component to the client-specific-fields module, we should move this to a shared service or find a better way to handle this
import { CustomField } from '@client-specific-fields/model/custom-field';
import { CodeValueService, CommonService, DialogService, NavigationService, PhxConstants, SignalrService } from '@common';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import { PhxNavigationBarComponent } from '@common/components/phx-navigation-bar/phx-navigation-bar.component';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { ICommonListsItem } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import {
  CodeValueGroups,
  EntityVersion,
  NavigationBarItem,
  OnClickStateActionOption,
  PhxButton,
  StateAction,
  StateActionButtonsOption,
  StateActionButtonStyle,
  StateActionDisplayType
} from '@common/model';
import { IPanelItem } from '@common/model/panel-bar';
import { FormGroup } from '@common/ngx-strongly-typed-forms';
import { AuthService } from '@common/services/auth.service';
import { ComplianceDocumentUpdateNotificationService } from '@common/services/compliance-document-update-notification/compliance-document-update-notification.service';
import { DocumentCollectorService } from '@common/services/document-collector/document-collector.service';
import { GoogleAnalyticsService } from '@common/services/google-analytics/google-analytics.service';
import { ClickAnalyticsData } from '@common/services/google-analytics/models/click-analytics-data.model';
import { NoteService } from '@common/services/note.service';
import { PhxLayoutBannerService } from '@common/services/phx-layout-banner.service';
import { PhxSideBarService } from '@common/services/phx-side-bar.service';
import { UserBehaviorService } from '@common/services/user-behavior.service';
import { VersionComparisonToggleService } from '@common/services/version-comparison-toggle.service';
import { VersionComparisonService } from '@common/services/version-comparison.service';
import { ComplianceDocumentService } from '@compliance/shared/compliance-document.service';
import { ConfigurationService } from '@configuration/service/configuration.service';
// TODO: this import couples the component to the organization module, we should move this to a shared service or find a better way to handle this
import { IOrganization } from '@organization/models';

import { ControlFieldAccessibility } from '../../control-field-accessibility';
import { IJobOwner, IReadOnlyStorage, IRoot, IWorkOrder, IWorkOrderHeaderDto, IWorkOrderResult, IWorkOrderVersionHeaderDto } from '../../models';
import { ApplicationTransactionSystemDto } from '../../models/dto/application-transaction-system.dto';
import {
  ATS_INTEGRATION_ENABLED,
  AttributionsFormService,
  WorkOrderDataService,
  WorkOrderFormService,
  WorkorderOffboardingService,
  WorkorderService,
  WorkplaceSafetyInsuranceFormService
} from '../../services';
import { IsTenantATSIntegrationEnabledProvider } from '../../services/is-tenant-ats-integration-enabled.provider';
import { WorkOrderVersionComparisonService, WorkOrderVersionGetParams } from '../../services/work-order-version-comparison.service';
import { ReleaseSickPayModalComponent } from '../release-sick-pay-modal/release-sick-pay-modal.component';
import { TerminationPlanningModalComponent } from '../termination-planning-modal/termination-planning-modal.component';
import { WorkorderOffboardingComponent } from '../workorder-offboarding/workorder-offboarding.component';
import { WorkOrdernWorkflowComponent } from '../workorder-workflow/workorder-workflow.component';

import ReportType = PhxConstants.ReportType;

interface WorkOrderEntityVersionMetaData {
  assignmentId: number;
  workorderId: number;
  workOrderVersionId: number;
}

@Component({
  selector: 'app-workorder',
  templateUrl: './workorder.component.html',
  styleUrls: ['./workorder.component.less'],
  providers: [{ provide: VersionComparisonService, useClass: WorkOrderVersionComparisonService }, IsTenantATSIntegrationEnabledProvider]
})
export class WorkorderComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {
  @Input() showTemplate = false;
  @ViewChild('workFlow')
  workOrdernWorkflowComponent: WorkOrdernWorkflowComponent;
  @ViewChild('modalAtsReSync') modalAtsReSync: PhxModalComponent;
  @ViewChild(ReleaseSickPayModalComponent) releaseSickPayModal: ReleaseSickPayModalComponent;
  @ViewChild(PhxNavigationBarComponent) navigationBar: PhxNavigationBarComponent;
  @ViewChild(TerminationPlanningModalComponent) terminationPlanningModal: TerminationPlanningModalComponent;

  isTenantATSIntegrationEnabled: boolean;
  Id: number;
  selectedCounter: number;
  workOrder: IWorkOrder;
  readOnlyStorage: IReadOnlyStorage = null;
  rootFormGroup: FormGroup<IRoot>;
  routerParams: any;
  clientSpecificFieldsData: CustomField[];
  syncFromATS: boolean;
  effectiveToDate: string;
  showClientSpecificFields$ = new BehaviorSubject(false);
  stateActions: StateAction[];
  stateActionsOffboarding: StateAction[];
  atsReSyncDialogTitle = 'Verify ATS Re-Sync';
  atsReSyncMessageHeader = 'Placement Details:';
  atsReSyncMessageBefore = 'Work Order Before Re-Sync:';
  atsReSyncMessageAfter = 'Work Order After Re-Sync:';
  atsJobOwner: IJobOwner;
  atsAddedBy: IJobOwner;
  selectedFboJobOwner: IJobOwner;
  selectedFboRecruiters: IJobOwner[];
  reSyncedFboRecruiters: IJobOwner[];

  selectedTimesheetReferenceContactProfileName: string;
  reSyncedTimesheetReferenceContactProfileName: string;
  selectedExpenseReferenceContactProfileName: string;
  reSyncedExpenseReferenceContactProfileName: string;

  timesheetRefenceContactHasDifference = false;
  expenseReferenceContactHasDifference = false;

  showRecruiters = false;
  stateActionDisplayType = StateActionDisplayType;

  selectedAttributionSaleUserProfileIds: number[];
  selectedAttributionRecruiterUserProfileIds: number[];

  /** NOTE: controlled by a feature flag configuration - check constructor*/
  showAttributionsTab = false;
  hideCommissions = false;
  includeTerminationPlanningFormAction = false;

  html: {
    navigationBarContent: Array<NavigationBarItem>;
    codeValueGroups: typeof CodeValueGroups;
    phxConstants: typeof PhxConstants;
    validationMessages: Array<any>;
    versionsOrdered: Array<IWorkOrderVersionHeaderDto>;
    commonLists: {
      listUserProfileInternal: Array<{
        userProfileId: number;
        displayName: string;
      }>;
    };
    list: {
      workOrderVersionStatuses: Array<any>;
      workOrders: Array<{
        workOrder: IWorkOrderHeaderDto;
        counter: number;
        text: string;
      }>;
    };
  } = {
      navigationBarContent: null,
      codeValueGroups: null,
      phxConstants: PhxConstants,
      validationMessages: [],
      versionsOrdered: null,
      commonLists: {
        listUserProfileInternal: []
      },
      list: {
        workOrderVersionStatuses: [],
        workOrders: []
      }
    };

  routerState: { Id: number, routerPath: string, url: string; };

  unregisterList: any[] = [];
  isImpactApprovalRequired = false;

  currentAction: StateAction;
  currentComponentOption: any;
  currentActionOption: any;
  totalNotesCount: number = null;

  haveClientSpecificFieldError = false;

  atsReSyncButtons: PhxButton[] = [
    {
      icon: null,
      btnType: 'primary',
      tooltip: 'Continue with Re-Sync',
      action: () => {
        this.onClickStateAction(this.currentAction, this.currentComponentOption, this.currentActionOption);
        this.closeModalAtsReSync();
      },
      disabled: () => !this.syncFromATS
    },
    {
      icon: null,
      btnType: 'default',
      tooltip: 'Cancel',
      action: () => this.closeModalAtsReSync()
    }
  ];

  behaviorPrimaryId: number;
  showedEarningsDeductionMessage = false;

  private reInit = false;

  compareVersionsFeatureEnabled = false;
  useComplianceDataForDocumentsFeatureEnabled = false;
  comparingToLastVersion = false;
  canViewVersionComparison = false;
  woIsPendingReview = false;
  compareRemovedItemsMessage$ = this.workOrderVersionComparisonService.getMessaging$();

  readonly showEnterAtsIdModal$ = new Subject<void>();
  readonly setATSId$ = new Subject<number>();

  /** NOTE: compliancy status of current profile */
  workorderCompliancyStatus: string = PhxConstants.StatusType.None;
  /** NOTE: custom name for header */
  customEntityName: string;
  sidebarIsInitialize = false;
  panelList: IPanelItem[] = [{
    name: PhxConstants.PanelBarName.CHECKLIST,
    type: PhxConstants.PanelBarType.CHECKLIST
  },
  {
    name: PhxConstants.PanelBarName.VERSIONS,
    type: PhxConstants.PanelBarType.VERSIONS
  },
  {
    name: PhxConstants.PanelBarName.NOTES,
    type: PhxConstants.PanelBarType.NOTES
  }];
  /** NOTE: termination date or wo end date */
  actualEndDate: string = null;

  atsReSyncWarningMessage?: { title: string, body: string; };
  hasJobOwnerDifferencesToShow = false;
  hasReferenceContactDifferencesToShow = false;

  private workOrderOrganization: IOrganization;

  constructor(
    private navigationService: NavigationService,
    private codeValueService: CodeValueService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workOrderService: WorkorderService,
    private clientSpecificFieldsService: ClientSpecificFieldsService,
    private clientSpecificFieldsFormService: ClientSpecificFieldsFormService,
    private signlrService: SignalrService,
    private commonListsObservableService: CommonListsObservableService,
    private noteService: NoteService,
    private workOrderDataService: WorkOrderDataService,
    private workOrderFormService: WorkOrderFormService,
    private phxSideBarService: PhxSideBarService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
    private configurationService: ConfigurationService,
    private attributionFormService: AttributionsFormService,
    private userBehaviorService: UserBehaviorService,
    private dialogService: DialogService,
    private authService: AuthService,
    private workOrderVersionComparisonService: VersionComparisonService<WorkOrderVersionGetParams, IWorkOrder>,
    private versionComparisonToggleService: VersionComparisonToggleService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private offboardingService: WorkorderOffboardingService,
    private layoutBannerService: PhxLayoutBannerService,
    @Inject(ATS_INTEGRATION_ENABLED) public isTenantATSIntegrationEnabled$: Observable<boolean>,
    private documentCollectorService: DocumentCollectorService,
    private complianceDocumentService: ComplianceDocumentService,
    private complianceDocumentUpdateNotificationService: ComplianceDocumentUpdateNotificationService
  ) {
    super();
    this.html.phxConstants = PhxConstants;
    this.html.codeValueGroups = CodeValueGroups;

    this.configurationService.isFeatureActive$([
      PhxConstants.FeatureFlags.HideCommissions,
      PhxConstants.FeatureFlags.AttributionsTab,
      PhxConstants.FeatureFlags.IncludeTerminationPlanningFormAction,
      PhxConstants.FeatureFlags.UseComplianceDataForDocuments,
      PhxConstants.FeatureFlags.CompareEntityVersions]
    ).pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(featureFlagState => {
      this.showAttributionsTab = featureFlagState[PhxConstants.FeatureFlags.AttributionsTab];
      this.hideCommissions = featureFlagState[PhxConstants.FeatureFlags.HideCommissions];
      this.includeTerminationPlanningFormAction = featureFlagState[PhxConstants.FeatureFlags.IncludeTerminationPlanningFormAction];
      this.compareVersionsFeatureEnabled = featureFlagState[PhxConstants.FeatureFlags.CompareEntityVersions];
      this.useComplianceDataForDocumentsFeatureEnabled = featureFlagState[PhxConstants.FeatureFlags.UseComplianceDataForDocuments];
    });
  }

  ngOnInit(): void {
    this.navigationService.disableBreadcrumbs();
    this.initRouteParams();
    this.setupPrivateEvents();
    this.initStateActions();
    this.getIsTenantATSIntegrationEnabled();
    this.complianceDocumentUpdateNotificationService.initComplianceDocumentDataRefreshListener(this.isDestroyed$, (documentComplianceData) => ({
      WorkOrderId: this.workOrder.WorkOrderId,
      OrganizationIdInternal: this.workOrder.OrganizationIdInternal,
      OrganizationIdClient: this.workOrder.WorkOrderVersion?.BillingInfoes[0]?.OrganizationIdClient,
      OrganizationIdSupplier: this.workOrder.WorkOrderVersion?.PaymentInfoes[0]?.OrganizationIdSupplier,
      UserProfileIdWorker: this.workOrder.UserProfileIdWorker,
      FilePublicId: documentComplianceData.filePublicId
    }))

    this.workOrderService.navigationBarChange$().pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((refresh: boolean) => {
        if (refresh) {
          this.html.navigationBarContent = this.navigationBarContentSetup();
        }
      });

    this.workOrderFormService.workOrder$.pipe(takeUntil(this.isDestroyed$)).subscribe(workOrder => {
      if (workOrder && this.rootFormGroup) {
        this.workOrder = workOrder;
        this.updateWorkOrder();
        this.onInitWorkorder(workOrder);
        this.isImpactApprovalRequired = this.checkWorkOrderVersionCommissionChange(workOrder);
      }
    });

    this.noteService.notes$.pipe(takeUntil(this.isDestroyed$))
      .subscribe(noteStore => {
        noteStore = noteStore || [];
        const entityId = this.workOrder ? this.workOrder.WorkOrderId : null;
        const note = noteStore.find(n => n.entityTypeId === PhxConstants.EntityType.WorkOrder && n.entityId === entityId);
        this.totalNotesCount = note ? note.noteCount : 0;
        const notesTab = this.html.navigationBarContent?.find(x => x.Name === PhxConstants.ContactNavigationName.notes);
        if (notesTab) {
          notesTab.BadgeCount = this.totalNotesCount;
          if (this.navigationBar) {
            this.navigationBar.repaint();
          }
        }
      });

    this.workOrderService.refreshWorkOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe(params => this.refreshWorkOrder(params));

    this.initInternalProfilesLists();
    this.initToggleVersionComparisonListener();
    this.initObserveCompliancyStatus();
  }

  initSidebar(workOrder: IWorkOrder) {
    this.complianceDocumentService.getExcludedClientListForDocumentComplianceData().pipe(
      take(1)
    ).subscribe(exclusionList => {
      /** NOTE: FYI - currently this is used to allow org id client to be used in the checklist in a temporary exclude gorilla vision condition -
       * because of that it needs to be set before the entity type is set - once that condition is removed - and everyone loves gorilla vision -
       * then when the meta data is set can change
       */
      this.phxSideBarService.updateEntityMetaData({
        organizationClientId: workOrder.WorkOrderVersion.BillingInfoes?.[0].OrganizationIdClient,
        documentComplianceDataExclusionList: exclusionList,
        documentComplianceDataFeatureIsActive: this.useComplianceDataForDocumentsFeatureEnabled
      });
      if (!this.sidebarIsInitialize) {
        this.finishSidebarSetup();
      } else {
        const defaultPanel = this.getDefaultPanel();
        this.phxSideBarService.setActivePanel(defaultPanel);
      }
    });
  }

  private getDefaultPanel() {
    /** NOTE: load the previous panel if there was one - if not default to checklist if no offboarding */
    return this.phxSideBarService.activePanel &&
      /** NOTE: user may have come from page with sidebar - double check we are in the context of WO before
       * using the current active panel */
      this.phxSideBarService.currentEntityType === PhxConstants.SideBarEntityType.WorkOrder
      ? this.phxSideBarService.activePanel
      : this.panelList.some(s => s.type === PhxConstants.PanelBarType.WO_OFFBOARDING)
        ? PhxConstants.PanelBarType.WO_OFFBOARDING
        : PhxConstants.PanelBarType.CHECKLIST;
  }

  private finishSidebarSetup() {
    const openOnLoadPanel = this.getDefaultPanel();
    this.phxSideBarService.setOpenOnLoadPanelType(openOnLoadPanel);
    /** NOTE: we need the work order to initialize the side bar - ensure we only initialize it once */
    if (!this.sidebarIsInitialize) {
      if (this.showTemplate) {
        this.phxSideBarService.setOpenOnLoadPanelType(PhxConstants.PanelBarType.CHECKLIST);
        this.phxSideBarService.updateEntityType(PhxConstants.SideBarEntityType.WorkOrder);
        this.panelList = [{
          name: PhxConstants.PanelBarName.CHECKLIST,
          type: PhxConstants.PanelBarType.CHECKLIST
        }];
      } else {
        this.phxSideBarService.updateEntityType(PhxConstants.SideBarEntityType.WorkOrder);
        this.phxSideBarService.updateEntityTypeId(PhxConstants.EntityType.WorkOrder);
        this.phxSideBarService.updateEntityId(this.routerParams.workorderId);
        this.phxSideBarService.updateEntityStatus(PhxConstants.StatusType.None);

        this.phxSideBarService.updateVersionsAreMultiple(true);
        this.phxSideBarService.updateStatusGroup$(this.html.codeValueGroups.WorkOrderVersionStatus);

        this.phxSideBarService.selectedVersion.pipe(
          filter(selectedVersion => {
            return selectedVersion && selectedVersion.VersionId !== this.routerParams.versionId;
          }),
          takeUntil(this.isDestroyed$)
        ).subscribe((selectedVersion: EntityVersion<WorkOrderEntityVersionMetaData>) => {
          this.loadVersion(selectedVersion);
        });
      }

      this.phxSideBarService.updatePanelList(this.panelList);
      /** NOTE: enable sidebar feature */
      this.phxSideBarService.enableSideBar();
      this.sidebarIsInitialize = true;
    }
  }

  private updateOffboardingSidePanel(showPanel: boolean) {
    const listHasOffboardingPanel = this.panelList.some(f => f.type === PhxConstants.PanelBarType.WO_OFFBOARDING);

    if (showPanel) {
      const customInputs = {
        userProfileIdWorker: this.workOrder.UserProfileIdWorker,
        workOrderId: this.workOrder.WorkOrderId,
        workOrderStatusId: this.workOrder.StatusId,
        workOrderVersionWorksite: this.workOrder.WorkOrderVersion.WorksiteId,
        actualEndDate: this.workOrder.TerminationDate || this.workOrder.EndDate,
        terminationNoticeDate: this.workOrder.TerminationNoticeDate,
        isExtended: this.workOrder.IsExtended,
        workOrder: this.workOrder
      };

      if (listHasOffboardingPanel) {
        this.panelList.find(f => f.type === PhxConstants.PanelBarType.WO_OFFBOARDING).customInputs = customInputs;
      } else {
        const offboardingPanel = {
          name: PhxConstants.PanelBarName.OFFBOARDING,
          type: PhxConstants.PanelBarType.WO_OFFBOARDING,
          isCustom: true,
          customComponentType: WorkorderOffboardingComponent,
          customInputs
        };

        this.panelList.push(offboardingPanel);
        this.phxSideBarService.setOpenOnLoadPanelType(PhxConstants.PanelBarType.WO_OFFBOARDING);
      }
    } else {
      this.panelList = this.panelList.filter(f => f.type !== PhxConstants.PanelBarType.WO_OFFBOARDING);
    }

    this.phxSideBarService.updatePanelList(this.panelList);
  }

  loadVersion(version: EntityVersion<WorkOrderEntityVersionMetaData>) {
    if (
      version.metaData.workOrderVersionId !== this.workOrder.WorkOrderVersion.Id
    ) {
      this.routerParams.versionId = Number(version.metaData.workOrderVersionId);
      this.routerParams.workorderId = Number(version.metaData.workorderId);
      this.navigationTo();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.unregisterList?.length) {
      for (const sub of this.unregisterList) {
        sub?.unsubscribe?.();
      }
    }
    this.commonListsObservableService.clearList();
    this.noteService.clearNoteCache();
    this.workOrderDataService.clear();
    this.destroySidebarCleanup();

    this.trackBehavior(PhxConstants.UserBehaviorCode.ClearEntity);
    this.versionComparisonToggleService.setIsShowingVersionChanges(false);
  }

  private trackBehavior(userBehavior: string) {
    if (!this.routerParams.templateId && this.routerParams.versionId !== 0) {
      this.userBehaviorService.trackBehavior(userBehavior, PhxConstants.EntityType.WorkOrder, this.behaviorPrimaryId);
    }
  }

  private destroySidebarCleanup() {
    this.phxSideBarService.disableSideBar();
    this.versionComparisonToggleService.setShowCompareAllVersionsToggle(false);
    this.workOrderVersionComparisonService.resetComparisonData();
  }

  updateWorkOrder() {
    this.workOrder = {
      ...this.workOrder,
      ...this.workOrderFormService.formGroupToPartial(this.workOrder)
    };

    this.workOrderDataService.updateWorkOrder(this.workOrder);

    if (!this.comparingToLastVersion && this.workOrder.WorkOrderVersion.StatusId !== PhxConstants.WorkOrderVersionStatus.PendingReview && !this.readOnlyStorage.IsEditable) {
      this.workOrderVersionComparisonService.resetComparisonData();
    }
  }

  openAdditionalDocuments() {
    // eslint-disable-next-line max-len
    const navigatePath = `/next/workorder/${this.routerParams.assignmentId}/${this.routerParams.workorderId}/${this.routerParams.versionId}/${this.html.phxConstants.WorkorderNavigationName.documents}`;
    this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent });
  }

  onVersionClick(version: IWorkOrderVersionHeaderDto) {
    if (version.Id !== this.workOrder.WorkOrderVersion.Id) {
      this.updateWorkOrder();
      this.routerParams.versionId = Number(version.Id);
      this.navigationTo();
    }
  }

  refreshWorkOrder(params: any) {
    this.workOrderDataService.clear();
    this.workOrderDataService.loadWorkOrder(params, this.showTemplate, this.isDestroyed$).subscribe(async (result: IWorkOrderResult) => {
      this.workOrder = result.WorkOrder;

      ControlFieldAccessibility.workorder = this.workOrder;
      await this.updateWorkorderState(this.workOrder, result.WorkerProfiles);
      this.workOrderFormService.updateForm(this.workOrder);
      this.onInitWorkorder(this.workOrder);

      this.workOrderVersionComparisonService.clearCachedComparisonControlListByVersion(this.workOrder.WorkOrderVersion.SourceId);
      this.setCustomEntityName(this.workOrder);
      this.initVersionComparison();
    });
  }

  setValidationMessages({ versionId, apiError, messages }) {
    if (messages) {
      this.html.validationMessages = messages;
    } else {
      this.html.validationMessages = [];
    }

    if (versionId && this.workOrder.WorkOrderVersion.Id === versionId) {
      this.workOrderDataService.updateWorkOrderValidationError(this.workOrder, apiError);
    }
  }

  private checkCompliancy() {
    this.phxSideBarService.updateEntity(this.workOrder);
  }

  private initObserveCompliancyStatus() {
    this.phxSideBarService.entityStatusChange$().pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(currentStatus =>
      /** NOTE: becaue we are switching from rule engine ComplianceStatus to phx constants StatusType - check value */
      this.workorderCompliancyStatus = PhxConstants.StatusType.Types.includes(currentStatus) ? currentStatus : PhxConstants.StatusType.None
    );
  }

  private setCustomEntityName(workOrder: IWorkOrder) {
    const versionNumber = workOrder.readOnlyStorage.isTemplate ? (workOrder.WorkOrderVersion.WorkOrderNumber ?? 1) : workOrder.WorkOrderVersion.WorkOrderNumber;
    this.customEntityName = `Work Order ${workOrder.AssignmentId}.${versionNumber}.${workOrder.WorkOrderVersion.VersionNumber}`;
  }

  private initRouteParams() {
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          params = { ...params, location: this.router.url };
          this.routerParams = params;

          this.behaviorPrimaryId = parseInt(params.versionId, 10);

          switch (params.tabId) {
            case PhxConstants.WorkorderNavigationName.core:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.core);
              break;
            case PhxConstants.WorkorderNavigationName.attributions:
              if (this.showAttributionsTab) {
                this.setRouterState(params, PhxConstants.WorkorderNavigationName.attributions);
              } else {
                this.setRouterState(params, PhxConstants.WorkorderNavigationName.core);
              }
              break;
            case PhxConstants.WorkorderNavigationName.parties:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.parties);
              break;
            case PhxConstants.WorkorderNavigationName.timematerialinvoice:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.timematerialinvoice);
              break;
            case PhxConstants.WorkorderNavigationName.expensemanagement:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.expensemanagement);
              break;
            case PhxConstants.WorkorderNavigationName.purchaseorder:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.purchaseorder);
              break;
            case PhxConstants.WorkorderNavigationName.earningsanddeductions:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.earningsanddeductions);
              break;
            case PhxConstants.WorkorderNavigationName.taxes:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.taxes);
              break;
            case PhxConstants.WorkorderNavigationName.compliancedocuments:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.compliancedocuments);
              break;
            case PhxConstants.WorkorderNavigationName.clientspecificfields:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.clientspecificfields);
              break;
            case PhxConstants.WorkorderNavigationName.notes:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.notes);
              break;
            case PhxConstants.WorkorderNavigationName.activity:
              this.setRouterState(params, PhxConstants.WorkorderNavigationName.activity);
              break;
            default:
              this.routerState = { Id: null, routerPath: null, url: null };
              break;
          }

          ControlFieldAccessibility.routerState = this.routerState;

          return forkJoin([
            this.routerState.Id ? this.workOrderDataService.loadWorkOrder(params, this.showTemplate, this.isDestroyed$) : of(null),
            this.workOrderService.isAutocalculatingFeatureActiveForWorkorder(+params.assignmentId)
          ]);
        }),
        catchError(error => {
          this.layoutBannerService.openBanner({
            title: 'We are sorry, something went wrong.',
            message: 'The work order record you requested could not be loaded or no longer exists.',
            canClose: false,
            type: PhxConstants.BannerType.Danger,
            isCustom: true,
            error
          });
          this.destroySidebarCleanup();

          throw new Error(error);
        }),
        switchMap(([result, isAutocalculateActive]) => {
          this.workOrderService.autoCalculateOvertimeIsActiveForWorkorder = isAutocalculateActive;
          return forkJoin([
            of(result),
            this.workOrderFormService.getWorkOrderOrganization(result?.WorkOrder?.WorkOrderVersion?.BillingInfoes[0]?.OrganizationIdClient)
          ]);
        }),
        map(([result, workorderOrganization]) => {
          this.workOrderOrganization = workorderOrganization;
          return result;
        }),
        takeUntil(this.isDestroyed$))
      .subscribe(async (result: IWorkOrderResult) => {
        if (result?.WorkOrder) {
          let workOrder = result.WorkOrder;

          let prevId: number;
          let currId: number;

          if (this.showTemplate) {
            prevId = this.workOrder ? this.workOrder.TemplateId : null;
            currId = workOrder ? workOrder.TemplateId : null;
          } else {
            prevId = this.workOrder?.WorkOrderVersion?.Id ?? null;
            currId = workOrder?.WorkOrderVersion?.Id ?? null;
          }

          if (currId === null) {
            // using reInit flag because if set this.workOrder = null, UI will blank out due to *ngIf
            this.reInit = true;
          } else {

            if (prevId !== currId || this.reInit) {
              workOrder = await this.updateWorkorderState(workOrder, result.WorkerProfiles);
            }

            ControlFieldAccessibility.workorder = workOrder;
            this.workOrder = workOrder;
            if (!this.rootFormGroup) {
              this.rootFormGroup = this.workOrderFormService.createForm(workOrder, this.isDestroyed$);
              this.onInitWorkorder(workOrder);
              /** NOTE: listen for form group changes then check compliancy */
              this.rootFormGroup.valueChanges
                .pipe(
                  startWith(this.rootFormGroup.value),
                  debounceTime(1000),
                  takeUntil(this.isDestroyed$))
                .subscribe(() => {
                  this.updateWorkOrder();
                  this.checkCompliancy();
                  if (this.rootFormGroup.dirty) {
                    this.trackBehavior(PhxConstants.UserBehaviorCode.WriteEntity);
                  }
                });
              this.initVersionComparison();
              this.setCustomEntityName(this.workOrder);
              /** NOTE: sidebar needs to be updated per potential client id change - this is a temp gorilla vision soft launch requirement - will change back */
              this.initSidebar(this.workOrder);
            } else if (prevId !== currId) {
              this.workOrderFormService.updateForm(this.workOrder);
              this.onInitWorkorder(workOrder);
              this.initVersionComparison();
              this.setCustomEntityName(this.workOrder);
              this.initSidebar(this.workOrder);
            } else {
              this.updateWorkOrder();
            }
          }
        } else {
          this.router.navigate([`/next/report-azsearch/${ReportType.WorkOrderReport}`]);
          this.commonService.logError('Cannot match any routes');
        }
      });
  }

  initToggleVersionComparisonListener() {
    this.versionComparisonToggleService.isShowingVersionChanges$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(isShowing => {
      this.comparingToLastVersion = isShowing;
      if (this.comparingToLastVersion) {
        this.doVersionComparison();
      } else {
        this.workOrderVersionComparisonService.resetComparisonData();
      }
    });
  }

  /** NOTE: determine if current user can use versioning changes feature - if yes and wo is require review - do comparison */
  private initVersionComparison() {
    this.canViewVersionComparison = false;
    const woApprovers = [PhxConstants.FunctionalRole.BackOffice, PhxConstants.FunctionalRole.SystemAdministrator, PhxConstants.FunctionalRole.Finance, PhxConstants.FunctionalRole.Controller];
    this.authService.getCurrentProfile().pipe(
      tap(() => this.versionComparisonToggleService.setShowCompareAllVersionsToggle(false)),
      filter(currentProfile => !this.showTemplate && currentProfile.FunctionalRoles.some(({ FunctionalRoleId }) => woApprovers.some(s => s === FunctionalRoleId))),
      tap(() => this.canViewVersionComparison = true),
      tap(() => {
        /** NOTE: we will compare to previous version if version is not 1 and form is not editable*/
        const woHeader = this.html.versionsOrdered.find(f => f.Id === +this.routerParams.versionId);
        if (!woHeader || woHeader.VersionNumber === 1 || this.readOnlyStorage.IsEditable) {
          this.canViewVersionComparison = false;
        }

        /** NOTE: we hide the 'show version changes' on version 1 and the pending WO version */
        this.woIsPendingReview = false;
        if (this.workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingReview) {
          this.woIsPendingReview = true;
        }

        this.versionComparisonToggleService.setShowCompareAllVersionsToggle(
          this.compareVersionsFeatureEnabled &&
          this.canViewVersionComparison &&
          !this.woIsPendingReview);
      }),
    ).subscribe(() => this.doVersionComparison());

  }

  /** NOTE: compare current work order version to it's source version (sourceId)  */
  private doVersionComparison(): void {
    this.workOrderVersionComparisonService.resetComparisonData();
    if (this.compareVersionsFeatureEnabled && this.canViewVersionComparison) {

      /** NOTE: we will compare to previous version if user has selected 'show version changes' or wo is pending review */
      if (this.comparingToLastVersion || this.woIsPendingReview) {
        if (this.woIsPendingReview) {
          this.logVersionComparisonGAEvent('Get pending review WO comparison');
        } else {
          this.logVersionComparisonGAEvent('Get previous WO version comparison');
        }

        const getPreviousVersionApiParams = {
          ...this.routerParams,
          versionId: this.workOrder.WorkOrderVersion.SourceId
        };

        this.workOrderVersionComparisonService.compareEntities(this.rootFormGroup, this.workOrder, getPreviousVersionApiParams, this.isDestroyed$);
      }
    }
  }

  private logVersionComparisonGAEvent(action: string): void {
    this.logGoogleAnalyticsEvent({
      feature: 'Version Comparison',
      type: 'Workorder',
      action
    });
  }

  private logGoogleAnalyticsEvent<T extends ClickAnalyticsData>(event: T): void {
    this.googleAnalyticsService.sendClickData(event);
  }

  private setRouterState(params: Params, WorkorderNavigationName: string) {
    this.routerState = {
      Id: this.showTemplate ? params.templateId : params.workorderId,
      routerPath: WorkorderNavigationName,
      url: params.location
    };
  }

  private getLastPartOfUrl() {
    const url = window.location.href;
    return url.split('/').reverse()[0];
  }

  private onInitWorkorder(workOrder: IWorkOrder) {
    if (!this.showTemplate) {
      this.html.list.workOrderVersionStatuses = this.codeValueService.getCodeValues(this.html.codeValueGroups.WorkOrderVersionStatus, true);
      this.html.list.workOrders = workOrder.WorkOrderHeaders.map((workOrderHeaderDto: IWorkOrderHeaderDto) => {
        return {
          workOrder: workOrderHeaderDto,
          counter: +workOrderHeaderDto.WorkOrderNumber,
          text: '#' + workOrderHeaderDto.WorkOrderNumber
        };
      });
      const currentHeader = workOrder.WorkOrderHeaders.find(wo => wo.Id === workOrder.WorkOrderId);
      this.html.versionsOrdered = currentHeader ? currentHeader.WorkOrderVersionHeaders : [];

      const mappedVersions = this.mapWorkorderVersions(workOrder.WorkOrderHeaders);
      this.phxSideBarService.updateEntityVersions$(mappedVersions);
      this.phxSideBarService.updateCurrentGroupAndVersion$(`${workOrder.AssignmentId}.${workOrder.WorkOrderVersion.WorkOrderNumber}`, workOrder.WorkOrderVersion.VersionNumber);

      this.effectiveToDate = workOrder.WorkOrderVersion.EffectiveToDate;
      this.trackBehavior(PhxConstants.UserBehaviorCode.ReadEntity);

      if (this.actualEndDate !== this.workOrder.TerminationDate || this.workOrder.EndDate) {
        this.actualEndDate = this.workOrder.TerminationDate || this.workOrder.EndDate;
        this.updateOffboardingSidePanel(
          this.offboardingService.showOffboarding(this.actualEndDate) &&
          this.workOrder.WorkOrderVersion.StatusId !== PhxConstants.WorkOrderVersionStatus.PendingUnterminate
        );
      }
    }

    this.readOnlyStorage = workOrder.readOnlyStorage;
    this.workOrderService.updateNavigationBar();

    // allow to sync from ATS if the viewing work order is the latest work order and status is in processing
    this.syncFromATS = this.isTenantATSIntegrationEnabled && workOrder.AtsPlacementId > 0
      && workOrder.WorkOrderVersion.IsDraftStatus
      && workOrder.StatusId === PhxConstants.WorkOrderStatus.Processing
      && workOrder.WorkOrderHeaders[workOrder.WorkOrderHeaders.length - 1].Id === workOrder.WorkOrderId;
  }

  /** NOTE: map api version model to EntityVersion model for version controls */
  private mapWorkorderVersions(versions: IWorkOrderHeaderDto[]): EntityVersion<WorkOrderEntityVersionMetaData>[] {
    const tmpArray: EntityVersion<WorkOrderEntityVersionMetaData>[] = [];
    versions.map(workOrder => {
      workOrder.WorkOrderVersionHeaders.map(workOrderVersion => {
        tmpArray.push({
          Id: +(this.routerParams.assignmentId + '' + workOrder.WorkOrderNumber),
          GroupNumber: `${this.routerParams.assignmentId}.${workOrder.WorkOrderNumber}`,
          StatusId: workOrderVersion.StatusId,
          VersionId: workOrderVersion.Id,
          VersionNumber: workOrderVersion.VersionNumber,
          EffectiveDate: workOrderVersion.EffectiveDate,
          metaData: {
            assignmentId: +this.routerParams.assignmentId,
            workorderId: workOrder.Id,
            workOrderVersionId: workOrderVersion.Id
          }
        });
      });
    });

    return tmpArray;
  }

  private navigationBarContentSetup(): Array<NavigationBarItem> {
    let path: string;
    if (this.showTemplate) {
      path = `/next/template/${this.routerParams.templateId}/`;
    } else {
      path = `/next/workorder/${this.routerParams.assignmentId}/${this.routerParams.workorderId}/${this.routerParams.versionId}/`;
    }

    const coreTabForm = this.rootFormGroup.get('TabCore');
    const isValidCoreTab$ = coreTabForm.statusChanges.pipe(startWith(coreTabForm.status),
      map(status => this.showTemplate || (status === 'VALID' && (!this.isImpactApprovalRequired || this.hideCommissions))));

    const attributionsTabForm = this.rootFormGroup.controls.TabAttributions;
    const isValidAttributionTab$ = attributionsTabForm.statusChanges.pipe(startWith(attributionsTabForm.status),
      map(status => this.showTemplate || (status === 'VALID')));

    const partyTabForm = this.rootFormGroup.get('TabParties');
    const isValidPartyTab$ = partyTabForm.statusChanges.pipe(startWith(partyTabForm.status),
      map(status => this.showTemplate || status === 'VALID'));

    const materialTabForm = this.rootFormGroup.get('TabTimeMaterialInvoice');
    const isValidMaterialTab$ = materialTabForm.statusChanges.pipe(startWith(materialTabForm.status),
      map(status => this.showTemplate || status === 'VALID'));

    const expenseTabForm = this.rootFormGroup.get('TabExpenseInvoice');
    const isValidExpenseTab$ = expenseTabForm.statusChanges.pipe(startWith(expenseTabForm.status),
      map(status => this.showTemplate || status === 'VALID'));

    const earningDeductionTabForm = this.rootFormGroup.get('TabEarningsAndDeductions');
    const isValidEarningDeductionTab$ = earningDeductionTabForm.statusChanges.pipe(startWith(earningDeductionTabForm.status),
      map(status => this.showTemplate || status === 'VALID'));


    const taxTabForm = this.rootFormGroup.get('TabTaxes');
    const isValidTaxTab$ = taxTabForm.statusChanges.pipe(startWith(taxTabForm.status),
      map(status => this.showTemplate || status === 'VALID'));

    const clientFieldForm = this.rootFormGroup.get('ClientSpecificFields');
    const isValidClientFieldTab$ = clientFieldForm.statusChanges.pipe(startWith(clientFieldForm.status),
      map(status => this.showTemplate || status === 'VALID'));

    const typedClientFieldForm = this.rootFormGroup.get('TypedClientSpecificFields');
    const isValidTypedClientFieldTab$ = typedClientFieldForm.statusChanges.pipe(startWith(typedClientFieldForm.status),
      map(status => this.showTemplate || status === 'VALID' || status === 'DISABLED'));


    let idCount = 0;
    return [
      {
        Id: idCount += 1,
        IsDefault: true,
        IsHidden: false,
        IsFormValid: isValidCoreTab$,
        Name: PhxConstants.WorkorderNavigationName.core,
        Path: path + PhxConstants.WorkorderNavigationName.core,
        DisplayText: 'Core',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.core)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: this.showTemplate || !this.showAttributionsTab,
        IsFormValid: isValidAttributionTab$,
        Name: PhxConstants.WorkorderNavigationName.attributions,
        Path: path + PhxConstants.WorkorderNavigationName.attributions,
        DisplayText: 'Attributions'
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: false,
        IsFormValid: isValidPartyTab$,
        Name: PhxConstants.WorkorderNavigationName.parties,
        Path: path + PhxConstants.WorkorderNavigationName.parties,
        DisplayText: 'Parties and Rates',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.parties)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: false,
        IsFormValid: isValidMaterialTab$,
        Name: PhxConstants.WorkorderNavigationName.timematerialinvoice,
        Path: path + PhxConstants.WorkorderNavigationName.timematerialinvoice,
        DisplayText: 'Timesheet and Invoice',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.timematerialinvoice)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: false,
        IsFormValid: isValidExpenseTab$,
        Name: PhxConstants.WorkorderNavigationName.expensemanagement,
        Path: path + PhxConstants.WorkorderNavigationName.expensemanagement,
        DisplayText: 'Expense and Invoice',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.expensemanagement)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        // IsHidden: !readOnlyStorage.IsEditable,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.purchaseorder,
        Path: path + PhxConstants.WorkorderNavigationName.purchaseorder,
        DisplayText: 'Purchase Order'
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        // IsHidden: !readOnlyStorage.IsEditable,
        IsFormValid: isValidEarningDeductionTab$,
        Name: PhxConstants.WorkorderNavigationName.earningsanddeductions,
        Path: path + PhxConstants.WorkorderNavigationName.earningsanddeductions,
        DisplayText: 'Earnings and Deductions',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.earningsanddeductions)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        // IsHidden: !readOnlyStorage.IsEditable,
        IsFormValid: isValidTaxTab$,
        Name: PhxConstants.WorkorderNavigationName.taxes,
        Path: path + PhxConstants.WorkorderNavigationName.taxes,
        DisplayText: 'Taxes',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.taxes)
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: this.showTemplate,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.compliancedocuments,
        Path: path + PhxConstants.WorkorderNavigationName.compliancedocuments,
        DisplayText: 'Documents'
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: this.showTemplate || !this.showClientSpecificFields$.getValue(),
        IsFormValid: combineLatest([isValidClientFieldTab$, isValidTypedClientFieldTab$]).pipe(
          map(([clientFieldIsValid, typedClientFieldIsValid]) => clientFieldIsValid && typedClientFieldIsValid)
        ),
        Name: PhxConstants.WorkorderNavigationName.clientspecificfields,
        Path: path + PhxConstants.WorkorderNavigationName.clientspecificfields,
        DisplayText: 'Client Specific Fields',
        HasVersionChanges: this.workOrderVersionComparisonService.tabHasChanges$(PhxConstants.WorkorderNavigationName.clientspecificfields)
      },
      {
        Id: idCount += 1,
        IsDefault: true,
        IsHidden: false,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.notes,
        Path: `${path}${PhxConstants.WorkorderNavigationName.notes}`,
        DisplayText: 'Notes',
        BadgeCount: this.totalNotesCount
      },
      {
        Id: idCount += 1,
        IsDefault: false,
        IsHidden: this.showTemplate,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.activity,
        Path: path + PhxConstants.WorkorderNavigationName.activity,
        DisplayText: 'Activity'
      }
    ];
  }

  private getTabUrl() {
    if (this.routerParams.tabId && this.routerParams.tabId !== 'activity') {
      return this.routerParams.tabId;
    } else if (this.routerState.url.includes('/activity')) {
      switch (this.getLastPartOfUrl()) {
        case 'history':
          return PhxConstants.WorkorderNavigationName.history;
        case 'documents':
          return PhxConstants.WorkorderNavigationName.documents;
        case 'transaction':
          return PhxConstants.WorkorderNavigationName.transaction;
        case 'workflow':
          return PhxConstants.WorkorderNavigationName.workflow;
        default:
          return PhxConstants.WorkorderNavigationName.history;
      }
    }
  }

  private navigationTo() {
    const navigateCommand = ['/next/workorder', this.routerParams.assignmentId, this.routerParams.workorderId, this.routerParams.versionId];
    const tabUrlSegments = this.getTabUrl().split('/');
    if (!tabUrlSegments.length) {
      tabUrlSegments.push(PhxConstants.WorkorderNavigationName.core);
    }
    navigateCommand.push(...tabUrlSegments);


    const navigationExtras = { relativeTo: this.activatedRoute };
    this.router.navigate(navigateCommand, navigationExtras);
  }

  private async onClickStateAction(action: StateAction, componentOption: StateActionButtonsOption, actionOption: OnClickStateActionOption) {
    if (action.commandName === PhxConstants.CommandNamesSupportedByUi.WorkOrderActionCommand.WorkOrderDiscardChanges) {
      return;
    }

    /** NOTE: worker is temp/canadiansp with differnt prov than org tax prov - user has to view E&D tab to set payment deductions - #39846 */
    if (action.actionId === PhxConstants.StateAction.WorkOrderVersionSubmit
      && !this.workOrder.WorkOrderVersion.PaymentInfoes?.[0]?.IsUseUserProfileWorkerSourceDeduction
      && !this.workOrder.WorkOrderVersion.PaymentInfoes?.[0]?.PaymentSourceDeductions?.length
      && !this.showedEarningsDeductionMessage) {
      this.router.navigate(['/next/workorder', this.routerParams.assignmentId, this.routerParams.workorderId,
        this.routerParams.versionId, PhxConstants.WorkorderNavigationName.earningsanddeductions]);

      this.dialogService.notify('Earnings and Deductions', 'Please view the Earnings and Deductions details before submitting.');
      this.showedEarningsDeductionMessage = true;
    } else {
      this.updateWorkOrder();
      this.workOrdernWorkflowComponent.onClickStateAction(action, componentOption, actionOption, this.workOrder, null);
    }
  }

  private workOrderTemplateSave() {

    const templateBody = {
      Id: 0,
      OrganizationCode: this.workOrder.RootObject.OrganizationCode,
      OrganizationIdInternal: this.workOrder.RootObject.OrganizationIdInternal,
      StatusId: this.workOrder.RootObject.StatusId,
      UserProfileIdWorker: this.workOrder.RootObject.UserProfileIdWorker,
      WorkOrders: [
        {
          ...this.workOrder,
          Id: this.workOrder.WorkOrderId,
          WorkOrderVersions: [
            {
              ...this.workOrder.WorkOrderVersion
            }
          ]
        }
      ]
    };

    templateBody.WorkOrders.forEach(wo => {
      wo.ExcludeFromBI = false;
    });

    const command = {
      TemplateId: this.workOrder.TemplateId,
      TemplateBody: templateBody,
      LastModifiedDatetime: this.workOrder.RootObject.LastModifiedDateTime
    };

    this.workOrderService.updateTemplateBody(command)
      .subscribe({
        next: () => {
          this.commonService.logSuccess('Work Order Template Updated');
        },
        error: error => {
          const validationMessages = this.commonService.parseResponseError(error);
          if (validationMessages.length > 0) {
            validationMessages.forEach(element => {
              this.html.validationMessages.push(element.Message);
            });
          }
        }
      });
  }

  private initInternalProfilesLists() {
    this.commonListsObservableService
      .listUserProfileInternalAll$()
      .pipe(
        filter(profiles => !!profiles),
        takeUntil(this.isDestroyed$)
      )
      .subscribe(profiles => {
        this.html.commonLists.listUserProfileInternal = profiles.map(profile => ({
          userProfileId: profile.Id,
          displayName: profile.Data.Contact.FullName
        }));
      });
  }

  private initStateActions() {
    let setATSIdSub: Subscription;
    this.stateActions = this.showTemplate
      ? [
        {
          displayText: 'Save Template',
          skipSecurityCheck: true,
          style: StateActionButtonStyle.PRIMARY,
          onClick: () => {
            this.workOrderTemplateSave();
          }
        }
      ]
      : [
        {
          actionId: PhxConstants.StateAction.AssignmentExtend,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideExtend();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderScheduleChange,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => componentOption.displayType !== StateActionDisplayType.DROPDOWN

        },
        {
          actionId: PhxConstants.StateAction.WorkOrderStopPayment,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },

          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) =>
            !this.workOrder || this.workOrder.IsPaymentStopped || componentOption.displayType !== StateActionDisplayType.DROPDOWN
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderMarkOffboarding,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          style: StateActionButtonStyle.SECONDARY,
          hiddenFn: () => true
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderResumePayment,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },

          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) =>
            !this.workOrder?.IsPaymentStopped || componentOption.displayType !== StateActionDisplayType.DROPDOWN
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderReactivate,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideReactivate();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderCreateTransaction,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideTransaction();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderReleaseVacationPay,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) =>

            !this.workOrder || PhxConstants.UserProfileType.WorkerTemp !== this.workOrder.workerProfileTypeId || componentOption.displayType !== StateActionDisplayType.DROPDOWN

        },
        {
          actionId: PhxConstants.StateAction.WorkOrderReleaseSickPay,
          onClick: () => {
            this.releaseSickPayModal.openModal();
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) =>
            !this.workOrder
            || (PhxConstants.UserProfileType.WorkerTemp !== this.workOrder.workerProfileTypeId
              && PhxConstants.UserProfileType.WorkerUnitedStatesW2 !== this.workOrder.workerProfileTypeId)
            || componentOption.displayType !== StateActionDisplayType.DROPDOWN
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderCreateGovernmentAdjustment,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideAdjustment();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionSubmit,
          style: StateActionButtonStyle.PRIMARY,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          disabledFn: () => !this.rootFormGroup.valid
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionFinalize,
          style: StateActionButtonStyle.PRIMARY,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          disabledFn: () => (!this.rootFormGroup.valid || (this.isImpactApprovalRequired && !this.hideCommissions))
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionApprove,
          style: StateActionButtonStyle.PRIMARY,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionDecline,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionDeclineActivation,
          showDeclinedCommentDialog: true,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionSave,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionRecallToCompliance,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionRecallToDraft,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionEdit,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              /* NOTE: we want the other handler (TermiantedEdit) when WO is terminated - hide this handler if WO terminated */
              return this.isWorkOrderTerminated();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionTerminatedEdit,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            return componentOption.displayType !== StateActionDisplayType.DROPDOWN || !this.isWorkOrderTerminated();
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderTerminate,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideTerminate();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderReviseTermination,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            if (componentOption.displayType !== StateActionDisplayType.DROPDOWN) {
              return true;
            } else {
              return this.shouldHideReviseTermination();
            }
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderUnterminate,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            return componentOption.displayType !== StateActionDisplayType.DROPDOWN || (this.workOrder.IsExtended && this.workOrder.TerminationTypeId === PhxConstants.TerminationType.TemporaryLayoff);
          }

        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionApproveReactivation,
          style: StateActionButtonStyle.PRIMARY,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionDiscard,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => componentOption.displayType !== StateActionDisplayType.DROPDOWN

        },
        {
          displayText: 'Save as a Template',
          commandName: PhxConstants.CommandNamesSupportedByUi.WorkOrderActionCommand.workOrderSaveAsTemplate,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
          },

          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => !this.workOrder || componentOption.displayType !== StateActionDisplayType.DROPDOWN
        },
        {
          displayText: 'Cancel',
          commandName: PhxConstants.CommandNamesSupportedByUi.WorkOrderActionCommand.WorkOrderDiscardChanges,
          onClick: (action, componentOption, actionOption) => {
            this.onClickStateAction(action, componentOption, actionOption);
            this.trackBehavior(PhxConstants.UserBehaviorCode.ReadEntity);
          },
          hiddenFn: () => {
            return !this.workOrder || !(this.workOrder.WorkOrderVersion.IsDraftStatus && this.workOrder.combinedAvailableStateActions.includes(PhxConstants.StateAction.WorkOrderVersionSave));
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionReSyncATS,
          onClick: (action, componentOption, actionOption) => {
            this.currentAction = action;
            this.currentComponentOption = componentOption;
            this.currentActionOption = actionOption;

            const lobId = this.rootFormGroup.get('TabCore').get('Details').get('LineOfBusinessId').value;
            this.showRecruiters = lobId === PhxConstants.LineOfBusiness.Regular || lobId === PhxConstants.LineOfBusiness.SubVendorPlacement || lobId === PhxConstants.LineOfBusiness.DirectSourcing;

            this.selectedFboJobOwner = this.rootFormGroup.get('TabCore').get('Commissions').get('JobOwner').value;
            const foundJobOwner = this.workOrderDataService.userProfileCommissionsList.find(up =>
              up.Id === this.selectedFboJobOwner.UserProfileIdSales);
            this.selectedFboJobOwner.FullName = foundJobOwner ? foundJobOwner.DisplayText : 'None Selected';

            if (this.showAttributionsTab) {
              this.selectedAttributionSaleUserProfileIds = this.attributionFormService.salesAttributionSharesFormArray.value.map(m => m.UserProfileId);
              this.selectedAttributionRecruiterUserProfileIds = this.attributionFormService.recruiterAttributionSharesFormArray.value.map(m => m.UserProfileId);
            }

            // Recruiter info
            if (this.showRecruiters) {
              this.selectedFboRecruiters = this.rootFormGroup.get('TabCore').get('Commissions').get('Recruiters').value;
              this.selectedFboRecruiters = this.selectedFboRecruiters && Array.isArray(this.selectedFboRecruiters)
                ? this.selectedFboRecruiters.filter(r => r?.UserProfileIdSales)
                : this.selectedFboRecruiters;

              this.selectedFboRecruiters.forEach(r => {
                const foundRecruiter = this.workOrderDataService.userProfileCommissionsList.find(up =>
                  up.Id === r.UserProfileIdSales);
                r.FullName = foundRecruiter ? foundRecruiter.DisplayText : 'Lookup Error';
                r.IsHouseAccount = foundRecruiter?.Data ? foundRecruiter.Data.IsHouseAccount : false;
              });

              this.reSyncedFboRecruiters = this.selectedFboRecruiters.filter(r => {
                return r.IsHouseAccount;
              });
            }

            this.workOrderService.getAts(1, this.workOrder.AtsPlacementId).pipe(
              takeUntil(this.isDestroyed$)
            ).subscribe({
              next: (response: any) => {
                const getAtsJobOwnerIdAndName = (): { UserProfileIdSales: number, FullName: string; } | null => {
                  if (response?.FboJobOwnerUserProfileId > 0 && response?.FboJobOwnerIsActive) {
                    return {
                      UserProfileIdSales: response.FboJobOwnerUserProfileId,
                      FullName: response.FboJobOwnerName
                    };
                  }

                  return null;
                };
                const getAtsReferenceContactIdAndName = (): { UserProfileId: number, FullName: string; } | null => {
                  if (response?.FboReferenceContactUserProfileId > 0 && response?.FboReferenceContactIsActive) {
                    return {
                      UserProfileId: response.FboReferenceContactUserProfileId,
                      FullName: response.FboReferenceContactName
                    };
                  }

                  return null;
                };

                this.timesheetRefenceContactHasDifference = false;
                this.expenseReferenceContactHasDifference = false;

                const jobOwner = getAtsJobOwnerIdAndName();

                if (jobOwner) {
                  this.atsJobOwner = {
                    Id: 0,
                    UserProfileIdSales: jobOwner.UserProfileIdSales,
                    FullName: jobOwner.FullName,
                    CommissionRoleId: null,
                    IsApplicable: null,
                    CommissionRateHeaderId: null,
                    Description: '',
                    CommissionRates: null,
                    IsHouseAccount: null
                  };

                  if (response.FboRecruiterUserProfileId > 0 && this.showRecruiters) {
                    this.atsAddedBy = {
                      Id: 0,
                      UserProfileIdSales: response.FboRecruiterUserProfileId,
                      FullName: response.FboRecruiterName,
                      CommissionRoleId: null,
                      IsApplicable: null,
                      CommissionRateHeaderId: null,
                      Description: '',
                      CommissionRates: null,
                      IsHouseAccount: null
                    };
                  }

                  // If there are any recruiters in the reSyncedFboRecruiters list, they will be house accounts and thus
                  // we should not add the recruiter from the ATS system. If the ATS recruiter is not in the list then add her
                  if (this.showRecruiters && (this.reSyncedFboRecruiters.length === 0
                    || (this.reSyncedFboRecruiters.length > 0 && this.atsAddedBy
                      && !this.reSyncedFboRecruiters.some(r => r.UserProfileIdSales === this.atsAddedBy.UserProfileIdSales)
                      && this.selectedFboRecruiters.some(r => r.UserProfileIdSales === this.atsAddedBy.UserProfileIdSales)))) {
                    this.reSyncedFboRecruiters.push(this.atsAddedBy);
                  }

                  this.workOrder.AtsUserProfileIdJobOwner = this.atsJobOwner.UserProfileIdSales;
                  this.workOrder.AtsUserProfileIdRecruiter = this.atsAddedBy ? this.atsAddedBy.UserProfileIdSales : null;

                  /** NOTE: compare ATS data against current WO core tab commisions component data */
                  const coreCommissionHasDifferences =
                    this.atsJobOwner.UserProfileIdSales !== this.selectedFboJobOwner.UserProfileIdSales ||
                    (this.showRecruiters &&
                      (this.selectedFboRecruiters.length !== 1 ||
                        (this.selectedFboRecruiters.length === 1 && this.atsAddedBy && this.selectedFboRecruiters[0].UserProfileIdSales !== this.atsAddedBy.UserProfileIdSales)));

                  /** NOTE: compare ATS data against current WO attributions tab selected sales/recruiter profile data */
                  let attributionTabHasDifferences = false;
                  if (this.showAttributionsTab) {
                    attributionTabHasDifferences = this.selectedAttributionSaleUserProfileIds.length !== 1 || this.atsJobOwner.UserProfileIdSales !== this.selectedAttributionSaleUserProfileIds?.[0] ||
                      /** NOTE: no ATS recruiter but attributions tab has recruiters selected */
                      (!this.atsAddedBy && this.selectedAttributionRecruiterUserProfileIds.length > 0) ||
                      /** NOTE: we have an ATS recruiter but attributions tab recruiter list doesn't match */
                      (this.atsAddedBy && this.selectedAttributionRecruiterUserProfileIds.length !== 1) ||
                      /** NOTE: we have an ATS recruiter and an attribution recruter but they are different */
                      (this.selectedAttributionRecruiterUserProfileIds.length === 1 && this.selectedAttributionRecruiterUserProfileIds?.[0] !== this.atsAddedBy.UserProfileIdSales);
                  }
                  this.getAtsReSyncWarningMessage(response);
                  this.hasJobOwnerDifferencesToShow = attributionTabHasDifferences
                    || coreCommissionHasDifferences;
                }

                /** NOTE: billing invoice reference contact for expenses and timesheets is set to ATS custom profile   */
                const referenceContact = getAtsReferenceContactIdAndName();
                if (referenceContact) {
                  const timesheetBillingInfo = this.rootFormGroup.get('TabTimeMaterialInvoice').get('TabTimeMaterialInvoiceBillingInfoes').get('BillingInfoes').value;
                  const selectedTimesheetReferenceContactProfileId = timesheetBillingInfo?.[0]?.BillingInvoices
                    ?.find(invoice => invoice.InvoiceTypeId === PhxConstants.InvoiceType.TimeSheet)?.BillingReferenceContactProfileId;

                  /** NOTE: compare selected timesheet reference contact to ats custom contact */
                  this.timesheetRefenceContactHasDifference = referenceContact.UserProfileId !== selectedTimesheetReferenceContactProfileId;
                  this.reSyncedTimesheetReferenceContactProfileName = referenceContact.FullName;
                  this.selectedTimesheetReferenceContactProfileName = this.html.commonLists.listUserProfileInternal
                    .find(profile => profile.userProfileId === selectedTimesheetReferenceContactProfileId)?.displayName;

                  const expenseBillingInfo = this.rootFormGroup.get('TabExpenseInvoice').get('TabExpenseInvoiceBillingInfoes').get('BillingInfoes').value;
                  const selectedExpenseReferenceContactProfileId = expenseBillingInfo?.[0]?.BillingInvoices
                    ?.find(invoice => invoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense)?.BillingReferenceContactProfileId;

                  /** NOTE: compare selected expense reference contact to placement job owner */
                  this.expenseReferenceContactHasDifference = referenceContact.UserProfileId !== selectedExpenseReferenceContactProfileId;
                  this.reSyncedExpenseReferenceContactProfileName = referenceContact.FullName;
                  this.selectedExpenseReferenceContactProfileName = this.html.commonLists.listUserProfileInternal
                    .find(profile => profile.userProfileId === selectedExpenseReferenceContactProfileId)?.displayName;

                  this.selectedTimesheetReferenceContactProfileName = this.selectedTimesheetReferenceContactProfileName ?? 'None Selected';
                  this.selectedExpenseReferenceContactProfileName = this.selectedExpenseReferenceContactProfileName ?? 'None Selected';

                  this.hasReferenceContactDifferencesToShow = this.timesheetRefenceContactHasDifference
                    || this.expenseReferenceContactHasDifference;

                }

                /** NOTE: if WO has different data than ATS that will be overwritten - prompt the user  */
                if (this.hasJobOwnerDifferencesToShow || this.atsReSyncWarningMessage || this.hasReferenceContactDifferencesToShow) {
                  this.modalAtsReSync.show();
                } else {
                  this.onClickStateAction(this.currentAction, this.currentComponentOption, this.currentActionOption);
                }

              },
              error: (error: HttpErrorResponse) => {
                this.html.validationMessages = [error.error];
              }
            });
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
            return !this.isTenantATSIntegrationEnabled || componentOption.displayType !== StateActionDisplayType.DROPDOWN || this.shouldHideResync();
          }
        },
        {
          actionId: PhxConstants.StateAction.WorkOrderVersionAddAtsId,
          commandName: PhxConstants.CommandNamesSupportedByUi.WorkOrderActionCommand.WorkOrderVersionAddAtsId,
          displayText: $localize`:@@workorder.addATSId:Add ATS Id`,
          onClick: (action, componentOption, actionOption) => {
            setATSIdSub?.unsubscribe();

            setATSIdSub = this.setATSId$.pipe(
              takeUntil(this.isDestroyed$),
              first()
            ).subscribe(atsPlacementId => {
              void this.onClickStateAction(action, componentOption, { ...actionOption, atsPlacementId });
            });

            this.showEnterAtsIdModal$.next();
          },
          hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption): boolean => {
            return !this.isTenantATSIntegrationEnabled || Boolean(this.workOrder?.AtsPlacementId) || componentOption.displayType !== StateActionDisplayType.DROPDOWN;
          }
        }
      ];

    if (this.includeTerminationPlanningFormAction && this.authService.getUserContextSync()?.User?.CurrentTenantName === PhxConstants.Tenant.PROCOM) {
      this.stateActions.push({
        displayText: 'Termination Planning Risk Assessment Form',
        onClick: () => {
          this.terminationPlanningModal.openModal();
        },
        hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption) => {
          return !this.workOrder || this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.Active || componentOption.displayType !== StateActionDisplayType.DROPDOWN;
        }
      });
    }

    this.stateActions.push({
      displayText: $localize`:@@common.button.collectDocuments:Collect Documents`,
      onClick: () => {
        this.documentCollectorService.goToDocumentCollector({
          referenceCode: this.workOrder.AssignmentId,
          entityId: this.workOrder.WorkOrderId,
          entityTypeId: PhxConstants.EntityType.WorkOrder,
          workerId: this.workOrder.UserProfileIdWorker
        });
      },
      hiddenFn: (action: StateAction, componentOption: StateActionButtonsOption): boolean => {
        return !this.workOrder
          || this.workOrder.readOnlyStorage.isTemplate
          || componentOption.displayType !== StateActionDisplayType.DROPDOWN
          || !this.documentCollectorService.checkCollectDocumentsIsAvailable({
            entityTypeId: PhxConstants.EntityType.WorkOrder,
            workerProfileTypeId: this.workOrder.workerProfileTypeId,
          });
      }
    });

    this.stateActionsOffboarding = this.stateActions.map(s => {
      return { ...s };
    });
  }

  private closeModalAtsReSync() {
    this.modalAtsReSync.hide();
  }

  private editableComplianceDraftFields(workorder: IWorkOrder) {
    return workorder.WorkOrderVersion.IsComplianceDraftStatus;
  }

  private setupPrivateEvents() {
    this.signlrService.onPrivate('WorkOrderOffboardingCompletionDate', (event, data) => {
      // check if the event received is for the current profile
      if (data.WorkOrderId === this.workOrder.WorkOrderId) {
        this.workOrder = {
          ...cloneDeep(this.workOrder),
          OffboardingCompletionDate: data.OffboardingCompletionDate
        };
        ControlFieldAccessibility.workorder = this.workOrder;
        this.workOrderFormService.updateForm(this.workOrder);
      }
    }, true)
      .then(unregister => {
        if (unregister) {
          this.unregisterList.push(unregister);
        }
      });
  }

  private async updateWorkorderState(workOrder: IWorkOrder, workerProfiles: Array<ICommonListsItem>) {

    let currId: number;

    if (this.showTemplate) {
      currId = workOrder ? workOrder.TemplateId : null;
    } else {
      currId = workOrder?.WorkOrderVersion?.Id ?? null;
    }

    if (workerProfiles?.length) {
      this.workOrderFormService.setWorkerProfiles(workerProfiles);
    }

    this.reInit = false;
    let checkEarningValidate = false;

    const canFinalize = workOrder.combinedAvailableStateActions.includes(PhxConstants.StateAction.WorkOrderVersionFinalize);
    if (workOrder.readOnlyStorage.IsCommissionsEditable || canFinalize) {
      workOrder = await this.workOrderDataService.getCommissionRates(workOrder);
    }

    this.isImpactApprovalRequired = this.checkWorkOrderVersionCommissionChange(workOrder);

    if (workOrder.readOnlyStorage.IsEditable && this.editableComplianceDraftFields(workOrder)) {
      checkEarningValidate = await this.workplaceSafetyInsuranceFormService.getValidatorForWorkerCompensation(workOrder);
    }


    const customFields = await this.IsClientSpecificFieldsIncluded(workOrder);
    // ABOVE ARE ASYNC CALL
    // CHECK ID AGAINST URL BEFORE WE PROCESS FURTHER TO SET VARIABLES
    if ((!this.showTemplate && +this.routerParams.versionId !== currId)
      || (this.showTemplate && +this.routerParams.templateId !== currId)) {
      return;
    }

    const orgHasFederalWorkers = this.workOrderOrganization?.OrganizationClientRoles.length > 0
      ? (this.workOrderOrganization.OrganizationClientRoles[0]?.HasFederalWorkers ?? false)
      : false;
    this.workOrderFormService.organizationHasFederalWorkers = orgHasFederalWorkers;
    this.showClientSpecificFields$.next(customFields?.length > 0 || orgHasFederalWorkers);
    this.clientSpecificFieldsData = customFields || [];
    this.clientSpecificFieldsFormService.fieldsData = this.clientSpecificFieldsData;
    this.workplaceSafetyInsuranceFormService.checkEarningvalidate = checkEarningValidate;

    if (!this.showTemplate) {
      this.navigationService.setTitle(
        'workorder-viewedit',
        [`${workOrder.AssignmentId}.${workOrder.WorkOrderVersion.WorkOrderNumber}`]
      );
    } else {
      this.navigationService.setTitle('workorder-template-viewedit');
    }
    this.setValidationMessages({
      versionId: null,
      apiError: null,
      messages: null
    });
    this.selectedCounter = workOrder.WorkOrderVersion.WorkOrderNumber;

    return workOrder;
  }

  private IsClientSpecificFieldsIncluded(workOrder: IWorkOrder): Promise<CustomField[]> {
    return new Promise<CustomField[]>((resolve, reject) => {
      const clientId = workOrder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient;
      const entityId = workOrder.WorkOrderVersion.Id;
      const entityTypeId = PhxConstants.EntityType.WorkOrderVersion;
      if (entityId !== null) {
        this.clientSpecificFieldsService.getCustomFields(clientId, entityId, entityTypeId).then(response => {
          if (!Array.isArray(response)) {
            throw new Error('failed request');
          } else {
            this.haveClientSpecificFieldError = false;
            resolve(response);
          }
        }, () => {
          reject();
        }).catch(() => {
          this.haveClientSpecificFieldError = true;
          resolve([]);
        });
      } else {
        reject();
      }
    });
  }

  private shouldHideReactivate(): boolean {
    return this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Complete && this.workOrder.WorkOrderVersion.StatusId !== PhxConstants.WorkOrderVersionStatus.Approved;
  }

  private shouldHideResync(): boolean {
    return !this.workOrder
      || !(this.syncFromATS && WorkOrdernWorkflowComponent.currentProfile?.ProfileTypeId === PhxConstants.UserProfileType.Internal)
      || this.workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Declined;
  }

  private isWorkOrderTerminated(): boolean {
    return this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Terminated
      || this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTermination
      || this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTerminationNotice;
  }

  private shouldHideTransaction(): boolean {
    return false;
  }

  private shouldHideAdjustment(): boolean {
    return false;
  }

  private shouldHideReviseTermination(): boolean {
    return this.workOrder.StatusId !== PhxConstants.WorkOrderStatus.PendingTerminationNotice;
  }

  private shouldHideExtend(): boolean {
    return (this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Terminated && this.workOrder.TerminationTypeId !== PhxConstants.TerminationType.TemporaryLayoff) ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Processing ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTerminationNotice ||
      this.workOrder.StatusId === PhxConstants.WorkOrderStatus.PendingTermination ||

      (this.workOrder.StatusId === PhxConstants.WorkOrderStatus.ChangeInProgress && this.workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingUnterminate) ||

      (this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Complete && this.workOrder.WorkOrderVersion.StatusId !== PhxConstants.WorkOrderVersionStatus.Approved);
  }

  private shouldHideTerminate(): boolean {

    if (this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Active || this.workOrder.StatusId === PhxConstants.WorkOrderStatus.Complete) {
      if (this.workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved) {
        return false;
      }
    }

    return true;
  }

  private checkWorkOrderVersionCommissionChange(workOrder: IWorkOrder): boolean {
    const cannotFinalize = !workOrder.combinedAvailableStateActions.includes(PhxConstants.StateAction.WorkOrderVersionFinalize);

    if (cannotFinalize) {
      return false;
    }

    const workOrderVersionCommissions = workOrder.WorkOrderVersion.WorkOrderVersionCommissions;
    const hasChangedFromPrevWov = this.workOrderService
      .isWorkOrderVersionCommissionsChanged(workOrderVersionCommissions, workOrder.PreviousWorkOrderVersionCommissions, workOrder);
    let impactApprovalRequired = false;
    if (!hasChangedFromPrevWov) {
      // If current commissions is the same as previous WOV commissions, no need to approve impact again
      workOrder.WorkOrderVersion.IsWovCommissionChanged = false;
      workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved = null;
    } else {
      const hasChangedFromLastSave = this.workOrderService
        .isWorkOrderVersionCommissionsChanged(workOrderVersionCommissions, workOrder.LastSavedWorkOrderVersionCommissions, workOrder);
      let hasChanged;
      // IsWovCommissionChanged: Detect changes based on if approved (in the DB)
      if (!workOrder.IsLastSavedWOVCommImpactApproved) {
        // If not currently approved: current commissions vs previous WOV commissions
        hasChanged = hasChangedFromPrevWov;
      } else {
        // If already approved: current commissions vs last saved WOV commissions
        hasChanged = hasChangedFromLastSave;
      }

      workOrder.WorkOrderVersion.IsWovCommissionChanged = hasChanged;
      // Tab validation: Only if changes are made
      impactApprovalRequired = hasChanged;

      let isCommissionChangeImpactApproved: boolean;
      // IsCommissionChangeImpactApproved: for the Approve Impact/Impact Approved button and disabled state
      if (workOrder.WorkOrderVersion.IsWovCommissionChanged) {
        // Changed either from previous wov (not approved) or from last impact approval
        isCommissionChangeImpactApproved = false;
      } else if (workOrder.IsLastSavedWOVCommImpactApproved) {
        // No changes since last impact approval
        isCommissionChangeImpactApproved = true;
      } else {
        // Not approved and no changes to approve
        isCommissionChangeImpactApproved = null;
      }

      workOrder.WorkOrderVersion.IsCommissionChangeImpactApproved = isCommissionChangeImpactApproved;
    }
    return impactApprovalRequired;
  }
  private getIsTenantATSIntegrationEnabled() {
    this.isTenantATSIntegrationEnabled$
      .subscribe(r => {
        this.isTenantATSIntegrationEnabled = r;
        ControlFieldAccessibility.isTenantATSIntegrationEnabled = r;
      });
  }

  private getAtsReSyncWarningMessage(response: ApplicationTransactionSystemDto): void {
    const missingFields: string[] = [];

    if (!response.PaymentRateUnitId) {
      missingFields.push('Pay Rate Unit');
    }
    if (!response.BillingRateUnitId) {
      missingFields.push('Bill Rate Unit');
    }

    this.atsReSyncWarningMessage = missingFields.length ? {
      title: '',
      body: `The following fields are missing on the placement and will not be synced: ${missingFields.join(', ')}. You will need to verify and update those fields manually.`
    } : undefined;
  }
}
