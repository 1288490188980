// Hoisting and function overload are needed here
export function trimFields<T>(arg: T[], key: keyof T | Array<keyof T>): T[];
export function trimFields<T>(arg: T, key: keyof T | Array<keyof T>): T;
export function trimFields<T>(arg: T | T[], key: keyof T | Array<keyof T>): T | T[] {
  if (!arg) {
    return arg;
  }

  if (Array.isArray(arg)) {
    return arg.map(i => trimFieldsForSingleItem(i, key));
  }

  return trimFieldsForSingleItem(arg, key);
}

function trimFieldsForSingleItem<T>(i: T, key: keyof T | Array<keyof T>): T {
  if (Array.isArray(key)) {
    return key.reduce((a, b) => ({ ...a, ...trimSingleField(a, b) }), i);
  }
  return trimSingleField(i, key);
}

function trimSingleField<T>(arg: T, key: keyof T): T {
  return { ...arg, [key]: typeof arg[key] === 'string' ? (arg[key] as string).trim() : arg[key] };
}
