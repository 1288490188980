<div *ngIf="recipientIndex === 0">
  <phx-form-control labelText="Invoice Recipient" [control]="inputFormGroup.controls.UserProfileId"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','UserProfileId')"
    viewModeText="{{inputFormGroup.controls.UserProfileId.value | lookup: html.commonLists.listProfilesForApproval:'Id':'Contact.FullName'}} - {{inputFormGroup.controls.UserProfileId.value}}">
    <app-phx-select-box [items]="html.commonLists.listProfilesForApproval" textField="DisplayValue" valueField="Id"
      [formControl]="inputFormGroup.controls.UserProfileId">
    </app-phx-select-box>
  </phx-form-control>
  <phx-form-control
    labelText="{{invoiceTypeId === phxConstants.InvoiceType.TimeSheet ? 'Delivery Strategy' : 'Invoice Strategy'}}"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','DeliveryMethodId')"
    [control]="inputFormGroup.controls.DeliveryMethodId"
    viewModeText="{{inputFormGroup.controls.DeliveryMethodId.value | CodeValue:codeValueGroups.DeliveryMethod}}">
    <app-phx-select-box [items]="html.codeValueLists.listDeliveryMethods" textField="text" valueField="id"
      [formControl]="inputFormGroup.controls.DeliveryMethodId">
    </app-phx-select-box>
  </phx-form-control>
  <ng-container *ngIf="inputFormGroup.controls.DeliveryMethodId.value===html.phxConstants.DeliveryMethod.FileExportFormat">
    <phx-form-control labelText="File Export Format"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','FileExportFormatId')"
      [control]="inputFormGroup.controls.FileExportFormatId"
      viewModeText="{{inputFormGroup.controls.FileExportFormatId.value | CodeValue:codeValueGroups.FileExportFormat}}">
      <app-phx-select-box [items]="html.codeValueLists.listFileExportFormats" textField="text" valueField="id"
        [formControl]="inputFormGroup.controls.FileExportFormatId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
  <ng-container
    *ngIf="inputFormGroup.controls.DeliveryMethodId.value===html.phxConstants.DeliveryMethod.InternalProfile">
    <phx-form-control labelText="Delivery To"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','DeliverToUserProfileId')"
      [control]="inputFormGroup.controls.DeliverToUserProfileId"
      viewModeText="{{inputFormGroup.controls.DeliverToUserProfileId.value | lookup: html.commonLists.listActiveUserProfile:'Id':'DisplayText'}}">
      <app-phx-select-box [items]="html.commonLists.listActiveUserProfile" textField="DisplayText" valueField="Id"
        [formControl]="inputFormGroup.controls.DeliverToUserProfileId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
  <ng-container
    *ngIf="inputFormGroup.controls.DeliveryMethodId.value===html.phxConstants.DeliveryMethod.ClientProfile">
    <phx-form-control labelText="Delivery To"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','DeliverToUserProfileId')"
      [control]="inputFormGroup.controls.DeliverToUserProfileId"
      viewModeText="{{inputFormGroup.controls.DeliverToUserProfileId.value | lookup: html.commonLists.listProfilesForApproval:'Id':'DisplayValue'}}">
      <app-phx-select-box [items]="html.commonLists.listProfilesForApproval" textField="DisplayValue" valueField="Id"
        [formControl]="inputFormGroup.controls.DeliverToUserProfileId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
</div>
<div *ngIf="recipientIndex !== 0" style="margin: 20px 0 0;">
  <ng-container *ngIf="!inputFormGroup.controls.RecipientTypeId.value">
    <phx-form-control labelText="Recipient Type"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','RecipientTypeId')"
      [control]="inputFormGroup.controls.RecipientTypeId"
      viewModeText="{{inputFormGroup.controls.RecipientTypeId.value | CodeValue:codeValueGroups.RecipientType}}">
      <div class="col-md-10 padding-0">
        <app-phx-select-box [items]="html.codeValueLists.listBillingRecipientTypes" textField="text" valueField="id"
          [formControl]="inputFormGroup.controls.RecipientTypeId">
        </app-phx-select-box>
      </div>
    </phx-form-control>
  </ng-container>
  <ng-container *ngIf="inputFormGroup.controls.RecipientTypeId.value">
    <phx-form-control
      labelText="{{inputFormGroup.controls.RecipientTypeId.value | lookup:html.codeValueLists.listRecipientTypes}}"
      [control]="inputFormGroup.controls.UserProfileId"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','UserProfileId')"
      viewModeText="{{inputFormGroup.controls.UserProfileId.value|lookup:html.commonLists.listProfilesForApproval:'Id':'Contact.FullName':'ContactId'}} ({{((inputFormGroup.controls.UserProfileId.value|lookup:html.commonLists.listProfilesForApproval:'Id':'ProfileTypeId')|CodeValue:codeValueGroups.ProfileType)}} - {{inputFormGroup.controls.UserProfileId.value}})">
      <app-phx-select-box [items]="html.commonLists.listProfilesForApproval" textField="DisplayValue" valueField="Id"
        [formControl]="inputFormGroup.controls.UserProfileId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
  <phx-form-control labelText="Delivery Strategy"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','DeliveryMethodId')"
    [control]="inputFormGroup.controls.DeliveryMethodId"
    viewModeText="{{inputFormGroup.controls.DeliveryMethodId.value | CodeValue:codeValueGroups.DeliveryMethod}}">
    <div class="col-md-10 padding-0">
      <app-phx-select-box [items]="deliveryMethods" textField="text" valueField="id"
        [formControl]="inputFormGroup.controls.DeliveryMethodId">
      </app-phx-select-box>
    </div>
    <div class="col-xs-1 pull-right padding-0">
      <button type="button" class="btn btn-default btn-material pull-right"
        (click)="removeBillingRecipient(recipientIndex)">
        <i class="material-icons">delete</i>
      </button>
    </div>
  </phx-form-control>
  <ng-container
    *ngIf="inputFormGroup.controls.DeliveryMethodId.value===html.phxConstants.DeliveryMethod.InternalProfile">
    <phx-form-control labelText="Delivery To"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice.BillingRecipients','DeliverToUserProfileId')"
      [control]="inputFormGroup.controls.DeliverToUserProfileId"
      viewModeText="{{inputFormGroup.controls.DeliverToUserProfileId.value | lookup: html.commonLists.listActiveUserProfile:'Id':'DisplayName'}}">
      <app-phx-select-box [items]="html.commonLists.listActiveUserProfile" textField="DisplayName" valueField="Id"
        [formControl]="inputFormGroup.controls.DeliverToUserProfileId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
</div>