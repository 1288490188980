<form [formGroup]="purchaseorderForm" *ngIf="purchaseorderForm">
  <div class="content-group form-horizontal">
    <div class="form-group" style="padding-bottom: 0px !important;">
    </div>
    <div class="form-group">
      <label class="h4 bold">PO Details</label>
    </div>
      <div class="form-group">
        <phx-form-control [editable]="false" labelText="PO Line Reference"
          [control]="purchaseorderForm.controls.PurchaseOrderLineReference"
          viewModeText="{{purchaseorderForm.controls.PurchaseOrderLineReference.value}}" class="col-lg-6">
          <!-- <div>
            <input formControlName="PurchaseOrderLineReference" type="text" class="form-control"
              value="{{purchaseorderForm.controls.PurchaseOrderLineReference.value}}" />
          </div> -->
        </phx-form-control>

        <phx-form-control
          [editable]="false"
          labelText="Total Funds"
          [control]="purchaseorderForm.controls.Amount"
          [viewModeText]="purchaseorderForm.controls.Amount.value | currency"
          class="col-lg-6"
        >
        </phx-form-control>
      </div>
      <div class="form-group">
      <div class="form-group">
        <label class="col-lg-2 control-label" title="">PO Number</label>
        <div class="col-lg-10 form-control-static">
          <a target="_blank" routerLink="/next/purchase-order/{{purchaseorderForm.controls.PurchaseOrderId.value}}/details" class="form-control-static">
            {{poNumber}}
          </a>
        </div>
      </div>
        <phx-form-control [editable]="false" [control]="purchaseorderForm.controls.CurrencyId" labelText="Currency"
          viewModeText="{{purchaseorderForm.controls.CurrencyId.value | CodeValue:codeValueGroups.Currency }}"
          class="col-lg-6">
          <!--  <div>
           <input formControlName="CurrencyId" type="text" class="form-control"
            value="{{purchaseorderForm.controls.CurrencyId.value | CodeValue:codeValueGroups.Currency }}" />
        </div> -->
        </phx-form-control>
      </div>
      <div class="form-group">
        <phx-form-control labelText="Allocate Funds" [editable]="false"
        [control]="purchaseorderForm.controls.DepletionOptionId"
        viewModeText="{{purchaseorderForm.controls.DepletionOptionId.value | lookup:listDepletionOption:'id':'text'}}"
        class="col-lg-6">
        <!-- <div>
                              <app-phx-select-box [items]="listDepletionOption" textField="text" valueField="id"
                                  [formControl]="purchaseorderForm.controls.DepletionOptionId">
                              </app-phx-select-box>
                          </div> -->
      </phx-form-control>
        <phx-form-control [control]="purchaseorderForm.controls.IsTaxIncluded" [editable]="false"
          labelText="Tax Included?" viewModeText="{{purchaseorderForm.controls.IsTaxIncluded.value | boolToYesNo}}" class="col-lg-6">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="true" 
              [checked]="purchaseorderForm.controls.IsTaxIncluded.value" formControlName="IsTaxIncluded">Yes</label>
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="false"
              [checked]="!purchaseorderForm.controls.IsTaxIncluded.value" formControlName="IsTaxIncluded">No</label>
        </phx-form-control>
      </div>

      <div class="form-group">
        <phx-form-control labelText="Depletion Group" [editable]="false"
        [control]="purchaseorderForm.controls.DepletionGroupId"
        viewModeText="{{purchaseorderForm.controls.DepletionGroupId.value | CodeValue:codeValueGroups.PurchaseOrderDepletedGroups}}"
        class="col-lg-6">
        <!-- <div>
                      <app-phx-select-box [items]="listDepletionOption" textField="text" valueField="id"
                          [formControl]="purchaseorderForm.controls.DepletionGroupId">
                      </app-phx-select-box>
                  </div> -->
      </phx-form-control>
        <phx-form-control labelText="Start Date" [editable]="false" [control]="purchaseorderForm.controls.StartDate"
          viewModeText="{{purchaseorderForm.controls.StartDate.value | date: phxConstants.DateFormat.mediumDate}}"
          class="col-lg-6">
        </phx-form-control>
      </div>
      <div class="form-group">
        <phx-form-control [control]="purchaseorderForm.controls.Description" [editable]="false" labelText="Description"
        viewModeText="{{purchaseorderForm.controls.Description.value}}" class="col-lg-6">
        <!-- <div>
                              <input formControlName="PurchaseOrderLineReference" type="text" class="form-control" />
                          </div> -->
      </phx-form-control>
          <phx-form-control labelText="End Date" [editable]="false"
              [control]="purchaseorderForm.controls.EndDate"
              viewModeText="{{purchaseorderForm.controls.EndDate.value | date: phxConstants.DateFormat.mediumDate }}" class="col-lg-6">
              <div>
                  <app-phx-date-box type="date"  [displayFormat]="{ dateStyle: 'medium' }"
                      [formControl]="purchaseorderForm.controls.EndDate">
                  </app-phx-date-box>
              </div>
          </phx-form-control>
      </div>
  </div>
</form>
