import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Pipe({
    name: 'phxPercentWithDecimals'
})
export class PhxDisplayPercentWithDecimalsPipe extends PercentPipe implements PipeTransform {
    /**
     * @param value The number (greater than zero) to be formatted as a percentage.
     */
    transform(value: number, digitsInfo: string = '1.4-4', locale: string = 'en-CA'): any {
        if (value != null && value !== undefined) {
            const formattedValue = super.transform(value / 100, digitsInfo, locale);
            return formattedValue;
        } else {
            return '';
        }
    }
}
