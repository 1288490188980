import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { StateHistoryHeader, PhxConstants, CodeValueGroups, StateHistory, StateHistoryGroupHeader } from 'src/app/common/model';
import { CommonService } from 'src/app/common';
import { IWorkOrder, WorkOrderOffboardingStateHistory, WorkOrderOffboardingTaskStateHistory } from '../../models';
import { WorkorderService, WorkorderOffboardingService } from '../../services';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-workorder-offboarding-history',
  templateUrl: './workorder-offboarding-history.component.html',
  styleUrls: ['./../../../common/components/phx-state-history/phx-state-history.component.less', './workorder-offboarding-history.component.less']
})
export class WorkorderOffboardingHistoryComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() workorder: IWorkOrder;
  @Input() actualEndDate: string;
  showHistory = false;

  statusCodeValueGroups: string;
  stateHistoryHeaders: Array<StateHistoryHeader>;
  isTerminatedWorkorder: boolean;

  phxConstant = PhxConstants;
  codeValueGroups = CodeValueGroups;
  NoStatus = 9999;
  constructor(private commonService: CommonService, private ref: ChangeDetectorRef, private workorderService: WorkorderService, private offboardingService: WorkorderOffboardingService) {
    super();
  }

  checkIsTerminated() {
    this.isTerminatedWorkorder =  this.workorder.StatusId === PhxConstants.WorkOrderStatus.Terminated;
  }

  ngOnInit() {
    this.checkIsTerminated();
    this.showHistory = this.offboardingService.showOffboarding(this.actualEndDate);
    this.workorderService
      .offboardingStatusChange$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((refresh: boolean) => {
        if (refresh) {
          this.refresh();
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.checkIsTerminated();
    this.showHistory = this.offboardingService.showOffboarding(this.actualEndDate);
    let needRefresh = false;
    if (changes.workorder?.currentValue) {
      const prevWorkOrder: IWorkOrder = changes.workorder.previousValue;
      const currWorkOrder: IWorkOrder = changes.workorder.currentValue;
      if (prevWorkOrder) {
        if (prevWorkOrder.IsOffboarding !== currWorkOrder.IsOffboarding || prevWorkOrder.WorkOrderId !== currWorkOrder.WorkOrderId ||
          prevWorkOrder.StatusId !== currWorkOrder.StatusId) {
          needRefresh = true;
        }
      } else {
        needRefresh = true;
      }
    }
    if (needRefresh) {
      this.refresh();
    }
  }

  refresh() {
    this.initStateHistory();
  }

  private initStateHistory() {
    this.workorderService.getWorkOrderOffboardingStateHistory(this.workorder.WorkOrderId).then(
      (workOrderOffboardingStateHistory: WorkOrderOffboardingStateHistory) => {
        if (workOrderOffboardingStateHistory.OffboardingTaskStateHistories) {
          const ssh = {
            EntityId: workOrderOffboardingStateHistory.WorkOrderStartOffboardingStateHistory ? workOrderOffboardingStateHistory.WorkOrderStartOffboardingStateHistory.EntityId : 0,
            EntityTypeId: this.phxConstant.EntityType.WorkOrder,
            VersionNumber: 0,
            VersionName: null,
            VersionDisplayText: 'Offboarding',
            EffectiveDate: this.workorder.TerminationDate || this.workorder.EndDate,
            StateHistory: [],
            StateHistoryGrouped: [], // UI grouping
            CurrentStatus: 0, // UI mapping
            isOpen: true, // UI flag
            HeaderTitle: 'Offboarding',
            ExpiryDate: this.workorder.TerminationDate || this.workorder.EndDate,
            SnoozeExpiryDate: '',
            Reason: this.workorder.TerminationDate ? 'Termination' : 'End of Assignment'
          };
          ssh.StateHistoryGrouped = this.groupStateHistory(workOrderOffboardingStateHistory);
          this.stateHistoryHeaders = [ssh];
        } else {
          this.stateHistoryHeaders = null;
        }

        this.ref.detectChanges();
      },
      () => {
        this.stateHistoryHeaders = null;
      }
    );
  }

  private getAllTaskCompletedHistory(offboardStateHistory: WorkOrderOffboardingStateHistory): StateHistoryGroupHeader {
    const header = {
      Future: true,
      NextStep: false,
      LatestCurrent: false,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      LastCreatedByFirstName: '',
      LastCreatedByLastName: '',
      LastCreatedByFullName: '',
      LastCreatedDatetime: null,
      StateHistory: []
    };

    const stateHistory = {
      ActionEntityLogId: 1,
      ActionLogId: 1,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      UserComment: '',
      Note: 'Offboarding Complete',
      CreatedByFirstName: '',
      CreatedByLastName: '',
      CreatedByFullName: '',
      isOffboardingStateHistory: true,
      CreatedDatetime: null,
      NextStep: false, // UI field
      Description: `` // UI field
    };
    header.StateHistory.push( stateHistory as WorkOrderOffboardingTaskStateHistory);
    if (offboardStateHistory.WorkOrderStartOffboardingStateHistory) {
      if (this.workorder.IsOffboarding || this.isTerminatedWorkorder) {
        if (this.isAllTaskCompleted()) {
          header.Future = false;
          header.NextStep = false;
          header.LatestCurrent = true;
        } else {
          stateHistory.NextStep = true;
          header.LatestCurrent = false;
        }
      }
    }
    if (this.workorder.OffboardingCompletionDate) {
      header.Future = false;
    }
    return header;
  }

  private getCompletedTaskHistory(offboardStateHistory: WorkOrderOffboardingStateHistory): StateHistoryGroupHeader {
    const header: StateHistoryGroupHeader = {
      Future: false,
      NextStep: true,
      LatestCurrent: false,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      LastCreatedByFirstName: '',
      LastCreatedByLastName: '',
      LastCreatedByFullName: '',
      LastCreatedDatetime: this.workorder.OffboardingCompletionDate,
      StateHistory: []
    };
    const stateHistory = {
      ActionEntityLogId: 1,
      ActionLogId: 1,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      UserComment: '',
      Note: 'Complete Tasks',
      CreatedByFirstName: '',
      CreatedByLastName: '',
      CreatedByFullName: '',
      isOffboardingStateHistory: true,
      CreatedDatetime: this.workorder.OffboardingCompletionDate,
      NextStep: false, // UI field
      Description: '' // UI field
    };

    header.StateHistory.push( stateHistory as WorkOrderOffboardingTaskStateHistory);

    if (this.workorder.IsOffboarding && !this.isTerminatedWorkorder) {
      if (this.isAllTaskCompleted()) {
        header.Future = false;
        header.LatestCurrent = false;
        if (offboardStateHistory.OffboardingTaskStateHistories) {
          const completedUsersFullName = [...new Set(offboardStateHistory.OffboardingTaskStateHistories.map(h => h.CreatedByFullName))];
          if (completedUsersFullName.length === 1) {
            stateHistory.Description = `Completed by ${completedUsersFullName[0]}`;
          } else {
            stateHistory.Description = `Completed by ${completedUsersFullName[0]} and ${completedUsersFullName.length - 1} other`;
          }
        }
      } else {
        header.LatestCurrent = true;
      }
      header.NextStep = false;
    } else if (this.isTerminatedWorkorder) {
      if (this.isAllTaskCompleted()) {
        header.Future = false;
        header.LatestCurrent = false;
      } else {
        header.LatestCurrent = true;
      }
    } else {
      stateHistory.NextStep = true;
      header.Future = true;
    }
    const workflowActions: Array<number> = [
      this.phxConstant.StateAction.OffboardSubmit,
      this.phxConstant.StateAction.OffboardExemption,
      this.phxConstant.StateAction.OffboardUncheck,
      this.phxConstant.StateAction.OffboardUndoExemption
    ];

    const statusPending = [PhxConstants.WorkOrderOffboardingTaskStatus.PendingClientServices, PhxConstants.WorkOrderOffboardingTaskStatus.PendingAccounting];

    offboardStateHistory.OffboardingTaskStateHistories.filter(
      action => workflowActions.includes(action.StateActionId) || (action.StateActionId === this.phxConstant.StateAction.OffboardSave && statusPending.includes(action.EntityStatusId))
    ).forEach((shistory: StateHistory) => {
      shistory.Description = this.getHistoryDescription(shistory);
      header.StateHistory.push( shistory as WorkOrderOffboardingTaskStateHistory);
    });

    return header;
  }

  private getConfirmOffboardingHistory(offboardStateHistory: WorkOrderOffboardingStateHistory): StateHistoryGroupHeader {
    const header = {
      Future: false,
      NextStep: false,
      LatestCurrent: false,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      LastCreatedByFirstName: '',
      LastCreatedByLastName: '',
      LastCreatedByFullName: '',
      LastCreatedDatetime: null,
      StateHistory: []
    };
    const stateHistory = {
      ActionEntityLogId: 1,
      ActionLogId: 1,
      EntityId: this.workorder.WorkOrderId,
      EntityStatusId: this.NoStatus,
      StateActionId: 1,
      UserComment: 'comment',
      Note: this.isTerminatedWorkorder ? 'Work Order Terminated' : 'Confirm Offboarding',
      CreatedByFirstName: '',
      CreatedByLastName: '',
      CreatedByFullName: '',
      isOffboardingStateHistory: true,
      CreatedDatetime: null,
      NextStep: false, // !this.workorder.IsOffboarding, // UI field
      Description: '' // UI field
    };
    header.StateHistory.push( stateHistory as WorkOrderOffboardingTaskStateHistory);
    if (offboardStateHistory.WorkOrderStartOffboardingStateHistory || this.isTerminatedWorkorder) {
      header.LastCreatedDatetime = this.workorder.OffboardingCompletionDate;
      stateHistory.CreatedDatetime = offboardStateHistory.WorkOrderStartOffboardingStateHistory.CreatedDatetime;
      stateHistory.Description = this.isTerminatedWorkorder ? `Termination initiated by ${offboardStateHistory.WorkOrderStartOffboardingStateHistory.CreatedByFullName}`
                                 : `Offboarding confirmed by ${offboardStateHistory.WorkOrderStartOffboardingStateHistory.CreatedByFullName}`;
    }

    if (this.workorder.IsOffboarding || this.isTerminatedWorkorder) {
      header.Future = false;
      header.NextStep = false;
      header.LatestCurrent = false;
    } else {
      header.Future = true;
      header.NextStep = true;
      header.LatestCurrent = true;
    }

    return header;
  }

  private groupStateHistory(offboardStateHistory: WorkOrderOffboardingStateHistory): Array<StateHistoryGroupHeader> {
    const historyGrouped: Array<StateHistoryGroupHeader> = [];

    // Offboarding Complete
    historyGrouped.push(this.getAllTaskCompletedHistory(offboardStateHistory));

    // Complete Tasks

    historyGrouped.push(this.getCompletedTaskHistory(offboardStateHistory));

    // confirm offboarding
    historyGrouped.push(this.getConfirmOffboardingHistory(offboardStateHistory));
    return historyGrouped;
  }

  private isAllTaskCompleted(): boolean {
    return !!this.workorder.OffboardingCompletionDate;
  }

  private getHistoryDescription(history: StateHistory): string {
    return this.commonService.getHistoryDescription(history, this.phxConstant.EntityType.WorkOrderOffboardingTask);
  }
}
