<app-workorder-offboarding-documents *ngIf="html.rootModel" [workOrderId]="html.rootModel.WorkOrderId"
  [actualEndDate]="html.rootModel.TerminationDate || html.rootModel.EndDate">
</app-workorder-offboarding-documents>
<app-compliance-document *ngIf="html.rootModel" [referenceCode]="html.rootModel.AssignmentId"
  [entityTypeId]="html.phxConstants.EntityType.WorkOrder"
  [entityName]="html.rootModel.UserProfileIdWorker|lookup:html.lists.listUserProfileWorker:'Id':'Contact.FullName'"
  [entityId]="html.rootModel.WorkOrderId" [maxSnoozeExpiryDate]="html.rootModel.EndDate"
  [workerId]="html.rootModel.UserProfileIdWorker"
  [workerProfileTypeId]="+(html.rootModel.UserProfileIdWorker|lookup:html.lists.listUserProfileWorker:'Id':'ProfileTypeId')"
  [additionalInformation]="html.documentComplianceAdditionalInformation"
  [organizationIdClient]="html.rootModel.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient"
  [secondaryEntityId]="workOrderVersionId">
</app-compliance-document>