<h4>Sales Tax</h4>

<phx-form-control labelText="Charge Sales Tax" [control]="inputFormGroup.controls.ApplySalesTax"
                  [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','ApplySalesTax')"
                  viewModeText="{{inputFormGroup.controls.ApplySalesTax.value ? 'Yes' : 'No'}}">
  <label class="radio-inline">
    <input class="form-check-input" type="radio" name="ApplySalesTax + {{paymentInfoIndex}}" [value]="true"
            [formControl]="inputFormGroup.controls.ApplySalesTax">Yes</label>
  <label class="radio-inline">
    <input class="form-check-input" type="radio" name="ApplySalesTax + {{paymentInfoIndex}}" [value]="false"
            [formControl]="inputFormGroup.controls.ApplySalesTax">No</label>
</phx-form-control>

<ng-container
    *ngIf="(inputFormGroup.controls.OrganizationIdSupplier.value>0 || isEligebleForPaymentSalesTax()) && inputFormGroup.controls.ApplySalesTax.value && validateComplianceDraft">
  
  <phx-form-control labelText="Tax Province/State"
                    [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','SubdivisionIdSalesTax')"
                    [control]="inputFormGroup.controls.SubdivisionIdSalesTax"
                    viewModeText="{{inputFormGroup.controls.SubdivisionIdSalesTax.value | CodeValue:codeValueGroups.Subdivision}}">
    <app-phx-select-box [items]="salesTaxTerritories" textField="text" valueField="id"
                        [formControl]="inputFormGroup.controls.SubdivisionIdSalesTax">
    </app-phx-select-box>
  </phx-form-control>
  <phx-form-control *ngIf="inputFormGroup.controls.SubdivisionIdSalesTax.value && taxJurisdictions?.length > 0"
                    labelText="Tax Jurisdiction"
                    [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','JurisdictionId')"
                    [control]="inputFormGroup.controls.JurisdictionId"
                    viewModeText="{{inputFormGroup.controls.JurisdictionId.value | lookup: taxJurisdictions:'JurisdictionId':'DisplayName'}}">
    <app-phx-select-box [items]="taxJurisdictions" textField="DisplayName" valueField="JurisdictionId"
                        [formControl]="inputFormGroup.controls.JurisdictionId">
    </app-phx-select-box>
  </phx-form-control>

  <ng-container *ngIf="inputFormGroup.get('PaymentSalesTaxes').value?.length > 0">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Description</th>
        <th>Percentage</th>
        <th>Has Number</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let paymentSalesTax of paymentSalesTaxesFormArray.controls">
        <tr *ngIf="paymentSalesTax.get('ratePercentage').value">
          <td>{{paymentSalesTax.get('DisplayName').value}}</td>
          <td>{{paymentSalesTax.get('ratePercentage').value}}<span
              *ngIf="paymentSalesTax.get('ratePercentage').value">
                %</span></td>
          <td>{{paymentSalesTax.get('hasNumber').value ? 'Yes' : 'No'}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </ng-container>
</ng-container>
