
import { takeUntil } from 'rxjs/operators';
// Angular
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { find } from 'lodash';
// Common
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
// Work order
import { IWorkOrder, IPaymentOtherEarning, ITabEarningsAndDeductions, IReadOnlyStorage } from '../../models';
import { EarningsDeductionsTabFormService, WorkOrderFormService } from '../../services';
import { PhxFormControlLayoutType, CodeValueGroups, PhxConstants } from '../../../common/model';
import { CodeValueService, } from 'src/app/common';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-workorder-tab-earnings-and-deduction-other-earnings',
  templateUrl: './workorder-tab-earnings-and-deduction-other-earnings.component.html',
  styleUrls: ['./workorder-tab-earnings-and-deduction-other-earnings.component.less']
})
export class WorkorderTabEarningsAndDeductionOtherEarningsComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() inputFormGroup: FormGroup<IPaymentOtherEarning>;
  @Input() listUserProfileWorker: Array<any>;
  @Input() readOnlyStorage: IReadOnlyStorage;

  phxConstants = PhxConstants;

  @ViewChild('phxDialogComponent', { static: true }) phxDialogComponent: any;

  isUserProfileWorkerLoaded = false;
  workOrder: IWorkOrder;
  layoutType: any;
  percentageFilter: any = { from: 0, to: 100, decimalplaces: 4 };
  phxDialogComponentConfigModel: any;
  codeValueGroups = CodeValueGroups;
  html: {
    commonLists: {
      paymentOtherEarningTypeList: Array<any>;
    };
  } = {
      commonLists: {
        paymentOtherEarningTypeList: []
      }
    };

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private codeValueService: CodeValueService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService,
    private authService: AuthService
  ) {
    super();
  }

  get isAppliedChanges$() {
    return this.inputFormGroup.get('IsApplied').valueChanges;
  }

  get IsVacationPay() {
    return this.inputFormGroup.controls.PaymentOtherEarningTypeId.value === this.phxConstants.PaymentOtherEarningType.VacationPay;
  }

  ngOnInit() {
    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        this.workOrder = workorder;
      });
    this.layoutType = PhxFormControlLayoutType;
    this.html.commonLists.paymentOtherEarningTypeList = this.codeValueService.getCodeValues(this.codeValueGroups.PaymentOtherEarningType, true);
    this.setupFormGroupListeners();
  }

  setupFormGroupListeners() {
    this.isAppliedChanges$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(valueOfIsApplied => {

        this.inputFormGroup.get('IsAccrued').setValidators(
          valueOfIsApplied && this.inputFormGroup.get('PaymentOtherEarningTypeId').value === PhxConstants.PaymentOtherEarningType.VacationPay
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'IsAccrued', [
              Validators.required
            ])
            : null
        );

        this.inputFormGroup.get('RatePercentage').setValidators(
          valueOfIsApplied
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'RatePercentage', [
              Validators.required
            ])
            : null
        );

        this.paymentOtherEarningIsAppliedOnChange(valueOfIsApplied);
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  setIsAccrued() {
    if (this.inputFormGroup.get('PaymentOtherEarningTypeId').value === this.phxConstants.PaymentOtherEarningType.VacationPay) {
      this.inputFormGroup.get('IsAccrued').patchValue(this.inputFormGroup.get('IsApplied').value ? true : null);
    }
  }

  paymentOtherEarningIsAppliedOnChange(isApplied: boolean) {
    this.setIsAccrued();
    this.inputFormGroup.get('RatePercentage').patchValue(null);
    if (isApplied) {
      if (this.workOrder.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerTemp) {
        if (this.inputFormGroup.parent.value.length !== 0) {
          const worker = this.getWorker();
          if (worker.OrganizationId === null && this.workOrder.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerTemp) {
            const findPaymentOtherEarning = find(worker.UserProfileWorkerOtherEarnings, eachPaymentOtherEarnings => {
              return eachPaymentOtherEarnings.PaymentOtherEarningTypeId === this.inputFormGroup.get('PaymentOtherEarningTypeId').value;
            });

            if (findPaymentOtherEarning) {
              this.inputFormGroup.get('RatePercentage').patchValue(findPaymentOtherEarning.RatePercentage);
            }

            if (this.inputFormGroup.get('RatePercentage').value === null) {
              this.inputFormGroup.get('RatePercentage').patchValue(this.phxConstants.PaymentOtherEarningTypeVacationPayRatePercentageDefault);
            }
          }
        }
      }
    } else {
      if (this.inputFormGroup.get('PaymentOtherEarningTypeId').value === this.phxConstants.PaymentOtherEarningType.VacationPay) {
        this.inputFormGroup.get('RatePercentage').patchValue(null);
      }
    }
  }

  getWorker() {
    const earningsAndDeduction = this.earningsDeductionsTabFormService.formGroup as FormGroup<ITabEarningsAndDeductions>;
    let worker = null;
    if (this.workOrder.UserProfileIdWorker > 0) {
      worker = find(this.listUserProfileWorker, w => {
        return w.Id === this.workOrder.UserProfileIdWorker;
      });
      if (typeof worker !== 'undefined') {
        earningsAndDeduction.get('WorkerProfileTypeId').patchValue(worker.ProfileTypeId);
        earningsAndDeduction.get('WorkerContactId').patchValue(worker.ContactId);
      } else {
        this.phxDialogComponentConfigModel = {
          HeaderTitle: 'Wrong Worker User Profile',
          BodyMessage: 'Worker User Profile with id "' + this.workOrder.UserProfileIdWorker + '" is broken or does not exists',
          Buttons: [
            {
              Id: 1,
              SortOrder: 1,
              CheckValidation: true,
              Name: 'Ok',
              Class: 'btn-primary',
              ClickEvent: () => {
                this.dialogActionCallBackObButtonClick();
              }
            }
          ],
          ObjectDate: null,
          ObjectComment: null
        };
        this.phxDialogComponent.open();
        earningsAndDeduction.get('WorkerProfileTypeId').patchValue(null);
        worker = null;
      }
    } else {
      earningsAndDeduction.get('WorkerProfileTypeId').patchValue(null);
      earningsAndDeduction.get('WorkerContactId').patchValue(null);
    }
    return worker;
  }

  dialogActionCallBackObButtonClick() {
    this.phxDialogComponent.close();
  }
}
