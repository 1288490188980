import {Observable, of} from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, LoadingSpinnerService } from '../../common';
import { CommandResponse } from '../../common/model';
import { ISalesTax } from '../models/people.interface';
import { IProfile } from '../models/profile.interface';

@Injectable()
export class ProfileService {
  private profiles: Array<{ Id: number, Profile: IProfile }> = [];

  constructor(
    private apiService: ApiService,
    private loadingSpinnerService: LoadingSpinnerService
  ) {
  }
  get(id) {
    return new Observable<IProfile>(observer => {
      const res = this.profiles.find(x => x.Id === id);
      if (res) {
        observer.next(res.Profile);
        observer.complete();
      } else {
        this.apiService.query<IProfile>('UserProfile/' + id)
          .subscribe({
            next: profile => {
              this.profiles.push({ Id: id, Profile: profile });
              observer.next(profile);
              observer.complete();
            },
            error: error => {
              observer.error(error);
            }
          });
      }
    });
  }

  getSalesTaxList() {
    const oDataParams =
      oreq
        .request()
        .withSelect([
          'Id',
          'Code',
          'DisplayName'])
        .url();
    return this.apiService.query<ISalesTax[]>('Payroll/getAllSalesTaxes/byActiveVersions/' + false + '?' + oDataParams);
  }

  userProfileSetPrimary(contactId, profileId) {
    return of(this.apiService.command('UserProfileSetPrimary', { WorkflowPendingTaskId: -1, ContactId: contactId, ProfileId: profileId }));
  }

  sendInvitation(invitationPayload: {
    EntityIds: number[],
    PrimaryEmail: string,
    ProfileTypeId: number,
    ProfileId: number,
    FirstName: string,
    CultureId: number,
    UIVersion: 2
  }) {
    return this.apiService.command('ContactInvite', invitationPayload);
  }

  public executeProfileCommand(commandName: string, workflowComments: string, payload: any): Promise<CommandResponse> {
    if (workflowComments && workflowComments !== '') {
      payload.Comment = workflowComments;
    }

    // show extra spinner to prevent flickering between executing action and query
    this.loadingSpinnerService.show();

    return new Promise((resolve, reject) => {
      this.apiService
        .command(commandName, payload)
        .then(r => {
          this.loadingSpinnerService.hideAll();
          if (!r.IsValid) {
            reject(r);
            return;
          } else {
            resolve(r);
          }
        })
        .catch(ex => {
          this.loadingSpinnerService.hideAll();
          reject(ex);
        });
    });
  }

  getAdvancesCount(profileId: number) {
    return this.apiService.query<number>(`UserProfile/${profileId}/advancesCount`);
  }

  getGarnisheeCount(profileId: number) {
    return this.apiService.query<number>(`UserProfile/${profileId}/garnisheeCount`);
  }

  isUserProfileMapActivated(contactId: number) {
    return this.apiService.query<boolean>(`UserProfile/isUserProfileMapActivated/${contactId}`);
  }

  isUserProfileMapMigrated(contactId: number) {
    return this.apiService.query<boolean>(`UserProfile/isUserProfileMapMigrated/${contactId}`);
  }

}
