import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { IBillingRate, IBillingRatesDetails, IClientDiscount, IReadOnlyStorage } from '../../models';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { PhxConstants, PhxFormControlLayoutType } from '../../../common/model';
import { PartyBillingInfoFormService } from '../../services';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-client-discount',
  templateUrl: './workorder-client-discount.component.html',
  styleUrls: ['./workorder-client-discount.component.less']
})

export class WorkorderClientDiscountComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() inputFormGroup: FormGroup<IClientDiscount>;
  @Input() billingRateDetails: FormGroup<IBillingRatesDetails>;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  effectiveBillRate: number;

  phxFormControlLayoutType = PhxFormControlLayoutType;

  private subscription$: Subscription;

  constructor(
    private billingInfoFormService: PartyBillingInfoFormService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    if (this.inputFormGroup?.get('HasClientDiscount').value) {
      this.calculateEffectiveBillRate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.billingRateDetails && this.inputFormGroup.get('HasClientDiscount').value) {
      this.calculateEffectiveBillRate();
    }

    if (changes.inputFormGroup) {
      this.setupFormGroupListeners();
    }
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('HasClientDiscount').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        this.billingInfoFormService.setClientDiscountValidation(
          this.inputFormGroup,
          value
        );

        if (!value) {
          this.patchValue({
            HasClientPercentDiscount: null,
            HasClientPerHourDiscount: null,
            HasClientFlatDiscountAmount: null,
            ClientPercentDiscount: null,
            ClientPerHourDiscount: null,
            ClientFlatDiscountAmount: null,
            ClientDiscountDescription: ''
          });
          this.effectiveBillRate = null;
        }

        this.calculateEffectiveBillRate();
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('HasClientPercentDiscount').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        if (!value) {
          this.patchValue({
            ClientPercentDiscount: null
          });
        }
        this.calculateEffectiveBillRate();
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('HasClientPerHourDiscount').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        if (!value) {
          this.patchValue({
            ClientPerHourDiscount: null
          });
        }
        this.calculateEffectiveBillRate();
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('HasClientFlatDiscountAmount').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        if (!value) {
          this.patchValue({
            ClientFlatDiscountAmount: null
          });
        }
        this.calculateEffectiveBillRate();
      })
    );

    this.subscription$.add(
      merge(
        this.inputFormGroup.get('ClientPercentDiscount').valueChanges,
        this.inputFormGroup.get('ClientPerHourDiscount').valueChanges,
        this.inputFormGroup.get('ClientFlatDiscountAmount').valueChanges
      ).pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(() => {
        this.calculateEffectiveBillRate();
      })
    );

    const billingRates = this.billingRateDetails.get('BillingRates') as FormArray<IBillingRate>;

    if (billingRates) {
      const billingRate = billingRates.at(0);
      if (billingRate) {
        this.subscription$.add(
          billingRate.get('Rate').valueChanges.pipe(
            filter(() => this.readOnlyStorage.IsEditable),
            debounceTime(100),
            distinctUntilChanged(),
            takeUntil(this.isDestroyed$)
          ).subscribe(() => this.calculateEffectiveBillRate())
        );
      }
    }

  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  calculateEffectiveBillRate(): void {
    let percentDiscount = this.inputFormGroup.get('ClientPercentDiscount').value;
    let perHourDiscount = this.inputFormGroup.get('ClientPerHourDiscount').value;

    const primaryRate: number = +this.billingRateDetails.value.BillingRates[0].Rate;
    if (percentDiscount == null) {
      percentDiscount = 0;
    }
    if (perHourDiscount == null) {
      perHourDiscount = 0;
    }
    this.effectiveBillRate = (primaryRate - (primaryRate * percentDiscount / 100) - perHourDiscount);

    if (this.effectiveBillRate < 0) {
      this.inputFormGroup.get('DiscountOptions').setValidators(Validators.required);
      this.inputFormGroup.get('DiscountOptions').updateValueAndValidity();
    } else {
      this.billingInfoFormService.setClientDiscountValidation(
        this.inputFormGroup,
        this.inputFormGroup.get('HasClientDiscount').value
      );
    }

  }

  private patchValue(value: Partial<IClientDiscount>, emitEvent = false) {
    this.inputFormGroup.patchValue(value, { emitEvent });
  }

}
