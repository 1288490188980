<app-phx-select-box
  [items]="items"
  [value]="value"
  [showClearButton]="showClearButton"
  [readOnly]="readOnly"
  [disabled]="disabled"
  [width]="width"
  [valueField]="valueField"
  [searchable]="isSearchable"
  [searchField]="searchField"
  (valueChanged)="onValueChanged($event)"
>
  <div *dxTemplate="let data of 'item-template'">
    {{ data[textField] }}
    <span
      class="badge"
      [ngClass]="badgeCssClass"
      >{{ data[badgeField] }}</span
    >
  </div>
  <div *dxTemplate="let data of 'field-template'">
    <div class="text-field-container">
      <!-- The text box serves to search and display value for searchable case only or to display the placeholder. -->
      <dx-text-box
        [value]="data?.[textField]"
        [readOnly]="!isSearchable"
        [placeholder]="placeholder"
        (onInput)="hasAlteredInput = true"
        (onBlur)="hasAlteredInput = !data?.[textField]"
        [buttons]="buttons"
      >
      </dx-text-box>
      <div
        *ngIf="data"
        class="padded-badge"
      >
        <!-- This element serves as a left span, in order to properly position the badge. -->
        <span
          class="badge-padding-element"
          [class.has-icon-padding]="isSearchable"
          >{{ data[textField] }}</span
        >

        <span
          *ngIf="!hasAlteredInput"
          class="badge"
          [ngClass]="badgeCssClass"
          >{{ data[badgeField] }}</span
        >
      </div>
    </div>
  </div>
</app-phx-select-box>
