// Angular
import { Component, Input, OnInit } from '@angular/core';
import { PhxConstants } from 'src/app/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
// Common
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
// Work order
import { IReadOnlyStorage, IStatutoryHoliday } from '../../models';
import { StatutoryHolidayFormService } from '../../services';

@Component({
  selector: 'app-workorder-tab-earnings-deduction-statutory-holiday',
  templateUrl: './workorder-tab-earnings-deduction-statutory-holiday.component.html'
})
export class WorkorderTabEarningsAndDeductionStatutoryHolidayComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  formGroup: FormGroup<IStatutoryHoliday>;

  constructor(
    private statutoryHolidayFormService: StatutoryHolidayFormService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.statutoryHolidayFormService.formGroup;
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }
}
