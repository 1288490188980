import {Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Validators } from '@angular/forms';

import moment from 'moment';

import { PhxConstants, ValidationExtensions } from '@common';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import { PhxButton, PhxFormControlLayoutType, CodeValueGroups, PhxDocument } from '@common/model';
import { FormGroup, FormBuilder } from '@common/ngx-strongly-typed-forms/model';

import { IOffboardingTask } from '../../models';

export interface IWorkOrderOffboardingExemptForm {
  OffboardingTaskTypeId: number;
  ExemptionReason: string;
}

@Component({
  selector: 'app-workorder-offboarding-exempttask-modal',
  templateUrl: './workorder-offboarding-exempttask-modal.component.html',
  styleUrls: ['./workorder-offboarding-exempttask-modal.component.less']
})
export class WorkorderOffboardingExempttaskModalComponent implements OnInit {
  phxConstants: typeof PhxConstants;
  codeValueGroups: typeof CodeValueGroups;
  @ViewChild(PhxModalComponent) modal: PhxModalComponent;

  @Output() actionClick = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<WorkorderOffboardingExempttaskModalComponent> = new EventEmitter();
  form: FormGroup<IWorkOrderOffboardingExemptForm>;
  layoutType = PhxFormControlLayoutType.Stacked;
  validationMessages: any;
  // action: StateAction;
  entity: IOffboardingTask;
  savedDocuments: Array<PhxDocument>;
  title: string;
  detail: string;
  isEditable: boolean;
  buttons: PhxButton[];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.phxConstants = PhxConstants;
    this.codeValueGroups = CodeValueGroups;
  }

  ngOnInit() {
    this.buildForm();
  }

  open(entity: IOffboardingTask, buttons: PhxButton[], isEditable: boolean = true) {
    this.buttons = buttons;
    this.entity = entity;
    this.detail = '';
    if (entity.exemption) {
      this.detail = 'Exempted by ' + this.entity.LastStateHistory.CreatedByFullName + ' at ' + moment(this.entity.LastStateHistory.CreatedDatetime).format('MMM DD YYYY HH:mm');
    }
    this.title = this.entity.exemption ? 'View Exemption' : 'Exempt Task';
    this.form.markAsPristine();
    this.fillForm(entity, isEditable);
    this.isEditable = isEditable;
    this.cdr.detectChanges();
    this.modal.show();
  }

  closed() {
    this.closeModal.emit(this);
  }

  private buildForm() {
    this.form = this.fb.group<IWorkOrderOffboardingExemptForm>({
      OffboardingTaskTypeId: [null, []],
      ExemptionReason: [null, [Validators.required, ValidationExtensions.minLength(10, 'Minimum of 10 characters required')]]
    });
  }

  private fillForm(entity: IOffboardingTask, isEditable: boolean) {
    this.form.patchValue({
      ExemptionReason: entity.ExemptionReason,
      OffboardingTaskTypeId: entity.OffboardingTaskId
    });
    if (isEditable) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  hasChanges() {
    if (this.form.get('ExemptionReason').value !== this.entity.ExemptionReason) {
      return true;
    }
    return false;
  }

  public isValidForm() {
    this.form.updateValueAndValidity();
    const excemptionReasonControl = this.form.get('ExemptionReason');
    excemptionReasonControl.updateValueAndValidity();
    if (!excemptionReasonControl.valid) {
      excemptionReasonControl.markAsDirty();
    }
    return this.form.valid;
  }

  public getPayload(): any {
    return {
      ...this.form.value,
      Id: this.entity.Id
    };
  }
}
