import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IDocument, IOverrideTimesheetException } from '../../../common/model/override-timesheet-exception';
import { IOverrideTimesheetExceptions, IWorkOrder } from 'src/app/workorder/models';


@Injectable({
  providedIn: 'root'
})
export class TimesheetExceptionsFormService {
  formGroup: FormGroup<IOverrideTimesheetExceptions>;

  constructor(
    private fb: FormBuilder,
  ) { }

  createTimesheetExceptionsFormGroup(overrideTimesheetExceptions: Array<IOverrideTimesheetException>): FormGroup<IOverrideTimesheetExceptions> {
    if (overrideTimesheetExceptions.length == 0) {
      overrideTimesheetExceptions = [
        {
          ExceptionCode: null,
          Document: {
            FileStorageId: null,
            FileName: null
          }
        } as IOverrideTimesheetException
      ];
      return this.formGroup = this.fb.group<IOverrideTimesheetExceptions>({
        OverrideTimesheetExceptions: this.createTimesheetExceptionFormArray(overrideTimesheetExceptions)
      });
    }
    else {
      return this.formGroup = this.fb.group<IOverrideTimesheetExceptions>({
        OverrideTimesheetExceptions: this.createTimesheetExceptionFormArray(overrideTimesheetExceptions)
      });
    }
  }

  private createTimesheetExceptionFormArray(overrideTimesheetExceptions: Array<IOverrideTimesheetException>): FormArray<IOverrideTimesheetException> {
    return this.fb.array<IOverrideTimesheetException>(
      overrideTimesheetExceptions.map((overrideTimesheetException: IOverrideTimesheetException) => this.createSingleOverrideTimesheetExceptionFormGroup(overrideTimesheetException))
    );
  }

  private createSingleOverrideTimesheetExceptionFormGroup(overrideTimesheetException: IOverrideTimesheetException): FormGroup<IOverrideTimesheetException> {
    return this.fb.group<IOverrideTimesheetException>({
      ExceptionCode: [overrideTimesheetException.ExceptionCode],
      Reason: [overrideTimesheetException.Reason],
      DateTimeUtc: [overrideTimesheetException.DateTimeUtc],
      Name: [overrideTimesheetException.Name],
      UserProfileId: [overrideTimesheetException.UserProfileId],
      IsSelected: [overrideTimesheetException.IsSelected],
      Document: this.fb.group<IDocument>({
        FileStorageId: [overrideTimesheetException.Document.FileStorageId],
        FileName: [overrideTimesheetException.Document.FileName]
      })
    });
  }

  formGroupToPartial(workOrder: IWorkOrder, overrideFormGroup: FormGroup<IOverrideTimesheetExceptions>): IWorkOrder {
    const overrideTimesheetExceptions: Array<IOverrideTimesheetException> = overrideFormGroup.value.OverrideTimesheetExceptions.filter(x => x.Reason !== null && x.Document.FileStorageId !== null);
    workOrder.WorkOrderVersion.OverrideTimesheetExceptions = overrideTimesheetExceptions;
    return workOrder;
  }

  updateOverrideTimesheetExceptionFormArray(
    formArray: FormArray<IOverrideTimesheetException>,
    overrideExceptions: Array<IOverrideTimesheetException>
  ) {

    if (formArray.length && overrideExceptions.length) {
      overrideExceptions.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IOverrideTimesheetException>;
        if (formGroup) {
          this.updateOverrideTimesheetFormGroup(formGroup, item);
        } else {
          formArray.push(this.createSingleOverrideTimesheetExceptionFormGroup(item));
        }
      });
      if (formArray.length > overrideExceptions.length) {
        this.clearArray(formArray, overrideExceptions.length);
      }
    } else if (overrideExceptions.length) {
      const array = this.createTimesheetExceptionFormArray(overrideExceptions);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }

  }

  private updateOverrideTimesheetFormGroup(
    formGroup: FormGroup<IOverrideTimesheetException>,
    timesheetException: IOverrideTimesheetException
  ) {

    formGroup.patchValue({
      ExceptionCode: timesheetException.ExceptionCode,
      Reason: timesheetException.Reason,
      DateTimeUtc: timesheetException.DateTimeUtc,
      Name: timesheetException.Name,
      UserProfileId: timesheetException.UserProfileId,
      IsSelected: timesheetException.IsSelected,
      Document: timesheetException.Document,
    }, { emitEvent: false });

  }

  private clearArray(formArray: FormArray<IOverrideTimesheetException>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

  removeTimesheetExceptionFormGroup(index: number, formGroup: FormGroup<IOverrideTimesheetExceptions>) {
    const formArray = formGroup.get('OverrideTimesheetExceptions') as FormArray<IOverrideTimesheetException>;
    formArray.removeAt(index);
  }

  addTimesheetExceptionFormGroup(formGroup: FormGroup<IOverrideTimesheetExceptions>) {
    const formArray = formGroup.get('OverrideTimesheetExceptions') as FormArray<IOverrideTimesheetException>;
    formArray.push(this.createSingleOverrideTimesheetExceptionFormGroup({ ExceptionCode: null, Document: { FileStorageId: null, FileName: null } } as IOverrideTimesheetException));
  }

}
