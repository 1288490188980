import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IPaymentRate, IReadOnlyStorage } from '../../models';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { CodeValueService, PhxConstants } from '../../../common';
import { CodeValue, CodeValueGroups, PhxFormControlLayoutType } from '../../../common/model';
import { PartyBillingInfoFormService, PartyPaymentInfoFormService, WorkOrderFormService } from '../../services';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-payment-rate',
  templateUrl: './workorder-payment-rate.component.html',
  styleUrls: ['./workorder-payment-rate.component.less']
})
export class WorkorderPaymentRateComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() inputFormGroup: FormGroup<IPaymentRate>;
  @Input() paymentInfoIndex: number;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() set currencyId(id: number) {
    this.currencyCode = this.codeValueService.getCodeValueCode(id, CodeValueGroups.Currency);
  }
  currencyCode: string;
  phxConstants = PhxConstants;
  WorksiteId: number;
  workerProfileTypeId: number;
  previousPrimaryRate: number;
  isRateNegotiationEnabled: boolean;
  layoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.InputOnly;
  codeValueGroups = CodeValueGroups;

  html: {
    rateWarningMessage: string;
    codeValueLists: {
      listCurrency: Array<CodeValue>;
      listProfileTypeList: Array<CodeValue>;
      listworkOrderRateTypes: Array<CodeValue>;
      listWorkOrderRateUnits: Array<CodeValue>;
    };
    commonLists: {
      listUserProfileWorker: Array<any>;
      listOrganizationSupplier: Array<any>;
      listavailableRebates: Array<any>;
      listavailableVmsFees: Array<any>;
    };
  } = {
    rateWarningMessage: null,
    codeValueLists: {
      listCurrency: [],
      listProfileTypeList: [],
      listworkOrderRateTypes: [],
      listWorkOrderRateUnits: []
    },
    commonLists: {
      listUserProfileWorker: [],
      listOrganizationSupplier: [],
      listavailableRebates: [],
      listavailableVmsFees: []
    }
  };

  private subscription$: Subscription;

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private codeValueService: CodeValueService,
    private paymentInfoFormService: PartyPaymentInfoFormService,
    private billingInfoFormService: PartyBillingInfoFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  ngOnInit() {
    this.workOrderFormService
      .workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe(workorder => {
        if (workorder) {
          this.WorksiteId = workorder.WorkOrderVersion.WorksiteId;
          this.workerProfileTypeId = workorder.workerProfileTypeId;

          const wov = workorder.WorkOrderVersion;

          if (workorder) {
            this.previousPrimaryRate = wov.PreviousPaymentRate;

            // Rate Negotiation warning
            this.isRateNegotiationEnabled = wov.IsRateNegotiationEnabled;
          }
        }
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormGroup) {
      this.setRateWarnings();
      this.setupFormGroupListeners();
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listProfileTypeList = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true);
    this.html.codeValueLists.listCurrency = this.codeValueService.getCodeValues(this.codeValueGroups.Currency, true);
    this.html.codeValueLists.listworkOrderRateTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RateType, true)
      .filter(n => n.id !== this.phxConstants.RateType.Stat)
      .filter(n => n.id !== this.phxConstants.RateType.Other);
    this.html.codeValueLists.listWorkOrderRateUnits = this.codeValueService.getCodeValues(this.codeValueGroups.RateUnit, true);
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('RateUnitId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        const currentRateTypeId = this.inputFormGroup.get('RateTypeId').value;

        this.paymentInfoFormService.updateRateUnitIdFormControl(value, currentRateTypeId);

        const IsApplyStatHoliday = this.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerTemp && this.inputFormGroup.controls.RateTypeId.value === this.phxConstants.RateType.Primary;
        this.inputFormGroup.controls.IsApplyStatHoliday.patchValue(IsApplyStatHoliday, { emitEvent: false });


        if (value === PhxConstants.RateUnit.Monthly || value === PhxConstants.RateUnit.Words || value === PhxConstants.RateUnit.Shift) {
          this.billingInfoFormService.updateRateUnitIdFormControl(value, currentRateTypeId);
        }
      })
    );
  }

  setRateWarnings() {
    this.html.rateWarningMessage = null;

    if (this.isRateNegotiationEnabled && this.inputFormGroup.value.RateTypeId === this.phxConstants.RateType.Primary && this.paymentInfoIndex === 0) {
      const rate = parseFloat(this.inputFormGroup.value.Rate);
      if (this.previousPrimaryRate != null && rate != null && !isNaN(rate) && this.previousPrimaryRate !== rate) {
        this.html.rateWarningMessage = 'Change in value may affect negotiation rates below';
      }
    }
  }
}
