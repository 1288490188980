import { Pipe, PipeTransform } from '@angular/core';
import { PhxLocalizationService } from '../services/phx-localization.service';

@Pipe({
    name: 'phxDisplayCurrency'
})

export class PhxDisplayCurrency implements PipeTransform {

    private isFrench = false;

    constructor(private localizationService: PhxLocalizationService) {
        /** TODO: should check for all 'french' language code variations */
        this.isFrench = localizationService.currentLang === 'fr-CA';
    }

    transform(value) {
        if (value != null && value !== undefined) {
            return this.isFrench ? `${parseFloat(value).toFixed(2).toString()} $` : `$${parseFloat(value).toFixed(2).toString()}`;
        } else {
            return '';
        }
    }
}
