<div class="content-top-right" *ngIf="workorder">
    <app-phx-panel headerTitle="Termination">
        <phx-form-control labelText="Type" [editable]="false" name="name"
            viewModeText="{{workorder.TerminationTypeId | CodeValue:codeValueGroups.TerminationReasonType:'description'}}">
            <div class="col-lg-4">
                Type
            </div>
        </phx-form-control>
    
        <div class="form-group">
            <div class="col-lg-4" style="text-align: right;">
                <label style="padding-right: 16px">
                    <div class="text">
                        Reason
                    </div>
                </label>
            </div>
            <ng-container *ngIf="workorder.TerminationReasons.length > 1; else multipleReasonTemplate">
                <div *ngFor="let tr of workorder.TerminationReasons; let i=index; first as isFirst"
                [class.col-lg-8]="isFirst" [class.col-lg-offset-4]="!isFirst" style="padding-left: 10px; padding-right: 10px">
                    {{tr.TerminationReasonId | CodeValue:codeValueGroups.TerminationReason:'description'}}
                </div>
            </ng-container>
            <ng-template #multipleReasonTemplate>
                <div class="col-lg-8" *ngFor="let tr of workorder.TerminationReasons">
                    {{tr.TerminationReasonId | CodeValue:codeValueGroups.TerminationReason:'description'}}
                </div>
            </ng-template>
        </div>

        <phx-form-control labelText="Date of Notification" [editable]="false" name="name"
            viewModeText="{{workorder.TerminationNoticeDate | date: phxConstants.DateFormat.mediumDate}}">
            <div class="col-lg-4">
                Date of Notification
            </div>
        </phx-form-control>

        <phx-form-control labelText="Last Day of Work" [editable]="false" name="name"
            viewModeText="{{workorder.TerminationDate | date: phxConstants.DateFormat.mediumDate}}">
            <div class="col-lg-4">
                Last Date of Work
            </div>
        </phx-form-control>

        <phx-form-control labelText="Additional Comments or Client Instructions" [editable]="false" name="name"
            [viewModeText]="workorder.TerminationComment">
            <div class="col-lg-4">
                Additional Comments or Client Instructions
            </div>
        </phx-form-control>
    </app-phx-panel>
</div>