<form [formGroup]="inputFormGroup">
  <ng-container formArrayName="PaymentInfoes">
    <ng-container *ngFor="let info of paymentInfoFormArray?.controls; let i = index">
      <app-phx-panel useCustomTitle="true">
        <ng-container custom-title>
          Payment Invoice
          <span *ngIf="workOrder?.IsPaymentStopped" class="warning-visual-cue"><i
              class="material-icons md-18">warning</i>Stop Payment</span>
        </ng-container>
        <ng-container [formGroupName]="i">
          <ng-container formArrayName="PaymentInvoices">
            <ng-container *ngFor="let invoice of info.get('PaymentInvoices')['controls']; let j = index; trackBy: trackByFn">
              <ng-container [formGroupName]="j">
                <ng-container *ngIf="+invoice.get('InvoiceTypeId').value === invoiceType">
                  <app-workorder-payment-invoice [index]="i" [inputFormGroup]="invoice"
                    [readOnlyStorage]="readOnlyStorage" [invoiceType]="invoiceType" [paymentInfo]="info">
                  </app-workorder-payment-invoice>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </app-phx-panel>
    </ng-container>
  </ng-container>
</form>