<app-phx-data-table 
    [configuration]="dataTableConfiguration" 
    [dataSourceUrl]="'assignment/getSearchWithDocumentCount'" 
    [dataSourceParams]='oDataParams' 
    [columns]="columns" 
    [summary]="summary"
    [exportFileName]="'workorder'" 
    [componentName]="'WorkOrderDocumentSearch'" 
    (selectionChanged)="onRowSelected($event)">
  <div *dxTemplate="let d of 'viewWorkOrderDetailsCellTemplate'">
    <div class="btn-group">
      <button
        mat-icon-button
        type="button"
        class="btn btn-xs btn-clouds btn-row-action no-padding-bottom-right"
        title="View"
        (click)="viewWorkOrderDetails(d.data)"
      >
        <mat-icon>visibility</mat-icon>
      </button>
    </div>
  </div>
</app-phx-data-table>