
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { PhxConstants } from '../../../common';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { ITabExpenseInvoice, IReadOnlyStorage, IWorkOrder } from '../../models/workorder.interface';
import { ExpenseDetailFormService, ExpenseInvoiceTabFormService, WorkOrderFormService } from '../../services';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workorder-tab-expense-invoice',
  templateUrl: './workorder-tab-expense-invoice.component.html'
})
export class WorkorderTabExpenseInvoiceComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;

  phxConstants: any;
  hideBillingPaymentInvoice = false;
  formGroup: FormGroup<ITabExpenseInvoice>;
  private subscription$: Subscription;

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private expenseInvoiceTabFormService: ExpenseInvoiceTabFormService,
    private expenseDetailFormService: ExpenseDetailFormService
  ) {
    super();
    this.phxConstants = PhxConstants;
  }

  ngOnInit() {
    this.formGroup = this.expenseInvoiceTabFormService.formGroup;

    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.setHideBillingPaymentInvoice(this.expenseDetailFormService.expenseIdFormControl.value || workorder.WorkOrderVersion.ExpenseMethodologyId);
        }
      });

    this.setupFormGroupListeners();
  }

  setHideBillingPaymentInvoice(value: number) {
    this.hideBillingPaymentInvoice = !value || value === PhxConstants.ExpenseMethodology.NoExpense;
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.expenseDetailFormService.expenseIdChange$
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(value => this.setHideBillingPaymentInvoice(value))
    );

  }

  addTimesheetBillingInvoiceNote({ infoIndex, invoiceIndex }) {
    this.expenseInvoiceTabFormService.addTimesheetBillingInvoiceNote(
      infoIndex,
      invoiceIndex
    );
  }

  removeTimesheetBillingInvoiceNote({ infoIndex, invoiceIndex, timeSheetIndex }) {
    this.expenseInvoiceTabFormService.removeTimesheetBillingInvoiceNote(
      infoIndex,
      invoiceIndex,
      timeSheetIndex
    );
  }

  addBillingRecipient({ infoIndex, invoiceIndex }) {
    this.expenseInvoiceTabFormService.addBillingRecipient(
      infoIndex,
      invoiceIndex
    );
  }

  removeBillingRecipient({ infoIndex, invoiceIndex, recipientIndex }) {
    this.expenseInvoiceTabFormService.removeBillingRecipient(
      infoIndex,
      invoiceIndex,
      recipientIndex
    );
  }
}
