import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IIncentiveCompensation, IPaymentInfo, IPaymentInfoes, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { PaymentInfoFormService } from './payment-info-form.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';

@Injectable()
export class PartyIncentiveCompensationFormService implements IFormService {

  formGroup: FormGroup<IIncentiveCompensation>;
  private isRootComponentDestroyed$: Subject<boolean>;

  get isEligibleForCommissionFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsEligibleForCommission') as FormControl<boolean>;
  }

  get isThirdPartyImportFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsThirdPartyImport') as FormControl<boolean>;
  }

  get isEligibleForCommissionChange$() {
    return this.isEligibleForCommissionFormControl.valueChanges;
  }

  get isThirdPartyImportChange$() {
    return this.isThirdPartyImportFormControl.valueChanges;
  }

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService
  ) { }

  get paymentInfoesFormGroup(): FormGroup<IPaymentInfoes> {
    return this.formGroup.get('TabPartiesAndRatesPaymentInfoes') as FormGroup<IPaymentInfoes>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const incentiveCompensation: IIncentiveCompensation = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IIncentiveCompensation>({
      IsEligibleForCommission: [incentiveCompensation.IsEligibleForCommission],
      IsEligibleForAllowance: [incentiveCompensation.IsEligibleForAllowance],
      IsThirdPartyImport: [
        incentiveCompensation.IsThirdPartyImport,
        incentiveCompensation.IsEligibleForCommission
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
            'WorkOrderVersion',
            'IsThirdPartyImport',
            Validators.required
          )
          : null
      ],
      CommissionThirdPartyWorkerReference: [
        incentiveCompensation.CommissionThirdPartyWorkerReference,
        incentiveCompensation.IsThirdPartyImport
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
            'WorkOrderVersion',
            'CommissionThirdPartyWorkerReference',
            Validators.required
          )
          : null
      ],
      IsCommissionVacation: [incentiveCompensation.IsCommissionVacation],
      TabPartiesAndRatesPaymentInfoes: this.paymentInfoFormService.createPaymentInfoesFormGroup(
        workorder.WorkOrderVersion.PaymentInfoes,
        this.setPaymentInfoesValidations(workorder),
        PhxConstants.InvoiceType.IncentiveCompensation
      )
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.isEligibleForCommissionChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {

      this.formGroup.get('IsThirdPartyImport').setValidators(
        value
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
            'WorkOrderVersion',
            'IsThirdPartyImport',
            Validators.required
          )
          : null
      );

      this.formGroup.patchValue({
        IsThirdPartyImport: this.formGroup.get('IsThirdPartyImport').value || null,
        CommissionThirdPartyWorkerReference: this.formGroup.get('CommissionThirdPartyWorkerReference').value || null
      });

    });

    this.isThirdPartyImportChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {

      this.formGroup.get('CommissionThirdPartyWorkerReference').setValidators(
        value
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
            'WorkOrderVersion',
            'CommissionThirdPartyWorkerReference',
            Validators.required
          )
          : null
      );

      this.formGroup.patchValue({
        CommissionThirdPartyWorkerReference: this.formGroup.get('CommissionThirdPartyWorkerReference').value || null
      }, {
        emitEvent: false
      });
    });

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const incentiveDetails: IIncentiveCompensation = this.formGroup.value;

    workOrder = this.paymentInfoFormService.formGroupToPartial(
      workOrder,
      this.paymentInfoesFormGroup,
      PhxConstants.InvoiceType.IncentiveCompensation
    );
    workOrder.WorkOrderVersion.IsEligibleForCommission = incentiveDetails.IsEligibleForCommission;
    workOrder.WorkOrderVersion.IsEligibleForAllowance = incentiveDetails.IsEligibleForAllowance;
    workOrder.WorkOrderVersion.IsThirdPartyImport = incentiveDetails.IsThirdPartyImport;
    workOrder.WorkOrderVersion.CommissionThirdPartyWorkerReference = incentiveDetails.CommissionThirdPartyWorkerReference;
    workOrder.WorkOrderVersion.PaymentInfoes.forEach(paymentInfo => {
      paymentInfo.IsCommissionVacation = incentiveDetails.IsCommissionVacation;
    });
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const incentiveCompensation: IIncentiveCompensation = this.mapWorkOrderToFormData(workorder);

    this.formGroup.get('IsThirdPartyImport').setValidators(
      incentiveCompensation.IsEligibleForCommission
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
          'WorkOrderVersion',
          'IsThirdPartyImport',
          Validators.required
        )
        : null
    );

    this.formGroup.get('CommissionThirdPartyWorkerReference').setValidators(
      incentiveCompensation.IsThirdPartyImport
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator(
          'WorkOrderVersion',
          'CommissionThirdPartyWorkerReference',
          Validators.required
        )
        : null
    );

    this.formGroup.patchValue({
      IsEligibleForCommission: incentiveCompensation.IsEligibleForCommission,
      IsEligibleForAllowance: incentiveCompensation.IsEligibleForAllowance,
      IsThirdPartyImport: incentiveCompensation.IsThirdPartyImport,
      CommissionThirdPartyWorkerReference: incentiveCompensation.CommissionThirdPartyWorkerReference,
      IsCommissionVacation: incentiveCompensation.IsCommissionVacation
    }, { emitEvent: false });

    const tabPartiesAndRatesPaymentInfoesFormGroup = this.formGroup.get('TabPartiesAndRatesPaymentInfoes') as FormGroup<IPaymentInfoes>;
    const paymentInfoesFormArray = tabPartiesAndRatesPaymentInfoesFormGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>;
    this.paymentInfoFormService.updatePaymentInfoFormArray(
      paymentInfoesFormArray,
      workorder.WorkOrderVersion.PaymentInfoes,
      this.setPaymentInfoesValidations(workorder),
      PhxConstants.InvoiceType.IncentiveCompensation
    );

  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): IIncentiveCompensation {
    return {
      IsEligibleForCommission: workorder.WorkOrderVersion.IsEligibleForCommission,
      IsEligibleForAllowance: workorder.WorkOrderVersion.IsEligibleForAllowance,
      IsThirdPartyImport: workorder.WorkOrderVersion.IsThirdPartyImport,
      CommissionThirdPartyWorkerReference: workorder.WorkOrderVersion.CommissionThirdPartyWorkerReference,
      IsCommissionVacation: workorder.WorkOrderVersion.PaymentInfoes[0].IsCommissionVacation,
      TabPartiesAndRatesPaymentInfoes: {
        PaymentInfoes: workorder.WorkOrderVersion.PaymentInfoes
      }
    };
  }

  private setPaymentInfoesValidations(workorder: IWorkOrder) {
    const valid = {
      isPaymentTermsNoValidation: !workorder.WorkOrderVersion.IsEligibleForCommission
    };
    return valid;
  }

}
