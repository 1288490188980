import { takeUntil } from 'rxjs/operators';
import { CodeValueGroups, CodeValueService, PhxConstants } from '../../../common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IBillingInvoice, IBillingRecipient, IReadOnlyStorage, IWorkOrder } from '../../models';
import { FormArray, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ExpenseDetailFormService, TimeMaterialInvoiceDetailFormService, WorkOrderFormService, WorkorderService } from '../../services';
import { Subscription } from 'rxjs';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-workorder-billing-invoice',
  templateUrl: './workorder-billing-invoice.component.html',
  styleUrls: ['./workorder-billing-invoice.component.less']
})
export class WorkorderBillingInvoiceComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() billingInfoIndex: number;
  @Input() billingInvoiceIndex: number;
  @Input() invoiceType: number;
  @Input() organizationClientId: number;
  @Input() inputFormGroup: FormGroup<IBillingInvoice>;
  @Input() readOnlyStorage: IReadOnlyStorage;

  @Output() addTimeSheetNote = new EventEmitter();
  @Output() removeTimeSheetNote = new EventEmitter();
  @Output() addRecipient = new EventEmitter();
  @Output() removeRecipient = new EventEmitter();

  invoiceIndex = 0;
  codeValueGroups = CodeValueGroups;
  IsTimesheetExpenseBillingVisible = false;
  isExpenseThirdPartyImport = false;

  listUserProfileInternalActiveAccountManagers$: Subscription;
  listDetailedOrganizationClients$: Subscription;

  workorder: IWorkOrder;

  html = {
    codeValueLists: {
      listPresentationStyles: [],
      listConsolidationTypes: [],
      listTransactionGenerationMethod: [],
      listBillingFrequencies: [],
      listBillingInvoiceTerms: [],
      listBillingInvoiceTemplates: [],
      listDeliveryMethods: [],
      listRecipientTypes: []
    },
    commonLists: {
      listProfilesForApproval: [],
      listOrgClientAB: []
    },
    phxConstants: PhxConstants,
    lists: {
      ReferenceContacts: []
    }
  };

  methodologyId: number;
  phxConstants = PhxConstants;
  private subscription$: Subscription;

  constructor(
    private workOrderService: WorkorderService,
    private codeValueService: CodeValueService,
    private workOrderFormService: WorkOrderFormService,
    private commonListsObservableService: CommonListsObservableService,
    private timeMaterialInvoiceDetailFormService: TimeMaterialInvoiceDetailFormService,
    private expenseDetailFormService: ExpenseDetailFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  get isUsesAlternateBillingFormControl(): FormControl<boolean> {
    return this.inputFormGroup.get('IsUsesAlternateBilling') as FormControl<boolean>;
  }

  get presentationStyleIdFormControl(): FormControl<number> {
    return this.inputFormGroup.get('BillingInvoicePresentationStyleId') as FormControl<number>;
  }

  get billingRecipientFormArray(): FormArray<IBillingRecipient> {
    return this.inputFormGroup.get('BillingRecipients') as FormArray<IBillingRecipient>;
  }

  get isUsesAlternateBillingChange$() {
    return this.isUsesAlternateBillingFormControl.valueChanges;
  }

  get presentationStyleIdChange$() {
    return this.presentationStyleIdFormControl.valueChanges;
  }

  get invoiceTypeIdValue() {
    return this.inputFormGroup.get('InvoiceTypeId').value;
  }

  get presentationStyleIdValue() {
    return this.presentationStyleIdFormControl.value;
  }

  ngOnInit() {
    // INVESTIGATE HOW THIS COMPONENT IS BEING USED
    // IF TOGGLE BY URL, THIS ASYNC CALL MIGHT NOT GET TRIGGER AGAIN WITH UPDATED VALUES
    if (this.organizationClientId) {
      this.workOrderService.getProfilesListOrganizational(this.organizationClientId)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((response) => {
          this.html.commonLists.listProfilesForApproval = response ? response.map(item => item.Data) : [];
        });

      this.getClientRoleAlternateBills();
      this.getUserProfileCommissionsList();
    }

    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.workorder = workorder;

          if (this.invoiceType === PhxConstants.InvoiceType.TimeSheet) {
            this.methodologyId = this.workorder.WorkOrderVersion.TimeSheetMethodologyId;
            this.setIsTimesheetExpenseBillingVisible(this.workorder.WorkOrderVersion.IsTimeSheetUsesProjects);
          } else if (this.invoiceType === PhxConstants.InvoiceType.Expense) {
            this.methodologyId = this.workorder.WorkOrderVersion.ExpenseMethodologyId;
            this.setIsTimesheetExpenseBillingVisible(this.workorder.WorkOrderVersion.IsExpenseUsesProjects);
            this.setIsExpenseThirdPartyImport(workorder.WorkOrderVersion.ExpenseMethodologyId);
          }
        }
      });

    this.setupFormGroupListeners();
  }

  setIsTimesheetExpenseBillingVisible(isUsingProjects: boolean) {
    if (this.invoiceType === PhxConstants.InvoiceType.TimeSheet) {
      this.IsTimesheetExpenseBillingVisible =
        (this.methodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval ||
          this.methodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval) &&
        isUsingProjects;
    } else if (this.invoiceType === PhxConstants.InvoiceType.Expense) {
      this.IsTimesheetExpenseBillingVisible =
        (this.methodologyId === PhxConstants.ExpenseMethodology.OnlineApproval ||
          this.methodologyId === PhxConstants.ExpenseMethodology.OfflineApproval) &&
        isUsingProjects;
    }
  }

  setIsExpenseThirdPartyImport(expenseMethodologyId: number) {
    this.isExpenseThirdPartyImport = expenseMethodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport;
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    if (this.invoiceType === PhxConstants.InvoiceType.TimeSheet) {
      this.subscription$.add(
        this.timeMaterialInvoiceDetailFormService.timeSheetMethodologyIdChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(methodologyId => {
            this.methodologyId = methodologyId;
            this.resetValidationOnMethodChange();
          })
      );

      this.subscription$.add(
        this.timeMaterialInvoiceDetailFormService.isTimeSheetUsesProjectsChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(value => {
            this.setIsTimesheetExpenseBillingVisible(value);
            this.updateBillingTransactionGenerationMethodFormGroup();
          })
      );
    } else if (this.invoiceType === PhxConstants.InvoiceType.Expense) {

      this.subscription$.add(
        this.expenseDetailFormService.expenseIdChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(methodologyId => {
            this.methodologyId = methodologyId;

            this.resetValidationOnMethodChange();

            this.inputFormGroup.patchValue({
              BillingInvoicePresentationStyleId: this.presentationStyleIdValue || null,
              IsUsesAlternateBilling: this.inputFormGroup.get('IsUsesAlternateBilling').value || null,
              BillingFrequencyId: this.inputFormGroup.get('BillingFrequencyId').value || null,
              BillingReferenceContactProfileId: this.inputFormGroup.get('BillingReferenceContactProfileId').value || null
            }, { emitEvent: false });

            this.setIsExpenseThirdPartyImport(methodologyId);

            if (this.inputFormGroup.get('InvoiceTypeId').value === PhxConstants.InvoiceType.Expense && this.isExpenseThirdPartyImport) {
              this.inputFormGroup.get('IsSalesTaxAppliedOnVmsImport').setValidators(
                this.methodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport && this.invoiceTypeIdValue === this.invoiceType
                  ? [Validators.required]
                  : null
              );

              this.inputFormGroup.patchValue({
                IsSalesTaxAppliedOnVmsImport: this.inputFormGroup.get('IsSalesTaxAppliedOnVmsImport').value || null
              }, { emitEvent: false });
            }
          })
      );

      this.subscription$.add(
        this.expenseDetailFormService.isExpenseUsesProjectsChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(value => {
            this.setIsTimesheetExpenseBillingVisible(value);
            this.updateBillingTransactionGenerationMethodFormGroup();
          })
      );
    }

    this.subscription$.add(
      this.isUsesAlternateBillingChange$
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(value => {
          this.inputFormGroup.get('OrganizatonClientRoleAlternateBillingId').setValidators(
            value && !(this.methodologyId === PhxConstants.ExpenseMethodology.NoExpense && this.invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && this.invoiceTypeIdValue === this.invoiceType
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'OrganizatonClientRoleAlternateBillingId', [
                Validators.required
              ])
              : null
          );

          this.inputFormGroup.patchValue({
            OrganizatonClientRoleAlternateBillingId: this.inputFormGroup.get('OrganizatonClientRoleAlternateBillingId').value || null
          }, { emitEvent: false });
        })
    );

    this.subscription$.add(
      this.presentationStyleIdChange$
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(value => {
          this.inputFormGroup.get('BillingConsolidationTypeId').setValidators(
            value === PhxConstants.BillingInvoicePresentationStyle.Consolidated &&
            !(this.methodologyId === PhxConstants.ExpenseMethodology.NoExpense &&
              this.invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) &&
            this.invoiceTypeIdValue === this.invoiceType
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingConsolidationTypeId', [
                Validators.required
              ])
              : null
          );

          this.inputFormGroup.get('BillingConsolidationTypeId').updateValueAndValidity({ emitEvent: false });
        })
    );
  }

  updateBillingTransactionGenerationMethodFormGroup(patch = true) {
    let validate = null;
    if (this.invoiceType === PhxConstants.InvoiceType.TimeSheet) {
      validate = this.IsTimesheetExpenseBillingVisible;
    } else if (this.invoiceType === PhxConstants.InvoiceType.Expense) {
      validate = this.IsTimesheetExpenseBillingVisible
        && !(this.methodologyId === PhxConstants.ExpenseMethodology.NoExpense &&
          this.invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && this.invoiceTypeIdValue === this.invoiceType;
    }
    this.inputFormGroup.get('BillingTransactionGenerationMethodId').setValidators(
      validate
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingTransactionGenerationMethodId', [
          Validators.required
        ])
        : null
    );

    if (patch) {
      this.inputFormGroup.patchValue({
        BillingTransactionGenerationMethodId: null
      }, { emitEvent: false });
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getClientRoleAlternateBills() {
    if (this.listDetailedOrganizationClients$) {
      this.listDetailedOrganizationClients$.unsubscribe();
    }
    this.listDetailedOrganizationClients$ = this.commonListsObservableService
      .getDetailedOrganizationClients$(this.organizationClientId)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        const clientOrg = response ? response.find(x => x.Id === this.organizationClientId) : null;
        const currentOrgClientRoles = clientOrg ? clientOrg.Data.OrganizationClientRoles : [];

        if (currentOrgClientRoles.length > 0) {
          this.html.commonLists.listOrgClientAB = currentOrgClientRoles[0].OrganizationClientRoleAlternateBills
            .map(a => {
              return { id: a.Id, text: a.AlternateBillLegalName, name: a.AlternateBillCode };
            });
        } else {
          this.html.commonLists.listOrgClientAB = [];
        }
      });
  }

  getUserProfileCommissionsList() {
    this.listUserProfileInternalActiveAccountManagers$?.unsubscribe();
    this.listUserProfileInternalActiveAccountManagers$ = this.commonListsObservableService.listUserProfileInternalActiveAccountManagers$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(response => {
        this.html.lists.ReferenceContacts = response ? response.map(item => item.Data) : [];
      });
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listPresentationStyles = this.codeValueService.getCodeValues(this.codeValueGroups.BillingInvoicePresentationStyle, true);
    this.html.codeValueLists.listConsolidationTypes = this.codeValueService.getCodeValues(this.codeValueGroups.BillingConsolidationType, true);
    this.html.codeValueLists.listTransactionGenerationMethod = this.codeValueService.getCodeValues(this.codeValueGroups.BillingTransactionGenerationMethod, true);
    this.html.codeValueLists.listBillingFrequencies = this.codeValueService.getCodeValues(this.codeValueGroups.BillingFrequency, true);
    this.html.codeValueLists.listBillingInvoiceTerms = this.codeValueService.getCodeValues(this.codeValueGroups.BillingInvoiceTerms, true);
    this.html.codeValueLists.listBillingInvoiceTemplates = this.codeValueService.getRelatedCodeValues(
      this.codeValueGroups.BillingInvoiceTemplate,
      this.phxConstants.EntityType.Assignment,
      this.codeValueGroups.EntityType
    );
    this.html.codeValueLists.listDeliveryMethods = this.codeValueService.getCodeValues(this.codeValueGroups.DeliveryMethod, true);
    this.html.codeValueLists.listRecipientTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RecipientType, true);
  }

  addTimesheetBillingInvoiceNote() {
    this.addTimeSheetNote.emit({
      infoIndex: this.billingInfoIndex,
      invoiceIndex: this.billingInvoiceIndex
    });
  }

  removeTimesheetBillingInvoiceNote(timeSheetIndex: number) {
    this.removeTimeSheetNote.emit({
      timeSheetIndex,
      infoIndex: this.billingInfoIndex,
      invoiceIndex: this.billingInvoiceIndex
    });
  }

  addBillingRecipient() {
    this.addRecipient.emit({
      infoIndex: this.billingInfoIndex,
      invoiceIndex: this.billingInvoiceIndex
    });
  }

  removeBillingRecipient(recipientIndex: number) {
    this.removeRecipient.emit({
      recipientIndex,
      infoIndex: this.billingInfoIndex,
      invoiceIndex: this.billingInvoiceIndex
    });
  }

  private resetValidationOnMethodChange() {

    const invoiceTypeIdValue = this.invoiceTypeIdValue;
    const isNoExpenseValid = this.invoiceType === PhxConstants.InvoiceType.Expense
      ? this.methodologyId === PhxConstants.ExpenseMethodology.NoExpense : false;

    this.isUsesAlternateBillingFormControl.setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'IsUsesAlternateBilling', [
          Validators.required
        ])
        : null
    );

    this.inputFormGroup.get('BillingFrequencyId').setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingFrequencyId', [
          Validators.required
        ])
        : null
    );

    this.inputFormGroup.get('BillingReferenceContactProfileId').setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingReferenceContactProfileId', [
          Validators.required
        ])
        : null
    );

    this.inputFormGroup.get('BillingInvoicePresentationStyleId').setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoicePresentationStyleId', [
          Validators.required
        ])
        : null
    );

    this.inputFormGroup.get('BillingInvoiceTermsId').setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTermsId', [
          Validators.required
        ])
        : null
    );

    this.inputFormGroup.get('BillingInvoiceTemplateId').setValidators(
      !(isNoExpenseValid && invoiceTypeIdValue === PhxConstants.InvoiceType.Expense) && invoiceTypeIdValue === this.invoiceType
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingInvoice', 'BillingInvoiceTemplateId', [
          Validators.required
        ])
        : null
    );

    this.updateBillingTransactionGenerationMethodFormGroup(false);

    this.presentationStyleIdFormControl.updateValueAndValidity();

    this.isUsesAlternateBillingFormControl.updateValueAndValidity();
    this.inputFormGroup.get('BillingInvoiceTermsId').updateValueAndValidity();
    this.inputFormGroup.get('BillingInvoiceTemplateId').updateValueAndValidity();
  }
}
