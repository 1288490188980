import { Component, Input, OnInit } from '@angular/core';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { AbstractControl, FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IBillingPartyInfoes, IPartiesRateDetail, IReadOnlyStorage, IRoot } from '../../models';
import { PartyBillingInfoFormService } from '../../services';

@Component({
  selector: 'app-workorder-billing-parties',
  templateUrl: './workorder-billing-parties.component.html'
})
export class WorkorderBillingPartiesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;

  formGroup: FormGroup<IBillingPartyInfoes>;

  constructor(
    private billingInfoFormService: PartyBillingInfoFormService
  ) {
    super();
  }

  get partiesRateDetailFormArray(): FormArray<IPartiesRateDetail> {
    return this.formGroup.get('PartiesRateDetails') as FormArray<IPartiesRateDetail>;
  }

  ngOnInit() {
    this.formGroup = this.billingInfoFormService.formGroup;
  }

  trackByFn(index: number, item: AbstractControl<IPartiesRateDetail>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }
}
