import { Pipe, PipeTransform } from '@angular/core';

import { CodeValue } from '../../model';

@Pipe({
  name: 'availablePaymentMethods'
})
export class AvailablePaymentMethodsPipe implements PipeTransform {
  transform(selectedPaymentTypeCode: string | null, allOptions: CodeValue[], currentPaymentMethods: Array<{ PaymentMethodTypeCode: string }>): CodeValue[] {
    const selectedCodes = new Set(currentPaymentMethods.map(({ PaymentMethodTypeCode }) => PaymentMethodTypeCode));

    return allOptions.filter(({ code }) => !selectedCodes.has(code) || code === selectedPaymentTypeCode);
  }
}
