
import { takeUntil } from 'rxjs/operators';
import { PhxFormControlLayoutType } from '../../../common/model/phx-form-control-layout-type';
// Angular
import { Component, Input, OnInit } from '@angular/core';
// Common
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
// Work order
import { IWorkOrder, IPaymentSourceDeductions, ITabEarningsAndDeductions, IReadOnlyStorage } from '../../models';
import { WorkOrderFormService } from '../../services';
import { CodeValueGroups, CodeValueService, PhxConstants } from '../../../common';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-tab-earnings-deduction-taxes',
  templateUrl: './workorder-tab-earnings-deduction-taxes.component.html'
})
export class WorkorderTabEarningsAndDeductionTaxesComponent extends BaseComponentOnDestroy implements OnInit {

  @Input() inputFormGroup: FormGroup<IPaymentSourceDeductions>;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  workOrder: IWorkOrder;
  formGroupTabEarningsAndDeductions: FormGroup<ITabEarningsAndDeductions>;
  percentageFilter: any = { from: 0, to: 100, decimalplaces: 4 };
  rateAmountFilter: any = { from: 0, to: 9999999999999.99, decimalplaces: 2 };
  layoutType = PhxFormControlLayoutType;
  codeValueGroups = CodeValueGroups;
  html: {
    lists: {
      sourceDeductionTypeList: Array<any>;
    };
  } = {
      lists: {
        sourceDeductionTypeList: []
      }
    };

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private codeValueService: CodeValueService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.html.lists.sourceDeductionTypeList = this.codeValueService.getCodeValues(this.codeValueGroups.SourceDeductionType, null);
    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        this.workOrder = workorder;
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

}
