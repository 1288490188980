<form [formGroup]="inputFormGroup">
  <phx-form-control labelText="Invoicing Type"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingInvoicePresentationStyleId')"
                    [control]="inputFormGroup.controls.BillingInvoicePresentationStyleId"
                    viewModeText="{{inputFormGroup.controls.BillingInvoicePresentationStyleId.value | CodeValue:codeValueGroups.BillingInvoicePresentationStyle }}">
    <app-phx-select-box [items]="html.codeValueLists.listPresentationStyles" textField="text" valueField="id"
                        [formControl]="inputFormGroup.controls.BillingInvoicePresentationStyleId">
    </app-phx-select-box>
  </phx-form-control>
  <ng-container *ngIf="(inputFormGroup.controls.BillingInvoicePresentationStyleId.value === phxConstants.BillingInvoicePresentationStyle.Consolidated)">
    <phx-form-control labelText="Consolidation Type"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingConsolidationTypeId')"
                      [control]="inputFormGroup.controls.BillingConsolidationTypeId"
                      viewModeText="{{inputFormGroup.controls.BillingConsolidationTypeId.value | CodeValue:codeValueGroups.BillingConsolidationType }}">
      <app-phx-select-box [items]="html.codeValueLists.listConsolidationTypes" textField="text" valueField="id"
                          [formControl]="inputFormGroup.controls.BillingConsolidationTypeId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
  <ng-container *ngIf="IsTimesheetExpenseBillingVisible">
    <phx-form-control
        labelText="{{inputFormGroup.controls.InvoiceTypeId.value === html.phxConstants.InvoiceType.Expense ? 'Expense Billing' : 'Timesheet Billing'}}"
        [control]="inputFormGroup.controls['BillingTransactionGenerationMethodId']"
        [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingTransactionGenerationMethodId')"
        viewModeText="{{inputFormGroup.controls['BillingTransactionGenerationMethodId'].value | CodeValue:codeValueGroups.BillingTransactionGenerationMethod}}">
      <label class="radio-inline">
        <input class="form-check-input" type="radio" name="TimesheetBilling + {{invoiceIndex}}"
               [value]="phxConstants.BillingTransactionGenerationMethod.OneBillingTransactionPerBillingDocument"
               [formControl]="inputFormGroup.controls.BillingTransactionGenerationMethodId">
        {{phxConstants.BillingTransactionGenerationMethod.OneBillingTransactionPerBillingDocument
          | CodeValue:codeValueGroups.BillingTransactionGenerationMethod}}
      </label>
      <label class="radio-inline" style="padding-left:10px;">
        <input class="form-check-input" type="radio" name="TimesheetBilling + {{invoiceIndex}}"
               [value]="phxConstants.BillingTransactionGenerationMethod.OneBillingTransactionPerProject"
               [formControl]="inputFormGroup.controls.BillingTransactionGenerationMethodId">
        {{phxConstants.BillingTransactionGenerationMethod.OneBillingTransactionPerProject
          | CodeValue:codeValueGroups.BillingTransactionGenerationMethod}}
      </label>
    </phx-form-control>
  </ng-container>
  <phx-form-control labelText="Have Alternate Bill" [control]="inputFormGroup.controls['IsUsesAlternateBilling']"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','IsUsesAlternateBilling')"
                    viewModeText="{{inputFormGroup.controls['IsUsesAlternateBilling'].value ? 'Yes' : 'No'}}">
    <label class="radio-inline">
      <input class="form-check-input" type="radio" name="{{invoiceIndex}}" [value]="true"
              [formControl]="inputFormGroup.controls.IsUsesAlternateBilling">
      Yes</label>
    <label class="radio-inline">
      <input class="form-check-input" type="radio" name="{{invoiceIndex}}" [value]="false"
              [formControl]="inputFormGroup.controls.IsUsesAlternateBilling">
      No</label>
  </phx-form-control>
  <ng-container *ngIf="inputFormGroup.controls.IsUsesAlternateBilling.value">
    <phx-form-control labelText="Billing Client"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','OrganizatonClientRoleAlternateBillingId')"
                      [control]="inputFormGroup.controls.OrganizatonClientRoleAlternateBillingId"
                      viewModeText="{{inputFormGroup.controls.OrganizatonClientRoleAlternateBillingId.value | lookup: html.commonLists.listOrgClientAB:'id':'text'}}">
      <app-phx-select-box [items]="html.commonLists.listOrgClientAB" textField="text" valueField="id"
                          [formControl]="inputFormGroup.controls.OrganizatonClientRoleAlternateBillingId">
      </app-phx-select-box>
    </phx-form-control>
  </ng-container>
  <phx-form-control labelText="Billing Frequency"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingFrequencyId')"
                    [control]="inputFormGroup.controls.BillingFrequencyId"
                    viewModeText="{{inputFormGroup.controls.BillingFrequencyId.value | CodeValue:codeValueGroups.BillingFrequency}}">
    <app-phx-select-box [items]="html.codeValueLists.listBillingFrequencies" textField="text" valueField="id"
                        [formControl]="inputFormGroup.controls.BillingFrequencyId">
    </app-phx-select-box>
  </phx-form-control>
  <phx-form-control labelText="Payment Terms"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingInvoiceTermsId')"
                    [control]="inputFormGroup.controls.BillingInvoiceTermsId"
                    viewModeText="{{inputFormGroup.controls.BillingInvoiceTermsId.value | CodeValue:codeValueGroups.BillingInvoiceTerms}}">
    <app-phx-select-box [items]="html.codeValueLists.listBillingInvoiceTerms" textField="text" valueField="id"
                        [formControl]="inputFormGroup.controls.BillingInvoiceTermsId">
    </app-phx-select-box>
  </phx-form-control>
  <ng-container
       *ngIf="(inputFormGroup.controls.InvoiceTypeId.value === html.phxConstants.InvoiceType.Expense && isExpenseThirdPartyImport)">
    <phx-form-control labelText="Add Bill Taxes for Expenses on Import"
                      [control]="inputFormGroup.controls.IsSalesTaxAppliedOnVmsImport"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{inputFormGroup.controls['IsSalesTaxAppliedOnVmsImport'].value ? 'Yes' : 'No'}}">
      <label class="radio-inline">
        <input class="form-check-input" name="IsSalesTaxAppliedOnVmsImport" type="radio" [value]="true"
               [formControl]="inputFormGroup.controls.IsSalesTaxAppliedOnVmsImport">Yes</label>
      <label class="radio-inline">
        <input class="form-check-input" name="IsSalesTaxAppliedOnVmsImport" type="radio" [value]="false"
               [formControl]="inputFormGroup.controls.IsSalesTaxAppliedOnVmsImport">No</label>
    </phx-form-control>
  </ng-container>
  <phx-form-control labelText="Invoice Template"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingInvoiceTemplateId')"
                    [control]="inputFormGroup.controls.BillingInvoiceTemplateId"
                    viewModeText="{{inputFormGroup.controls.BillingInvoiceTemplateId.value | CodeValue:codeValueGroups.BillingInvoiceTemplate}}">
    <app-phx-select-box [items]="html.codeValueLists.listBillingInvoiceTemplates"
                        [itemTemplateRef]="invoiceTemplateNameTemplate" itemTemplate="item" textField="text"
                        valueField="id"
                        [formControl]="inputFormGroup.controls.BillingInvoiceTemplateId">
    </app-phx-select-box>
  </phx-form-control>
  <ng-template #invoiceTemplateNameTemplate let-itemData="itemData">
    <div class="wrap-text">
      {{ itemData?.text }}
    </div>
  </ng-template>
  <phx-form-control labelText="Reference Contact"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','BillingReferenceContactProfileId')"
                    [control]="inputFormGroup.controls.BillingReferenceContactProfileId"
                    viewModeText="{{inputFormGroup.controls.BillingReferenceContactProfileId.value | lookup: html.lists.ReferenceContacts:'Id':'Contact.FullName'}}">
    <app-phx-select-box [items]="html.lists.ReferenceContacts" textField="Contact.FullName" valueField="Id"
                        [formControl]="inputFormGroup.controls.BillingReferenceContactProfileId">
    </app-phx-select-box>
  </phx-form-control>
  <div class="form-group">
    <label class="col-lg-4 control-label label-to-right">Invoice Notes</label>
    <div class="col-lg-8">
    </div>
  </div>
  <phx-form-control [control]="inputFormGroup.controls.InvoiceNote1"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','InvoiceNote1')"
                    labelText="Invoice Note 1" viewModeText="{{inputFormGroup.controls.InvoiceNote1.value}}">
    <span class="input-display-cell">
      <input formControlName="InvoiceNote1" type="text" class="form-control" maxlength="128"/>
    </span>
    <span class="input-group-addon" style="padding:3px 6px;">
      <i class="material-icons">note</i>
    </span>
  </phx-form-control>
  <ng-container *ngIf="inputFormGroup.controls.ShowBillingInfoNote2.value">
    <phx-form-control [control]="inputFormGroup.controls.InvoiceNote2"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','InvoiceNote2')"
                      labelText="Invoice Note 2" viewModeText="{{inputFormGroup.controls.InvoiceNote2.value}}">
      <div class="col-md-10 padding-0">
        <input [formControl]="inputFormGroup.controls.InvoiceNote2" type="text" class="form-control" maxlength="128"/>
      </div>
      <div class="col-xs-1 pull-right padding-0">
        <button type="button" class="btn btn-default btn-material pull-right"
                (click)="removeTimesheetBillingInvoiceNote(2)">
          <i class="material-icons">delete</i>
        </button>
      </div>
    </phx-form-control>
  </ng-container>
  <ng-container *ngIf="inputFormGroup.controls.ShowBillingInfoNote3.value">
    <phx-form-control [control]="inputFormGroup.controls.InvoiceNote3"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','InvoiceNote3')"
                      labelText="Invoice Note 3" viewModeText="{{inputFormGroup.controls.InvoiceNote3.value}}">
      <div class="col-md-10 padding-0">
        <input [formControl]="inputFormGroup.controls.InvoiceNote3" type="text" class="form-control" maxlength="128"/>
      </div>
      <div class="col-xs-1 pull-right padding-0">
        <button type="button" class="btn btn-default btn-material pull-right"
                (click)="removeTimesheetBillingInvoiceNote(3)">
          <i class="material-icons">delete</i>
        </button>
      </div>
    </phx-form-control>
  </ng-container>
  <ng-container *ngIf="inputFormGroup.controls.ShowBillingInfoNote4.value">
    <phx-form-control [control]="inputFormGroup.controls.InvoiceNote4"
                      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingInvoice','InvoiceNote4')"
                      labelText="Invoice Note 4" viewModeText="{{inputFormGroup.controls.InvoiceNote4.value}}">
      <div class="col-md-10 padding-0">
        <input [formControl]="inputFormGroup.controls.InvoiceNote4" type="text" class="form-control" maxlength="128"/>
      </div>
      <div class="col-xs-1 pull-right padding-0">
        <button type="button" class="btn btn-default btn-material pull-right"
                (click)="removeTimesheetBillingInvoiceNote(4)">
          <i class="material-icons">delete</i>
        </button>
      </div>
    </phx-form-control>
  </ng-container>
  <div class="form-group" *ngIf="!inputFormGroup.controls.ShowBillingInfoNote4.value && readOnlyStorage.IsEditable">
    <div class="col-lg-2 pull-right">
      <button type="button" class="btn btn-default btn-material pull-right add-btn-group"
              (click)="addTimesheetBillingInvoiceNote()" style="margin-top: 5px;">
        <i class="material-icons">add</i>
      </button>
    </div>
  </div>
  <div class="panel-internal">
    <div class="form-group">
      <div class="col-lg-12">
        <h4>Recipients</h4>
      </div>
    </div>

    <div
        *ngFor="let billingRecipient of billingRecipientFormArray.controls; let first = first; let recipientIndex = index">
      <div [formGroup]="billingRecipient">
        <app-workorder-billing-recipient [inputFormGroup]="billingRecipient" [readOnlyStorage]="readOnlyStorage"
                                         [recipientIndex]="recipientIndex" [invoiceType]="invoiceType"
                                         [invoiceTypeId]="inputFormGroup.controls.InvoiceTypeId.value"
                                         [organizationClientId]="organizationClientId"
                                         (removeRecipient)="removeBillingRecipient($event)">
        </app-workorder-billing-recipient>
      </div>
    </div>
    <div class="form-group" *ngIf="readOnlyStorage.IsEditable">
      <div class="col-lg-2 pull-right">
        <div class="btn-toolbar">
          <button type="button" class="btn btn-default btn-material pull-right" (click)="addBillingRecipient()">
            <i class="material-icons">add</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
