import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IReadOnlyStorage, ITabTimeMaterialInvoice } from '../../models/workorder.interface';
import { TimeMaterialInvoiceTabFormService, WorkOrderFormService } from '../../services';
import { PhxConstants } from '../../../common';
import { IOverrideTimesheetException } from 'src/app/common/model/override-timesheet-exception';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';

@Component({
  selector: 'app-workorder-tab-time-material',
  templateUrl: './workorder-tab-time-material.component.html'
})

export class WorkorderTabTimeMaterialAndInvoiceComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() readOnlyStorage: IReadOnlyStorage;

  phxConstants: typeof PhxConstants;
  IsTimesheetExpenseBillingVisible = false;
  formGroup: FormGroup<ITabTimeMaterialInvoice>;

  overrideTimesheetExceptions:IOverrideTimesheetException[] =[];
  entityType:PhxConstants.EntityType;
  entityId:number;

  constructor(private timeMaterialInvoiceTabFormService: TimeMaterialInvoiceTabFormService,
    private workOrderFormService: WorkOrderFormService) {
      super();
    this.phxConstants = PhxConstants;
  }

  ngOnInit() {
    this.formGroup = this.timeMaterialInvoiceTabFormService.formGroup;
    this.overrideTimesheetExceptions =  this.timeMaterialInvoiceTabFormService.formGroup.controls.TabMaterialTimesheetExceptions?.get('OverrideTimesheetExceptions').value;
    this.entityType = this.phxConstants.EntityType.WorkOrder;
   
    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe(workOrder => {
        this.entityId = workOrder.WorkOrderId;
      });
  }

  ngOnChanges() {
    this.overrideTimesheetExceptions =  this.timeMaterialInvoiceTabFormService.formGroup.controls.TabMaterialTimesheetExceptions?.get('OverrideTimesheetExceptions').value;
    this.entityType = this.phxConstants.EntityType.WorkOrder;
  }

  addTimesheetBillingInvoiceNote({ infoIndex, invoiceIndex }) {
    this.timeMaterialInvoiceTabFormService.addTimesheetBillingInvoiceNote(
      infoIndex,
      invoiceIndex
    );
  }

  removeTimesheetBillingInvoiceNote({ infoIndex, invoiceIndex, timeSheetIndex }) {
    this.timeMaterialInvoiceTabFormService.removeTimesheetBillingInvoiceNote(
      infoIndex,
      invoiceIndex,
      timeSheetIndex
    );
  }

  addBillingRecipient({ infoIndex, invoiceIndex }) {
    this.timeMaterialInvoiceTabFormService.addBillingRecipient(
      infoIndex,
      invoiceIndex
    );
  }

  removeBillingRecipient({ infoIndex, invoiceIndex, recipientIndex }) {
    this.timeMaterialInvoiceTabFormService.removeBillingRecipient(
      infoIndex,
      invoiceIndex,
      recipientIndex
    );
  }

  addNewTimesheetException(event) {
    this.timeMaterialInvoiceTabFormService.addNewTimesheetException();
  }

  removeTimesheetException(timesheetExceptionIndex: number){
   this.timeMaterialInvoiceTabFormService.removeTimesheetException(timesheetExceptionIndex);
  }


  getUpdatedTimesheetExceptions(value: IOverrideTimesheetException[]): void {
   // Handle the received object value from the child component here
    if (Array.isArray(value)) {
     this.formGroup.controls.TabMaterialTimesheetExceptions.get('OverrideTimesheetExceptions').patchValue(value);
    }
}
}
