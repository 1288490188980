import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lookup'
})

export class LookupPipe implements PipeTransform {
  transform(value: any, inputList: Array<any>, valueField: string = 'id', ...textField: string[]): any {
    if(textField[0] === undefined || textField[0] === null || textField[0] === ""){
      textField[0]='text';
    }
    if (value && Array.isArray(value)) {
      const result = value
        .map(value => this.format(value, inputList, valueField, textField))
        .join(', ');
      return result;
    } else {
      return this.format(value, inputList, valueField, textField);
    }
  }

  private format(value: any, inputList: Array<any>, valueField: string, textField: string[]): any {
    let result = value;
    if (value && inputList?.length && textField?.length) {
      const displayText = this.getDisplayText(inputList, valueField, value, textField);
      result = displayText
    }
    return result;
  }

  private getDisplayText(inputList: any[], valueField: string, value: any, textField: string[]) {
    const valueItem = inputList?.find(x => x[valueField] == value);
    if (valueItem) 
      return this.getDisplayTextFromTextField(textField, valueItem);
    else
      return value;
  }

  private getDisplayTextFromTextField (textField: string[], valueItem: any) {
    const textValues = textField?.map(field => {
      let subObject = valueItem
      if (subObject && field.indexOf('.') > 0) {
        const descriptionList = field.split('.');
        descriptionList.forEach(field => {
          subObject = subObject[field]
        });
      }
      else {
        subObject = valueItem[field];
      }
      return subObject
    });
    const displayText = textValues?.join('-');
    return displayText;
  }
}
