<form [formGroup]="formGroup">
  <div class="form-horizontal">

    <app-workorder-expense-detail [readOnlyStorage]="readOnlyStorage">
    </app-workorder-expense-detail>

    <div class="content-group" [hidden]="hideBillingPaymentInvoice">
      <div class="content-left">
        <app-workorder-billing-invoices [inputFormGroup]="formGroup.controls.TabExpenseInvoiceBillingInfoes"
          [invoiceType]="phxConstants.InvoiceType.Expense" [readOnlyStorage]="readOnlyStorage"
          (addRecipient)="addBillingRecipient($event)" (removeRecipient)="removeBillingRecipient($event)"
          (addTimeSheetNote)="addTimesheetBillingInvoiceNote($event)"
          (removeTimeSheetNote)="removeTimesheetBillingInvoiceNote($event)">
        </app-workorder-billing-invoices>
      </div>
      <div class="content-right">
        <app-workorder-payment-invoices [inputFormGroup]="formGroup.controls.TabExpenseInvoicePaymentInfoes"
          [invoiceType]="phxConstants.InvoiceType.Expense" [readOnlyStorage]="readOnlyStorage">
        </app-workorder-payment-invoices>
      </div>
    </div>
  </div>
</form>