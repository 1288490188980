import { Component, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import moment from 'moment';

import { CodeValueService, CommonService, ApiService, PhxConstants } from '@common';
import { PhxDialogComponentConfigModel, PhxDialogComponentEventEmitterInterface, PhxDialogComponentConfigModelButton } from '@common/components/phx-dialog/phx-dialog.component.model';
import { PhxModalComponent } from '@common/components/phx-modal/phx-modal.component';
import { IPhxModalMessage } from '@common/components/phx-modal/phx-modal.interface';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { PhxFormControlLayoutType, CodeValue, CodeValueGroups } from '@common/model';
import { FormGroup, FormBuilder } from '@common/ngx-strongly-typed-forms/model';

import { ITermination, ITerminationDates, IWorkOrder, ITerminationType, ITerminationReason } from '../../models/workorder.interface';
import { WorkorderService } from '../../services';

@Component({
  selector: 'app-workorder-terminate-modal',
  templateUrl: './workorder-terminate-modal.component.html',
  styleUrls: ['./workorder-terminate-modal.component.less']
})
export class WorkorderTerminateModalComponent extends BaseComponentOnDestroy {

  html = {
    IsCommentValid: true,
    CommentValidationMessage: 'Please enter at least 10 characters',
    IsTerminationTypeValidWhenTypeIsNoStart: true,
    TerminationTypeValidationMessageWhenTypeIsNoStart: '',
    IsWOHasSubmittedTimesheetAndIsTerminationTypeNoStartValid: true,
    WOHasSubmittedTimesheetValidationMessage: ''
  };

  modalButtons: Array<any> = [];
  formGroup: FormGroup<ITermination>;
  layoutType = PhxFormControlLayoutType;
  TerminationTypes: Array<ITerminationType> = [];
  TerminationReasons: Array<ITerminationReason> = [];
  modalTitle = '';
  workorder: IWorkOrder;
  isReviseTermination: boolean;
  userComment = '';
  modalMessages: Array<IPhxModalMessage> = [];
  isAfterSubmit = false;
  @Input() ConfigModel: PhxDialogComponentConfigModel;

  @ViewChild('modal', { static: true })
  modal: PhxModalComponent;
  TerminationReasonTypeCodes: Array<CodeValue> = [];
  TerminationReasonCodes: Array<CodeValue> = [];
  LastDayOfWorkMin: string | Date = '';
  LastDayOfWorkMax = '';
  bodyMessage = 'This action will terminate the work order and cancel any future versions';
  @Output() commandValidation: EventEmitter<any> = new EventEmitter<any>();
  public codeValueGroups: typeof CodeValueGroups = null;
  terminationReasonIds: Array<number> = [];
  isEligibleForSeverance = false;

  constructor(
    private formBuilder: FormBuilder,
    private workorderSerice: WorkorderService,
    private commonService: CommonService,
    private codeValueService: CodeValueService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.TerminationReasonTypeCodes = this.codeValueService.getCodeValues(CodeValueGroups.TerminationReasonType, true);
    this.TerminationReasonCodes = this.codeValueService.getCodeValues(CodeValueGroups.TerminationReason, true);
  }


  showModal(workorder: IWorkOrder, isReviseTermination: boolean = false) {
    this.workorder = workorder;
    this.isReviseTermination = isReviseTermination;
    this.formGroup = this.initializeFormGroup(isReviseTermination, workorder);
    this.cdr.detectChanges();
    this.modalTitle = isReviseTermination ? 'Revise Termination' : 'Terminate Work Order';
    this.workorderSerice.getTerminationTypeAndReason(workorder.workerProfileTypeId).pipe(takeUntil(this.isDestroyed$)).subscribe((params: Array<any>) => {
      this.TerminationTypes = params.map((type: { TerminationTypeId: number, TerminationReasons: Array<number>; }) => {
        return {
          TerminationTypeId: type.TerminationTypeId,
          TerminationTypeText: this.TerminationReasonTypeCodes.find(codeType => codeType.id === type.TerminationTypeId).description,
          TerminationReasons: type.TerminationReasons.map((reason: number) => {
            return {
              TerminationReasonId: reason,
              TerminationReasonText: this.TerminationReasonCodes.find(reasonCode => reasonCode.id === reason).description
            };
          })
            .sort((a, b) => {
              return (this.TerminationReasonCodes.find(c => c.id === a.TerminationReasonId).sortOrder - this.TerminationReasonCodes.find(c => c.id === b.TerminationReasonId).sortOrder);
            })
        };
      })
        .sort((a, b) => {
          return (this.TerminationReasonTypeCodes.find(c => c.id === a.TerminationTypeId).sortOrder - this.TerminationReasonTypeCodes.find(c => c.id === b.TerminationTypeId).sortOrder);
        });

      if (isReviseTermination) {
        this.TerminationReasons = this.TerminationTypes.length > 0 ? this.TerminationTypes.find(type => type.TerminationTypeId === workorder.TerminationTypeId).TerminationReasons : [];
        this.workorderSerice.getWorkOrderTerminationComment(PhxConstants.EntityType.WorkOrder, workorder.WorkOrderId).then((data: string) => {
          this.formGroup.controls.AdditionalInstruction.setValue(data);
          this.formGroup.controls.TerminationReasons.setValue(workorder.TerminationReasons.map(tr => {
            return tr.TerminationReasonId;
          }));
        });
      }
    });

    this.LastDayOfWorkMax = workorder.EndDate;
    this.LastDayOfWorkMin = this.hasLastTimeSheetsStartDate(workorder) ?
      workorder.LastSubmitedTimesheetStartDate :
      workorder.StartDate;

    this.modalButtons = [
      {
        Id: 1,
        SortOrder: 2,
        CheckValidation: true,
        Name: isReviseTermination ? 'Save' : 'Terminate',
        Class: 'btn-primary',
        ClickEvent: () => {
          this.onSubmit(workorder, isReviseTermination);
        }
      },
      {
        Id: 2,
        SortOrder: 1,
        CheckValidation: false,
        Name: 'Cancel',
        Class: 'btn-default',
        ClickEvent: () => {
          this.modal.hide();
          this.resetForm();
        }
      }
    ];

    this.modal.show();
  }
  onSubmit(workorder: IWorkOrder, isReviseTermination: boolean) {
    this.submitForm(workorder, isReviseTermination);
  }
  submitForm(workorder: IWorkOrder, isReviseTermination: boolean) {
    const emailToLegalLeadsProfileTypes = [
      PhxConstants.UserProfileType.WorkerCanadianSp,
      PhxConstants.UserProfileType.WorkerCanadianInc,
      PhxConstants.UserProfileType.WorkerUnitedStatesLLC,
      PhxConstants.UserProfileType.WorkerSubVendor
    ];
    const terminationDate = moment(this.formGroup.controls.TerminationDates.get('LastDayOfWork').value).format('YYYY-MM-DD');
    const planedNotificationDate = moment(this.formGroup.controls.TerminationDates.get('PlannedDateOfNotification').value).format('YYYY-MM-DD');
    const commandBody = {
      EntityIds: [workorder.WorkOrderId],
      Comment: this.formGroup.controls.AdditionalInstruction.value || '',
      TerminationDate: terminationDate,
      PlanedNotificationDate: planedNotificationDate,
      TerminationReasonIds: this.formGroup.controls.TerminationReasons.value,
      TerminationTypeId: this.formGroup.controls.TerminationType.value
    };
    const commandName = isReviseTermination ? 'WorkOrderReviseTermination' : 'WorkOrderTerminate';
    this.apiService.command(commandName, commandBody).then(response => {
      this.commandValidation.emit(null);
      if (!response.IsValid) {
        return;
      }
      this.commonService.logSuccess('Success: Work Order is in Pending Termination Notice.');
      if ((commandBody.TerminationTypeId === PhxConstants.TerminationType.Immediate || commandBody.TerminationTypeId === PhxConstants.TerminationType.WithCause) &&
        workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp) {
        this.commonService.logWarning('HR and Legal will be notified of this termination');

      } else if ((commandBody.TerminationTypeId === PhxConstants.TerminationType.Immediate || commandBody.TerminationTypeId === PhxConstants.TerminationType.WithCause) &&
        emailToLegalLeadsProfileTypes.includes(workorder.workerProfileTypeId)) {
        this.commonService.logWarning('Legal will be notified of this termination');

      }
      if (this.isEligibleForSeverance) {
        this.commonService.logWarning('HR will be notified as this worker may be eligible for severance');

      }
      this.workorderSerice.refreshWorkOrder(
        workorder.WorkOrderId,
        workorder.AssignmentId,
        workorder.WorkOrderVersion.Id,
        workorder.TemplateId
      );
      this.modal.hide();
      this.resetForm();
      this.isAfterSubmit = true;
    }).catch(error => {
      this.commandValidation.emit(
        {
          error,
          workorderVersionId: workorder.WorkOrderVersion.Id
        });
      this.showErrorMessages(error);
    });
  }

  private showErrorMessages(error: any) {
    const validationMessages = this.commonService.parseResponseError(error);
    if (typeof validationMessages.find(vm => vm.PropertyName === 'Transaction') !== 'undefined') {
      this.html.IsTerminationTypeValidWhenTypeIsNoStart = false;
      this.html.TerminationTypeValidationMessageWhenTypeIsNoStart = validationMessages.find(vm => vm.PropertyName === 'Transaction').Message;
      this.formGroup.controls.TerminationType.setErrors({ cannotTerminateWOwithTxnWhenTypeIsNoStart: true });
    }
    if (typeof validationMessages.find(vm => vm.PropertyName === 'SubmittedTimesheets') !== 'undefined') {
      this.html.IsWOHasSubmittedTimesheetAndIsTerminationTypeNoStartValid = false;
      this.html.WOHasSubmittedTimesheetValidationMessage = validationMessages.find(vm => vm.PropertyName === 'SubmittedTimesheets').Message;
      this.formGroup.controls.TerminationType.setErrors({ cannotTerminateWOwithSubmittedTimesheetWhenTypeIsNoStart: true });
    }
  }

  hasLastTimeSheetsStartDate(workorder: IWorkOrder) {
    return workorder.LastSubmitedTimesheetStartDate;
  }

  onLastDayOfWorkChange(value) {
    if (value) {
      this.initModalMessages();
    }
  }

  initModalMessages() {
    this.modalMessages = [];
    this.modalMessages =
      [
        {
          message: 'Please consult with HR about this termination',
          messageType: 'warning',
          showMessage: () => {
            if (this.formGroup) {
              return ((this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.Immediate ||
                this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.WithCause) &&
                (this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp ||
                  this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesW2));
            }
            return false;
          }
        },
        {
          message: 'Please consult with Legal about this termination',
          messageType: 'warning',
          showMessage: () => {
            if (this.formGroup) {
              return ((this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.Immediate ||
                this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.WithCause) &&
                (this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesLLC ||
                  this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianInc ||
                  this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp ||
                  this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerSubVendor));
            }
            return false;
          }
        },
        {
          message: 'Please consult with HR about this termination regarding severance',
          messageType: 'warning',
          showMessage: () => {
            if (this.formGroup?.controls.TerminationDates && this.workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp) {
              const lastDayOfWork = moment(this.formGroup.controls.TerminationDates.value.LastDayOfWork);
              const startDate = moment(this.workorder.AssignmentStartDate);
              if (lastDayOfWork.diff(startDate, 'years', true) >= 4.5) {
                this.isEligibleForSeverance = true;
                return true;
              }
              return false;
            }
            return false;
          }
        },
        {
          message: 'Caution: Termination date falls within the previous timesheet period',
          messageType: 'warning',
          showMessage: () => {
            if (this.formGroup?.controls.TerminationDates) {
              const lastDayOfWork = moment(this.formGroup.controls.TerminationDates.value.LastDayOfWork);
              const startDate = moment(this.workorder.LastSubmitedTimesheetStartDate);
              const endDate = moment(this.workorder.LastSubmitedTimesheetEndDate);
              if (lastDayOfWork.isBetween(startDate, endDate) || lastDayOfWork.isSame(startDate) || lastDayOfWork.isSame(endDate)) {
                return true;
              }
              return false;
            }
            return false;
          }
        }
      ];
  }

  terminationTypeSelected(event) {
    if (event.value) {
      this.initModalMessages();
      this.TerminationReasons = this.TerminationTypes.length > 0 ? this.TerminationTypes.find(type => type.TerminationTypeId === event.value).TerminationReasons : [];
      this.formGroup.controls.TerminationReasons.reset();

      const noStartTerminationTypes = [PhxConstants.TerminationType.NoStartWorkerInitiated, PhxConstants.TerminationType.NoStartClientInitiated];
      if (noStartTerminationTypes.includes(event.value)) {
        this.formGroup.controls.TerminationDates.patchValue({
          LastDayOfWork: new Date(this.workorder.StartDate.replace(/-/g, '\/'))
        });
      } else if (noStartTerminationTypes.includes(event.previousValue) && !noStartTerminationTypes.includes(event.value) &&
        this.formGroup.controls.TerminationDates.value?.LastDayOfWork &&
        !this.isAfterSubmit) {
        this.formGroup.controls.TerminationDates.patchValue({
          LastDayOfWork: null
        });
        this.formGroup.controls.TerminationDates.markAsPristine();
      }
      this.html.IsTerminationTypeValidWhenTypeIsNoStart = true;
      this.html.IsWOHasSubmittedTimesheetAndIsTerminationTypeNoStartValid = true;
      this.isAfterSubmit = false;
    } else {
      this.formGroup.controls.TerminationReasons.setValue(null);
      this.formGroup.controls.TerminationDates.setValue({
        LastDayOfWork: null,
        PlannedDateOfNotification: null
      });
      this.formGroup.controls.AdditionalInstruction.setValue(null);
      this.TerminationReasons = [];
    }
  }

  isLastDayOfWorkDisabled(): boolean {
    if (this.formGroup?.controls) {
      return this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.NoStartClientInitiated ||
        this.formGroup.controls.TerminationType.value === PhxConstants.TerminationType.NoStartWorkerInitiated;
    }
    return false;
  }

  onButtonClick(button: PhxDialogComponentConfigModelButton) {
    const callBackObj: PhxDialogComponentEventEmitterInterface = {
      buttonId: button.Id,
      config: this.ConfigModel
    };
    if (button.ClickEvent != null) {
      button.ClickEvent(callBackObj);
    }
  }

  isSubmitButtonEnabled(btn: any): boolean {
    if (btn.Id === 1) {
      return this.formGroup.valid;
    }
    return true;
  }

  onCommentChange() {
    if (this.formGroup.controls.AdditionalInstruction.value) {
      this.html.IsCommentValid = (this.formGroup.controls.AdditionalInstruction.value.length >= 10 &&
        this.formGroup.controls.AdditionalInstruction.value.length <= 8000) ? true : false;
    }
  }

  resetForm() {
    this.formGroup.reset();
    this.TerminationReasons = [];
    this.resetHtmlValidationMessages();
    this.formGroup = this.initializeFormGroup(this.isReviseTermination, this.workorder);
    this.cdr.detectChanges();
  }

  private resetHtmlValidationMessages() {
    this.html.IsTerminationTypeValidWhenTypeIsNoStart = true;
    this.html.IsCommentValid = true;
    this.html.IsWOHasSubmittedTimesheetAndIsTerminationTypeNoStartValid = true;
  }

  private initializeFormGroup(isReviseMode: boolean, workorder: IWorkOrder) {
    const termination: ITermination = {
      TerminationType: null,
      TerminationReasons: null,
      TerminationDates: {
        PlannedDateOfNotification: null,
        LastDayOfWork: null
      },
      AdditionalInstruction: null
    };
    if (isReviseMode && workorder) {
      termination.TerminationDates.PlannedDateOfNotification = new Date(workorder.TerminationNoticeDate.replace(/-/g, '\/'));
      termination.TerminationDates.LastDayOfWork = new Date(workorder.TerminationDate.replace(/-/g, '\/'));
      termination.TerminationReasons = workorder.TerminationReasons.map(tr => {
        return tr.TerminationReasonId;
      });
      termination.TerminationType = workorder.TerminationTypeId;
    }
    const form = this.formBuilder.group<ITermination>({
      TerminationType: [termination.TerminationType, [Validators.required]],
      TerminationReasons: isReviseMode ? [[...termination.TerminationReasons], [Validators.required]] : [termination.TerminationReasons, [Validators.required]],
      TerminationDates: this.formBuilder.group<ITerminationDates>({
        PlannedDateOfNotification: [termination.TerminationDates.PlannedDateOfNotification, [Validators.required]],
        LastDayOfWork: [termination.TerminationDates.LastDayOfWork, [Validators.required]]
      }),
      AdditionalInstruction: [termination.AdditionalInstruction, [Validators.required, Validators.maxLength(8000), Validators.minLength(10)]]
    });
    return form;
  }

}
