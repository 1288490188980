<br />
<app-phx-navigation-bar [navigationBarContent]="html.navigationBarContent" [url]="routerState?.url"
  *ngIf="this.html.navigationBarContent"></app-phx-navigation-bar>
<div>
  <div *ngIf="routerState?.routerPath === html.phxConstants.WorkorderNavigationName.history">
    <app-phx-change-history-fake-door></app-phx-change-history-fake-door>
  </div>
  <div *ngIf="routerState?.routerPath === html.phxConstants.WorkorderNavigationName.transaction">
    <app-workorder-tab-activity-transactions></app-workorder-tab-activity-transactions>
  </div>
  <div *ngIf="routerState?.routerPath === html.phxConstants.WorkorderNavigationName.documents">
    <app-workorder-tab-activity-documents>
    </app-workorder-tab-activity-documents>
  </div>
  <div *ngIf="routerState?.routerPath === html.phxConstants.WorkorderNavigationName.workflow">
    <app-workorder-offboarding-history [workorder]="workorder" [actualEndDate]="workorder.TerminationDate || workorder.EndDate"></app-workorder-offboarding-history>
    <app-phx-state-history [entityTypeId]="html.entityTypeId" [entityId]="html.entityId"
      [currentStatusId]="html.stateHistoryCurrentStatus"></app-phx-state-history>
  </div>
</div>
