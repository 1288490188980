<app-phx-panel headerTitle="Payment Parties">
    <form [formGroup]="formGroup">
        <div
            *ngFor="let paymentInfo of paymentPartiesRateDetailFormArray.controls; let i = index;let last = last; trackBy: trackByFn">
            <div *ngIf="paymentInfo">
                <app-workorder-payment-party [inputFormGroup]="paymentInfo" [paymentInfoIndex]="i"
                    [readOnlyStorage]="readOnlyStorage">
                </app-workorder-payment-party>
            </div>
            <hr />
            <div class="form-group">
                <div class="col-lg-10"></div>
                <div class="col-lg-2">
                    <div class="btn-toolbar">
                        <button type="button" class="btn btn-default btn-material pull-right"
                            *ngIf="last && readOnlyStorage?.IsEditable" (click)="addPaymentInfo()">
                            <i class="material-icons">add</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</app-phx-panel>
