<phx-form-control *ngIf=" readOnlyStorage.IsEditable || inputFormGroup.controls['PaySideDeductionHeaderId']?.value"
  labelText="Rate Selection {{ paySideDeductionIndex + 1 }}"
  [control]="inputFormGroup.controls.PaySideDeductionHeaderId"
  [editable]="readOnlyStorage.IsEditable"
  viewModeText="{{ inputFormGroup.controls.PaySideDeductionHeaderId.value | lookup : listPaySideDeductions : 'PaySideDeductionId' : 'Description' }}"
>
  <div class="col-md-10 no-padding-h">
    <app-phx-select-box
      [items]="
        listPaySideDeductions
          | filterSelectChoicesByUsage : paymentInfo.get('PaymentPaySideDeductions').value : inputFormGroup.value : 'PaySideDeductionHeaderId' : 'PaySideDeductionId' : 'Description'
      "
      textField="Description"
      valueField="PaySideDeductionId"
      [formControl]="inputFormGroup.controls.PaySideDeductionHeaderId"
    >
    </app-phx-select-box>
  </div>
  <div class="col-xs-1 pull-right no-padding-h">
    <button
      type="button"
      class="btn btn-default btn-material pull-right"
      (click)="removePaySideDeduction(paySideDeductionIndex)"
    >
      <i class="material-icons">delete</i>
    </button>
  </div>
</phx-form-control>
