
import { takeUntil } from 'rxjs/operators';
// Angular
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
// Common
import { AbstractControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { CodeValueService, PhxConstants } from '../../../common';
import { CodeValueGroups } from '../../../common/model';

// Work order
import { IWorkOrder, IFormGroupSetup, ISourceDeductions, IReadOnlyStorage } from '../../models';
import { EarningsDeductionsTabFormService, WorkOrderFormService } from '../../services';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { PhxModalComponent } from 'src/app/common/components/phx-modal/phx-modal.component';

@Component({
  selector: 'app-workorder-tab-earnings-deduction-source-deduction',
  templateUrl: './workorder-tab-earnings-deduction-source-deduction.component.html'
})
export class WorkorderTabEarningsAndDeductionSourceDeductionComponent extends BaseComponentOnDestroy implements OnInit {

  @Output() isUseUserProfileWorkerSourceDeductionChangeEvent = new EventEmitter<boolean>();

  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() inputFormGroup: FormGroup<ISourceDeductions>;

  @ViewChild('phxDialogComponent', { static: false })
  phxDialogComponent: any;

  @ViewChild('modal', { static: true })
  modal: PhxModalComponent;

  phxConstants = PhxConstants;
  codeValueGroups = CodeValueGroups;

  workOrder: IWorkOrder;
  formGroupSetup: IFormGroupSetup;
  phxDialogComponentConfigModel: any;

  html: {
    lists: {
      workOrderSalesTaxTerritories: Array<any>;
    };
  } = {
      lists: {
        workOrderSalesTaxTerritories: []
      }
    };
  modalFabButtons = [
    {
      icon: 'done',
      tooltip: 'Yes',
      btnType: 'primary',
      action: () => {
        this.modal.hide();
        this.onClickIsUseUserProfileWorkerSourceDeductionSuccess();
      }
    },
    {
      icon: 'library_add',
      tooltip: 'No',
      btnType: 'default',
      action: () => {
        this.modal.hide();
        this.onClickIsUseUserProfileWorkerSourceDeductionReject();
      }
    }
  ];

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private codeValueService: CodeValueService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService,
    private authService: AuthService
  ) {
    super();
  }

  get subdivisionIdSourceDeductionControl(): AbstractControl<number> {
    return this.inputFormGroup.get('SubdivisionIdSourceDeduction');
  }

  get isUseUserProfileWorkerSourceDeductionFormControl(): AbstractControl<boolean> {
    return this.inputFormGroup.get('IsUseUserProfileWorkerSourceDeduction');
  }

  get subdivisionIdSourceDeductionChange$() {
    return this.subdivisionIdSourceDeductionControl.valueChanges;
  }

  get isUseUserProfileWorkerSourceDeductionChange$() {
    return this.isUseUserProfileWorkerSourceDeductionFormControl.valueChanges;
  }

  ngOnInit() {
    this.html.lists.workOrderSalesTaxTerritories = this.codeValueService.getRelatedCodeValues(this.codeValueGroups.Subdivision, PhxConstants.Country.CA, this.codeValueGroups.Country);
    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        this.workOrder = workorder;
      });
    this.setupFormGroupListeners();
  }

  setupFormGroupListeners() {
    this.subdivisionIdSourceDeductionChange$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(value => {
        this.earningsDeductionsTabFormService.updateAccrueEmployerHealthTaxLiability(
          value === PhxConstants.Subdivision.Ontario &&
          this.workOrder.OrganizationIdInternal === 1 &&
          this.workOrder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp
        );
        let useUserProfileWorkerSourceDeduction = true;
        const userProfile = this.workOrderFormService.getWorkerProfile(this.workOrder.UserProfileIdWorker);
        // US#15234 Earnings and Deductions Default on WO when Permanent Establishment rule applies
        if (userProfile && 
            (userProfile.ProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp ||
            userProfile.ProfileTypeId === PhxConstants.UserProfileType.WorkerTemp) &&
            Boolean(userProfile.TaxSubdivisionId) &&
            userProfile.TaxSubdivisionId !== value) {
              useUserProfileWorkerSourceDeduction = false;
        }
        this.isUseUserProfileWorkerSourceDeductionFormControl.patchValue(useUserProfileWorkerSourceDeduction);
      });

    this.isUseUserProfileWorkerSourceDeductionChange$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(value => {
        this.isUseUserProfileWorkerSourceDeductionChangeEvent.emit(value);
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  onClickIsUseUserProfileWorkerSourceDeductionSuccess() {
    this.isUseUserProfileWorkerSourceDeductionFormControl.patchValue(true, { emitEvent: false });
    this.earningsDeductionsTabFormService.setPaymentInfoSourceDeductions(0);
  }

  onClickIsUseUserProfileWorkerSourceDeductionReject() {
    this.isUseUserProfileWorkerSourceDeductionFormControl.patchValue(false, { emitEvent: false });
    this.earningsDeductionsTabFormService.setPaymentInfoSourceDeductions(0);
  }
}
