import { switchMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingRatesDetails, IReadOnlyStorage, IBillingRate, IWorkOrder, IRoot } from '../../models';
import { CodeValueGroups, CodeValueService, DialogService } from '../../../common';
import { CodeValue, DialogResultType, PhxConstants } from '../../../common/model';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { PartyBillingInfoFormService, PartyPaymentInfoFormService, WorkOrderFormService } from '../../services';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';

@Component({
  selector: 'app-workorder-billing-rates',
  templateUrl: './workorder-billing-rates.component.html',
  styleUrls: ['./workorder-billing-rates.component.less']
})
export class WorkorderBillingRatesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() billingInfoIndex: number;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() inputFormGroup: FormGroup<IBillingRatesDetails>;
  @Input() currencyId: number;

  readonly codeValGroup = CodeValueGroups;
  workOrderDetails: IWorkOrder;
  workOrderRateTypes: Array<CodeValue>;
  phxConstants = PhxConstants;
  workerProfileTypeId;

  constructor(
    private codeValService: CodeValueService,
    private workOrderFormService: WorkOrderFormService,
    private billingInfoFormService: PartyBillingInfoFormService,
    private dialogService: DialogService,
    private commonListsObservableService: CommonListsObservableService,
    protected paymentInfoFormService: PartyPaymentInfoFormService
  ) {
    super();
  }

  get billingRateFormArray(): FormArray<IBillingRate> {
    return this.inputFormGroup.get('BillingRates') as FormArray<IBillingRate>;
  }

  ngOnInit() {
    this.workOrderRateTypes = this.codeValService.getCodeValues(this.codeValGroup.RateType, true);

    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$),
      switchMap(workOrder => {
        this.workOrderDetails = workOrder;

        return this.commonListsObservableService.getUserProfileWorkers$(this.workOrderDetails.UserProfileIdWorker);
      }))
      .subscribe((listUserProfileWorker: any) => {
        const worker = listUserProfileWorker.find(f => f.Id === this.workOrderDetails.UserProfileIdWorker);
        if (worker) {
          this.workerProfileTypeId = worker.ProfileTypeId;
        }
      });

  }

  trackByFn(index: number, item: AbstractControl<IBillingRate>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  onAddBillingPartyRate() {
    this.billingInfoFormService.addBillingPartyRate();
  }

  ValidToAddBillingRate(): boolean {
    const formArrayBillingRates = this.inputFormGroup.controls.BillingRates.value;
    let isValid = true;
    formArrayBillingRates.forEach(element => {
      if (element.Rate === null || element.RateTypeId === null || element.RateUnitId === null) {
        isValid = false;
      } else {
        isValid = true;
      }
    });
    return isValid;
  }

  onOvertimeExempt(overtimeIsExempt: boolean) {
    if (overtimeIsExempt) {
      const overtimeRates = [PhxConstants.RateType.Overtime, PhxConstants.RateType.DoubleTime];
      const hasOverTimeRateType = this.inputFormGroup.controls.BillingRates.value.some(f => overtimeRates.includes(f.RateTypeId));

      if (hasOverTimeRateType) {
        this.dialogService.confirmDialog('Overtime Exempt', 'This will remove all overtime and double time rates.', { size: 'md' })
          .then(button => {
            if (button === DialogResultType.Yes){
              let billingRates = this.billingInfoFormService.billingRateFormArray.value;

              overtimeRates.forEach(rateTypeId => {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let idx = 0; idx < billingRates.length; idx += 1) {
                  if (billingRates[idx].RateTypeId === rateTypeId) {
                    this.billingInfoFormService.deleteBillingPartyRate(idx);
                    break;
                  }
                }
                billingRates = this.billingInfoFormService.billingRateFormArray.value;
              });
            } else{
              const overTimeDetails = this.paymentInfoFormService.formGroup.controls.OverTimeDetails;
              overTimeDetails.patchValue({IsOvertimeExempt:false});
            }
          });
      }
    }
  }
}
