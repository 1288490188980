import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { IPaymentInfo, IReadOnlyStorage, ITabTaxes, IWorkOrder } from '../../models';
import { FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { TaxesTabFormService, WorkOrderFormService } from '../../services';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { PhxConstants } from '../../../common';

@Component({
  selector: 'app-workorder-tab-taxes',
  templateUrl: './workorder-tab-taxes.component.html'
})
export class WorkorderTabTaxesComponent extends BaseComponentOnDestroy implements OnInit {
  formGroup: FormGroup<ITabTaxes>;
  @Input() readOnlyStorage: IReadOnlyStorage;
  editable: boolean;
  userProfileIdWorker: number;
  workerProfileTypeId: number;
  validateComplianceDraft: boolean;
  readonly workerProfilesEligibleForTaxes = new Set<PhxConstants.UserProfileType>([
    PhxConstants.UserProfileType.WorkerSubVendor,
    PhxConstants.UserProfileType.WorkerCanadianInc,
    PhxConstants.UserProfileType.WorkerCanadianSp,
    PhxConstants.UserProfileType.WorkerUnitedStatesLLC
  ]);

  constructor(private workOrderFormService: WorkOrderFormService, private taxesTabFormService: TaxesTabFormService) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.taxesTabFormService.formGroup;
    this.workOrderFormService.workOrder$.pipe(takeUntil(this.isDestroyed$)).subscribe((workorder: IWorkOrder) => {
      if (workorder) {
        this.userProfileIdWorker = workorder.UserProfileIdWorker;
        this.workerProfileTypeId = workorder.workerProfileTypeId;
        this.validateComplianceDraft = workorder.WorkOrderVersion.ValidateComplianceDraft;
        this.editable = workorder.WorkOrderVersion.IsComplianceDraftStatus;
      }
    });
  }

  get billingSalesTaxJurisdictions() {
    return this.taxesTabFormService.billingSalesTaxJurisdictions;
  }
  get paymentSalesTaxJurisdictions() {
    return this.taxesTabFormService.paymentSalesTaxJurisdictions;
  }

  get paymentInfos(): FormGroup<IPaymentInfo>[] {
    return (this.formGroup.get('PaymentInfoes') as FormArray<IPaymentInfo>).controls as FormGroup<IPaymentInfo>[];
  }
}
