import { Injectable } from '@angular/core';
import { Subject, from, of } from 'rxjs';
import { takeUntil, catchError, map, mergeMap } from 'rxjs/operators';

import { DocumentComplianceEntityInformation } from '../../model/document-compliance/document-compliance-entity-information';
import { ApiService } from '../api.service';
import { CommonService } from '../common.service';
import { PhxSideBarService } from '../phx-side-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceDocumentUpdateNotificationService {
  constructor(private apiService: ApiService, private commonService: CommonService, private phxSideBarService: PhxSideBarService) {}

  public initComplianceDocumentDataRefreshListener(
    isDestroyed$: Subject<any>,
    generateAdditionalInformation: (documentComplianceData: { complianceDocumentId: number; filePublicId: string; documentName: string }) => DocumentComplianceEntityInformation
  ): void {
    this.phxSideBarService.onRefreshDocumentComplianceRules
      .pipe(
        takeUntil(isDestroyed$),
        mergeMap(documentComplianceData => {
          const additionalInformation: DocumentComplianceEntityInformation = generateAdditionalInformation(documentComplianceData);
          const commandRequest = {
            CommandName: 'ComplianceDocumentRunRule',
            ComplianceDocumentId: documentComplianceData.complianceDocumentId,
            AdditionalInformation: JSON.stringify(additionalInformation)
          };
          const html = `
            <div class="gvlogo-for-toast">
              <div class="gv-logo-container"><img src="/assets/logos/gv-logo.svg"></div>
              <div class="text-container">Analyzing <i>${documentComplianceData.documentName}</i> document${documentComplianceData.filePublicId ? '' : 's'}</div>
            </div>
          `;
          this.commonService.logWarningNoIcon(html, true);
          return from(this.apiService.command('ComplianceDocumentRunRule', commandRequest, false)).pipe(
            map(() => ({
              documentComplianceData,
              hasError: false
            })),
            catchError(() => {
              return of({
                documentComplianceData,
                hasError: true
              });
            })
          );
        })
      )
      .subscribe({
        next: result => {
          if (result.hasError) {
            this.commonService.logError('Error updating document compliance rules');
          } else {
            this.commonService.logSuccess('Document compliance rules have been updated');
          }
          this.phxSideBarService.refreshWithRefreshedDocumentComplianceData$.next(result.documentComplianceData);
        }
      });
  }
}
