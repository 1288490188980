<div
  [formGroup]="formGroup"
  class="content-group form-horizontal"
>
  <div
    class="row"
  >
    <div class="col-md-10 col-lg-9 col-xl-8 col-xxl-7 col-xxxl-6">
      <div *ngIf="isTenantATSIntegrationEnabled">
        <phx-form-control
          [control]="formGroup.controls.ImportFromAts"
          [editable]="true"
          class="horizontal-radio-button-group"
          labelText=""
          viewModeText="Import from ATS"
        >
          <label class="radio-inline radio-inline-gap">
            <input
              [value]="true"
              class="form-check-input"
              formControlName="ImportFromAts"
              type="radio"
            />Import from ATS
          </label>
          <label class="radio-inline">
            <input
              [value]="false"
              class="form-check-input"
              formControlName="ImportFromAts"
              type="radio"
            />Create without ATS Placement
          </label>
        </phx-form-control>
      </div>

      <div *ngIf="!disableATS">

        <ng-container
          *ngIf="formGroup.controls.AtsSourceId.value > 0 && formGroup.controls.ImportFromAts.value && isTenantATSIntegrationEnabled"
        >
          <phx-form-control
            [control]="formGroup.controls.AtsSourceId"
            [editable]="true"
            labelText="ATS Placement ID"
          >
            <input
              class="form-control"
              formControlName="AtsPlacementId"
              phxFloatBetweenInput='{"from":1, "to":999999, "decimalplaces":0}'
              type="text"
            />
          </phx-form-control>
        </ng-container>

        <ng-container
          *ngIf="!(formGroup.controls.AtsSourceId.value > 0 && formGroup.controls.ImportFromAts.value && isTenantATSIntegrationEnabled)"
        >
          <phx-form-control
            [control]="formGroup.controls.OrganizationIdClient"
            [editable]="true"
            labelText="Work Order Client Company"
          >
            <app-phx-select-box
              [items]="listOrganizationClient"
              formControlName="OrganizationIdClient"
              textField="DisplayText"
              valueField="Id"
            >
            </app-phx-select-box>
          </phx-form-control>

          <phx-form-control
            [control]="formGroup.controls.LineOfBusinessId"
            labelText="Line Of Business"
            [editable]="this.allowLineOfBusinessEdit"
          >
            <app-phx-select-box
              [items]="listLineOfBusiness"
              textField="text"
              valueField="id"
              formControlName="LineOfBusinessId"
            >
            </app-phx-select-box>

            <ng-container view-mode>
              <label class="control-label">
                {{ formGroup.controls.LineOfBusinessId.value | CodeValue : codeValueGroups.LineOfBusiness }}
              </label>
            </ng-container>
          </phx-form-control>

          <phx-form-control
            [control]="formGroup.controls.TemplateId"
            [editable]="true"
            labelText="Work Order Template"
          >
            <app-phx-select-box
              [items]="listFilteredWorkOrderTemplates"
              formControlName="TemplateId"
              textField="DisplayValue"
              valueField="Id"
            >
            </app-phx-select-box>
          </phx-form-control>

          <phx-form-control
            [control]="formGroup.controls.UserProfileIdWorker"
            [editable]="true"
            labelText="Work Order User Profile Worker"
          >
            <app-phx-select-box-w-tooltip
              [items]="listUserProfileWorker"
              formControlName="UserProfileIdWorker"
              textField="DisplayValue"
              valueField="Id"
            >
              <ng-template let-item>
                <table>
                  <ng-container *ngFor="let field of tooltipFields">
                    <tr>
                      <td class="text-left prm f12c">{{ field.label }}:</td>
                      <td class="text-left f12c">{{ item[field.value] || '-' }}</td>
                    </tr>
                  </ng-container>
                </table>
              </ng-template>
            </app-phx-select-box-w-tooltip>
            <div
              *ngIf="formGroup.controls.UserProfileIdWorker.value && selectedProfileWorker"
              class="selected-profile-worker-box"
            >
              <div
                *ngIf="selectedProfileWorkerUrlLink"
                class="row"
              >
                <div class="col-lg-12">
                  <a
                    routerLink="{{ selectedProfileWorkerUrlLink }}"
                    target="_blank"
                  >
                    {{ selectedProfileWorker.DisplayValue }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 bold-thin">Email:</div>
                <div class="col-lg-9">{{ selectedProfileWorker.PrimaryEmail }}</div>
              </div>
              <div class="row">
                <div class="col-lg-3 bold-thin">Organization:</div>
                <div class="col-lg-9">{{ selectedProfileWorker.OrganizationName || '-' }}</div>
              </div>
              <div class="row">
                <div class="col-lg-3 bold-thin">Update Date:</div>
                <div class="col-lg-9">{{ selectedProfileWorker.LastModifiedDatetime }}</div>
              </div>
            </div>
          </phx-form-control>
        </ng-container>
        <div
          *ngIf="!getDataInProgress"
        >
          <div
            class="pull-right padding-10"
          >
            <button
              (click)="workOrderCreate()"
              [disabled]="!formGroup.valid"
              class="btn btn-primary"
              type="button"
            >
              {{ primaryButtonLabel }}
            </button>
          </div>
          <div
            *ngIf="checkingDuplicateWorkOrders"
            class="pull-right text-right text-primary error-box"
          >
            <strong>Looking for matching work orders...</strong>
          </div>
        </div>
        <div
          *ngIf="displayWarningMessage && duplicateWorkOrders.length > 0"
          class="duplicate-workorder-warning"
        >
          <p>We've found the following work orders with this ATS ID. Select to review, or edit to change an existing work order.</p>
        </div>
        <div
          *ngIf="displayWarningMessage && duplicateWorkOrders.length === 0"
          class="duplicate-workorder-warning"
        >
          <p>We haven't found any work order with this ATS ID.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 col-lg-9 col-xl-8 col-xxl-7 col-xxxl-6">
      <div
        *ngIf="duplicateWorkOrders.length > 0"
        class="panel-footer duplicate-workorder-footer"
      >
        <div class="duplicate-workorder-table">
          <div class="row bg-gray-strong duplicate-workorder-table-header">
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">Work Order</div>
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">Worker Name</div>
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">Status</div>
            <div class="clearfix visible-xs-block"></div>
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">Start Date</div>
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">End Date</div>
            <div class="col-xs-4 col-sm-2 duplicate-workorder-table-cell text-black">Action</div>
          </div>
          <div
            *ngFor="let workOrder of duplicateWorkOrders"
            class="row duplicate-workorder-table-row"
          >
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">
              {{ workOrder.WorkOrderFullNumber }}
            </div>
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">{{ workOrder.WorkerName }}</div>
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">{{ workOrder.WorkOrderStatus }}</div>
            <div class="clearfix visible-xs-block"></div>
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">{{ workOrder.StartDate }}</div>
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">{{ workOrder.EndDate }}</div>
            <div class="col-xs-4 col-sm-2 text-black duplicate-workorder-table-cell">
              <a
                (click)="openWorkOrder(workOrder)"
                class="duplicate-workorder-table-action"
                >Select</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-phx-validation-messages [messages]="ValidationMessages"></app-phx-validation-messages>
</div>
