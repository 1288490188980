import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationErrors, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { distinctUntilChanged, switchMap, take, takeUntil } from 'rxjs/operators';

import { FormArray, FormBuilder, FormGroup, ValidatorFn } from '../../../common/ngx-strongly-typed-forms';
import { IFormGroupSetup, IProfile, ITransactionAdjustment } from '../../../contact/models/profile.interface';
import { ContactService } from '../../../contact/services/contact.service';
import { CommonService, CustomFieldService, PhxConstants } from '../../../common';
import { HashModel } from '../../../common/utility/hash-model';
import { CodeValueGroups, CommandResponse, FunctionalRole } from '../../../common/model';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { ProfileService } from '../../../contact/services/profile.service';
import { WorkorderService } from '../../services';
import { AuthService } from '../../../common/services/auth.service';
import { AdjustmentApplicableDto, AdjustmentNewDto, ApplicableAdjustmentType, IWorkOrderVersion } from '../../models';
import { ToastService } from '../../../common/services/toast.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { combineLatest, EMPTY, forkJoin } from 'rxjs';


@Component({
  selector: 'app-workorder-create-adjustment',
  templateUrl: './workorder-create-adjustment.component.html',
  styleUrls: ['./workorder-create-adjustment.component.less']
})
export class WorkorderCreateAdjustmentComponent extends BaseComponentOnDestroy implements OnInit {

  html = {
    isPeriodEndDateBeforePeriodStartDate: false,
    PeriodEndDateBeforePeriodStartDateMessage: 'Transaction End Date cannot be before Start Date',
    isPeriodStartDateBeforeWOVStartDate: false,
    PeriodStartDateBeforeWOVStartDateMessage: 'Warning : Transaction Start Date is before Work Order Start Date',
    isPeriodEndDateAfterWOVEndDate: false,
    PeriodEndDateAfterWOVEndDateMessage: 'Warning : Transaction End Date is after Work Order End Date',
    isReleaseTypeValid: true,
    ReleaseTypeValidationMessage: '',
    isNonTempWorkerVacationPay: false
  };

  @ViewChild('ConfirmModal', { static: true }) ConfirmModal: PhxModalComponent;
  EmployeeAmountTotal: number;
  EmployerAmountTotal: number;
  ClientAmountTotal: number;
  listsWorkOrder: Array<any>;
  transactionAdjustmentForm: FormGroup<ITransactionAdjustment>;
  readonly phxConstants = PhxConstants;
  readonly codeValueGroups= CodeValueGroups;
  adjustmentList: Array<AdjustmentApplicableDto> = [];
  organizationIdInternal: number;
  workerDetails: AdjustmentNewDto;
  selectedAdjustments: Array<AdjustmentApplicableDto> = [];
  formGroupSetup: IFormGroupSetup;
  newTransactionAdjustment = {} as ITransactionAdjustment;
  validationMessages: CommandResponse;
  userProfileId: number;
  workOrderId: number;
  isWorkerProfileSpValid = true;
  isWorkerProfileIncValid = true;
  ShowSalesTaxNumberAlert = false;
  contactDetails: IProfile;
  adjustmentsOrderTracker: Record<string, number> = {};
  currentUserFunctionalRoles:  FunctionalRole[] = [];
  workorderVersion: IWorkOrderVersion;
  transactionDate: Date;
  showDateRangeWarning = false;

  private readonly sourceDeductionsApplicableToBackDate = new Set<number>([
    PhxConstants.SourceDeductionType.CPP,
    PhxConstants.SourceDeductionType.CPP2,
    PhxConstants.SourceDeductionType.EI,
    PhxConstants.SourceDeductionType.FederalTax,
    PhxConstants.SourceDeductionType.PIP,
    PhxConstants.SourceDeductionType.Provincial,
    PhxConstants.SourceDeductionType.QPP,
    PhxConstants.SourceDeductionType.QPP2
  ]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private customFieldService: CustomFieldService,
    private commonService: CommonService,
    private profileService: ProfileService,
    private router: Router,
    private location: Location,
    private workOrderService: WorkorderService,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.authService.getCurrentProfile().pipe(take(1)),
      this.activatedRoute.params
    ]).pipe(
      switchMap(([profile, params]) => {
        this.currentUserFunctionalRoles = profile.FunctionalRoles;
        this.userProfileId = +params.userProfileId;
        this.workOrderId = +params.workOrderId;

        if (!this.userProfileId) {
          return EMPTY;
        }

        return forkJoin([
          this.profileService.get(this.userProfileId),
          this.workOrderService.getWorkOrderListByWorkerProfileId(this.userProfileId)
        ]);
      }),
      takeUntil(this.isDestroyed$)
    ).subscribe(([contactDetails, workOrderList]) => {
      if (contactDetails) {
        this.contactDetails = contactDetails;
        this.listsWorkOrder = workOrderList.Items;
        this.initializeForm();
        
        if (this.workOrderId) {
          this.setWorkOrderDetails(this.workOrderId);
        }

        this.setupFormEventListeners();
      }
    });
  }

  trimFirstPart(value: string) {
    return value.replace(/^((?!Aa).)*\s-\s/, '');
  }

  addAdjustment(adjustmentName: string) {
    if (adjustmentName) {
      const filteredItem = this.adjustmentList.find(x => x.Name === adjustmentName);
      if (filteredItem.SalesTaxTypeId && !filteredItem.IsSalesTaxNumberAvailableBillSide) {
        this.toastService.showToast(`Internal Organization does not have sales tax number setup for ${filteredItem.Name}`,
          'error', 'error', 10000, null, 'center');
        return;
      }
      filteredItem.isApplied = true;
      filteredItem.EmployeeAmount = 0;
      filteredItem.EmployerAmount = 0;
      filteredItem.ClientAmount = 0;
      const formArrayOf = this.formArrayTransactionAdjustmentAmount();
      formArrayOf.push(this.getAdjustmentAmountGroup(this.formGroupSetup, {
        EmployeeAmount: 0,
        EmployerAmount: 0,
        ClientAmount: 0,
        isApplied: true,
        ApplicableAdjustmentTypeId: filteredItem.ApplicableAdjustmentTypeId,
        EmployeeYtdDeduction: filteredItem.EmployeeYtdDeduction,
        EntityId: filteredItem.EntityId,
        EntityName: filteredItem.EntityName,
        IsApplicableToClient: filteredItem.IsApplicableToClient,
        IsApplicableToEmployee: filteredItem.IsApplicableToEmployee,
        IsApplicableToEmployer: filteredItem.IsApplicableToEmployer,
        Name: filteredItem.Name,
        SalesTaxTypeId: filteredItem.SalesTaxTypeId,
        SourceDeductionTypeId: filteredItem.SourceDeductionTypeId,
        EmployerYtdDeduction: filteredItem.EmployerYtdDeduction,
        PaymentOtherEarningTypeId: filteredItem.PaymentOtherEarningTypeId,
        IsVacationPayAccured: filteredItem.IsVacationPayAccured,
        IsSalesTaxNumberAvailableBillSide: filteredItem.IsSalesTaxNumberAvailableBillSide,
        IsSalesTaxNumberAvailablePaySide: filteredItem.IsSalesTaxNumberAvailablePaySide
      }));

      this.adjustmentList = this.adjustmentList.filter(item => item.isApplied !== true);
      this.selectedAdjustments.push(filteredItem);
    }
  }

  formArrayTransactionAdjustmentAmount(): FormArray<AdjustmentApplicableDto> {
    return (this.transactionAdjustmentForm.get('Amount') as FormArray<AdjustmentApplicableDto>);
  }

  datePickerCallbackOnDoneStartDate() {
    this.html.isPeriodEndDateBeforePeriodStartDate = false;
    this.html.isPeriodStartDateBeforeWOVStartDate = false;
    this.transactionAdjustmentForm.controls.PeriodEndDate.setErrors({ invalid: null });
    this.transactionAdjustmentForm.controls.PeriodEndDate.updateValueAndValidity();
    this.transactionAdjustmentForm.controls.PeriodStartDate.setErrors({ invalid: null });
    this.transactionAdjustmentForm.controls.PeriodStartDate.updateValueAndValidity();
    if ((this.transactionAdjustmentForm.get('PeriodStartDate').value !== null && this.transactionAdjustmentForm.get('PeriodEndDate').value !== null) &&
      (this.transactionAdjustmentForm.get('PeriodStartDate').value > this.transactionAdjustmentForm.get('PeriodEndDate').value)) {
      this.html.isPeriodEndDateBeforePeriodStartDate = true;
      this.transactionAdjustmentForm.controls.PeriodStartDate.setErrors({ invalid: true });
    }
    if (this.transactionAdjustmentForm.controls.PeriodStartDate.value !== null &&
      (typeof this.workorderVersion !== 'undefined' && this.workorderVersion !== null) &&
      (this.workorderVersion.WorkOrderStartDateState !== null) &&
      (this.transactionAdjustmentForm.controls.PeriodStartDate.value < new Date(this.workorderVersion.WorkOrderStartDateState.replace(/-/g, '\/')))) {
      this.html.isPeriodStartDateBeforeWOVStartDate = true;
    }
  }

  datePickerCallbackOnDoneEndDate() {
    this.html.isPeriodEndDateBeforePeriodStartDate = false;
    this.html.isPeriodEndDateAfterWOVEndDate = false;
    this.transactionAdjustmentForm.controls.PeriodStartDate.setErrors({ invalid: null });
    this.transactionAdjustmentForm.controls.PeriodStartDate.updateValueAndValidity();
    this.transactionAdjustmentForm.controls.PeriodEndDate.setErrors({ invalid: null });
    this.transactionAdjustmentForm.controls.PeriodEndDate.updateValueAndValidity();
    if ((this.transactionAdjustmentForm.get('PeriodStartDate').value !== null && this.transactionAdjustmentForm.get('PeriodEndDate').value !== null) &&
      (this.transactionAdjustmentForm.get('PeriodStartDate').value > this.transactionAdjustmentForm.get('PeriodEndDate').value)) {
      this.html.isPeriodEndDateBeforePeriodStartDate = true;
      this.transactionAdjustmentForm.controls.PeriodEndDate.setErrors({ invalid: true });
    }
    if (this.transactionAdjustmentForm.controls.PeriodEndDate.value !== null &&
      (typeof this.workorderVersion !== 'undefined' && this.workorderVersion !== null) &&
      (this.workorderVersion.WorkOrderEndDateState !== null) &&
      (this.transactionAdjustmentForm.controls.PeriodEndDate.value > new Date(this.workorderVersion.WorkOrderEndDateState.replace(/-/g, '\/')))) {
      this.html.isPeriodEndDateAfterWOVEndDate = true;
    }
  }

  removeAdjustment(index: any, pushRemovedAdjToList: boolean = true) {
    const arrayData = this.formArrayTransactionAdjustmentAmount();
    const tempData = arrayData.at(index).value;
    tempData.isApplied = false;
    if (pushRemovedAdjToList) {
      this.adjustmentList.push(tempData);
      this.adjustmentList = this.adjustmentList.slice(0).sort((a, b) => this.adjustmentsOrderTracker[a.Name] - this.adjustmentsOrderTracker[b.Name]);
    }
    arrayData.removeAt(index);
    const indx = this.selectedAdjustments.findIndex(x => x.EntityId === tempData.EntityId && x.ApplicableAdjustmentTypeId === tempData.ApplicableAdjustmentTypeId);
    this.selectedAdjustments.splice(indx, 1);
    // reset the flag whenever user removes an adjustment
    this.ShowSalesTaxNumberAlert = false;
    this.updateTotalAmounts(false);
  }

  updateTotalAmounts(updateYTD = true) {
    this.EmployeeAmountTotal = 0;
    this.EmployerAmountTotal = 0;
    this.ClientAmountTotal = 0;

    this.formArrayTransactionAdjustmentAmount().value.forEach(value => {
      const employeeAmount = value.EmployeeAmount ? parseFloat(value.EmployeeAmount.toString()) : 0;
      const employerAmount = value.EmployerAmount ? parseFloat(value.EmployerAmount.toString()) : 0;
      const clientAmount = value.ClientAmount ? parseFloat(value.ClientAmount.toString()) : 0;
      if (value.PaymentOtherEarningTypeId !== null) {
        this.EmployeeAmountTotal += employeeAmount;
      } else {
        this.EmployeeAmountTotal -= employeeAmount;
      }
      this.EmployerAmountTotal += employerAmount;
      this.ClientAmountTotal += clientAmount;
      if (updateYTD) {
        value.NewYTDWorkerDeduction = (value.EmployeeYtdDeduction || 0) + employeeAmount;
        value.NewYTDEmployerDeduction = (value.EmployerYtdDeduction || 0) + employerAmount;
      }

      // Do not override flag once it is set to true unless removing the deduction type that set the flag to true
      if ((value.ApplicableAdjustmentTypeId === ApplicableAdjustmentType.SalesTaxType && !this.ShowSalesTaxNumberAlert)) {
        this.ShowSalesTaxNumberAlert = !value.IsSalesTaxNumberAvailablePaySide && employeeAmount !== 0;
      }
    });
  }

  onClickSubmit() {
    this.html.isNonTempWorkerVacationPay = false;

    this.workOrderService.getWorkOrderCreateAdjustmentTransactionPreCheck(this.workOrderId)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(result => {
          const workOrderStartDate = new Date(result.WorkOrderVersionStartDate);
          const workOrderEndDate = new Date(result.WorkOrderVersionEndDate);
          const transactionDate = new Date(result.CalculatedTransactionDate);

          if (transactionDate < workOrderStartDate || transactionDate > workOrderEndDate) {
            this.showDateRangeWarning = true;
          } else {
            this.showDateRangeWarning = false;
          }

          this.ConfirmModal.show();
          this.formArrayTransactionAdjustmentAmount().value.forEach(item => {
            if (this.workerDetails.WorkerProfileTypeId === this.phxConstants.UserProfileType.WorkerCanadianSp &&
                item.SourceDeductionTypeId > 0 &&
                item.SourceDeductionTypeId !== this.phxConstants.SourceDeductionType.CPP &&
                item.SourceDeductionTypeId !== this.phxConstants.SourceDeductionType.EI) {
              this.isWorkerProfileSpValid = false;
            }

            if (this.workerDetails.WorkerProfileTypeId === this.phxConstants.UserProfileType.WorkerCanadianInc &&
                item.SourceDeductionTypeId > 0) {
              this.isWorkerProfileIncValid = false;
            }

            if (this.workerDetails.WorkerProfileTypeId !== this.phxConstants.UserProfileType.WorkerTemp &&
                item.PaymentOtherEarningTypeId === this.phxConstants.PaymentOtherEarningType.VacationPay) {
              this.html.isNonTempWorkerVacationPay = true;
            }
          });
        });
  }

  finalSubmit() {
    const formValue = this.transactionAdjustmentForm.value;
    const transactionHeaderAdjustmentSubmitCommand = {
      WorkOrderVersionId: this.workerDetails.Id,
      Description: formValue.Description,
      SuppressPayment: formValue.ReleaseType,
      Adjustments: formValue.Amount,
      EntityIds: [this.workerDetails.Id],
      PeriodStartDate: formValue.PeriodStartDate,
      PeriodEndDate: formValue.PeriodEndDate,
      IsBackDate: formValue.IsBackDate,
      TransactionDate: formValue.TransactionDate
    };

    this.profileService.executeProfileCommand(PhxConstants.CommandNamesSupportedByUi.BaseContactsCommand.UserProfileCreateAdjustment, null, transactionHeaderAdjustmentSubmitCommand).then((result) => {
      this.validationMessages = null;
      this.ConfirmModal.hide();
      if (result.EntityIdRedirect) {
        this.commonService.logSuccess('Transaction Submitted');
        const navigateTo = (transactionIdNavigateTo: number, tabNavigationName: any) => {
          const navigatePath = `/next/transaction/${transactionIdNavigateTo}/${tabNavigationName}`;
          this.router.navigate([navigatePath], { relativeTo: this.activatedRoute.parent });
        };
        navigateTo(result.EntityIdRedirect, 'summary');
      }
    }).catch((result: CommandResponse) => {
      this.validationMessages = result;
      const validationMessages = this.commonService.parseResponseError(result);
      this.html.isReleaseTypeValid = !validationMessages.some(vm => vm.PropertyName === 'Release Type');
      this.html.ReleaseTypeValidationMessage = this.html.isReleaseTypeValid ? '' : validationMessages.find(vm => vm.PropertyName === 'Release Type').Message;
      this.ConfirmModal.hide();
    });
  }

  cancelModal() {
    this.ConfirmModal.hide();
  }

  cancelClick() {
    this.location.back();
  }

  private workerOrderChanged(workOrderVersionId: number) {
    if (workOrderVersionId !== null) {
      this.contactService.getAdjustmentNew(workOrderVersionId)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((result) => {
          this.setWorkerDetails(result);
          this.setSelectedAdjustments();
          this.workOrderService.getAssignment(result.AssignmentId, this.workOrderId, workOrderVersionId)
            .pipe(takeUntil(this.isDestroyed$))
            .subscribe(data => {
              this.workorderVersion = data.WorkOrders.find(wo => wo.Id === this.workOrderId).WorkOrderVersions.find(wov => wov.Id === workOrderVersionId);
              this.datePickerCallbackOnDoneStartDate();
              this.datePickerCallbackOnDoneEndDate();
              this.setTransactionDate(false, true);
            });
        });
    } else {
      this.workerDetails = null;
      this.adjustmentList = [];
      this.organizationIdInternal = null;
    }
  }

  private setupFormEventListeners() {
    this.transactionAdjustmentForm.get('IsBackDate').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$))
      .subscribe(isBackDate => {
        this.transactionAdjustmentForm.get('AdjustmentName').reset(null, { emitEvent: false });
        this.setTransactionDate(isBackDate);
      });

    this.transactionAdjustmentForm.get('AdjustmentName').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$))
      .subscribe(adjustmentName => {
        this.addAdjustment(adjustmentName);
      });

    this.transactionAdjustmentForm.get('WorkOrderVersionId').valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.isDestroyed$))
      .subscribe(workOrderVersionId => {
        this.workerOrderChanged(workOrderVersionId);
      });
  }

  private setWorkOrderDetails(workOrderId: number) {
    const workOrder = this.listsWorkOrder.find(wo => wo.WorkOrderId === workOrderId);
    const wovId = workOrder ? workOrder.WorkOrderVersionId : null;
    this.transactionAdjustmentForm.controls.WorkOrderVersionId.setValue(wovId);
    this.workerOrderChanged(wovId);
  }

  private initializeForm() {
    this.newTransactionAdjustment = {
      Description: null,
      WorkOrderVersionId: null,
      AdjustmentName: null,
      ReleaseType: null,
      Amount: [],
      PeriodStartDate: new Date(),
      PeriodEndDate: new Date(),
      IsBackDate: false,
      TransactionDate: new Date()
    };
    this.newTransactionAdjustment.PeriodStartDate.setHours(0, 0, 0, 0);
    this.newTransactionAdjustment.PeriodEndDate.setHours(0, 0, 0, 0);
    this.formGroupSetup = { hashModel: new HashModel(), toUseHashCode: true, formBuilder: this.formBuilder, customFieldService: this.customFieldService };
    this.transactionAdjustmentForm = this.formBuilderGroupSetup(this.formGroupSetup, this.newTransactionAdjustment);
  }

  private formBuilderGroupSetup(formGroupSetup: IFormGroupSetup, transactionAdjustmentForm: ITransactionAdjustment): FormGroup<ITransactionAdjustment> {
    return formGroupSetup.hashModel.getFormGroup<ITransactionAdjustment>(formGroupSetup.toUseHashCode, 'New Transaction Adjustment', transactionAdjustmentForm, 0, () =>
      formGroupSetup.formBuilder.group<ITransactionAdjustment>({
        Description: [
          transactionAdjustmentForm.Description,
          [
            Validators.required
          ]
        ],
        WorkOrderVersionId: [
          transactionAdjustmentForm.WorkOrderVersionId,
          [
            Validators.required
          ]
        ],
        AdjustmentName: [
          transactionAdjustmentForm.AdjustmentName
        ],
        ReleaseType: [
          transactionAdjustmentForm.ReleaseType,
          [
            Validators.required
          ]
        ],
        Amount: this.getAdjustmentAmountArray(this.formGroupSetup, []),
        PeriodStartDate: [
          transactionAdjustmentForm.PeriodStartDate,
          [
            Validators.required
          ]
        ],
        PeriodEndDate: [
          transactionAdjustmentForm.PeriodEndDate,
          [
            Validators.required
          ]
        ],
        IsBackDate: [
          transactionAdjustmentForm.IsBackDate
        ],
        TransactionDate: [
          transactionAdjustmentForm.TransactionDate
        ]
      }, {
        validator: this.adjustAmountValidator()
      })
    );
  }

  private adjustAmountValidator(): ValidatorFn<ITransactionAdjustment> {
    return (control: FormGroup<ITransactionAdjustment>): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      if (!(control.get('Amount') as FormArray<AdjustmentApplicableDto>).length) {
        errors.Amount = {
          message: 'You must add at least one adjustment'
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  private getAdjustmentAmountArray(formGroupOnNew: IFormGroupSetup, TransactionAmountArray: Array<AdjustmentApplicableDto>) {
    return formGroupOnNew.formBuilder.array<AdjustmentApplicableDto>(
      TransactionAmountArray.map((NewAmountArray: AdjustmentApplicableDto, index) =>
        formGroupOnNew.hashModel.getFormGroup<AdjustmentApplicableDto>(formGroupOnNew.toUseHashCode, 'NewAmountArray', NewAmountArray, index, () =>
          this.getAdjustmentAmountGroup(formGroupOnNew, NewAmountArray)
        )
      )
    );
  }

  private getAdjustmentAmountGroup(formGroupOnNew: IFormGroupSetup, TransactionAmount: AdjustmentApplicableDto) {
    return formGroupOnNew.formBuilder.group<AdjustmentApplicableDto>({
      EmployeeAmount: [TransactionAmount.EmployeeAmount],
      EmployerAmount: [TransactionAmount.EmployerAmount],
      ClientAmount: [TransactionAmount.ClientAmount],
      isApplied: [TransactionAmount.isApplied],
      ApplicableAdjustmentTypeId: [TransactionAmount.ApplicableAdjustmentTypeId],
      EmployeeYtdDeduction: [TransactionAmount.EmployeeYtdDeduction],
      EmployerYtdDeduction: [TransactionAmount.EmployerYtdDeduction],
      EntityId: [TransactionAmount.EntityId],
      EntityName: [TransactionAmount.EntityName],
      IsApplicableToClient: [TransactionAmount.IsApplicableToClient],
      IsApplicableToEmployee: [TransactionAmount.IsApplicableToEmployee],
      IsApplicableToEmployer: [TransactionAmount.IsApplicableToEmployer],
      Name: [TransactionAmount.Name],
      SalesTaxTypeId: [TransactionAmount.SalesTaxTypeId],
      SourceDeductionTypeId: [TransactionAmount.SourceDeductionTypeId],
      NewYTDEmployerDeduction: [TransactionAmount.NewYTDEmployerDeduction],
      NewYTDWorkerDeduction: [TransactionAmount.NewYTDWorkerDeduction],
      PaymentOtherEarningTypeId: [TransactionAmount.PaymentOtherEarningTypeId],
      IsVacationPayAccured: [TransactionAmount.IsVacationPayAccured],
      IsSalesTaxNumberAvailableBillSide: [TransactionAmount.IsSalesTaxNumberAvailableBillSide],
      IsSalesTaxNumberAvailablePaySide: [TransactionAmount.IsSalesTaxNumberAvailablePaySide]
    });
  }

  private setAdjustmentList(isBackDate: boolean) {
    const arrayData = this.formArrayTransactionAdjustmentAmount();
    const length = arrayData.length;
    this.adjustmentList = [];
    if (!isBackDate) {
      this.contactService.getAdjustmentNew(this.transactionAdjustmentForm.get('WorkOrderVersionId').value)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((result) => {
          this.setWorkerDetails(result);
          this.setSelectedAdjustments();
        });
    } else {
      this.adjustmentList = this.workerDetails.AdjustmentApplicables.filter(
        adjustment => adjustment.ApplicableAdjustmentTypeId === this.phxConstants.ApplicableAdjustmentType.SourceDeductionType && 
          this.sourceDeductionsApplicableToBackDate.has(adjustment.SourceDeductionTypeId)
      );
      this.adjustmentList.forEach(element => {
        element.isApplied = false;
        element.EmployeeAmount = 0;
        element.EmployerAmount = 0;
        element.ClientAmount = 0;
        element.IsApplicableToEmployee = true;
        element.IsApplicableToEmployer = false;
        element.IsApplicableToClient = false;
        this.adjustmentsOrderTracker[element.Name] = this.adjustmentList.indexOf(element);
      });

      for (let i = 0; i < length; i++) {
        const index = this.getToBeRemovedAdjustmentIndex(this.selectedAdjustments);
        if (index !== -1) {
          this.removeAdjustment(index, false);
        }
      }
      this.setSelectedAdjustments();
    }
    this.adjustmentList = this.adjustmentList.filter((adj) => {
        return typeof this.selectedAdjustments.find((s) => s.ApplicableAdjustmentTypeId === adj.ApplicableAdjustmentTypeId && s.EntityId === adj.EntityId) === 'undefined';
      }
    );
  }

  private getToBeRemovedAdjustmentIndex(adjustmentList: AdjustmentApplicableDto[]): number {
    return adjustmentList.findIndex(adj => 
      (adj.ApplicableAdjustmentTypeId === this.phxConstants.ApplicableAdjustmentType.SourceDeductionType &&
       !this.sourceDeductionsApplicableToBackDate.has(adj.SourceDeductionTypeId)) ||
      adj.ApplicableAdjustmentTypeId === this.phxConstants.ApplicableAdjustmentType.SalesTaxType ||
      adj.ApplicableAdjustmentTypeId === this.phxConstants.ApplicableAdjustmentType.PaymentEarningType
    );
  }

  private setWorkerDetails(result: AdjustmentNewDto) {
    this.workerDetails = result;
    this.workOrderId = result.WorkOrderId;
    this.workerDetails.Id = result.WorkOrderVersionId;
    this.adjustmentList = result.AdjustmentApplicables;
    this.organizationIdInternal = result.OrganizationIdInternal;
    if (this.adjustmentList) {
      if (this.currentUserFunctionalRoles.filter(role => role.FunctionalRoleId !== this.phxConstants.FunctionalRole.BackOfficeARAP
        && role.FunctionalRoleId !== this.phxConstants.FunctionalRole.AccountsReceivable).length === 0) {
        this.adjustmentList = this.adjustmentList.filter(item => item.ApplicableAdjustmentTypeId === this.phxConstants.ApplicableAdjustmentType.SalesTaxType);
      }

      this.adjustmentList.forEach(element => {
        element.Name = element.EntityName + ' - ' + element.Name;
        element.isApplied = false;
        element.EmployeeAmount = 0;
        element.EmployerAmount = 0;
        element.ClientAmount = 0;
        this.adjustmentsOrderTracker[element.Name] = this.adjustmentList.indexOf(element);
      });
    }
  }

  private setSelectedAdjustments() {
    const formArray = this.formArrayTransactionAdjustmentAmount();
    if (formArray) {
      const length = formArray.value.length;
      const temp = formArray.value;
      for (let i = 0; i < length; i++) {
        this.removeAdjustment(0, false);
      }
      for (const item of temp) {
        this.addAdjustment(item.Name);
      }
    }
  }

  private setTransactionDate(isBackDate: boolean, isInIt: boolean = false) {
    if (!isBackDate) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      this.transactionDate = new Date(this.workerDetails.OrganizationInternalApplicationDate.replace(/-/g, '\/')) < currentDate
        ? new Date(this.workerDetails.OrganizationInternalApplicationDate.replace(/-/g, '\/')) : currentDate;
    } else {
      const currentYear = new Date().getFullYear();
      const oldTransactionDateYear = new Date(currentYear.toString() + '/12/31').getFullYear();
      this.transactionDate = new Date((oldTransactionDateYear - 1).toString() + '/12/31');
    }
    this.transactionAdjustmentForm.get('TransactionDate').patchValue(this.transactionDate);
    if (!isInIt) {
      this.setAdjustmentList(isBackDate);
    }
  }

}
