<div class="form-horizontal wo-tab-po">
    <div class="content-group">
        <div class="panel-internal panel-full">
            <div class="form-group">
                <div class="col-xs-10">
                    <h4>Purchase Order Lines</h4>
                </div>
                <div class="col-xs-2" style="margin-top: 10px;">
                    <button type="button"
                        class="btn btn-default btn-material dropdown-toggle btn-narrow material-icons pull-right"
                        (click)="addWorkOrderPurchaseOrderLine()">
                        add
                    </button>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <table class="table table-striped table-content table-hover" style="background-color: white;">
                        <thead class="align-table-cells-center" style="background-color: #EAEAEA;">
                            <tr>
                                <th scope="col">PO</th>
                                <th scope="col">Depletion Group</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Funds Allocated</th>
                                <th scope="col">Funds Spent</th>
                                <th scope="col">Funds Reserved</th>
                                <th scope="col">Funds Remaining</th>
                                <th scope="col">Work Units Remaining</th>
                                <th scope="col">Action</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody class="align-table-cells-center" *ngIf="workOrder">
                            <tr *ngFor="let po of workOrder.WorkOrderVersion.WorkOrderPurchaseOrderLines; let i=index"
                                (click)="purchaseOrderLineOpen(po)" class="po-tr">
                                <td>
                                    <span class="po-td-text">{{po.PurchaseOrderNumber}} Line
                                        {{po.PurchaseOrderLineNumber}}</span>
                                </td>
                                <td>
                                    <span class="po-td-text">{{po.PurchaseOrderDepletionGroupId | lookup:purchaseOrderDepletedGroupList}}</span>
                                </td>
                                <td>
                                    <span class="po-td-text">{{(po.StartDate | date: phxConstants.DateFormat.mediumDate) ||'N/A'}}</span>
                                </td>
                                <td>
                                    <span class="po-td-text">{{(po.EndDate | date: phxConstants.DateFormat.mediumDate)
                                        ||'N/A'}}</span>
                                </td>
                                <td>
                                    <span class="po-td-text">{{po.CurrencyCode}}</span>
                                </td>
                                <td>
                                    <span class="po-td-text">
                                        {{ po.AmountCommited | currency:'':" " }}
                                    </span>
                                </td>
                                <td>
                                    <span class="po-td-text">
                                        {{ po.AmountSpent | currency:'':" " }}
                                    </span>
                                </td>
                                <td>
                                    <span class="po-td-text">
                                        {{ po.AmountReserved | currency:'':" " }}
                                    </span>
                                </td>
                                <td>
                                    <span class="po-td-text">
                                        {{ po.AmountRemaining | currency:'':" " }}
                                    </span>
                                </td>
                                <td>
                                    <span class="po-td-text">
                                        {{
                                        (primaryFirstBillingRate > 0) ? (
                                        ((po.AmountRemaining) / (primaryFirstBillingRate * (1 + (po.IsTaxIncluded ?
                                        po.SumSalesTaxVersionRate : 0)))) | number : '1.2-2')
                                        : "--"}} {{primaryworkunit}}
                                    </span>
                                </td>
                                <td class="po-edit-td" (click)="$event.stopPropagation()">
                                    <div class="form-control-static">
                                        <a title="Activate" target="_blank"
                                            class="btn btn-default btn-material ng-scope btn-po-edit"
                                            *ngIf="po.StatusId!==2" (click)="purchaseOrderLineActivate(po)">
                                            <i class="material-icons">check</i>
                                        </a>
                                        <a title="View" target="_blank"
                                            class="btn btn-default btn-material ng-scope btn-po-edit"
                                            (click)="purchaseOrderLineOpen(po)">
                                            <i class="material-icons">edit</i>
                                        </a>
                                        <a title="Delete" target="_blank"
                                            class="btn btn-default btn-material ng-scope btn-po-edit"
                                            *ngIf="hasWorkOrderPurchaseOrderLineDeleteAccess || (po.NoOfPurchaseOrderTransactions === 0 && isInternalUser)"
                                            (click)="purchaseOrderLineDelete(po)">
                                            <i class="material-icons">delete</i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <span class="po-td-text"
                                        [ngClass]="{'text-warning': po.StatusId===phxConstants.WorkOrderPurchaseOrderLineStatus.Active}">{{getCodeText(po.StatusId)}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="validationMessages.length > 0" class="alert alert-danger alert-dismissable error-box">
    <div *ngFor="let message of validationMessages">
        <div>
            {{message}}
        </div>
    </div>
</div>

<app-phx-modal title="Purchase Order Lines" #modalLine cssClass="modal-large">
    <app-workorder-tab-purchaseorder-lines [purchaseOrderSearchLines]="purchaseOrderSearchLines"
        [AssignmentId]="AssignmentId" [workOderPurchaseOrderLineId]="workOderPurchaseOrderLineId"
        [workorderId]="workorderId" [workorderVersion]="workorderVersion" [workorderNumber]="workorderNumber"
        [lineModal]="modalLine" [workerProfileId]="workerProfileId" (cancelLineEvent)="onCancelPOLine()"
        (lineSaved)="getWorkOrderPurchaseOrderLinesByWorkOrderId()">
    </app-workorder-tab-purchaseorder-lines>
</app-phx-modal>

<app-phx-modal showHeader="true" #modalLineEdit cssClass="modal-large">
    <span custom-title>Purchase Order {{poNumber}} <i
            class="material-icons keyboard-arrow-right">keyboard_arrow_right</i> {{poLineNum}}</span>
    <app-purchaseorderlinepopup [LineId]="LineId" [poLineNum]="poLineNum" [poNumber]="poNumber"
        [workOderPurchaseOrderLineId]="workOderPurchaseOrderLineId" [AssignmentId]="AssignmentId"
        [workorderVersion]="workorderVersion" [workorderId]="workorderId" [workorderNumber]="workorderNumber"
        [POmodal]="modalLineEdit" (lineSaved)="getWorkOrderPurchaseOrderLinesByWorkOrderId()"
        (cancelEvent)="onCancel()">
    </app-purchaseorderlinepopup>
</app-phx-modal>
