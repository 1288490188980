<phx-form-control
    [control]="formGroup.controls.EffectiveDate"
    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','EffectiveDate')"
    labelText="Effective Date"
    [viewModeText]="workOrder.WorkOrderVersion.StatusId === html.phxConstants.WorkOrderVersionStatus.Approved
    ? (formGroup.controls.EffectiveDate.value | date: html.phxConstants.DateFormat.mediumDate) + ' - ' + (effectiveToDate|date:html.phxConstants.DateFormat.mediumDate)
    : formGroup.controls.EffectiveDate.value | date: html.phxConstants.DateFormat.mediumDate"
>
    <app-phx-date-box
        type="date"
        [displayFormat]="{ dateStyle: 'medium' }"
        [formControl]="formGroup.controls.EffectiveDate"
    ></app-phx-date-box>
</phx-form-control>
