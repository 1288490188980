import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { IPaymentInfo, IPaymentInvoice, IReadOnlyStorage, IWorkOrder } from '../../models/workorder.interface';
import { ExpenseDetailFormService, PartyIncentiveCompensationFormService, WorkOrderFormService } from '../../services';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { CodeValue, CodeValueGroups } from '../../../common/model';
import { CodeValueService, PhxConstants } from '../../../common';
import { PhxModalComponent } from '../../../common/components/phx-modal/phx-modal.component';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { Validators } from '@angular/forms';

interface IHtml {
  codeValueLists: {
    listPaymentInvoiceTerms: Array<CodeValue>;
    listPaymentReleaseSchedules: Array<CodeValue>;
    listPaymentInvoiceTemplates: Array<CodeValue>;
    listPaymentMethods: Array<CodeValue>;
  };
  phxConstants: any;
}

@Component({
  selector: 'app-workorder-payment-invoice',
  templateUrl: './workorder-payment-invoice.component.html',
  styleUrls: ['./workorder-payment-invoice.component.less'],
})
export class WorkorderPaymentInvoiceComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() index: number;
  @Input() invoiceType: number;
  @Input() paymentInfo: FormGroup<IPaymentInfo>;
  @Input() inputFormGroup: FormGroup<IPaymentInvoice>;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  modalFabButtons = null;
  isExpenseThirdPartyImport = false;
  codeValueGroups = CodeValueGroups;

  paymentReleaseScheduleId: number;
  userProfileWorkerId: number;
  workerProfileTypeId: number;
  workerContactId: number;
  html: IHtml = {
    codeValueLists: {
      listPaymentInvoiceTerms: [],
      listPaymentReleaseSchedules: [],
      listPaymentInvoiceTemplates: [],
      listPaymentMethods: [],
    },
    phxConstants: {},
  };
  workorder: IWorkOrder;
  expenseMethodologyId: number;

  private subscription$: Subscription;

  @ViewChild('paymentReleaseSchedule', { static: true }) paymentReleaseSchedule: PhxModalComponent;

  constructor(
    private codeValueService: CodeValueService,
    private workOrderFormService: WorkOrderFormService,
    private expenseDetailFormService: ExpenseDetailFormService,
    private partyIncentiveCompensationFormService: PartyIncentiveCompensationFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  get paymentInvoiceTermsIdChanges$() {
    return this.inputFormGroup.get('PaymentInvoiceTermsId').valueChanges;
  }

  get invoiceTypeIdFormGroupValue() {
    return this.inputFormGroup.get('InvoiceTypeId').value;
  }

  ngOnInit() {
    this.paymentReleaseSchedule.addClassToConfig('modal-lg');
    this.workOrderFormService.workOrder$.pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.workorder = workorder;
          this.userProfileWorkerId = workorder.UserProfileIdWorker;
          this.workerProfileTypeId = workorder.workerProfileTypeId;
          this.workerContactId = workorder.workerContactId;

          if (this.invoiceType === PhxConstants.InvoiceType.IncentiveCompensation) {
            this.partyIncentiveCompensationFormService.isEligibleForCommissionFormControl.updateValueAndValidity();
          }
          if (this.invoiceType === PhxConstants.InvoiceType.TimeSheet) {
            this.updatePaymentInvoiceValidations();
          }
          if (this.invoiceType === PhxConstants.InvoiceType.Expense) {
            this.setIsExpenseThirdPartyImport(this.workorder.WorkOrderVersion.ExpenseMethodologyId);
            if (this.workorder.WorkOrderVersion.ExpenseMethodologyId !== PhxConstants.ExpenseMethodology.NoExpense) {
              this.updatePaymentInvoiceValidations();
            }
          }

        }
      });
  }

  ngOnChanges() {
    this.setupFormGroupListeners();
  }

  setIsExpenseThirdPartyImport(value) {
    this.isExpenseThirdPartyImport = value === PhxConstants.ExpenseMethodology.ThirdPartyImport;
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    if (this.invoiceType === PhxConstants.InvoiceType.IncentiveCompensation) {
      this.subscription$.add(
        this.partyIncentiveCompensationFormService.isEligibleForCommissionChange$.pipe(
          distinctUntilChanged(),
          takeUntil(this.isDestroyed$)
        ).subscribe(value => {

          this.inputFormGroup.get('PaymentInvoiceTermsId').setValidators(
            value && this.invoiceTypeIdFormGroupValue === this.invoiceType
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTermsId', [
                Validators.required,
              ])
              : null
          );

          this.inputFormGroup.patchValue({
            PaymentInvoiceTermsId: this.inputFormGroup.get('PaymentInvoiceTermsId').value || null,
          });

        })
      );
    } else if (this.invoiceType === PhxConstants.InvoiceType.Expense) {
      this.subscription$.add(
        this.expenseDetailFormService.expenseIdChange$
          .pipe(takeUntil(this.isDestroyed$))
          .subscribe(methodologyId => {
            this.expenseMethodologyId = methodologyId;
            this.setIsExpenseThirdPartyImport(methodologyId);

            this.inputFormGroup.get('PaymentInvoiceTermsId').setValidators(
              methodologyId !== PhxConstants.ExpenseMethodology.NoExpense && this.invoiceTypeIdFormGroupValue === this.invoiceType
                ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTermsId', [
                  Validators.required,
                ])
                : null
            );

            this.inputFormGroup.get('IsSalesTaxAppliedOnVmsImport').setValidators(
              methodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport && this.invoiceTypeIdFormGroupValue === this.invoiceType
                ? [Validators.required]
                : null,
            );

            this.inputFormGroup.patchValue({
              PaymentInvoiceTermsId: this.inputFormGroup.get('PaymentInvoiceTermsId').value || null,
              IsSalesTaxAppliedOnVmsImport: this.inputFormGroup.get('IsSalesTaxAppliedOnVmsImport').value || null
            });

            this.updatePaymentInvoiceValidations(true);
          })
      );
    }

    this.subscription$.add(
      this.paymentInvoiceTermsIdChanges$
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe(value => {

          this.inputFormGroup.get('PaymentReleaseScheduleId').setValidators(
            value === PhxConstants.PaymentInvoiceTerms.ScheduledTerms
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentReleaseScheduleId', [
                Validators.required,
              ])
              : null
          );

          this.inputFormGroup.get('PaymentFrequency').setValidators(
            value === PhxConstants.PaymentInvoiceTerms.Term
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentFrequency', [
                Validators.required,
              ])
              : null
          );

          this.inputFormGroup.patchValue({
            PaymentReleaseScheduleId: this.inputFormGroup.get('PaymentReleaseScheduleId').value || null,
            PaymentFrequency: this.inputFormGroup.get('PaymentFrequency').value || null
          });
        })
    );
  }

  updatePaymentInvoiceValidations(patchValues = false) {
    if (this.invoiceTypeIdFormGroupValue !== PhxConstants.InvoiceType.IncentiveCompensation) {
      const required = this.invoiceTypeIdFormGroupValue === PhxConstants.InvoiceType.TimeSheet
        ? true : this.expenseMethodologyId !== PhxConstants.ExpenseMethodology.NoExpense;

      this.inputFormGroup.get('PaymentMethodId').setValidators(
        required && this.invoiceTypeIdFormGroupValue === this.invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentMethodId', [
            Validators.required,
          ])
          : null,
      );

      this.inputFormGroup.get('PaymentInvoiceTemplateId').setValidators(
        required && this.invoiceTypeIdFormGroupValue === this.invoiceType
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentInvoices', 'PaymentInvoiceTemplateId', [
            Validators.required,
          ])
          : null
      );

      if (patchValues) {
        this.inputFormGroup.patchValue({
          PaymentMethodId: this.inputFormGroup.get('PaymentMethodId').value || null,
          PaymentInvoiceTemplateId: this.inputFormGroup.get('PaymentInvoiceTemplateId').value || null,
        }, { emitEvent: false });
      }
    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.phxConstants = PhxConstants;
    this.html.codeValueLists.listPaymentInvoiceTerms = this.codeValueService.getCodeValues(this.codeValueGroups.PaymentInvoiceTerms, true);
    this.html.codeValueLists.listPaymentReleaseSchedules = this.codeValueService.getCodeValues(this.codeValueGroups.PaymentReleaseSchedule, true);
    this.html.codeValueLists.listPaymentInvoiceTemplates = this.codeValueService.getCodeValues(this.codeValueGroups.PaymentInvoiceTemplate, true);
    this.html.codeValueLists.listPaymentMethods = this.codeValueService.getCodeValues(this.codeValueGroups.PaymentMethodType, true);
  }

  onClickViewPaymentReleaseSchedule(id: number) {
    this.paymentReleaseScheduleId = id;
    this.paymentReleaseSchedule.show();
  }
}
