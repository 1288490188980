<form [formGroup]="inputFormGroup">
  <div class="panel-internal">
    <div style="margin-top:30px">
      <h4>Rates</h4>
    </div>
  </div>
  <div formArrayName="BillingRates">
    <div *ngFor="let billingRate of billingRateFormArray?.controls; let i = index;let last=last; trackBy: trackByFn"
      style="border: none;">
      <div>
          <app-workorder-billing-rate
                  [workOrder]="workOrderDetails"
                  [inputFormGroup]="billingRate"
                  [rateIndex]="i"
                  [readOnlyStorage]="readOnlyStorage"
                  [workOrderRateTypes]="workOrderRateTypes"
                  [selectedRateType]="inputFormGroup.controls.BillingRates"
                  [currencyId]="currencyId"
                  [overtimeIsExempt]="paymentInfoFormService.formGroup.controls.OverTimeDetails.value?.IsOvertimeExempt"
          >
          </app-workorder-billing-rate>
      </div>
    </div>
    <div class="form-group">
      <div class="col-lg-10"></div>
      <div class="col-lg-2">
        <div class="btn-toolbar" *ngIf="workOrderDetails">
          <button type="button" class="btn btn-default btn-material pull-right"
            [disabled]="!ValidToAddBillingRate() || ( billingRateFormArray?.length === workOrderRateTypes.length)"
            *ngIf="!billingInfoIndex && workOrderDetails.readOnlyStorage.IsEditable"
            (click)="onAddBillingPartyRate() "
            title="used {{ billingRateFormArray?.length }} from {{workOrderRateTypes.length}} available rates">
            <i class="material-icons ">add</i>
          </button>
        </div>
      </div>
    </div>    
    <app-workorder-billing-rate-overtime-config
      *ngIf="!readOnlyStorage.isTemplate
      && (workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp
        || workerProfileTypeId === phxConstants.UserProfileType.WorkerUnitedStatesW2)"
      [readOnlyStorage]="readOnlyStorage"
      (overtimeExemption)="onOvertimeExempt($event)"
    ></app-workorder-billing-rate-overtime-config>
  </div>
</form>
