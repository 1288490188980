import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService, PhxConstants } from '../../index';
import { NoteService } from '../../services/note.service';
import { PhxNote } from '../../model/phx-note';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { UntypedFormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CommandResponse } from '../../model';

@Component({
  selector: 'app-phx-notes',
  templateUrl: './phx-notes.component.html',
  styleUrls: ['./phx-notes.component.less']
})
export class PhxNotesComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() entityTypeId: PhxConstants.EntityType;
  @Input() entityId: number;
  @Input() entityStatusId: number;
  @Input() entityStatusCode: string;
  @Input() workOrderVersionId: number;
  @Output() readCountNumber = new EventEmitter<any>();
  note: string;
  notes: Array<PhxNote>;
  noteControl: UntypedFormControl;
  dateFormat = PhxConstants.DateFormat;
  currentUserId: number = null;
  isCurrentUserHasAdminRole: boolean = false;
  unreadNoteCount = 0;

  constructor(public commonService: CommonService, private noteService: NoteService, private authService: AuthService, private cdr: ChangeDetectorRef) {
    super();
    this.noteControl = new UntypedFormControl();
  }

  get isEntityStatusDraft() {
    let isDraft = false;

    switch (this.entityTypeId) {
      case PhxConstants.EntityType.Contact:
        isDraft = this.entityStatusId !== PhxConstants.UserStatus.Active;
        break;
      case PhxConstants.EntityType.WorkOrder:
        isDraft = this.entityStatusId !== PhxConstants.WorkOrderVersionStatus.Approved;
        break;
      case PhxConstants.EntityType.Organization:
        isDraft = this.entityStatusCode !== PhxConstants.CodeOrganizationStatus.Active; 
        break;
      default:
        break;
    }
    return isDraft;
  }

  ngOnInit() {
    this.noteService.notes$.pipe(takeUntil(this.isDestroyed$))
      .subscribe(noteStore => {
        noteStore = noteStore || [];
        const note = noteStore.find(n => n.entityId === this.entityId && n.entityTypeId === this.entityTypeId);
        this.notes = note ? note.notes : [];
        this.unreadNoteCount = note ? note.noteUnreadCount : 0;
        this.cdr.detectChanges();
      });
    this.authService.getCurrentProfile().pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(user => {
      this.currentUserId = user.Id;
      this.isCurrentUserHasAdminRole = user.FunctionalRoles.some(n => n.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.entityTypeId || changes.entityId) && this.entityId && this.entityTypeId) {
      this.loadNotes();
    }
  }

  loadNotes() {
    this.noteService.loadNotes(this.entityTypeId, this.entityId, this.isDestroyed$, true);
  }

  addNote() {
    const note = this.noteControl.value;
    if (!note || note.trim().length === 0) {
      return;
    }
    this.noteService.saveNote({ EntityTypeId: this.entityTypeId, EntityId: this.entityId, Comment: note.trim() })
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.noteControl.setValue('', { emitEvent: false });
        this.loadNotes();
      });
  }

  deleteNote(note: PhxNote) {
    if (!this.isCurrentUserHasAdminRole) {
      return;
    }
    const deleteNoteCommand = {
      Id: note.Id,
      WorkOrderVersionId: this.workOrderVersionId // this is only available/needed when in WorkOrder module
    };
    this.noteService.deleteNote(deleteNoteCommand)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: () => this.loadNotes(),
        error: (error: CommandResponse) => {
          const validationMessages = this.commonService.responseErrorMessages(error, null);
          if (validationMessages?.length > 0) {
            validationMessages.forEach(x => {
              this.commonService.logError(x.Message);
            });
          }
        }
      });
  }

  markRead(note, isRead) {
    const markReadCommand = {
      NoteId: note.Id,
      LastModifiedProfileId: note.LastModifiedByProfileId,
      IsRead: isRead
    };
    this.noteService.markRead(markReadCommand)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.loadNotes());
  }

  trackByNotes(index, item: PhxNote) {
    return `${item.EntityId}.${item.EntityTypeId}`;
  }

  markAllNotesAsRead() {
    const markAllReadCommand = {
      NoteIds: this.notes.filter(x => !x.IsRead).map(x => x.Id)
    };
    this.noteService.markAllRead(markAllReadCommand)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.loadNotes());
  }
}
