import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { PhxConstants } from '../../common';
import { FboMonolithAdminDataService } from '../../common/data-services/fbo-monolith-admin-data/fbo-monolith-admin-data.service';

export const ATS_INTEGRATION_ENABLED = new InjectionToken<Observable<boolean>>('ATS Integration Enabled');

export const IsTenantATSIntegrationEnabledProvider: Provider = {
  provide: ATS_INTEGRATION_ENABLED,
  useFactory: (fboMonolithAdminDataService: FboMonolithAdminDataService) => {
    const atsIdIsEnabledResponse = '1';
    return fboMonolithAdminDataService.getBusinessRuleConfiguration(PhxConstants.BusinessConfigurationKey.IsATSIntegrationEnabled, {}, false).pipe(
      map(r => r === atsIdIsEnabledResponse),
      shareReplay(1)
    );
  },
  deps: [FboMonolithAdminDataService]
};
