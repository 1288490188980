<ng-container *ngIf="listUserProfileWorker$ | async as listUserProfileWorker">
  <ng-container *ngIf="listUserProfileWorker.length">
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerTemp"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workertemp/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerCanadianSp"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workercanadiansp/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerCanadianInc"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workercanadianinc/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerSubVendor"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workersubvendor/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerUnitedStatesW2"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workerunitedstatesw2/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
    <a
      *ngIf="+(userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ProfileTypeId') === phxConstants.UserProfileType.WorkerUnitedStatesLLC"
      routerLink="/next/contact/{{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'ContactId' }}/profile/workerunitedstatesllc/{{ userProfileIdWorker }}"
      [appCollectClickDataAnalytics]="clickAnalyticsData"
    >
      {{ userProfileIdWorker | lookup : listUserProfileWorker : 'Id' : 'Contact.FullName' }}
      -
      {{ userProfileIdWorker }}({{ listUserProfileWorker[0].ProfileTypeId | lookup : profileTypeList : 'id' : 'text' }})
    </a>
  </ng-container>
</ng-container>
