
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IIncentiveCompensation, IPaymentInfo, IReadOnlyStorage, IWorkOrder } from '../../models/index';
import { EarningsDeductionsTabFormService, PartyIncentiveCompensationFormService, PartyPaymentInfoFormService, WorkOrderFormService } from '../../services';
import { PhxConstants } from 'src/app/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-incentive-compensation',
  templateUrl: './workorder-incentive-compensation.component.html'
})
export class WorkorderIncentiveCompensationComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;

  workerProfileTypeId: number;
  OrganizationIdSupplier: number;
  paymentInfo: Array<IPaymentInfo>;
  formGroup: FormGroup<IIncentiveCompensation>;

  phxConstants = PhxConstants;
  private workOrder: IWorkOrder;

  constructor(
    private authService: AuthService,
    private workOrderFormService: WorkOrderFormService,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
    private incentiveFormService: PartyIncentiveCompensationFormService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService,
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.incentiveFormService.formGroup;

    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(workorder => {
      if (workorder) {
        this.workOrder = workorder;
        this.workerProfileTypeId = workorder.workerProfileTypeId;
        this.paymentInfo = workorder.WorkOrderVersion.PaymentInfoes;
        this.OrganizationIdSupplier = this.paymentInfo[0].OrganizationIdSupplier;
      }
    });

    this.partyPaymentInfoFormService.organizationIdSupplierChange$.pipe(
      debounceTime(100),
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.workOrder = this.partyPaymentInfoFormService.formGroupToPartial(this.workOrder);
      this.workerProfileTypeId = this.earningsDeductionsTabFormService.workerProfileTypeIdValue;
      this.paymentInfo = this.workOrder.WorkOrderVersion.PaymentInfoes;
      this.OrganizationIdSupplier = this.paymentInfo[0].OrganizationIdSupplier;
    });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

}
