<form *ngIf="transactionAdjustmentForm" [formGroup]="transactionAdjustmentForm">
    <div class="row">
        <div class="col-md-6">
            <h4>Transaction Info</h4>
            <section class="form-container">
                <div class="row form-group">
                    <label class="col-md-4 control-label">Backdate to prior year?</label>
                    <div class="col-md-8">
                        <label class="radio-inline">
                            <input class="form-check-input" name="BackDateToPriorYear"
                                   type="radio" [value]="true"
                                   [formControl]="transactionAdjustmentForm.get('IsBackDate')">
                            &nbsp;Yes
                        </label>
                        <label class="radio-inline">
                            <input class="form-check-input" name="BackDateToPriorYear"
                                   type="radio" [value]="false"
                                   [formControl]="transactionAdjustmentForm.get('IsBackDate')">
                            &nbsp;No
                        </label>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Transaction Date:</label>
                    <div class="col-md-8">
                        {{transactionDate | date: phxConstants.DateFormat.mediumDate}}
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Employee Amount Total:</label>
                    <div class="col-md-8">
                        {{ (EmployeeAmountTotal || 0.00) | currency }}
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Employer Amount Total:</label>
                    <div class="col-md-8">
                        {{ (EmployerAmountTotal || 0.00) | currency }}
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Client Amount Total:</label>
                    <div class="col-md-8">
                        {{ (ClientAmountTotal || 0.00) | currency }}
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Period Start Date:</label>
                    <div class="col-md-6">
                        <app-phx-date-box
                            type="date"
                            (valueChanged)="datePickerCallbackOnDoneStartDate()"
                            [displayFormat]="{ dateStyle: 'medium' }"
                            [formControl]="transactionAdjustmentForm.get('PeriodStartDate')"
                        ></app-phx-date-box>
                        <span class="warning"
                              *ngIf="html.isPeriodStartDateBeforeWOVStartDate">{{ html.PeriodStartDateBeforeWOVStartDateMessage }}</span>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Period End Date:</label>
                    <div class="col-md-6">
                        <app-phx-date-box
                            type="date"
                            (valueChanged)="datePickerCallbackOnDoneEndDate()"
                            [displayFormat]="{ dateStyle: 'medium' }"
                            [formControl]="transactionAdjustmentForm.get('PeriodEndDate')"
                        ></app-phx-date-box>
                        <span class="invalid-period-end-date"
                              *ngIf="html.isPeriodEndDateBeforePeriodStartDate">{{ html.PeriodEndDateBeforePeriodStartDateMessage }}</span>
                        <span class="warning"
                              *ngIf="html.isPeriodEndDateAfterWOVEndDate">{{ html.PeriodEndDateAfterWOVEndDateMessage }}</span>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-md-6">
            <h4>Work Order Info</h4>

            <section class="form-container">
                <div class="row form-group">
                    <label class="control-label col-md-4">Work Order
                        <span [ngClass]="transactionAdjustmentForm.get('WorkOrderVersionId').value === null ? 'error' : ''"
                              class="asterisk">&nbsp;*</span>
                    </label>

                    <div class="col-md-8">
                        <app-phx-select-box [items]="listsWorkOrder"
                                            textField="WorkOrderFullNumber"
                                            valueField="WorkOrderVersionId"
                                            [formControl]="transactionAdjustmentForm.get('WorkOrderVersionId')">
                        </app-phx-select-box>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Worker:</label>
                    <div class="col-md-8">
                        <a>{{contactDetails?.Contact?.FullName}}
                            - {{contactDetails?.ContactId}}
                            ({{contactDetails?.ProfileTypeId | CodeValue:codeValueGroups.ProfileType}}
                            -
                            {{contactDetails?.Id}})</a>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-md-4 control-label">Internal Company:</label>
                    <div class="col-md-8">
                        <a *ngIf="organizationIdInternal">
                            {{workerDetails.OrganizationInternalDisplayName}} -
                            {{workerDetails.OrganizationIdInternal}}
                            ({{workerDetails.OrganizationInternalCode}}) </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="content-group form-horizontal adjustment-info-container">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <phx-form-control [control]="transactionAdjustmentForm.get('Description')"
                                      labelText="Description"
                                      viewModeText="{{transactionAdjustmentForm.get('Description').value}}">
                        <input [formControl]="transactionAdjustmentForm.get('Description')" maxlength="64"
                               minlength="3" type="text" class="form-control"/>
                    </phx-form-control>
                </div>
                <div class="form-group">
                    <phx-form-control [control]="transactionAdjustmentForm.get('ReleaseType')"
                                      labelText="Release Type"
                                      viewModeText="{{transactionAdjustmentForm.get('ReleaseType').value}}">
                        <label class="create-adj-radio-inline">
                            <input class="form-check-input" name="ReleaseType" type="radio"
                                   [value]="false" [formControl]="transactionAdjustmentForm.get('ReleaseType')">
                            Payment/Invoice Release
                        </label>
                        <label class="create-adj-radio-inline">
                            <input class="form-check-input" name="ReleaseType" type="radio"
                                   [value]="true" [formControl]="transactionAdjustmentForm.get('ReleaseType')">
                            Record-keeping only (Suppress Payment/Invoice)
                        </label>
                        <span *ngIf="!html.isReleaseTypeValid"
                              class="Invalid-Message">{{ html.ReleaseTypeValidationMessage }}</span>
                    </phx-form-control>
                </div>
                <div class="form-group">
                    <div *ngIf="adjustmentList.length > 0">
                        <phx-form-control labelText="Add Adjustment"
                                          [enableLabelForceAsterisk]="formArrayTransactionAdjustmentAmount().controls.length === 0"
                                          [control]="transactionAdjustmentForm.get('AdjustmentName')">
                            <app-phx-select-box [items]="adjustmentList" textField="Name" valueField="Name"
                                                [formControl]="transactionAdjustmentForm.get('AdjustmentName')">
                            </app-phx-select-box>
                        </phx-form-control>

                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="selectedAdjustments.length>0">
            <div class="col-md-12">
                <table class="table table-striped table-content table-hover">
                    <thead>
                    <tr>
                        <th>Deduction Type</th>
                        <th><span class="pull-right">YTD Worker Deduction/Earnings</span></th>
                        <th><span class="pull-right">Worker Amount</span></th>
                        <th><span class="pull-right">New YTD Worker Deduction/Earnings</span></th>
                        <th><span class="pull-right">YTD Employer Deduction</span></th>
                        <th><span class="pull-right">Employer Amount</span></th>
                        <th><span class="pull-right">New YTD Employer Deduction</span></th>
                        <th><span class="pull-right">Client Amount</span></th>
                        <th><span class="pull-right">Action</span></th>
                    </tr>
                    </thead>

                    <tbody class="sales-patt-table-body" formArrayName="Amount">
                    <tr class="cursor-pointer"
                        *ngFor="let data of formArrayTransactionAdjustmentAmount().controls; let k= index;"
                        [formGroupName]="k">
                        <td title="{{data.value.EntityName}} - {{data.value.Name}}"><span
                                class="adj-item-in">{{trimFirstPart(data.value.Name)}}</span>
                        </td>
                        <td>
                            <span class="adj-item-in pull-right">
                                {{ data.value.EmployeeYtdDeduction | currency }}
                            </span>
                        </td>
                        <td>
                            <div class="input-group pull-right"
                                 *ngIf="data.value.IsApplicableToEmployee">
                                <input type="text" required
                                       phxFloatBetweenInput='{"from":-999999, "to":999999.99, "decimalplaces":2}'
                                       class="input-align-right form-control form-control ng-scope"
                                       formControlName="EmployeeAmount"
                                       (focusout)="updateTotalAmounts()">
                            </div>
                        </td>
                        <td>
                            <span class="adj-item-in pull-right">
                                {{ data.value.NewYTDWorkerDeduction | currency }}
                            </span>
                        </td>
                        <td>
                            <span class="adj-item-in pull-right">
                                {{ (data.value.EmployerYtdDeduction || 0.00) | currency }}
                            </span>
                        </td>
                        <td>
                            <div class="input-group pull-right"
                                 *ngIf="data.value.IsApplicableToEmployer">
                                <input type="text" required
                                       phxFloatBetweenInput='{"from":-999999, "to":999999.99, "decimalplaces":2}'
                                       class="input-align-right form-control form-control ng-scope"
                                       formControlName="EmployerAmount" (input)="updateTotalAmounts()">
                            </div>
                        </td>
                        <td>
                            <span class="adj-item-in pull-right">
                                {{ data.value.NewYTDEmployerDeduction | currency }}
                            </span>
                        </td>
                        <td>
                            <div class="input-group pull-right"
                                 *ngIf="data.value.IsApplicableToClient">
                                <input type="text" required
                                       phxFloatBetweenInput='{"from":-999999, "to":999999.99, "decimalplaces":2}'
                                       class="input-align-right form-control form-control ng-scope"
                                       formControlName="ClientAmount" (input)="updateTotalAmounts()">
                            </div>
                        </td>
                        <td>
                            <div class="pull-right">
                                <button type="button" class="btn btn-default btn-material pull-right"
                                        (click)="removeAdjustment(k)">
                                    <i class="material-icons">delete</i></button>
                            </div>
                        </td>
                    </tr>
                    </tbody>

                    <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            <span class="pull-right">
                                {{ EmployeeAmountTotal | currency }}
                            </span></td>
                        <td></td>
                        <td></td>
                        <td>
                            <span class="pull-right">
                                {{ EmployerAmountTotal | currency }}
                            </span>
                        </td>
                        <td></td>
                        <td>
                            <span class="pull-right">
                            {{ ClientAmountTotal | currency }}
                            </span>
                        </td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>

    <div class="btn-toolbar button-bar">
        <div class="btn-group">
            <button type="button" class="btn btn-default action-button" (click)="cancelClick()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary action-button" (click)="onClickSubmit()"
                    [disabled]="!transactionAdjustmentForm.valid">Create
            </button>
        </div>
    </div>
</form>

<app-phx-validation-messages [messages]="validationMessages"></app-phx-validation-messages>

<app-phx-modal title="Create Adjustment Transaction" #ConfirmModal cssClass="modal-lg">
    <div class="slider-content">
        <div class="slider-body">
            <div class="alert alert-warning"
                 *ngIf="!isWorkerProfileSpValid || !isWorkerProfileIncValid || html.isNonTempWorkerVacationPay">Some
                source
                deductions/earnings selected are not normally charged for this profile type
            </div>
            <div><label>Employee Amount :</label> {{ EmployeeAmountTotal || 0 | currency }} </div>
            <div><label>Employer Amount :</label> {{ EmployerAmountTotal || 0 | currency }} </div>
            <div><label>Client Amount :</label> {{ ClientAmountTotal || 0 | currency }} </div>
            <div *ngIf="ShowSalesTaxNumberAlert; else notShow" class="Invalid-Message" >
                <div *ngIf="showDateRangeWarning">
                    <p>An Adjustment transaction will be created for a worker with no sales tax registration number.</p>
                    <p>The Transaction Date is outside of this Work Order period. Are you sure?</p>
                </div>
                <div *ngIf="!showDateRangeWarning">An Adjustment transaction will be created for a worker with no sales tax registration number. Are you sure?</div>
            </div>
            <ng-template #notShow>
                <div *ngIf="showDateRangeWarning" class="Invalid-Message">
                    An Adjustment transaction will be created, but the Transaction Date is outside of this Work Order period. Are you sure?
                </div>
                <div *ngIf="!showDateRangeWarning">An Adjustment transaction will be created. Are you sure?</div>
            </ng-template>            
        </div>
        <div class="slider-footer">
            <div class="btn-toolbar button-bar">
                <div class="btn-group">
                    <button type="button" class="btn btn-default" (click)="cancelModal()">No</button>
                    <button type="button" class="btn btn-primary" (click)="finalSubmit()">Yes</button>
                </div>
            </div>
        </div>
    </div>

</app-phx-modal>
