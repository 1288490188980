<form [formGroup]="formGroup">
  <div class="content-group ">
    <div class="content-left">
      <app-phx-panel title="Expense Methodology">
        <phx-form-control labelText="Expense Methodology" [control]="formGroup.controls.ExpenseMethodologyId"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','ExpenseMethodologyId')"
          viewModeText="{{formGroup.controls.ExpenseMethodologyId.value | CodeValue:codeValueGroups.ExpenseMethodology }}">
          <app-phx-select-box [items]="html.codeValueLists.listExpenseMethodologies" textField="text" valueField="id"
            [formControl]="formGroup.controls.ExpenseMethodologyId">
          </app-phx-select-box>
        </phx-form-control>
      </app-phx-panel>
    </div>
    <div class="content-right" *ngIf="html.expenseCard.expenseApproval">
      <app-phx-panel title="Expense Approver">
        <ng-container
          *ngIf="formGroup.controls.ExpenseMethodologyId?.value !== html.phxConstants.ExpenseMethodology.OfflineApproval">
          <ng-container *ngFor="let item of supplierApproverFormArray.controls">
            <ng-container [formGroup]="item">
              <phx-form-control labelText="Supplier Approver" [control]="item.get('UserProfileId')"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.ExpenseApprovers','UserProfileId')"
                viewModeText="{{item.get('UserProfileId').value | lookup: html.commonLists.listProfilesForSupplierApproval:'Id':'DisplayValue'}}">
                <app-phx-select-box [items]="html.commonLists.listProfilesForSupplierApproval"
                  textField="DisplayValue" valueField="Id" [formControl]="item.get('UserProfileId')"
                  placeholder="Approver Name Search">
                </app-phx-select-box>
              </phx-form-control>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let item of internalApproverFormArray.controls">
          <ng-container [formGroup]="item">
            <phx-form-control labelText="Internal Approver" [control]="item.get('UserProfileId')"
              [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.ExpenseApprovers','UserProfileId')"
              viewModeText="{{item.get('UserProfileId').value | lookup: html.commonLists.listProfilesForInternalApproval:'Id':'DisplayValue'}}">
              <app-phx-select-box [items]="html.commonLists.listProfilesForInternalApproval" textField="DisplayValue"
                valueField="Id" [formControl]="item.get('UserProfileId')" placeholder="Approver Name Search">
              </app-phx-select-box>
            </phx-form-control>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="formGroup.controls.ExpenseMethodologyId?.value !== html.phxConstants.ExpenseMethodology.OfflineApproval">
          <ng-container
            *ngFor="let item of clientApproverFormArray.controls; let clientIndex=index; let lastClient = last">
            <ng-container [formGroup]="item">
              <phx-form-control labelText="Client Approver" [control]="item.get('UserProfileId')"
                [editable]="readOnlyStorage.IsEditable  && checkPtFiledAccessibility('WorkOrderVersion.ExpenseApprovers','UserProfileId')"
                viewModeText="{{item.get('UserProfileId').value | lookup: html.commonLists.listProfilesForClientApproval:'Id':'DisplayValue'}}">
                <div [ngClass]="{'col-md-10 padding-0': (clientIndex !== 0)}">
                  <app-phx-select-box [items]="html.commonLists.listProfilesForClientApproval 
                | filterSelectChoicesByUsage: clientApproverFormArray.value
                : item.value: 'UserProfileId': 'Id': 'Contact.FullName': 'Contact.Id'" textField="DisplayValue"
                    valueField="Id" [formControl]="item.get('UserProfileId')" placeholder="Approver Name Search">
                  </app-phx-select-box>
                </div>
                <div class="col-xs-1 pull-right padding-0" *ngIf="clientIndex !== 0">
                  <button type="button" class="btn btn-default btn-material pull-right"
                    (click)="onClickRemoveExpenseApproverDefinition(clientIndex)">
                    <i class="material-icons">delete</i>
                  </button>
                </div>
              </phx-form-control>
            </ng-container>
            <div class="form-group">
              <div class="col-lg-2 pull-right" *ngIf="lastClient && readOnlyStorage.IsEditable">
                <button type="button" class="btn btn-default btn-material pull-right add-btn-group"
                  (click)="onClickAddExpenseApproverDefinition()"
                  [disabled]="clientApproverFormArray.length >= html.commonLists.listProfilesForClientApproval.length"
                  title="Add a new Expense Approver">
                  <i class=" material-icons">add</i>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="clientApproverFormArray.length > 1">
          <phx-form-control labelText="Expense Approval Flow Type" [control]="formGroup.controls.ExpenseApprovalFlowId"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','ExpenseApprovalFlowId')"
            viewModeText="{{formGroup.controls.ExpenseApprovalFlowId.value | CodeValue:codeValueGroups.TimeSheetApprovalFlow}}">
            <app-phx-select-box [items]="html.codeValueLists.listExpenseApprovalFlows" textField="text" valueField="id"
              [formControl]="formGroup.controls.ExpenseApprovalFlowId">
            </app-phx-select-box>
          </phx-form-control>
        </ng-container>
      </app-phx-panel>
    </div>
  </div>
  <div class="content-group">
    <div class="content-left" *ngIf="html.expenseCard.projectsAndCoding">
      <app-phx-panel title="Projects & Coding">
        <phx-form-control labelText="Expense Uses Projects?" [control]="formGroup.controls['IsExpenseUsesProjects']"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsExpenseUsesProjects')"
          viewModeText="{{formGroup.controls['IsExpenseUsesProjects'].value ? 'Yes' : 'No'}}">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="true"
              formControlName="IsExpenseUsesProjects">Yes</label>
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="false"
              formControlName="IsExpenseUsesProjects">No</label>
        </phx-form-control>
      </app-phx-panel>
    </div>
    <div class="content-right" *ngIf="html.expenseCard.configurationAndDescriptors">
      <app-phx-panel title="Configuration & Descriptors">
        <phx-form-control [control]="formGroup.controls.ExpenseThirdPartyWorkerReference"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','ExpenseThirdPartyWorkerReference')"
          labelText="Third Party Worker ID"
          viewModeText="{{formGroup.controls.ExpenseThirdPartyWorkerReference.value}}">
          <input
            appTrimInputSpaces
            formControlName="ExpenseThirdPartyWorkerReference"
            type="text"
            class="form-control"
          />
        </phx-form-control>
        <ng-container *ngIf="html.expenseCard.expenseDescription">
          <phx-form-control [control]="formGroup.controls.ExpenseDescription" labelText="Expense Description"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','ExpenseDescription')"
            viewModeText="{{formGroup.controls.ExpenseDescription.value}}">
            <input formControlName="ExpenseDescription" type="text" class="form-control" />
          </phx-form-control>
        </ng-container>
      </app-phx-panel>
    </div>
  </div>
</form>
