import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationBarItem, NavigationBarSubItem, PhxConstants } from '../../model';
import { NoteService } from '../../services/note.service';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { PhxNoteStore } from '../../model/phx-note';
import { NavigationExtras } from '@angular/router';
import { IframeService } from '../../services/iframe.service';

@Component({
  selector: 'app-phx-navigation-bar',
  templateUrl: './phx-navigation-bar.component.html',
  styleUrls: ['./phx-navigation-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxNavigationBarComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @Input() activeTab: NavigationBarItem;
  @Input() activeSubMenu: NavigationBarSubItem;
  @Input() navigationBarContent: Array<NavigationBarItem>;
  @Input() url: string;
  @Input() entityTypeId: number;
  @Input() entityId: number;

  @Output() tabSelected: EventEmitter<NavigationBarItem> = new EventEmitter();
  @Input() activeTabObj;

  notesTabNavigationName: string = PhxConstants.NotesTabNavigationName;
  showUnreadNotesMessage = false;
  unreadNotesCount = 0;
  note: PhxNoteStore;

  navigationExtras: NavigationExtras;

  private subscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private noteService: NoteService,
    private iframeService: IframeService
  ) {
    super();
    // Put here so it can be used in the template right away
    this.navigationExtras = this.iframeService.navigationExtras;
  }

  ngOnInit() {
    if (typeof this.activeTabObj !== 'undefined') {
      this.activeTab = this.activeTabObj;
    }

    this.noteService.notes$.pipe(takeUntil(this.isDestroyed$))
      .subscribe(noteStore => {
        noteStore = noteStore || [];
        const note = noteStore.find(n => n.entityId === this.entityId && n.entityTypeId === this.entityTypeId);
        this.unreadNotesCount = note ? note.noteUnreadCount : 0;
        if (!note || !this.note || note.entityId !== this.note.entityId || note.entityTypeId !== this.note.entityTypeId) {
          // will not re-init the flag after user manually close the message
          this.showUnreadNotesMessage = note ? note.showUnreadNoteNotification : false;
        } else if (this.unreadNotesCount === 0) {
          this.showUnreadNotesMessage = false;
        }
        this.note = note;
        this.cdr.detectChanges();
      });
  }

  onTabSelect(tab: NavigationBarItem) {
    this.activeTab = tab;
    this.tabSelected.emit(tab);
  }

  onSubMenuSelect(subMenu: any) {
    this.activeSubMenu = subMenu;
  }

  trackByFn(index, item) {
    return '_' + index + (item ? item.BadgeCount : 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.navigationBarContent || changes.url)) {
      if (this.navigationBarContent) {
        // Find the active tab
        let tabByUrl = null;

        if (this.url) {
          tabByUrl = this.navigationBarContent.find(nbc => this.url.includes(nbc.Name));
        }

        this.activeTab = tabByUrl ? tabByUrl : this.navigationBarContent.filter(item => item.IsDefault === true)[0];
        if (this.activeTab) {
          if (this.activeTab.SubMenu !== undefined && this.activeTab.SubMenu.length > 0) {
            this.activeSubMenu = this.activeTab.SubMenu.filter(item => item.IsDefault === true)[0];
          }
          this.tabSelected.emit(this.activeTab);
        }
      }

      if (changes.navigationBarContent) {
        this.setupNavbarObservablesListener(changes.navigationBarContent.currentValue);
      }
    }
  }

  private setupNavbarObservablesListener(items: Array<NavigationBarItem>) {
    this.subscription?.unsubscribe();

    const validations = items.filter(item => item.IsFormValid).map(item => item.IsFormValid);
    const versionChanges = items.filter(item => item.HasVersionChanges).map(item => item.HasVersionChanges);

    if (validations.length || versionChanges.length) {
      this.subscription = merge(...validations, ...versionChanges).pipe(
        takeUntil(this.isDestroyed$)
      ).subscribe(() => this.cdr.detectChanges());
    }
  }

  repaint() {
    if (this.navigationBarContent && this.url) {
      const tabItem = this.navigationBarContent.find(nbc => this.url.includes(nbc.Name));
      this.activeTab = tabItem ? tabItem : this.navigationBarContent.filter(item => item.IsDefault === true)[0];
      this.cdr.detectChanges();
    }
  }

  hideUnreadNotesNotification(event: MouseEvent) {
    event.stopPropagation();
    this.showUnreadNotesMessage = false;
  }
}
