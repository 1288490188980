<ng-container *ngIf="formGroup">
  <div class="content-group form-horizontal">
    <div class="content-left">
      <app-workorder-billing-parties [readOnlyStorage]="readOnlyStorage">
      </app-workorder-billing-parties>
    </div>
    <div class="content-right">
      <app-workorder-payment-parties [readOnlyStorage]="readOnlyStorage">
      </app-workorder-payment-parties>

      <app-workorder-incentive-compensation
        *ngIf="hasIncentiveCompensationSection$ | async"
        [readOnlyStorage]="readOnlyStorage">
      </app-workorder-incentive-compensation>
    </div>
  </div>
  <div class="content-group form-horizontal" *ngIf="formGroup.controls.RateNegotiation.enabled">
    <div class="content-left">
      <app-workorder-rate-negotiation [readOnlyStorage]="readOnlyStorage">
      </app-workorder-rate-negotiation>
    </div>
  </div>
</ng-container>