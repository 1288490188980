<form [formGroup]="inputFormGroup" *ngIf="inputFormGroup">
  <div class="content-group form-horizontal" style="height:100%;">
    <fieldset *ngIf="!workOrderCreateInProgress">
      <div class="row">
        <div class="col-lg-8">
          <app-phx-panel headerTitle="Application Transaction System Result" *ngIf="sourceId > 0">
            <div class="form-group">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Source</label>
              <div class="col-lg-6">
                <label class="control-label">{{sourceId | CodeValue:codeValueGroups.AtsSource}}</label>
              </div>
            </div>
            <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Placement ID</label>
              <div class="col-lg-6">
                <label class="control-label">{{inputFormGroup.controls.AtsPlacementId.value}}</label>
              </div>
            </div>
            <div class="form-group" *ngIf="!inputFormGroup.controls.AtsPlacementId.value && placementId>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Placement ID</label>
              <div class="col-lg-6">
                <label class="control-label alert-danger">No ATS result on requested id: {{placementId}}</label>
              </div>
            </div>

            <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Start Date</label>
              <div class="col-lg-6">
                <label class="control-label">{{inputFormGroup.controls.StartDate.value}}</label>
              </div>
            </div>

            <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">End Date</label>
              <div class="col-lg-6">
                <label class="control-label">{{inputFormGroup.controls.EndDate.value}}</label>
              </div>
            </div>

            <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0">
              <label class="col-lg-4 control-label label-to-right" title="">Placement Type</label>
              <div class="col-lg-6">
                <label class="control-label">{{ inputFormGroup.controls.AtsPlacementType.value | spoofValueForTenant : { substituteFor: "Regular", forTenant: "Demo" } }}</label>
              </div>
            </div>

            <div formArrayName="BillingRates">
              <div class="form-group" *ngFor="let rate of billingRatesFormArray.controls; let idx = index">
                <div [formGroup]="rate">
                  <label class="col-lg-4 control-label label-to-right" data-original-title=""
                    title="">{{rate.get('RateTypeId').value | CodeValue:codeValueGroups.RateType}} Billing Rate</label>
                  <div class="col-lg-6">
                    <label class="control-label">$ {{rate.get('Rate').value | number : '1.2-2'}} {{inputFormGroup.controls.BillingRateUnitId.value
                      | CodeValue:codeValueGroups.RateUnit}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div formArrayName="PaymentRates">
              <div class="form-group" *ngFor="let rate of paymentRatesFormArray.controls; let idx = index">
                <div [formGroup]="rate">
                  <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">
                    {{rate.get('RateTypeId').value | CodeValue:codeValueGroups.RateType}} Payment Rate</label>
                  <div class="col-lg-6">
                    <label class="control-label">$ {{rate.get('Rate').value | number : '1.2-2'}} {{inputFormGroup.controls.PaymentRateUnitId.value
                      | CodeValue:codeValueGroups.RateUnit}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="inputFormGroup.controls.AtsClientId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">{{atsOwnerLabel}} (Job)</label>
              <div class="col-lg-6" *ngIf="inputFormGroup.controls.AtsJobOwnerId.value>0">
                <label class="control-label">{{inputFormGroup.controls.AtsJobOwnerId.value}} - {{inputFormGroup.controls.AtsJobOwnerName.value}}</label>
              </div>
            </div>
            <div class="form-group" *ngIf="inputFormGroup.controls.AtsClientId.value>0 && isRegularPlacement">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Added By (Candidate Submission)</label>
              <div class="col-lg-6" *ngIf="inputFormGroup.controls.AtsRecruiterId.value>0">
                <label class="control-label">{{inputFormGroup.controls.AtsRecruiterId.value}} - {{inputFormGroup.controls.AtsRecruiterName.value}}</label>
              </div>
              <div class="col-lg-6" *ngIf="inputFormGroup.controls.AtsRecruiterId.value === 0">
                <label class="control-label text-warning">Submission not found</label>
              </div>
            </div>
            <div class="form-group" *ngIf="inputFormGroup.controls.AtsClientId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">Client Company</label>
              <div class="col-lg-6">
                <label class="control-label">{{inputFormGroup.controls.AtsClientId.value}} - {{inputFormGroup.controls.AtsClientDisplayName.value}}</label>
              </div>
            </div>
            <div class="form-group" *ngIf="inputFormGroup.controls.AtsWorkerId.value>0">
              <label class="col-lg-4 control-label label-to-right" data-original-title="" title="">User Profile Worker</label>
              <div class="col-lg-6">
                <label class="control-label">{{inputFormGroup.controls.AtsWorkerId.value}} - {{inputFormGroup.controls.AtsWorkerName.value}}</label>
              </div>
            </div>
          </app-phx-panel>
          <div class="form-group">
            <label class="col-lg-4 control-label label-to-right" title="">Line Of Business</label>
            <div class="col-lg-6">
              <label class="control-label">{{inputFormGroup.get('LineOfBusinessId').value | CodeValue:codeValueGroups.LineOfBusiness}}</label>
            </div>
          </div>

          <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0">
            <label class="col-lg-4 control-label label-to-right" title="">Job Owner</label>
            <div class="col-lg-6" *ngIf="inputFormGroup.controls.FboJobOwnerUserProfileId.value > 0">
              <label class="control-label">{{inputFormGroup.controls.FboJobOwnerUserProfileId.value}} - {{inputFormGroup.controls.FboJobOwnerName.value}}</label>
              <span class="bold text-warning" *ngIf="!inputFormGroup.controls.FboJobOwnerIsActive.value">[Profile is inactive]</span>
            </div>
            <div class="col-lg-6" *ngIf="inputFormGroup.controls.AtsJobOwnerId.value > 0 && inputFormGroup.controls.FboJobOwnerUserProfileId.value === 0">
              <label class="control-label text-warning">Unable to find {{inputFormGroup.controls.AtsJobOwnerName.value}} in FBO</label>
            </div>
          </div>

          <div class="form-group" *ngIf="inputFormGroup.controls.AtsPlacementId.value>0 && isRegularPlacement">
            <label class="col-lg-4 control-label label-to-right" title="">Recruiter</label>
            <div class="col-lg-6" *ngIf="inputFormGroup.controls.FboRecruiterUserProfileId.value > 0">
              <label class="control-label">{{inputFormGroup.controls.FboRecruiterUserProfileId.value}} - {{inputFormGroup.controls.FboRecruiterName.value}}</label>
              <span class="bold text-warning" *ngIf="!inputFormGroup.controls.FboRecruiterIsActive.value">[Profile is inactive]</span>
            </div>
            <div class="col-lg-6" *ngIf="inputFormGroup.controls.AtsRecruiterId.value > 0 && inputFormGroup.controls.FboRecruiterUserProfileId.value === 0">
              <label class="control-label text-warning">Unable to find {{inputFormGroup.controls.AtsRecruiterName.value}} in FBO</label>
            </div>
          </div>

          <ng-container *ngIf="inputFormGroup.get('MappedOrganizationIdClients').value?.length !== 1">
            <phx-form-control [control]="inputFormGroup.controls.SuggestedOrganizationIdClient" labelText="Work Order Client Company"
              [editable]="true">
              <app-phx-select-box [items]="listOrganizationClient" textField="DisplayText" valueField="Id"
                formControlName="SuggestedOrganizationIdClient">
              </app-phx-select-box>
            </phx-form-control>
          </ng-container>
          <ng-container *ngIf="inputFormGroup.get('MappedOrganizationIdClients').value?.length === 1">
            <label class="col-lg-4 control-label" data-original-title="" title="">Mapped Client Company</label>
            <div class="col-lg-8">
              <label class="control-label" *ngIf="inputFormGroup.get('SuggestedOrganizationIdClient').value">
                {{inputFormGroup.controls.SuggestedOrganizationIdClient.value|lookup:listOrganizationClient:'Id':'DisplayText'}}
              </label>
            </div>
          </ng-container>
          <ng-container>
            <phx-form-control [control]="inputFormGroup.controls.TemplateId" labelText="Work Order Template" [editable]="true">
              <app-phx-select-box [items]="listFilteredWorkOrderTemplates" textField="DisplayValue" valueField="Id"
                formControlName="TemplateId">
              </app-phx-select-box>
            </phx-form-control>
          </ng-container>
          <ng-container *ngIf="!inputFormGroup.controls.MappedUserProfileIdWorker.value">
            <phx-form-control [control]="inputFormGroup.controls.SuggestedUserProfileIdWorker" labelText="Work Order User Profile Worker"
              [editable]="true">
                <app-phx-select-box-w-tooltip [items]="listUserProfileWorker" textField="DisplayValue" valueField="Id"
                                              formControlName="SuggestedUserProfileIdWorker">
                    <ng-template let-item>
                        <table>
                            <ng-container *ngFor="let field of tooltipFields">
                                <tr>
                                    <td class="text-left prm f12c">{{field.label}}:</td>
                                    <td class="text-left f12c">{{item[field.value] || '-'}}</td>
                                </tr>
                            </ng-container>
                        </table>
                    </ng-template>
                </app-phx-select-box-w-tooltip>
                <div *ngIf="inputFormGroup.controls.SuggestedUserProfileIdWorker.value && selectedProfileWorker" class="selected-profile-worker-box">

                    <div class="row">
                        <div class="col-lg-12">
                            <a routerLink="{{selectedProfileWorkerUrlLink}}" target="_blank">
                                {{selectedProfileWorker.DisplayValue}}
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 bold-thin">Email:</div>
                        <div class="col-lg-9">{{selectedProfileWorker.PrimaryEmail}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 bold-thin">Organization:</div>
                        <div class="col-lg-9">{{selectedProfileWorker.OrganizationName || '-'}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 bold-thin">Update Date:</div>
                        <div class="col-lg-9">{{selectedProfileWorker.LastModifiedDatetime}}</div>
                    </div>
                </div>
            </phx-form-control>
          </ng-container>
          <ng-container *ngIf="inputFormGroup.controls.MappedUserProfileIdWorker.value>0">
            <phx-form-control [control]="inputFormGroup.controls.MappedUserProfileIdWorker" labelText="Mapped User Profile Worker" [editable]="false"
              viewModeText="{{inputFormGroup.controls.MappedUserProfileIdWorker.value}} -
              {{inputFormGroup.controls.MappedUserProfileIdWorker.value | lookup:listUserProfileWorker:'Id':'Contact.FullName'">
            </phx-form-control>
          </ng-container>
        </div>
      </div>
      <div class="col-lg-4">
      </div>
    </fieldset>
  </div>
  <div class="panel-footer" *ngIf="!workOrderCreateInProgress">
    <div class="row">
      <div class="col-lg-6"></div>
      <div class="col-lg-6">
        <div class="pull-right">
          <button type="button" class="btn btn-primary" (click)="validateLOBAndSubmit()" [disabled]="!inputFormGroup.valid || ValidationMessages?.ValidationMessages?.length > 0">Next</button>
        </div>
      </div>
    </div>
  </div>
  
  <app-phx-validation-messages [messages]="ValidationMessages"></app-phx-validation-messages>
</form>
<app-phx-dialog [config]="phxDialogComponentConfigModel"></app-phx-dialog>
