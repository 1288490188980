import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoteService } from '../../services/note.service';
import { PhxNote } from '../../model/phx-note';
import { CommonService, PhxConstants } from '../../index';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-phx-note-header',
  templateUrl: './phx-note-header.component.html',
  styleUrls: ['./phx-note-header.component.less']
})
export class PhxNoteHeaderComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() entityTypeId: PhxConstants.EntityType;
  @Input() entityId: number;
  @Output() navigateToNote = new EventEmitter();
  @Input() showPreview = false;

  notes: Array<PhxNote>;
  noteCount: number;

  readonly dateColumnFormat = PhxConstants.DateFormat.mediumDate;

  constructor(public commonService: CommonService, private noteService: NoteService) {
    super();
  }

  ngOnInit() {
    this.noteService.loadNotes(this.entityTypeId, this.entityId, this.isDestroyed$);
    this.noteService.notes$.pipe(takeUntil(this.isDestroyed$))
      .subscribe(noteStore => {
        noteStore = noteStore || [];
        const note = noteStore.find(n => n.entityId === this.entityId && n.entityTypeId === this.entityTypeId);
        this.notes = note ? note.notes : [];
        this.noteCount = note ? note.noteCount : 0;
      });
  }

  navigateToContactNotes() {
    this.navigateToNote.emit();
  }

  showNote() {
    this.showPreview = true;
  }
}
