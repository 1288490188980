import { Component, inject } from '@angular/core';
import { from, map } from 'rxjs';

import { AuthService } from '@common/services/auth.service';

@Component({
  selector: 'app-phx-change-history-fake-door',
  templateUrl: './phx-change-history-fake-door.component.html',
  styleUrls: ['./phx-change-history-fake-door.component.less', './../phx-state-history/phx-state-history.component.less']
})
export class PhxChangeHistoryFakeDoorComponent {
  isOpen = false;
  readonly canCreateTicket$ = from(inject(AuthService).getUserContext()).pipe(map(context => context.User.CurrentTenantName === 'Procom'));
}
