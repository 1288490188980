<form [formGroup]="purchaseorderForm" *ngIf="purchaseorderForm">
  <div class="content-group form-horizontal">
    <div class="form-group" style="padding-bottom: 0px !important;">
    </div>
    <div class="form-group">
      <label class="h4 bold">Work Orders Details</label>
    </div>

    <div class="panel-internal row table-responsive">
      <table class="table table-striped table-content table-hover">
        <thead style="background-color: #EAEAEA;">
          <tr>
            <th>
              Work Order
            </th>
            <th>
              Worker<br /> Name
            </th>
            <th class="text-right">
              Funds Allocated
            </th>
            <th class="text-right">
              Funds Reserved
            </th>
            <th class="text-right">
              Funds Spent
            </th>
            <th class="text-right">
              Funds Remaining
            </th>
            <th class="text-right">
              Work Units Remaining
            </th>
            <th>
              Allocation Note
            </th>
          </tr>
        </thead>
        <tbody formArrayName="WorkOrderPurchaseOrderLines">
          <tr *ngFor="let item of purchaseorderForm.get('WorkOrderPurchaseOrderLines').controls; let i = index"
            [formGroupName]="i">
            <td style="vertical-align: middle;">
              <a class="form-control-static" (click)="OnNavigate(item)">
                <p class="form-control-static">  {{item.controls.AssignmentId.value }}.{{ item.controls.WorkOrderNumber.value }} </p>
              </a>
            </td>
            <td>
              <p class="form-control-static">
                {{item.controls.WorkerName.value}}</p>
            </td>
            <td>
              <div class="input-group"
                *ngIf="lineNew.DepletionOptionId === phxConstants.DepletionOption.FundsAllocated && (item.controls.Id.value === workOderPurchaseOrderLineId || item.controls.Id.value <= 0); else amountElseBlock">
                <input type="text" formControlName="AmountCommited" class="form-control ng-scope input-align-right"
                  (input)="onCommitChange(purchaseorderForm.get('WorkOrderPurchaseOrderLines').controls)">
              </div>
              <ng-template #amountElseBlock>
                <div *ngIf="lineNew.DepletionOptionId === phxConstants.DepletionOption.FundsAllocated">
                  {{item.controls.AmountCommited.value}}</div>
                <div class="form-control-static pull-right"
                  *ngIf="lineNew.DepletionOptionId !== phxConstants.DepletionOption.FundsAllocated">
                  -</div>
              </ng-template>
            </td>
            <td>
              <p class="form-control-static pull-right">
                {{ item.controls.AmountReserved.value | currency }}</p>
            </td>
            <td>
              <p class="form-control-static pull-right">
                {{ item.controls.AmountSpent.value | currency }}</p>
            </td>
            <td>
              <p class="form-control-static pull-right">
                {{ item.controls.AmountRemaining.value | currency }}</p>
            </td>
            <td>
              <p class="form-control-static pull-right">
                {{(item.controls.Rate.value>0) ? (((item.controls.AmountRemaining.value)/(item.controls.Rate.value  * (1 + (item.controls.IsTaxIncluded.value ? item.controls.SumSalesTaxVersionRate.value : 0))))
                  | number : '1.2-2') : "--" }}
{{item.controls.RateUnitId.value  | lookup:workRateUnitTypes:'id':'code'| slice: 0:1}} </p>
            </td>
            <td>
              <input
                *ngIf="item.controls.Id.value === workOderPurchaseOrderLineId || item.controls.Id.value <= 0; else allocationNoteElseBlock"
                type="text" formControlName="AllocationNote" maxlength="128" class="form-control ng-scope" />
              <ng-template #allocationNoteElseBlock>
                {{item.controls.AllocationNote.value}}
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
