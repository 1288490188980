import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { CodeValueGroups } from '../../../common/model/phx-code-value-groups';
import { PhxConstants } from 'src/app/common';
import { IWorkOrder } from '../../models/workorder.interface';
import { WorkOrderFormService, WorkorderService } from '../../services';

@Component({
  selector: 'app-workorder-tab-core-termination',
  templateUrl: './workorder-tab-core-termination.component.html',
  styleUrls: ['./workorder-tab-core-termination.component.less']
})
export class WorkorderTabCoreTerminationComponent extends BaseComponentOnDestroy implements OnInit {

  workorder: IWorkOrder;
  phxConstants = PhxConstants;
  codeValueGroups = CodeValueGroups;

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private workOrderService: WorkorderService
  ) {
    super();
  }

  ngOnInit() {
    this.workOrderFormService.workOrder$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((workorder: IWorkOrder) => {
        if (workorder) {
          this.workorder = workorder;

          // ASYNC CALL HERE, POTENTIAL RACE CONDITION
          this.workOrderService.getWorkOrderTerminationComment(PhxConstants.EntityType.WorkOrder, this.workorder.WorkOrderId).then(data => {
            this.workorder.TerminationComment = data;
          }).catch(() => {
            this.workorder.TerminationComment = null;
          });
        }
      });
  }
}
