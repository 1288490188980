<form [formGroup]="formGroup">
  <div class="content-group ">
    <div class="content-left">
      <app-phx-panel headerTitle="Timesheet Methodology & Cycle">
        <phx-form-control labelText="Timesheet Methodology"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','TimeSheetMethodologyId')"
          [control]="formGroup.controls.TimeSheetMethodologyId"
          viewModeText="{{formGroup.controls.TimeSheetMethodologyId.value | CodeValue:codeValueGroups.TimeSheetMethodology }}">
          <app-phx-select-box [items]="html.codeValueLists.listTimesheetMethodologies" textField="text"
            valueField="id" [formControl]="formGroup.controls.TimeSheetMethodologyId">
          </app-phx-select-box>
        </phx-form-control>
        <ng-container *ngIf="html.timeCard.timeCardCycle">
          <phx-form-control labelText="Timesheet Cycle"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','TimeSheetCycleId')"
            [control]="formGroup.controls.TimeSheetCycleId"
            viewModeText="{{formGroup.controls.TimeSheetCycleId.value | CodeValue:codeValueGroups.TimeSheetCycle }}">
            <app-phx-select-box [items]="html.codeValueLists.listTimesheetCycles" textField="text" valueField="id"
              [formControl]="formGroup.controls.TimeSheetCycleId">
            </app-phx-select-box>
          </phx-form-control>
        </ng-container>
        <phx-form-control labelText="Seasonal/Part-Time Timesheets"
          [control]="formGroup.controls['IsSeasonalOrPartTimeTimesheet']"
          [editable]="readOnlyStorage.IsEditable"
          viewModeText="{{formGroup.controls['IsSeasonalOrPartTimeTimesheet'].value ? 'Yes' : 'No'}}">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="true"
              formControlName="IsSeasonalOrPartTimeTimesheet">Yes</label>
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="false"
              formControlName="IsSeasonalOrPartTimeTimesheet">No</label>
        </phx-form-control>
      </app-phx-panel>
    </div>
    <div class="content-right" *ngIf="html.timeCard.timeCardApproval">
      <app-phx-panel headerTitle="Timesheet Approval">
        <div formArrayName="TimeSheetApprovers">
          <div
            *ngFor="let item of timeSheetApproversFormArray.controls; let i = index; let last = last; trackBy: trackByFn">
            <div [formGroup]="item">
              <phx-form-control labelText="Client Approver"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.TimeSheetApprovers','UserProfileId')"
                [control]="item.get('UserProfileId')"
                viewModeText="{{item.get('UserProfileId').value | lookup: html.commonLists.listProfilesForApproval:'Id':'Contact.FullName'}} - {{item.get('UserProfileId').value}}">
                <div [ngClass]="{'col-md-10 padding-0': (i !== 0)}">
                  <app-phx-select-box
                    [items]="html.commonLists.listProfilesForApproval | filterSelectChoicesByUsage: formGroup.get('TimeSheetApprovers').value: item.value: 'UserProfileId': 'Id': 'Contact.FullName': 'Contact.Id'"
                    textField="DisplayValue" valueField="Id" [formControl]="item.get('UserProfileId')"
                    placeholder="Approver Name Search">
                  </app-phx-select-box>
                </div>
                <div class="col-xs-1 pull-right padding-0" *ngIf="i !== 0">
                  <button type="button" class="btn btn-default btn-material pull-right"
                    (click)="removeTimeSheetApproverDefinition(i)">
                    <i class="material-icons">delete</i>
                  </button>
                </div>
              </phx-form-control>
              <div class="form-group">
                <div class="col-lg-2 pull-right" *ngIf="last && readOnlyStorage.IsEditable">
                  <button type="button" class="btn btn-default btn-material pull-right add-btn-group"
                    (click)="addTimeSheetApproverDefinition()" title="Add a new Timesheet Approver"
                    [disabled]="formGroup.get('TimeSheetApprovers').value?.length === html.commonLists.listProfilesForApproval.length">
                    <i class="material-icons">add</i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="formGroup.get('TimeSheetApprovers').value?.length > 1">
          <phx-form-control labelText="Timecard Approval Flow Type"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','TimeSheetApprovalFlowId')"
            [control]="formGroup.controls.TimeSheetApprovalFlowId"
            viewModeText="{{formGroup.controls.TimeSheetApprovalFlowId.value | CodeValue:codeValueGroups.TimeSheetApprovalFlow }}">
            <app-phx-select-box [items]="html.codeValueLists.listTimeSheetApprovalFlows" textField="text"
              valueField="id" [formControl]="formGroup.controls.TimeSheetApprovalFlowId">
            </app-phx-select-box>
          </phx-form-control>
        </ng-container>
      </app-phx-panel>
    </div>
  </div>
  <div class="content-group">
    <div class="content-left" *ngIf="html.timeCard.projectsAndCoding">
      <app-phx-panel headerTitle="Projects & Coding">
        <phx-form-control labelText="Timesheet Uses Projects?"
          [control]="formGroup.controls['IsTimeSheetUsesProjects']"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsTimeSheetUsesProjects')"
          viewModeText="{{formGroup.controls['IsTimeSheetUsesProjects'].value ? 'Yes' : 'No'}}">
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="true"
              formControlName="IsTimeSheetUsesProjects">Yes</label>
          <label class="radio-inline">
            <input class="form-check-input" type="radio" [value]="false"
              formControlName="IsTimeSheetUsesProjects">No</label>
        </phx-form-control>
        <ng-container *ngIf="formGroup.controls['IsTimeSheetUsesProjects'].value">
          <phx-form-control labelText="Project Use Mandatory?"
            [control]="formGroup.controls['IsTimesheetProjectMandatory']"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsTimesheetProjectMandatory')"
            viewModeText="{{formGroup.controls['IsTimesheetProjectMandatory'].value ? 'Yes' : 'No'}}">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" [value]="true"
                formControlName="IsTimesheetProjectMandatory">Yes</label>
            <label class="radio-inline">
              <input class="form-check-input" type="radio" [value]="false"
                formControlName="IsTimesheetProjectMandatory">No</label>
          </phx-form-control>
        </ng-container>
      </app-phx-panel>
    </div>
    <div class="content-right">
      <app-phx-panel headerTitle="Configuration & Descriptors">
        <phx-form-control [control]="formGroup.controls.VmsWorkOrderReference"
          [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','VmsWorkOrderReference')"
          labelText="Third Party Worker ID" viewModeText="{{formGroup.controls.VmsWorkOrderReference.value}}">
          <span class="input-display-cell">
            <input
              appTrimInputSpaces
              formControlName="VmsWorkOrderReference"
              type="text"
              class="form-control"
            />
          </span>
          <span class="input-group-addon" style="padding:3px 6px;">
            <i class="material-icons">account_box</i>
          </span>
        </phx-form-control>
        <ng-container *ngIf="html.timeCard.timecardDescription">
          <phx-form-control [control]="formGroup.controls.TimeSheetDescription"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','TimeSheetDescription')"
            labelText="Timecard Description" viewModeText="{{formGroup.controls.TimeSheetDescription.value}}">
            <input formControlName="TimeSheetDescription" type="text" class="form-control" maxlength="200" />
          </phx-form-control>
        </ng-container>
      </app-phx-panel>
    </div>
  </div>
</form>
