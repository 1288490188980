import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeENCA from '@angular/common/locales/en-CA';
import localeFRCA from '@angular/common/locales/fr-CA';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { DragAndDropModule } from 'angular-draggable-droppable';
import {
  DxButtonModule,
  DxCalendarModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownButtonModule,
  DxListModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTagBoxModule,
  DxTemplateHost,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  WatcherHelper
} from 'devextreme-angular';
import { FileUploadModule } from 'ng2-file-upload';
import { CookieModule } from 'ngx-cookie';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AssociatedWorkordersComponent } from './components/associated-workorders/associated-workorders.component';
import { CurrentUsersWithBehaviorComponent } from './components/current-users-with-behavior/current-users-with-behavior.component';
import { DocumentCollectorButtonComponent } from './components/document-collector-button/document-collector-button.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { PhxAccordionComponent } from './components/phx-accordion/phx-accordion.component';
import { PhxAccordionGroupComponent } from './components/phx-accordion-group/phx-accordion-group.component';
import { PhxAdvanceFilterComponent } from './components/phx-advance-filter/phx-advance-filter.component';
import { PhxAzsearchColumnChooserComponent } from './components/phx-azsearch-column-chooser/phx-azsearch-column-chooser.component';
import { PhxAZSearchDataTableComponent } from './components/phx-azsearch-data-table/phx-azsearch-data-table.component';
import { PhxBadgedAccordionGroupComponent } from './components/phx-badged-accordion-group/phx-badged-accordion-group.component';
import { PhxBannerComponent } from './components/phx-banner/phx-banner.component';
import { PhxCalendarComponent } from './components/phx-calendar/phx-calendar.component';
import { PhxChangeHistoryFakeDoorComponent } from './components/phx-change-history-fake-door/phx-change-history-fake-door.component';
import { PhxChecklistListComponent } from './components/phx-checklist-list/phx-checklist-list.component';
import { PhxCognitiveSearchGridComponent } from './components/phx-cognitive-search-grid/phx-cognitive-search-grid.component';
import { PhxCustomFieldValueComponent } from './components/phx-custom-field-value/phx-custom-field-value.component';
import { PhxDataTableComponent } from './components/phx-data-table/phx-data-table.component';
import { PhxDateBoxComponent } from './components/phx-date-box/phx-date-box.component';
import { PhxDialogComponent } from './components/phx-dialog/phx-dialog.component';
import { PhxDialogCommentComponent } from './components/phx-dialog-comment/phx-dialog-comment.component';
import { PhxDialogCommentTemplateComponent } from './components/phx-dialog-comment-template/phx-dialog-comment-template.component';
import { PhxDialogConfirmTemplateComponent } from './components/phx-dialog-confirm-template/phx-dialog-confirm-template.component';
import { PhxDialogErrorTemplateComponent } from './components/phx-dialog-error-template/phx-dialog-error-template.component';
import { PhxDialogNotifyTemplateComponent } from './components/phx-dialog-notify-template/phx-dialog-notify-template.component';
import { PhxDocumentFileUploadComponent } from './components/phx-document-file-upload/phx-document-file-upload.component';
import { PhxDocumentFileUploadPreviewModalComponent } from './components/phx-document-file-upload-preview-modal/phx-document-file-upload-preview-modal.component';
import { PhxEntityDocumentsComponent } from './components/phx-entity-documents/phx-entity-documents.component';
import { PhxEntityDocumentsListComponent } from './components/phx-entity-documents-list/phx-entity-documents-list.component';
import { PhxFabComponent } from './components/phx-fab/phx-fab.component';
import { PhxFormControlLabelComponent } from './components/phx-form-control/phx-form-control-label.component';
import { PhxFormControlValidationComponent } from './components/phx-form-control/phx-form-control-validation.component';
import { PhxFormControlComponent } from './components/phx-form-control/phx-form-control.component';
import { PhxHeaderVersionComponent } from './components/phx-header-version/phx-header-version.component';
import { PhxInterceptPanelComponent } from './components/phx-intercept-panel/phx-intercept-panel.component';
import { PhxLayoutBannerComponent } from './components/phx-layout-banner/phx-layout-banner.component';
import { PhxMatSearchableDropdownComponent } from './components/phx-mat-searchable-dropdown/phx-mat-searchable-dropdown.component';
import { PhxMatToastComponent } from './components/phx-mat-toast/phx-mat-toast.component';
import { PhxModalComponent } from './components/phx-modal/phx-modal.component';
import { PhxModalDatePickerComponent } from './components/phx-modal-date-picker/phx-modal-date-picker.component';
import { PhxNavigationBarComponent } from './components/phx-navigation-bar/phx-navigation-bar.component';
import { PhxNoteHeaderComponent } from './components/phx-note-header/phx-note-header.component';
import { PhxNotesComponent } from './components/phx-notes/phx-notes.component';
import { PhxPanelComponent } from './components/phx-panel/phx-panel.component';
import { PhxPiiFieldComponent } from './components/phx-pii-field/phx-pii-field.component';
import { PhxProfilePictureComponent } from './components/phx-profile-picture/phx-profile-picture.component';
import { PhxResponsiveButtonsComponent } from './components/phx-responsive-buttons/phx-responsive-buttons.component';
import { PhxSelectBoxComponent } from './components/phx-select-box/phx-select-box.component';
import { PhxSelectBoxBadgeComponent } from './components/phx-select-box-badge/phx-select-box-badge.component';
import { PhxSelectBoxCodeValueComponent } from './components/phx-select-box-code-value/phx-select-box-code-value.component';
import { PhxSelectBoxWTooltipComponent } from './components/phx-select-box-w-tooltip/phx-select-box-w-tooltip.component';
import { PhxSliderComponent } from './components/phx-slider/phx-slider.component';
import { PhxStateActionComponent } from './components/phx-state-action/phx-state-action.component';
import { PhxStateActionButtonsComponent } from './components/phx-state-action-buttons/phx-state-action-buttons.component';
import { PhxStateHistoryComponent } from './components/phx-state-history/phx-state-history.component';
import { PhxStateHistoryTooltipComponent } from './components/phx-state-history-tooltip/phx-state-history-tooltip.component';
import { PhxSwitchVersionComponent } from './components/phx-switch-version/phx-switch-version.component';
import { PhxTagBoxComponent } from './components/phx-tag-box/phx-tag-box.component';
import { PhxTextareaExpandingComponent } from './components/phx-textarea-expanding/phx-textarea-expanding.component';
import { PhxTimesheetOverrideExceptionComponent } from './components/phx-timesheet-override-exception/phx-timesheet-override-exception.component';
import { AvailableOverrideExceptionCodePipe } from './components/phx-timesheet-override-exception/pipes/available-override-exception-code.pipe';
import { PhxToastComponent } from './components/phx-toast/phx-toast.component';
import { PhxToolTipComponent } from './components/phx-tooltip/phx-tooltip.component';
import { PhxValidationFailuresComponent } from './components/phx-validation-failures/phx-validation-failures.component';
import { PhxValidationMessagesComponent } from './components/phx-validation-messages/phx-validation-messages.component';
import { PhxVersionComparisonToggleComponent } from './components/phx-version-comparison-toggle/phx-version-comparison-toggle.component';
import { PhxWorkflowButtonsComponent } from './components/phx-workflow-buttons/phx-workflow-buttons.component';
import { PhxWorkflowChangeHistoryComponent } from './components/phx-workflow-change-history/phx-workflow-change-history.component';
import { PhxWorkflowEventHistoryComponent } from './components/phx-workflow-event-history/phx-workflow-event-history.component';
import { ReportShareDialogComponent } from './components/report-share-dialog/report-share-dialog.component';
import { ReportShareeComponent } from './components/report-share-dialog/report-sharee/report-sharee.component';
import { RoeModalComponent } from './components/roe-modal/roe-modal.component';
import { SelectWorkerProfileComponent } from './components/select-worker-profile/select-worker-profile.component';
import { ServiceUnavailableComponent } from './components/service-unavailable/service-unavailable.component';
import { TimeSheetAutoCalculatedOvertimeDetailsComponent } from './components/time-sheet-auto-calculated-overtime-details/time-sheet-auto-calculated-overtime-details.component';
import { TimeSheetOvertimeBreakdownComponent } from './components/time-sheet-overtime-breakdown/time-sheet-overtime-breakdown.component';
import { TimeSheetRateLabelComponent } from './components/time-sheet-rate-label/time-sheet-rate-label.component';
import { CollapseDirective } from './directives/collapse.directive';
import { CollectClickDataAnalyticsDirective } from './directives/collect-click-data-analytics/collect-click-data-analytics.directive';
import { CurrencyFormatterDirective } from './directives/currencyFormatter.directive';
import { DebounceDirective } from './directives/debounce.directive';
import { EllipsisTipDirective } from './directives/ellipsis-tip.directive';
import { EqualHeightDirective } from './directives/equalHeights.directive';
import { FloatBetweenInputDirective } from './directives/floatBetweenInput.directive';
import { InputTextLimitWithDecimalsDirective } from './directives/inputTextLimitWithDecimals.directive';
import { MaxDateYesterdayDirective } from './directives/max-date-yesterday/max-date-yesterday.directive';
import { PostalZipCodeValidDirective } from './directives/postalZipCodeValid.directive';
import { ResizableDirective } from './directives/resizable/resizable.directive';
import { TrimInputSpacesDirective } from './directives/trim-input-spaces/trim-input-spaces.directive';
import { BouncingBallsComponent } from './loading-spinner/component/bouncing-balls/bouncing-balls.component';
import { LoadingSpinnerComponent } from './loading-spinner/component/loading-spinner/loading-spinner.component';
import { LoadingSpinnerOverlayComponent } from './loading-spinner/component/loading-spinner-overlay/loading-spinner-overlay.component';
import { AvailablePaymentMethodsPipe } from './pipes/available-payment-methods/available-payment-methods.pipe';
import { BoolToTextPipe } from './pipes/bool-to-text.pipe';
import { BoolToYesNoPipe } from './pipes/bool-to-yes-no.pipe';
import { ChangeHistoryIsBlackFilterPipe } from './pipes/change-history-is-black-filter.pipe';
import { CodeValueByCodePipe } from './pipes/code-value-by-code.pipe';
import { CodeValuePipe } from './pipes/code-value.pipe';
import { FieldNamePipe } from './pipes/field-name/field-name.pipe';
import { FilterSelectChoicesByUsage } from './pipes/filterSelectChoicesByUsage.pipe';
import { HumanReadableFileSizePipe } from './pipes/human-readable-file-size.pipe';
import { JoinPipe } from './pipes/join/join.pipe';
import { KeyValuePipe } from './pipes/key-value.pipe';
import { LookupPipe } from './pipes/lookup.pipe';
import { PhxDisplayPercentWithDecimalsPipe } from './pipes/phx-display-percent-with-decimals.pipe';
import { PhxFormatPhonePipe } from './pipes/phx-format-phone.pipe';
import { PhxLocalizationPipe } from './pipes/phx-localization.pipe';
import { PhxTimeWithUnit } from './pipes/phx-time-with-unit.pipe';
import { PhxTypedLocalizationPipe } from './pipes/phx-typed-localization';
import { PhxWorkflowHistorySearchPipe } from './pipes/phx-workflow-history-search.pipe';
import { PhxWorkflowStatusNamePipe } from './pipes/phx-workflow-status-name.pipe';
import { PhxDisplayCurrency } from './pipes/phxDisplayCurrency.pipe';
import { PxCurrencyPipe } from './pipes/pxCurrency.pipe';
import { PxCurrencyFormatter } from './pipes/pxCurrencyFormatter.pipe';
import { RelativeDatePipe } from './pipes/relative-date/relative-date.pipe';
import { ReplaceHtmlBreakWithNewLinePipe } from './pipes/replace-html-break-with-new-line.pipe';
import { ReportGridCellLookupPipe } from './pipes/report-gridCell-lookup-pipe';
import { SanitizeResourceUrlPipe } from './pipes/sanitize-resource-url.pipe';
import { SortArrayOfObjectsPipe } from './pipes/sortArrayOfObjects.pipe';
import { SpoofValueForTenantPipe } from './pipes/spoof-value-for-tenant/spoof-value-for-tenant.pipe';
import { WorkflowHistoryPropertyPathPipe } from './pipes/workflow-history-property-path.pipe';
import { AggregateSummarizerService } from './services/aggregateSummarizer.service';
import { AuditHistoryService } from './services/audit-history.service';
import { CodeValueService } from './services/code-value.service';
import { CookieService } from './services/cookie.service';
import { CustomFieldService } from './services/custom-field.service';
import { DialogService } from './services/dialog.service';
import { EventService } from './services/event.service';
import { PhxDataTableService } from './services/phx-data-table.service';
import { PhxLocalizationService } from './services/phx-localization.service';
import { PhxToastService } from './services/phxToast.service';
import { ReportViewStateService } from './services/report-view-state.service';
import { ReportService } from './services/report.service';
import { UtilityService } from './services/utility-service.service';
import { WorkflowService } from './services/workflow.service';

registerLocaleData(localeENCA);
registerLocaleData(localeFRCA);
@NgModule({
  imports: [
    DxTooltipModule,
    DxPopoverModule,
    CommonModule,
    HttpClientModule,
    DxButtonModule,
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxPopupModule,
    DxTemplateModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxRadioGroupModule,
    DxCalendarModule,
    DxTagBoxModule,
    DragAndDropModule,
    RouterModule,
    CookieModule.forRoot(),
    DxTooltipModule,
    DxSwitchModule,
    DxListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    ScrollingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    FileUploadModule,
    MatRippleModule,
    MatMenuModule,
    NgxSkeletonLoaderModule,
    DxDropDownButtonModule,
    MatDialogModule,
    MatProgressBarModule
  ],
  declarations: [
    FieldNamePipe,
    ResizableDirective,
    ReportGridCellLookupPipe,
    PhxLocalizationPipe,
    CodeValuePipe,
    CodeValueByCodePipe,
    PhxWorkflowButtonsComponent,
    PhxDialogCommentComponent,
    SortArrayOfObjectsPipe,
    PxCurrencyPipe,
    ChangeHistoryIsBlackFilterPipe,
    CurrencyFormatterDirective,
    PhxValidationMessagesComponent,
    PhxWorkflowEventHistoryComponent,
    PhxDialogComponent,
    DebounceDirective,
    PhxNavigationBarComponent,
    PhxDataTableComponent,
    PhxAZSearchDataTableComponent,
    PhxAccordionComponent,
    PhxAccordionGroupComponent,
    PhxBadgedAccordionGroupComponent,
    PhxSliderComponent,
    PhxTextareaExpandingComponent,
    PhxFormControlComponent,
    PhxFormControlLabelComponent,
    PhxFormControlValidationComponent,
    PhxDateBoxComponent,
    PhxModalDatePickerComponent,
    KeyValuePipe,
    CollapseDirective,
    PhxCustomFieldValueComponent,
    ReplaceHtmlBreakWithNewLinePipe,
    PhxInterceptPanelComponent,
    PhxEntityDocumentsComponent,
    PhxSelectBoxComponent,
    PhxSelectBoxCodeValueComponent,
    PhxSelectBoxBadgeComponent,
    PhxPanelComponent,
    PhxTagBoxComponent,
    PhxFabComponent,
    PhxModalComponent,
    PhxProfilePictureComponent,
    PhxResponsiveButtonsComponent,
    FloatBetweenInputDirective,
    PhxDisplayPercentWithDecimalsPipe,
    InputTextLimitWithDecimalsDirective,
    PhxStateActionButtonsComponent,
    PhxStateActionComponent,
    PhxAdvanceFilterComponent,
    PhxNotesComponent,
    LookupPipe,
    PhxDisplayCurrency,
    FilterSelectChoicesByUsage,
    BoolToYesNoPipe,
    BoolToTextPipe,
    PostalZipCodeValidDirective,
    PhxCalendarComponent,
    PhxEntityDocumentsListComponent,
    PhxNoteHeaderComponent,
    PhxCalendarComponent,
    PxCurrencyFormatter,
    PhxToolTipComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerOverlayComponent,
    PhxDialogErrorTemplateComponent,
    PhxDialogNotifyTemplateComponent,
    PhxDialogConfirmTemplateComponent,
    PhxDialogCommentTemplateComponent,
    PhxStateHistoryComponent,
    EllipsisTipDirective,
    PhxTimeWithUnit,
    EqualHeightDirective,
    PhxStateHistoryTooltipComponent,
    PhxTypedLocalizationPipe,
    PhxToastComponent,
    PhxFormatPhonePipe,
    PhxBannerComponent,
    PhxSelectBoxWTooltipComponent,
    PhxChangeHistoryFakeDoorComponent,
    PhxPiiFieldComponent,
    ServiceUnavailableComponent,
    PhxMatSearchableDropdownComponent,
    PhxMatToastComponent,
    HumanReadableFileSizePipe,
    RelativeDatePipe,
    ReportShareDialogComponent,
    ReportShareeComponent,
    AssociatedWorkordersComponent,
    PhxCognitiveSearchGridComponent,
    PhxDocumentFileUploadComponent,
    PhxStateActionComponent,
    PhxWorkflowChangeHistoryComponent,
    PhxWorkflowHistorySearchPipe,
    PhxSwitchVersionComponent,
    PhxHeaderVersionComponent,
    PhxValidationFailuresComponent,
    PhxWorkflowStatusNamePipe,
    PhxAzsearchColumnChooserComponent,
    MaxDateYesterdayDirective,
    PhxTimesheetOverrideExceptionComponent,
    AvailableOverrideExceptionCodePipe,
    SanitizeResourceUrlPipe,
    PhxChecklistListComponent,
    CurrentUsersWithBehaviorComponent,
    AvailablePaymentMethodsPipe,
    NoAccessComponent,
    SpoofValueForTenantPipe,
    CollectClickDataAnalyticsDirective,
    BouncingBallsComponent,
    PhxVersionComparisonToggleComponent,
    BouncingBallsComponent,
    TrimInputSpacesDirective,
    PhxLayoutBannerComponent,
    SelectWorkerProfileComponent,
    WorkflowHistoryPropertyPathPipe,
    DocumentCollectorButtonComponent,
    PhxDocumentFileUploadPreviewModalComponent,
    JoinPipe,
    TimeSheetAutoCalculatedOvertimeDetailsComponent,
    TimeSheetOvertimeBreakdownComponent,
    TimeSheetRateLabelComponent,
    RoeModalComponent
  ],
  exports: [
    FieldNamePipe,
    PhxLocalizationPipe,
    DragAndDropModule,
    CodeValuePipe,
    CodeValueByCodePipe,
    PhxWorkflowButtonsComponent,
    PhxDialogCommentComponent,
    SortArrayOfObjectsPipe,
    PxCurrencyPipe,
    ChangeHistoryIsBlackFilterPipe,
    CurrencyFormatterDirective,
    PhxValidationMessagesComponent,
    PhxWorkflowEventHistoryComponent,
    PhxModalDatePickerComponent,
    DebounceDirective,
    PhxNavigationBarComponent,
    PhxDataTableComponent,
    PhxAZSearchDataTableComponent,
    PhxAccordionComponent,
    PhxAccordionGroupComponent,
    PhxBadgedAccordionGroupComponent,
    PhxTextareaExpandingComponent,
    PhxFormControlComponent,
    PhxFormControlLabelComponent,
    PhxFormControlValidationComponent,
    PhxSliderComponent,
    PhxDateBoxComponent,
    PhxDialogComponent,
    KeyValuePipe,
    CollapseDirective,
    PhxCustomFieldValueComponent,
    ReplaceHtmlBreakWithNewLinePipe,
    PhxInterceptPanelComponent,
    PhxEntityDocumentsComponent,
    PhxSelectBoxComponent,
    PhxSelectBoxCodeValueComponent,
    PhxSelectBoxBadgeComponent,
    PhxPanelComponent,
    PhxTagBoxComponent,
    PhxFabComponent,
    PhxModalComponent,
    PhxProfilePictureComponent,
    PhxResponsiveButtonsComponent,
    FloatBetweenInputDirective,
    PhxDisplayPercentWithDecimalsPipe,
    FilterSelectChoicesByUsage,
    InputTextLimitWithDecimalsDirective,
    PhxStateActionButtonsComponent,
    PhxStateActionComponent,
    PhxAdvanceFilterComponent,
    LookupPipe,
    PhxDisplayCurrency,
    BoolToYesNoPipe,
    BoolToTextPipe,
    PostalZipCodeValidDirective,
    PhxCalendarComponent,
    PhxEntityDocumentsListComponent,
    PhxNotesComponent,
    PhxNoteHeaderComponent,
    PhxCalendarComponent,
    PxCurrencyFormatter,
    PhxToolTipComponent,
    LoadingSpinnerComponent,
    BouncingBallsComponent,
    LoadingSpinnerOverlayComponent,
    PhxDialogErrorTemplateComponent,
    PhxDialogNotifyTemplateComponent,
    PhxDialogConfirmTemplateComponent,
    PhxDialogCommentTemplateComponent,
    PhxStateHistoryComponent,
    PhxBannerComponent,
    PhxSelectBoxWTooltipComponent,
    EllipsisTipDirective,
    PhxTimeWithUnit,
    EqualHeightDirective,
    PhxStateHistoryTooltipComponent,
    PhxTypedLocalizationPipe,
    PhxToastComponent,
    PhxFormatPhonePipe,
    PhxChangeHistoryFakeDoorComponent,
    PhxMatSearchableDropdownComponent,
    PhxMatToastComponent,
    HumanReadableFileSizePipe,
    RelativeDatePipe,
    ReportShareDialogComponent,
    AssociatedWorkordersComponent,
    PhxCognitiveSearchGridComponent,
    PhxDocumentFileUploadComponent,
    PhxWorkflowChangeHistoryComponent,
    PhxWorkflowHistorySearchPipe,
    PhxSwitchVersionComponent,
    PhxHeaderVersionComponent,
    PhxValidationFailuresComponent,
    PhxWorkflowStatusNamePipe,
    MaxDateYesterdayDirective,
    PhxTimesheetOverrideExceptionComponent,
    SanitizeResourceUrlPipe,
    PhxChecklistListComponent,
    CurrentUsersWithBehaviorComponent,
    AvailablePaymentMethodsPipe,
    NoAccessComponent,
    SpoofValueForTenantPipe,
    CollectClickDataAnalyticsDirective,
    PhxVersionComparisonToggleComponent,
    TrimInputSpacesDirective,
    ResizableDirective,
    SelectWorkerProfileComponent,
    PhxLayoutBannerComponent,
    DocumentCollectorButtonComponent,
    JoinPipe,
    TimeSheetAutoCalculatedOvertimeDetailsComponent,
    TimeSheetOvertimeBreakdownComponent,
    TimeSheetRateLabelComponent,
    RoeModalComponent
  ],
  providers: [
    EventService,
    CurrencyPipe,
    WorkflowService,
    CodeValueService,
    DxTemplateHost,
    WatcherHelper,
    DialogService,
    AggregateSummarizerService,
    CustomFieldService,
    ReportService,
    CookieService,
    UtilityService,
    PxCurrencyPipe,
    PhxDataTableService,
    PhxToastService,
    ReportViewStateService,
    AuditHistoryService,
    PhxWorkflowStatusNamePipe,
    {
      provide: LOCALE_ID,
      useFactory: () => PhxLocalizationService.locale
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PhoenixCommonModule {}

export * from './utility/property';
export * from './utility/uuid';
export * from './model/phx-constants';
export * from './PhoenixCommonModule.resource-keys';
