<div *ngIf="showBanner" [ngClass]="style" class="alert">
  <div class="row">
    <div class="col-md-8 ">
        <span class="banner-box">
            <i class="alert-icon material-icons">{{ icon }}</i>
        </span>
        <div class="banner-box"> 
          <strong class="status-title">{{ head }} </strong> 
          <span [innerHTML]="messageBody"></span> 
        </div>
    </div>
    <div class="col-md-4">
        <div *ngIf="!isOffboardingCompleted" >
          <span class="pull-right">
            <app-phx-state-action-buttons [stateActions]="stateActionsFiltered" 
                                          [availableStateActions]="availableStateActionsFiltered"></app-phx-state-action-buttons>
          </span>
        </div>
        <button *ngIf="isOffboardingCompleted" type="button" class="close close-stage" (click)="closeBanner()">
          <i class="large material-icons">close</i>
        </button> 
    </div>
  </div>
</div>
