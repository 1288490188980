<div [formGroup]="inputFormGroup">
  <ng-container *ngIf="IsVacationPay">
    <phx-form-control labelText="Use vacation setup from Worker Profile?"
      [control]="inputFormGroup.controls.UseWorkerProfileVacationSetup" viewModeText="{{ inputFormGroup.controls.UseWorkerProfileVacationSetup.value | boolToYesNo}}"
      [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings','IsApplied')">
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="true" formControlName="UseWorkerProfileVacationSetup">Yes</label>
      <label class="radio-inline">
        <input class="form-check-input" type="radio" [value]="false" formControlName="UseWorkerProfileVacationSetup">No</label>
    </phx-form-control>
  </ng-container>
  <ng-container *ngIf="!IsVacationPay || (IsVacationPay && !inputFormGroup.controls.UseWorkerProfileVacationSetup.value)">
    <div class="form-group">
      <div class="col-lg-4 text-right">
        <phx-form-control-label [control]="inputFormGroup">Apply
          {{inputFormGroup.controls.PaymentOtherEarningTypeId.value
          | lookup:html.commonLists.paymentOtherEarningTypeList:'id':'text'}}</phx-form-control-label>
      </div>
      <div class="col-lg-8">
        <div class="col-lg-6 no-padding">
          <phx-form-control labelText="" [layoutType]="layoutType.InputOnly" [control]="inputFormGroup.controls.IsApplied"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings','IsApplied')"
            viewModeText="{{inputFormGroup.value.IsApplied | boolToYesNo}}">
            <label class="radio-inline">
              <input class="form-check-input" type="radio" [value]="true" formControlName="IsApplied">Yes</label>
            <label class="radio-inline">
              <input class="form-check-input" type="radio" [value]="false" formControlName="IsApplied">No</label>
          </phx-form-control>
        </div>
        <div class="col-lg-6 no-padding" *ngIf="inputFormGroup.controls.IsApplied.value">
          <phx-form-control labelText="" [layoutType]="layoutType.InputOnly" [control]="inputFormGroup.controls.RatePercentage"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings','RatePercentage')"
            viewModeText="{{inputFormGroup.value.RatePercentage / 100 | percent: '1.4-4'}}">
            <div class="input-group">
              <input [inputTextLimitWithDecimals]="percentageFilter" type="text" class="form-control ng-scope"
                formControlName="RatePercentage" />
              <span class="input-group-addon">%</span>
            </div>
          </phx-form-control>
        </div>
      </div>
    </div>
    <ng-container *ngIf="inputFormGroup.controls.IsApplied.value && IsVacationPay">
      <phx-form-control labelText="Accrue {{inputFormGroup.controls.PaymentOtherEarningTypeId.value | lookup:html.commonLists.paymentOtherEarningTypeList:'id':'text'}}"
        [control]="inputFormGroup.controls.IsAccrued" viewModeText="{{inputFormGroup.controls.IsAccrued.value | boolToYesNo}}"
        [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings','IsAccrued')">
        <label class="radio-inline">
          <input class="form-check-input" type="radio" [value]="true" formControlName="IsAccrued">Yes</label>
        <label class="radio-inline">
          <input class="form-check-input" type="radio" [value]="false" formControlName="IsAccrued">No</label>
      </phx-form-control>
    </ng-container>
  </ng-container>
</div>
<app-phx-dialog #phxDialogComponent (callback)="dialogActionCallBackObButtonClick()" [config]="phxDialogComponentConfigModel"></app-phx-dialog>
