<app-phx-modal [buttons]="buttons" cssClass="modal-lg" [showHeader]="true">
    <div class="col-md-12 no-padding-h" custom-title>
        <div class="col-xs-11">
            <div class="col-md-9 no-padding-h">
                <span>{{currentTranslations.title}} <i id="formTooltip"
                        class="material-icons md-18 icon-tooltip">info</i>
                    <app-phx-tool-tip targetId="#formTooltip" position="right">
                        <div class="tool-tip-container">{{currentTranslations.tooltip}}</div>
                    </app-phx-tool-tip>
                </span>
            </div>
            <div class="col-md-3 no-padding-h" *ngIf="!!currentRiskLevel && currentPage < Pages.analysisPage">
                {{currentTranslations.risk}}: <span
                    [class]="'color-' + currentRiskLevel">{{currentTranslations[currentRiskLevel]}}</span>
            </div>
        </div>
        <div class="col-xs-1">
            <span (click)="setLanguage(englishLang)" class="lang-container">{{englishLang}}</span>/<span
                (click)="setLanguage(frenchLang)" class="lang-container">{{frenchLang}}</span>
        </div>
    </div>
    <div class="form-container">
        <form [formGroup]="form">
            <div class="content-group form-horizontal form-row" [ngClass]="{'hidden': currentPage !== Pages.formPage}">
                <div class="col-md-6">
                    <!-- NOTE: first page left column -->
                    <app-phx-panel [headerTitle]="currentTranslations.requestDetails">
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.requestDate" [editable]="true"
                                [labelText]="currentTranslations.requestDate"
                                [viewModeText]="form.controls.requestDate.value | date: PhxConstants.DateFormat.mediumDate">
                                <app-phx-date-box
                                    type="date" [displayFormat]="{ dateStyle: 'medium' }"
                                    [formControl]="form.controls.requestDate"
                                ></app-phx-date-box>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.contractorName" [editable]="false"
                                [labelText]="currentTranslations.contractorName"
                                [viewModeText]="form.controls.contractorName.value">
                                <input [formControl]="form.controls.contractorName" type="text" class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.contractorLocation" [editable]="false"
                                [labelText]="currentTranslations.contractorLocation"
                                [viewModeText]="form.controls.contractorLocationId.value | lookup: workerLocationOptions:'id':'text'">
                                <input [formControl]="form.controls.contractorLocation" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.clientName" [editable]="false"
                                [labelText]="currentTranslations.clientName"
                                [viewModeText]="form.controls.clientName.value">
                                <input [formControl]="form.controls.clientName" type="text" class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.clientLocation" [editable]="false"
                                [labelText]="currentTranslations.clientLocation"
                                [viewModeText]="form.controls.clientLocationId.value | lookup: clientWorksiteOptions:'id':'text'">>
                                <input [formControl]="form.controls.clientLocation" type="text" class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.clientContactName" [editable]="false"
                                [labelText]="currentTranslations.clientContact"
                                [viewModeText]="form.controls.clientContactName.value">
                                <input [formControl]="form.controls.clientContactName" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                    </app-phx-panel>
                    <app-phx-panel [headerTitle]="currentTranslations.engagementDetails">
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.engagementDate" [editable]="false"
                                [labelText]="currentTranslations.assignmentStartDate"
                                [viewModeText]="form.controls.engagementDate.value | date: PhxConstants.DateFormat.mediumDate">
                                <app-phx-date-box
                                    type="date"
                                    [displayFormat]="{ dateStyle: 'medium' }"
                                    [formControl]="form.controls.engagementDate"
                                ></app-phx-date-box>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.assignmentLength" [editable]="false"
                                [labelText]="currentTranslations.assignmentLength"
                                [viewModeText]="form.controls.assignmentLength.value">
                                <input [formControl]="form.controls.assignmentLength" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.timeOnAssignment" [editable]="true"
                                [labelText]="currentTranslations.timeOnAssignment"
                                [viewModeText]="form.controls.timeOnAssignment.value">
                                <input [formControl]="form.controls.timeOnAssignment" type="text" class="form-control"
                                    phxFloatBetweenInput='{"from":0, "to":999999999999.99, "decimalplaces":1}' />
                            </phx-form-control>
                        </div>
                    </app-phx-panel>
                </div>
                <div class="col-md-6">
                    <!-- NOTE: first page right column -->
                    <app-phx-panel [headerTitle]="currentTranslations.riskReviewWith">
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.conversationDate" [editable]="true"
                                [labelText]="currentTranslations.conversationDate"
                                [viewModeText]="form.controls.conversationDate.value | date: PhxConstants.DateFormat.mediumDate">
                                <app-phx-date-box
                                    type="date"
                                    [displayFormat]="{ dateStyle: 'medium' }"
                                    [formControl]="form.controls.conversationDate"
                                ></app-phx-date-box>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.conversationMethod"
                                [labelText]="currentTranslations.method" [editable]="true"
                                [viewModeText]="form.controls.conversationMethod.value">
                                <app-phx-select-box [formControl]="form.controls.conversationMethod"
                                    [placeholder]="currentTranslations.selectPlaceholder"
                                    [items]="isEnglish ? methodOptions : methodOptionsFr" textField="value"
                                    valueField="value">
                                </app-phx-select-box>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.conversationWith" [editable]="true"
                                [labelText]="currentTranslations.conversationWith"
                                [viewModeText]="form.controls.conversationWith.value">
                                <input [formControl]="form.controls.conversationWith" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.conversationRole" [editable]="true"
                                [labelText]="currentTranslations.conversationRole"
                                [viewModeText]="form.controls.conversationRole.value">
                                <input [formControl]="form.controls.conversationRole" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.noticePeriodProvided"
                                [labelText]="currentTranslations.noticePeriodProvided" [editable]="true">
                                <fieldset>
                                    <label class="radio-inline">
                                        <input class="form-check-input" name="noticePeriodProvided" type="radio"
                                            [value]="true" [checked]="form.controls.noticePeriodProvided.value"
                                            [formControl]="form.controls.noticePeriodProvided">{{currentTranslations.yes}}</label>
                                    <label class="radio-inline">
                                        <input class="form-check-input" name="noticePeriodProvided" type="radio"
                                            [value]="false" [checked]="!form.controls.noticePeriodProvided.value"
                                            [formControl]="form.controls.noticePeriodProvided">{{currentTranslations.no}}</label>
                                </fieldset>
                            </phx-form-control>
                        </div>
                        <div class="form-group" *ngIf="form.controls.noticePeriodProvided.value">
                            <phx-form-control [control]="form.controls.noticePeriodProvidedDetails" [editable]="true"
                                [labelText]="currentTranslations.noticePeriodProvidedDetails"
                                [viewModeText]="form.controls.noticePeriodProvidedDetails.value">
                                <input [formControl]="form.controls.noticePeriodProvidedDetails" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.followUp"
                                [labelText]="currentTranslations.followUp" [editable]="true">
                                <fieldset>
                                    <label class="radio-inline">
                                        <input class="form-check-input" name="followUp" type="radio" [value]="true"
                                            [checked]="form.controls.followUp.value"
                                            [formControl]="form.controls.followUp">{{currentTranslations.yes}}</label>
                                    <label class="radio-inline">
                                        <input class="form-check-input" name="followUp" type="radio" [value]="false"
                                            [checked]="!form.controls.followUp.value"
                                            [formControl]="form.controls.followUp">{{currentTranslations.no}}</label>
                                </fieldset>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.procomProfile" [editable]="true"
                                [labelText]="currentTranslations.procomParticipants"
                                [viewModeText]="form.controls.procomProfile.value">
                                <input [formControl]="form.controls.procomProfile" type="text" class="form-control" />
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.offboardingNotes"
                                [labelText]="currentTranslations.offboardingNotes"
                                [viewModeText]="form.controls.offboardingNotes.value">
                                <textarea class="form-control" type="text" formControlName="offboardingNotes"
                                    rows="3"></textarea>
                            </phx-form-control>
                        </div>
                    </app-phx-panel>
                    <app-phx-panel [headerTitle]="currentTranslations.contractor">
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.classification"
                                [labelText]="currentTranslations.classification" [editable]="true"
                                [viewModeText]="form.controls.classification.value">
                                <app-phx-select-box [formControl]="form.controls.classification"
                                    [placeholder]="currentTranslations.selectPlaceholder"
                                    [items]="isEnglish ? classificationOptions : classificationOptionsFr "
                                    textField="value" valueField="id">
                                </app-phx-select-box>
                            </phx-form-control>
                        </div>
                        <div class="form-group">
                            <phx-form-control [control]="form.controls.classificationNotes"
                                [labelText]="currentTranslations.notes"
                                [viewModeText]="form.controls.classificationNotes.value">
                                <textarea class="form-control" type="text" formControlName="classificationNotes"
                                    rows="3"></textarea>
                            </phx-form-control>
                        </div>
                    </app-phx-panel>
                </div>
            </div>
        </form>
        <form [formGroup]="riskForm">
            <!-- NOTE: second page - risk questions -->
            <div class="content-group form-horizontal" [ngClass]="{'hidden': currentPage !== Pages.questionPage}">
                <ng-container *ngFor="let question of questions">
                    <div class="col-md-6">
                        <ng-container
                            *ngTemplateOutlet="riskSelectionTemplate; context: { $implicit: question}"></ng-container>
                    </div>
                </ng-container>
            </div>
            <!-- NOTE: third page - risk assessment-->
            <div [ngClass]="{'hidden': currentPage !== Pages.analysisPage}">
                <div class="risk-summary-container">
                    {{currentTranslations.assessed}}
                    <div [class]="'risk-status-container font-size-20 color-' + currentRiskLevel">
                        <ng-container *ngIf="isEnglish">{{currentTranslations[currentRiskLevel]}} Risk</ng-container>
                        <ng-container
                            *ngIf="!isEnglish && currentRiskLevel === RISK.LOW">{{currentTranslations[currentRiskLevel]}}
                            risque</ng-container>
                        <ng-container *ngIf="!isEnglish && currentRiskLevel !== RISK.LOW">risque
                            {{currentTranslations[currentRiskLevel]}}</ng-container>
                    </div>
                    <div class="summary-container">
                        {{isEnglish ? riskSummaries[currentRiskLevel] : riskSummariesFr[currentRiskLevel]}}
                    </div>
                    <div class="recipients-container">
                        <div class="form-group">
                            <phx-form-control [control]="riskForm.controls.additionalRecipients" [editable]="true"
                                [labelText]="currentTranslations.recipients"
                                [viewModeText]="riskForm.controls.additionalRecipients.value">
                                <input [formControl]="riskForm.controls.additionalRecipients" type="text"
                                    class="form-control" />
                            </phx-form-control>
                        </div>
                    </div>
                </div>
                <div *ngIf="!!validationMessages.length"
                    class="error-container alert alert-danger alert-dismissable error-box col-lg-12">
                    <div *ngFor="let msg of validationMessages">
                        {{msg}}
                    </div>
                </div>
            </div>
            <div [ngClass]="{'hidden': currentPage !== Pages.finalPage}" class="final-message-container">
                {{currentTranslations.submitted}}</div>

            <!-- NOTE: template for each question - a list of options, each getting a check box - an 'other' option drop down with 'notes' text area -->
            <ng-template #riskSelectionTemplate let-question>
                <app-phx-panel [useCustomTitle]="true">
                    <div custom-title>{{isEnglish ? question.question : question.questionFr}} <br /><span
                            class="font-size-12">{{currentTranslations.selectOne}}</span>
                    </div>
                    <table class="table table-striped">
                        <tbody>
                            <ng-container *ngFor="let option of question.options">
                                <tr [class]="'option-row option-row-' + option.risk"
                                    (click)="onSelectRiskOption(question, option)">
                                    <td>{{isEnglish ? option.text : option.textFr}}</td>
                                    <td class="width-60px pull-right"><input type='checkbox' [checked]="option.checked">
                                    </td>
                                    <td [class]="'width-110px color-' + option.risk">
                                        {{currentTranslations[option.risk]}}
                                    </td>
                                </tr>
                            </ng-container>
                            <tr class="option-row">
                                <td>{{currentTranslations.other}} {{currentTranslations.includeDetails}}</td>
                                <td colspan="2">
                                    <app-phx-select-box [items]="isEnglish ? riskOptions : riskOptionsFr"
                                        textField="value" [placeholder]="currentTranslations.selectPlaceholder"
                                        valueField="id" (valueChanged)="onOtherOptionSelected($event, question)">
                                    </app-phx-select-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="form-group">
                        <phx-form-control [control]="riskForm.controls[question.form + 'Notes']" [editable]="true"
                            [labelText]="currentTranslations.notes">
                            <textarea class="form-control" type="text" [formControlName]="question.form + 'Notes'"
                                rows="3" [formControl]="riskForm.controls[question.form + 'Notes']"></textarea>
                        </phx-form-control>

                    </div>
                </app-phx-panel>
            </ng-template>
        </form>
    </div>
</app-phx-modal>
