<form [formGroup]="inputFormGroup">
  <app-phx-panel headerTitle="Billing Invoice">
    <div formArrayName="BillingInfoes">
      <ng-container *ngFor="let billingInfo of billingInfoFormArray?.controls; let i = index; trackBy: trackByForBillingInfo">
        <div class="form-group">
          <div class="col-lg-8">
            <p class="form-control-static" *ngIf="billingInfo.get('OrganizationClientDisplayName').value">
              <a routerLink="/next/organization/{{billingInfo.get('OrganizationIdClient').value}}/details">
                {{billingInfo.get('OrganizationClientDisplayName').value}} -
                {{billingInfo.get('OrganizationIdClient').value}}</a>
            </p>
          </div>
        </div>
        <ng-container [formGroupName]="i">
          <ng-container formArrayName="BillingInvoices">
            <ng-container
                *ngFor="let billingInvoice of billingInvoicesFormArray(i).controls; let j = index; trackBy: trackByForBillingInvoice">
              <ng-container [formGroupName]="j">
                <ng-container *ngIf="billingInvoice.get('InvoiceTypeId').value === invoiceType">
                  <app-workorder-billing-invoice [inputFormGroup]="billingInvoice"
                                                 [readOnlyStorage]="readOnlyStorage" [invoiceType]="invoiceType"
                                                 [organizationClientId]="billingInfo.get('OrganizationIdClient').value"
                                                 [billingInfoIndex]="i"
                                                 [billingInvoiceIndex]="j"
                                                 (addRecipient)="addBillingRecipient($event)"
                                                 (removeRecipient)="removeBillingRecipient($event)"
                                                 (addTimeSheetNote)="addTimesheetBillingInvoiceNote($event)"
                                                 (removeTimeSheetNote)="removeTimesheetBillingInvoiceNote($event)">
                  </app-workorder-billing-invoice>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </app-phx-panel>
</form>
