import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodeValue, CodeValueGroups } from '../../model';
import { CodeValueService } from '../../services/code-value.service';


@Component({
  selector: 'app-phx-select-box-code-value',
  templateUrl: './phx-select-box-code-value.component.html',
  styleUrls: ['./phx-select-box-code-value.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhxSelectBoxCodeValueComponent)
    }
  ]
})
export class PhxSelectBoxCodeValueComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input() groupName: string;
  @Input() sortByFieldName: string; // id, code, text, sortOrder (default = sortOrder)
  @Input() relatedGroupName: string;
  @Input() relatedValue: any;
  @Input() placeholder = $localize`:@@phx.selectBox.selectOne:-- Select One --`;
  @Input() showClearButton = true;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() searchable = true;
  @Input() filter: () => any;
  @Input() prioritizeCanadaUSA = true;
  @Input() itemTemplate = 'item-template';
  @Input() itemTemplateRef: TemplateRef<any>;
  @Input() value: any;
  @Input() valueFieldName = 'id';
  @Input() textFieldName = 'text';

  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  items: Array<CodeValue> = [];
  private isDestroyed = false;

  constructor(private codeValueService: CodeValueService, private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groupName) {
      this.loadCodeValues(changes.groupName.currentValue, this.sortByFieldName);
    }

    if (changes.sortByFieldName?.currentValue) {
      this.loadCodeValues(this.groupName, changes.sortByFieldName.currentValue);
    }

    if (changes.relatedValue) {
      this.loadRelatedCodeValues(this.groupName, this.sortByFieldName);
    }

    if (changes.relatedGroupName) {
      this.loadRelatedCodeValues(this.groupName, this.sortByFieldName);
    }
  }

  refresh() {
    if (this.relatedGroupName) {
      this.loadRelatedCodeValues(this.groupName, this.sortByFieldName);
    } else {
      this.loadCodeValues(this.groupName, this.sortByFieldName);
    }
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = _ => {
  };

  onTouched = () => {
  };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onValueChanged(event: any) {
    this.value = event.value;
    if (event.event) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  private loadRelatedCodeValues(groupName: string, sortBy: string) {
    if (this.groupName == null || this.relatedGroupName == null || this.relatedValue == null) {
      this.items = [];
      return;
    }

    let relatedValue = this.relatedValue;

    if (isNaN(relatedValue)) {
      relatedValue = this.codeValueService.getCodeValueIdByCode(relatedValue, this.relatedGroupName);
    }

    const data = this.codeValueService.getRelatedCodeValues(groupName, relatedValue, this.relatedGroupName);
    const sortedData = this.sortCodeValues(data, sortBy);
    this.items = this.filterCodeValues(sortedData, this.filter);
  }

  private loadCodeValues(groupName: string, sortBy: string) {
    if (this.groupName == null) {
      this.items = [];
      return;
    }

    const data = this.codeValueService.getCodeValues(groupName, true);
    const sortedData = this.sortCodeValues(data, sortBy);
    this.items = this.filterCodeValues(sortedData, this.filter);
  }

  private sortCodeValues(data: Array<CodeValue>, sortBy: string): Array<CodeValue> {
    let sortedData: Array<CodeValue>;
    switch ((sortBy || '').toLowerCase()) {
      case 'code': {
        sortedData = data.sort((a: CodeValue, b: CodeValue) => {
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case 'id': {
        sortedData = data.sort((a: CodeValue, b: CodeValue) => b.id - a.id);
        break;
      }
      case 'text': {
        sortedData = data.sort((a: CodeValue, b: CodeValue) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case 'sortorder':
      default: {
        sortedData = data.sort((a: CodeValue, b: CodeValue) => b.sortOrder - a.sortOrder);
      }
    }

    if (this.groupName === CodeValueGroups.Country && this.prioritizeCanadaUSA) {
      sortedData = sortedData.filter(country => country.code === 'CA' || country.code === 'US').concat(sortedData.filter(country => country.code !== 'CA' && country.code !== 'US'));
    } else if (this.groupName === CodeValueGroups.Currency && this.prioritizeCanadaUSA) {
      sortedData = sortedData.filter(currency => currency.code === 'CAD' || currency.code === 'USD').concat(sortedData.filter(currency => currency.code !== 'CAD' && currency.code !== 'USD'));
    }

    return sortedData;
  }

  private filterCodeValues(data: Array<CodeValue>, filter: (_: any) => any) {
    let filteredData = data;
    if (filter) {
      filteredData = data.filter(item => filter(item));
    }

    return filteredData;
  }
}
