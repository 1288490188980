import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimInputSpaces]'
})
export class TrimInputSpacesDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('blur')
  public trimSpaces(): void {
    if (typeof this.ngControl.control.value === 'string') {
      this.ngControl.control.setValue(this.ngControl.control.value.trim());
    }
  }
}
