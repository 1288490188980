import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { IAttributionManager, IAttributionShare, IAttributionSplitOption, ICommisionAttribution, ISalesAttribution } from 'src/app/attributions/attribution.interface';
import { ApiService } from 'src/app/common';
import { IFormService } from 'src/app/common/model';
import { FormArray, FormBuilder, FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { ContactService } from 'src/app/contact/services/contact.service';
import { OrganizationDataService } from 'src/app/organization/services/organization-data.service';
import { environment } from 'src/environments/environment';
import { IWorkOrder } from '../../models';
import { GlobalConfiguration } from 'src/app/common/model/gobal-configuration/global-configuration';

@Injectable()
export class AttributionsFormService implements IFormService {
  private isRootComponentDestroyed$: Subject<boolean>;

  private lineOfBusinessAttributionSplits$ = new BehaviorSubject<ISalesAttribution[]>([]);
  private allSplitOptions: IAttributionSplitOption[];

  private filteredSalesSplitOptionsSubject = new Subject<IAttributionSplitOption[]>();
  filteredSalesSplitOptions$ = this.filteredSalesSplitOptionsSubject.asObservable();

  private filteredRecruiterSplitOptionsSubject = new Subject<IAttributionSplitOption[]>();
  filteredRecruiterSplitOptions$ = this.filteredRecruiterSplitOptionsSubject.asObservable();

  private currentNationalAccountManagers: IAttributionManager[] = null;

  formGroup: FormGroup<ICommisionAttribution>;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private organizationDataService: OrganizationDataService,
    private contactService: ContactService
  ) {
  }

  //#region FORM FUNCTIONS
  setupFormListeners(): void {
  }

  createForm(workOrder: IWorkOrder, isRootComponentDestroyed$: Subject<boolean>): FormGroup<ICommisionAttribution> {
    this.isRootComponentDestroyed$ = isRootComponentDestroyed$;

    this.formGroup = this.formBuilder.group<ICommisionAttribution>({
      SalesAttribution: workOrder.WorkOrderVersion.CommissionAttribution?.SalesAttribution,
      SalesAttributionShare: this.getSharesAsFormArray(workOrder.WorkOrderVersion.CommissionAttribution?.SalesAttributionShare),
      RecruiterAttribution: workOrder.WorkOrderVersion.CommissionAttribution?.RecruiterAttribution,
      RecruiterAttributionShare: this.getSharesAsFormArray(workOrder.WorkOrderVersion.CommissionAttribution?.RecruiterAttributionShare),
      BranchManagers: this.getManagersAsFormArray(workOrder.WorkOrderVersion.CommissionAttribution?.BranchManagers),
      NationalAccountManagers: this.getManagersAsFormArray([]),
    });

    this.setNationalAccountManager(workOrder);

    return this.formGroup;
  }

  updateForm(workOrder: IWorkOrder): void {
    this.updateFormArray(this.salesAttributionSharesFormArray, workOrder.WorkOrderVersion.CommissionAttribution?.SalesAttributionShare, true);
    this.updateFormArray(this.recruiterAttributionSharesFormArray, workOrder.WorkOrderVersion.CommissionAttribution?.RecruiterAttributionShare, true);
    this.updateFormArray(this.branchManagersFormArray, workOrder.WorkOrderVersion.CommissionAttribution?.BranchManagers, false);
    this.updateFormArray(this.nationalAccountManagersFormArray, workOrder.WorkOrderVersion.CommissionAttribution?.NationalAccountManagers, false);

    this.formGroup.patchValue({
      SalesAttribution: workOrder.WorkOrderVersion.CommissionAttribution?.SalesAttribution,
      RecruiterAttribution: workOrder.WorkOrderVersion.CommissionAttribution?.RecruiterAttribution,
    }, { emitEvent: false });

    this.setNationalAccountManager(workOrder);
  }

  destroyForm() {
    this.formGroup = null;
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder.WorkOrderVersion.CommissionAttribution = this.updateOverAllShare(this.formGroup.value);

    return workOrder;
  }
  //#endregion

  /** NOTE: get LOB attribution split data  */
  getLineOfBusinessAttributionSplits(): Observable<ISalesAttribution[]> {
    if (this.lineOfBusinessAttributionSplits$.getValue().length) {
      return this.lineOfBusinessAttributionSplits$.asObservable();
    } else {
      return this.apiService.httpGetRequest<GlobalConfiguration<ISalesAttribution>[]>(`globalConfiguration/Configuration/CommissionAttributionSplit`, environment.adminServiceApiEndpoint).pipe(
        map(salesAttributions => salesAttributions.map(m => m.Data))
      );
    }
  }

  initAttributionSplitOptions(): void {
    /** NOTE: user selects attribution share splits option from list populated by this API call - same for all WOs  */
    this.apiService.httpGetRequest<GlobalConfiguration<IAttributionSplitOption>[]>(`globalConfiguration/Configuration/WorkOrderAttributionSplit`, environment.adminServiceApiEndpoint)
      .subscribe(splitOptions => {
      this.allSplitOptions = splitOptions.map(m => m.Data);

      this.updateSalesSplitOptions();
      this.updateRecruiterSplitOptions();
    });
  }

  //#region SALES PROFILES
  addSalesShare(id: number) {
    this.addShare(this.salesAttributionSharesFormArray, id);
    this.updateSalesSplitOptions();
  }

  removeSalesShare(index: number) {
    this.removeShare(this.salesAttributionSharesFormArray, index);
    this.updateSalesSplitOptions();
  }

  updateSalesSharesAttributionShares(newSplit: string) {
    this.updateAttributionShares(this.salesAttributionSharesFormArray, newSplit);
  }
  //#endregion

  //#region RECRUITER PROFILES
  addRecruiterShare(id: number) {
    this.addShare(this.recruiterAttributionSharesFormArray, id);
    this.updateRecruiterSplitOptions();
  }
  removeRecruiterShare(index: number) {
    this.removeShare(this.recruiterAttributionSharesFormArray, index);
    this.updateRecruiterSplitOptions();
  }

  updateRecruiterSharesAttributionShares(newSplit: string) {
    this.updateAttributionShares(this.recruiterAttributionSharesFormArray, newSplit);
  }
  //#endregion

  updateBranchManagers(managers: IAttributionManager[]) {
    this.updateFormArray(this.branchManagersFormArray, managers, false);
  }

  updateNationalAccountManagers(managers: IAttributionManager[]) {
    this.updateFormArray(this.nationalAccountManagersFormArray, managers, false);
  }

  private getNationalAccountManager(organizationId: number) {
    this.organizationDataService.getNationalAccountManagersByOrganizationId(organizationId).pipe(
      switchMap(accountManagers => forkJoin([
        of(accountManagers),
        this.contactService.getActiveInternalUserProfileList()
      ])),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(([accountManagers, internalUsers]) => {
      const managerProfiles = internalUsers.filter(f => accountManagers.map(m => m.UserProfileInternalId).includes(f.Id));
      this.updateNationalAccountManagers(managerProfiles.map(m => ({ UserProfileId: m.Id, FullName: m.Contact.FullName })));
    });
  }

  private setNationalAccountManager(workOrder: IWorkOrder) {
    const currentNams = workOrder.WorkOrderVersion?.CommissionAttribution?.NationalAccountManagers;
    /** NOTE: if the WO is editable and we dont have a NAM - then we get national account managers from the api */
    if (workOrder.readOnlyStorage.IsEditable && (!currentNams?.length)
    ) {
      this.getNationalAccountManager(workOrder.WorkOrderVersion?.BillingInfoes?.[0].OrganizationIdClient);
    } else {
      /** NOTE: if the WO already has a saved NAM - we update the form with that value*/
      this.updateNationalAccountManagers(workOrder.WorkOrderVersion.CommissionAttribution?.NationalAccountManagers);
    }
  }

  private addShare(formArray: FormArray<IAttributionShare>, id: number) {
    formArray.push(this.getShareAsFormGroup({
      ...this.getBlankAttributionShare(),
      UserProfileId: id,
    }));
    this.updateAttributionShares(formArray);
  }

  private removeShare(formArray: FormArray<IAttributionShare>, index: number) {
    formArray.removeAt(index);
    this.updateAttributionShares(formArray);
  }

  private updateAttributionShares(formArray: FormArray<IAttributionShare>, newSplit: string = null) {
    const arSplits = this.getValidSplits(formArray, newSplit);
    /** NOTE: split options are selected from a list - newSplit param is selected option from that list or the default 100%*/
    formArray.value.forEach((share, index) => {
      formArray.at(index).patchValue({
        ...share,
        /** NOTE: when a share is added or removed we clear out any previous attributionShare values.
         * in that scenario the newSplit parameter passed in is null - and we populate with default splits according to formArray length */
        AttributionShare: arSplits?.[index]
        /** NOTE: emit change event if service is updating selected split option - component will catch event and update option select box */
      }, { emitEvent: !newSplit });
    });
  }

  /** NOTE: get split option as array or the default option as array */
  private getValidSplits(formArray: FormArray<IAttributionShare>, newSplit: string): number[] {
    if (newSplit) {
      /** NOTE: user selected a split option */
      return this.allSplitOptions?.find(f => f.Text === newSplit)?.Value;
    } else {
      /** NOTE: number of profiles changed - so service sets a default split option */
      return this.allSplitOptions?.find(f => f.Value.length === formArray.length && f.IsDefault)?.Value || [100];
    }
  }

  private updateSalesSplitOptions() {
    /** NOTE: filter split option list by number of sales people added */
    this.filteredSalesSplitOptionsSubject.next(this.filterSplits(this.salesAttributionSharesFormArray?.length || 0));
  }

  private updateRecruiterSplitOptions() {
    /** NOTE: filter split option list by number of recruiters added */
    this.filteredRecruiterSplitOptionsSubject.next(this.filterSplits(this.recruiterAttributionSharesFormArray?.length || 0));
  }

  private filterSplits(length: number) {
    return this.allSplitOptions?.filter(f => f.Value.length === length);
  }


  private updateOverAllShare(formValue: ICommisionAttribution): ICommisionAttribution {
    const updatedValue = { ...formValue };
    updatedValue.SalesAttributionShare.forEach(share => share.OverAllShare = formValue.SalesAttribution * (share.AttributionShare / 100));
    updatedValue.RecruiterAttributionShare.forEach(share => share.OverAllShare = formValue.RecruiterAttribution * (share.AttributionShare / 100));
    return updatedValue;
  }

  private updateFormArray(formArray: FormArray<any>, updates: Array<any>, isSharesArray: boolean) {
    /** NOTE: if we have an existing formArray to update, and changes to update it with */
    if (formArray.length && updates?.length) {
      updates.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<any>;
        if (formGroup) {
          formGroup.patchValue(item, { emitEvent: false });
        } else {
          if (isSharesArray) {
            formArray.push(this.getShareAsFormGroup(item));
          } else {
            formArray.push(this.getManagerAsFormGroup(item));
          }
        }
      });
      /** NOTE: if there are more items in the current array than the update - clear out the extras */
      if (formArray.length > updates.length) {
        this.clearArray(formArray, updates.length);
      }
      /** NOTE: if no existing array - turn updates into a new formArray */
    } else if (updates?.length) {
      updates.forEach((item) => {
        if (isSharesArray) {
          formArray.push(this.getShareAsFormGroup(item));
        } else {
          formArray.push(this.getManagerAsFormGroup(item));
        }
      });
      /** NOTE: if updates is an empty array then clear the existing one */
    } else {
      this.clearArray(formArray);
    }
  }

  private clearArray(formArray: FormArray<IAttributionShare>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

  private getSharesAsFormArray(shares: IAttributionShare[]): FormArray<IAttributionShare> {
    const attributionShares = shares || [];
    return this.formBuilder.array<IAttributionShare>(attributionShares?.map(share => this.getShareAsFormGroup(share)));
  }

  private getManagersAsFormArray(managers: IAttributionManager[]): FormArray<IAttributionManager> {
    const attributionManagers = managers || [];
    return this.formBuilder.array<IAttributionManager>(attributionManagers?.map(manager => this.getManagerAsFormGroup(manager)));
  }


  private getShareAsFormGroup(share: IAttributionShare): FormGroup<IAttributionShare> {
    return this.formBuilder.group<IAttributionShare>({
      UserProfileId: share.UserProfileId,
      AttributionShare: [share.AttributionShare, Validators.required],
      OverAllShare: share.OverAllShare
    });
  }
  private getManagerAsFormGroup(share: IAttributionManager): FormGroup<IAttributionManager> {
    return this.formBuilder.group<IAttributionManager>({
      UserProfileId: share.UserProfileId,
      FullName: share.FullName
    });
  }

  private getBlankAttributionShare(): IAttributionShare {
    return {
      UserProfileId: null,
      AttributionShare: null,
      OverAllShare: null
    };
  }

  get salesAttributionSharesFormArray(): FormArray<IAttributionShare> {
    return this.formGroup.controls.SalesAttributionShare as FormArray<IAttributionShare>;
  }

  get recruiterAttributionSharesFormArray(): FormArray<IAttributionShare> {
    return this.formGroup.controls.RecruiterAttributionShare as FormArray<IAttributionShare>;
  }

  get branchManagersFormArray(): FormArray<IAttributionManager> {
    return this.formGroup.controls.BranchManagers as FormArray<IAttributionManager>;
  }
  get nationalAccountManagersFormArray(): FormArray<IAttributionManager> {
    return this.formGroup.controls.NationalAccountManagers as FormArray<IAttributionManager>;
  }

}
