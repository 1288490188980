<h4>Sales Tax</h4>

<phx-form-control labelText="Tax Province/State"
                  [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes','SubdivisionIdSalesTax')"
                  [control]="inputFormGroup.controls.SubdivisionIdSalesTax"
                  viewModeText="{{inputFormGroup.controls.SubdivisionIdSalesTax.value | CodeValue:codeValueGroups.Subdivision}}">
  <app-phx-select-box [items]="salesTaxTerritories" textField="text" valueField="id"
                      [formControl]="inputFormGroup.controls.SubdivisionIdSalesTax">
  </app-phx-select-box>
</phx-form-control>

<phx-form-control *ngIf="inputFormGroup.controls.SubdivisionIdSalesTax.value && salesTaxJurisdictions?.length > 0"
                  labelText="Tax Jurisdiction"
                  [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes','JurisdictionId')"
                  [control]="inputFormGroup.controls.JurisdictionId"
                  viewModeText="{{inputFormGroup.controls.JurisdictionId.value | lookup: salesTaxJurisdictions:'JurisdictionId':'DisplayName'}}">
  <app-phx-select-box [items]="salesTaxJurisdictions" textField="DisplayName" valueField="JurisdictionId"
                      [formControl]="inputFormGroup.controls.JurisdictionId">
  </app-phx-select-box>
</phx-form-control>

<ng-container *ngIf="inputFormGroup.controls.SubdivisionIdSalesTax.value">
  <ng-container *ngIf="salesTaxFormArray?.length > 0">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Description</th>
        <th>Percentage</th>
        <th>Has Number</th>
        <th>Is Applied</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let billingSalesTax of salesTaxFormArray?.controls">
        <td>{{billingSalesTax.get('DisplayName').value}}</td>
        <td>{{billingSalesTax.get('ratePercentage').value}}<span
            [hidden]="!billingSalesTax.get('ratePercentage').value">
              %</span></td>
        <td>{{billingSalesTax.get('hasNumber').value ? 'Yes' : 'No'}}</td>
        <td class="billing-info-apply">
          <phx-form-control [layoutType]="layoutType.InputOnly"
                            [editable]="editable && checkPtFiledAccessibility('WorkOrderVersion.BillingInfoes.BillingSalesTaxes','IsApplied')"
                            [control]="billingSalesTax.get('IsApplied')"
                            viewModeText="{{billingSalesTax.get('IsApplied').value ? 'Yes' : 'No'}}">
            <app-phx-select-box [items]="isAppliedList" textField="text" valueField="id"
                                [formControl]="billingSalesTax.get('IsApplied')" [showClearButton]="false">
            </app-phx-select-box>
          </phx-form-control>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>
