import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IClientOrganization, IExpenseApprover, ISupplierOrganization, ITabExpenseInvoiceApprovers, ITabExpenseInvoiceDetail, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';

@Injectable()
export class ExpenseDetailFormService implements IFormService {

  formGroup: FormGroup<ITabExpenseInvoiceDetail>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  get expenseIdFormControl(): FormControl<number> {
    return this.formGroup.get('ExpenseMethodologyId') as FormControl<number>;
  }

  get expenseApproverFormArray(): FormArray<IExpenseApprover> {
    const expenseApprovers = this.formGroup.get('ExpenseApprovers');
    return expenseApprovers.get('ClientApprover') as FormArray<IExpenseApprover>;
  }
  get internalApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('InternalApprover') as FormArray<IExpenseApprover>;
  }

  get organizationIdClientFormArray(): FormArray<IClientOrganization> {
    return this.formGroup.get('OrganizationIdClients') as FormArray<IClientOrganization>;
  }

  get organizationIdSupplierFormArray(): FormArray<ISupplierOrganization> {
    return this.formGroup.get('OrganizationIdSuppliers') as FormArray<ISupplierOrganization>;
  }

  get expenseIdChange$() {
    return this.expenseIdFormControl.valueChanges;
  }

  get isExpenseUsesProjectsFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsExpenseUsesProjects') as FormControl<boolean>;
  }

  get isExpenseUsesProjectsChange$() {
    return this.isExpenseUsesProjectsFormControl.valueChanges;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabExpenseInvoiceDetail> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const expenseDetail: ITabExpenseInvoiceDetail = this.mapWorkOrderToFormData(workorder);
    const validations = this.setValidationsForExpenseInvoiceDetails(workorder);
    const expenseApprovers = workorder.WorkOrderVersion.ExpenseApprovers;

    this.formGroup = this.fb.group<ITabExpenseInvoiceDetail>({
      ExpenseMethodologyId: [
        expenseDetail.ExpenseMethodologyId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseMethodologyId', [
          Validators.required
        ])
      ],
      ExpenseApprovalFlowId: [
        expenseDetail.ExpenseApprovalFlowId,
        validations.isExpenseApprovalFlowId
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseApprovalFlowId', [
            Validators.required
          ])
          : null
      ],
      ExpenseApprovers: this.createExpenseApproverFormGroup(expenseApprovers, validations.isExpenseApprovers, expenseDetail.ExpenseMethodologyId),
      IsExpenseUsesProjects: [
        expenseDetail.IsExpenseUsesProjects,
        validations.isExpenseUsesProjects
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsExpenseUsesProjects', [
            Validators.required
          ])
          : null
      ],
      ExpenseThirdPartyWorkerReference: [
        expenseDetail.ExpenseThirdPartyWorkerReference,
        validations.isExpenseThirdPartyWorkerReference
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseThirdPartyWorkerReference', [
            Validators.required
          ])
          : null
      ],
      ExpenseDescription: [expenseDetail.ExpenseDescription],
      OrganizationIdInternal: [expenseDetail.OrganizationIdInternal],
      OrganizationIdClients: this.createOrganizationIdClientFormArray(expenseDetail.OrganizationIdClients),
      OrganizationIdSuppliers: this.createOrganizationIdSupplierFormArray(expenseDetail.OrganizationIdSuppliers)
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.expenseIdChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      const projectsAndCoding = value && (
        value === PhxConstants.ExpenseMethodology.OnlineApproval ||
        value === PhxConstants.ExpenseMethodology.OfflineApproval);
      const configurationAndDescriptors = value &&
        value !== PhxConstants.ExpenseMethodology.NoExpense;

      const firstExpenseApproverFormGroup = this.expenseApproverFormArray.at(0);
      const firstInternalApproverFormArrayGroup = this.internalApproverFormArray.at(0);
      if( value !== PhxConstants.ExpenseMethodology.OnlineApproval){
        firstInternalApproverFormArrayGroup.get('UserProfileId').clearValidators();
      }else{
        firstInternalApproverFormArrayGroup.get('UserProfileId').setValidators(
          firstInternalApproverFormArrayGroup.get('ApproverTypeId').value === PhxConstants.ApproverType.InternalApprover 
            ? Validators.required
            : null
        );
      }
      firstInternalApproverFormArrayGroup.setValue(firstInternalApproverFormArrayGroup.value, { emitEvent: false });

      firstExpenseApproverFormGroup.get('UserProfileId').setValidators(
        firstExpenseApproverFormGroup.get('ApproverTypeId').value === PhxConstants.ApproverType.ClientApprover && value === PhxConstants.ExpenseMethodology.OnlineApproval
          ? Validators.required
          : null
      );

      firstExpenseApproverFormGroup.patchValue({
        UserProfileId: firstExpenseApproverFormGroup.get('UserProfileId').value || null
      }, { emitEvent: false });

      this.formGroup.get('IsExpenseUsesProjects').setValidators(
        value && (value === PhxConstants.ExpenseMethodology.OnlineApproval || value === PhxConstants.ExpenseMethodology.OfflineApproval)
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsExpenseUsesProjects', [
            Validators.required
          ])
          : null
      );

      this.formGroup.get('ExpenseThirdPartyWorkerReference').setValidators(
        value && value === PhxConstants.ExpenseMethodology.ThirdPartyImport
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseThirdPartyWorkerReference', [
            Validators.required
          ])
          : null
      );

      this.formGroup.patchValue({
        IsExpenseUsesProjects: projectsAndCoding ? this.formGroup.get('IsExpenseUsesProjects').value : null,
        ExpenseThirdPartyWorkerReference: configurationAndDescriptors ? this.formGroup.get('ExpenseThirdPartyWorkerReference').value : null
      }, { emitEvent: false });

      this.formGroup.get('IsExpenseUsesProjects').updateValueAndValidity();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const expenseInvoiceDetail: ITabExpenseInvoiceDetail = this.formGroup.value;

    workOrder.WorkOrderVersion.ExpenseMethodologyId = expenseInvoiceDetail.ExpenseMethodologyId;
    workOrder.WorkOrderVersion.ExpenseApprovalFlowId =
      expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval
        ? expenseInvoiceDetail.ExpenseApprovalFlowId
          ? expenseInvoiceDetail.ExpenseApprovalFlowId
          : PhxConstants.TimeSheetApprovalFlow.Sequential
        : null;
    workOrder.WorkOrderVersion.IsExpenseUsesProjects =
      expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval || expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval
        ? expenseInvoiceDetail.IsExpenseUsesProjects
        : null;
    workOrder.WorkOrderVersion.ExpenseApprovers =
      expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval
        ? [
          ...expenseInvoiceDetail.ExpenseApprovers.ClientApprover,
          ...expenseInvoiceDetail.ExpenseApprovers.SupplierApprover.filter(a => a.UserProfileId),
          ...expenseInvoiceDetail.ExpenseApprovers.InternalApprover.filter(a => a.UserProfileId)
        ]
        : expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval ?
          [...expenseInvoiceDetail.ExpenseApprovers.InternalApprover.filter(a => a.UserProfileId)] : [];
    workOrder.WorkOrderVersion.ExpenseThirdPartyWorkerReference = expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.NoExpense ||
      expenseInvoiceDetail.ExpenseThirdPartyWorkerReference === '' ? null : expenseInvoiceDetail.ExpenseThirdPartyWorkerReference;
    workOrder.WorkOrderVersion.ExpenseDescription =
      expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval || expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval
        ? expenseInvoiceDetail.ExpenseDescription
        : null;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const expenseDetail: ITabExpenseInvoiceDetail = this.mapWorkOrderToFormData(workorder);
    const validations = this.setValidationsForExpenseInvoiceDetails(workorder);
    const expenseApprovers = workorder.WorkOrderVersion.ExpenseApprovers;

    this.formGroup.get('ExpenseMethodologyId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseMethodologyId', [
        Validators.required
      ])
    );

    this.formGroup.get('ExpenseApprovalFlowId').setValidators(
      validations.isExpenseApprovalFlowId
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseApprovalFlowId', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('IsExpenseUsesProjects').setValidators(
      validations.isExpenseUsesProjects
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'IsExpenseUsesProjects', [
          Validators.required
        ])
        : null
    );

    this.formGroup.get('ExpenseThirdPartyWorkerReference').setValidators(
      validations.isExpenseThirdPartyWorkerReference
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'ExpenseThirdPartyWorkerReference', [
          Validators.required
        ])
        : null
    );

    this.formGroup.patchValue({
      ExpenseMethodologyId: expenseDetail.ExpenseMethodologyId,
      ExpenseApprovalFlowId: expenseDetail.ExpenseApprovalFlowId,
      IsExpenseUsesProjects: expenseDetail.IsExpenseUsesProjects,
      ExpenseThirdPartyWorkerReference: expenseDetail.ExpenseThirdPartyWorkerReference,
      ExpenseDescription: expenseDetail.ExpenseDescription,
      OrganizationIdInternal: expenseDetail.OrganizationIdInternal,
    }, { emitEvent: false });

    const expenseApproversFormGroup = this.formGroup.get('ExpenseApprovers') as FormGroup<ITabExpenseInvoiceApprovers>;
    this.updateExpenseApproverFormGroup(expenseApproversFormGroup, expenseApprovers, validations.isExpenseApprovers, expenseDetail.ExpenseMethodologyId);

    const organizationIdClientsFormArray = this.formGroup.get('OrganizationIdClients') as FormArray<IClientOrganization>;
    this.updateOrganizationIdClientFormArray(organizationIdClientsFormArray, expenseDetail.OrganizationIdClients);

    const organizationIdSuppliersFormArray = this.formGroup.get('OrganizationIdSuppliers') as FormArray<ISupplierOrganization>;
    this.updateOrganizationIdSupplierFormArray(organizationIdSuppliersFormArray, expenseDetail.OrganizationIdSuppliers);

    this.organizationIdClientFormArray.updateValueAndValidity();
  }

  addTimeSheetApproverDefinitionFormGroup() {
    const formGroup = this.createBlankExpenseApproverFormArray();
    this.expenseApproverFormArray.push(formGroup);
  }

  removeTimeSheetApproverDefinitionFormGroup(index: number) {
    this.expenseApproverFormArray.removeAt(index);
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabExpenseInvoiceDetail {
    const organizationIdSuppliers = workorder.WorkOrderVersion.PaymentInfoes.map(o => {
      return { OrganizationIdSupplier: o.OrganizationIdSupplier };
    });

    const organizationIdClients = workorder.WorkOrderVersion.BillingInfoes.map(o => {
      return { OrganizationIdClient: o.OrganizationIdClient };
    });
    return {
      ExpenseMethodologyId: workorder.WorkOrderVersion.ExpenseMethodologyId,
      ExpenseApprovalFlowId: workorder.WorkOrderVersion.ExpenseApprovalFlowId,
      IsExpenseUsesProjects: workorder.WorkOrderVersion.IsExpenseUsesProjects,
      ExpenseThirdPartyWorkerReference: workorder.WorkOrderVersion.ExpenseThirdPartyWorkerReference,
      ExpenseDescription: workorder.WorkOrderVersion.ExpenseDescription,
      ExpenseApprovers: null,
      OrganizationIdInternal: workorder.OrganizationIdInternal,
      OrganizationIdSuppliers: organizationIdSuppliers,
      OrganizationIdClients: organizationIdClients
    };
  }

  private setValidationsForExpenseInvoiceDetails(workorder: IWorkOrder) {
    const valid = {
      isExpenseApprovers: workorder.WorkOrderVersion.ExpenseMethodologyId && workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval,
      isExpenseApprovalFlowId:
        workorder.WorkOrderVersion.ExpenseMethodologyId &&
        workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval &&
        workorder.WorkOrderVersion.ExpenseApprovers.filter(i => i.ApproverTypeId === PhxConstants.ApproverType.ClientApprover).length > 1,
      isExpenseUsesProjects:
        workorder.WorkOrderVersion.ExpenseMethodologyId &&
        (workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval ||
          workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval),
      isExpenseThirdPartyWorkerReference: workorder.WorkOrderVersion.ExpenseMethodologyId && workorder.WorkOrderVersion.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.ThirdPartyImport
    };
    return valid;
  }

  private createExpenseApproverFormGroup(expenseApprovers: Array<IExpenseApprover> = [], isApproverValid: boolean,  expenseMethodologyId: number) {
    let isClientApproverExists = false;
    let isInternalApproverExists = false;
    let isSupplierApproverExists = false;

    if (expenseApprovers.length > 0) {
      isClientApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.ClientApprover).length > 0;
      isInternalApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.InternalApprover).length > 0;
      isSupplierApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.SupplierApprover).length > 0;
    }

    return this.fb.group<ITabExpenseInvoiceApprovers>({
      ClientApprover: isClientApproverExists
        ? this.createGroupExpenseApproverFormArray(expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.ClientApprover), isApproverValid, expenseMethodologyId)
        : this.createGroupExpenseApproverFormArray(this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.ClientApprover), isApproverValid, expenseMethodologyId),
      InternalApprover: isInternalApproverExists
        ? this.createGroupExpenseApproverFormArray(expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.InternalApprover), false, expenseMethodologyId)
        : this.createGroupExpenseApproverFormArray(this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.InternalApprover), false, expenseMethodologyId),
      SupplierApprover: isSupplierApproverExists
        ? this.createGroupExpenseApproverFormArray(expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.SupplierApprover), false , expenseMethodologyId)
        : this.createGroupExpenseApproverFormArray(this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.SupplierApprover), false, expenseMethodologyId)
    });

  }

  private createGroupExpenseApproverFormArray(expenseApprovers: Array<IExpenseApprover>, isApproverValid = false, expenseMethodologyId ) {
    return this.fb.array<IExpenseApprover>(
      expenseApprovers
        .sort((a1: IExpenseApprover, a2: IExpenseApprover) => a1.Sequence - a2.Sequence)
        .map((approver: IExpenseApprover) => this.createGroupExpenseApproverFormGroup(approver, isApproverValid, expenseMethodologyId))
    );
  }

  private createGroupExpenseApproverFormGroup(approver: IExpenseApprover, isApproverValid: boolean, expenseMethodologyId: number): FormGroup<IExpenseApprover> {

    return this.fb.group<IExpenseApprover>({
      Id: [approver.Id],
      IsDraft: [approver.IsDraft],
      MustApprove: [approver.MustApprove],
      Sequence: [approver.Sequence],
      SourceId: [approver.SourceId],
      UserProfileId: [
        approver.UserProfileId,
        ((approver.ApproverTypeId === PhxConstants.ApproverType.ClientApprover && isApproverValid)
          || (approver.ApproverTypeId === PhxConstants.ApproverType.InternalApprover && expenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval) )
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.ExpenseApprovers', 'UserProfileId', [
            Validators.required
          ])
          : []
      ],
      WorkOrderVersion: [approver.WorkOrderVersion],
      WorkOrderVersionId: [approver.WorkOrderVersionId],
      ApproverTypeId: [approver.ApproverTypeId]
    });
  }

  private getExpenseApproverEmptyObject(ApproverType: number): Array<IExpenseApprover> {
    const expenseApprover: Array<IExpenseApprover> = [
      {
        Id: 0,
        ApproverTypeId: ApproverType,
        IsDraft: true,
        MustApprove: true,
        Sequence: ApproverType === PhxConstants.ApproverType.SupplierApprover ? 1 : ApproverType === PhxConstants.ApproverType.InternalApprover ? 2 : 3,
        SourceId: null,
        UserProfileId: null,
        WorkOrderVersion: null,
        WorkOrderVersionId: 0
      }
    ];
    return expenseApprover;
  }

  private createOrganizationIdClientFormArray(organizationIdClients: Array<IClientOrganization>) {
    return this.fb.array<IClientOrganization>(
      organizationIdClients.map((id: IClientOrganization) => this.createOrganizationIdClientFormGroup(id))
    );
  }

  private createOrganizationIdClientFormGroup(id: IClientOrganization): FormGroup<IClientOrganization> {
    return this.fb.group<IClientOrganization>({
      OrganizationIdClient: [id.OrganizationIdClient]
    });
  }

  private createOrganizationIdSupplierFormArray(organizationIdSuppliers: Array<ISupplierOrganization>) {
    return this.fb.array<ISupplierOrganization>(
      organizationIdSuppliers.map((id: ISupplierOrganization) => this.createOrganizationIdSupplierFormGroup(id))
    );
  }

  private createOrganizationIdSupplierFormGroup(id: ISupplierOrganization): FormGroup<ISupplierOrganization> {
    return this.fb.group<ISupplierOrganization>({
      OrganizationIdSupplier: [id.OrganizationIdSupplier]
    });
  }

  private createBlankExpenseApproverFormArray(): FormGroup<IExpenseApprover> {
    const expenseApprovers = this.formGroup.get('ExpenseApprovers');
    const clientApprovers = expenseApprovers.get('ClientApprover') as FormArray<IExpenseApprover>;        
    return this.fb.group<IExpenseApprover>({
      Id: 0,
      ApproverTypeId: PhxConstants.ApproverType.ClientApprover,
      IsDraft: true,
      MustApprove: true,
      Sequence: clientApprovers.length > 0 ? (clientApprovers.at(clientApprovers.length-1).get('Sequence').value + 1) : 3,
      SourceId: null,
      UserProfileId: [null, [Validators.required]],
      WorkOrderVersion: null,
      WorkOrderVersionId: 0
    });
  }

  private updateExpenseApproverFormGroup(
    formGroup: FormGroup<ITabExpenseInvoiceApprovers>,
    expenseApprovers: Array<IExpenseApprover> = [],
    isApproverValid: boolean,
    expenseMethodologyId: number
  ) {
    let isClientApproverExists = false;
    let isInternalApproverExists = false;
    let isSupplierApproverExists = false;

    if (expenseApprovers.length > 0) {
      isClientApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.ClientApprover).length > 0;
      isInternalApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.InternalApprover).length > 0;
      isSupplierApproverExists = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.SupplierApprover).length > 0;
    }

    const clientApproverFormArray = formGroup.get('ClientApprover') as FormArray<IExpenseApprover>;
    const internalApproverFormArray = formGroup.get('InternalApprover') as FormArray<IExpenseApprover>;
    const supplierApproverFormArray = formGroup.get('SupplierApprover') as FormArray<IExpenseApprover>;

    if (isClientApproverExists) {
      this.updateGroupExpenseApproverFormArray(clientApproverFormArray, expenseApprovers
        .filter(a => a.ApproverTypeId === PhxConstants.ApproverType.ClientApprover), isApproverValid, expenseMethodologyId);
    } else {
      this.updateGroupExpenseApproverFormArray(clientApproverFormArray, this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.ClientApprover), isApproverValid, expenseMethodologyId);
    }

    if (isInternalApproverExists) {
      this.updateGroupExpenseApproverFormArray(internalApproverFormArray, expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.InternalApprover), false, expenseMethodologyId);
    } else {
      this.updateGroupExpenseApproverFormArray(internalApproverFormArray, this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.InternalApprover), false, expenseMethodologyId);
    }

    if (isSupplierApproverExists) {
      this.updateGroupExpenseApproverFormArray(supplierApproverFormArray, expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.SupplierApprover), false, expenseMethodologyId);
    } else {
      this.updateGroupExpenseApproverFormArray(supplierApproverFormArray, this.getExpenseApproverEmptyObject(PhxConstants.ApproverType.SupplierApprover), false, expenseMethodologyId);
    }


  }

  private updateGroupExpenseApproverFormArray(
    formArray: FormArray<IExpenseApprover>,
    expenseApprovers: Array<IExpenseApprover>,
    isApproverValid = false,
    expenseMethodologyId: number
  ) {
    if (formArray.length && expenseApprovers.length) {
      expenseApprovers.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IExpenseApprover>;
        if (formGroup) {
          this.updateGroupExpenseApproverFormGroup(formGroup, item, isApproverValid);
        } else {
          formArray.push(this.createGroupExpenseApproverFormGroup(item, isApproverValid,expenseMethodologyId));
        }
      });
      if (formArray.length > expenseApprovers.length) {
        this.clearArray(formArray, expenseApprovers.length);
      }
    } else if (expenseApprovers.length) {
      const array = this.createGroupExpenseApproverFormArray(expenseApprovers, false, expenseMethodologyId);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateGroupExpenseApproverFormGroup(
    formGroup: FormGroup<IExpenseApprover>,
    approver: IExpenseApprover,
    isApproverValid: boolean
  ) {
    formGroup.get('UserProfileId').setValidators(
      approver.ApproverTypeId === PhxConstants.ApproverType.ClientApprover && isApproverValid
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.ExpenseApprovers', 'UserProfileId', [
          Validators.required
        ])
        : []
    );
    formGroup.patchValue({
      Id: approver.Id,
      IsDraft: approver.IsDraft,
      MustApprove: approver.MustApprove,
      Sequence: approver.Sequence,
      SourceId: approver.SourceId,
      UserProfileId: approver.UserProfileId,
      WorkOrderVersion: approver.WorkOrderVersion,
      WorkOrderVersionId: approver.WorkOrderVersionId,
      ApproverTypeId: approver.ApproverTypeId
    }, { emitEvent: false });
  }

  private updateOrganizationIdClientFormArray(
    formArray: FormArray<IClientOrganization>,
    organizationIdClients: Array<IClientOrganization>
  ) {
    if (formArray.length && organizationIdClients.length) {
      organizationIdClients.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IClientOrganization>;
        if (formGroup) {
          this.updateOrganizationIdClientFormGroup(formGroup, item);
        } else {
          formArray.push(this.createOrganizationIdClientFormGroup(item));
        }
      });
      if (formArray.length > organizationIdClients.length) {
        this.clearArray(formArray, organizationIdClients.length);
      }
    } else if (organizationIdClients.length) {
      const array = this.createOrganizationIdClientFormArray(organizationIdClients);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateOrganizationIdClientFormGroup(
    formGroup: FormGroup<IClientOrganization>,
    id: IClientOrganization
  ) {
    formGroup.patchValue({ OrganizationIdClient: id.OrganizationIdClient }, { emitEvent: false });
  }

  private updateOrganizationIdSupplierFormArray(
    formArray: FormArray<ISupplierOrganization>,
    organizationIdSuppliers: Array<ISupplierOrganization>
  ) {
    if (formArray.length && organizationIdSuppliers.length) {
      organizationIdSuppliers.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<ISupplierOrganization>;
        if (formGroup) {
          this.updateOrganizationIdSupplierFormGroup(formGroup, item);
        } else {
          formArray.push(this.createOrganizationIdSupplierFormGroup(item));
        }
      });
      if (formArray.length > organizationIdSuppliers.length) {
        this.clearArray(formArray, organizationIdSuppliers.length);
      }
    } else if (organizationIdSuppliers.length) {
      const array = this.createOrganizationIdSupplierFormArray(organizationIdSuppliers);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateOrganizationIdSupplierFormGroup(
    formGroup: FormGroup<ISupplierOrganization>,
    id: ISupplierOrganization
  ) {
    formGroup.patchValue({ OrganizationIdSupplier: id.OrganizationIdSupplier }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IExpenseApprover | IClientOrganization | ISupplierOrganization>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
