import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';

import _ from 'lodash';
import moment from 'moment';

import { WorkflowService, PhxConstants, PhxToastService, CodeValueService, CodeValueGroups } from '@common';
import { PhxToastType } from '@common/components/phx-toast/phx-toast-types';
import { ApiService } from '@common/services/api.service';

import { ICommissionRateDto, ICommissionRateVersionDto } from '../commission-rate.interface';
import { CommissionService } from './commission.service';

@Injectable()
export class CommissionRateObservableService {

  showTemplate: boolean;
  entityId: number;

  private currentId: number = null;
  private commissionRateDtoState: { [id: number]: ICommissionRateDto } = {};
  private commissionRateDtoSubject: BehaviorSubject<ICommissionRateDto> = new BehaviorSubject(null);
  commissionRateDto$: Observable<ICommissionRateDto> = this.commissionRateDtoSubject.asObservable();

  private refreshCommissionRateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  refreshCommissionRate$: Observable<boolean> = this.refreshCommissionRateSubject.asObservable();


  constructor(
    private apiService: ApiService,
    private workflowService: WorkflowService,
    public toastService: PhxToastService,
    public commissionService: CommissionService,
    public codeValueService: CodeValueService
  ) {
    this.commissionRateDto$.subscribe(data => {
      if (data) {
        this.commissionRateDtoState[this.currentId] = data;
      } else if (!data && this.currentId) {
        delete this.commissionRateDtoState[this.currentId];
        this.currentId = null;
      }
    });
  }

  private set commissionRateDto(commissionRateDto: ICommissionRateDto) {
    this.commissionRateDtoSubject.next(commissionRateDto);
  }

  private sortNVersion(ary: ICommissionRateVersionDto[]) {
    ary.sort((a, b) => {
      return b.Id - a.Id;
    });
    let i = 0;
    ary.forEach(detail => {
      detail.VersionNumber = ary.length - i;
      i++;
    });
  }

  private sortDeactivationDetails(commissionRate: ICommissionRateDto) {
    if (commissionRate.DeactivationReassignmentDetails.length) {
      const details = commissionRate.DeactivationReassignmentDetails;
      details.forEach(detail => {
        detail.BranchName = this.codeValueService.getCodeValueText(detail.BranchId, CodeValueGroups.InternalOrganizationDefinition1);
        detail.LineOfBusinessName = this.codeValueService.getCodeValueText(detail.LineOfBusinessId, CodeValueGroups.LineOfBusiness);
      });

      commissionRate.DeactivationReassignmentDetails = _(details)
        .sortBy([
          dto => dto.BranchName,
          dto => dto.InternalOrganizationName,
          dto => dto.ClientOrganizationName,
          dto => dto.LineOfBusinessName
        ])
        .value();
    }
  }

  private getObservableCommsissionRateFromServer(versionId: number, routerParams: any, showLoader: boolean = true, oDataParams?: any) {
    Promise.all([
      lastValueFrom(this.apiService.query<ICommissionRateDto>('Commission/getCommissionRateHeaderByCommissionRateVersionId/' + versionId + (oDataParams || ''), showLoader)),
      this.workflowService.getAvailableStateActions(PhxConstants.EntityType.CommissionRateHeader, Number(routerParams.commissionRateHeaderId), showLoader),
      this.workflowService.getAvailableStateActions(PhxConstants.EntityType.CommissionRateVersion, versionId, showLoader)
    ])
      .then(result => {
        const commissionRate: ICommissionRateDto = result[0];

        commissionRate.CurrentVersion = commissionRate.CommissionRateVersions.find(x => x.Id === versionId);

        const headerActions = (result[1] || [])
          .find(id => id.EntityStatusId === commissionRate.CommissionRateHeaderStatusId);
        commissionRate.AvailableStateActions = headerActions ? headerActions.AvailableStateActions : [];

        const versionActions = (result[2] || [])
          .find(id => id.EntityStatusId === commissionRate.CurrentVersion.CommissionRateVersionStatusId);
        commissionRate.CurrentVersion.AvailableStateActions = versionActions ? versionActions.AvailableStateActions : [];
        this.sortNVersion(commissionRate.CommissionRateVersions);
        this.sortDeactivationDetails(commissionRate);
        this.commissionRateDto = commissionRate;
      })
      .catch(responseError => {
        this.commissionRateDto = null;
        this.toastService.showToast('Error:', 'Commission Rate - ' + (responseError.statusText || responseError.message), PhxToastType.danger);
        console.error('Commission Rate - ' + (responseError.statusText || responseError.message));
      });
  }

  private getCommissionRateHeadersByCommissionUserProfile(commissionUserProfileId: number, commissionRoleId: number, commissionRateTypeId: number, commissionTemplateId: number) {
    const commissionDataParams = oreq.request().withSelect(['CommissionUserProfileId', 'CommissionUserProfileFirstName', 'CommissionUserProfileLastName', 'CommissionUserProfileStatusName']).url();

    this.commissionService.getCommissionRateHeadersByCommissionUserProfile(commissionUserProfileId, commissionDataParams)
      .subscribe((responseSucces: any) => {
        if (responseSucces.Items !== null && responseSucces.Items.length === 1) {
          const commissionRateVersion: ICommissionRateVersionDto = {
            Id: 0,
            CommissionRateVersionStatusId: PhxConstants.CommissionRateVersionStatus.New,
            EffectiveDate: moment(new Date(), PhxConstants.DateFormat.API_Date).toDate(),
            ScheduledChangeRateApplicationId: PhxConstants.ScheduledChangeRateApplication.AllWorkOrders,
            Percentage: null,
            CreationReasonId: PhxConstants.CommissionRateCreationReason.New,
            SourceId: null,
            HadApprovedImpact: false
          };

          const commissionRate: ICommissionRateDto = {
            Id: 0,
            AvailableStateActions: [],
            CommissionRateHeaderStatusId: PhxConstants.CommissionRateHeaderStatus.New,
            CommissionRateRestrictions: [],
            CommissionRateTypeId: commissionRateTypeId,
            CommissionRateVersions: [commissionRateVersion],
            CurrentVersion: commissionRateVersion,
            CommissionRoleId: commissionRoleId,
            CommissionUserProfileId: commissionUserProfileId,
            Description: null,
            IsCommissionRateInUse: false,
            DeactivationDate: null,
            DeactivationReason: null,
            DeactivationReassignmentDetails: [],
            CommissionUserProfileFirstName: responseSucces.Items[0].CommissionUserProfileFirstName,
            CommissionUserProfileLastName: responseSucces.Items[0].CommissionUserProfileLastName,
            CommissionUserProfileStatusName: responseSucces.Items[0].CommissionUserProfileStatusName,
            CommissionUserProfileStatusId: responseSucces.Items[0].CommissionUserProfileStatusId,
            HadApprovedImpactOnDeactivation: false
          };
          if (commissionTemplateId > 0) {
            this.commissionService.getTemplate(commissionTemplateId).subscribe((responseSuccesTemplate: any) => {

              commissionRate.Description = responseSuccesTemplate.Entity.Description;

              commissionRate.CommissionRateRestrictions = responseSuccesTemplate.Entity.CommissionRateRestrictions;

              commissionRate.CommissionRateVersions[0].ScheduledChangeRateApplicationId = responseSuccesTemplate.Entity.CommissionRateVersions[0].ScheduledChangeRateApplicationId;
              commissionRate.CommissionRateVersions[0].Percentage = responseSuccesTemplate.Entity.CommissionRateVersions[0].Percentage;
              this.commissionRateDto = commissionRate;
            });
          } else {
            this.commissionRateDto = commissionRate;
          }
        }
      });
  }

  getCommissionRate(versionId: number, routerParams: Params, showLoader: boolean = true) {
    this.currentId = versionId;
    if (this.refreshCommissionRateSubject.value) {
      this.refreshCommissionRateSubject.next(false);
    }
    if (!this.commissionRateDtoState[this.currentId]) {
      if (!+routerParams.commissionTemplateId) {
        this.getObservableCommsissionRateFromServer(versionId, routerParams, showLoader);
      } else if (+routerParams.commissionTemplateId && +routerParams.commissionUserProfileId && +routerParams.commissionRoleId && +routerParams.commissionRateTypeId) {
        this.getCommissionRateHeadersByCommissionUserProfile(
          +routerParams.commissionUserProfileId, +routerParams.commissionRoleId, +routerParams.commissionRateTypeId, +routerParams.commissionTemplateId
        );
      }
    } else {
      this.commissionRateDto = this.commissionRateDtoState[this.currentId];
    }
  }

  commissionRateClearAll() {
    this.commissionRateDtoState = {};
    this.commissionRateDto = null;
  }

  commissionRateDelete() {
    this.commissionRateDto = null;
    this.refreshCommissionRateSubject.next(true);
  }

  updateCommissionRate(commissionRate: ICommissionRateDto) {
    this.commissionRateDto = commissionRate;
  }

}
