import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkorderSetup } from '../../models';
import { Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable()
export class AssignmentCreateSetupFormService implements IFormService {

  formGroup: FormGroup<IWorkorderSetup>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  get lineOfBusinessIdFormControl() {
    return this.formGroup.get('LineOfBusinessId');
  }

  get lineOfBusinessIdFormControlChange$() {
    return this.lineOfBusinessIdFormControl.valueChanges;
  }

  get atsSourceIdFormControl() {
    return this.formGroup.get('AtsSourceId');
  }

  get atsSourceIdFormControlChange$() {
    return this.atsSourceIdFormControl.valueChanges;
  }

  get atsPlacementIdFormControl() {
    return this.formGroup.get('AtsPlacementId');
  }

  get atsPlacementIdFormControlChange$() {
    return this.atsPlacementIdFormControl.valueChanges;
  }

  get importFromAtsFormControl() {
    return this.formGroup.get('ImportFromAts');
  }

  get importFromAtsFormControlChange$() {
    return this.importFromAtsFormControl.valueChanges;
  }

  get organizationIdClientFormControl() {
    return this.formGroup.get('OrganizationIdClient');
  }

  get organizationIdClientFormControlChange$() {
    return this.organizationIdClientFormControl.valueChanges;
  }

  get templateIdFormControl() {
    return this.formGroup.get('TemplateId');
  }

  get userProfileIdWorkerFormControl() {
    return this.formGroup.get('UserProfileIdWorker');
  }

  get userProfileIdWorkerFormControlChange$() {
    return this.userProfileIdWorkerFormControl.valueChanges;
  }

  createForm(workOrderSetup: IWorkorderSetup, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<IWorkorderSetup>({
      ImportFromAts: [workOrderSetup.ImportFromAts,
        [Validators.required]],
      AtsPlacementId: [workOrderSetup.AtsPlacementId, workOrderSetup?.ImportFromAts && workOrderSetup.ImportFromAts === true
        ? Validators.required
        : null],
      AtsSourceId: [workOrderSetup.AtsSourceId],
      OrganizationIdClient: [workOrderSetup.OrganizationIdClient],
      OrganizationClientLegalName: [workOrderSetup.OrganizationClientLegalName],
      UserProfileIdWorker: [workOrderSetup.UserProfileIdWorker],
      UserProfileWorkerName: [workOrderSetup.UserProfileWorkerName],
      TemplateId: null,
      LineOfBusinessId: PhxConstants.LineOfBusiness.SubVendorPayroll
    });

    this.setupFormListeners();
    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.observeImportFromAtsChange();
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  updateForm(data: any): void {
    // Enforced by the interface
  }

  updateLineOfBusinessIdFormControl(value: number | null) {
    this.lineOfBusinessIdFormControl.setValue(value, { emitEvent: false });
  }

  updateTemplateIdFormControl(value: number | null) {
    this.templateIdFormControl.setValue(value, { emitEvent: false });
  }

  private observeImportFromAtsChange() {
    this.importFromAtsFormControlChange$
      .pipe(distinctUntilChanged(), takeUntil(this.isRootComponentDestroyed$))
      .subscribe((importFromAts) => {
        if (importFromAts === true) {
          this.atsPlacementIdFormControl.setValidators(Validators.required);
          this.organizationIdClientFormControl.clearValidators();
          this.organizationIdClientFormControl.setValue(null);
          this.userProfileIdWorkerFormControl.clearValidators();
          this.userProfileIdWorkerFormControl.setValue(null);
          this.lineOfBusinessIdFormControl.clearValidators();
          this.updateLineOfBusinessIdFormControl(null);
        } else {
          this.organizationIdClientFormControl.setValidators(Validators.required);
          this.userProfileIdWorkerFormControl.setValidators(Validators.required);
          this.lineOfBusinessIdFormControl.setValidators(Validators.required);
          this.updateLineOfBusinessIdFormControl(PhxConstants.LineOfBusiness.SubVendorPayroll);
          this.atsPlacementIdFormControl.clearValidators();
          this.atsPlacementIdFormControl.setValue(null);
        }
        this.atsPlacementIdFormControl.updateValueAndValidity();
        this.organizationIdClientFormControl.updateValueAndValidity();
        this.userProfileIdWorkerFormControl.updateValueAndValidity();
        this.lineOfBusinessIdFormControl.updateValueAndValidity();
      });
  }
}
