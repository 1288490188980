<div *ngIf="showOffboarding" class="col-lg-12">
    <div class="row offboarding-document-entity">
        <div class="col-lg-12" class="entity-name-col">
            <h4>
                Offboarding
            </h4>
        </div>
        <div class="col-lg-12">
            <div class="row offboarding-document-header">
                <div class="col-lg-2">Task Name</div>
                <div class="col-lg-4">File Name</div>
                <div class="col-lg-2">Uploaded By</div>
                <div class="col-lg-2">Date</div>
                <div class="col-lg-2"></div>
            </div>
            <div *ngIf="allTaskDocuments.length === 0" class="row offboarding-document-row">
                <div class="col-lg-12 center-block text-center">
                    <h4 class="no-data">You have no data at the moment.</h4>
                </div>
            </div>
            <div *ngIf="allTaskDocuments.length > 0">
                <div *ngFor="let task of allTaskDocuments">
                    <div *ngFor="let doc of task.OffboardingTasksDocuments; let first = first;"
                         class="row offboarding-document-row" [class.hidden-line]="!first">
                        <div class="col-lg-2 elipsis">
                            <span *ngIf="first">
                                {{task.OffboardingTaskId | CodeValue:codeValueGroups.WorkOrderOffboardingTask}}
                            </span>
                        </div>
                        <div class="col-lg-4 elipsis">
                            <a (click)="openDocument(doc)">{{doc.Name}}</a>
                        </div>
                        <div class="col-lg-2 elipsis">{{doc.UploadedByContactFirstName}}
                            {{doc.UploadedByContactLastName}}</div>
                        <div class="col-lg-2 elipsis">
                            {{doc.CreatedDatetime|date:phxConstant.DateFormat.mediumDate}}
                        </div>
                        <div class="col-lg-2 elipsis align-right">
                            <a type="button" class="btn btn-default action-button btn-secondary" (click)="openDocument(doc)">View</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>