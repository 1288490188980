import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { filter as _filter } from 'lodash';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IAvailableRebates, IBillingRate, IReadOnlyStorage, IWorkOrder } from '../../models';
import { AbstractControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { CodeValue, CodeValueGroups, PhxConstants } from '../../../common/model';
import { PartyBillingInfoFormService, PartyPaymentInfoFormService, WorkorderService } from '../../services';
import { CodeValueService } from '../../../common';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-billing-rate',
  templateUrl: './workorder-billing-rate.component.html',
  styleUrls: ['./workorder-billing-rate.component.less']
})
export class WorkorderBillingRateComponent extends BaseComponentOnDestroy implements OnChanges {
  @Input() rateIndex: number;
  @Input() workOrder: IWorkOrder;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() workOrderRateTypes: Array<CodeValue>;
  @Input() selectedRateType: AbstractControl<any>;
  @Input() inputFormGroup: FormGroup<IBillingRate>;
  @Input() overtimeIsExempt: boolean;
  @Input() set currencyId(id: number) {
    this.currencyCode = this.codeValueService.getCodeValueCode(id, CodeValueGroups.Currency);
  }

  currencyCode: string;

  readonly phxConstants = PhxConstants;
  readonly codeValueGroups = CodeValueGroups;

  rebates: any;
  vms: any;
  list: Array<any> = [];
  workorderLineOfBusinessId: number;
  billingOraganizationId: number;
  previousPrimaryRate: number;
  isRateNegotiationEnabled: boolean;
  showOptions = false;
  html: {
    rateWarningMessage: string;
    parentOrganizationNameFromList: boolean;
    codeValueLists: {
      listCurrency: Array<CodeValue>;
      listRebateTypes: Array<CodeValue>;
      listworkOrderRateTypes: Array<CodeValue>;
      listworkOrderRateTypesNoOt: Array<CodeValue>;
      listWorkOrderRateUnits: Array<CodeValue>;
    };
    commonLists: {
      listOrganizationClient: Array<any>;
      listUserProfileClient: Array<any>;
      listavailableRebates: Array<any>;
      listavailableVmsFees: Array<any>;
    };
  } = {
      rateWarningMessage: null,
      parentOrganizationNameFromList: false,
      codeValueLists: {
        listCurrency: [],
        listRebateTypes: [],
        listworkOrderRateTypes: [],
        listworkOrderRateTypesNoOt: [],
        listWorkOrderRateUnits: []
      },
      commonLists: {
        listOrganizationClient: [],
        listUserProfileClient: [],
        listavailableRebates: [],
        listavailableVmsFees: []
      }
    };

  private subscription$: Subscription;

  constructor(
    private codeValueService: CodeValueService,
    private workorderService: WorkorderService,
    private billingInfoFormService: PartyBillingInfoFormService,
    private paymentInfoFormService: PartyPaymentInfoFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormGroup) {
      this.setRateWarnings();
      this.setupFormGroupListeners();
    }

    if (changes.workOrder) {
      this.onWorkOrderUpdate(changes.workOrder.currentValue);
    }
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('RateTypeId').valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        if (value !== null) {
          this.paymentInfoFormService.addPaymentPartyRate(value);
        }
      }));

    // update the description of the same rate type for the primary payment party
    this.subscription$.add(this.inputFormGroup.get('Description').valueChanges.pipe(
      filter(() => this.readOnlyStorage.IsEditable),
      distinctUntilChanged(),
      takeUntil(this.isDestroyed$)
    ).subscribe(value => {
      const currentRateTypeId = this.inputFormGroup.get('RateTypeId').value;
      this.paymentInfoFormService.updatePaymentRateDescription(
        0,
        currentRateTypeId,
        value
      );
    }));

    // if sync payment rate unit if the following condition is met
    this.subscription$.add(this.inputFormGroup.get('RateUnitId').valueChanges.pipe(
      filter(() => this.readOnlyStorage.IsEditable),
      distinctUntilChanged(),
      takeUntil(this.isDestroyed$)
    ).subscribe(value => {
      if (value === PhxConstants.RateUnit.Monthly || value === PhxConstants.RateUnit.Words || value === PhxConstants.RateUnit.Shift) {
        const currentRateTypeId = this.inputFormGroup.get('RateTypeId').value;
        this.paymentInfoFormService.updateRateUnitIdFormControl(value, currentRateTypeId);
      }
    }));

  }

  onWorkOrderUpdate(workorder: IWorkOrder) {
    const wov = workorder ? workorder.WorkOrderVersion : null;

    if (workorder) {
      this.previousPrimaryRate = wov.PreviousBillingRate;

      // Rate Negotiation warning
      this.isRateNegotiationEnabled = wov.IsRateNegotiationEnabled;
      if (workorder.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerTemp ||
        workorder.workerProfileTypeId === this.phxConstants.UserProfileType.WorkerCanadianSp) {
        this.showOptions = true;
      }
    }

    const lob = wov ? wov.LineOfBusinessId : null;
    const billingInfo = wov?.BillingInfoes?.length ? wov.BillingInfoes[0] : null;
    const organizationId = billingInfo ? billingInfo.OrganizationIdClient : null;
    if (lob && organizationId && lob !== this.workorderLineOfBusinessId && organizationId !== this.billingOraganizationId) {

      // ASYNC CALL HERE, POTENTIAL RACE CONDITION
      this.workorderService.getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId).subscribe(val => {
        this.rebates = val.Rebates;
        this.vms = val.VmsFees;
        if (this.rebates) {
          this.rebates.Headers.forEach(header => {
            header.Versions.forEach(version => {
              const item = {} as IAvailableRebates;
              item.description = header.Description;
              item.headerId = header.Id;
              item.lineOfBusinessId = version.LineOfBusinessId;
              item.rate = version.Rate;
              item.rebateTypeId = version.RebateTypeId;
              item.type = this.rebates.type;
              item.versionId = version.Id;
              this.html.commonLists.listavailableRebates.push(item);
              this.html.commonLists.listavailableRebates = _filter(this.html.commonLists.listavailableRebates, ['lineOfBusinessId', lob]);
            });
          });
        }
        if (this.vms) {
          this.vms.Headers.forEach(header => {
            header.Versions.forEach(version => {
              const item = {} as IAvailableRebates;
              item.description = header.Description;
              item.headerId = header.Id;
              item.lineOfBusinessId = version.LineOfBusinessId;
              item.rate = version.Rate;
              item.rebateTypeId = version.RebateTypeId;
              item.type = this.vms.type;
              item.versionId = version.Id;
              this.html.commonLists.listavailableVmsFees.push(item);
              this.html.commonLists.listavailableVmsFees = _filter(this.html.commonLists.listavailableVmsFees, ['lineOfBusinessId', lob]);
            });
          });
        }
      });
    }
    this.workorderLineOfBusinessId = lob;
    this.billingOraganizationId = organizationId;
  }

  setRateWarnings() {
    this.html.rateWarningMessage = null;

    if (this.isRateNegotiationEnabled && this.inputFormGroup.value.RateTypeId === this.phxConstants.RateType.Primary) {
      const rate = parseFloat(this.inputFormGroup.value.Rate);
      if (this.previousPrimaryRate != null && rate != null && !isNaN(rate) && this.previousPrimaryRate !== rate) {
        this.html.rateWarningMessage = 'Change in value may affect negotiation rates below';
      }
    }
  }

  onRemoveBillingPartyRate() {
    this.billingInfoFormService.deleteBillingPartyRate(this.rateIndex);
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listCurrency = this.codeValueService.getCodeValues(this.codeValueGroups.Currency, true);
    this.html.codeValueLists.listRebateTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RebateType, true);
    this.html.codeValueLists.listworkOrderRateTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RateType, true)
      .filter(n => n.id !== this.phxConstants.RateType.Stat)
      .filter(n => n.id !== this.phxConstants.RateType.Other);

    const otRateTypes = [+this.phxConstants.RateType.Overtime, +this.phxConstants.RateType.DoubleTime];
    this.html.codeValueLists.listworkOrderRateTypesNoOt = this.html.codeValueLists.listworkOrderRateTypes.filter(
      type => !otRateTypes.includes(type.id)
    );
    this.html.codeValueLists.listWorkOrderRateUnits = this.codeValueService.getCodeValues(this.codeValueGroups.RateUnit, true);
  }
}
