import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CodeValueGroups, CodeValueService } from 'src/app/common';
import { CodeValue } from 'src/app/common/model';
import { FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { IReadOnlyStorage, ITabCoreJob } from '../../models/workorder.interface';
import { CoreJobFormService } from '../../services';

@Component({
  selector: 'app-workorder-tab-core-job',
  templateUrl: './workorder-tab-core-job.component.html',
  styleUrls: ['./workorder-tab-core-job.component.less']
})
export class WorkorderTabCoreJobComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {

  @Input() readOnlyStorage: IReadOnlyStorage;
  formGroup: FormGroup<ITabCoreJob>;

  readonly codeValueGroups = CodeValueGroups;
  skillsList: Array<{ text, value }> = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private codeValueService: CodeValueService,
    private coreJobFormService: CoreJobFormService
  ) {
    super();
    this.loadSkills();
  }

  ngOnInit() {
    this.formGroup = this.coreJobFormService.formGroup;
    this.setupFormGroupListeners();
  }

  setupFormGroupListeners() {
    this.coreJobFormService.isSameJobFunctionChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isDestroyed$)
    ).subscribe(() => this.cdr.detectChanges());
  }


  private loadSkills() {
    this.skillsList = this.codeValueService.getCodeValues(this.codeValueGroups.JobSkill, true)
      .sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      })
      .map((codeValue: CodeValue) => {
        return {
          text: codeValue.text,
          value: codeValue.id
        };
      });
  }

}
