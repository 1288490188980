import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { cloneDeep, indexOf, isArray, remove, slice, uniq } from 'lodash';

import { IBillingInfo, IPartiesRateDetail, IPaymentPartiesRateDetail, IReadOnlyStorage } from '../../models';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { CodeValueService } from '../../../common';
import { CodeValue, CodeValueGroups, PhxConstants } from '../../../common/model';
import { PartyPaymentInfoFormService, WorkOrderFormService, WorkorderService } from '../../services';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { ICommonListsItem } from '../../../common/lists';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-billing-party',
  templateUrl: './workorder-billing-party.component.html',
  styleUrls: ['./workorder-billing-party.component.less']
})
export class WorkorderBillingPartyComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() billingInfoIndex: number;
  @Input() inputFormGroup: FormGroup<IPartiesRateDetail>;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  codeValueGroups = CodeValueGroups;

  paymentInfo: Array<IPaymentPartiesRateDetail>;
  oldBillingInfo: IBillingInfo[];
  AtsPlacementId: number;
  TransactionHeaderCount: number;
  WorkOrderVersionActiveCount: number;
  setCurrency = false;

  getProfilesListOrganizational$: Subscription;
  html: {
    parentOrganizationNameFromList: boolean;
    codeValueLists: {
      listCurrency: Array<CodeValue>;
      profileTypeList: Array<CodeValue>;
    };
    commonLists: {
      listOrganizationClient: Array<ICommonListsItem>;
      ProfilesListForBillingOrganization: Array<any>;
    };
  } = {
    parentOrganizationNameFromList: false,
    codeValueLists: {
      listCurrency: [],
      profileTypeList: []
    },
    commonLists: {
      listOrganizationClient: [],
      ProfilesListForBillingOrganization: []
    }
  };
  private subscription$: Subscription;

  constructor(
    private workorderService: WorkorderService,
    private codeValueService: CodeValueService,
    private workOrderFormService: WorkOrderFormService,
    private paymentInfoFormService: PartyPaymentInfoFormService,
    private listObservableService: CommonListsObservableService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
    // this.getLists();
  }

  ngOnInit() {
    this.workOrderFormService.workOrder$.pipe(
      filter(workorder => !!workorder),
      switchMap(workorder => {
        const organizationIdClient = this.inputFormGroup.get('OrganizationIdClient').value;
        return this.listObservableService
          .getDetailedOrganizationClients$(organizationIdClient)
          .pipe(
            map(listClient => ({ workorder, listClient }))
          );
      }),
      takeUntil(this.isDestroyed$)
    )
      .subscribe(data => {
        if (data) {
          this.oldBillingInfo = cloneDeep(data.workorder.WorkOrderVersion.BillingInfoes);
          this.AtsPlacementId = data.workorder.AtsPlacementId;
          this.TransactionHeaderCount = data.workorder.TransactionHeaderCount;
          this.WorkOrderVersionActiveCount = data.workorder.WorkOrderVersionActiveCount;
          this.html.commonLists.listOrganizationClient = data.listClient || [];
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormGroup) {
      const previousValue = changes.inputFormGroup.previousValue as FormGroup<IPartiesRateDetail>;

      if (previousValue == null || previousValue.value.OrganizationIdClient !== this.inputFormGroup.value.OrganizationIdClient) {
        // ASYNC CALL HERE, POTENTIAL RACE CONDITION
        this.getClientManagers();
      }

      this.setupFormGroupListeners();

    }
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): boolean {
    return !!ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('CurrencyId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        if (!this.setCurrency) {
          this.paymentInfoFormService.updateCurrenyIdFormControl(0, value);
          this.setCurrency = true;
        }
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('Hours').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe(value => {
        this.paymentInfoFormService.updateHoursFormControl(0, value);
      })
    );

  }

  // WE DO NOT ALLOW CHANGE OF CLIENT COMPANY ONCE WO IS CREATED IN DRAFT
  // DOUBLE CHECK WO TEMPLATE, IF NOT USED, CAN BE REMOVED
  onChangeOrganizationIdClient() {
    if (this.inputFormGroup.controls.OrganizationIdClient.value && this.inputFormGroup.controls.OrganizationIdClient.value > 0) {
      this.getClientManagers();
      const organizationClient = this.html.commonLists.listOrganizationClient.find(organization => {
        return organization.Id === Number(this.inputFormGroup.controls.OrganizationIdClient.value);
      });
      if (organizationClient) {
        this.inputFormGroup.controls.OrganizationClientDisplayName.patchValue(organizationClient.DisplayText);
      }
    }
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listCurrency = this.codeValueService.getCodeValues(this.codeValueGroups.Currency, true);
    this.html.codeValueLists.profileTypeList = this.codeValueService.getCodeValues(this.codeValueGroups.ProfileType, true);
  }

  getClientManagers() {
    if (this.inputFormGroup?.controls.OrganizationIdClient.value > 0) {
      if (this.getProfilesListOrganizational$) {
        this.getProfilesListOrganizational$.unsubscribe();
      }
      this.getProfilesListOrganizational$ = this.workorderService.getProfilesListOrganizational(this.inputFormGroup.controls.OrganizationIdClient.value)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe((response) => {
          const list = response ? response.map(item => item.Data) : [];
          this.html.commonLists.ProfilesListForBillingOrganization = list;
          this.html.commonLists.ProfilesListForBillingOrganization.forEach(i => {
            i.DisplayValue = i.Contact.FullName + ' - ' + i.ContactId;
          });
          this.removeInactiveProfileWithConfig(null, this.html.commonLists.ProfilesListForBillingOrganization, this.inputFormGroup.controls.UserProfileIdClient.value);
        });
    } else {
      this.html.commonLists.ProfilesListForBillingOrganization = [];
    }
  }

  removeInactiveProfileWithConfig(config = null, profiles: any, exceptionIds: any) {
    const inactiveProfileStatusIds = [2, 9, 10];
    const settings = Object.assign({}, { profileStatusId: 'ProfileStatusId', id: 'Id' }, config);
    const exceptionProfileIds = uniq(isArray(exceptionIds) ? exceptionIds : slice(arguments, 2));
    remove(profiles, profile => {
      return indexOf(inactiveProfileStatusIds, profile[settings.profileStatusId]) > -1 && indexOf(exceptionProfileIds, profile[settings.id]) < 0;
    });
  }

  getLists() {
    this.listObservableService
      .listDetailedOrganizationClients$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        if (response) {
          this.html.commonLists.listOrganizationClient = response;
        }
      });
  }

  floatApplyTwoDecimalPlaces() {
    const value = this.inputFormGroup.controls.Hours.value;
    if (isNaN(Number(value)) || value === null) {
      return null;
    }
    return parseFloat(value.toString()).toFixed(2);
  }

}
