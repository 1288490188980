<app-phx-panel headerTitle="Job Categorization">
  <form [formGroup]="formGroup" *ngIf="formGroup">
    <phx-form-control [control]="formGroup.controls.JobCategoryId" labelText="Job Category"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{formGroup.controls.JobCategoryId.value | CodeValue:codeValueGroups.JobCategory}}">
      <app-phx-select-box-code-value [formControl]="formGroup.controls.JobCategoryId" #jobCategory
                                     [groupName]="codeValueGroups.JobCategory" sortByFieldName="text">
      </app-phx-select-box-code-value>
    </phx-form-control>
  
    <phx-form-control *ngIf="formGroup.controls.JobCategoryId.value" [control]="formGroup.controls.JobFunctionId"
                      labelText="Job Function" [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{formGroup.controls.JobFunctionId.value | CodeValue:codeValueGroups.JobFunction}}">
      <app-phx-select-box-code-value [formControl]="formGroup.controls.JobFunctionId"
                                     [groupName]="codeValueGroups.JobFunction" sortByFieldName="text"
                                     [relatedGroupName]="codeValueGroups.JobCategory" [relatedValue]="jobCategory.value">
      </app-phx-select-box-code-value>
    </phx-form-control>
  
    <phx-form-control [control]="formGroup.controls.JobSkills" labelText="Skills"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{formGroup.controls.JobSkills.value | CodeValue:codeValueGroups.JobSkill}}">
      <app-phx-tag-box [dataSource]="skillsList" valueExpr="value" displayExpr="text" showSelectionControls="true"
                       formControlName="JobSkills" searchEnabled="true">
      </app-phx-tag-box>
    </phx-form-control>
  
    <hr>
  
    <phx-form-control [control]="formGroup.get('IsClientPositionTitleSameAsJobFunction')"
                      labelText="Client Position Title"
                      [editable]="readOnlyStorage.IsEditable"
                      viewModeText="{{formGroup.get('IsClientPositionTitleSameAsJobFunction').value ? 'Same as Job Function' : formGroup.get('ClientPositionTitle').value}}"
                      [enableLabelForceAsterisk]="!formGroup.get('IsClientPositionTitleSameAsJobFunction').value && !formGroup.get('ClientPositionTitle').valid">
      <div class="checkbox">
        <label>
          <input type="checkbox"
                 [attr.disabled]="!readOnlyStorage.IsEditable ? '' : null"
                 formControlName="IsClientPositionTitleSameAsJobFunction"/>
          Same as Job Function
        </label>
      </div>
    </phx-form-control>
  
    <div class="form-group"
         *ngIf="readOnlyStorage.IsEditable && !formGroup.controls.IsClientPositionTitleSameAsJobFunction.value">
      <phx-form-control [control]="formGroup.controls.ClientPositionTitle" [editable]="readOnlyStorage.IsEditable"
                        viewModeText="{{formGroup.controls.ClientPositionTitle.value}}">
              <span class="input-display-cell">
                  <input formControlName="ClientPositionTitle" type="text" class="form-control" maxlength="128"/>
              </span>
      </phx-form-control>
    </div>
  
  </form>
</app-phx-panel>
