import { Injectable } from '@angular/core';
import { forEach } from 'lodash';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IOtherEarning, IWorkOrder, IOtherEarnings, IPaymentInfo, IPaymentOtherEarning } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { Validators } from '@angular/forms';

@Injectable()
export class OtherEarningsFormService implements IFormService {

  formGroup: FormGroup<IOtherEarning>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  get otherEarningFormArray(): FormArray<IOtherEarnings> {
    return this.formGroup.get('OtherEarning') as FormArray<IOtherEarnings>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<IOtherEarning> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<IOtherEarning>({
      OtherEarning: this.createOtherEarningFormArray(workorder.WorkOrderVersion.PaymentInfoes)
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const formGroupOtherEarningsValues: IOtherEarnings[] = this.otherEarningFormArray.value;
    forEach(formGroupOtherEarningsValues, (paymentInfo: IOtherEarnings) => {
      const paymentInfoIndex = workOrder.WorkOrderVersion.PaymentInfoes.findIndex(a => a.Id === paymentInfo.PaymentInfoId);
      workOrder.WorkOrderVersion.PaymentInfoes[paymentInfoIndex] = { ...workOrder.WorkOrderVersion.PaymentInfoes[paymentInfoIndex], PaymentOtherEarnings: [...paymentInfo.PaymentOtherEarnings] };
    });
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const otherEarningFormArray = this.formGroup.get('OtherEarning') as FormArray<IOtherEarnings>;
    this.updateOtherEarningFormArray(otherEarningFormArray, workorder.WorkOrderVersion.PaymentInfoes);
  }

  private createOtherEarningFormArray(paymentInfoes: IPaymentInfo[]): FormArray<IOtherEarnings> {
    return this.fb.array<IOtherEarnings>(
      paymentInfoes.map((paymentInfo: IPaymentInfo) => this.createOtherEarningFormGroup(paymentInfo))
    );
  }

  private createOtherEarningFormGroup(paymentInfo: IPaymentInfo) {
    return this.fb.group<IOtherEarnings>({
      PaymentInfoId: paymentInfo.Id,
      OrganizationIdSupplier: paymentInfo.OrganizationIdSupplier,
      PaymentOtherEarnings: this.createPaymentOtherEarningFormArray(paymentInfo.PaymentOtherEarnings)
    });
  }

  private createPaymentOtherEarningFormArray(paymentOtherEarnings: IPaymentOtherEarning[]) {
    return this.fb.array<IPaymentOtherEarning>(
      paymentOtherEarnings.map((paymentOtherEarning: IPaymentOtherEarning) => this.createPaymentOtherEarningFormGroup(paymentOtherEarning))
    );
  }

  private createPaymentOtherEarningFormGroup(paymentOtherEarning: IPaymentOtherEarning) {
    return this.fb.group<IPaymentOtherEarning>({
      Id: [paymentOtherEarning.Id],
      PaymentOtherEarningTypeId: [paymentOtherEarning.PaymentOtherEarningTypeId],
      UseWorkerProfileVacationSetup: paymentOtherEarning.UseWorkerProfileVacationSetup,
      IsApplied: [
        paymentOtherEarning.IsApplied,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'IsApplied', [
          Validators.required
        ])
      ],
      IsAccrued: [
        paymentOtherEarning.IsAccrued,
        paymentOtherEarning.IsApplied && paymentOtherEarning.PaymentOtherEarningTypeId === PhxConstants.PaymentOtherEarningType.VacationPay
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'IsAccrued', [
            Validators.required
          ])
          : null
      ],
      RatePercentage: [
        paymentOtherEarning.RatePercentage,
        paymentOtherEarning.IsApplied
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'RatePercentage', [
            Validators.required
          ])
          : null
      ],
      SourceId: [paymentOtherEarning.SourceId],
      PaymentInfoId: [paymentOtherEarning.PaymentInfoId]
    });
  }

  private updateOtherEarningFormArray(
    formArray: FormArray<IOtherEarnings>,
    paymentInfoes: IPaymentInfo[]
  ) {
    if (formArray.length && paymentInfoes.length) {
      paymentInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IOtherEarnings>;
        if (formGroup) {
          this.updateOtherEarningFormGroup(formGroup, item);
        } else {
          formArray.push(this.createOtherEarningFormGroup(item));
        }
      });
      if (formArray.length > paymentInfoes.length) {
        this.clearArray(formArray, paymentInfoes.length);
      }
    } else if (paymentInfoes.length) {
      const array = this.createOtherEarningFormArray(paymentInfoes);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateOtherEarningFormGroup(
    formGroup: FormGroup<IOtherEarnings>,
    paymentInfo: IPaymentInfo
  ) {
    formGroup.patchValue({
      PaymentInfoId: paymentInfo.Id,
      OrganizationIdSupplier: paymentInfo.OrganizationIdSupplier,
    }, { emitEvent: false });

    const paymentOtherEarningsFormArray = formGroup.get('PaymentOtherEarnings') as FormArray<IPaymentOtherEarning>;
    this.updatePaymentOtherEarningFormArray(paymentOtherEarningsFormArray, paymentInfo.PaymentOtherEarnings);
  }

  private updatePaymentOtherEarningFormArray(
    formArray: FormArray<IPaymentOtherEarning>,
    paymentOtherEarnings: IPaymentOtherEarning[]
  ) {
    if (formArray.length && paymentOtherEarnings.length) {
      paymentOtherEarnings.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentOtherEarning>;
        if (formGroup) {
          this.updatePaymentOtherEarningFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentOtherEarningFormGroup(item));
        }
      });
      if (formArray.length > paymentOtherEarnings.length) {
        this.clearArray(formArray, paymentOtherEarnings.length);
      }
    } else if (paymentOtherEarnings.length) {
      const array = this.createPaymentOtherEarningFormArray(paymentOtherEarnings);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentOtherEarningFormGroup(
    formGroup: FormGroup<IPaymentOtherEarning>,
    paymentOtherEarning: IPaymentOtherEarning
  ) {
    formGroup.get('IsApplied').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'IsApplied', [
        Validators.required
      ])
    );

    formGroup.get('IsAccrued').setValidators(
      paymentOtherEarning.IsApplied && paymentOtherEarning.PaymentOtherEarningTypeId === PhxConstants.PaymentOtherEarningType.VacationPay
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'IsAccrued', [
          Validators.required
        ])
        : null
    );

    formGroup.get('RatePercentage').setValidators(
      paymentOtherEarning.IsApplied
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentOtherEarnings', 'RatePercentage', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: paymentOtherEarning.Id,
      PaymentOtherEarningTypeId: paymentOtherEarning.PaymentOtherEarningTypeId,
      UseWorkerProfileVacationSetup: paymentOtherEarning.UseWorkerProfileVacationSetup,
      IsApplied: paymentOtherEarning.IsApplied,
      IsAccrued: paymentOtherEarning.IsAccrued,
      RatePercentage: paymentOtherEarning.RatePercentage,
      SourceId: paymentOtherEarning.SourceId,
      PaymentInfoId: paymentOtherEarning.PaymentInfoId
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IOtherEarnings | IPaymentOtherEarning>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
