import { Component, Input, OnInit } from '@angular/core';
import { IPaymentPartiesRateDetail, IPaymentRate, IPaymentRatesDetail, IReadOnlyStorage, IRoot, IWorkOrder, OvertimeDetails } from '../../models';
import { AbstractControl, FormArray, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { PartyPaymentInfoFormService } from '../../services';

@Component({
  selector: 'app-workorder-payment-rates',
  templateUrl: './workorder-payment-rates.component.html',
  styleUrls: ['./workorder-payment-rates.component.less']
})
export class WorkorderPaymentRatesComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() inputFormGroup: FormGroup<IPaymentRatesDetail>;
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Input() paymentInfoIndex: number;
  @Input() currencyId: number;
  workOrder: IWorkOrder;

  constructor(private partyPaymentInfoFormService: PartyPaymentInfoFormService) {
    super();
  }

  ngOnInit(): void {
    this.initFormListener();
  }

  get paymentRatesFormArray(): FormArray<IPaymentRate> {
    return this.inputFormGroup.get('PaymentRates') as FormArray<IPaymentRate>;
  }

  trackByFn(index: number, item: AbstractControl<IPaymentRate>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  private initFormListener(): void {
    const prd = this.partyPaymentInfoFormService.formGroup.controls.PaymentPartiesRateDetails as FormArray<IPaymentPartiesRateDetail>;
    const prdForm = prd.controls[0] as FormGroup<IPaymentPartiesRateDetail>;
    const otFormGroup = prdForm.controls.PaymentRatesDetail as FormGroup<IPaymentRatesDetail>;

    /** NOTE: if the WO worker isn't temp or W2 then OT config doesn't matter */
    otFormGroup.controls.PaymentRates.valueChanges.pipe(startWith(otFormGroup.controls.PaymentRates.value), takeUntil(this.isDestroyed$), distinctUntilChanged()).subscribe(rates => {
      const isValidWorkerType = this.partyPaymentInfoFormService.isOvertimeEligibleProfile;
      const haveHourRateUnit = this.partyPaymentInfoFormService.isOvertimeEligibleRates(rates);

      const overtimeDetailsFormGroup = this.partyPaymentInfoFormService.formGroup.controls.OverTimeDetails as FormGroup<OvertimeDetails>;
      if (haveHourRateUnit && isValidWorkerType) {
        overtimeDetailsFormGroup.controls.IsOvertimeExempt.enable({ emitEvent: false });
        if (overtimeDetailsFormGroup.controls.IsOvertimeExempt.value === true) {
          overtimeDetailsFormGroup.controls.OvertimeExemptionReason.enable({ emitEvent: false });
        } else {
          overtimeDetailsFormGroup.controls.OvertimeExemptionReason.disable({ emitEvent: false });
        }
      }
      overtimeDetailsFormGroup.updateValueAndValidity();
    });
  }
}
