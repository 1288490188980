import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkOrder, ITabCoreCollaborators } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { Validators } from '@angular/forms';

@Injectable()
export class CoreCollaboratorFormService implements IFormService {
  formGroup: FormGroup<ITabCoreCollaborators>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const collaboratorDetails: ITabCoreCollaborators = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabCoreCollaborators>({
      AssignedToUserProfileId: [collaboratorDetails.AssignedToUserProfileId,
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'AssignedToUserProfileId', [Validators.required]
      )]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const collaboratorDetail: ITabCoreCollaborators = this.formGroup.value;

    workOrder.WorkOrderVersion.AssignedToUserProfileId = collaboratorDetail.AssignedToUserProfileId;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    const collaboratorDetails: ITabCoreCollaborators = this.mapWorkOrderToFormData(workorder);

    this.formGroup.get('AssignedToUserProfileId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'AssignedToUserProfileId',
        [Validators.required]
      )
    );
    this.formGroup.patchValue({ AssignedToUserProfileId: collaboratorDetails.AssignedToUserProfileId }, { emitEvent: false });
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabCoreCollaborators {
    return {
      AssignedToUserProfileId: (workorder.WorkOrderVersion.AssignedToUserProfileId && workorder.WorkOrderVersion.AssignedToUserProfileId !== 1)
        ? workorder.WorkOrderVersion.AssignedToUserProfileId : null
    };
  }

}
