import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService, CodeValueService, PhxConstants } from '../../../common';
import { CodeValue, CodeValueGroups, PhxDocumentFileUploadConfiguration, PhxDocumentFileUploaderOptions } from '../../../common/model';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { PhxDocumentFileUploadComponent } from '../../../common/components/phx-document-file-upload/phx-document-file-upload.component';
import { PhxEntityDocumentsListComponent } from '../../../common/components/phx-entity-documents-list/phx-entity-documents-list.component';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-workorder-tab-activity-documents',
  templateUrl: './workorder-tab-activity-documents.component.html',
  styleUrls: ['./workorder-tab-activity-documents.component.less']
})
export class WorkorderTabActivityDocumentsComponent extends BaseComponentOnDestroy implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUpload: PhxDocumentFileUploadComponent;
  @ViewChild('documentList') documentList: PhxEntityDocumentsListComponent;

  html: {
    phxConstants: typeof PhxConstants;
    documentTypeList: CodeValue[];
    documentTypeSubList: CodeValue[];
    documentTypeUnfilteredSubList: CodeValue[];
    documentFileUploadConfiguration?: PhxDocumentFileUploadConfiguration;
    fileUploaderOptionsDocumentMain: PhxDocumentFileUploaderOptions;
  } = {
    fileUploaderOptionsDocumentMain: {
      queueLimit: 5,
      maxFileSize: 20 * 1024 * 1024, // 20971520 == 20 MB
      allowedMimeType: [
        // https://github.com/valor-software/ng2-file-upload/issues/661
        // 'image/gif',
        // 'text/csv',
        // 'application/vnd.ms-excel',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      allowedFileType: [
        // https://github.com/valor-software/ng2-file-upload/issues/696
        // 'application',
        // 'video',
        // 'audio',
        // 'compress',
        // 'xls',
        // 'ppt',
        'image',
        'pdf',
        'doc'
      ]
    },
    phxConstants: PhxConstants,
    documentTypeSubList: [],
    documentTypeUnfilteredSubList: [],
    documentFileUploadConfiguration: null,
    documentTypeList: []
  };

  workorderId: any;

  constructor(private commonService: CommonService, private codeValueService: CodeValueService, private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.loadDocumentTypes();
    this.activatedRoute.params.pipe(takeUntil(this.isDestroyed$)).subscribe((params: Params) => {
      this.workorderId = params.workorderId;
    });
  }

  loadDocumentTypes() {
    this.html.documentTypeList = this.codeValueService.getRelatedCodeValues(
      CodeValueGroups.DocumentType,
      this.html.phxConstants.EntityType.WorkOrder,
      CodeValueGroups.EntityType
    );
    this.html.documentTypeUnfilteredSubList = this.codeValueService.getCodeValues(CodeValueGroups.DocumentType, null);
  }

  generateFileUploadConfig(title: string, documentType: PhxConstants.DocumentType): PhxDocumentFileUploadConfiguration {
    return new PhxDocumentFileUploadConfiguration({
      entityTypeId: PhxConstants.EntityType.WorkOrder,
      entityId: this.workorderId,
      documentTypeId: documentType,
      UploadTitle: title,
      SupportedFileExtensions: 'PNG, JPG, JPEG, TIF, BMP, PDF, DOC, DOCX | 20 MB file size limit',
      customComment: null,
      customUiConfig: {
        objectDate: null,
        objectComment: {
          value: null,
          isRequired: false,
          label: 'Description',
          helpBlock: null,
          minlength: 3,
          maxlength: 200
        },
        objectDocumentType: {
          value: null,
          isRequired: true,
          label: 'Document Type',
          helpBlock: null
        }
      }
    });
  }

  documentUploadCallbackOnDone() {
    this.documentList.loadDocumentsList();
  }

  onSuccessItem($event) {
    this.commonService.logSuccess(`${$event.item.file.name} uploaded successfully.`);
    this.documentList.loadDocumentsList();
  }

  uploadDocument(docTypeId: PhxConstants.DocumentType) {
    const title = 'Upload a supporting document to your work order';

    this.html.documentFileUploadConfiguration = this.generateFileUploadConfig(title, docTypeId);

    if (this.fileUpload) {
      this.html.documentTypeSubList = this.codeValueService.getCodeValues(CodeValueGroups.DocumentType, null).filter(x => x.parentId === docTypeId);
      this.fileUpload.showModal(this.html.fileUploaderOptionsDocumentMain);
    }
  }
}
