import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { WorkorderOffboardingService } from '../../services';
import { IOffboardingTaskDocuments } from '../../models';
import { CodeValueGroups, PhxDocument, PhxConstants } from 'src/app/common/model';
import { DocumentService } from 'src/app/common/services/document.service';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';

@Component({
  selector: 'app-workorder-offboarding-documents',
  templateUrl: './workorder-offboarding-documents.component.html',
  styleUrls: ['./workorder-offboarding-documents.component.less']
})
export class WorkorderOffboardingDocumentsComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {

  @Input() workOrderId: number;
  @Input() actualEndDate: string;
  allTaskDocuments: IOffboardingTaskDocuments [] = [];

  showOffboarding = false;
  codeValueGroups = CodeValueGroups;
  phxConstant = PhxConstants;

  constructor(private offboardingService: WorkorderOffboardingService,
              private documentService: DocumentService) {
    super();
  }

  ngOnInit() {
    this.showOffboarding = this.offboardingService.showOffboarding(this.actualEndDate);
    this.getListDocuments();
    this.offboardingService.refreshDocumentsEvent.pipe(takeUntil(this.isDestroyed$)).subscribe((data: string) => {
      this.getListDocuments();
    });
  }

  ngOnChanges() {
    this.showOffboarding = this.offboardingService.showOffboarding(this.actualEndDate);
    this.getListDocuments();
  }

  getListDocuments() {
    if (this.showOffboarding) {
      this.offboardingService.getOffboardingDocumentsByWorkOrderId(this.workOrderId).then(response => {
        this.allTaskDocuments = response;
      });
    }
  }

  openDocument(doc: PhxDocument): void {
    return this.documentService.openDocument(doc.PublicId, true);
  }
}
