<app-phx-accordion>
    <app-phx-accordion-group
      [showToggle]="false"
      [(isOpen)]="isOpen"
      panelClass="state-history-panel"
    >
        <div accordion-heading class="version-header">
            <div class="menu-icon">
              <i class="glyphicon"
                [ngClass]="{'glyphicon-menu-up': isOpen, 'glyphicon-menu-down': !isOpen}"></i>
            </div>
            <div class="version-header-content">
              <div class="col-xs-12">
                <div class="version-number">
                  Retrieve Changes
                </div>
              </div>
            </div>
        </div>
        <div class="content">
            Please contact FBO support to request change history information 
            <a *ngIf="canCreateTicket$ | async" class="btn btn-link" href="https://hotfix.procom.ca/servicedesk/customer/portal/19/create/705" target="_blank">Create Hotfix ticket</a>
        </div>
    </app-phx-accordion-group>
</app-phx-accordion>
