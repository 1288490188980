import { Component, Input } from '@angular/core';

import { CalculatedOvertimeDetails, ThresholdDetails } from '../../model/calculated-overtime-details.model';

@Component({
  selector: 'app-time-sheet-auto-calculated-overtime-details',
  templateUrl: './time-sheet-auto-calculated-overtime-details.component.html',
  styleUrls: ['./time-sheet-auto-calculated-overtime-details.component.less']
})
export class TimeSheetAutoCalculatedOvertimeDetailsComponent {
  @Input() calculatedOvertimeDetails: CalculatedOvertimeDetails<ThresholdDetails<string | number>[]>;
}
