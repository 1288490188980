import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '../../services/auth.service';

@Pipe({
  name: 'spoofValueForTenant',
  pure: false
})
export class SpoofValueForTenantPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform<T>(value: T, args: { substituteFor: T; forTenant: string }): T {
    const context = this.authService.getUserContextSync();
    let newValue: T;
    if (!value) {
      newValue = value;
    } else {
      newValue = context.User.CurrentTenantName === args.forTenant ? args.substituteFor : value;
    }
    return newValue;
  }
}
