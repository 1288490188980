<form [formGroup]="formGroup">
  <div class="content-group form-horizontal">
    <div class="content-left">
      <app-phx-panel headerTtle="Billing Taxes">
        <ng-container *ngFor="let billingInfo of formGroup.get('BillingInfoes')['controls']; let i = index">
          <ng-container *ngIf="billingInfo.controls.OrganizationIdClient.value > 0">
            <div class="form-group">
              <div class="col-lg-12">
                <p class="form-control-static">
                  <a routerLink="/next/organization/{{ billingInfo.controls.OrganizationIdClient.value }}/details">
                    {{ billingInfo.controls.OrganizationClientDisplayName.value }} - {{ billingInfo.controls.OrganizationIdClient.value }}</a
                  >
                </p>
              </div>
            </div>
            <app-workorder-billing-taxes
              [readOnlyStorage]="readOnlyStorage"
              [inputFormGroup]="billingInfo"
              [editable]="editable"
              [salesTaxJurisdictions]="billingSalesTaxJurisdictions"
            >
            </app-workorder-billing-taxes>
          </ng-container>
        </ng-container>
      </app-phx-panel>
    </div>
    <div class="content-right">
      <app-phx-panel headerTitle="Payment Taxes">
        <ng-container *ngFor="let paymentInfo of paymentInfos; let i = index">
          <ng-container *ngIf="paymentInfo.value.OrganizationIdSupplier || workerProfilesEligibleForTaxes.has(workerProfileTypeId)">
            <div class="form-group">
              <div class="col-lg-12">
                <p class="form-control-static">
                  <ng-container *ngIf="paymentInfo.controls.OrganizationIdSupplier.value; else workerLink">
                    <a routerLink="/next/organization/{{ paymentInfo.controls.OrganizationIdSupplier.value }}/details">
                      {{ paymentInfo.controls.OrganizationSupplierDisplayName.value }} - {{ paymentInfo.controls.OrganizationIdSupplier.value }}
                    </a>
                  </ng-container>
                  <ng-template #workerLink>
                    <app-workorder-worker-link [workerProfileId]="userProfileIdWorker"></app-workorder-worker-link>
                  </ng-template>
                </p>
              </div>
            </div>
            <app-workorder-payment-taxes
              [readOnlyStorage]="readOnlyStorage"
              [inputFormGroup]="paymentInfo"
              [editable]="editable"
              [userProfileIdWorker]="userProfileIdWorker"
              [paymentInfoIndex]="i"
              [workerProfileTypeId]="workerProfileTypeId"
              [validateComplianceDraft]="validateComplianceDraft"
              [salesTaxJurisdictions]="paymentSalesTaxJurisdictions"
            >
            </app-workorder-payment-taxes>
          </ng-container>
        </ng-container>
      </app-phx-panel>
    </div>
  </div>
</form>
