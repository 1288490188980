<app-phx-modal [title]="title" [buttons]="buttons" (closeModal)="closed()">

  <div [formGroup]="form" *ngIf="form && entity">
    <div class="col-xs-12">
      <div class="alert alert-danger" *ngIf="form.dirty && form.errors?.DocumentOrComment">
        {{ alertMessage }}
      </div>
      <app-phx-validation-messages [messages]="validationMessages"></app-phx-validation-messages>
    </div>
    <div *ngIf="detail !==''" class="row div-detail">
      <span>
        <i class="material-icons md-18">done</i>
        {{detail}}
      </span>
    </div>
    <div class="row">
      <phx-form-control [control]="form.get('OffboardingTaskTypeId')" labelText="Task" [layoutType]="layoutType">
        <div class="div-task-type">
          {{form.get('OffboardingTaskTypeId').value | CodeValue: codeValueGroups.WorkOrderOffboardingTask}}
        </div>
      </phx-form-control>
    </div>
    <div class="row">
      <div class="col-xs-12 divlabel" *ngIf="instructions?.length">
        <p>{{ instructions }}</p>
      </div>
      <phx-form-control [control]="form.get('Notes')" class="comment" [showValidationMessages]="true"
        [labelText]="'Notes'" [enableLabelForceAsterisk]="isNotesAsteriskEnabled()" [enableLabelAsterisk]="false"
        [layoutType]="layoutType">
        <textarea [formControl]="form.get('Notes')" class="form-control" rows="10"
          placeholder="Minimum of 10 characters"></textarea>
      </phx-form-control>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <phx-form-control-label [control]="form.get('Documents')" [name]="'Documents'"
          [enableForceAsterisk]="isDocumentsAsteriskEnabled()">Document</phx-form-control-label>
        <button class="btn btn-link pull-right no-padding-h-r no-padding-v-b" *ngIf="!entity.exemption && editable"
          (click)="uploadDocument(entity)" [disabled]="!editable"> Add File
        </button>
      </div>
      <div class="col-xs-12 divlabel error" *ngIf="form.get('Documents').value?.length === 0"
        [class.error]="form.errors?.DocumentOrComment && form.dirty">
        No file uploaded
      </div>
      <div formArrayName="Documents" class="col-xs-12" *ngFor="let doc of form.get('Documents').value">
        <a class="btn btn-link no-padding-h-l" (click)="openDocument(doc)">{{ doc.Name }}</a>
        <button *ngIf="editable" class="close pull-right" (click)="removeDocument(doc)">
          <i class="material-icons">
            close
          </i>
        </button>
      </div>

    </div>
  </div>
  <app-phx-validation-messages [messages]="validationMessages"></app-phx-validation-messages>
  <app-phx-document-file-upload [editable]="false" [showAddButton]="false" [insideModal]="true"
    [fileUploaderOptions]="fileUploaderOptionsDocumentMain" [configuration]="documentFileUploadConfiguration"
    (successOnEachItem)="callBackOnFileUploadItem($event)">
  </app-phx-document-file-upload>
</app-phx-modal>