import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IAvailableRebates, IPaymentInfo, IReadOnlyStorage, IRebateAndVMSFee, IWorkOrder } from '../../models';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { CodeValue, CodeValueGroups, PhxConstants } from '../../../common/model';
import { EarningsDeductionsTabFormService, PartyPaymentInfoFormService, WorkOrderFormService, WorkorderService } from '../../services';
import { filter as _filter, find } from 'lodash';
import { CodeValueService } from '../../../common';
import { Subscription } from 'rxjs';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-workorder-rebate-vmsfee',
  templateUrl: './workorder-rebate-vmsfee.component.html',
  styleUrls: ['./workorder-rebate-vmsfee.component.less']
})
export class WorkorderRebateVmsfeeComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  /**
   * Profile types that require a selection for flowdown fee. Add more to the array to include more types.
   * Used to determine if the field should be displayed. For setting required add role to control-field-accessibility.ts
   * method ptFieldViewEventOnChangeStatusId where the fieldname is IsFlowdownFee
   */
  static readonly flowdownFeeProfileTypes = [PhxConstants.UserProfileType.WorkerSubVendor];
  @Input() inputFormGroup: FormGroup<IRebateAndVMSFee>;
  @Output() removeRate = new EventEmitter<any>();

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  workorder: IWorkOrder;

  showFlowdownFee = false;
  codeValueGroups = CodeValueGroups;

  rebates: any;
  vms: any;
  list: Array<any> = [];
  workorderLineOfBusinessId: number;
  billingOraganizationId: number;
  html: {
    codeValueLists: {
      listRebateTypes: Array<CodeValue>;
    };
    commonLists: {
      listavailableRebates: Array<IAvailableRebates>;
      listavailableVmsFees: Array<IAvailableRebates>;
    };
  } = {
      codeValueLists: {
        listRebateTypes: []
      },
      commonLists: {
        listavailableRebates: [],
        listavailableVmsFees: []
      }
    };

  private subscription$: Subscription;


  constructor(
    private authService: AuthService,
    private codeValueService: CodeValueService,
    private workorderService: WorkorderService,
    private workOrderFormService: WorkOrderFormService,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService,
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  get rebateTypeIdFormGroup() {
    return this.inputFormGroup.get('RebateTypeId');
  }

  get rebateTypeIdFormGroupValue() {
    return this.rebateTypeIdFormGroup.value;
  }

  get rebateRateFormGroup() {
    return this.inputFormGroup.get('RebateRate');
  }

  get rebateRateFormGroupValue() {
    return this.rebateRateFormGroup.value;
  }

  get vmsFeeTypeIdFormGroup() {
    return this.inputFormGroup.get('VmsFeeTypeId');
  }

  get vmsFeeTypeIdFormGroupValue() {
    return this.vmsFeeTypeIdFormGroup.value;
  }

  get vmsFeeRateFormGroup() {
    return this.inputFormGroup.get('VmsFeeRate');
  }

  get vmsFeeRateFormGroupValue() {
    return this.vmsFeeRateFormGroup.value;
  }

  get isFlowdownFeeFormGroup() {
    return this.inputFormGroup.get('IsFlowdownFee');
  }

  get isFlowdownFeeFormGroupValue() {
    return this.isFlowdownFeeFormGroup.value;
  }

  ngOnInit() {
    this.workOrderFormService.workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe(workorder => {
        this.workorder = workorder;
        const wov = workorder ? workorder.WorkOrderVersion : null;

        this.setShowFlowdownFee(
          workorder ? workorder.workerProfileTypeId : null,
          wov ? wov.PaymentInfoes : []
        );

        const lob = wov ? wov.LineOfBusinessId : null;
        const billingInfo = wov?.BillingInfoes?.length ? wov.BillingInfoes[0] : null;
        const organizationId = billingInfo ? billingInfo.OrganizationIdClient : null;
        if (lob && organizationId && lob !== this.workorderLineOfBusinessId && organizationId !== this.billingOraganizationId) {

          // ASYNC CALL HERE, POTENTIAL RACE CONDITION
          this.workorderService.getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId).subscribe(val => {
            this.rebates = val.Rebates;
            this.vms = val.VmsFees;
            if (this.rebates) {
              this.html.commonLists.listavailableRebates = [];
              this.rebates.Headers.forEach(header => {
                header.Versions.forEach(version => {
                  const item = {} as IAvailableRebates;
                  item.description = header.Description;
                  item.headerId = header.Id;
                  item.lineOfBusinessId = version.LineOfBusinessId;
                  item.rate = version.Rate;
                  item.rebateTypeId = version.RebateTypeId;
                  item.type = this.rebates.type;
                  item.versionId = version.Id;
                  item.headerStatusId = header.RebateHeaderStatusId;

                  this.html.commonLists.listavailableRebates.push(item);
                  this.html.commonLists.listavailableRebates = _filter(this.html.commonLists.listavailableRebates, ['lineOfBusinessId', lob]);
                });
              });
              this.html.commonLists.listavailableRebates = this.getActiveOrSelectedList(this.html.commonLists.listavailableRebates,
                                                                                        this.inputFormGroup.get('RebateHeaderId').value,
                                                                                        this.phxConstants.RebateHeaderStatus);
            }
            if (this.vms) {
              this.html.commonLists.listavailableVmsFees = [];
              this.vms.Headers.forEach(header => {
                header.Versions.forEach(version => {
                  const item = {} as IAvailableRebates;
                  item.description = header.Description;
                  item.headerId = header.Id;
                  item.lineOfBusinessId = version.LineOfBusinessId;
                  item.rate = version.Rate;
                  item.rebateTypeId = version.RebateTypeId;
                  item.type = this.vms.type;
                  item.versionId = version.Id;
                  item.isFlowdownFee = version.IsFlowdownFee;
                  item.headerStatusId = header.RebateHeaderStatusId;
                  
                  this.html.commonLists.listavailableVmsFees.push(item);
                  this.html.commonLists.listavailableVmsFees = _filter(this.html.commonLists.listavailableVmsFees, ['lineOfBusinessId', lob]);
                });
              });
              this.html.commonLists.listavailableVmsFees = this.getActiveOrSelectedList(this.html.commonLists.listavailableVmsFees,
                                                                                        this.inputFormGroup.get('VmsFeeHeaderId').value,
                                                                                        this.phxConstants.VmsFeeHeaderStatus);
            }
          });
        }
        this.workorderLineOfBusinessId = lob;
        this.billingOraganizationId = organizationId;
      });

    this.partyPaymentInfoFormService.organizationIdSupplierChange$.pipe(
      debounceTime(100),
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.workorder = this.partyPaymentInfoFormService.formGroupToPartial(this.workorder);
      this.setShowFlowdownFee(
        this.earningsDeductionsTabFormService.workerProfileTypeIdValue,
        this.workorder.WorkOrderVersion.PaymentInfoes
      );
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputFormGroup) {
      this.setupFormGroupListeners();
    }
  }

  // filter the list of rebates and vms fees to get only active rebates/fees and the previously saved one (even if it was inactive)
  private getActiveOrSelectedList(list: Array<IAvailableRebates>, selectedHeader: number, headerStatusEnum) {
    list = list.filter(vmsRebate => vmsRebate.headerStatusId === headerStatusEnum.Active || vmsRebate.headerId === selectedHeader) // all active or selected headers
               .map(vmsRebate => ({ // add 'Inactive' to description of inactive headers
                  ...vmsRebate,
                  description: vmsRebate.headerStatusId === headerStatusEnum.InActive ? vmsRebate.description + ' (Inactive)'
                                                                                      : vmsRebate.description
                }));
    return list;
  }

  setupFormGroupListeners() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.inputFormGroup.get('RebateHeaderId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(() => {
        this.patchValue({
          RebateTypeId: null,
          RebateRate: null
        });
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('RebateTypeId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(() => {
        this.patchValue({
          RebateRate: null
        });
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('HasRebate').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(valueOfHasRebate => {

        this.rebateTypeIdFormGroup.setValidators(
          valueOfHasRebate && !this.workorder.WorkOrderVersion.RebateHeaderId
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
              Validators.required
            ])
            : null
        );

        this.rebateRateFormGroup.setValidators(
          valueOfHasRebate && !this.workorder.WorkOrderVersion.RebateHeaderId
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
              Validators.required
            ])
            : null
        );

        this.patchValue({
          RebateHeaderId: null,
          RebateTypeId: this.rebateTypeIdFormGroupValue || null,
          RebateRate: this.rebateRateFormGroupValue || null,
        });
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('HasVmsFee').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(valueOfHasVmsFee => {

        this.vmsFeeTypeIdFormGroup.setValidators(
          valueOfHasVmsFee && !this.workorder.WorkOrderVersion.VmsFeeHeaderId
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
              Validators.required
            ])
            : null
        );

        this.vmsFeeRateFormGroup.setValidators(
          valueOfHasVmsFee && !this.workorder.WorkOrderVersion.VmsFeeHeaderId
            ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
              Validators.required
            ])
            : null
        );

        this.patchValue({
          VmsFeeHeaderId: null,
          VmsFeeTypeId: this.vmsFeeTypeIdFormGroupValue || null,
          VmsFeeRate: this.vmsFeeRateFormGroupValue || null,
        });

        if (this.showFlowdownFee) {
          this.isFlowdownFeeFormGroup.setValidators(
            valueOfHasVmsFee && !this.workorder.WorkOrderVersion.VmsFeeHeaderId
              ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrder', 'IsFlowdownFee', [
                Validators.required
              ])
              : null
          );

          this.patchValue({
            IsFlowdownFee: this.isFlowdownFeeFormGroupValue || null
          });
        }
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('VmsFeeTypeId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(() => {
        this.patchValue({
          VmsFeeRate: null
        });
      })
    );

    this.subscription$.add(
      this.inputFormGroup.get('VmsFeeHeaderId').valueChanges.pipe(
        filter(() => this.readOnlyStorage.IsEditable),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$),
      ).subscribe(value => {
        this.patchValue({
          VmsFeeTypeId: null,
          VmsFeeRate: null,
          IsFlowdownFee: value ? null : false
        });
      })
    );
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listRebateTypes = this.codeValueService.getCodeValues(this.codeValueGroups.RebateType, true);
  }

  getRebateType(list: any, rebateHeaderId: number) {
    if (list.length && rebateHeaderId) {
      const item = this.getRebateOrVmsFee(list, rebateHeaderId);
      const rebateTypeId = item ? item.rebateTypeId : null;
      const rebateType = find(this.html.codeValueLists.listRebateTypes, ['id', rebateTypeId]);
      return rebateType ? rebateType.text : null;
    }
  }

  getRebateOrVmsFeeRate(list: any, rebateHeaderId: number) {
    if (list.length && rebateHeaderId) {
      const item = this.getRebateOrVmsFee(list, rebateHeaderId);
      return item && this.displayRate(item);
    }
  }

  getRebateOrVmsFee(list: any, rebateHeaderId: number) {
    let item: any;
    if (list.length && rebateHeaderId) {
      item = find(list, ['headerId', rebateHeaderId]);
    }
    return item;
  }

  /**
   * Get the flowdown fee associated with the vms fee
   * @param list List to search in
   * @param headerId VmsFeeHeaderId
   */
  getFlowdownFee(list: any, headerId: number): boolean {
    if (list.length && headerId) {
      const item = this.getRebateOrVmsFee(list, headerId);
      return item?.isFlowdownFee;
    }
  }

  displayRate(item) {
    return item.rebateTypeId === this.phxConstants.RebateType.Amount ? '$' + item.rate : item.rebateTypeId === this.phxConstants.RebateType.Percentage ? item.rate + '%' : null;
  }

  private patchValue(value: Partial<IRebateAndVMSFee>, emitEvent = false) {
    this.inputFormGroup.patchValue(value, { emitEvent });
  }

  private setShowFlowdownFee(workerProfileTypeIdValue: number, paymentInfoes: Array<IPaymentInfo>) {
    const isWorkerSubVendor = workerProfileTypeIdValue ? WorkorderRebateVmsfeeComponent.flowdownFeeProfileTypes.includes(workerProfileTypeIdValue) : false;
    const doesSubVendorOrganizationExist = paymentInfoes?.some(pi => pi.IsOrganizationSupplierSubVendor);

    this.showFlowdownFee = isWorkerSubVendor || doesSubVendorOrganizationExist;
  }

}
