import { Directive, OnInit } from '@angular/core';

import { PhxDateBoxComponent } from '../../components/phx-date-box/phx-date-box.component';

@Directive({
  selector: '[appMaxDateYesterday]'
})
export class MaxDateYesterdayDirective implements OnInit {
  constructor(private el: PhxDateBoxComponent) {}

  ngOnInit() {
    this.el.max = Date.now() - 24 * 60 * 60 * 1000;
  }
}
