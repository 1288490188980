import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CodeValueGroups, PhxConstants } from '../../model';
import { ConfigurationService } from '../../../configuration/service/configuration.service';
import { AuthService } from '../auth.service';
import { WorkerSearchResult } from './models/worker-search-result.model';
import { ArrayOfSearchResults } from '../../model/array-of-search-results.model';
import { environment } from '../../../../environments/environment';
import { DocumentCollectorParams } from './models/document-collector-params.model';
import { PhxLocalizationService } from '../phx-localization.service';
import { ApiService } from '../api.service';
import { WindowRefService } from '../WindowRef.service';
import { CodeValueService } from '../code-value.service';
import { DocumentCollectorCheckParams } from './models/document-collector-check-params.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentCollectorService {
  private readonly profilesCache: Map<number, WorkerSearchResult> = new Map();

  constructor(
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private apiService: ApiService,
    private winRef: WindowRefService,
    private codeValueService: CodeValueService
  ) {}

  public checkCollectDocumentsIsAvailable(params: { entityTypeId: number; workerProfileTypeId: number }): boolean {
    return (
      params.entityTypeId === PhxConstants.EntityType.WorkOrder &&
      params.workerProfileTypeId !== PhxConstants.UserProfileType.WorkerSubVendor &&
      this.authService.getUserContextSync().User.CurrentTenantName === 'Procom' &&
      this.configurationService.isFeatureActive(PhxConstants.FeatureFlags.DocumentCollector)
    );
  }

  public goToDocumentCollector(params: DocumentCollectorCheckParams): void {
    this.searchWorkerById(params.workerId)
      .pipe(
        filter(r => Boolean(r)),
        map(r => this.mapWorkerProfileToDocumentCollectorParams(r, params))
      )
      .subscribe(p => {
        const url = new URL(environment.documentCollectorPageUrl);

        for (const [key, value] of Object.entries(p)) {
          url.searchParams.append(key, typeof value === 'string' ? value : value.toString());
        }

        this.winRef.nativeWindow.open(url.href, '_blank');
      });
  }

  private searchWorkerById(id: number): Observable<WorkerSearchResult | null> {
    const fieldsToSelect: Array<keyof WorkerSearchResult> = ['FirstName', 'LastName', 'PrimaryEmail', 'PhoneNumber'];

    const path = `report/${PhxConstants.ReportType.PeopleAllReport}?$select=${fieldsToSelect.join(',')}&$top=1&$filter=EntityId eq ${id}`;

    return this.profilesCache.has(id) ? of(this.profilesCache.get(id)) : this.apiService.query<ArrayOfSearchResults<WorkerSearchResult>>(path, false, true).pipe(map(({ value }) => value[0] ?? null));
  }

  private mapWorkerProfileToDocumentCollectorParams(worker: WorkerSearchResult, params: DocumentCollectorCheckParams): DocumentCollectorParams {
    const userContext = this.authService.getUserContextSync();
    const userProfile = userContext.User.UserProfiles.find(({ Id }) => Id === userContext.User.CurrentProfileId);
    return {
      referencecode: params.referenceCode,
      entityid: params.entityId,
      entitytype: this.codeValueService.getCodeValueCode(params.entityTypeId, CodeValueGroups.EntityType),
      fromemail: userProfile?.PrimaryEmail,
      instancename: userContext.User.CurrentTenantName,
      lang: PhxLocalizationService.locale.toLowerCase() as Lowercase<string>,
      ModuleID: 1,
      product: 'FBO',
      recipientemail: worker.PrimaryEmail ?? '',
      recipientfirstname: worker.FirstName ?? '',
      recipientlastname: worker.LastName ?? '',
      recipientphone: worker.PhoneNumber ?? ''
    };
  }
}
