import { Component, Input, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, switchMap, startWith } from 'rxjs/operators';

import { PhxConstants, CodeValueService } from '@common';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { ICommonListsItem } from '@common/lists';
import { CodeValue, CodeValueGroups } from '@common/model';
import { FormGroup, AbstractControl, FormArray } from '@common/ngx-strongly-typed-forms';
import { AuthService } from '@common/services/auth.service';

import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { ITabTimeMaterialInvoiceDetail, ITimeSheetApprover, IRoot, IReadOnlyStorage } from '../../models';
import { TimeMaterialInvoiceDetailFormService, WorkorderService } from '../../services';

@Component({
  selector: 'app-workorder-time-material-detail',
  templateUrl: './workorder-time-material-detail.component.html',
  styleUrls: ['./workorder-time-material-detail.component.less']
})
export class WorkorderTimeMaterialDetailComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  readonly phxConstants = PhxConstants;
  readonly codeValueGroups = CodeValueGroups;
  
  formGroup: FormGroup<ITabTimeMaterialInvoiceDetail>;

  html = {
    codeValueLists: {
      listTimesheetMethodologies: [] as Array<CodeValue>,
      listTimesheetCycles: [] as Array<CodeValue>,
      listTimeSheetApprovalFlows: [] as Array<CodeValue>
    },
    timeCard: {
      timeCardApproval: false,
      timeCardCycle: false,
      projectsAndCoding: false,
      timecardDescription: false
    },
    commonLists: {
      // FIXME: fix type, looks like an extension of ICommonListsItem
      listProfilesForApproval: [] as Array<any>
    }
  };

  private subscription$: Subscription;

  constructor(
    private codeValueService: CodeValueService,
    private workOrderService: WorkorderService,
    private timeMaterialInvoiceDetailFormService: TimeMaterialInvoiceDetailFormService,
    private authService: AuthService
  ) {
    super();
    this.getCodeValuelistsStatic();
  }

  get timeSheetApproversFormArray(): FormArray<ITimeSheetApprover> {
    return this.formGroup.get('TimeSheetApprovers') as FormArray<ITimeSheetApprover>;
  }

  ngOnInit() {
    this.formGroup = this.timeMaterialInvoiceDetailFormService.formGroup;
    this.setupFormGroupListener();
  }

  setupFormGroupListener() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }

    this.subscription$ = new Subscription();

    this.subscription$.add(
      this.timeMaterialInvoiceDetailFormService.organizationIdChange$.pipe(
        startWith(this.timeMaterialInvoiceDetailFormService.organizationIdValue),
        distinctUntilChanged(),
        switchMap(orgId => {
          if (orgId) {
            // INVESTIGATE HOW THIS COMPONENT IS BEING USED
            // IF TOGGLE BY URL, THIS ASYNC CALL MIGHT NOT GET TRIGGER AGAIN WITH UPDATED VALUES
            return this.workOrderService.getProfilesListOrganizational(orgId);
          } else {
            return of([] as ICommonListsItem[]);
          }
        }),
        takeUntil(this.isDestroyed$)
      ).subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        this.html.commonLists.listProfilesForApproval = list;
        this.html.commonLists.listProfilesForApproval.forEach(element => {
          element.DisplayValue = element.Contact.FullName + ' - ' + element.Contact.Id;
        });
      })
    );

    this.subscription$.add(
      this.timeMaterialInvoiceDetailFormService.timeSheetMethodologyIdChange$.pipe(
        startWith(this.timeMaterialInvoiceDetailFormService.timeSheetMethodologyIdValue),
        distinctUntilChanged(),
        takeUntil(this.isDestroyed$)
      ).subscribe((value) => {
        this.html.timeCard.timeCardApproval = value && value === PhxConstants.TimeSheetMethodology.OnlineApproval;
        this.html.timeCard.timeCardCycle = !(!value || value === PhxConstants.TimeSheetMethodology.NoTimesheet);
        this.html.timeCard.projectsAndCoding = value && (value === PhxConstants.TimeSheetMethodology.OnlineApproval || value === PhxConstants.TimeSheetMethodology.OfflineApproval);
        this.html.timeCard.timecardDescription = value && (value === PhxConstants.TimeSheetMethodology.OnlineApproval || value === PhxConstants.TimeSheetMethodology.OfflineApproval);
      })
    );
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  getCodeValuelistsStatic() {
    this.html.codeValueLists.listTimesheetCycles = this.codeValueService.getCodeValues(this.codeValueGroups.TimeSheetCycle, true);
    this.html.codeValueLists.listTimesheetMethodologies = this.codeValueService.getCodeValues(this.codeValueGroups.TimeSheetMethodology, true);
    this.html.codeValueLists.listTimeSheetApprovalFlows = this.codeValueService.getCodeValues(this.codeValueGroups.TimeSheetApprovalFlow, true);
  }

  trackByFn(index: number, item: AbstractControl<ITimeSheetApprover>) {
    return item.value.Id || (item.root.value as IRoot).Id + '_NEW_' + index;
  }

  addTimeSheetApproverDefinition() {
    this.timeMaterialInvoiceDetailFormService.addTimeSheetApproverDefinitionFormGroup();
  }

  removeTimeSheetApproverDefinition(index: number) {
    this.timeMaterialInvoiceDetailFormService.removeTimeSheetApproverDefinitionFormGroup(index);
  }

}
