import { Injectable, EventEmitter } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import moment from 'moment';

import { ApiService, PhxConstants } from '@common';
import { EntityList } from '@common/model';

import { IOffboardingTask, IOffboardingTaskDocuments } from '../models';

@Injectable({
  providedIn: 'root'
})
export class WorkorderOffboardingService {

  constructor(private apiService: ApiService) { }

  refreshDocumentsEvent: EventEmitter<any> = new EventEmitter<any>();

  statusesPending = [
    PhxConstants.WorkOrderOffboardingTaskStatus.PendingAccounting,
    PhxConstants.WorkOrderOffboardingTaskStatus.PendingClientServices
  ];

  statusesCompleted = [
    PhxConstants.WorkOrderOffboardingTaskStatus.CompleteClientServices,
    PhxConstants.WorkOrderOffboardingTaskStatus.CompleteAccounting
  ];

  statusesExemption = [
    PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionClientServices,
    PhxConstants.WorkOrderOffboardingTaskStatus.ExemptionAccounting
  ];

  daysToStartOffboarding() {
    return 30;
  }

  showOffboarding(actualEndDate: string) {
    const endDate = moment(actualEndDate, PhxConstants.DateFormat.API_Date);
    const today = moment().startOf('day');
    const daysUntilEndDate = endDate.diff(today, 'day');
    return daysUntilEndDate <= this.daysToStartOffboarding();
  }

  refreshDocuments() {
    this.refreshDocumentsEvent.emit(null);
  }

  getOffboardingTasksByWorkOrderId(workOrderId: number) {
    return lastValueFrom(this.apiService.query<{WorkOrderId: number; OffboardingTasks: IOffboardingTask[]}>(`assignment/getOffboardingTasksByWorkOrderId/${workOrderId}`))
    .then(response => {
      response.OffboardingTasks.forEach(task => {
        task.completed = this.statusesCompleted.includes(task.StatusId);
        task.exemption = this.statusesExemption.includes(task.StatusId);
        task.pending = this.statusesPending.includes(task.StatusId);
        task.isCompliance = task.Config.IsCompliance;
      });
      return response;
    });
  }

  getOffboardingDocumentsByWorkOrderId(workOrderId: number) {
    return lastValueFrom(this.apiService.query<EntityList<IOffboardingTaskDocuments>>(`assignment/getOffboardingDocumentsByWorkOrderId/${workOrderId}`))
    .then(response => {
      return response.Items;
    });
  }
}
