export interface ICommissionChangeImpact {
    AssignmentId: number;
    WorkOrderId: number;
    WorkOrderVersionId: number;
    CurrencyId: number;
    WorkOrderNumber: number;
    WorkOrderVersionNumber: number;
    TransactionHeaderId: number;
    TransactionNumber: string;
    TransactionDate: Date;
    TransactionGrossMarginAmount: number;
    CurrentCommissionTransactionStatusId: number;
    CurrentCommission: ICommissionTransationImpact;
    Debit: ICommissionTransationImpact;
    Credit: ICommissionTransationImpact;
    NetImpact: number;
    CorrectionReason: string;
}

export interface ICommissionTransationImpact {
    Id: number;
    UserProfileId: number;
    FirstName: string;
    LastName: string;
    Rate: number;
    CommissionRate: number;
    ScalePercentageRate: number;
    Date: Date;
    Amount: number;
    Role: string;
    Description: string;
}

export interface ICommissionChangeImpactSummary {
    ImpactApprovedDateTime?: Date;
    ImpactApprovedByUserProfileId?: number;
    ImpactApprovedByUserProfileFirstName: string;
    ImpactApprovedByUserProfileLastName: string;
    CorrectionPeriodStartDate: Date;
    CorrectionPeriodEndDate: Date;
    ExceedsMaxNumOfPossibleCorrections: boolean;
    CommissionTransactionChangeImpacts: ICommissionChangeImpact[];
}

export interface IUsersImpacted {
    Id: number;
    Name: string;
}

export class CommissionChangeImpactModalButtons {
    static ConfirmApproval = 'Confirm Approval';
    static Ok = 'Ok';
    static Cancel = 'Cancel';
}
