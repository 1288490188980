<app-phx-panel headerTitle="Commissions">
  <div class="alert alert-dismissable info-box"
    [ngClass]="!workOrder.WorkOrderVersion.IsWovCommissionChanged || workOrder?.WorkOrderVersion?.IsCommissionChangeImpactApproved ? 'alert-info' : 'alert-error'"
    *ngIf="html.showChangeImpactMsg">
    <div [innerHTML]="CommissionImpactMessage">
    </div>
  </div>
  
  <form [formGroup]="formGroup" *ngIf="formGroup">

    <phx-form-control [control]="formGroup.controls.SalesPatternId" labelText="Sales Pattern"
      [editable]="readOnlyStorage.IsCommissionsEditable && checkPtFiledAccessibility('WorkOrderVersion','SalesPatternId')"
      viewModeText="{{formGroup.controls.SalesPatternId.value | lookup: html.lists.salesPatterns:'Id':'Description'}}">
      <app-phx-select-box [formControl]="formGroup.controls.SalesPatternId" [items]="html.lists.salesPatterns"
        textField="Description" valueField="Id">
      </app-phx-select-box>
    </phx-form-control>

    <ng-container *ngIf="formGroup.controls.SalesPatternId.value">
      <div class="form-group">
        <label class="col-lg-4 control-label label-to-right">Job Owner</label>
        <div class="col-lg-8">
          <div class="fieldViewMode form-control-static ">
            {{ jobOwnerFormGroup?.value?.FullName }}
          </div>
        </div>
      </div>
      <div class="form-group" *ngFor="let support of supportingJobOwnerFormArray?.value; let i = index">
        <label class="col-lg-4 control-label label-to-right" *ngIf="i === 0">Support</label>
        <div class="col-lg-4" *ngIf="i > 0"></div>
        <div class="col-lg-8">
          <div class="fieldViewMode form-control-static ">
            {{ support.FullName }}
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!formGroup.controls.SalesPatternId.value">
      <phx-form-control labelText="Job Owner Uses Support?" [control]="formGroup.controls.JobOwnerUsesSupport"
        [editable]="readOnlyStorage.IsCommissionsEditable && checkPtFiledAccessibility('WorkOrderVersion','JobOwnerUsesSupport')"
        viewModeText="{{formGroup.controls.JobOwnerUsesSupport.value ? 'Yes' : 'No'}}">
        <label class="radio-inline">
          <input class="form-check-input" type="radio" [value]="true" required
            formControlName="JobOwnerUsesSupport">Yes</label>
        <label class="radio-inline">
          <input class="form-check-input" type="radio" [value]="false" required
            formControlName="JobOwnerUsesSupport">No</label>
      </phx-form-control>
      
      <form [formGroup]="jobOwnerFormGroup">
        <phx-form-control [control]="jobOwnerFormGroup.controls.UserProfileIdSales" labelText="Job Owner"
          [editable]="readOnlyStorage.IsCommissionsEditable && checkPtFiledAccessibility('WorkOrderVersion.JobOwner','UserProfileIdSales')"
          viewModeText="{{jobOwnerFormGroup.controls.UserProfileIdSales.value | lookup: html.lists.JobOwners:'Id':'Contact.FullName'}}">
          <app-phx-select-box [formControl]="jobOwnerFormGroup.controls.UserProfileIdSales"
            [items]="html.lists.JobOwners" textField="Contact.FullName" valueField="Id">
          </app-phx-select-box>
        </phx-form-control>
      </form>

      <ng-container *ngIf="formGroup.controls.JobOwnerUsesSupport.value">
        <div class="form-group" *ngIf="supportingJobOwnerFormArray?.length === 0">
          <label class="col-lg-4 control-label label-to-right small-label">Support</label>
          <div class="col-lg-8" *ngIf="readOnlyStorage.IsCommissionsEditable">
            <div class="pull-right">
              <button type="button" class="btn btn-sm btn-default btn-material" (click)="addSupportingJobOwner()">
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
        </div>
        <ng-container formArrayName="SupportingJobOwners"
          *ngFor="let item of supportingJobOwnerFormArray?.controls; let i = index;">
          <phx-form-control [control]="formGroup.get('SupportingJobOwners')" labelText="{{i === 0 ? 'Support' : ''}}"
            [editable]="readOnlyStorage.IsCommissionsEditable && checkPtFiledAccessibility('WorkOrderVersion.SupportingJobOwners','UserProfileIdSales')"
            viewModeText="{{ item.get('UserProfileIdSales').value | lookup: html.lists.SupportJobOwners:'Id':'Contact.FullName'}}">
            <div class="col-md-10 padding-0">
              <app-phx-select-box [formControl]="item.get('UserProfileIdSales')"
                [items]="html.lists.SupportJobOwners | filterSelectChoicesByUsage: formGroup.get('SupportingJobOwners').value:item.value:'UserProfileIdSales':'Id': 'Contact.FullName'"
                textField="Contact.FullName" valueField="Id">
              </app-phx-select-box>
            </div>
            <div class="col-xs-1 pull-right padding-0">
              <button type="button" class="btn btn-sm btn-default btn-material supp-del pull-right"
                (click)="deleteSupportingJobOwner(i)">
                <i class="material-icons">delete</i>
              </button>
            </div>

          </phx-form-control>

          <div class="form-group">
            <div class="col-lg-2 pull-right" *ngIf="readOnlyStorage.IsCommissionsEditable">
              <button type="button" class="btn btn-sm btn-default btn-material supp-add pull-right"
                *ngIf="i === supportingJobOwnerFormArray?.length - 1" (click)="addSupportingJobOwner()"
                style="margin-top: 5px;">
                <i class="material-icons">add</i>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="workOrder && formGroup && (workOrder.WorkOrderVersion.LineOfBusinessId === phxConstants.LineOfBusiness.Regular || workOrder.WorkOrderVersion.LineOfBusinessId === phxConstants.LineOfBusiness.SubVendorPlacement || workOrder.WorkOrderVersion.LineOfBusinessId === phxConstants.LineOfBusiness.DirectSourcing)">
      <div class="form-group"
        *ngIf="!formGroup.get('Recruiters').value || recruiterFormArray?.length===0 && html.isRecruitersAllowed">
        <label class="col-lg-4 control-label label-to-right">Recruiter(s)</label>
        <div class="col-lg-8">
          <div class="fieldViewMode form-control-static label-to-left">No Recruiter on this Work Order</div>
          <div class="pull-right">
            <button type="button" class="btn btn-sm btn-default btn-material"
              *ngIf="readOnlyStorage.IsCommissionsEditable" (click)="addRecruiter()">
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </div>

      <ng-container formArrayName="Recruiters"
        *ngFor="let item of recruiterFormArray?.controls; let i = index;">
        <phx-form-control [control]="formGroup.get('Recruiters')" labelText="{{i === 0 ? 'Recruiter(s)' : ''}}"
          [editable]="readOnlyStorage.IsCommissionsEditable && checkPtFiledAccessibility('WorkOrderVersion.Recruiters','UserProfileIdSales')"
          viewModeText="{{item.get('UserProfileIdSales').value | lookup: html.lists.Recruiters:'Id':'Contact.FullName'}}">
          <div class="col-md-10 padding-0">
            <app-phx-select-box [formControl]="item.get('UserProfileIdSales')"
              [items]="html.lists.Recruiters | filterSelectChoicesByUsage: formGroup.get('Recruiters').value:item.value:'UserProfileIdSales':'Id': 'Contact.FullName'"
              textField="Contact.FullName" valueField="Id">
            </app-phx-select-box>
          </div>
          <div class="col-xs-1 pull-right padding-0">
            <button type="button" class="btn btn-sm btn-default btn-material supp-del pull-right"
              (click)="deleteRecruiter(i)">
              <i class="material-icons">delete</i>
            </button>
          </div>
        </phx-form-control>

        <div class="form-group">
          <div class="col-lg-2 pull-right" *ngIf="readOnlyStorage.IsCommissionsEditable">
            <button type="button" class="btn btn-sm btn-default btn-material supp-add pull-right"
              *ngIf="i === recruiterFormArray?.length - 1" (click)="addRecruiter()" style="margin-top: 5px;">
              <i class="material-icons">add</i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="formGroup">
      <ng-container *ngIf="commissionFormArray?.length > 0 && canViewCommissionRates()">
        <table class="table">
          <thead>
            <tr>
              <th>
                <div class="row">
                  <span class="col-lg-4 text-right">Name&nbsp;&nbsp;</span>
                  <span class="col-lg-8">Commission Description</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody formArrayName="WorkOrderVersionCommissions">
            <ng-container *ngFor="let commission of commissionFormArray?.controls; let i = index;">
              <tr app-workorder-commission-row [inputFormGroup]="commission" [readOnlyStorage]="readOnlyStorage"
                [rowIndex]="i">
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div class="text-right" *ngIf="html.showApproveImpactButton">
          <button class="btn btn-primary btn-approve-impact" type="button" (click)="showChangeImpact(false)"
            [disabled]="!workOrder.WorkOrderVersion.IsWovCommissionChanged || !formGroup.valid || !workOrder.WorkOrderVersion.EffectiveDate">Approve
            Impact</button>
        </div>
        <div class="text-right" *ngIf="html.showImpactApprovedBtn">
          <button class="btn btn-default btn-approve-impact" type="button" (click)="showChangeImpact(true)"><i
              class="material-icons check-mark-icon">check_circle</i>Impact Approved</button>
        </div>
      </ng-container>
    </ng-container>
  </form>
</app-phx-panel>
  

<app-commission-change-impact #CommissionChangeImpact [buttons]="commissionChangeImpactBtns">
</app-commission-change-impact>

<app-workorder-workflow #workOrderWorkFlow></app-workorder-workflow>
