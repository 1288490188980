import { Component, OnInit, Input } from '@angular/core';
import { IFooter, IReadOnlyStorage, IWorkOrder } from '../../models';
import { PhxConstants } from 'src/app/common';
import { FormGroup } from 'src/app/common/ngx-strongly-typed-forms/model';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';
import { FooterFormService } from '../../services';
import { AuthService } from 'src/app/common/services/auth.service';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';

@Component({
  selector: 'app-workorder-footer-effective-date',
  templateUrl: './workorder-footer-effective-date.component.html',
  styleUrls: ['./workorder-footer-effective-date.component.less']
})
export class WorkorderFooterEffectiveDateComponent extends BaseComponentOnDestroy implements OnInit {

  formGroup: FormGroup<IFooter>;

  @Input() workOrder: IWorkOrder;
  @Input() effectiveToDate: string;

  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;

  html: {
    phxConstants: typeof PhxConstants;
  } = {
      phxConstants: PhxConstants
    };

  constructor(
    private footerFormService: FooterFormService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.footerFormService.formGroup;
  }

  public checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

}
