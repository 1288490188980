<div class="panel-internal">
  <div class="title">
    <h4>Rates </h4>
  </div>
</div>
<div>
  <div *ngFor="let paymentRate of paymentRatesFormArray.controls; let i = index; trackBy: trackByFn">
    <app-workorder-payment-rate
            [inputFormGroup]="paymentRate"
            [readOnlyStorage]="readOnlyStorage"
            [paymentInfoIndex]="paymentInfoIndex"
            [currencyId]="currencyId"
    ></app-workorder-payment-rate>
  </div>
</div>
