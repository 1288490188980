import { Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { filter, find } from 'lodash';

import { CodeValueGroups, CodeValueService, PhxConstants } from '../../../common';
import { FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IReadOnlyStorage, IWorkOrder, IWorkplaceSafetyInsurance } from '../../models';
import { IWcbSubdivisionVersionDetailWorkerTypeDefault } from '../../../payroll/model';
import { WorkOrderFormService, WorkorderService, WorkplaceSafetyInsuranceFormService } from '../../services';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { AuthService } from '../../../common/services/auth.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { ControlFieldAccessibility, TFConstant } from '../../control-field-accessibility';

@Component({
  selector: 'app-workorder-tab-earnings-and-deduction-safety-insurance',
  templateUrl: './workorder-tab-earnings-and-deduction-safety-insurance.component.html'
})
export class WorkorderTabEarningsAndDeductionSafetyInsuranceComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  phxConstants = PhxConstants;
  formGroup: FormGroup<IWorkplaceSafetyInsurance>;
  workOrder: IWorkOrder;
  codeValueGroups = CodeValueGroups;
  html: {
    commonLists: {
      workOrderWorkLocations: Array<any>;
      OrganizationInternalList: Array<any>;
      wcbCodeList: Array<IWcbSubdivisionVersionDetailWorkerTypeDefault>;
    };
  } = {
    commonLists: {
      workOrderWorkLocations: [],
      OrganizationInternalList: [],
      wcbCodeList: []
    }
  };

  constructor(
    private workOrderFormService: WorkOrderFormService,
    private workorderService: WorkorderService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
    private codeValueService: CodeValueService,
    private commonListsObservableService: CommonListsObservableService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.workplaceSafetyInsuranceFormService.formGroup;
    this.setupFormGroupListeners();

    this.html.commonLists.workOrderWorkLocations = this.codeValueService.getCodeValues(this.codeValueGroups.Worksite, true);
    this.getInternalOrganization();
    this.workOrderFormService
      .workOrder$.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((workOrder: IWorkOrder) => {
        if (workOrder) {
          this.workOrder = workOrder;
          const { OrganizationIdInternal, WorkOrderVersion: { WorksiteId, WorkerLocationId } } = workOrder;

          if (OrganizationIdInternal) {
            if (WorkerLocationId) {
              this.getWCBCodeList(
                WorkerLocationId,
                OrganizationIdInternal
              );
            } else if (WorksiteId) {
              this.getWCBCodeList(
                this.workorderService.getSubdivisionIdByWorksiteId(WorksiteId),
                OrganizationIdInternal
              );
            }
          }
        }
      });
  }

  checkPtFiledAccessibility(modelPrefix: string, fieldName: string): TFConstant {
    return ControlFieldAccessibility.ptFieldViewEventOnChangeStatusId(modelPrefix, fieldName, this.authService);
  }

  private setupFormGroupListeners() {
    this.workplaceSafetyInsuranceFormService.workerCompensationIdChanges$
      .pipe(distinctUntilChanged(),
        takeUntil(this.isDestroyed$))
      .subscribe((workerCompensationId) => {
        if (workerCompensationId == null || this.html.commonLists.wcbCodeList.length === 0) {
          return;
        }

        const currentWCBDetail = find(this.html.commonLists.wcbCodeList, o => {
          return o.WorkerCompensationId === workerCompensationId;
        });
        const wcbSubdivisionDetailWorkerTypes = filter(currentWCBDetail.WcbSubdivisionDetailWorkerTypeDefault, o => {
          return o.ProfileTypeIdWorker === this.workOrder.workerProfileTypeId;
        });
        this.workplaceSafetyInsuranceFormService.updateWCBIsApplied(wcbSubdivisionDetailWorkerTypes.length > 0);
      });
  }

  private getInternalOrganization() {
    this.commonListsObservableService.listOrganizationInternals$()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((response) => {
        const list = response ? response.map(item => item.Data) : [];
        if (list.length > 0) {
          this.html.commonLists.OrganizationInternalList = list.map((value: any) => {
            return {
              Code: value.Code,
              IsTest: value.IsTest,
              OrganizationIdInternal: value.Id,
              Name: value.DisplayName,
              Id: value.Id
            };
          });
        }
      });
  }

  private getWCBCodeList(subdivisionId: number, organizationIdInternal: number): void {
    this.workorderService.getWCBCodesBySubdivisionId(subdivisionId, organizationIdInternal).subscribe((response: any) => {
      this.html.commonLists.wcbCodeList = response?.Items?.map(item => {
        return {
          ...item,
          DisplayText: item.WorkerCompensation.Code + ' - ' + item.WorkerCompensation.Name
        };
      }) ?? [];
    });
  }
}
