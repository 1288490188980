import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPaymentPartiesRateDetail, IPaymentPartyInfoes, IPaymentRatesDetail, IReadOnlyStorage, OvertimeDetails } from '../../models';
import { ToTypedFormGroup } from 'src/app/common/model/to-typed-form-group.model';
import { PartyPaymentInfoFormService, WorkOrderFormService, WorkorderService } from '../../services';
import { FormArray, FormGroup } from '@angular/forms';
import { FormGroup as LegacyFormGroup } from 'src/app/common/ngx-strongly-typed-forms';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { combineLatest } from 'rxjs';

type PhxFormGroup<T> = FormGroup<ToTypedFormGroup<T>> | LegacyFormGroup<T>;
type PhxFormArray<T> = FormArray<FormGroup<ToTypedFormGroup<T>>> | LegacyFormGroup<T[]>;

@Component({
  selector: 'app-workorder-billing-rate-overtime-config',
  templateUrl: './workorder-billing-rate-overtime-config.component.html',
  styleUrls: ['./workorder-billing-rate-overtime-config.component.less']
})
export class WorkorderBillingRateOvertimeConfigComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() readOnlyStorage: IReadOnlyStorage;
  @Output() overtimeExemption = new EventEmitter<boolean>();

  haveHourRateUnit = false;

  formGroup: PhxFormGroup<IPaymentPartyInfoes>;
  otFormGroup: PhxFormGroup<IPaymentRatesDetail>;
  overtimeDetailsFormGroup: PhxFormGroup<OvertimeDetails>;
  autoCalculateOvertimeIsActiveForWorkorder: boolean;

  constructor(
    private partyBillingInfoFormService: PartyPaymentInfoFormService,
    private workOrderFormService: WorkOrderFormService,
    private workorderService: WorkorderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.autoCalculateOvertimeIsActiveForWorkorder = this.workorderService.autoCalculateOvertimeIsActiveForWorkorder;
    this.formGroup = this.partyBillingInfoFormService.formGroup as PhxFormGroup<IPaymentPartyInfoes>;
    const prd = this.formGroup.controls.PaymentPartiesRateDetails as PhxFormArray<IPaymentPartiesRateDetail>;
    const prdForm = prd.controls[0] as PhxFormGroup<IPaymentPartiesRateDetail>;
    this.otFormGroup = prdForm.controls.PaymentRatesDetail as PhxFormGroup<IPaymentRatesDetail>;
    this.overtimeDetailsFormGroup = this.partyBillingInfoFormService.formGroup.controls.OverTimeDetails as PhxFormGroup<OvertimeDetails>;

    this.otFormGroup.controls.PaymentRates.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
    ).subscribe(billingRates => {
      /** NOTE: auto calculate OT is only relevant if primary rate unit is hours  */
      const haveHourRateUnit = this.partyBillingInfoFormService.isOvertimeEligibleRates(billingRates);
      if (!this.haveHourRateUnit && haveHourRateUnit && this.readOnlyStorage.IsEditable) {
        this.overtimeDetailsFormGroup.patchValue({
          IsOvertimeExempt: false
        });
      }
      this.haveHourRateUnit = haveHourRateUnit;
    });

    combineLatest([
      (this.partyBillingInfoFormService.formGroup.controls.OverTimeDetails as unknown as PhxFormGroup<OvertimeDetails>).controls.IsOvertimeExempt.valueChanges,
      this.otFormGroup.controls.PaymentRates.valueChanges
    ])
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(() => this.readOnlyStorage.IsEditable),
        tap(([isOvertimeExempt, billingRates]) => {
          const newAutoCalculateOvertimeValue = !isOvertimeExempt // If the work order is not exempt from overtime
            && this.partyBillingInfoFormService.isOvertimeEligibleProfile // and the worker profile is eligible
            && !this.overtimeDetailsFormGroup.controls.AutoCalculateOvertime.disabled // if overtime calculation is not disabled
            && this.autoCalculateOvertimeIsActiveForWorkorder // if the autocalculation of overtime is enabled for the work order
            && this.partyBillingInfoFormService.isOvertimeEligibleRates(billingRates); // if there is applicable rate for the overtime

          /** NOTE: autocalculate OT is only relevant if work order has OT rates and the worker is temp or w2, null otherwise */
          this.overtimeDetailsFormGroup.controls.AutoCalculateOvertime.patchValue(newAutoCalculateOvertimeValue || null);

          if(isOvertimeExempt) {
            this.overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.patchValue(null);
          }
        })
      )
      .subscribe(([isOvertimeExempt]) => {
        this.overtimeExemption.emit(isOvertimeExempt);
      });

    this.workOrderFormService.workOrder$.pipe(this.takeUntilDestroyed).subscribe(wo => {
      this.haveHourRateUnit = this.partyBillingInfoFormService.isOvertimeEligibleRates(wo.WorkOrderVersion.PaymentInfoes[0].PaymentRates);
    });
  }
}
