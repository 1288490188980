<div class="content-group form-horizontal">
    <div class="content-left">
        <div class="panel-internal">
            <div class="col-lg-12">
                <h4>Attributions</h4>
            </div>
            <!-- NOTE: manager details header -->
            <div class="no-padding-h">
                <div class="col-lg-12">
                    <div class="col-lg-4 manager-header-label">National Account Manager:</div>
                    <div class="col-lg-8">{{nationalAccountManagerDisplay}}</div>
                </div>
                <div class="col-lg-12">
                    <div class="col-lg-4 manager-header-label">Branch Manager:</div>
                    <div class="col-lg-8">{{branchManagerDisplay}}</div>
                </div>
            </div>
            <!-- NOTE: if we have a work order line of business -->
            <ng-container *ngIf="haveWoLob">
                <!-- NOTE: LOB attribution splits -->
                <div class="col-lg-12 padding-20-v-t" *ngIf="!!currentLineOfBusinessSplit">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Line of Business</th>
                                <th>Sales Attribution</th>
                                <th>Recruiter Attribution</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{currentLineOfBusinessSplit.LineOfBusinessCode | lookup:
                                    lobCodeValues:'code':'description'}}
                                </td>
                                <td>{{salesAttributionControl.value ? salesAttributionControl.value + '%'
                                    : '-' }}</td>
                                <td>{{recruiterAttributionControl.value ?
                                    recruiterAttributionControl.value + '%' : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-12 padding-20-v-t">
                    <!-- NOTE: attribution share sales split options -->
                    <ng-container *ngIf="readOnlyStorage.IsEditable && salesSharesFormArray.value?.length > 1">
                        <div [formGroup]="splitOptionsForm" class="form-group col-lg-8 pull-right margin-20-v-t">
                            <phx-form-control [control]="salesSplitOptionControl" labelText="Split Options"
                                [editable]="true">
                                <app-phx-select-box [formControl]="salesSplitOptionControl"
                                    [items]="filteredSalesSplitOptions" textField="Text" valueField="Text">
                                </app-phx-select-box>
                            </phx-form-control>
                        </div>
                    </ng-container>
                    <!-- NOTE: sales attribution shares table -->
                    <ng-container *ngIf="salesSharesFormArray.value.length">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="width-20-p">Sales #</th>
                                    <th class="width-30-p">Name</th>
                                    <th class="width-30-p">Attribution Share <i class="material-icons lock-icon"
                                            *ngIf="salesSplitOptionControl.value && readOnlyStorage.IsEditable">lock</i>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let profile of salesSharesFormArray.value; let idx = index">
                                    <td>{{profile.UserProfileId}}</td>
                                    <td>{{profile.UserProfileId |
                                        lookup:profileListLookup:'userProfileId':'displayName'}}
                                    </td>
                                    <td>
                                        <span *ngIf="profile.AttributionShare !== null; else noSalesShare">
                                            {{profile.AttributionShare }}%
                                        </span>
                                        <ng-template #noSalesShare>
                                            -
                                        </ng-template>
                                    </td>
                                    <td class="actions-column">
                                        <ng-container *ngIf="readOnlyStorage.IsEditable">
                                            <ng-container *ngIf="salesSharesFormArray.value.length > 1">
                                                <button type="button" class="btn btn-default btn-material"
                                                    (click)="onClickMoveUp(idx, salesSharesFormArray)" *ngIf="idx > 0">
                                                    <span class="material-icons">
                                                        arrow_upward
                                                    </span>
                                                </button>
                                                <button type="button" class="btn btn-default btn-material"
                                                    (click)="onClickMoveDown(idx, salesSharesFormArray)"
                                                    *ngIf="idx < salesSharesFormArray.value.length -1">
                                                    <span class="material-icons">
                                                        arrow_downward
                                                    </span>
                                                </button>
                                            </ng-container>
                                            <button type="button" class="btn btn-default btn-material"
                                                (click)="onClickRemoveSales(idx)">
                                                <i class="material-icons">delete</i>
                                            </button>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <!-- NOTE: add new sales button - max 3 sales people allowed-->
                    <div *ngIf="readOnlyStorage.IsEditable">
                        <button class="btn btn-default pull-right" (click)="onClickOpenAddShareModal(SALES)"
                            [disabled]="salesSharesFormArray.length > 2">Add New
                            Sales</button>
                    </div>
                </div>
                <div class="col-lg-12">
                    <hr />
                    <!-- NOTE: attribution share recruiter split options -->
                    <ng-container *ngIf="readOnlyStorage.IsEditable && recruiterSharesFormArray.value?.length > 1">
                        <div [formGroup]="splitOptionsForm" class="form-group col-lg-8 pull-right">
                            <phx-form-control [control]="recruiterSplitOptionControl" labelText="Split Options"
                                [editable]="true">
                                <app-phx-select-box [formControl]="recruiterSplitOptionControl"
                                    [items]="filteredRecruiterSplitOptions" textField="Text" valueField="Text">
                                </app-phx-select-box>
                            </phx-form-control>
                        </div>
                    </ng-container>
                    <!-- NOTE: recruiter attribution shares table -->
                    <ng-container *ngIf="recruiterSharesFormArray.value.length">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="width-20-p">Recruiter #</th>
                                    <th class="width-30-p">Name</th>
                                    <th class="width-30-p">Attribution Share <i class="material-icons lock-icon"
                                            *ngIf="recruiterSplitOptionControl.value && readOnlyStorage.IsEditable">lock</i>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let profile of recruiterSharesFormArray.value; let idx = index">
                                    <td>{{profile.UserProfileId}}</td>
                                    <td>{{profile.UserProfileId |
                                        lookup:profileListLookup:'userProfileId':'displayName'}}
                                    <td>
                                        <span *ngIf="profile.AttributionShare !== null; else noRecruiterShare">
                                            {{profile.AttributionShare }}%
                                        </span>
                                        <ng-template #noRecruiterShare>
                                            -
                                        </ng-template>
                                    </td>
                                    <td class="actions-column">
                                        <ng-container *ngIf="readOnlyStorage.IsEditable">
                                            <ng-container *ngIf="recruiterSharesFormArray.length > 1">
                                                <button type="button" class="btn btn-default btn-material"
                                                    (click)="onClickMoveDown(idx, recruiterSharesFormArray)"
                                                    *ngIf="idx === 0">
                                                    <span class="material-icons">
                                                        arrow_downward
                                                    </span>
                                                </button>
                                                <button type="button" class="btn btn-default btn-material"
                                                    (click)="onClickMoveUp(idx, recruiterSharesFormArray)"
                                                    *ngIf="idx === 1">
                                                    <span class="material-icons">
                                                        arrow_upward
                                                    </span>
                                                </button>
                                            </ng-container>
                                            <button type="button" class="btn btn-default btn-material pull-right"
                                                (click)="onClickRemoveRecruiter(idx)">
                                                <i class="material-icons">delete</i>
                                            </button>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <!-- NOTE: add new recruiter button - max 2 recruiters allowed -->
                    <div *ngIf="readOnlyStorage.IsEditable">
                        <button class="btn btn-default pull-right" (click)="onClickOpenAddShareModal(RECRUITER)"
                            [disabled]="recruiterSharesFormArray.length > 1">Add
                            New Recruiter</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="content-right">
        <div class="panel-internal">
        </div>
    </div>
</div>
<!-- NOTE: modal for adding new sales or recruiter profile -->
<app-phx-modal [title]="modalTitle" #phxModalComponent>
    <div style="line-height: 21px; color: #5E6E90;">
        {{modalMessage}}
    </div>
    <div [formGroup]="profileListForm" class="form-group  margin-10-v-t">
        <phx-form-control [control]="profileListControl" [labelText]="modalLabel" [editable]="true"
            [layoutType]="stackedLayout">
            <app-phx-select-box [formControl]="profileListControl"
                [items]="(profileListForm.value.profileType === SALES ? salesProfiles : recruiterProfiles) | filterSelectChoicesByUsage: profileListForm.value.profileType === SALES ? salesSharesFormArray.value : recruiterSharesFormArray.value : profileListControl.value: 'UserProfileId': 'Id' : 'DisplayName'"
                textField="DisplayText">
            </app-phx-select-box>
        </phx-form-control>
    </div>
    <div class="col-lg-12  margin-20-v-t">
        <div class="pull-right">
            <button class="btn btn-default margin-5-l" (click)="onClickModalCancel()">Cancel</button>
            <button class="btn btn-primary margin-5-l" (click)="onClickAddProfile()"
                [disabled]="!profileListForm.valid">Add</button>
        </div>
    </div>
</app-phx-modal>