<ng-container>
  <div class="message-container" *ngIf="!offboardingTasksByRole?.length">
    Currently, there are no offboarding tasks.
  </div>

  <div class="ck-accord" *ngIf="offboardingTasksByRole?.length">
    <div class="group-container" *ngFor="let group of offboardingTasksByRole">
      <app-phx-accordion
        [closeOthers]="false"
      >
        <app-phx-accordion-group
          #chk
          [(isOpen)]="group.isOpen"
          [showToggle]="false"
          class="accordian-group"
        >
          <div class="ck-accord-head" accordion-heading>
            <span class="ck-title">
              {{ group.title}}
            </span>
            <span class="ck-count">({{group.completeCount}}/{{group.tasks?.length}})</span>
            <i class="material-icons header-glyphicon" *ngIf="!chk.isOpen">keyboard_arrow_down</i>
            <i class="material-icons header-glyphicon" *ngIf="chk.isOpen">keyboard_arrow_up</i>
          </div>
          <div class="ck-accord-body">
            <div *ngFor="let task of group.tasks">
              <div class="checkbox chk-box" [class.disabled]="isTaskDisabled(task)"
                [class.clickable]="!disableClickTask(task)">
                <label>
                  <input type="checkbox" [checked]="task.completed" [class.disabled]="isTaskDisabled(task)"
                    [disabled]="disableClickTask(task)" [class.exemption]="task.exemption"
                    [class.crossed]="task.exemption" (click)="onCheckboxClicked(task, $event)" />
                  <i class="checkmark material-icons"></i>
                  <span>{{task.OffboardingTaskId |
                    CodeValue:codeValueGroups.WorkOrderOffboardingTask}}</span>
                </label>
                <div class="btn-group elipsis pull-right" *ngIf="isEnableExemption(task)">
                  <button class="btn btn-xs dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" type="button">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="dropdown-menu bordered">
                    <li *ngIf="task.pending" (click)="openExemptTaskModal(task)" class="action-link">
                      <a> Exempt Task </a>
                    </li>
                    <li *ngIf="task.exemption" (click)="recallExemptionTask(task)" class="action-link">
                      <a> Recall Exemption </a>
                    </li>
                  </ul>
                </div>
                <div class="date-container">
                  <span *ngIf="task.completed || task.exemption" class="detail">{{task.LastStateHistory.CreatedDatetime
                    |
                    date:phxConstants.DateFormat.EEE_MMM_DD}} by {{task.LastStateHistory.CreatedByFirstName}}
                    {{task.LastStateHistory.CreatedByFirstName.substr(0,1)}}</span>
                  <span *ngIf="task.pending && task.Config.DueDate" class="duedate"
                    [class.today-or-past]="task.Config.NoOfDaysDueFromToday <= 0">
                    {{task.Config.NoOfDaysDueFromToday === 0 ? 'Due Today': 'Due '+(task.Config.DueDate |
                    date:phxConstants.DateFormat.EEE_MMM_DD)}}</span>
                </div>
              </div>
            </div>
          </div>
        </app-phx-accordion-group>
      </app-phx-accordion>
    </div>
  </div>
</ng-container>


<app-workorder-offboarding-compliancetask-modal #modalComplianceTask [contactFullName]="contactFullName"
  [buttons]="complianceTaskModalbuttons">
</app-workorder-offboarding-compliancetask-modal>
<app-workorder-offboarding-exempttask-modal #modalExemptTask>
</app-workorder-offboarding-exempttask-modal>
