import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CodeValueGroups, PhxConstants } from 'src/app/common';

@Component({
  selector: 'app-workorder-purchaseorderline-tab-workorders',
  templateUrl: './workorder-purchaseorderline-tab-workorders.component.html',
  styleUrls: ['./workorder-purchaseorderline-tab-workorders.component.less']
})
export class WorkorderPurchaseorderlineTabWorkordersComponent {
  @Input() purchaseorderForm;
  @Input() lineNew;
  @Input() workRateUnitTypes;
  @Input() workOderPurchaseOrderLineId;
  @Output() CommitChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() Navigate: EventEmitter<any> = new EventEmitter<any>();

  readonly phxConstants = PhxConstants;
  readonly codeValueGroups = CodeValueGroups;

  onCommitChange(CalcLine) {
    this.CommitChange.emit(CalcLine);
  }

  OnNavigate(item) {
    this.Navigate.emit(item);
  }
}
