import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { of as observableOf } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { PhxConstants } from '@common';
import { PhxNoteHeaderComponent } from '@common/components/phx-note-header/phx-note-header.component';
import { BaseComponentOnDestroy } from '@common/epics/base-component-on-destroy';
import { NavigationBarItem, StateHistoryCurrentStatus } from '@common/model';

import { IWorkOrder, IWorkOrderResult, IWorkorderRouterState } from '../../models';
import { WorkOrderDataService } from '../../services';

@Component({
  selector: 'app-workorder-tab-activity',
  templateUrl: './workorder-tab-activity.component.html',
  styleUrls: ['./workorder-tab-activity.component.less']
})
export class WorkorderTabActivityComponent extends BaseComponentOnDestroy implements OnInit {
  public routerState: IWorkorderRouterState = null;
  public routerParams: any;

  @ViewChild(PhxNoteHeaderComponent) notesHeader: PhxNoteHeaderComponent;
  workorder: IWorkOrder;
  html: {
    navigationBarContent: Array<NavigationBarItem>;
    phxConstants: typeof PhxConstants;
    entityTypeId: number;
    entityId: number;
    changeHistoryBlackList: any[];
    cultureId: number;
    stateHistoryCurrentStatus: Array<StateHistoryCurrentStatus>;
  } = {
      navigationBarContent: [],
      phxConstants: PhxConstants,
      entityTypeId: 0,
      entityId: 0,
      changeHistoryBlackList: [],
      cultureId: 48,
      stateHistoryCurrentStatus: []
    };
  showTemplate = false;

  constructor(
    private workOrderDataService: WorkOrderDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          params = { ...params, location: this.router.url };
          if (params.location.includes(PhxConstants.WorkorderNavigationName.documents)) {
            this.setRouterState(params, PhxConstants.WorkorderNavigationName.documents);
          } else if (params.location.includes(PhxConstants.WorkorderNavigationName.history)) {
            this.setRouterState(params, PhxConstants.WorkorderNavigationName.history);
          } else if (params.location.includes(PhxConstants.WorkorderNavigationName.transaction)) {
            this.setRouterState(params, PhxConstants.WorkorderNavigationName.transaction);
          } else if (params.location.includes(PhxConstants.WorkorderNavigationName.workflow)) {
            this.setRouterState(params, PhxConstants.WorkorderNavigationName.workflow);
          }

          this.routerParams = params;
          return this.routerParams.versionId ? this.workOrderDataService.loadWorkOrder(params, this.showTemplate, this.isDestroyed$) : observableOf(null);
        }),
        takeUntil(this.isDestroyed$)
      )
      .subscribe((result: IWorkOrderResult) => {
        if (result.WorkOrder) {
          this.workorder = result.WorkOrder;
          this.html.navigationBarContent = this.navigationBarContentSetup();
          this.recalcLocalProperties();
          this.setStateHistoryCurrentStatus(result.WorkOrder);
        }
      });
  }

  setStateHistoryCurrentStatus(workorder: IWorkOrder) {
    const woHeader = workorder.WorkOrderHeaders.find(wo => wo.Id === workorder.WorkOrderId);
    this.html.stateHistoryCurrentStatus = woHeader?.WorkOrderVersionHeaders
      ? woHeader.WorkOrderVersionHeaders.map(wovHeader => {
        return {
          versionEntityId: wovHeader.Id,
          currentStatusId: wovHeader.StatusId
        } as StateHistoryCurrentStatus;
      })
      : [];
  }

  setRouterState(params: Params, WorkorderNavigationName: string) {
    this.routerState = {
      Id: params.workorderId,
      routerPath: WorkorderNavigationName,
      url: params.location
    };

    this.html.entityTypeId = this.html.phxConstants.EntityType.WorkOrder;
    this.html.entityId = this.routerState.Id;
  }

  navigationBarContentSetup(): Array<NavigationBarItem> {
    const path = `/next/workorder/${this.routerParams.assignmentId}/${this.routerParams.workorderId}/${this.routerParams.versionId}/`;
    return [
      {
        Id: 1,
        IsDefault: false,
        IsHidden: false,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.history,
        Path: `${path}${PhxConstants.WorkorderNavigationName.history}`,
        DisplayText: 'Change History'
      },
      {
        Id: 2,
        IsDefault: false,
        IsHidden: false,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.transaction,
        Path: `${path}${PhxConstants.WorkorderNavigationName.transaction}`,
        DisplayText: 'Transaction'
      },
      {
        Id: 3,
        IsDefault: false,
        IsHidden: false,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.documents,
        Path: path + PhxConstants.WorkorderNavigationName.documents,
        DisplayText: 'Additional Documents'
      },
      {
        Id: 4,
        IsDefault: false,
        IsHidden: false,
        Valid: true,
        Name: PhxConstants.WorkorderNavigationName.workflow,
        Path: path + PhxConstants.WorkorderNavigationName.workflow,
        DisplayText: 'Workflow'
      }
    ];
  }

  recalcLocalProperties() {
    this.html.changeHistoryBlackList = [
      { TableSchemaName: '', TableName: '', ColumnName: 'IsDraft' },
      { TableSchemaName: '', TableName: '', ColumnName: 'IsDeleted' },
      { TableSchemaName: '', TableName: '', ColumnName: 'SourceId' },

      { TableSchemaName: '', TableName: '', ColumnName: 'Id' },
      { TableSchemaName: '', TableName: '', ColumnName: 'Metadata' },

      { TableSchemaName: '', TableName: '', ColumnName: 'LastModifiedByProfileId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'LastModifiedDatetime' },
      { TableSchemaName: '', TableName: '', ColumnName: 'CreatedByProfileId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'CreatedDatetime' },

      { TableSchemaName: '', TableName: '', ColumnName: 'AssignmentId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'WorkOrderId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'WorkOrderVersionId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'PaymentInfoId' },
      { TableSchemaName: '', TableName: '', ColumnName: 'BillingInfoId' },

      { TableSchemaName: 'workorder', TableName: 'Assignment', ColumnName: 'StatusId' },

      { TableSchemaName: 'workorder', TableName: 'WorkOrder', ColumnName: 'WorkOrderVersion' },

      { TableSchemaName: 'workorder', TableName: 'WorkOrderVersion', ColumnName: 'StatusId' },
      { TableSchemaName: 'workorder', TableName: 'WorkOrderVersion', ColumnName: 'WorkOrderCreationReasonId' },
      { TableSchemaName: 'workorder', TableName: 'WorkOrderVersion', ColumnName: 'VersionNumber' },
      { TableSchemaName: 'workorder', TableName: 'WorkOrderVersion', ColumnName: 'WorkOrderStartDateState' },
      { TableSchemaName: 'workorder', TableName: 'WorkOrderVersion', ColumnName: 'WorkOrderEndDateState' },

      { TableSchemaName: 'po', TableName: 'WorkOrderPurchaseOrderLine', ColumnName: 'PurchaseOrderLineId' },
      { TableSchemaName: 'po', TableName: 'WorkOrderPurchaseOrderLine', ColumnName: 'StatusId' },
      { TableSchemaName: 'org', TableName: 'ClientBasedEntityCustomFieldValue', ColumnName: 'EntityTypeId' },
      { TableSchemaName: 'org', TableName: 'ClientBasedEntityCustomFieldValue', ColumnName: 'EntityId' }
    ];
  }
}
