import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import moment from 'moment';

import { EntityList } from './../model/entity-list';
import { ApiService } from './api.service';
import { CodeValueService } from './code-value.service';
import { ReportShareService } from './report-share.service';
import { CodeValue } from '../model/code-value';
import { PhxDataTableState, PhxDataTableUserProfile } from '../model/index';

@Injectable()
export class PhxDataTableService {
  saveCommandName = 'UserProfileSearchStateSave';
  deleteCommandName = 'UserProfileSearchStateDelete';
  showLoader = false;
  codeReportTypes: CodeValue[] = [];

  constructor(private apiService: ApiService,
              private reportShareService: ReportShareService,
              codeValueService: CodeValueService) {
    this.codeReportTypes = codeValueService.getCodeValues('report.CodeReportType', true);
  }

  public replaceSpecialCharacters(value: string): string {
    value = value.replace(/%/g, '%25');
    // replace the single quotes
    // eslint-disable-next-line @typescript-eslint/quotes
    value = value.replace(/'/g, '\'\'');
    value = value.replace(/\+/g, '%2B');
    value = value.replace(/\//g, '%2F');
    value = value.replace(/\?/g, '%3F');
    value = value.replace(/#/g, '%23');
    value = value.replace(/&/g, '%26');
    return value;
  }

  // TODO: Delete when old grids are obsolete
  public saveState(phxDataTableUserProfile: PhxDataTableUserProfile) {
    const payload = this.transformUserProfileSave(phxDataTableUserProfile);
    return this.apiService.command(this.saveCommandName, payload, this.showLoader);
  }

  // TODO: Delete when old grids are obsolete
  public removeState(selectedState) {
    return this.apiService.command(this.deleteCommandName, selectedState, this.showLoader);
  }

  // TODO: Delete when old grids are obsolete
  public getState(componentName: string, stateName: string): Promise<EntityList<PhxDataTableUserProfile>> {
    return new Promise((resolve, reject) => {
      this.apiService.queryWithPromise(`userProfileSearchState?$filter=ComponentName eq '${componentName}' and StateName eq '${this.replaceSpecialCharacters(stateName)}'`, this.showLoader)
        .then((response: any) => {
          resolve(this.parseUserProfileResponse(response));
        })
        .catch(ex => {
          reject(ex);
        });
    });
  }

  // TODO: Delete when old grids are obsolete
  public getStates(componentName): Promise<EntityList<PhxDataTableUserProfile>> {
    return new Promise((resolve, reject) => {
      this.apiService.queryWithPromise(`userProfileSearchState/getByComponentName/${componentName}?$orderby=StateName`, this.showLoader)
        .then((response: any) => {
          resolve(this.parseUserProfileResponse(response));
        })
        .catch(ex => {
          reject(ex);
        });
    });
  }

  ////////////////////////////////////////////////////
  // PhxAZSearchDataTable & ReportAzSearch Adapters //
  ////////////////////////////////////////////////////

  public getDetailedReportShareByIdAdapter(stateId: number): Promise<PhxDataTableUserProfile> {
    return lastValueFrom(this.reportShareService.getReportShareById(stateId).pipe(map((reportShareDetail => {
      if (!reportShareDetail) { return null; }
      return {
        Id: reportShareDetail.StateId,
        ComponentName: reportShareDetail.ComponentName,
        StateName: reportShareDetail.StateName,
        StateDescription: reportShareDetail.StateDescription,
        State: reportShareDetail.State,
        UserProfileId: reportShareDetail.OwnerProfileId,
        UserProfileFullName: reportShareDetail.OwnerFullName,
        IsPublic: reportShareDetail.IsPublic,
        ShareeUserIds: reportShareDetail.ShareeUserIds,
        ShareeTeamIds: reportShareDetail.ShareeTeamIds,
        ReportTypeId: reportShareDetail.ReportTypeId
      };
    }))));
  }

  public getReportShareByComponentNameAdapter(componentName): Promise<PhxDataTableUserProfile[]> {
    const reportTypeId = this.getReportTypeIdByComponentName(componentName);
    if (!reportTypeId) {
      return Promise.resolve([]);
    }

    return lastValueFrom(this.reportShareService.getAllReportsForCurrentUserByReportType(reportTypeId, this.showLoader)
      .pipe(map((reportData) => {
        if (!reportData) {
          return [];
        }

        const allStates: PhxDataTableUserProfile[] = [];
        [reportData.OwnedStates, reportData.SharedStates, reportData.CompanyStates].forEach(returnedStates => {
          returnedStates.forEach(state => {
            allStates.push({
              Id: state.StateId,
              ComponentName: state.ComponentName,
              StateName: state.StateName,
              UserProfileId: state.OwnerProfileId,
              UserProfileFullName: state.OwnerFullName,
              IsPublic: state.IsPublic,
              ShareeUserIds: state.ShareeUserIds || [],
              ShareeTeamIds: state.ShareeTeamIds || [],
              ReportTypeId: state.ReportTypeId
            });
          });
        });

        return allStates;
      })));
  }

  public deleteReportShareAdapter(stateId: number) {
    return lastValueFrom(this.reportShareService.deleteReportShare(stateId, this.showLoader));
  }

  public saveReportShareDetailAdapter(phxDataTableUserProfile: PhxDataTableUserProfile): Promise<PhxDataTableUserProfile> {
    return lastValueFrom(this.reportShareService.saveReportShareDetail({
      StateId: phxDataTableUserProfile.Id,
      ComponentName: phxDataTableUserProfile.ComponentName,
      IsPublic: phxDataTableUserProfile.IsPublic,
      ShareeTeamIds: phxDataTableUserProfile.ShareeTeamIds,
      ShareeUserIds: phxDataTableUserProfile.ShareeUserIds,
      StateName: (phxDataTableUserProfile.StateName || '').trim(),
      StateDescription: (phxDataTableUserProfile.StateDescription || '').trim(),
      State: phxDataTableUserProfile.State
    }, this.showLoader).pipe(map((res) => {
      if (!res) { return; }
      return {
        Id: res.StateId,
        ComponentName: res.ComponentName,
        StateName: res.StateName,
        StateDescription: res.StateDescription,
        State: res.State,
        UserProfileId: res.OwnerProfileId,
        UserProfileFullName: res.OwnerFullName,
        IsPublic: res.IsPublic,
        ShareeUserIds: res.ShareeUserIds,
        ShareeTeamIds: res.ShareeTeamIds,
        ReportTypeId: res.ReportTypeId
      };
    })));
  }

  private parseUserProfileResponse(userProfileResponse: EntityList<PhxDataTableUserProfile>): EntityList<PhxDataTableUserProfile> {
    for (const profile of userProfileResponse.Items) {
      profile.State = this.getStateObject(profile.State as string);
    }
    return userProfileResponse;
  }

  private getStateObject(stateString: string): PhxDataTableState {
    const state = JSON.parse(stateString) as PhxDataTableState;
    if (state != null) {
      for (const column of state.columns) {
        if (column.dataType === 'date' && column.filterValue) {
          if (typeof column.filterValue === 'string' || column.filterValue instanceof String) {
            column.filterValue = new Date(moment(column.filterValue.toString()).format());
          } else if (Array.isArray(column.filterValue)) {
            for (let i = 0; i < column.filterValue.length; i++) {
              column.filterValue[i] = new Date(moment(column.filterValue[i].toString()).format());
            }
          }
        }
      }
    }
    return state;
  }

  private getStringFromStateObject(state: PhxDataTableState): string {
    if (!state) {
      return null;
    }

    const stateCopy = JSON.parse(JSON.stringify(state));

    delete stateCopy.selectedRowKeys;
    for (const column of stateCopy.columns) {
      if (column.dataType === 'date' && column.filterValue) {
        if (column.filterValue instanceof Date) {
          column.filterValue.toJSON = function() { return moment(this).format(); };
        } else if (Array.isArray(column.filterValue)) {
          for (let i = 0; i < column.filterValue.length; i++) {
            column.filterValue[i] = moment(column.filterValue[i]).format();
          }
        }
      }
    }

    return JSON.stringify(stateCopy);
  }

  private transformUserProfileSave(phxDataTableUserProfile: PhxDataTableUserProfile): any {
    const savePayload: PhxDataTableUserProfile = JSON.parse(JSON.stringify(phxDataTableUserProfile));

    if (!savePayload.LastModifiedDatetime) {
      delete savePayload.LastModifiedDatetime;
    }

    if (!savePayload.State) {
      console.error('PhxDataTableUserProfile.State is undefined', phxDataTableUserProfile, savePayload);
    }

    return {
      Id: savePayload.Id,
      LastModifiedDatetime: savePayload.LastModifiedDatetime,
      ComponentName: savePayload.ComponentName,
      StateName: savePayload.StateName,
      StateDescription: savePayload.StateDescription,
      State: this.getStringFromStateObject(savePayload.State),
      UserProfileId: savePayload.UserProfileId
    };
  }

  public createEmptyPhxDataTableUserProfile(componentName: string): PhxDataTableUserProfile {
    return {
      Id: 0,
      LastModifiedDatetime: null,
      ComponentName: componentName,
      StateName: '',
      State: null,
      UserProfileId: null

    } as PhxDataTableUserProfile;
  }

  private getReportTypeIdByComponentName(componentName: string) {
    const codeReportType = this.codeReportTypes.find(codeVal => componentName === codeVal.text);
    if (codeReportType) {
      return codeReportType.id;
    }
    return null;
  }
}
