<app-phx-panel headerTitle="Incentive Compensation">
    <form [formGroup]="formGroup">
        <phx-form-control [control]="formGroup.controls.IsEligibleForCommission"
            labelText="Eligible for Incentive Compensation"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsEligibleForCommission')"
            viewModeText="{{formGroup.controls.IsEligibleForCommission.value? 'Yes' : 'No'}}">
            <label class="radio-inline">
                <input class="form-check-input" name="IsEligibleForCommission" type="radio" [value]="true"
                    [checked]="formGroup.controls.IsEligibleForCommission.value == true"
                    formControlName="IsEligibleForCommission">Yes</label>
            <label class="radio-inline">
                <input class="form-check-input" name="IsEligibleForCommission" type="radio" [value]="false"
                    [checked]="formGroup.controls.IsEligibleForCommission.value == false"
                    formControlName="IsEligibleForCommission">No</label>
        </phx-form-control>
        <div *ngIf="formGroup.controls.IsEligibleForCommission.value">
            <phx-form-control [control]="formGroup.controls.IsThirdPartyImport" labelText="Is Third Party Import"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsThirdPartyImport')"
                viewModeText="{{formGroup.controls.IsThirdPartyImport.value? 'Yes' : 'No'}}">
                <label class="radio-inline">
                    <input class="form-check-input" name="IsThirdPartyImport" type="radio" [value]="true"
                        [checked]="formGroup.controls.IsThirdPartyImport.value == true"
                        formControlName="IsThirdPartyImport">Yes</label>
                <label class="radio-inline">
                    <input class="form-check-input" name="IsThirdPartyImport" type="radio" [value]="false"
                        [checked]="formGroup.controls.IsThirdPartyImport.value == false"
                        formControlName="IsThirdPartyImport">No</label>
            </phx-form-control>
        </div>
        <div *ngIf="formGroup.controls.IsThirdPartyImport.value && formGroup.controls.IsEligibleForCommission.value">

            <phx-form-control [control]="formGroup.controls.CommissionThirdPartyWorkerReference"
                labelText="Third Party Worker ID"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','CommissionThirdPartyWorkerReference')"
                viewModeText="{{formGroup.controls.CommissionThirdPartyWorkerReference.value}}">
                <input formControlName="CommissionThirdPartyWorkerReference" type="text" class="form-control" />
            </phx-form-control>
        </div>
        <div *ngIf="formGroup.controls.IsThirdPartyImport.value">
            <div
                *ngIf="OrganizationIdSupplier == null && workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp">
                <phx-form-control [control]="formGroup.controls.IsCommissionVacation"
                    labelText="Pay Vacation for Incentive Compensation"
                    [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion.PaymentInfoes','IsCommissionVacation')"
                    viewModeText="{{formGroup.controls.IsCommissionVacation.value? 'Yes' : 'No'}}">
                    <label class="radio-inline">
                        <input class="form-check-input" name="IsCommissionVacation" type="radio" [value]="true"
                            [checked]="formGroup.controls.IsCommissionVacation.value == true"
                            formControlName="IsCommissionVacation">Yes</label>
                    <label class="radio-inline">
                        <input class="form-check-input" name="IsCommissionVacation" type="radio" [value]="false"
                            [checked]="formGroup.controls.IsCommissionVacation.value == false"
                            formControlName="IsCommissionVacation">No</label>
                </phx-form-control>
            </div>
        </div>
        <div *ngIf="formGroup.controls.IsEligibleForCommission.value">
            <app-workorder-payment-invoices [inputFormGroup]="formGroup.controls.TabPartiesAndRatesPaymentInfoes"
                [invoiceType]="phxConstants.InvoiceType.IncentiveCompensation" [readOnlyStorage]="readOnlyStorage">
            </app-workorder-payment-invoices>
        </div>
    </form>
</app-phx-panel>
<app-phx-panel headerTitle="Allowance">
    <form [formGroup]="formGroup">
        <phx-form-control [control]="formGroup.controls.IsEligibleForAllowance" labelText="Eligible for Allowance"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','IsEligibleForAllowance')"
            viewModeText="{{formGroup.controls.IsEligibleForAllowance.value? 'Yes' : 'No'}}">
            <label class="radio-inline">
                <input class="form-check-input" name="IsEligibleForAllowance" type="radio" [value]="true"
                    [checked]="formGroup.controls.IsEligibleForAllowance.value == true"
                    formControlName="IsEligibleForAllowance">Yes</label>
            <label class="radio-inline">
                <input class="form-check-input" name="IsEligibleForAllowance" type="radio" [value]="false"
                    [checked]="formGroup.controls.IsEligibleForAllowance.value == false"
                    formControlName="IsEligibleForAllowance">No</label>
        </phx-form-control>
    </form>
</app-phx-panel>