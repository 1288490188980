<ul *ngIf="calculatedOvertimeDetails" class="overtime-details">
  <li *ngIf="calculatedOvertimeDetails.dailyThresholds.totalOvertimeHours">
    <b>{{ calculatedOvertimeDetails.dailyThresholds.totalOvertimeHours }} <span i18n="@@timesheet.overtime.dueToDailyThreshold">hour(s) due to Daily Threshold exceeded on</span></b>
    <ng-container *ngTemplateOutlet="thresholdDetails; context: { threshold: calculatedOvertimeDetails.dailyThresholds }"></ng-container>
  </li>

  <li *ngIf="calculatedOvertimeDetails.statHolidayThresholds.totalOvertimeHours">
    <b>{{ calculatedOvertimeDetails.statHolidayThresholds.totalOvertimeHours }} <span i18n="@@timesheet.overtime.dueToHoliday">hour(s) due to Stat Holiday on</span></b>
    <ng-container *ngTemplateOutlet="thresholdDetails; context: { threshold: calculatedOvertimeDetails.statHolidayThresholds }"></ng-container>
  </li>

  <li *ngIf="calculatedOvertimeDetails.weeklyThresholds.totalOvertimeHours">
    <b>{{ calculatedOvertimeDetails.weeklyThresholds.totalOvertimeHours }} <span i18n="@@timesheet.overtime.dueToWeeklyThreshold">hour(s) due to Weekly Threshold exceeded on</span></b>
    <ng-container *ngTemplateOutlet="thresholdDetails; context: { threshold: calculatedOvertimeDetails.weeklyThresholds }"></ng-container>
  </li>
</ul>

<ng-template
  #thresholdDetails
  let-threshold="threshold"
>
  <ul class="overtime-details">
    <li
      *ngFor="let detail of threshold.details"
      class="threshold-detail"
    >
      {{ detail.date }} <span>({{ detail.overtimeHours }})</span>
    </li>
  </ul>
</ng-template>
