<app-phx-panel headerTitle="Billing Party">
  <form [formGroup]="formGroup">
    <div *ngFor="let rateDetail of partiesRateDetailFormArray?.controls; let i = index; trackBy: trackByFn">
      <div *ngIf="rateDetail">
        <app-workorder-billing-party [inputFormGroup]="rateDetail" [billingInfoIndex]="i"
                                     [readOnlyStorage]="readOnlyStorage">
        </app-workorder-billing-party>
      </div>
    </div>
  </form>
</app-phx-panel>
