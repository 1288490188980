import { Injectable } from '@angular/core';
import { IJobOwner, IWorkOrderVersionCommission } from '../../models';
import { CommissionPickerSummaryDto } from 'src/app/commission/model';
import { PhxConstants } from 'src/app/common';
import { CommissionPickerService } from 'src/app/commission/services';
import { ICommonListsItem } from 'src/app/common/lists';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderCommissionPickerService {

  constructor(private commissionPickerService: CommissionPickerService) { }

  /**
   * Merge current Work Order Version commissions with the API commission picker result
   * @param existingWOVCommissions
   * @param commissionPickerResults List of available commissions for the selected users from the API following commission restriction rules
   * @param assignmentStatus 
   * @param workOrderStatus 
   * @param previousCommissionRateIds Last Approved or saved Commission Rate Ids 
   * @param userProfileCommissionsList Lookup of commission user profiles e.g. Display Name
   * @param reset Override value for all items? (why do we need this?)
   * @returns 
   */
  getNewSummary(
    existingWOVCommissions: IWorkOrderVersionCommission[],
    commissionPickerResults: CommissionPickerSummaryDto[],
    assignmentStatus: PhxConstants.AssignmentStatus,
    workOrderStatus: PhxConstants.WorkOrderStatus,
    previousCommissionRateIds: number[],
    userProfileCommissionsList: ICommonListsItem[],
    reset: Partial<IJobOwner> = {}
  ): IWorkOrderVersionCommission[] {

    const list: IWorkOrderVersionCommission[] = [];

    for (const pickerResult of commissionPickerResults) {

      const newSummary = this.calculateSummary(
        assignmentStatus,
        workOrderStatus,
        previousCommissionRateIds,
        existingWOVCommissions,
        pickerResult,
        userProfileCommissionsList,
        reset
      );

      if (newSummary != null) {
        list.push(newSummary);
      }
    }

    return list;
  }

  private calculateSummary(
    assignmentStatus: PhxConstants.AssignmentStatus,
    workOrderStatus: PhxConstants.WorkOrderStatus,
    previousCommissionRateIds: number[],
    existingSummaries: IWorkOrderVersionCommission[],
    pickerResult: CommissionPickerSummaryDto,
    userProfileCommissionsList: ICommonListsItem[],
    reset: Partial<IJobOwner>
  ): IWorkOrderVersionCommission {

    const existingSummary = existingSummaries.find(c => c.UserProfileIdSales === pickerResult.UserProfileId && c.CommissionRoleId === pickerResult.CommissionRoleId);

    const newSummary: IWorkOrderVersionCommission = {
      ...existingSummary,
      ...{
        UserProfileIdSales: pickerResult.UserProfileId,
        IsApplicable: pickerResult.IsApplicable,
        CommissionRoleId: pickerResult.CommissionRoleId,
        CommissionRates: pickerResult.CommissionRates.map(rate => {
          return {
            ...rate,
            DisplayText: null
          };
        }),
        CommissionRateHeaderId: null
      },
      ...reset
    };


    // Calculate if current selection should be removed if missing from the picker results
    const shouldRemoveMissingRate = this.shouldRemoveMissingRate(existingSummary, assignmentStatus, workOrderStatus, previousCommissionRateIds);

    // Assign existing commission rate if exists in picker results
    if (newSummary.CommissionRateHeaderId == null && existingSummary?.CommissionRateHeaderId > 0) {
      const isRateInNewSummary = newSummary.CommissionRates.some(c => c.CommissionRateHeaderId === existingSummary.CommissionRateHeaderId);

      if (!shouldRemoveMissingRate || isRateInNewSummary) {   
        newSummary.CommissionRateHeaderId = existingSummary.CommissionRateHeaderId;
        
        if (!isRateInNewSummary) {
          newSummary.CommissionRates.push(existingSummary.CommissionRates.find(c => c.CommissionRateHeaderId === existingSummary.CommissionRateHeaderId));
        }
      }
    }

    newSummary.CommissionRates.forEach(c => {
      c.DisplayText = this.commissionPickerService.getCommissionRateDisplayText(c.CommissionRateHeaderId, c.Description, c.CommissionRateHeaderStatusId);
    });

    if (newSummary.CommissionRateHeaderId === null && newSummary.CommissionRates.length === 1) {
      // assign header id if only 1 rate exist
      newSummary.CommissionRateHeaderId = newSummary.CommissionRates[0].CommissionRateHeaderId;
    }

    const contact = userProfileCommissionsList.find(profile => profile.Id === newSummary.UserProfileIdSales);

    newSummary.FullName = contact ? contact.DisplayText : '';

    const autoAssignedRoles = [
      PhxConstants.CommissionRole.NationalAccountsRole,
      PhxConstants.CommissionRole.BranchManagerRole
    ];
    
    const isUserAutoAssigned = autoAssignedRoles.includes(newSummary.CommissionRoleId);

    if (!isUserAutoAssigned || newSummary.CommissionRates.length > 0) {
      return newSummary;
    }
    else {
      return null;
    }
  }

  private shouldRemoveMissingRate(
    existingSummary: IWorkOrderVersionCommission,
    assignmentStatus: PhxConstants.AssignmentStatus,
    workOrderStatus: PhxConstants.WorkOrderStatus,
    previousCommissionRateIds: number[]
  ): boolean {
    // Calculate if current selection should be removed if missing from the picker results
    let shouldRemoveMissingRate = true;
    if (existingSummary) {
      switch (existingSummary.CommissionRateStatusId) {
        case PhxConstants.CommissionRateHeaderStatus.InActive:
          // Inactive rates ara not available for first WOV in Work Order (status = Processing), but can carry over for following WOVs in that WO
          shouldRemoveMissingRate = workOrderStatus === PhxConstants.WorkOrderStatus.Processing
            || !previousCommissionRateIds.some(rateId => rateId === existingSummary.CommissionRateHeaderId);
        break;
        case PhxConstants.CommissionRateHeaderStatus.Grandfathered:
          // Grandfathered rates are not available for new Assignments, but can carry over for following Work Orders and WOV
          shouldRemoveMissingRate = assignmentStatus === PhxConstants.AssignmentStatus.Onboarding
            || !previousCommissionRateIds.some(rateId => rateId === existingSummary.CommissionRateHeaderId);
          break;
        default:
          break;
      }
    }

    return shouldRemoveMissingRate;
  }

}
