<div class="loading-container" *ngIf="loading">
    <app-bouncing-balls color="#333"></app-bouncing-balls>
</div>
<div class="ck-panel" *ngIf="!loading">
    <div class="ck-panel-body">
        <div class="ck-accord">
            <div class="ck-accord-body">
                <div *ngFor="let rule of ruleResults" class="ck-rule">
                    <ng-container
                        *ngIf="rule.IsValid && rule.strictness !== RuleStrictness.NotApplicable && displayConfiguration.showValid">
                        <mat-icon class="ck-success">
                            done
                        </mat-icon>
                        <div class="ck-text">
                            <p class="ck-passed">{{rule.ruleText}}</p>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="!rule.isValid  && rule.strictness !== RuleStrictness.NotApplicable && displayConfiguration.showInvalid">
                        <mat-icon *ngIf="rule.strictness === RuleStrictness.Warning" class="ck-warn">
                            warning
                        </mat-icon>
                        <mat-icon *ngIf="rule.strictness !== RuleStrictness.Warning" class="ck-critical">
                            close
                        </mat-icon>
                        <div class="ck-text">
                            <p>{{rule.ruleText}}</p>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="rule.strictness === RuleStrictness.NotApplicable && displayConfiguration.showNotApplicable">
                        <mat-icon fontSet="material-icons-outlined">
                            do_not_disturb_on
                        </mat-icon>
                        <div class="ck-text-disabled">
                            <p class="ck-passed">
                                {{rule.ruleText}}
                            </p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
