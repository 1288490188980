<app-phx-panel headerTitle="Details">
    
    <form [formGroup]="formGroup" *ngIf="formGroup">
    
        <phx-form-control [control]="formGroup.controls.AtsPlacementId" [editable]="false" labelText="ATS Id"
            viewModeText="{{formGroup.controls.AtsPlacementId.value}}">
        </phx-form-control>
    
        <ng-container
          *ngIf="formGroup.controls.OnboardingPackageId.value"
        >
            <phx-form-control
              [control]="formGroup.controls.OnboardingPackageId"
              [editable]="false"
              labelText="Onboarding Package Id"
              i18n-labelText="@@common.onboardingPackageId"
              [viewModeText]="formGroup.controls.OnboardingPackageId.value?.toString()"
              [link]="'/next/onboarding/onboarding-package/' + formGroup.controls.OnboardingPackageId.value"
              [clickAnalyticsData]="{ action: 'View Onboarding Package', feature: 'Work Order', type: 'Redirection link' }"
            >
            </phx-form-control>
        </ng-container>
    
        <ng-container *ngIf="workOrder?.AssignmentStartDate">
            <phx-form-control [control]="formGroup.controls.OriginalStartDate" [editable]="false"
                labelText="Original Start Date"
                viewModeText="{{formGroup.controls.OriginalStartDate.value | date: phxConstants.DateFormat.mediumDate}}">
            </phx-form-control>
        </ng-container>
    
        <ng-container *ngIf="isDisplayWorkOrderStartDateState">
            <phx-form-control [control]="formGroup.controls.WorkOrderStartDateState"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','WorkOrderStartDateState')"
                labelText="Start Date"
                viewModeText="{{formGroup.controls.WorkOrderStartDateState.value | date: phxConstants.DateFormat.mediumDate}}">
                <app-phx-date-box
                    type="date"
                    [displayFormat]="{ dateStyle: 'medium' }"
                    [formControl]="formGroup.controls.WorkOrderStartDateState"
                ></app-phx-date-box>
            </phx-form-control>
        </ng-container>
    
        <ng-container *ngIf="!isDisplayWorkOrderStartDateState">
            <phx-form-control [control]="formGroup.controls.StartDate"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrder','StartDate')"
                labelText="Start Date"
                viewModeText="{{formGroup.controls.StartDate.value | date: phxConstants.DateFormat.mediumDate}}">
                <app-phx-date-box
                    type="date"
                    [displayFormat]="{ dateStyle: 'medium' }"
                    [formControl]="formGroup.controls.StartDate"
                ></app-phx-date-box>
            </phx-form-control>
        </ng-container>
    
        <ng-container *ngIf="isDisplayWorkOrderEndDateState">
            <phx-form-control [control]="formGroup.controls.WorkOrderEndDateState"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','WorkOrderEndDateState')"
                labelText="{{workorderEndDateLabelText}}"
                viewModeText="{{formGroup.controls.WorkOrderEndDateState.value | date: phxConstants.DateFormat.mediumDate}}">
                <app-phx-date-box
                    type="date" [displayFormat]="{ dateStyle: 'medium' }"
                    [formControl]="formGroup.controls.WorkOrderEndDateState"
                    [min]="formGroup.controls.StartDate.value"
                >
                </app-phx-date-box>
            </phx-form-control>
        </ng-container>
    
        <ng-container *ngIf="!isDisplayWorkOrderEndDateState">
            <phx-form-control [control]="formGroup.controls.EndDate"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrder','EndDate')"
                labelText="{{workorderEndDateLabelText}}"
                viewModeText="{{formGroup.controls.EndDate.value | date: phxConstants.DateFormat.mediumDate}}">
                <app-phx-date-box
                    type="date" [displayFormat]="{ dateStyle: 'medium' }"
                    [formControl]="formGroup.controls.EndDate"
                    [min]="formGroup.controls.StartDate.value"
                >
                </app-phx-date-box>
            </phx-form-control>
        </ng-container>
    
        <phx-form-control [control]="formGroup.controls.LineOfBusinessId" labelText="Line Of Business"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','LineOfBusinessId', workOrder?.WorkOrderVersion)"
            viewModeText="{{formGroup.controls.LineOfBusinessId.value | lookup: html.lists.lineOfBusiness:'id':'text'}}">
            <app-phx-select-box [formControl]="formGroup.controls.LineOfBusinessId" [items]="html.lists.lineOfBusiness"
                textField="text" valueField="id">
            </app-phx-select-box>
        </phx-form-control>
    
        <phx-form-control [control]="formGroup.controls.InternalOrganizationDefinition1Id" labelText="Branch"
            [editable]="(hideCommissions && readOnlyStorage.IsEditable) || (readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','InternalOrganizationDefinition1Id'))"
            viewModeText="{{formGroup.controls.InternalOrganizationDefinition1Id.value | lookup: html.lists.InternalOrganizationDefinition1List:'id':'value'}}">
            <app-phx-select-box [formControl]="formGroup.controls.InternalOrganizationDefinition1Id"
                [items]="html.lists.InternalOrganizationDefinition1List" textField="value" valueField="id">
            </app-phx-select-box>
        </phx-form-control>
    
        <phx-form-control [control]="formGroup.controls.WorksiteId"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','WorksiteId')"
            labelText="Client Work Site"
            viewModeText="{{formGroup.controls.WorksiteId.value | lookup: html.lists.workOrderWorkLocations:'id':'text'}}">
            <app-phx-select-box [formControl]="formGroup.controls.WorksiteId"
                [items]="html.lists.workOrderWorkLocations" textField="text" valueField="id">
            </app-phx-select-box>
        </phx-form-control>
    
        <div class="worker-location-container">
            <phx-form-control [control]="formGroup.controls.WorkerLocationId"
                [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','WorkerLocationId')"
                labelText="Consultant Work Location"
                viewModeText="{{formGroup.controls.WorkerLocationId.value | lookup: html.lists.workerLocations:'id':'text'}}">
                <app-phx-select-box [formControl]="formGroup.controls.WorkerLocationId" [items]="html.lists.workerLocations"
                    textField="text" valueField="id">
                </app-phx-select-box>
            </phx-form-control>
            <div class="location-tip-container"><i class="material-icons md-64" id="locationtooltip">info</i></div>
        </div>
        <app-phx-tool-tip targetId="#locationtooltip">
            This field represents the location where the consultant is physically sitting to do the work.
        </app-phx-tool-tip>
    
        <ng-container *ngIf="shouldShowHolidaySchedule()">
            <phx-form-control [control]="formGroup.controls.HolidayScheduleNameId" [editable]="true"
                labelText="Holiday Schedule"
                viewModeText="{{formGroup.controls.HolidayScheduleNameId.value | lookup: html.lists.holidayScheduleNameList:'Id':'DisplayText'}}"
            >
                <div class="row no-margin">
                    <div class="col-lg-10 no-padding"
                        *ngIf="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','HolidayScheduleNameId')">
                        <app-phx-select-box [formControl]="formGroup.controls.HolidayScheduleNameId" [items]="holidayItemList"
                            textField="DisplayText" valueField="Id">
                        </app-phx-select-box>
    
                    </div>
                    <div class="col-lg-10 fieldViewMode form-control-static no-padding"
                        *ngIf="!(readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrderVersion','HolidayScheduleNameId'))">
                        <p class="form-control-static">
                            {{ formGroup.controls.HolidayScheduleNameId.value | lookup: html.lists.holidayScheduleNameList:'Id':'DisplayText' }}
                        </p>
                    </div>
                    <div class="col-lg-2 no-padding-h" *ngIf="formGroup.controls.HolidayScheduleNameId.value">
                        <p class="form-control-static" [class.text-align-right]="readOnlyStorage.IsEditable">
                            <a (click)="openSchedule()" style="cursor: pointer;">View</a>
                        </p>
                    </div>
                </div>
            </phx-form-control>
        </ng-container>
    
        <phx-form-control [control]="formGroup.controls.OrganizationIdInternal" labelText="Internal Company"
            [editable]="readOnlyStorage.IsEditable && checkPtFiledAccessibility('WorkOrder','OrganizationIdInternal')"
            viewModeText="{{formGroup.controls.OrganizationIdInternal.value | lookup: html.lists.OrganizationInternalList:'OrganizationIdInternal':'Name'}}">
            <app-phx-select-box [formControl]="formGroup.controls.OrganizationIdInternal"
                [items]="html.lists.OrganizationInternalList" textField="DisplayValue"
                valueField="OrganizationIdInternal">
            </app-phx-select-box>
        </phx-form-control>
    
        <phx-form-control *ngIf="!this.workOrder.TemplateId && hasTheOptionToExcludeFromBIReport$ | async" [control]="formGroup.controls.ExcludeFromBI" labelText="Exclude From BI Report">
            <div class="checkbox">
                <label>
                    <input type="checkbox" formControlName="ExcludeFromBI" [attr.disabled]="!readOnlyStorage.IsEditable ? 'disabled' : null "/>
                </label>
            </div>
        </phx-form-control>
    </form>
</app-phx-panel>

<app-phx-dialog #phxDialogComponent [config]="phxDialogComponentConfigModel">
    <ng-container custom-body>
        <p>The following fields have been updated in Earnings and Deductions</p>
        <ul>
            <li>Worker classification</li>
            <li *ngIf="workOrder &&
                (workOrder.workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp
                || workOrder.workerProfileTypeId === phxConstants.UserProfileType.WorkerCanadianSp
                || workOrder.workerProfileTypeId === phxConstants.UserProfileType.WorkerUnitedStatesW2)">
                Deduction Province/State
            </li>
        </ul>
    </ng-container>
</app-phx-dialog>

<app-phx-modal #itemModal
    title="{{calendarName?.value | lookup: html.lists.holidayScheduleNameList:'Id':'DisplayText' }}">
    <div class="row">
        <span style="font-size:14px; color: #1E2430">List of holidays for the selected calendar</span>
    </div>
    <div class="row">
        <ul class="list-group">
            <li style="cursor:pointer;border-top:none;border-left:none;border-right:none;margin:10px;"
                class="list-group-item" *ngFor="let holiday of holidayList">
                <div style="font-weight:bold;font-size: 16px;color:#000000;border:none;">
                    {{holiday?.HolidayName}}
                </div>
                <span style="opacity: 0.60;font-size:14px;">{{holiday?.CurrentYearHoliday?.Date}}</span>
            </li>
        </ul>
    </div>

</app-phx-modal>
