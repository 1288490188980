<div class="content-group form-horizontal" [formGroup]="formGroup">
  <div class="content-left">
    <app-phx-panel headerTitle="Other Earnings" 
      *ngIf="workOrder?.workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp"
    >
      <form [formGroup]="formGroup.controls.OtherEarnings">
        <ng-container formArrayName="OtherEarning"
          *ngFor="let paymentInfo of formGroup.get('OtherEarnings').get('OtherEarning')['controls']; let i = index;">
          <ng-container *ngIf="!paymentInfo.controls.OrganizationIdSupplier.value">
            <ng-container *ngIf="i === 0">
              <ng-container [formGroupName]="i">
                <ng-container *ngIf="paymentInfo.controls.PaymentOtherEarnings.value.length>0">
                  <ng-container formArrayName="PaymentOtherEarnings"
                    *ngFor="let paymentOtherEarning of paymentInfo.get('PaymentOtherEarnings').controls; let j = index;">
                    <ng-container [formGroupName]="j">
                      <app-workorder-tab-earnings-and-deduction-other-earnings [inputFormGroup]="paymentOtherEarning"
                        [listUserProfileWorker]="listUserProfileWorker" [readOnlyStorage]="readOnlyStorage">
                      </app-workorder-tab-earnings-and-deduction-other-earnings>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </app-phx-panel>
    <app-phx-panel headerTitle="Workplace Safety Insurance">
      <app-workorder-tab-earnings-and-deduction-safety-insurance [readOnlyStorage]="readOnlyStorage">
      </app-workorder-tab-earnings-and-deduction-safety-insurance>
    </app-phx-panel>

    <app-phx-panel headerTitle="Statutory Holiday" 
      *ngIf="workOrder?.workerProfileTypeId === phxConstants.UserProfileType.WorkerTemp"
    >
      <app-workorder-tab-earnings-deduction-statutory-holiday [readOnlyStorage]="readOnlyStorage">
      </app-workorder-tab-earnings-deduction-statutory-holiday>
    </app-phx-panel>

  </div>
  <div class="content-right">
    <app-phx-panel *ngIf="showCanadianNonResidentWithholdingTax" headerTitle="Canadian Non-Resident Withholding Tax">
      <app-workorder-tab-earnings-and-deduction-non-resident-withholding [readOnlyStorage]="readOnlyStorage">
      </app-workorder-tab-earnings-and-deduction-non-resident-withholding>
    </app-phx-panel>
    <div formArrayName="PaymentInfoes">
      <div *ngFor="let paymentInfo of formGroup.get('PaymentInfoes')['controls']; let i = index">
        <div *ngIf="paymentInfo.get('OrganizationIdSupplier').value === null && 
                    listUserProfileWorker.length &&
                   (listUserProfileWorker[0].ProfileTypeId === phxConstants.UserProfileType.WorkerTemp ||
                    listUserProfileWorker[0].ProfileTypeId === phxConstants.UserProfileType.WorkerCanadianSp)">
          <div role="form" *ngIf="i === 0">

            <app-workorder-tab-earnings-deduction-source-deduction
              [inputFormGroup]="paymentInfo.controls.SourceDeductions" [readOnlyStorage]="readOnlyStorage"
              (isUseUserProfileWorkerSourceDeductionChangeEvent)="onIsUseUserProfileWorkerSourceDeductionChangeEvent($event)">
            </app-workorder-tab-earnings-deduction-source-deduction>

            <app-phx-panel headerTitle="Income Taxes"
              *ngIf="(paymentInfo.get('SourceDeductions').controls.IsUseUserProfileWorkerSourceDeduction.value ||
                      paymentInfo.get('PaymentSourceDeductions').value.length) &&
                     filterOnPaymentSourceDeductionsByIncomeTaxes(paymentInfo.get('PaymentSourceDeductions').controls).length > 0">
              <ng-container
                *ngFor="let incomeTaxControl of filterOnPaymentSourceDeductionsByIncomeTaxes(paymentInfo.get('PaymentSourceDeductions').controls); let j = index">
                <ng-container *ngIf="incomeTaxControl.get('ToShow').value || incomeTaxControl.get('IsApplied').value">
                  <app-workorder-tab-earnings-deduction-taxes [inputFormGroup]="incomeTaxControl"
                    [readOnlyStorage]="readOnlyStorage">
                  </app-workorder-tab-earnings-deduction-taxes>
                </ng-container>
              </ng-container>
            </app-phx-panel>

            <app-phx-panel headerTitle="Payroll Taxes"
              *ngIf="(paymentInfo.get('SourceDeductions').controls.IsUseUserProfileWorkerSourceDeduction.value ||
                      paymentInfo.get('PaymentSourceDeductions').value.length) &&
                     filterOnPaymentSourceDeductionsByPayrollTaxes(paymentInfo.get('PaymentSourceDeductions').controls).length > 0">
              <ng-container
                *ngFor="let payrollTaxControl of filterOnPaymentSourceDeductionsByPayrollTaxes(paymentInfo.get('PaymentSourceDeductions').controls); let j = index">
                <ng-container *ngIf="payrollTaxControl.get('ToShow').value || payrollTaxControl.get('IsApplied').value">
                  <app-workorder-tab-earnings-deduction-taxes [inputFormGroup]="payrollTaxControl"
                    [readOnlyStorage]="readOnlyStorage">
                  </app-workorder-tab-earnings-deduction-taxes>
                </ng-container>
              </ng-container>
            </app-phx-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-phx-dialog #phxDialogComponent (callback)="dialogActionCallBackObButtonClick()"
  [config]="phxDialogComponentConfigModel"></app-phx-dialog>

<app-phx-modal #modal [buttons]="modalFabButtons" title="Source Deduction">
  <div class="attachment-container">
    Are you sure you would like to take from Worker Profile? This will erase all current selections.
  </div>
</app-phx-modal>
